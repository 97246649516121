import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { PortfolioSurveyService } from 'app/_services';

@Component({
	selector: 'app-technology-vulnerabilty',
	templateUrl: './technology-vulnerabilty.component.html',
	styleUrls: ['./technology-vulnerabilty.component.scss']
})
export class TechnologyVulnerabiltyComponent implements OnInit {

	@Input() mainDomain: any;
	@Input() technologyVulnerabilties = [];
	@Input() showExecutiveReport;
	@Input() isUserTrial: boolean = false;

	public order: number = 1;
	public sortWith = 'technology_name';
	public initialSort: boolean = false;
	public show_max_list: number = 3;
	public show_max_location: number = 5;
	public seeMore = 50;
	public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
	// public delReason = null;
	public vulnerabilityData = {};
	public allVulnerabilites = []
	public detailVul={}
	public seeRef= false;
	public fieldsToDownload = {
		'Title': 'technology_name' ,
		'Version' : 'technology_version' ,
		'CVE Id' : 'cve_id',
		'Description' : 'cve_summary',
		'Severity': 'cve_score',
		// 'Impact' : 'impact',
		// 'Remediation' : 'remediation',
		'References': 'references',

	}
	public openThis;
	constructor(public commonService: CommonService, public portfolioSurveyService: PortfolioSurveyService,
		public modalService: BsModalService,
		public modalRef: BsModalRef) { }

	ngOnInit() {
	}
	ngOnChanges() {
		if (this.technologyVulnerabilties && this.technologyVulnerabilties.length) {
			this.technologyVulnerabilties.forEach(tecVul => {
				if (tecVul.cves.length) {
					tecVul.cves.forEach(cve => {
						this.allVulnerabilites.push({...cve, ...{technology_name: tecVul.technology_name, technology_version: tecVul.version }})
					});
				}
			})
		}
	}
	openRemoveOptions(template: TemplateRef<any>, vul) {
		if (this.vulnerabilityData['ids']) {
			this.vulnerabilityData['ids'].push(...vul['ids'])
		} else {
			this.vulnerabilityData['ids'] = vul['ids']
		}
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}

	delTechVul(reason) {
		this.vulnerabilityData['archive_reason'] = reason;
		this.deleteTechnologyVulnerability();
		// this.delReason = null;
	}

	public deleteTechnologyVulnerability() {
		this.portfolioSurveyService.deleteTechnologyVulnerability(this.vulnerabilityData, this.mainDomain).subscribe(data => {
			this.modalRef.hide();
			alert("Vulnerability deleted successfully!")
			this.vulnerabilityData = {};
		}, err => {
			this.modalRef.hide();
			this.vulnerabilityData = {};
		});
	}
	dataToRemediate(data) {
		this.vulnerabilityData['archive_reason'] = data['archive_reason'];
		this.vulnerabilityData['comment'] = data['comment']
		this.deleteTechnologyVulnerability();
	}

	closeModel(value) {
		if (value) {
			this.modalRef.hide();
			this.vulnerabilityData = {};
		}
	}
	delReason(reason) {
		this.delTechVul(reason)
	}
	openDetailVul(template: TemplateRef<any>, tech) {
		this.detailVul=tech
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	// severityOfRiskLevel(riskLevel) {
	// 	switch (true) {
	// 		case (riskLevel == 'High'): return 'badge-danger';
	// 		case (riskLevel == 'Low'): return 'badge-success';
	// 		case (riskLevel == 'Medium'): return 'badge-warning';

	// 		default: return 'badge-primary';
	// 	}
	// }
	cancelDel(a) {
		this.vulnerabilityData = {};
	}
}
