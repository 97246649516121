import { Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "./http.service";
import {
  StorageAccessConfig,
  JobAutomationConfig,
} from "../../environments/environment";
import {
  HttpHeaders,
  HttpClient
} from "@angular/common/http";
@Injectable()
export class UploadEvidenceService {
  public storageAccessConfig = StorageAccessConfig.spUrl;
  public jobAutomationConfig = JobAutomationConfig;

  private headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("id_token"),
  });
  constructor(
    public httpSVC: HttpService,
    private http: HttpClient
  ) {}
  public uploadFileToBucket(url: string, file) {
    const options = {
      headers: new HttpHeaders({
        skip: "true",
        "Content-Type": "image/png",
      }),
    }
    return this.http.put(url, file, options);
  }
  public getSignedUrlForUploadEvidence(
    domainName, dataToSend
  ) {
    const url =
      this.storageAccessConfig +
      "/shadowit/signed_url/evidence/" +
      domainName 
    return this.httpSVC.post(url,dataToSend);
  }
  public uploadFileToSignedUrl(signedUrl, file, content_type) {
    if (!signedUrl || !file) throw Error("File or signed url missing");
    else {
      return this.httpSVC.uploadFileToBucketV2(signedUrl, file, content_type);
    }
  }
  public updateEvidence(
    domain,dataToSend
  ) {
    const url =
      this.storageAccessConfig +
      "/shadowit/vulnerability/update/evidence/" +
      domain
    return this.httpSVC.post(url,dataToSend);
  }

  public openEvidenceUrl(
    domain, dataToSend
  ){
    const url =
      this.storageAccessConfig +
      "/shadowit/vulnerability/update/evidence/" +
      domain + "?bucket_name=" +
      dataToSend.bucket_name +
      "&blob_name=" +
      dataToSend.blob_name;
      return this.httpSVC.get(url);
  }

  public getWebCVELocationsEvidences(domain, dataToSend) {
    const url =
      this.storageAccessConfig +
      "/shadowit/webapplication/vulnerability/locations/" +
      domain + "?web_app=" +
      dataToSend.web_app +
      "&cve_id=" +
      dataToSend.cve_id +
      "&location=" +
      dataToSend.location;
      return this.httpSVC.get(url);
  }
  public deleteEvidence(
    domain,dataToSend
  ) {
    const url =
      this.storageAccessConfig +
      "/shadowit/vulnerability/update/evidence/" +
      domain
    return this.httpSVC.delete(url,dataToSend);
  }
}
