import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loading'
})
export class LoadingPipe implements PipeTransform {
	constructor() { }
  transform(value, ...args: unknown[]): unknown {
    if (value || value == 0) return `<span>${value}</span>`
    else return `<i class="fa fa-spinner"></i>`
  }
}