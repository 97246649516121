import { Component, Input, OnInit , TemplateRef} from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from 'app/_services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mobile-application-vulnerability',
  templateUrl: './mobile-application-vulnerability.component.html',
  styleUrls: ['./mobile-application-vulnerability.component.scss']
})
export class MobileApplicationVulnerabilityComponent implements OnInit {

  @Input() mainDomain: any;
  public loadingMobileVul: boolean = true;
  public mobileApplication: any = [];
  public sortWith = 'vulnerabilities';
  public initialSort: boolean = true;
  public order = -1;
  public seeMoreMobile: number = 20;
  public cve_details: any;
  public filterObj = { "module": ["deepc"] };
  public count = 0;
  public showMod = "deepc";
  public VulTabsToShow = [{
    'name': 'Deepc',
    'module': 'deepc'
  },
  {
      'name': 'Appknox',
      'module': 'appknox'
  },
  {
      'name': 'Manual',
      'module': 'manual'
  }];
  public mobileAppFieldsToDownload = {
    'Platform': 'platform',
    'Source Url': 'source_url',
    'No.of Downloads': 'downloads',
    'Critical Vulnerability': 'criticalVulCount',
    'High Vulnerability': 'highVulCount',
    'Medium Vulnerability': 'mediumVulCount',
    'Low Vulnerability': 'lowVulCount',
    'Created At': 'created_at',
    'Updated At': 'updated_at',
    "Comments": "comments"
  }
  constructor(
    public commonService: CommonService,
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getmobileapplication();
  }

  forEachMobile() {
    if (this.mobileApplication) {
      this.mobileApplication.forEach(mob => {
        this.checkCveScore(mob)
      })
    }
  }

  getmobileapplication() {
    if (!this.mainDomain) return;
    this.loadingMobileVul = true;
    this.portfolioSurveyService.getmobileapplication(this.mainDomain, {}).subscribe(data => {
      this.loadingMobileVul = false;
      if (data && data['data']) {
        this.mobileApplication = data['data']
        this.forEachMobile()
      } else {
        this.mobileApplication = [];
      }
    },(error)=>{
      console.log(error);
      this.loadingMobileVul = false;
    })
  }

  checkCveScore(mob) {
    mob.criticalVul = null
    mob.highVul = null
    mob.mediumVul = null
    mob.lowVul = null
    mob.criticalVulCount = 0
    mob.highVulCount = 0
    mob.mediumVulCount = 0
    mob.lowVulCount = 0
    mob.vulnerabilities.forEach(cve => {
      if (cve.severity >= 9) {
        mob.criticalVulCount = mob.criticalVulCount ? mob.criticalVulCount + 1 : 1;
        if (mob.criticalVul) {
          mob.criticalVul.push(cve);
        } else {
          mob.criticalVul = [cve];
        }
      } else if (cve.severity >= 7) {
        mob.highVulCount = mob.highVulCount ? mob.highVulCount + 1 : 1;
        if (mob.highVul) {
          mob.highVul.push(cve);
        } else {
          mob.highVul = [cve];
        }
      } else if (cve.severity >= 4) {
        mob.mediumVulCount = mob.mediumVulCount ? mob.mediumVulCount + 1 : 1;
        if (mob.mediumVul) {
          mob.mediumVul.push(cve);
        } else {
          mob.mediumVul = [cve];
        }
      } else {
        mob.lowVulCount = mob.lowVulCount ? mob.lowVulCount + 1 : 1;
        if (mob.lowVul) {
          mob.lowVul.push(cve);
        } else {
          mob.lowVul = [cve];
        }
      }
    })
  }
  modalMobOpen(template: TemplateRef<any>, vulnerability) {
    console.log(vulnerability);
    this.cve_details = vulnerability;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
  }
  filterApplicationVul(mod) {
    this.showMod = mod.module;
    this.filterObj['module'] = [mod.module];
    this.count = this.count + 1;
  }
}
