import { Component, OnInit,Input } from '@angular/core';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';

@Component({
  selector: 'app-view-submit-vendor-response',
  templateUrl: './view-submit-vendor-response.component.html',
  styleUrls: ['./view-submit-vendor-response.component.scss']
})
export class ViewSubmitVendorResponseComponent implements OnInit {
  @Input() responseQuestion:any;
  @Input() vendorProject:any;
  public predfineQuestions=[]
  public customiseQuestion=[]
  public preDefineResponseData=[]
  public customResponseData=[]
  public updateResponse:boolean = false
  public statusPost:boolean=false;
  public displayPredifine=-1;
  public displayCustom=-1;
  public predifineSections=[];
  public customSections=[]
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,

  ) { }
  ngOnChanges(){
    this.getResponse()
    this.predifineSections=this.responseQuestion['predefined_questions_sections']
    this.customSections=this.responseQuestion['custom_questions_sections'] 
  }
  ngOnInit() {
    this.getResponse()
  }
  getPreQueDetails(index){
    this.displayPredifine=index
  }
  getCustomQueDetails(index){
    this.displayCustom=index
  }
  getOptions(opt) {
     return JSON.parse(opt);
  }
  getResponse(){
    this.predfineQuestions= this.responseQuestion.predefined_questions_details;
    this.customiseQuestion=this.responseQuestion.custom_questions_details;
      if(this.responseQuestion.question_set_id== this.vendorProject['question_set_id'])
       {
        if(this.vendorProject.status=="post"){
            this.statusPost=true;
        } else{
          this.statusPost=false;
        }
        this.portfolioSurveyService.getvendorResponceForProject(this.vendorProject.vendor_question_set_id).subscribe((data:any)=>{
        this.preDefineResponseData=data.getPreDefQue;
        this.customResponseData=data.getCustomQue
        if(this.preDefineResponseData.length || this.customResponseData.length){
          this.updateResponse=true
          this.predfineQuestions.forEach(que=>{
            var index = this.preDefineResponseData.findIndex(abc => abc.question_id == que.question_id)
            que['response_content'] = this.preDefineResponseData[index]['response_content'];
            que['response_id'] = this.preDefineResponseData[index]['response_id'];
            que['maturity_score'] = this.preDefineResponseData[index]['maturity_score']
          })

          this.customiseQuestion.forEach(que=>{
            var index = this.customResponseData.findIndex(abc => abc.question_id == que.question_id)
            que['response_content'] = this.customResponseData[index]['response_content'];
            que['response_id'] = this.customResponseData[index]['response_id'];
            que['maturity_score'] = this.customResponseData[index]['maturity_score']
          })
        }
        })
       
      }
    
  }
  submitResponse(status){
    var dataTosend={}
      if(this.responseQuestion['question_set_id']==this.vendorProject.question_set_id){
          dataTosend['vendor_project_details']=this.vendorProject
      }
    dataTosend['vendor_project_details']['status']=status;
    dataTosend['project_details']=this.responseQuestion;
    if(!this.updateResponse)
    {
      this.portfolioSurveyService.addVendorResponse(dataTosend).subscribe(data => {
        alert('Data Added Successfully...!')
      })
    } else if(this.updateResponse){
      this.portfolioSurveyService.updateVendorResponse(dataTosend).subscribe(data => {
        alert('Data Updated Successfully...!')
      })
    } else {
    }
  }
}