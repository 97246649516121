import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from '../../../_services';
import {CommonService} from '../../../_services/common.service'
@Component({
	selector: 'app-keyword-data-leaks',
	templateUrl: './keyword-data-leaks.component.html',
	styleUrls: ['./keyword-data-leaks.component.scss']
})
export class KeywordDataLeaksComponent implements OnInit {
	public listOfKeywords = []
	public searchedData
	public dataRows
	public timeInterval
	public showSpinner = false;
	public seeMore = 10
	public startDay: number = 7;
	public timeStamp
	public openSlider: boolean = false;
	public totalItems = 0;
	public page = 0;
	public keyword=null;
	public jobId;
	constructor(
		public modalService: BsModalService,
		public modalRef: BsModalRef,
		public portfolioSurveyService: PortfolioSurveyService,
		public commonService :CommonService
	) { }

	ngOnInit() {
		this.filterDataOnDay(null)
	}
	getSearchState(jobId, that) {
		that.portfolioSurveyService.keywordSearchDataLeaksStatus(jobId).subscribe(dataState => {

			// that.portfolioSurveyService.keywordSearchDataLeaksStatus('53d8f8b2-2bc2-4376-afae-794ab26093bc').subscribe(dataState => {
			if (dataState.state == "DONE") {
				that.portfolioSurveyService.keywordSearchDataLeaksResult(jobId,that.page).subscribe(result => {
					that.searchedData = result
					that.totalItems=that.searchedData['total']
					console.log("that.searchedData.....",that.searchedData,"sdfsdfsd......",that.totalItems)
					that.showSpinner = false
					clearInterval(that.timeInterval)
				}, (error) => {
					alert("Not able to process your request.Please try after some time.")
					that.showSpinner = false
				})
			} else {
				console.log("no result found!")
			}

		}, (error) => {
			alert("Not able to process your request.Please try after some time.")
			that.showSpinner = false

		})
	}
	keywordList(values) {
		this.showSpinner = true
		this.page=0
		this.portfolioSurveyService.keywordSearchDataLeaksJobId(values,this.timeStamp).subscribe((dataId:any) => {
			if (dataId.job_id) {
				this.jobId = dataId.job_id
				var that = this;
				this.timeInterval = setInterval(this.getSearchState, 3000, dataId.job_id, that)
			}
		}, (error) => {
			alert("Not able to process your request.Please try after some time.")
			this.showSpinner = false
		})
	}

	openDataModel(template: TemplateRef<any>, fullData) {
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
		this.dataRows = fullData
	}
	filterDataOnDay(value){
		if(value==null){
			this.timeStamp = this.commonService.getTimeStamp(this.startDay)
		}
		if(value=='all'){
			this.timeStamp = this.commonService.getTimeStamp(365)
		}
	}
	 goToPage(val) {
		this.page = val;
		console.log(this.jobId)
		var that = this;
		this.getSearchState(this.jobId, that)
	}
}
