import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationComponent } from './pagination.component';
import { LoaderComponent } from './loader/loader.component';
import { ObservationsInsightsComponent } from './observations-insights/observations-insights.component';
import { FormsModule } from '@angular/forms';
import { CommonObservationInsightComponent } from './observations-insights/common-observation-insight/common-observation-insight.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [
    PaginationComponent,
    LoaderComponent,
  ],
  exports: [
    PaginationComponent,
    LoaderComponent,
    ObservationsInsightsComponent,
    CommonObservationInsightComponent,
  ]
})

export class SharedModule { }