import { Component, Input, OnInit, SimpleChanges, SimpleChange } from '@angular/core';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
import { CommonService } from '../../_services/common.service'
import { AsyncToasterTriggerService } from '../../_services/async-toaster-trigger.service';
import { ReportService } from './../../_services/report.service';
import { JobService }  from './../../_services/job.service'
@Component({
  selector: 'app-reports-csv',
  templateUrl: './reports-csv.component.html',
  styleUrls: ['./reports-csv.component.scss']
})
export class ReportsCsvComponent implements OnInit {
  @Input() csvSection
  @Input() scopeChangeFilter
  @Input() dataFilter
  public csvLoader = false
  public ReportJobId
  public createNew
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService,
    public asyncTrigger: AsyncToasterTriggerService,
    public reportService: ReportService,
    public jobService: JobService
  ) { }

  ngOnInit(): void {
    this.createNew = {
      name: this.csvSection + " CSV download",
      job_type: "report_generation",
      schedule: {
        crontab: {},
        one_off: true,
      },
      job_config: {
        template: {
          allowed_formats: ["excel"],
          id: "2b5b197f-da0d-4ea4-829b-b25e5348a179",
          key: "asset_inventory",
          name: "Asset Inventory",
          sections: [{ "key": this.csvSection, "selected": true }],
          require_datetime: false
        },
        frequency: null,
        duration: {
          fromDate: null,
          toDate: null,
        },
        primary_domain: null,
        port_state: 'all',
        generate_report_summary : false,
        scope : this.scopeChangeFilter?.['scope'],
        filters: this.dataFilter
      },
    };
  }
  ngOnChanges(changes: SimpleChanges) {
    const changeFilters: SimpleChange = changes.scopeChangeFilter
    if (changeFilters && this.createNew && this.dataFilter != null) {
      this.createNew.job_config['filters'] = this.dataFilter
    }
    if (changeFilters && this.createNew && this.scopeChangeFilter != null) {
      this.createNew.job_config['scope']= this.scopeChangeFilter
    } else if (changeFilters && this.createNew && (this.scopeChangeFilter == null || this.dataFilter== null)){
      delete this.createNew.job_config['scope']

    } 
    
  }
  getCsv() {

    if (this.commonService.reportDownloadState()) {
      this.csvLoader = true
      // console.log(this.csvSection,this.csvSection);
      this.createNew.job_config.template.sections = [{ "key": this.csvSection, "selected": true }]
      this.createNew.job_config['filters'] = this.dataFilter
      this.jobService
        .createNewReoprtSchedule(this.createNew)
        .subscribe(
          (data) => {
            this.ReportJobId = data['id']
            if (this.ReportJobId) {
              this.asyncTrigger.emit({
                id:this.ReportJobId,
                type:'download',
                messages:{
                  running:'Generating CSV for '+this.csvSection,
                  completed:'CSV generated for '+this.csvSection,
                  send_to_background:"It's taking longer than expected, will continue in background.",
                  error:'Something went wrong'
                }
              });
              this.generateCsv();
            } else {
              alert("Error while downloading CSV")
            }
          }, (error) => {
            alert("Error while downloading CSV")
            this.csvLoader = false
          }
        );
    } else {
      alert("Reports download is disabled for this account. Please write to us at contact@firecompass.com.")
    }
  }
  generateCsv() {
    let waitingTime = 30
    let poolingCount = 1;
    const poolingInterval = setInterval(() => {
      this.reportService.generateLeakedCredCsv(this.ReportJobId).subscribe((data: any) => {
        poolingCount = poolingCount + 1
        if (data && data.results && data.results.length || poolingCount > waitingTime) {
          if (data.results[0]?.report_status == 'completed') {
            this.csvLoader = false
            window.open(data.results[0].files[0]['url'], '_blank');
            clearInterval(poolingInterval);
          } else if (data.results[0]?.report_status == 'failed') {
            this.csvLoader = false
            alert("Error while downloading CSV")
            clearInterval(poolingInterval);
          } else if (data.results[0]?.report_status == 'running' && poolingCount > waitingTime) {
            alert("Error while downloading CSV")
            this.csvLoader = false
            clearInterval(poolingInterval);
          }

        }
        if (!data?.results?.length && poolingCount > waitingTime) {
          alert("Error while downloading CSV")
          this.csvLoader = false
          clearInterval(poolingInterval);
        }
      }, (error) => {
        alert("Error while downloading CSV");
        this.csvLoader = false
        clearInterval(poolingInterval);
      }
      )
    }, 10000)
  }

}
