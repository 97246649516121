import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../views/pages/auth.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./full-layout.component.html",
})
export class FullLayout implements OnInit {
  paid: Boolean = true
  showBanner: Boolean = true
  constructor(public authService: AuthService) {
    this.authService.getLastestToken();
  }
  // public disabled = false;
  // public status: {isopen: boolean} = {isopen: false};
  //
  // public toggled(open: boolean): void {
  //   console.log('Dropdown is now: ', open);
  // }
  //
  // public toggleDropdown($event: MouseEvent): void {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   this.status.isopen = !this.status.isopen;
  // }

  ngOnInit() {
    const profile = JSON.parse(localStorage.getItem('profile'));
    this.paid = profile.paid
  }
}
