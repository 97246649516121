import { Injectable } from '@angular/core';


// Convert atomic steps to atomic runbook
@Injectable({
  providedIn: 'root'
})
export class RbToNgxGraphFormatService {

  public dummyStartNode = {
    "id": "dndnode_qpqjzr",
    "type": "custom_node",
    "position": {
      "x": 20,
      "y": 50
    },
    "data": {
      "title": "Start",
      'phase':["Core"],
      "defaults": {
        "color": "#fcfcfc",
        "backgroundColor": "#263238"
      },
      "handle": [
        {
          "type": "source",
          "position": "right",
          "id": "Source",
          "style": {
            "background": "#555",
            "borderRadius": 0
          }
        }
      ]
    }
  }
  public dummyEndNode = {
    "id": "dndnode_0dusz7",
    "type": "custom_node",
    "position": {
      "x": 640,
      "y": 50
    },
    "data": {
      "title": "End",
      'phase':["Core"],
      "defaults": {
        "color": "#fcfcfc",
        "backgroundColor": "#263238"
      },
      "handle": [
        {
          "type": "target",
          "position": "left",
          "id": "Target",
          "style": {
            "background": "#555",
            "borderRadius": 0
          }
        }
      ]
    }
  }
  constructor() { }

  createRunflowForAtomicRB(runbook) {
    let startNode = {...this.dummyStartNode};
    startNode.id = 'atomicnode_'+ Math.random().toString(36).substring(7)
    startNode.type = 'atomic_node'
    let endNode = {...this.dummyEndNode}
    endNode.id = 'atomicnode_'+ Math.random().toString(36).substring(7)
    endNode.type = 'atomic_node'
    let runbookOptions;
    try {
      runbookOptions = JSON.parse(runbook.fields.RunStepOptions);
    } catch (e) {
      runbookOptions = {};
    }
    for (let key in runbookOptions) {
      runbookOptions[key] = {...runbookOptions[key], value: "{{runoptions."+key+"}}"}
    }
    const extraRunOptions = {
      module_name: {"value": "{{runoptions.module_name}}", 'hideToUser': true},
      module_title: {"value": "{{runoptions.module_title}}", 'hideToUser': true}, //"UnrealIRCD 3.2.8.1 Backdoor Command Execution",
      module_description: {"value": "{{runoptions.module_description}}", 'hideToUser': true}, //"UnrealIRCD 3.2.8.1 Backdoor Command Execution",
      module_type: {"value": "exploit", 'hideToUser': true}
    }
    runbookOptions = {...runbookOptions, ...extraRunOptions}
    let atomicNode = {
      id: "atomicnode_"+ Math.random().toString(36).substring(7),
      type: 'atomic_node',
      data: {
        deepcclustername: ['attack'], // ['metasploite'], // TODO change this to deepccluster name
        modules: [{
          name: 'mod_metasploit',//runbook.fields ? runbook.fields.Name : 'Not defined',
          category: runbook.fields ? runbook.fields.Category : null,
          consumes: runbook.fields ? runbook.fields.Consumes || runbook.fields.Targets : null,
          framework: runbook.fields ? runbook.fields.Framework : null,
          tags: runbook.fields ? runbook.fields.Tags: null,
          title: runbook.fields ? runbook.fields.Title : null,
          tool: runbook.fields ? runbook.fields.Tool : null,
          created_date: runbook.fields ? runbook.fields['Creation Date'] : null,
          description: runbook.fields ? runbook.fields.Description : null,
          module_source_path: runbook.fields ? runbook.fields['Module Source Path']: null,
          options: runbookOptions,
          payloads: runbook.fields ? runbook.fields.Payloads : null,
          platform: runbook.fields ? runbook.fields.Platform : null,
          references: runbook.fields ? runbook.fields.References : null,
          targets: runbook.fields ? runbook.fields.Targets : null,
          ...runbook.fields}],
        name: runbook.fields.Title || runbook.fields.Name,
        phase: runbook.fields ? [runbook.fields.Category] : null,
        runstepoptions: {MAX_DEPTH: 40, MAX_TARGETS: 1},
        status: 'Active',
        title: runbook.fields.Title || runbook.fields.Name,
        defaults: {
          backgroundColor: "#1976D2",
          color: "#FFFFFF"
        },
        label: runbook.fields.Title || runbook.fields.Name
      }
    }
    let edge1 = {
      "source": startNode.id,
      "sourceHandle": "NA",
      "target": atomicNode.id,
      "targetHandle": "NA",
      "animated": false,
      "type": "smoothstep",
      "arrowHeadType": "arrowclosed",
      "id": startNode.id+atomicNode.id
    }

    let edge2 = {
      "source": atomicNode.id,
      "sourceHandle": "NA",
      "target": endNode.id,
      "targetHandle": "NA",
      "animated": false,
      "type": "smoothstep",
      "arrowHeadType": "arrowclosed",
      "id": atomicNode.id+endNode.id
    }
    let runflow = {
      'name': runbook.fields ? runbook.fields.Title: runbook.fields.Title,
      "elements": [startNode, endNode, atomicNode, edge1, edge2]
    }
    return [runflow]
  }

  getElementLabel(element) {
    if (element.data) {
      if (element.data.title) return element.data.title
      else if (element.data.fields && element.data.fields.title) return element.data.fields.title
    }
    return "Not defined"
  }
  convertElementsToNodesAndEdges(runbooksToUpdate) {
    runbooksToUpdate.forEach(runbook => {
      if (!runbook.runflow) runbook.runflow = this.createRunflowForAtomicRB(runbook)
      let runflows = runbook.runflow || []
      if (runflows && runflows.length) {
        runflows.forEach(runflow => {
          let nodes = []
          let edges = []
          runflow.elements.forEach(element => {
            if (element && element.source && element.target) {
              edges.push(element);
            } else {
              nodes.push({ "label": this.getElementLabel(element), ...element , ...element.data.defaults, phase: ['Exploit']});
            }
            runflow['nodes'] = nodes;
            runflow['edges'] = edges;
          });
        });
      }
    })
  }
}
