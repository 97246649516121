import { Pipe, PipeTransform } from '@angular/core';
import * as validator from 'validator';

@Pipe({
	name: 'validateEmail'
})
export class ValidEmailPipe implements PipeTransform {
	constructor() { }
	transform(email: string) {
		if (email != null) {
			return validator.isEmail(email);
		}
	}
}