// Network Vulnrability
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from '../../../_services';
import { ToastrService } from "ngx-toastr";
import { CommonService } from '../../../_services/common.service';

@Component({
  selector: 'app-add-vulnerability',
  templateUrl: './add-vulnerability.component.html',
  styleUrls: ['./add-vulnerability.component.scss']
})
export class AddVulnerabilityComponent implements OnInit {

  @Output() filterByUpdatedAt: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedScope:any = null;
  domain:string = localStorage.getItem("mydomain");
  isSubmiting:boolean = false;
  isSearchingIp:boolean = false;
  isSearchingCve:boolean = false;
  ipExist = 0;
  addNewVulnerability:any = {
      'target':"",
      'service_id':"",
      'vul_id':"",
      'cve_id':"",
      'cve_summary':"",
      'tags':"",
      'cve_score':0.0,
      'state':"open",
      'comment':"",
      'remediation':"",
      // 'discovered_at':""
  }
  stateCommentTitle = {
    'open':'',
    'resolved':'Add closure text',
    'ready_to_retest':'Add retest text',
    'closed':'Add comment'
  }
  availableCveRecord:any = [];
  tempTag:string = "";
  allTags:any = ["MANUAL"];

  portsFound:any = [];
  allCve:any = [];
  cves:any=[];
  ipSearching:boolean = false;
  searched:boolean = false;

  constructor(
    public modalService: BsModalService,
		public modalRef: BsModalRef,
    public portfolioSurveyService: PortfolioSurveyService,
    private toastr: ToastrService,
    public commonService:CommonService
  ) { 
    
  }

  ngOnInit(): void {
  }

  showVulneraBilityModal(template){
    this.resetAllFields();
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  appendTag(){
    if(this.tempTag){
      let checkCommal = this.tempTag.trim().split(',').filter(el => {
        el = el.trim(); return el != null && el != '' && el != ' ';
      });
      if(checkCommal.length>0){
        checkCommal = checkCommal.map((x)=>{ if(x) return x.trim().toUpperCase(); });
        this.allTags.push(...checkCommal);
        this.allTags = this.getUniqueValuesWithCase(this.allTags,false);
        this.addNewVulnerability.tags = this.allTags.join(",");
      }else{
        alert('Please enter one or multiple tags using comma "," as a separator.');
      }
      this.tempTag = '';
    }
  }
  getUniqueValuesWithCase(arr, caseSensitive){
    let temp = [];
    return [...new Set(caseSensitive ? arr : arr.filter(x => {
        let _x = typeof x === 'string' ? x.toLowerCase() : x;
        if(temp.indexOf(_x) === -1){
            temp.push(_x)
            return x;
        }
    }))];
  }
  removeTag(array,value){
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
      this.addNewVulnerability.tags = this.allTags.join(",");
    }
  }
  checkSpace(e){
    let text = e;
    return text.replace(/\s+/, "");
  }

  search_ip() {
    this.isSearchingIp = true;
    let targetIp = this.addNewVulnerability.target;
		let params = {
			searchKey: targetIp.trim()
		}
    if(this.selectedScope) params['scope'] = this.selectedScope;
    this.ipSearching = true;
    this.searched = true;
    this.cves = [];
    this.ipExist = 0;
		// this.portfolioSurveyService.getIpsDetailsPaginated(this.domain, params).subscribe(
    this.portfolioSurveyService.getIpsVulDetailsPaginated(this.domain, params).subscribe(
			(data:any) => {
        this.isSearchingIp = false;
        this.ipExist = data?.data.length;
				if(data?.data && data?.data.length && data?.data[0].services){
          this.portsFound = data?.data[0].services;
          this.allCve = data?.data[0].cves;
        }else{
          this.portsFound = [];
          this.cves = [];
          this.addNewVulnerability.port = "";
        }
        this.ipSearching = false;
			},
			(error) => {
        this.isSearchingIp = false;
        this.ipSearching = false;
				console.log(error);
        this.portsFound = [];
        this.cves = [];
        this.addNewVulnerability.port = "";
			}
		);
	}
  setCves(){
    this.cves = [];
    if(this.addNewVulnerability.service_id){
      let cves = this.allCve.filter(item => item.service_id==this.addNewVulnerability.service_id);
      // console.log(cves);
      if(cves.length>0){
        cves.map((x)=>{
          this.cves.push(x.cve_id);
        })
      }
    }
  }
  checkUniqueId(){
    this.addNewVulnerability.cve_id = this.addNewVulnerability.cve_id.trim()
    return this.cves.includes(this.addNewVulnerability.cve_id);
  }
  checkComment(){
    if(this.addNewVulnerability.state!="open"){
      if(this.checkSpace(this.addNewVulnerability.comment)){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  }
  submitNewVulnerability(){
    this.isSubmiting = true;
    this.addNewVulnerability.tags = (this.allTags.length==1 && this.allTags[0]=="No tag available")?"":this.allTags.join(",");
    this.portfolioSurveyService.addVulnerebility("service",this.addNewVulnerability, this.domain).subscribe(
			(data:any) => {
        this.isSubmiting = false;
        if(data.status=="pass"){
          if(data.message){
            this.toastr.success(data.message);
            this.modalRef.hide();
            this.filterByUpdatedAt.emit('update');
            setTimeout( () => {
              this.resetAllFields();
            },500);
          }
        }else{
          console.log(data);
          this.toastr.info("Unable to add Vulnerability.");
        }
			},
			(error) => {
        this.isSubmiting = false;
        console.log(error);
        this.toastr.error("Something went wrong.");
			}
		);
  }
  search_cve(){
    this.isSearchingCve = true;
    this.cveReset();
    // this.portfolioSurveyService.searchCveId("service",this.addNewVulnerability['cve_id'], this.domain).subscribe(
    this.portfolioSurveyService.searchCveIdV2("service",this.addNewVulnerability['cve_id'], this.domain).subscribe(
			(data:any) => {
        this.isSearchingCve = false;
        console.log(data);
        
        if(data.status=="pass"){
          if(data['data'].length>0){
            this.availableCveRecord = data.data;
          }else{
            this.toastr.info('No existing CVE found, proceed to create new one.')
            this.availableCveRecord = [];
          }
        }else{
          this.isSearchingCve = false;
          this.availableCveRecord = [];
        }
			},
			(error) => {
        console.log(error);
        this.toastr.error("Something went wrong.");
			}
		);
  }
  resetAllFields(){
    this.addNewVulnerability = {
      'target':"",
      'service_id':"",
      'vul_id':"",
      'cve_id':"",
      'cve_summary':"",
      'tags':"",
      'cve_score':0.0,
      'state':"open",
      'comment':"",
      'remediation':"",
      // 'discovered_at':""
    }
    this.tempTag = "";
    this.allTags = ["MANUAL"];
    this.portsFound = [];
    this.cves = [];
    this.ipSearching = false;
    this.searched = false;
    this.availableCveRecord = [];
  }
  getSummary($e){
    // console.log($e);
    
    if(!this.addNewVulnerability.vul_id){
      this.cveReset();
      return;
    }
    
    if(this.availableCveRecord.length>0){
      this.availableCveRecord.map((x)=>{
        console.log(x);
        if(x.id==this.addNewVulnerability.vul_id){
          this.addNewVulnerability.cve_summary = x.cve_summary;
          // this.allTags = (x.tags)?x.tags:['No tag available'];
          this.addNewVulnerability.cve_score = x.severity || x.cve_score;
        }
      })
    }
  }
  cveReset(){
    // console.log('cveReset');
    this.addNewVulnerability.cve_summary = '';
    // this.allTags = ["MANUAL"];
    this.addNewVulnerability.cve_score = 0.0;
    this.addNewVulnerability.vul_id = '';
  }
  strTrim(str=''){
    return str.trim();
  }
}