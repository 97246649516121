import { Component, OnInit, Input } from '@angular/core';
import { technology_base_url } from '../../_services/common-var.service';

@Component({
  selector: 'app-technology-enumeration',
  templateUrl: './technology-enumeration.component.html',
  styleUrls: ['./technology-enumeration.component.scss']
})
export class TechnologyEnumerationComponent implements OnInit {

  public showThis = 'applications';
  @Input() technologies: any;
  @Input() showVulnerablities: false;
  public technology_base_url = technology_base_url;

  constructor() { }

  ngOnInit() {
    console.log("technologies...", this.technologies)
    if(this.technologies) {
      console.log(this.technologies)
    }
  }

  lowCve(cv) {
    if (cv.cve_score >= 0 && cv.cve_score < 4) {
      return true;
    }
    return false;
  }

  mediumCve(cv) {
    if (cv.cve_score >= 4 && cv.cve_score < 7) {
      return true;
    }
    return false;
  }

  highCve(cv) {
    if (cv.cve_score >= 7 && cv.cve_score < 9) {
      return true;
    }
    return false;
  }

  criticalCve(cv) {
    if (cv.cve_score >= 9 && cv.cve_score <= 10) {
      return true;
    }
    return false;
  }

  displayCve(cv) {
    if (this.technologies && this.technologies.type) {
      if (this.technologies.type == 'total') {
        return true;
      } else if (this.technologies.type == 'low') {
        return this.lowCve(cv);
      } else if (this.technologies.type == 'medium') {
        return this.mediumCve(cv);
      } else if (this.technologies.type == 'high') {
        return this.highCve(cv);
      } else if (this.technologies.type == 'critical') {
        return this.criticalCve(cv);
      } else {
        return false;
      }
    } else {
      return true
    }
  }
  checkCveType(application) {
    if (application['cveDetail'] && this.technologies && this.technologies.type) {
      var cveDetails = application['cveDetail'] ? application['cveDetail'] : {};
      if (this.technologies.type == 'total') {
        if (cveDetails.count) {
          return true;
        }
      } else if (this.technologies.type == 'low') {
        if (cveDetails['low'] && cveDetails['low'].length) {
          return true;
        }
      } else if (this.technologies.type == 'medium') {
        if (cveDetails['medium'] && cveDetails['medium'].length) {
          return true;
        }
      } else if (this.technologies.type == 'high') {
        if (cveDetails['high'] && cveDetails['high'].length) {
          return true;
        }
      } else if (this.technologies.type == 'critical') {
        if (cveDetails['critical'] && cveDetails['critical'].length) {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
    return false;
  }

}
