import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortfolioSurveyService } from 'app/_services';


@Component({
  selector: 'app-add-top-risk',
  templateUrl: './add-top-risk.component.html',
  styleUrls: ['./add-top-risk.component.scss']
})
export class AddTopRiskComponent implements OnInit {
  @Input() modalRef: any;
	@Input() addNewTopRisk: any;
	@Input() mainDomain: any;
	@Input() addNew: boolean = false;
	@Output() newTopRisk: EventEmitter<any> = new EventEmitter<any>();
	@Input() entityType: any;
	@Output() refreshData = new EventEmitter();
  public tags = {
    "leaked content": "leaked content",
    "top risk": "top_risk",
    "cloud document": "cloud document",
    "malicious": "malicious",
    "leaked credentials": "leaked credentials",
    "vulnerability": "vulnerability",
}
	public tags_dropdown = [{
		"value": "select",
		"name": "Select"
	}, {
		"value": this.tags["leaked content"],
		"name": "Leaked Content"
	}, {
		"value": this.tags.malicious,
		"name": "Malicious"
	}, {
		"value": this.tags["leaked credentials"],
		"name": "Leaked Credentials"
	}, {
		"value": this.tags.vulnerability,
		"name": "Vulnerability"
	}, {
		"value": this.tags["top risk"],
		"name": "Top Risk"
	}]

	public showSpinnerAdd;
	public sourceStatus = false;
	public showMessage = false;
	public showSourceLoader = false;
	public infoFlag ;

	constructor(public portfolioSurveyService: PortfolioSurveyService) { }

	ngOnInit() {
		this.infoFlag = localStorage.getItem('info_vul_config')
	}
	submitRisk() {
		this.portfolioSurveyService.addTopRisk(this.addNewTopRisk,this.entityType).subscribe(data => {
			if(data){
				alert(data['message'])
				this.modalRef.hide()
			}
		});
	}

	

	addSource(sourceId) {
 		if (sourceId.value) {
			let sourceValue = sourceId.value
			if(this.addNewTopRisk['sources'].indexOf(sourceValue) == -1){
				this.showSourceLoader=true
				this.portfolioSurveyService.validateTopRiskSource(this.mainDomain,this.entityType,sourceValue).subscribe(data => {
					if(data){
						this.showMessage=true
						this.sourceStatus = data['source_status']
						this.showSourceLoader=false

						if(this.sourceStatus){
							this.addNewTopRisk['sources'].push(sourceValue.toLowerCase());
		
						}

					}

				});
			}

		 }
	}

	validateWhiteSpace(data,type){
		if(type=="description"){
			data.description=data.description.trim()
		}
		else if(type=="remediation"){
		data.remediation = data.remediation.trim()
		}
	}
	removeSource(source, i) {
		this.addNewTopRisk['sources'].splice(i, 1);
		// this.addNewTopRisk['source'] = this.addNewTopRisk['sourceList'].join(' ,');
	}

	addTags(tag) {
		if (tag.length && tag.toLowerCase() != 'select') {
			if (this.addNewTopRisk['tags'].indexOf(tag) == -1) {
				this.addNewTopRisk['tags'].push(tag.toLowerCase());
			}
		}
	}

	removeTags(tag, i) {
		if (tag != this.tags["top risk"]) {
			this.addNewTopRisk['tags'].splice(i, 1);
		}
	}
}
