import { Pipe, PipeTransform } from '@angular/core';
import { ValidIpPipe } from './validate-ip.pipe';
import { ValidDomainPipe } from './valid-domain.pipe';
import { ValidateNetblockPipe } from './validate-netblok.pipe'


@Pipe({
	name: 'validateInternetName'
})
export class ValidateInternetNamePipe implements PipeTransform {

	constructor() { }

	transform(str: string) {
		if (str != null) {
			let validIpPipe = new ValidIpPipe().transform(str);
			let validdomainPipe = new ValidDomainPipe().transform(str)
			let validCidrPipe = new ValidateNetblockPipe().transform(str)
			if (validIpPipe || validdomainPipe || validCidrPipe) {
				return true
			} else return false
		}

	}
}