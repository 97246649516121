import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { CommonService } from "../../_services/common.service";

@Component({
  selector: "app-footprint",
  templateUrl: "./footprint.component.html",
  styleUrls: ["./footprint.component.scss"],
})
export class FootprintComponent implements OnInit {
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public route: ActivatedRoute,
    public router: Router,
    public commonService: CommonService
  ) {}
  public showThis = "domains";
  public mainDomain: any;
  public noPremission: boolean = false;
  public cveDetails: any;
  public startDay: number = 365;
  public filters = {};
  public automatedDone: boolean = false;
  public openSlider: boolean = false;
  public services;
  public totalLeakCredentialsCount = -1;
  public totalLeakCredentialsCountOld;
  public domainsLeakedCredentials: any;
  public phishingDomains;
  public uploadedIps;
  public domains;
  public domain;
  public thirdPartyData: any = [];
  // public relatedDomainsHostsOld: any;
  public relatedDomainsLeakCredentialsOld: any;
  public uploadedIpsOld: any;
  public servicesOld: any;
  public phishingDomainsOld: any;
  public relatedDomainsCount: number = -1;
  public relatedDomainsCountOld: number;
  public subDomainsCount: number = -1;
  public subDomainsCountOld: number;
  public servicesCount: number = -1;
  public servicesCountOld: number;
  public ipsCount: number = -1;
  public ipsCountOld: number;
  public subDomains;
  public subDomainsOld;
  public total_cve_count;
  public whoisDetailsForRds: any;
  public ips_with_vul_mal: any;
  public ips_with_vul_mal_old: any;
  public isUserTrial: boolean = false;
  public webApplication: any;
  public mobileApplication: any;
  public netblocks: any;
  public webApplicationOld: any;
  public mobileApplicationOld: any;
  public netblocksOld: any = [];
  public netblocks_count: number = -1;
  public netblocks_countOld: number = 0;
  public appCount: number = -1;
  // public mobileAppCount: number = 0;
  public showSpinnerDomain: boolean = false;
  public technologies;
  public loadingPhishing = false;
  public phishingCount: number = -1;
  public phishingCountOld: number;
  public technologyVulnerabilties = [];
  public technologyCount: number;
  public technologyCountOld: number;
  public technologyVulnerabiltiesOld;
  public newPortList: any = [];
  public apiDiscoveryCount = null;
  public paramView = null;
  public alreadyDisplayed = new Set()
  ngOnInit() {
    this.getDomainName();
    this.getRelatedDomainsLeakCredentials();
    this.getmobileapplication();
    this.getTechnology();
  }

  public getDomainName() {
    this.route.queryParams.subscribe((params) => {
      if (params && params["view"]) {
        this.showThis = params["view"];
        this.paramView = params["view"];
      }
      if (params && params["domain"]) {
        this.domain = params["domain"];
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { domain: this.domain },
          queryParamsHandling: "merge",
          skipLocationChange: true,
        });
        if (this.domain) {
          this.portfolioSurveyService
            .getMainDomainDetails(this.domain)
            .subscribe(
              (data) => {
                this.mainDomain = data["data"];
                if (this.mainDomain) {
                  this.getAllData();
                  localStorage.setItem('scope_config', this.mainDomain.scope_config)
                  localStorage.setItem('info_vul_config', this.mainDomain.info_vul_config)
                }
              },
              (error) => {
                this.errorForDomain(error);
              }
            );
        }
      } else {
        this.portfolioSurveyService.getDomainsSearchForUser().subscribe(
          (data: any) => {
            data.filter((d) => {
              if (d["domain_type"] == "self") {
                this.domain = d["domain"];
              }
            });
            if (this.domain) {
              this.portfolioSurveyService
                .getMainDomainDetails(this.domain)
                .subscribe((data) => {
                  this.mainDomain = data["data"];
                  if (this.mainDomain) {
                    this.getAllData();
                    console.log("this.domain", this.mainDomain)
                    localStorage.setItem('scope_config', this.mainDomain.scope_config)
                  }
                });
            }
          },
          (error) => {
            this.errorForDomain(error);
          }
        );
      }
    });
  }
  public getAllData() {
    if (this.domain) {
      switch (this.paramView){
        case 'domain':{
          this.getRelatedDomainCount();
          break;
        }
        case 'ips':{
          this.getipsCount();
          break;
        }
        case 'sub-domains':{
          this.getSubdomainCount();
          break;
        }
        case 'netblocks':{
          this.getnetblocksCount();
          break;
        }
        case 'service':{
          this.getOpenPortsCount();
          break;
        }
        case 'service':{
          this.getOpenPortsCount();
          break;
        }
        case 'application':{
          this.getmobileapplication();
          this.getApplicationsCount();          
          break;
        }
        case 'leaked-credentials':{
          this.getLeakedCredCount();
          this.getRelatedDomainsLeakCredentials();
          break;
        }
        case 'api-discovery':{
          this.getApiDiscoveryCount();
          break;
        }
        // default:{                    
        //   this.getTechnology();          
        // }
      }
      // this.getPhishingCount();
      // this.getRelatedDomainWhois();
      // this.getCveDetailsForDomains();
      // this.getIps();
      // this.getIpsDetailsFromDeepc();
      // this.getService();
      // this.getAutomatedData();
      
      // this.getNetblocks();
      // this.getSubDomain();
      // this.getTechnologyVulnerability();      
      // this.getRelatedDomainsSubDomains();
      // this.getPhishing();
      //this.getwebapplication();

    } else {
      alert(
        "No domain associated with you, Please write us on contact@firecompass.com"
      );
    }
  }

  setUserAccount(userAccount) {
    if (userAccount && userAccount == "trial") {
      this.isUserTrial = true;
    }
  }

  getnetblocksCount() {
    this.portfolioSurveyService.getNetblocksCount(this.domain, this.filters).subscribe(
      (data) => {
        if (data) {
          this.netblocks_count = data["count"];
          this.netblocks_countOld = data["count"];
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }
  getipsCount() {
    this.portfolioSurveyService.getipsCount(this.domain, this.filters).subscribe(
      (data) => {
        if (data) {
          this.ipsCount = data["count"];
          this.ipsCountOld = data["count"];
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }
  getTechnology() {
    this.portfolioSurveyService.getTechnology(this.domain).subscribe(
      (data) => {
        if (data) {
          this.technologies = data["data"];
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }
  getSubDomain() {
    this.portfolioSurveyService.getSubDomain(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.subDomains = data["data"].results;
          // this.subDomainsCountOld = data['data'].count
          this.commonService.calculateCreatedAndUpdated(this.subDomains);
          this.subDomainsOld = this.commonService.getJsonCopy(this.subDomains);
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getwebapplication() {
    this.portfolioSurveyService.getwebapplication(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.webApplication = data["data"];
          // this.appCount = this.appCount + this.webApplication.length;
          this.commonService.calculateCreatedAndUpdated(this.webApplication);
          this.webApplicationOld = this.webApplication.slice();
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getmobileapplication() {
    this.portfolioSurveyService.getmobileapplication(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.mobileApplication = data["data"];
          // this.appCount = this.appCount + this.mobileApplication.length;
          this.commonService.calculateCreatedAndUpdated(this.mobileApplication);
          this.mobileApplicationOld = this.mobileApplication.slice();
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  // getIpsDetailsFromDeepc() {
  //   this.portfolioSurveyService.getIpsDetailsFromDeepc(this.domain).subscribe(
  //     (data) => {
  //       if (data) {
  //         this.setUserAccount(data["userAccount"]);
  //         this.ips_with_vul_mal = data["data"];
  //         this.commonService.calculateCreatedAndUpdated(this.ips_with_vul_mal);
  //         this.ips_with_vul_mal_old = this.ips_with_vul_mal.slice();
  //       } else {
  //         // this.getIps();
  //       }
  //     },
  //     (error) => {
  //       this.errorForDomain(error);
  //     }
  //   );
  // }

  getPhishing() {
    this.loadingPhishing = true;
    this.portfolioSurveyService.getPhishingDomains(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.loadingPhishing = false;
          this.phishingDomains = data["data"];
          this.phishingDomainsOld = this.phishingDomains.slice();
          this.commonService.calculateCreatedAndUpdated(this.phishingDomains);
          // this.phishingCountOld = this.phishingCount
        }
      },
      (error) => {
        this.loadingPhishing = false;
        this.errorForDomain(error);
      }
    );
  }

  getRelatedDomainsLeakCredentials() {
    const params = {
       ...this.filters
    }
    this.portfolioSurveyService
      .getRelatedDomainsLeakCredentials(this.domain, params)
      .subscribe(
        (data: any) => {
          if (data) {
            if (data["leak_credentials"]) {
              this.setUserAccount(data["userAccount"]);
              // this.totalLeakCredentialsCount = data['leak_credentials']['total_leaked_credentials'] ? data['leak_credentials']['total_leaked_credentials'] : 0;
              this.domainsLeakedCredentials = data["leak_credentials"]["data"];
              // this.mainDomain = data.domain;
              if (
                data["leak_credentials"]["status"] == "fail" ||
                !this.totalLeakCredentialsCount
              ) {
                this.getAutomatedData();
              } else {
                this.domainsLeakedCredentials.forEach((element) => {
                  this.commonService.checkCreatedAndUpdated(element);
                });
                this.relatedDomainsLeakCredentialsOld = this.domainsLeakedCredentials.slice();
                this.totalLeakCredentialsCountOld = this.totalLeakCredentialsCount;
              }
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  getWhoIsForDomainsWithArray(domainsArray) {
    this.portfolioSurveyService
      .getWhoisDetailsForPagination(this.domain, domainsArray)
      .subscribe((data) => {
        this.whoisDetailsForRds = data["data"];
      });
  }
  getRelatedDomainsSubDomains() {
    this.portfolioSurveyService
      .getRelatedDomainsSubDomainsWithFilters(this.domain)
      .subscribe(
        (data: any) => {
          if (data && data["status"] == "pass") {
            this.setUserAccount(data["userAccount"]);
            this.domains = data.data.results;
            let domainsArray = [];
            this.domains.forEach((d) => {
              domainsArray.push(d.domain);
            });
            this.getWhoIsForDomainsWithArray(domainsArray);
            // this.relatedDomainsCountOld = data.related_domains['related_domains_count'] ? data.related_domains['related_domains_count'] : this.domains.length;
            // this.mainDomain = data.domain['data'];
            if (
              data["status"] == "fail" ||
              !this.domains ||
              !this.domains.length
            ) {
              this.getAutomatedData();
            } else {
              this.domains.forEach((element) => {
                this.commonService.checkCreatedAndUpdated(element);
                this.commonService.calculateCreatedAndUpdated(element.hosts);
              });
            }
          } else {
            if (data && data["message"] == "Failure while getDomainByNameDb") {
              this.portfolioSurveyService
                .startScanForDomain(this.domain)
                .subscribe((data) => {
                  if (data["status"] == "pass") {
                    alert(
                      "Scan started for new domain or scan is under progress.. Please re-visit after some time."
                    );
                  } else {
                    alert(data["message"]);
                  }
                  this.router.navigate(["/new/self-domains"]);
                });
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  // getIps() {
  //   this.portfolioSurveyService.getIps(this.domain).subscribe(
  //     (data: any) => {
  //       if (data) {
  //         this.setUserAccount(data["userAccount"]);
  //         this.uploadedIps = data["ips"]["data"];
  //         // this.mainDomain = data.domain['data'];
  //         if (data["ips"]["status"] == "fail" || !this.uploadedIps.length) {
  //           this.getAutomatedData();
  //         } else {
  //           this.commonService.calculateCreatedAndUpdated(this.uploadedIps);
  //           this.uploadedIpsOld = this.uploadedIps.slice();
  //         }
  //       }
  //     },
  //     (error) => {
  //       this.errorForDomain(error);
  //     }
  //   );
  // }

  getService() {
    this.portfolioSurveyService.getService(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.services = data["data"];
          // this.servicesCount = data['count'] ? data['count'] : data['data'].length;
          // this.servicesCountOld = data['count'] ? data['count'] : data['data'].length;
          // this.mainDomain = data.domain['data'];
          if (data["status"] == "fail" || !this.services.length) {
            this.getAutomatedData();
          } else {
            this.commonService.calculateCreatedAndUpdated(this.services);
            this.servicesOld = this.services.slice();
          }
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }
  getAutomatedData() {
    // if (!this.automatedDone) {
    this.automatedDone = true;
    //   this.portfolioSurveyService.getAutomatedDataForDomain(this.domain).subscribe(data => {
    //     if (data) {
    //       console.log(data);
    //     }
    //   })
    // }
  }
  getNetblocks() {
    this.portfolioSurveyService.getNetblocksPaginated(this.domain).subscribe(
      (data) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.netblocks = data["data"].results;
          // this.netblocks_count = data['netblock_count'];
          // this.netblocks_countOld = data['netblock_count'];
          this.commonService.calculateCreatedAndUpdated(this.netblocks);
          this.netblocksOld = this.netblocks.slice();
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  errorForDomain(error) {
    console.log("..... Error ......", error.status);
    if (error.status == 403) {
      this.noPremission = true;
      alert(
        "Forbidden! You are not allowed to perform this action. Please contact us at contact@firecompass.com"
      );
      this.router.navigate(["/new/self-domains"]);
    }
  }

  getCveDetailsForDomains() {
    this.portfolioSurveyService
      .getTechnologyVulnerability(this.domain)
      .subscribe((data) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.cveDetails = data["data"];
          this.total_cve_count = data["total_cve_count"];
        }
      });
  }

  filterDataOnDay(value) {
    this.domains = [];
    if (value == null) {
      // this.filterRdsHosts();
      this.filterFootprint();
      this.domainsLeakedCredentials = [];
      this.totalLeakCredentialsCount = 0;
      const todayDate = new Date();
      this.filters = {
        ...this.filters,
        ...{
          fromDate: new Date(
            todayDate.getTime() - this.startDay * 24 * 3600 * 1000
          ).toISOString(),
          toDate: new Date(todayDate.setDate(todayDate.getDate() + 1)).toISOString(),
          page: 1
        },
      };
      this.getSubdomainCount();
      this.getApiDiscoveryCount();
      this.getRelatedDomainCount();
      this.getApplicationsCount();
      this.getipsCount();
      this.getOpenPortsCount()
      this.getnetblocksCount()
      this.getLeakedCredCount()
      this.getRelatedDomainsLeakCredentials()
      // console.log(startDate)
    } else {
      delete this.filters["toDate"];
      delete this.filters["fromDate"];
      this.filters = { ...this.filters, ...{'days':'All'} };
      this.startDay = 365;
      // this.ips_with_vul_mal = JSON.parse(
      //   JSON.stringify(this.ips_with_vul_mal_old)
      // );
      // this.domains = JSON.parse(JSON.stringify(this.relatedDomainsHostsOld));
      this.getSubdomainCount();
      this.getApiDiscoveryCount();
      this.getRelatedDomainCount();
      this.getApplicationsCount();
      this.getipsCount();
      this.getOpenPortsCount()
      this.getnetblocksCount()
      this.getLeakedCredCount()
      this.getRelatedDomainsLeakCredentials()
      this.relatedDomainsCount = this.relatedDomainsCountOld;
      this.subDomainsCount = this.subDomainsCountOld;
      this.ipsCount = this.ipsCountOld;
      this.servicesCount = this.servicesCountOld;
      if (this.uploadedIpsOld) this.uploadedIps = this.uploadedIpsOld.slice();
      if (this.servicesOld) this.services = this.servicesOld.slice();
      if (this.webApplicationOld)
        this.webApplication = this.webApplicationOld.slice();
      this.appCount =
        this.webApplication.length + this.mobileApplication.length;
      if (this.mobileApplicationOld)
        this.mobileApplication = this.mobileApplicationOld.slice();
      this.appCount =
        this.webApplication.length + this.mobileApplication.length;
      if (this.netblocksOld) this.netblocks = this.netblocksOld.slice();
      this.netblocks_count = this.netblocks_countOld;
      if (this.subDomainsOld)
        this.subDomains = this.commonService.getJsonCopy(this.subDomainsOld);
      if (this.phishingDomainsOld)
        this.phishingDomains = this.phishingDomainsOld.slice();
      this.phishingCount = this.phishingCountOld;
      if (this.technologyVulnerabiltiesOld)
        this.technologyVulnerabilties = this.technologyVulnerabiltiesOld.slice();
      this.technologyCount = this.technologyCountOld;
      if (this.relatedDomainsLeakCredentialsOld)
        this.domainsLeakedCredentials = this.relatedDomainsLeakCredentialsOld.slice();
      this.totalLeakCredentialsCount = this.totalLeakCredentialsCountOld
        ? this.totalLeakCredentialsCountOld
        : 0;
    }
  }

  filterFootprint() {
    this.uploadedIps = this.filterByNoOfDays(
      this.uploadedIps,
      this.uploadedIpsOld
    );
    this.ips_with_vul_mal = this.filterByNoOfDays(
      this.ips_with_vul_mal,
      this.ips_with_vul_mal_old
    );
    this.ipsCount = this.ips_with_vul_mal.length;
    this.services = this.filterByNoOfDays(this.services, this.servicesOld);
    this.servicesCount = this.services.length;
    this.phishingDomains = this.filterByNoOfDays(
      this.phishingDomains,
      this.phishingDomainsOld
    );
    this.phishingCount = this.phishingDomains.length;
    this.webApplication = this.filterByNoOfDays(
      this.webApplication,
      this.webApplicationOld
    );
    this.mobileApplication = this.filterByNoOfDays(
      this.mobileApplication,
      this.mobileApplicationOld
    );
    this.appCount = this.webApplication.length + this.mobileApplication.length;
    this.netblocks = this.filterByNoOfDays(this.netblocks, this.netblocksOld);
    this.netblocks_count = this.netblocks.length;
    this.subDomains = this.filterByNoOfDays(
      this.subDomains,
      this.subDomainsOld
    );
    this.technologyVulnerabilties = this.filterByNoOfDays(
      this.technologyVulnerabilties,
      this.technologyVulnerabiltiesOld
    );
    this.technologyCount = this.technologyVulnerabilties.length;
    this.subDomainsCount = this.subDomains.length;
  }

  filterByNoOfDays(domain_list, domain_list_old) {
    domain_list = [];
    if (domain_list_old) {
      domain_list_old.forEach((element) => {
        if (
          element.createdNoOfDays >= 0 &&
          element.createdNoOfDays <= this.startDay
        ) {
          domain_list.push(element);
        }
      });
    }
    return domain_list;
  }

  updateTotalCvCount(count) {
    // if (count != undefined && count != null)
    //   document.getElementById('totalCveCount').innerText = count;
  }
  getTechnologyVulnerability() {
    this.portfolioSurveyService
      .getTechnologyVulnerability(this.domain)
      .subscribe((vul) => {
        this.technologyVulnerabilties = vul["data"];
        this.technologyCount = this.technologyVulnerabilties.length
          ? this.technologyVulnerabilties.length
          : 0;
        this.technologyCountOld = this.technologyCount;
        this.commonService.calculateCreatedAndUpdated(
          this.technologyVulnerabilties
        );
        this.technologyVulnerabiltiesOld = this.technologyVulnerabilties.slice();
      });
  }

  getRelatedDomainCount() {
    this.portfolioSurveyService
      .getRelatedDomainsCount(this.domain, this.filters)
      .subscribe((data) => {
        this.relatedDomainsCount = data["count"];
        this.relatedDomainsCountOld = data["count"];
      });
  }
  getSubdomainCount() {
    this.portfolioSurveyService
      .getSubdomainCount(this.domain, this.filters)
      .subscribe((data) => {
        this.subDomainsCount = data["count"];
        this.subDomainsCountOld = data["count"];
      });
  }
  getApiDiscoveryCount() {
    this.portfolioSurveyService
      .getApiDiscoveryCount(this.domain, this.filters)
      .subscribe((data) => {
        this.apiDiscoveryCount = data["count"];
      });
  }
  getLeakedCredCount() {
    this.portfolioSurveyService
      .getLeakedCredCount(this.domain, this.filters)
      .subscribe((data) => {
        if (data["status"] == "pass") {
          if (data["leak_credentials"].total_leaked_credentials != null) {
            this.totalLeakCredentialsCount =
              data["leak_credentials"].total_leaked_credentials;
          } else this.totalLeakCredentialsCount = 0;
        } else this.totalLeakCredentialsCount = 0;
      });
  }
  getOpenPortsCount() {
    this.portfolioSurveyService.getOpenPortCount(this.domain, this.filters).subscribe(
      (data) => {
        if (data) {
          this.servicesCount = data["count"];
          this.servicesCountOld = data["count"];
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getApplicationsCount() {
    this.portfolioSurveyService
      .getApplicationCount(this.domain, this.filters)
      .subscribe(
        (data) => {
          if (data) {
            this.appCount = data["count"];
          } else {
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  getPhishingCount() {
    this.portfolioSurveyService.getPhishingCount(this.domain).subscribe(
      (data) => {
        if (data) {
          this.phishingCount = data["count"];
          this.phishingCountOld = data["count"];
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  refreshCount(e){
    console.log('refreshCount',e);
    if(e=="domain"){
      this.getRelatedDomainCount();
    }else if(e=="subdomain"){
      this.getSubdomainCount();
    }else if(e=="ip"){
      this.getipsCount();
    }else if(e=="openports"){
      this.getOpenPortsCount();
    }else if(e=="application"){
      this.getApplicationsCount();
    }else if(e=="netblock"){
      this.getnetblocksCount();
    }else if(e=="Leakedcreds"){
      this.getLeakedCredCount();
    }else if(e=="api"){
      this.getApiDiscoveryCount();
    }
  }

  checAlreadyDisplayed(entity) {
    // return 
  }
  
}