// import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { FormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { RoleGuard } from "./_guards/role-guard.service";
import { AngularFireModule } from "@angular/fire";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { REGION } from '@angular/fire/functions';
import { firebaseRegion } from "../environments/environment";


// import { FormsModule } from '@angular/forms';
import { ToastrModule } from "ngx-toastr";

// Import services
import {
  PortfolioSurveyService,
  AssessmentService,
  EcsmService,
  ExpertService,
  UploadCsvService,
  DwInsightsService,
} from "./_services";

// Import containers
import { FullLayout, SimpleLayout } from "./containers";

const APP_CONTAINERS = [FullLayout, SimpleLayout];

// Import components
import {
  AppAside,
  AppBreadcrumbs,
  AppFooter,
  AppHeader,
  AppSidebar,
} from "./components";

const APP_COMPONENTS = [
  AppAside,
  AppBreadcrumbs,
  AppFooter,
  AppHeader,
  AppSidebar,
];

// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  SIDEBAR_TOGGLE_DIRECTIVES,
} from "./directives";

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  SIDEBAR_TOGGLE_DIRECTIVES,
];

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AuthService } from "./views/pages/auth.service";
import { AuthGuardService } from "./_guards/auth-guard.service";
import { RolesService } from "./_services/roles.service";
import { CommonService } from "./_services/common.service";
import { NewviewsModule } from "./newviews/newviews.module";
import { DashboardModule } from "./views/dashboard/dashboard.module";
import { AllNotificationComponent } from "./shared/all-notification/all-notification.component";
import { ClickOutsideDirective } from "app/shared/click-outside.directive";
import { PipeModule } from "app/pipes/pipe.module";
import { MainDomainSelectionComponent } from "./shared/main-domain-selection/main-domain-selection.component";
import { GoogleChartsModule } from "angular-google-charts";
import { ChartsModule } from "ng2-charts";
import { AddHeaderInterceptor, HttpService } from "./_services/http.service";
import { createApollo } from "./_services/pelago.service";
import { ModalModule, BsModalRef } from "ngx-bootstrap/modal";
import { firebase } from "environments/environment";
import { AutomatedReportsModule } from "./automated-reports/automated-reports.module";
import { AppBannerComponent } from "./components/app-banner/app-banner.component";
import { CspdataRoutingModule } from "./cspdata/cspdata-routing.module";
import { RunbookModule } from "./runbook/runbook.module";
import { AvatarModule } from "ngx-avatar";
import { SeedconfigRoutingModule } from "./seed_config/seedconfig-routing.module";
import { SecretsModule } from "./secrets/secrets.module";
import { AttributionSeedComponent } from "./components/attribution-seed/attribution-seed.component";
import { AsyncToasterComponent } from "./async-toaster/async-toaster.component";
import { UploadEvidenceService } from "./_services/upload-evidence.service";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastrModule } from 'ngx-toastr';
@NgModule({
  imports: [
    // BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
    FormsModule,
    NewviewsModule,
    ChartsModule,
    GoogleChartsModule,
    PipeModule,
    DashboardModule,
    // Ng2GoogleChartsModule,
    AutomatedReportsModule,
    ModalModule.forRoot(),
    AngularFireModule.initializeApp(firebase),
    NgxUiLoaderModule,
    CspdataRoutingModule,
    SeedconfigRoutingModule,
    RunbookModule,
    AvatarModule,
    ToastrModule.forRoot(),
    SecretsModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    AllNotificationComponent,
    ClickOutsideDirective,
    MainDomainSelectionComponent,
    AppBannerComponent,
    AttributionSeedComponent,
    AsyncToasterComponent,
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    {provide: REGION, useValue: firebaseRegion},
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    PortfolioSurveyService,
    AssessmentService,
    EcsmService,
    CommonService,
    RoleGuard,
    AuthService,
    AuthGuardService,
    RolesService,
    HttpService,
    BsModalRef,
    ExpertService,
    UploadCsvService,
    DwInsightsService,
    UploadEvidenceService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  //   exports: [NotificationComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
