import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { PortfolioSurveyService } from "app/_services";
import { CommonService } from '../../../_services/common.service';
import { SearchPipe } from 'app/pipes/search.pipe';
import { SortPipe } from 'app/pipes/sort.pipe';

@Component({
  selector: 'app-take-over-risk',
  templateUrl: './take-over-risk.component.html',
  styleUrls: ['./take-over-risk.component.scss']
})
export class TakeOverRiskComponent implements OnInit {
  @Input() mainDomain: any;
  @Input() filters: {}
  public takeoverRisk=[];
  public allTakeOverRisk;
  public searchPipe = new SearchPipe();
  public sortPipe = new SortPipe();
  public keysToSearch = ['entity']
  public order = -1;
  public sortWith = 'takeover_risk';
  public initialSort = true;
  public sortMap = null;
  public fieldsToDownload = {
    "Domain/Subdomain": "entity",
    "Takeover Risk": "takeover_risk_name",
    "C-Name" : "c_name",
    "Updated At": "updated_at",
  };
  public loading:boolean = false;
  constructor(public portfolioSurveyService: PortfolioSurveyService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.getTakeoverRisk()
  }
  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    if(changes.mainDomain && changes.mainDomain["currentValue"] != null){
      // this.loading = true;
      this.filters = this.filters
      this.getTakeoverRisk();
    }
  }
  getTakeoverRisk() {
    this.loading = true;
    this.portfolioSurveyService
      .getTakeoverRisk(this.mainDomain['domain_name'],this.filters)
      .subscribe((data) => {
        this.loading = false;
        if (data && data['data'] && data['data'].length) {
          data['data'].forEach(d => {
            d.takeover_risk = parseFloat(d.takeover_risk);
            d.takeover_risk_name = this.confidenceLevel(d.takeover_risk)
          });
        }
        this.takeoverRisk = data && data['data'] ? data['data'] : []
        this.allTakeOverRisk = [...this.takeoverRisk]
      });
  }
  search(value) {
    this.takeoverRisk = this.searchPipe.transform(this.allTakeOverRisk, this.keysToSearch, value);
  }

  confidenceLevel(data) {
    data = parseFloat(data)
    let confidence = "Low"
    if (data) {
      if (data <= 2) {
        confidence = "Low";
        return confidence
      }
      else if (data <= 6) {
        confidence = "Medium";
        return confidence
      }
      else if (data <= 8) {
        confidence = "High";
        return confidence
      }
      else if (data <= 10) {
        confidence = "Critical";
        return "Critical"
      }
    }
    return confidence;
  }

}
