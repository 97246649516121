import { Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef } from '@angular/core';
import { PortfolioSurveyService } from '../../../../_services/portfolio-survey.service';
import { CommonService } from '../../../../../app/_services/common.service';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-webapp-assets-view',
  templateUrl: './webapp-assets-view.component.html',
  styleUrls: ['./webapp-assets-view.component.scss']
})
export class WebappAssetsViewComponent implements OnInit {

  @Output() changeViewType: EventEmitter<any> = new EventEmitter<any>();
  @Input() mainDomain: any;
  @Input() filters = {};
  @Input() totalCount = 0;
  @ViewChild("filterBlock") filterBlock;
  @Input() selectedScope:any = '';

  // filter
  public openDropdown;
  public openActionDropdown;
  public showThis = 'stateFilter';
  public webAssetVulSubjectObsIns;
  public subjectType = 'web_app_cve';
  public stateFinalFilter:any = ['open','ready_to_retest'];
  public filter_states = [
    { selected: true, key: 'open', value: 'Open' },
    { selected: true, key: 'ready_to_retest', value: 'Ready To Retest' },
    { selected: false, key: 'closed', value: 'Closed' },
    { selected: false, key: 'resolved', value: 'Resolved' },
    { selected:false, key: 'retest_in_progress', value: 'Retest In Progress' },
  ];
  public severitiesFinalFilter = [];
public filter_severities = localStorage.getItem('info_vul_config')=="true"?
[
{ selected:false, key: 'critical', value: 'Critical'},
{ selected:false, key: 'high', value: 'High'},
{ selected:false, key: 'medium', value: 'Medium' },
{ selected:false, key: 'low', value: 'Low'},
{ selected:false, key: 'info', value: 'Info' }
]:
[
{ selected:false, key: 'critical', value: 'Critical'},
{ selected:false, key: 'high', value: 'High'},
{ selected:false, key: 'medium', value: 'Medium' },
{ selected:false, key: 'low', value: 'Low'},
];
  public validatedFinalFilter = [true,false];
  public validated_flags = [
    { selected: true, key: 'Validated', value: true },
    { selected: true, key: 'Not Validated', value: false },
  ];
  public fieldsToDownload = {
		'Title': 'cve_id' ,
		'Description' : 'cve_summary' ,
		'Severity' : 'severity' ,
		'Apps (Validated)': 'applications.source_url',
		// 'Impact' : 'impact',
		'Remediation' : 'remediation',
		'References' : 'references',
		'Discovered At' : 'applications.discovered_at',
    'Last Seen At': 'applications.applicationcve_updated_at',
    'Observations': 'observation',
    'Insights': 'insight',
  }
  public allWebVulnerbities = [];
  public all_states = {
    'open': 'Open',
    'ready_to_retest': 'Ready To Retest',
    'closed': 'Closed',
    'resolved': 'Resolved',
    'retest_in_progress': 'Retest In Progress',
  };
  public stateComment = "";

  // List
  public appToShow: any = [];
  public allObsIns;
  public showSpinner: boolean = false;
  public newRequest: any = null;
  public page: number = 1;
  public limit: number = 10;
  public searchKey: any = null;
  public selectedTags;
  public selectedIsp;
  public showArchivedIps;
  public sortKey;
  public order;
  public pocSubjectComment;
  public ipTypeToFilter;
  public total_ips_count: number = 0;
  public selectAllRecord = false;
  public noOfSelectedRecord = 0;
  public noOfSelectedCve = 0;
  public confirmaModal: any = null;
  public multipleStateModal: any = null;
  public applyRetestConfirmModal:any = null;
  public currentCve: any = null;
  public manualIdArray : any = [];
  public multipleSelectedCveIdArray = [];
  public multipleCveWithManual=0
  public functionMultipleState = "open";
  public obsInsCountSpinner = false;
  public vulDetailForWebApp;
  public webAppIdArray = []
  public retestParam: any = {
    "services": {
      "service_cve_ids": [],
      "cve_ids": [],
      "ips": []
    },
    "web_apps": {
      "application_cve_ids": [],
      "cve_ids": [],
      "application_urls": []
    }
  };
  public allFilterList:any = ['State', 'Validate'];
  public downloadLoading:boolean = false;
  public cve_id_text = false
  public impactServ:any = [];
	public editImpact = false;
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
		public toastr:ToastrService
  ) {

  }

  ngOnInit(): void {
    if(this.selectedScope){
			if(!this.allFilterList.includes('Scope')) this.allFilterList.push('Scope');
		}else{
			if(this.filterBlock){
				this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Scope');
			}
		}
    this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
			this.getWebApplicationDetailsFromDeepc()
		});
  }

  getWebApplicationDetailsFromDeepc() {
    this.newRequest ? this.newRequest.unsubscribe() : null
    this.showSpinner = true;
    this.appToShow = [];
    this.webAppIdArray = []
    // this.selectedIpType = this.ipTypeToFilter
    const params = {
      page: this.page,
      limit: this.limit,
      searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
      tags: this.selectedTags && this.selectedTags.length ? this.selectedTags : null,
      asn_names: this.selectedIsp && this.selectedIsp.length ? this.selectedIsp : null,
      ...this.filters,
      archive: this.showArchivedIps,
      view : "asset_view"
    }
    if (this.sortKey) params['sortKey'] = this.order == -1 ? this.sortKey : '-' + this.sortKey
    if (this.ipTypeToFilter != 'all') {
      params['ipType'] = this.ipTypeToFilter
    } else delete params['ipType']
    let stateString = '';
		if(this.stateFinalFilter.length){
			stateString = this.joinState(this.stateFinalFilter);			
		}else{
			let allFilter:any = [];
			this.filter_states.map(x=>allFilter.push(x.key));
			stateString = this.joinState(allFilter);
		}
		if(stateString){
			params['state'] = stateString;
		}
		let severityString = this.joinState(this.severitiesFinalFilter);
		if(severityString){
			params['severities'] = severityString;
		}
    let validatedString = this.joinState(this.validatedFinalFilter);
		if(validatedString){
			params['validated_flags'] = validatedString;
		}
    if (this.selectedScope) {
      params['scope'] = this.selectedScope;
    }
    this.newRequest = this.portfolioSurveyService.getwebapplication(this.mainDomain, params).subscribe(
      (data) => {
        this.newRequest = null;
        let profile: any = localStorage.getItem('profile');
        let parsedData = JSON.parse(profile);
        let account_type = parsedData.account_type;
        console.log(data);
        if (data['data']) {
          this.appToShow = data['data'];
          this.showSpinner = false;
          this.total_ips_count = this.appToShow[0]?.total ? this.appToShow[0]?.total : 0;
          this.appToShow.map(ip => {
            ip.cvelimit = 3;
            ip.checked = false;
            ip.vulnerabilities.map(cve => {
              cve.account_type = account_type
              cve.subjectTypeName = {
                "subject_type": this.subjectType,
                "subject_name":' App URL-' + cve.application_url + ', CVE_Title-' + cve.cve_id,
                "subject_id": cve.application_cve_id
              }
              let tagsList = cve.appcvetags
              if(tagsList && tagsList.includes("MANUAL")){
                this.manualIdArray.push(cve.application_cve_id)
              }
              if(!cve.state){
                cve.state = 'open'
              }
              cve.state_new = cve.state;
            })
            ip.vulnerabilities = ip.vulnerabilities.reduce((acc, current)=>{
              const x = acc.find(item => item.cve_id === current.cve_id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
          })

          this.selectAllIp();
          this.appToShow.forEach(web_vul => {	
            let web_vuls = web_vul.vulnerabilities
            web_vuls.forEach(vul_detail => {
              this.webAppIdArray.push(vul_detail.application_cve_id)
              })
            })
          if (this.webAppIdArray.length) {
          this.getObsInsCountsForEachWebApp(this.webAppIdArray)
          }
         
        } else {
          this.showSpinner = false
        }
      },
      (error) => {
        this.newRequest = null;
        this.commonService.errorForDomain(error);
      }
    );
    this.loadDownloadJsonAllData(params);
    this.filterCounter(this.mainDomain);
  }
  loadDownloadJsonAllData(params) {
    this.downloadLoading = true;
    var csvData: any = []
    if (params['page']) params['page'] = 1;
    if (params['limit']) params['limit'] = null;
    // if(this.selectedScope) this.fieldsToDownload['Scope'] = "applications.scope";
    this.allWebVulnerbities = [];
    this.portfolioSurveyService.getWebAppVulnerability(this.mainDomain,params).subscribe(vul => {
			this.showSpinner = true
			this.portfolioSurveyService.getAllObsAndIns(this.mainDomain, this.subjectType).subscribe((data) => {
				  this.allObsIns = data['data']
				  this.showSpinner = false
          csvData = vul['data'];
          if (csvData && csvData.length) {
            csvData.forEach(serVul => {
              serVul=this.commonService.parseValidate(serVul);
              let severity = this.commonService.confidenceLevel(serVul.severity)
              var applications_data = serVul.applications;
              var len_applications_data = applications_data.length
              serVul.obs_arr = {}
              serVul.obs_arr[serVul.cve_id] = {}
              serVul.ins_arr = {}
              serVul.ins_arr[serVul.cve_id] = {}
              if (len_applications_data) {
                applications_data.forEach(app_data => {
                  app_data.discovered_at = (app_data.discovered_at) ? new Date(app_data.discovered_at).toLocaleString() : app_data.discovered_at;
								  app_data.applicationcve_updated_at = (app_data.applicationcve_updated_at) ? new Date(app_data.applicationcve_updated_at).toLocaleString() : app_data.applicationcve_updated_at;
                  serVul.obs_arr[serVul.cve_id][app_data.source_url]=[]
                  serVul.ins_arr[serVul.cve_id][app_data.source_url]=[]
                  this.allObsIns.forEach(obsIns => {
                    app_data.applicationcve_id.forEach(appcve_id => {
                      if (obsIns.subject_id == appcve_id) {
                        serVul.obs_arr[serVul.cve_id][app_data.source_url].push(obsIns.observation)
                        serVul.ins_arr[serVul.cve_id][app_data.source_url].push(obsIns.insight)
                      }
                    })
                  })
                })
              }
              var observations_data = serVul.obs_arr[serVul.cve_id]
              var insights_data = serVul.ins_arr[serVul.cve_id]
              for (let key in observations_data ) {
                if (!observations_data[key].length) {
                  delete observations_data[key]
                }
              }
              for (let key in insights_data) {
                if (!insights_data[key].length) {
                  delete insights_data[key]
                }
              }
              if (Object.keys(observations_data).length === 0) {observations_data  = ''}
              if (Object.keys(insights_data).length === 0) {insights_data = ''}
              this.allWebVulnerbities.push({ ...serVul, ...{ severity: severity }, ...{ observation: observations_data }, ...{ insight: insights_data}})
            })
        }
      }, error => {
        this.showSpinner = false;
      })
    this.downloadLoading = false;
		})
  }
  goToPage(val) {
    // this.select10IPs = false
    this.page = val;
    this.getWebApplicationDetailsFromDeepc();
  }
  checkUncheckCve(ip) {
    let checkedStatus = ip.checked;
    if(!checkedStatus) this.selectAllRecord = false;
    if (ip.vulnerabilities) {
      ip.vulnerabilities.map(x => x.checked = checkedStatus)
    }
    this.getSelectedRecords();
  }
  selectAllIp() {
    if (this.selectAllRecord) {
      this.appToShow.map(x => {
        x.checked = true;
        this.checkUncheckCve(x);
      });
    }else{
      this.appToShow.map(x => {
        x.checked = false;
        this.checkUncheckCve(x);
      });
    }
    this.getSelectedRecords();
  }
  // getSelectedRecords(){
  //   this.noOfSelectedRecord = 0;
  //   this.appToShow.map(x => {
  //     if(x.checked){
  //       this.noOfSelectedRecord = this.noOfSelectedRecord+1;
  //     }
  //   });
  // }
  getSelectedRecords() {
    let selectedCve:any = [];
    this.multipleCveWithManual = 0
    this.multipleSelectedCveIdArray = [];
    this.noOfSelectedRecord = 0;
    this.noOfSelectedCve = 0;
    this.appToShow.map(x => {
      if (x.checked) {
        this.noOfSelectedRecord = this.noOfSelectedRecord + 1;
      }
      x.vulnerabilities.map(cve => {
        if(cve.checked){
          this.multipleSelectedCveIdArray.push(cve.application_cve_id)
          this.noOfSelectedCve = this.noOfSelectedCve + 1;
          if(this.manualIdArray.includes(cve.application_cve_id) && this.functionMultipleState=="ready_to_retest"){}
          else{
            selectedCve.push(cve.application_cve_id);
          }
          if(this.manualIdArray.includes(cve.application_cve_id)){
            this.multipleCveWithManual = this.multipleCveWithManual +1
          }
        }
      })
    });
    return selectedCve;
  }
  // State Change
  changeStateMultiple(modalTemplate,changeStateMultipleTemplateWithManual){
    if(this.multipleCveWithManual >=1){
      this.multipleStateModal = this.modalService.show(changeStateMultipleTemplateWithManual, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }
    else if(!this.multipleCveWithManual ){
      this.multipleStateModal = this.modalService.show(modalTemplate, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }
  }
  validMultipleState(){
    if(this.noOfSelectedCve>0){
      if (this.functionMultipleState != 'open') {
        return (this.strTrim(this.stateComment)) ? true : false;
      } else {
        return true;
      }
    }else{
      return false;
    }
  }
  stateChanged(cve, confirmTemplate) {
    this.currentCve = cve;
    this.confirmaModal = this.modalService.show(confirmTemplate, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  cancleStateChange() {
    let that = this;
    setTimeout(function () {
      that.currentCve.state_new = that.currentCve.state;
      that.stateComment = "";
    }, 500);
  }
  cancleStateChangeMultiple(){
    if(this.multipleStateModal) { this.multipleStateModal.hide(); }
    this.stateComment = "";
  }
  validState() {
    let thisState = this.currentCve.state_new;
    if (thisState != 'open') {
      return (this.strTrim(this.stateComment)) ? true : false;
    } else {
      return true;
    }
  }
  strTrim(str = '') {
    return str.trim();
  }
  saveStateMultiple(){
    let payload:any = {
      "ids": this.getSelectedRecords(),
      "state": this.functionMultipleState,
      "comment": this.stateComment
    }
    // console.log(payload);
    this.saveState(payload);
  }
  saveState(multipleParam=false) {
    console.log(this.currentCve); 
    let payload:any = {};
    if(multipleParam){
      payload = multipleParam;
    }else{
      payload = {
        "ids": [this.currentCve.application_cve_id],
        "state": this.currentCve.state_new,
        "comment": this.stateComment
      }
    }
    // console.log(payload); return;
    this.portfolioSurveyService.updateState("web_app", payload, this.mainDomain).subscribe((data: any) => {
      if (data.status == "pass") {
        if (data.message) {
          this.toastr.success('CVE state updated successfully');
        }
        if(this.confirmaModal){ this.confirmaModal.hide(); this.cancleStateChange(); } 
        if(this.multipleStateModal){ this.multipleStateModal.hide(); this.cancleStateChangeMultiple(); } 
        this.getWebApplicationDetailsFromDeepc();
      } else {
        console.log(data);
        this.toastr.info("Unable to update state.");
      }
    },(error) => {
      console.log(error);
      this.toastr.error("Something went wrong.");
    });
  }

  // Filter
  hangeSeverityFilter(state){
		this.severitiesFinalFilter=[];
		this.filter_severities.map((x)=>{
			if(x.selected) this.severitiesFinalFilter.push(x.key);
		});
	}
	filterSubDomainBySeverities(action){
		if(action=='clear'){
      this.selectAllRecord = false;
			this.severitiesFinalFilter=[];
			this.filter_severities.map((x)=>{
				x.selected = false
			});
      this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Severities');
		}
		// Filter send to api
		this.page=1
		this.getWebApplicationDetailsFromDeepc();
    
	}
	joinState(states,commaSep=true){
		if(states.length){
			let state_string = "";
			if(commaSep){
				state_string = '"'+states.join('","')+'"';
			}else{
				state_string = states.join('');
			}
			return state_string;
		}else{
			return "";
		}
	}
	changeState(filter,index){
		console.log(filter,index);
		if(filter=='severity'){
			this.filter_severities.map(x=>{
				x.selected = false;
			});
			this.filter_severities[index].selected = true;
			this.changeSeverityFilter(this.filter_severities[index].key);
			this.filterSubDomainByState('clear');
			this.page=1
			this.getWebApplicationDetailsFromDeepc();
		}
		if(filter=='state'){
			this.filter_states.map(x=>{
				x.selected = false;
			});
			this.filter_states[index].selected = true;
			this.changeStateFilter(this.filter_states[index].key);
			this.filterSubDomainBySeverities('clear');
			this.page=1
			this.getWebApplicationDetailsFromDeepc();
		}
	}
  filterSubDomainByState(action=''){
		if(action=='clear'){
			this.stateFinalFilter=[];
			this.filter_states.map((x)=>{
				x.selected = false
			});
      this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'State');
		}
		// Filter send to api
		this.page=1
		this.getWebApplicationDetailsFromDeepc();
    
	}
  detectFilters(){
		this.allFilterList = this.filterBlock.checkState(this.stateFinalFilter,this.allFilterList,'State');
		this.allFilterList = this.filterBlock.checkState(this.severitiesFinalFilter,this.allFilterList,'Severities');
    this.allFilterList = this.filterBlock.checkState(this.validatedFinalFilter,this.allFilterList,'Validate');
	}
  changeStateFilter(state){
		this.stateFinalFilter=[];
		this.filter_states.map((x)=>{
			if(x.selected) this.stateFinalFilter.push(x.key);
		});
    this.detectFilters();
    
	}
  changeSeverityFilter(state){
		this.severitiesFinalFilter=[];
		this.filter_severities.map((x)=>{
			if(x.selected) this.severitiesFinalFilter.push(x.key);
		});
    this.detectFilters();
    
	}
  // Search
  searchVulnerability() {
		this.page = 1;
		this.getWebApplicationDetailsFromDeepc();
    
	}
  clearSearch() {
    this.selectAllRecord = false;
		this.searchKey = null;
		this.page = 1;
		this.getWebApplicationDetailsFromDeepc();
    this.selectAllRecord = false;
	}
  resetRetestValue(){
    this.retestParam = {
      "services": {
        "service_cve_ids": [],
        "cve_ids": [],
        "ips": []
      },
      "web_apps": {
        "application_cve_ids": [],
        "cve_ids": [],
        "application_urls": []
      }
    };
  }
  applyRetest(modalTemplate){
    this.resetRetestValue();
    let application_urls:any = [];
    let cve_id:any = [];
    let application_cve_ids:any = [];
    this.cve_id_text = false
    this.appToShow.map(x => {
      x.vulnerabilities.map(cve => {
        if(cve.checked && cve.state=='ready_to_retest'){
          if (this.cve_id_text != true){
            if (this.isValidCve(cve.cve_id) == false){
              this.cve_id_text = true
            }
          }
          if(!application_urls.includes(x.source_url)){
            application_urls.push(x.source_url);
          }
          if(!cve_id.includes(cve.cve_id)){
            cve_id.push(cve.cve_id);
          }
          if(!application_cve_ids.includes(cve.application_cve_id)){
            application_cve_ids.push(cve.application_cve_id);
          }
        }
      })
    });
    this.retestParam.web_apps.application_cve_ids = application_cve_ids;
    this.retestParam.web_apps.cve_ids = cve_id;
    this.retestParam.web_apps.application_urls = application_urls;
    // console.log(this.retestParam);
    // this.submitForReset(this.retestParam);
    this.applyRetestConfirmModal = this.modalService.show(modalTemplate, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  submitForReset(param={}){
    let profile:any = localStorage.getItem('profile');
    let parsedData = JSON.parse(profile);
    let customer_id = parsedData.cust_id;
    this.openActionDropdown = false;
    this.applyRetestConfirmModal.hide();
    if(param['services']) delete param['services'];
    this.portfolioSurveyService.triggerReset(this.mainDomain, param, customer_id).subscribe((data: any) => {
      console.log(data);
      this.toastr.success("Applied for retest successfully.");
    },(error) => {
      console.log(error);
      this.toastr.error("Something went wrong.");
    });
  }
  filterCounter(mainDomain){
		let params:any = {
      searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
			...this.filters
		}
		let stateString = '';
		if(this.stateFinalFilter.length){
			stateString = this.joinState(this.stateFinalFilter);			
		}else{
			let allFilter:any = [];
			this.filter_states.map(x=>allFilter.push(x.key));
			stateString = this.joinState(allFilter);
		}
		if(stateString){
			params['state'] = stateString;
		}
		let severityString = this.joinState(this.severitiesFinalFilter);
		if(severityString){
			params['severities'] = severityString;
		}
    params['scope'] = this.selectedScope;
    let validatedString = this.joinState(this.validatedFinalFilter);
		if(validatedString){
			params['validated_flags'] = validatedString;
		}
		// console.log(params);
		this.portfolioSurveyService.getWebChartData(mainDomain, params).subscribe((data: any) => {
			console.log(data);
			let totalCount = 0;
			if(data && data['by_severity']){
				Object.keys(data['by_severity']).forEach(function (key) {
					totalCount = totalCount + data['by_severity'][key] || 0;
				});
			}
			this.totalCount = totalCount;
			console.log('NewCount',this.totalCount);
		}, (error) => {
			console.log(error);
		});
	}
  isValidCve(cve_id){
    const regexExp = /CVE-\d{4}-\d{4,7}/
    const found = cve_id.match(regexExp);
    return (found)?true:false
  }
  changeValidatedFilter() {
    this.validatedFinalFilter = [];
    this.validated_flags.map((x) => {
      if (x.selected) this.validatedFinalFilter.push(x.value);
    });
    this.detectFilters();
  }
  filterValidatedCve(action) {
    if (action == 'clear') {
      this.selectAllRecord = false;
      this.validatedFinalFilter = [];
      this.validated_flags.map((x) => {
        x.selected = false
      });
      this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Validate');
    }
    // Filter send to api
    this.page = 1
    this.getWebApplicationDetailsFromDeepc();
    
  }
  getObsInsCountsForEachWebApp(arrayList) {
      this.obsInsCountSpinner = true
      const params = {
        subject_ref_list: arrayList,
        subject_type: this.subjectType
      }
      this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain, params).subscribe(data => {
        this.obsInsCountSpinner = false
        this.vulDetailForWebApp = data['data']
        this.appToShow.forEach((web_vul) => {
          let web_vuls = web_vul.vulnerabilities
          web_vuls.forEach(vul_detail => {
              let app_cve_id = vul_detail.application_cve_id
              if (this.vulDetailForWebApp[app_cve_id]) {
                vul_detail.obs_count = this.vulDetailForWebApp[app_cve_id].count_obs
                vul_detail.ins_count = this.vulDetailForWebApp[app_cve_id].count_ins
              }
            })
        })
      })
      }
  openCommentsModule(template: TemplateRef<any>, cve) {
		let profile: any = localStorage.getItem('profile');
		let parsedData = JSON.parse(profile);
		let account_type = parsedData.account_type;
    this.pocSubjectComment = {
      "subject_type": cve['subjectTypeName']['subject_type'],
      "subject_name": cve['subjectTypeName']['subject_name'].trim(),
      "subject_id": cve['subjectTypeName']['subject_id'],
      "visible_to": account_type
    }
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  openImpact(template: TemplateRef<any>, ser) {
    console.log(ser);
		this.editImpact = false;
		this.impactServ = ser;
    console.log(this.impactServ);
		// this.impactServ['impact'] = 'Lorem Ipsome';
		if(!this.impactServ['app_impact']) this.editImpact = true;
		this.modalRef = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}
	updateImpact(){
		let ip = this.impactServ;
		let payload = {
			"ids":[ip['application_cve_id']],
			"impact":ip['app_impact']
		}
		this.portfolioSurveyService.updateState('web_app',payload, this.mainDomain).subscribe((data:any) => {
			if(data.status=="pass"){
				if(data.message){
					this.editImpact = false;
					this.toastr.success('Impact updated successfully');
				}
			}else{
				this.toastr.info("Unable to update impact.");
			}
		},
		(error) => {
			console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
  deleteImpct(){
		let ip = this.impactServ;
		let payload = {
			"ids":[ip['application_cve_id']],
			"impact":null
		}
		this.portfolioSurveyService.updateState('web_app',payload, this.mainDomain).subscribe((data:any) => {
			if(data.status=="pass"){
				if(data.message){
					this.editImpact = true;
					this.toastr.success('Impact deleted successfully');
					ip['app_impact'] = null;
				}
			}else{
				this.toastr.info("Unable to delete impact.");
			}
		},
		(error) => {
			console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
}
