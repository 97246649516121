import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
import { AuthService } from '../pages/auth.service';

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, DoCheck {

  constructor(
    public router: Router,
    public portfolioSurveyService: PortfolioSurveyService,
    public authService: AuthService
  ) { }

  public showDL;
  public showSR;
  public showES;
  public showWS;
  public showNS;
  public showDS;
  public brandPrimary = '#20a8d8';
  public brandSuccess = '#4dbd74';
  public brandInfo = '#63c2de';
  public brandWarning = '#f8cb00';
  public brandDanger = '#f86c6b';
 
  public scopes: Array<object> =  [];
  public controls: Array<object> =  [];
  

  // dropdown buttons
  public status: { isopen } = { isopen: false };
  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  // convert Hex to RGBA
  public convertHex(hex: string, opacity: number) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const rgba = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity / 100 + ')';
    return rgba;
  }

  // events
  public chartClicked(e: any): void {
    // console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }

  // overallScore
  public overallScoreData: Array<any> = [
    {
      data: [45, 45, 50, 55, 50, 45, 40, 50],
      label: 'Overall Score'
    }
  ];
  public overallScoreLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];
  public overallScoreOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent'
        },
        ticks: {
          fontSize: 2,
          fontColor: 'transparent',
        }

      }],
      yAxes: [{
        display: false,
        ticks: {
          display: false,
          min: 40 - 5,
          max: 84 + 5,
        }
      }],
    },
    elements: {
      line: {
        borderWidth: 1
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false
    }
  };
  public overallScoreColours: Array<any> = [
    { // grey
      backgroundColor: this.brandPrimary,
      borderColor: 'rgba(255,255,255,.55)'
    }
  ];
  public overallScoreLegend = false;
  public overallScoreType = 'line';

  // osIntScore
  public osIntScoreData: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40, 55],
      label: 'OSINT Score'
    }
  ];
  public osIntScoreLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];
  public osIntScoreOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false
      }],
      yAxes: [{
        display: false
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false
    }
  };
  public osIntScoreColours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.2)',
      borderColor: 'rgba(255,255,255,.55)',
    }
  ];
  public osIntScoreLegend = false;
  public osIntScoreType = 'line';


  // maturityScore
  public maturityScoreData: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40, 78, 81, 80, 45, 34],
      label: 'Maturity Score'
    }
  ];
  public maturityScoreLabels: Array<any> = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  public maturityScoreOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
        barPercentage: 0.6,
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      display: false
    }
  };
  public maturityScoreColours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.3)',
      borderWidth: 0
    }
  ];
  public maturityScoreLegend = false;
  public maturityScoreType = 'bar';

  // mainChart

  public random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public mainChartElements = 27;
  public mainChartData1: Array<number> = [];
  public mainChartData2: Array<number> = [];
  public mainChartData3: Array<number> = [];

  public mainChartData: Array<any> = [
    {
      data: this.mainChartData1,
      label: 'Current'
    },
    {
      data: this.mainChartData2,
      label: 'Previous'
    },
    {
      data: this.mainChartData3,
      label: 'BEP'
    }
  ];
  /* tslint:disable:max-line-length */
  public mainChartLabels: Array<any> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Thursday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  /* tslint:enable:max-line-length */
  public mainChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function(value: any) {
            return value.charAt(0);
          }
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          stepSize: Math.ceil(250 / 5),
          max: 250
        }
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };
  public mainChartColours: Array<any> = [
    { // brandInfo
      backgroundColor: this.convertHex(this.brandInfo, 10),
      borderColor: this.brandInfo,
      pointHoverBackgroundColor: '#fff'
    },
    { // brandSuccess
      backgroundColor: 'transparent',
      borderColor: this.brandSuccess,
      pointHoverBackgroundColor: '#fff'
    },
    { // brandDanger
      backgroundColor: 'transparent',
      borderColor: this.brandDanger,
      pointHoverBackgroundColor: '#fff',
      borderWidth: 1,
      borderDash: [8, 5]
    }
  ];
  public mainChartLegend = false;
  public mainChartType = 'line';

  // social box charts

  public socialChartData1: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: 'Facebook'
    }
  ];
  public socialChartData2: Array<any> = [
    {
      data: [1, 13, 9, 17, 34, 41, 38],
      label: 'Twitter'
    }
  ];
  public socialChartData3: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40],
      label: 'LinkedIn'
    }
  ];
  public socialChartData4: Array<any> = [
    {
      data: [35, 23, 56, 22, 97, 23, 64],
      label: 'Google+'
    }
  ];

  public socialChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public socialChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false,
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };
  public socialChartColours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.1)',
      borderColor: 'rgba(255,255,255,.55)',
      pointHoverBackgroundColor: '#fff'
    }
  ];
  public socialChartLegend = false;
  public socialChartType = 'line';

  // sparkline charts

  public sparklineChartData1: Array<any> = [
    {
      data: [35, 23, 56, 22, 97, 23, 64],
      label: 'Clients'
    }
  ];
  public sparklineChartData2: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: 'Clients'
    }
  ];

  public sparklineChartData3: Array<any> = [
    {
      data: [55, 43, 56, 52, 47, 43, 54],
      label: 'Clients'
    }
  ];
  public sparklineChartData4: Array<any> = [
    {
      data: [40, 41, 44, 44, 55, 50, 60],
      label: 'Clients'
    }
  ];

  public sparklineChartData5: Array<any> = [
    {
      data: [48, 50, 54, 53, 43, 47, 44],
      label: 'Clients'
    }
  ];
  public sparklineChartData6: Array<any> = [
    {
      data: [56, 52, 45, 58, 40, 52, 48],
      label: 'Clients'
    }
  ];

  public sparklineChartData7: Array<any> = [
    {
      data: [53, 51, 45, 54, 50, 46, 47],
      label: 'Clients'
    }
  ];
  public sparklineChartData8: Array<any> = [
    {
      data: [52, 43, 40, 51, 58, 53, 54],
      label: 'Clients'
    }
  ];

  public sparklineChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public sparklineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false,
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };
  public sparklineChartDefault: Array<any> = [
    {
      backgroundColor: 'transparent',
      borderColor: '#d1d4d7',
    }
  ];
  public sparklineChartPrimary: Array<any> = [
    {
      backgroundColor: 'transparent',
      borderColor: this.brandPrimary,
    }
  ];
  public sparklineChartInfo: Array<any> = [
    {
      backgroundColor: 'transparent',
      borderColor: this.brandInfo,
    }
  ];
  public sparklineChartDanger: Array<any> = [
    {
      backgroundColor: 'transparent',
      borderColor: this.brandDanger,
    }
  ];
  public sparklineChartWarning: Array<any> = [
    {
      backgroundColor: 'transparent',
      borderColor: this.brandWarning,
    }
  ];
  public sparklineChartSuccess: Array<any> = [
    {
      backgroundColor: 'transparent',
      borderColor: this.brandSuccess,
    }
  ];


  public sparklineChartLegend = false;
  public sparklineChartType = 'line';

  public maturityScores: Array<any> = [
    {
      asset: 'Devices',
      identify: { 
        score: 2,
        approach: `
        Critical Systems inventory maintained in SharePoint
        Vulnerability assessment tool is run every month to discover vulnerabilities
        Infrastructure team gets inputs from the risk team on vulnerability prioritization and critical devices identification        
        `,
        gaps: `
        No Baseline configuration is maintained
        No Periodic audits of assets
        Asset Ownership?
        Historical Data on Risk Posed?
        Automation        
        `
      },
      protect: {
        score: 0,
      },
      detect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      respond: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      recover: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      }
    },
    {
      asset: 'Applications',
      identify: {
        score: 2,
        approach: `
        Application inventory maintained by Application Development team
        1st level Unit testing is performed by Dev team
        UAT team does the 2nd level on testing based on use cases & abuse cases specified by Business analysis team
        Security VAPT is done by security team or partner after application is developed or major feature releases
        Security VAPT for all application is done once per year otherwise        
        `,
        gaps: `
        Some of the tools and applications used by development teams are not standardized
        Threat Identification: No Formal threat modelling is done before application development
        Security requirements are not explicitly specified beforehand 
        No proper integration between Dev team, UAT team and production team
        Code signing is not used for published applications        
        `
      },
      protect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      detect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      respond: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      recover: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      }
    },
    {
      asset: 'Networks',
      identify: {
        score: 3,
        approach: `
        All the network devices are inventoried at one central location
        All the Network devices are baselined for secure configuration
        Risk team classifies network devices as per risk/impact analysis
        NTA?        
        `,
        gaps: `
        Historical Data
        …
        
        `
      },
      protect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      detect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      respond: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      recover: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      }
    },
    {
      asset: 'Data',
      identify: {
        score: 1,
        approach: `
        Data classification/Labeling at the source is Ad hoc
        Informal policy is used to categorize data into 3-4 sub-categories by the risk team        
        `,
        gaps: `
        No formal data classification policy
        No tool is used to identify/discover sensitive data across endpoints, applications and databases
        No formal data governance policy        
        `
      },
      protect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      detect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      respond: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      recover: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      }
    },
    {
      asset: 'Users',
      identify: {
        score: 3,
        approach: `
        All the users accounts are managed through Active directory using GPO’s
        Privileged users accounts are identified and monitored        
        `,
        gaps: ``
      },
      protect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      detect: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      respond: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      },
      recover: {
        score: 1,
        approach: `
        `,
        gaps: `
        `
      }
    }
  ];
  
  public osIntScores: any = {
    'email': {
      'label': 'Email Security',
      'score': 99,
      'grade': 'A'
    }
  };
  public portfolioAnalysis;

  public scoreClass(score: number){
    return {
      'badge': true,
      'badge-success': (score > 3),
      'badge-warning': ((score > 1) && (score <= 3)),
      'badge-danger': ((score > 0) && (score <= 1))
    }
  }
  // public scopes: Array<string> =  [
  //   'Network',
  //   'Endpoints',
  //   'Applications',
  //   'Users',
  //   'Data'
  // ];
  
  // public controls: Array<string> =  [
  //   'Identify',
  //   'Prevent',
  //   'Detect',
  //   'Respond'
  // ];
  public portfolioScore(scope: string, control: string){
    if(this.portfolioAnalysis) {
      let data = this.portfolioAnalysis.data.find(data => ((data.scope== scope) && (data.control == control)));
      return data ? data.score : 0;
    } else return 0;
    // console.log(data);
  };
  public displayData: any;

  public updateDisplayData(scope: string, control: string){
    if(this.portfolioAnalysis) {
      this.displayData = this.portfolioAnalysis.data.find(data => ((data.scope== scope) && (data.control == control)));
    }
  };
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: false,
  };
  public barChartLabels: string[] = [
    'Application Security Testing',
    'Data Loss Prevention', 
    'Distributed Denial of Service',
    'Endpoint Security',
    'Email Security Gateway',
    'Next Generation Firewall',
    'Web Application Firewall',
    'Identity and Access Management'
  ];
  public barChartType = 'horizontalBar';
  public barChartLegend = false;
  public barColor = [{
    backgroundColor: 'rgba(31, 119, 180, 0.5)',
    borderColor: 'rgba(31, 119, 180, 1)'
  }]

  public barChartData: any[] = [
    {data: [66.5, 57.1, 56.4, 88.4, 91.2, 68.7, 63.6, 43.6], backgroundColor: 'rgb(31, 119, 180)'},
  ];

  loadPortfolioAnalysisData() {
    this.portfolioSurveyService.getPortfolioData().subscribe((data) => {
        this.portfolioAnalysis = data;
      }, (error) => {
        alert('Error Occurred');
        this.router.navigate(['/']);
      }
    );
  }

  public showScopeControlSpinner = false;
  public showScopeControlError;

  loadPortfolioscopeandcontrols() {
    this.showScopeControlSpinner = true;
    this.portfolioSurveyService.getPortfolioData().subscribe((data:any) => {
        this.showScopeControlSpinner = false;      
        this.portfolioAnalysis = data;
        this.scopes = data.scopesControls['Scope'];
        this.controls = data.scopesControls['Control'];
      }, (error) => {
        this.showScopeControlSpinner = false;      
        this.showScopeControlError = "Please complete portfolio analysis survey, if not done, for results."
        // alert('Error Occurred');
        // this.router.navigate(['/']);
      }
    );
  }
  public getFontClass(grade: string) {
    return {
      'callout': true,
      'callout-success': (grade == 'A'),
      'callout-mild-success' : (grade == 'B'),
      'callout-safe': (grade == 'C'),
      'callout-warning': (grade == 'D'),
      'callout-danger': (grade == 'F'),
    }
  }
  public getFontContentClass(grade: string) {
    return {
      'callout-content': true,
      'callout-content-success': (grade == 'A'),
      'callout-content-mild-success' : (grade == 'B'),
      'callout-content-safe': (grade == 'C'),
      'callout-content-warning': (grade == 'D'),
      'callout-content-danger': (grade == 'F'),
    }
  }

  ngOnInit(): void {

    // this.loadPortfolioAnalysisData();
    this.loadPortfolioscopeandcontrols();
    // generate random values for mainChart
    for (let i = 0; i <= this.mainChartElements; i++) {
      this.mainChartData1.push(this.random(50, 200));
      this.mainChartData2.push(this.random(80, 100));
      this.mainChartData3.push(65);
    }
    this.loadExternalOsintData();
  }
  public externalOSINTData = {
    "leakedCredentials" : "615*",
    "phishingDomains": "32",
    "webSecurityScore": "25"
  };
  public myDomain;
  public loadExternalOsintData() {
    var domain = localStorage.getItem("mydomain");
    this.myDomain = domain;
    if(domain) {
      this.portfolioSurveyService.getBreachedCredentials(domain).subscribe((data:any) => {
      if(data && data.results && data.results.breachSize < 10) {
        this.externalOSINTData['leakedCredentials'] = data.results.breachSize;
      } else if(data && data.results && data.results.breachSize >= 10) {
        this.portfolioSurveyService.getBreachedPassByDomain(domain).subscribe(data => {
          if(data && data[0] && data[0][0]) {
            this.externalOSINTData['leakedCredentials'] = data[0][0].total;
          } else if(data && data[0] && !data[0].length) {
            this.externalOSINTData['leakedCredentials'] = '0';
          }
        })
      }
      })
      // this.portfolioSurveyService.getPhishingDomains(domain).subscribe(data => {
      //   if(data && data.results && data.results['DomainListSize']) {
      //     this.externalOSINTData['phishingDomains'] = data.results['DomainListSize'];
      //   }
      // })
      this.portfolioSurveyService.getWebSecurity(domain).subscribe((data:any) => {
        if(data.analyze && data.analyze.state === "FINISHED") {
        this.externalOSINTData['webSecurityScore'] = data.analyze.grade;
        } else if(data.error === "invalid-hostname") {
          this.externalOSINTData['webSecurityScore'] = "NA"
        }
      })
    }
  }

  ngDoCheck(){
    // console.log('this.authService.isAuthenticated()', this.authService.isAuthenticated())
    this.authService.isAuthenticated();
  }

}
