import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  TemplateRef, Output, EventEmitter
} from "@angular/core";
import { CommonService } from "app/_services/common.service";
import { reasonToDeleteDigitalRisk } from "app/_services/common-var.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { PortfolioSurveyService } from "app/_services";

@Component({
  selector: "app-code-leaks",
  templateUrl: "./code-leaks.component.html",
  styleUrls: ["./code-leaks.component.scss"],
})
export class CodeLeaksComponent implements OnInit {
  @Input() mainDomain: any;
  @Input() isUserTrial = false;
  @Input() codeLeaks: any;
  @Input() showExecutiveReport;
  @Input() filters = {};
  @Input() codeLeaksCount;
  @Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
  public page = 1;
  public seeMoreLeak = 50;
  public seeMoreFile = 50;
  public allLeaks: any;
  public openThis: number = -1;
  public sortKey = null;
  public initialSort: boolean = false;
  public order = 1;
  public showItem_i;
  public showItem_j;
  public searchKey: string = null;
  public keysToSearch = ["repo_url"];
  public showDowloadText: boolean = false;
  public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
  public codeLeakSpinner = true;
  public sortWithKey = null;
  // public delReason: string = null;

  public fieldsToDownload = {
    Repository: "repo_url",
    "No.Of Files": "code_leaks.count",
    "Created At": "created_at",
    "Update At": "updated_at",
  };

  public leaks;
  public seeMoreleak;
  public loading:boolean = false;
  constructor(
    public commonService: CommonService,
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    if (changes.codeLeaks && changes.codeLeaks["currentValue"] != null) {
      this.codeLeaks = changes.codeLeaks["currentValue"];
      this.codeLeakSpinner = false;

    }
    if (
      changes.codeLeaksCount &&
      changes.codeLeaksCount["currentValue"] != null
    ) {
      this.codeLeaksCount = changes.codeLeaksCount["currentValue"];
    }
    
    if(changes.mainDomain && changes.mainDomain["currentValue"] != null){
      this.getAllCodeLeaksForDomain();
    }
  }

  goToPage(val) {
    this.page = val;
    this.getCodeLeaksForDomain();
  }

  public getAllCodeLeaksForDomain() {
    this.loading = true;
    this.portfolioSurveyService
      .getCodeLeaks(this.mainDomain["domain_name"])
      .subscribe((data: any) => {
        this.loading = false;
        if (data && data["status"] == "pass") {
          this.allLeaks = data["data"];
        }
      });
  }

  public getCodeLeaksForDomain() {
    this.codeLeakSpinner = true;
    this.codeLeaks = [];
    const params = {
      page: this.page,
      limit: 10,
      searchKey: this.searchKey,
      ...this.filters,
    };
    if (this.sortWithKey) params['sortKey'] = this.order == -1 ? this.sortWithKey : '-' + this.sortWithKey
    this.portfolioSurveyService
      .getCodeLeaks(this.mainDomain["domain_name"], params)
      .subscribe((data: any) => {
        if (data && data["status"] == "pass") {
          this.codeLeaks = data["data"];
          this.codeLeaksCount = data['count']

          this.codeLeakSpinner = false;
        }
      });
  }
  searchAllLeaks() {
    this.searchKey = null;
    this.page = 1;
    this.getCodeLeaksForDomain();
  }
  searchLeaks() {
    this.page = 1;
    this.getCodeLeaksForDomain();
  }

  openRemoveOptions(template: TemplateRef<any>, code_leak) {
    code_leak.selected = true;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }

  delcodeLeaks(reason) {
    this.codeLeaks.map((ph) => {
      if (ph.selected == true) {
        ph.updated = true;
        ph.archive = true;
        ph.archive_reason = reason;
        delete ph["selected"];
        // this.delReason = null;
      }
    });
    this.updateCodeLeaks();
  }
  delReason(reason) {
    this.delcodeLeaks(reason);
  }
  public updateCodeLeaks() {
    this.portfolioSurveyService
      .updateCodeLeaks(this.codeLeaks, this.mainDomain["domain_name"])
      .subscribe(
        (data) => {
          this.modalRef.hide();
          alert("Entity deleted successfully!");
          this.getCodeLeaksForDomain();
          this.refreshCount.emit('leakedcreds');
        },
        (err) => {
          this.modalRef.hide();
        }
      );
  }
  cancelDel(a) {
    this.codeLeaks.map((content) => {
      content.selected = false;
    });
  }
}
