import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RunbookRoutingModule } from './runbook-routing.module';
import { RunbookComponent } from './runbook.component';
import { AttackListComponent } from './attack-list/attack-list.component';
import { AssesmentComponent } from './assesment/assesment.component';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { RbSelectionComponent } from './rb-selection/rb-selection.component';
import { RbTargetComponent } from './rb-target/rb-target.component';
import { RbAdvanceOptionComponent } from './rb-advance-option/rb-advance-option.component';
import { FormsModule } from "@angular/forms";
import { PipeModule } from "app/pipes/pipe.module";
import { RbLauncherComponent } from './rb-launcher/rb-launcher.component';
import { PaginationComponent } from '../shared/pagination.component'
import { NewPaginationComponent } from '../shared/newPagination.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { AvatarModule } from 'ngx-avatar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RunflowComponent } from './runflow/runflow.component';
import { RbResultsComponent } from './rb-results/rb-results.component';
import { LoaderComponent } from 'app/shared/loader/loader.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AttckSurfaceComponent } from './attck-surface/attck-surface.component';
import { ClickOutsideDirective } from "app/shared/click-outside.directive";
import  { AttackListAssessmentDetailsComponent } from "./attack-list-assessment-details/attack-list-assessment-details.component"

import { MarkdownModule } from "ngx-markdown";
import { RbResumeComponent } from './rb-resume/rb-resume.component';
import { GraphRunstepComponent } from './graph-runstep/graph-runstep.component';
import { AssesmentTargetListComponentComponent } from './assesment-target-list-component/assesment-target-list-component.component';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};
@NgModule({
  declarations: [
  
    RunbookComponent,
       AttackListComponent,
       AssesmentComponent,
       RbSelectionComponent,
       RbTargetComponent,
       RbAdvanceOptionComponent,
       RbLauncherComponent,
       PaginationComponent,
       NewPaginationComponent,
       PaginationComponent,
       LoaderComponent,
       RunflowComponent,
       RbResultsComponent,
       AttckSurfaceComponent,
       ClickOutsideDirective,
       AttackListAssessmentDetailsComponent,
       RbResumeComponent,
       GraphRunstepComponent,
       AssesmentTargetListComponentComponent
      ],
  imports: [
    CommonModule,
    FormsModule,
    RunbookRoutingModule,
    PipeModule,
    NgxGraphModule,
    AvatarModule,
    InfiniteScrollModule,
    MarkdownModule.forRoot(),
    NgWizardModule.forRoot(ngWizardConfig),
    CollapseModule.forRoot()
  ]
})
export class RunbookModule { }
