import { Injectable, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

import { HttpService } from "./http.service";
import { PelagoService } from "./pelago.service"

import {
    AirtableConfig
} from "../../environments/environment";

@Injectable()
export class AirtableService {

    public airTableConfig = AirtableConfig.url;

    private headers = new Headers({
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("id_token"),
	});


    constructor(
		public httpSVC: HttpService
	) { }

    public getAtomicRunBookDetails() {
		var url = this.airTableConfig + "/at/api/modules/";
		return this.httpSVC.get(url);
	}
}