import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-related-domains",
  templateUrl: "./related-domains.component.html",
  styleUrls: ["./related-domains.component.scss"],
})
export class RelatedDomainsComponent implements OnInit {
  @Input() relatedDomains: any;
  public seeMore = 10;
  constructor() {}

  public allRelatedDomains;
  public showError;
  public showSpinner;
  ngOnInit() {
    if (this.relatedDomains && !this.relatedDomains.relatedDomains.length) {
      this.showError = "Fetching data.";
    } else if (
      this.relatedDomains &&
      this.relatedDomains.relatedDomains.length
    ) {
      this.relatedDomains.relatedDomains.sort();
      this.allRelatedDomains = this.relatedDomains.relatedDomains.slice();
    }
  }
  searchRDomain(rDomain) {
    var newRelatedDomain = [];
    this.relatedDomains["relatedDomains"] = this.allRelatedDomains.slice();
    this.relatedDomains["relatedDomains"].forEach((element) => {
      if (
        element.domain_name.toLowerCase().indexOf(rDomain.toLowerCase()) >= 0
      ) {
        newRelatedDomain.push(element);
      }
    });
    this.relatedDomains["relatedDomains"] = newRelatedDomain;
  }
}
