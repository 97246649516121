import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { UploadEvidenceService } from 'app/_services/upload-evidence.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
@Component({
	selector: 'app-vulnerability-evidence',
	templateUrl: './vulnerability-evidence.component.html',
	styleUrls: ['./vulnerability-evidence.component.scss']
})
export class VulnerabilityEvidenceComponent implements OnInit {

	@Input() vul_type: any;
	@Input() cve_id: any;
	@Input() ip: any;
	@Input() ser: any;
	@Input() mainDomain;
	@Input() web_app: any;
	@Input() location: any;
	@Input() risk_view_mode: boolean = false;
	@Input() risk_evidences: any;

	public evidence_file: File = null;
	public evidence_text = ""
	public evidence_sign_url = null
	public evidence_uploadDataDetails = null
	public evidence_service = null
	public evidences:any = null
	public sub_folder_name : any = null
	public can_add_evidence : boolean =  true
	public evidence_modal_loading : boolean = false
	public file_uploading: boolean =  false;
	public sortedEvidence:any = [];
	constructor(
		public commonService: CommonService,
		public modalRef: BsModalRef,
		public modalService: BsModalService,
		public uploadEvidenceService: UploadEvidenceService,
		public toastr: ToastrService
	) { }

	ngOnInit(): void {
	}
	openEvidence(template: TemplateRef<any>) {
		(async () => {
			this.evidence_modal_loading = true
			if (this.vul_type == "service" && !this.risk_view_mode) {
				this.ip.services.forEach((item) => {
					if (item.id == this.ser.service_id) {
						this.evidence_service = item.service_name
					}
				});
				if (this.ser.evidence) {
					for (const item in this.ser.evidence) {
						let url = await this.openEvidenceUrl(this.ser.evidence[item]["bucket_name"], this.ser.evidence[item]["blob_name"])
						if (this.evidences) {
							this.evidences[item] = { "sign_url": url, ...this.ser.evidence[item] }
						} else {
							this.evidences = { 1: { "sign_url": url, ...this.ser.evidence[item] } }
						}
					}
					if (Object.keys(this.ser.evidence).length == 0) {
						this.evidences = {}
					}
					if (Object.keys(this.evidences).length >= 10) {
						this.can_add_evidence = false
					}
					this.sortEvidence()
				}
				this.sub_folder_name = "service_" + this.ser.servicecve_id
			}
			else if (this.vul_type == "web_app" && !this.risk_view_mode) {
				let data = await this.getWebAppCVELocationsEvidences()
				if (data['data'].hasOwnProperty('locations')){
					this.evidences = data['data']['locations']['evidence']
					if (this.evidences) {
						for (const item in this.evidences) {
							let url = await this.openEvidenceUrl(this.evidences[item]["bucket_name"], this.evidences[item]["blob_name"])
							this.evidences[item]['sign_url'] = url
						}
						if (Object.keys(this.evidences).length >= 10) {
							this.can_add_evidence = false
						}
						this.sortEvidence()
					}
				} else {
					this.evidences = {}
				}
				
				this.sub_folder_name = "web_app_" + this.web_app.applicationcve_id.join('_')
			} else if (this.risk_view_mode) {
				this.can_add_evidence = false
				this.evidences = this.risk_evidences
				for (const item in this.evidences) {
					let url = await this.openEvidenceUrl(this.evidences[item]["bucket_name"], this.evidences[item]["blob_name"])
					this.evidences[item]['sign_url'] = url
				}
				this.sortEvidence()
			}
			this.evidence_modal_loading = false
			this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal vuln-evidence' });
		})()
	}

	async uploadEvidence(event) {
		if (this.evidence_file) {
			this.file_uploading = true
			await this.getSignedUrlfForEvidence();
			if (this.evidence_file && this.evidence_sign_url) {
				var reader: any = new FileReader();
				reader.readAsArrayBuffer(this.evidence_file);
				reader.onload = (e) => {
					try {
						let a = e.target.result;
						this.uploadEvidenceService
							.uploadFileToSignedUrl(this.evidence_sign_url, a, this.evidence_file.type)
							.subscribe(
								(data) => {

									let evidence_data = {
										evidence_text: this.evidence_text,
										bucket_name: this.evidence_uploadDataDetails.bucket_name,
										blob_name: this.evidence_uploadDataDetails.blob_name
									}
								
									let uploadData:any = null
									if (this.vul_type =='service') {
										uploadData = {
											ip_address: this.ip.ip_address,
											service_name: this.evidence_service,
											vul_type: this.vul_type,
											cve_id: this.cve_id,
											...evidence_data
										}
										
									}
									else if (this.vul_type == 'web_app') {
										uploadData = {
											web_app: this.web_app.source_url,
											web_app_location: this.location,
											vul_type: this.vul_type,
											cve_id: this.cve_id,
											...evidence_data
										}
									}
									this.uploadEvidenceService.updateEvidence(this.mainDomain, 
										uploadData
									).subscribe(
										(data) => {
											let url : any = null;
											this.uploadEvidenceService
											.openEvidenceUrl(
												this.mainDomain,
												{
													bucket_name: evidence_data.bucket_name,
													blob_name: evidence_data.blob_name
												}
											)
											.subscribe(
												(data) => {
													url = data["url"];
													if (this.evidences) {
														this.evidences[Object.keys(this.evidences).length + 1] = {
															...evidence_data,
															"sign_url" : url
														}
													}
													else {
														this.evidences = {
															1: {
																...evidence_data,
																"sign_url" : url
															}
														}
													}
													this.sortEvidence()
													this.evidence_file = null
													this.evidence_text = null
													this.evidence_sign_url = null
													this.evidence_uploadDataDetails = null
													this.file_uploading = false
													if (Object.keys(this.evidences).length >= 10) {
														this.can_add_evidence = false
													}	
												},
												(error) => {
													alert('Sign Url generation error.')
													this.file_uploading = false
												}
											)
											
										}
									)
								},
								(error) => {
									alert("Something went wrong!");
									this.file_uploading = false
								}
							);
					} catch (e) {
						console.log(e)
						alert("Please enter valid CSV file");
						this.file_uploading = false
					}
				};
			} else {
				alert("Somethig went wrong, Please try again!");
				this.file_uploading = false
			}
		}

	}
	onEvidenceSelect(event) {
		this.evidence_file = null;
		this.evidence_file = event.target.files[0];
		if (!this.evidence_file || !this.evidence_file.name || !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(this.evidence_file.type )) {
			alert(" Invalid file (Only JPEG | PNG | PDF files cane be uploaded.)");
			this.evidence_file = null
			return;
		}
		event.target.value =null;
	}
	getSignedUrlfForEvidence() {
		let random = Math.random().toString(20).substr(2, 6);
		let file_name = this.commonService.conevrtStringToSnakeCase(this.evidence_file.name)

		let dataToSend = {
			content_type: this.evidence_file.type,
			file_name:
				random +
				"_" + file_name
			,
			sub_folder: this.sub_folder_name,
		};
		return new Promise((resolve, reject) => {
			this.uploadEvidenceService
				.getSignedUrlForUploadEvidence(
					this.mainDomain,
					dataToSend
				)
				.subscribe(
					(data) => {
						this.evidence_sign_url = data["data"]["upload_url"];
						this.evidence_uploadDataDetails = data["data"];
						resolve(data["data"]["upload_url"]);
					},
					(error) => {
						if (error["status"] === 403) {
							alert(
								"You do not have permission to perform this action. Please write to us at contact@firecompass.com."
							);
							reject("Signed url generation failed");
						} else {
							alert("Error while getting platform counts!");
							reject("Signed url generation failed");
						}
					}
				);
		});
	}

	openEvidenceUrl(bucket_name, blob_name) {
		return new Promise((resolve, reject) => {
			this.uploadEvidenceService
				.openEvidenceUrl(
					this.mainDomain,
					{
						bucket_name: bucket_name,
						blob_name: blob_name
					}
				)
				.subscribe(
					(data) => {
						resolve(data["url"]);
					},
					(error) => {
						alert('url view error.')
						reject("Signed url generation failed");
					}
				)
		})
	}

	getWebAppCVELocationsEvidences() {
		return new Promise((resolve, reject) => {
			this.uploadEvidenceService
				.getWebCVELocationsEvidences(
					this.mainDomain,
					{
						web_app: btoa(this.web_app.source_url),
						cve_id: this.cve_id,
						location : btoa(this.location)
					}
				)
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => {
						alert('location evidence error')
						reject("location evidence error");
					}
				)
		})
	}

	sortEvidence(){
		// function sorter(a, b) {
		// 	a = parseInt(a)
		// 	b = parseInt(b)
		// 	console.log('comparing', a, b)
		// 	if (a < b) return -1;  // any negative number works
		// 	if (a > b) return 1;   // any positive number works
		// 	return 0; // equal values MUST yield zero  {1:{}, 2:{}}
		//   }
		// this.evidences_filtered = Object.keys(this.evidences).sort(sorter).reduce(
		// 	(obj, key) => { 
		// 	  obj[key] = this.evidences[key]; 
		// 	  return [obj];
		// 	}, 
		// 	{}
		//   );
		this.sortedEvidence = [];
		Object.keys(this.evidences).map(x=>{
			this.sortedEvidence.push({[x]:this.evidences[x]});
		})
		if (this.vul_type == "service" && !this.risk_view_mode) {
			this.ser.evidence = this.evidences
		}
		return this.sortedEvidence;
	}

	deleteEvidence(evidenceKey) {
		let uploadData: any = {}
		if (this.vul_type == 'service') {
			uploadData = {
				ip_address: this.ip.ip_address,
				service_name: this.evidence_service,
				vul_type: this.vul_type,
				cve_id: this.cve_id,
				evidence_key: evidenceKey
			}
		} else if (this.vul_type == 'web_app') {
			uploadData = {
				web_app: this.web_app.source_url,
				web_app_location: this.location,
				vul_type: this.vul_type,
				cve_id: this.cve_id,
				evidence_key: evidenceKey
			}
		}
		this.uploadEvidenceService.deleteEvidence(this.mainDomain,
			uploadData
		).subscribe(
			(data) => {
				if (data['status'] == 'success') {
					let new_evidence = {}
					delete this.evidences[evidenceKey];
					for (const item in this.evidences) {
						if (parseInt(item) > parseInt(evidenceKey)) {
							new_evidence[parseInt(item) - 1] = this.evidences[item]
						}
						else {
							new_evidence[parseInt(item)] = this.evidences[item]
						}
					}
					this.evidences = new_evidence
					this.sortEvidence()
					if (Object.keys(this.evidences).length >= 10) {
						this.can_add_evidence = false
					}
					else {
						this.can_add_evidence = true
					}
					this.toastr.success('Evidence deleted successfully.');
				}else{
					this.toastr.error('Failure while deleting evidence.');
				}
			},
			(error) => {
				// alert('Failure while deleting evidence.');
				this.toastr.error('Failure while deleting evidence.');
			}
		)



	}
}
