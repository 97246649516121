import { filter } from "rxjs/operators";
import { Component, TemplateRef, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { LogoutEmmitor } from "app/_services/logout-emitor.service";
import { max_hours_of_inactivity_before_logout } from "../../../environments/environment"
import { Session_expire_commonService } from "app/_services/session_expire_common.service";
@Component({
  selector: "app-breadcrumbs",
  template: `<div style="justify-content: space-between; display: flex;">
      <div>
      <ng-template
        ngFor
        let-breadcrumb
        [ngForOf]="breadcrumbs"
        let-last="last"
      > 
        <li
          class="breadcrumb-item"
          *ngIf="breadcrumb.label.title"
          [ngClass]="{ active: last }"
        >
          <span> {{ breadcrumb.label.title }} </span>
          <span
            *ngIf="last"
            (click)="openModal(domainChange, breadcrumb.label.title)"
          >
            <i class="fas fa-edit" aria-hidden="true" *ngIf="enableChangeDomain"></i
          ></span>
        </li>
      </ng-template>
      </div>
      <div>
        <span *ngIf="domainScope" class="text-primary" >Scope is enabled</span>
      </div>
    </div>
    <ng-template #domainChange>
      <div style="width: 400px; position: absolute;left: 37%;top: 50%;">
        <div class="modal-body" style="max-height: 600px;overflow: auto;">
          <div class="card">
            <h4
              class="modal-title pull-left"
              style="margin-bottom: 25px; margin-left: 12px;"
            >
              Select Domain
              <span class="pull-right" style="margin-right:10px;">
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="this.modalRef.hide()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </h4>
            <app-main-domain-selection
              (saveDone)="this.modalRef.hide()"
            ></app-main-domain-selection>
          </div>
        </div>
      </div>
    </ng-template>`,
})
export class AppBreadcrumbs implements OnInit {
  breadcrumbs: Array<Object>;
  domainScope:any = false;
  enableChangeDomain:boolean = true;
  private max_hours_of_inactivity_before_logout:number =  max_hours_of_inactivity_before_logout.hour; // hour
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private portfolioSurveyService: PortfolioSurveyService,
    private logoutEmitor: LogoutEmmitor,
    public sessionExpireCommonService: Session_expire_commonService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.checkDomain();
        this.breadcrumbs = [];
        let currentRoute = this.route.root,
          url = "";
        this.checkAndHideEdit();
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach((route) => {
            if (route.outlet === "primary") {
              const routeSnapshot = route.snapshot;
              url +=
                "/" +
                routeSnapshot.url.map((segment) => segment.path).join("/");
              this.breadcrumbs.push({
                label: route.snapshot.data,
                url: url,
              });
              currentRoute = route;
            }
          });
        } while (currentRoute);
        route.queryParams.subscribe((params) => {
          if (params && params["domain"]) {
            this.breadcrumbs.push({
              label: { title: params["domain"] },
              url: "/new/self-domains",
            });
            // localStorage.setItem("mydomain-1", params["domain"]);
          } else {
            const domain = localStorage.getItem("mydomain");
            this.breadcrumbs.push({
              label: { title: domain },
              url: "/new/self-domains",
            });
          }
        });
      });
  }
  public allUserDomains;
  public selfDomainCount: number = 0;
  public logoutEmitorSubscription:any = null;
  ngOnInit() {
    this.getDomains();
    this.checkDomain();
    this.logoutEmitorSubscription = this.logoutEmitor.onChange().subscribe(data => {
      if (this.modalRef) this.modalRef.hide();
    });
  }
  ngOnDestroy() {
    this.logoutEmitorSubscription.unsubscribe();
    if(this.modalRef) this.modalRef.hide();
  }
  checkDomain(){
    let that = this;
    setTimeout(function(){
      that.domainScope = (localStorage.getItem('scope_config')==='true');
    },1000);
  }
  getDomains() {
    this.portfolioSurveyService.getDomainsSearchForUser().subscribe((data) => {
      this.allUserDomains = data;
      this.allUserDomains.forEach((ele) => {
        if (ele.domain_type == "self") {
          ++this.selfDomainCount;
        }
      });
    });
  }
  openModal(template: TemplateRef<any>, data) {
    if (!this.sessionExpireCommonService.isSessionTimedOut()) {
      this.modalRef = this.modalService.show(template, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }
  }
  checkAndHideEdit(){
    let url = this.router.url;
    let baseUrl:any = [];
    if(url) baseUrl = this.router.url.split('?');
    this.enableChangeDomain = (baseUrl && baseUrl[0]=="/dashboard/delta")?false:true;
  }
}
