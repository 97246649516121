import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable({
	providedIn:"root"
})
export class MultiErrorEmitService {
	public _subject = new BehaviorSubject<any>('');
	errors:any = [];
	timeout:number = 4000;
	currentTimeout:any;
	alertType="error";

	constructor(
		private toaster:ToastrService,
		public route: ActivatedRoute,
    	public router: Router,
	){

	}

	init(alertType:string=null, timeout:number=null){
		if(timeout){
			this.timeout = timeout;
		}
		if(alertType){
			this.alertType = alertType;
		}
	}

	emit(data){
		console.log('emit',data);
		this._subject.next(data);
		this.log(data);
	}
	on(){
		console.log('On');
		return this._subject.asObservable();
	}

	log(error){
		console.log("..................",error)
		this.errors.push(error);
		if(!this.currentTimeout){
			let this_ = this;
			this.currentTimeout = setTimeout(() => {
				this.alertNow();
			}, this.timeout);
		}
	}

	alertNow(){
		console.log('Alerted',this.errors);
		let errorMessage = '';
		if(this.errors.length==1){
			errorMessage = this.errors[0].message;
		}else{
			errorMessage = 'Something went wrong. Please try again later.';
		}
		
		console.log(errorMessage);
		if(this.alertType=="error"){
			this.toaster.error(errorMessage);
		}else if(this.alertType=="info"){
			this.toaster.info(errorMessage);
		}else if(this.alertType=="success"){
			this.toaster.success(errorMessage);
		}else if(this.alertType=="warning"){
			this.toaster.warning(errorMessage);
		}
		this.resetVariables();
	}

	resetVariables(){
		this.errors = [];
		this.currentTimeout = null;
	}
	
	cancle(){
		if(this.currentTimeout){
			clearTimeout(this.currentTimeout);
			this.resetVariables();
		}
	}
	errorCodeHandel(error){
		console.log("in here...............",error)
		let showError
		let noPremission
		if (error.status === 504) {
			showError = "Time error! This error occurs while fetching data.";
		  } else if (error.status === 403 && !noPremission) {
			this.alertType  = 'error'
			showError = "Forbidden! You are not allowed to perform this action."
			this.log(error)
			noPremission = true;
			// alert(
			//   "Forbidden! You are not allowed to perform this action. Please contact us at contact@firecompass.com"
			// );
			this.router.navigate(["/new/self-domains"]);
		  } else {
			showError = "API not found! Error while connecting to backend.";
		  }
	}
}