import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { PortfolioSurveyService } from "../../_services";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../_services/common.service';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-poc-comments',
  templateUrl: './poc-comments.component.html',
  styleUrls: ['./poc-comments.component.scss']
})
export class PocCommentsComponent implements OnInit {
  @Input() modalRef;
  @Input() subject;
  @Input() mainDomain

  public comment = {
    "subject_type": null,
    "subject_name": null,
    "title": null,
    "description": null,
    "main_domain_name": null,
    "creator": null,
    "created_by": null,
    "subject_id": null,
    "visible_to": null
  }

  public updatedComments = [];
  public allComments = [];
  public newComments = [];
  public editIndex: number;
  public editComment: boolean = false;
  public showDowloadText: boolean = false;
  public delComment
  public showSpinner: boolean = false;
  public fieldsToDownload = {
    "Assets": "subject_name",
    "Comment": "title",
    "Description": "description",
    "Commented By": "creator",
    "Commented At": "created_at",
    "Updated At": "updated_at"
  };
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRefhere: BsModalRef,
    public commonService: CommonService,
    private toaster:ToastrService
  ) { }

  ngOnInit(): void {
    this.comment.main_domain_name = this.mainDomain;
    this.comment.subject_name = this.subject['subject_name']
    this.comment.subject_type = this.subject['subject_type']
    if (this.subject['subject_id']) {
      this.comment.subject_id = this.subject['subject_id']
      }
    if (this.subject['visible_to']) {
      this.comment.visible_to = this.subject['visible_to']
      }
    this.getPocComments()
  }
  public getPocComments() {
		this.showSpinner=true
    this.portfolioSurveyService.getPocComments(this.mainDomain, this.subject['subject_name'], this.subject['subject_type'], this.subject['subject_id'], this.subject['visible_to']).subscribe((data: any) => {
      this.allComments = data.data;
      // console.log(this.allComments);
      this.allComments.sort(function(a,b){
        let timea:any = new Date(b.updated_at);
        let timeb:any = new Date(a.updated_at);
        return  timea - timeb;
      });
      this.showSpinner = false
    }, error => {
      this.showSpinner = false;
    })
  }
  updatePocComments() {
    if (this.updatedComments && this.updatedComments.length) {
      this.portfolioSurveyService.updatePocComments(this.updatedComments, this.mainDomain).subscribe((data: any) => {
        if(data && data['status']=='pass'){
          this.toaster.success('Comment updated successfully.');
        }else if(data && data['status']=='fail'){
          this.toaster.error('Failed to update comment.');
        }
        this.updatedComments = []
      })
    }
  }

  public doneWithChanges() {
    this.createPocComments()
    this.updatePocComments()
  }
  public createPocComments() {
    if (this.newComments && this.newComments.length) {
      this.portfolioSurveyService.createPocComments(this.newComments, this.mainDomain).subscribe((data: any) => {
        if(data && data['status']=='pass'){
          this.toaster.success('Comment added successfully.');
        }else if(data && data['status']=='fail'){
          this.toaster.error('Faild to add comment. Try again later.');
        }
        this.newComments = []
      })
    }
  }
  addNewComment(comment) {
    this.allComments.push({ ...comment })
    this.newComments.push({ ...comment })
    this.comment.title = null;
    this.comment.description = null;
  }
  updateComments(comment) {
    this.updatedComments.push({ ...comment });
  }
  delCommentModel(template: TemplateRef<any>, comment) {

    this.delComment = comment
    this.modalRefhere = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  delData() {
    this.delComment.archive = true
    this.portfolioSurveyService.updatePocComments([this.delComment], this.mainDomain).subscribe((data: any) => { })
    this.modalRefhere.hide()

  }
  cancelDelete() {
    this.delComment = {}
    this.modalRefhere.hide()
  }

}