import { Component, OnInit } from '@angular/core';
import {Router, Route, ActivatedRoute, Params} from "@angular/router";
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public route: ActivatedRoute,

  ) { }
  public displayPredifine;
  public preDefineResponseData=[]
  public customResponseData=[]
  public vendorDetails;
  public questionResponseDeatis;
  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      let set_id = params['vendor_project_id'];
      this.portfolioSurveyService.getvendorResponceForProject(set_id).subscribe((data:any)=>{
        this.preDefineResponseData=data.getPreDefQue;
        this.customResponseData=data.getCustomQue
        this.vendorDetails = data.vendorProjectDetails;
        if(this.vendorDetails){
          this.portfolioSurveyService.getProjectDetails(this.vendorDetails.question_set_id).subscribe(data => {
            this.questionResponseDeatis=data
          })
        }
      })
    })
  }
  getPreQueDetails(index){
    this.displayPredifine=index
  }
}