import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rb-launcher',
  templateUrl: './rb-launcher.component.html',
  styleUrls: ['./rb-launcher.component.scss']
})
export class RbLauncherComponent implements OnInit {
  public createNew = {
    name: null,
    job_type: "assesment",
    delta_duration: '',
    schedule: {
      crontab: {},
      one_off: true,
      one_off_dupe: true
    },
    job_config: {
      frequency: null,
      duration: {
        fromDate: null,
        toDate: null,
      }
    }
  }
  public todaysDate = new Date().toISOString().split("T")[0];
  public timeForScheduler = null;
  public dateForOneTime = null
  public monthDay;
  public freqSentance = {
    "0":"One time",
    "1":"Daily",
    "7":"Weekly",
    "30":"Monthly"
  }


  @Output() newAssessment = new EventEmitter<any>();
  @Input() showError: Boolean
  @Input() selectedRunbook

  constructor() { }

  ngOnInit(): void {
    Array.from({ length: 10 }, (_, i) => i + 1);
    this.monthDay = Array.from({ length: 31 }, (_, i) => i + 1);
    console.log('selectedRunbook',this.selectedRunbook)
  }
  isdelta(){
    if(this.selectedRunbook?.config?.target?.delta_attack_surface?.value){
      return true;
    }else{
      return false;
    }
  }
  switchDuration() {
    this.dateForOneTime = null;
    if (this.createNew.schedule.one_off) {
      this.resetCronTab();
    } else {
      this.resetDuration();
    }
  }
  resetCronTab() {
    delete this.createNew.schedule.crontab['minute']
    delete this.createNew.schedule.crontab['hour']
    delete this.createNew.schedule.crontab['day_of_week']
    delete this.createNew.schedule.crontab['day_of_month']
    delete this.createNew.schedule.crontab['month_of_year']
    this.createNew.schedule.one_off = true
    this.createNew.delta_duration = '';
  }
  resetDuration() {
    // this.createNew.schedule.crontab = {
    //   minute: "*",
    //   hour: "*",
    //   day_of_week: "*",
    //   day_of_month: "*",
    //   month_of_year: "*",
    // };
    this.resetCronTab()
    this.createNew.job_config.frequency = null;
    this.createNew.job_config.duration = {
      fromDate: null,
      toDate: null,
    };
    this.emitChanges();
  }
  emitChanges() {
    this.createNew.name = this.createNew.name?.trim()
    // this.newAssessment['advanceOptions'] = this.advanceOptions
    this.newAssessment.emit(this.createNew)
  }
  crontabTimeChange() {
    const nowDateTime = new Date();
    if (!this.createNew.schedule.one_off_dupe) {
      if (this.timeForScheduler) {
        let arr = this.timeForScheduler.split(":");
        nowDateTime.setHours(arr[0]);
        nowDateTime.setMinutes(arr[1]);
        this.createNew.schedule.crontab['hour'] = nowDateTime.getUTCHours() + "";
        this.createNew.schedule.crontab['minute'] = nowDateTime.getUTCMinutes() + "";
      }
    }
    this.emitChanges()
  }
  changeOneOf() {
    this.dateForOneTime = null;
    if (this.createNew.job_config.frequency == 0)
      this.createNew.schedule.one_off = true
    else
      this.createNew.schedule.one_off = false
      this.createNew.schedule.crontab = {
        minute: "*",
        hour: "*",
        day_of_week: "*",
        day_of_month: "*",
        month_of_year: "*",
      };
      this.createNew.job_config.duration = {
        fromDate: null,
        toDate: null,
      };
      this.timeForScheduler = null
    // console.log(".............................",this.createNew.schedule.crontab)
  }
  crontabDateChange() {
    let oneTimeDate = new Date(this.dateForOneTime)
    this.createNew.schedule.crontab['day_of_week'] = oneTimeDate.getDay()+""
    this.createNew.schedule.crontab['day_of_month'] = oneTimeDate.getDate()+""
    this.createNew.schedule.crontab['month_of_year'] = oneTimeDate.getMonth()+1+""
    this.createNew.schedule.one_off = true
  }
}
