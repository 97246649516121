import { Injectable, EventEmitter, PLATFORM_ID, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { Location, isPlatformBrowser } from "@angular/common";
import { AngularFireAuth } from "@angular/fire/auth";
@Injectable({
	providedIn: 'root'
})
export class Auth_logoutService {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        public router: Router,
        public location: Location,
        public auth: AngularFireAuth,
    ) {
    }

    logoutEmmiter = new EventEmitter<boolean>();

    public logout(): void {
        console.log("Logout");
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("mydomain");
            localStorage.removeItem("access_token");
            localStorage.removeItem("id_token");
            localStorage.removeItem("profile");
            localStorage.removeItem("socialLogin");
            localStorage.removeItem("isAuthenticated");
            localStorage.removeItem("whiteLabel");
            localStorage.setItem("scope_config", "false");
            localStorage.setItem("lasActivity", null);
            // localStorage.removeItem("mydomain");
            this.auth.signOut();
            this.logoutEmmiter.emit(false);
            // location.href = 'https://www.firecompass.com/dashboard/#/pages/login';
            this.router.navigate(["/pages/login"], {queryParams: {"refresh": 1}});
        }
    }
}
