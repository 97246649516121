import {
  Component,
  OnInit,
  AfterViewChecked,
  DoCheck,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { ProfileTypePipe } from "../../pipes/profile-type.pipe";
import { SortPipe } from "../../pipes/sort.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { classicNameResolver } from "typescript";

@Component({
  selector: "app-dashboard-vendor",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, AfterViewChecked, DoCheck {
  public domain;
  public previousDomains = [];
  public showSpinner;
  public currentVendor = null;
  public showError;
  public showUpgradeWedgit = false;
  public userAllowed = false;
  public freeTrials = 1;
  public myDomain;
  public sortWith = "domain";
  public order = 1;
  public vendorNames = [];
  public topVendorNumber: number = 5;
  public topVendors = [];
  public vendors;
  public usertype;
  public selectedVendorValue: string = "LeakedCredentials";
  public vendorsByRiskScore = [];
  public sortpipe = new SortPipe();
  public toggle = {};
  public sortWithLabel = "Vendor Risk";
  public openDropdown = false;
  public showItems = 5;
  public viewType = "gridView";
  //   public emailText;
  //   public nameText;
  public mailBody = null;
  public contactNumber;
  public vendorToContact;
  //   public contactnumber;
  public userDetails = {};
  public sortMapForBusinessCriticality = {
    high: 1,
    medium: 2,
    low: 3,
  };
  public sortMapForTopRisk = {
    High: 1,
    Medium: 2,
    Low: 3,
  };
  public topRisk = [];

  public riskVendorArray: any[] = [
    { data: 0, vendorNames: [] },
    { data: 0, vendorNames: [] },
    { data: 0, vendorNames: [] },
    { data: 0, vendorNames: [] },
    { data: 0, vendorNames: [] },
    { data: 0, vendorNames: [] },
    { data: 0, vendorNames: [] },
    { data: 0, vendorNames: [] },
    { data: 0, vendorNames: [] },
  ];

  public barChartOptions: any;
  public barChartLabels: Array<any> = [];
  public barChartType = "horizontalBar";
  public barChartLegend = true;
  public barChartData: any[] = [{ data: [], label: "" }];
  public barChartColors = [{ backgroundColor: [] }];

  public barChartLabels2: Array<any> = [];
  public barChartData2: any[] = [{ data: [], label: "" }];
  public barChartColors2 = [{ backgroundColor: [] }];
  public openSlider;
  public i;
  public showThirdParty = false;
  constructor(
    public router: Router,
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.setBarChart();
    this.toggle["switch"] = false;
    this.getUserVendorDomains();
  }

  ngAfterViewChecked() {
    // this.sortWith = 'overAllGrade';
  }

  ngDoCheck(): void {
    // this.TopVendorRisk(this.selectedVendorValue);
  }

  setBarChart() {
    this.usertype = new ProfileTypePipe().transform("usertype");
    this.barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      legend: {
        display: false,
      },
      // title: {
      //   display: true,
      //   text: "Vendor Risk Score",
      //   fontSize: 18,
      //   fontColor: 'black'
      // },
      tooltips: {
        callbacks: {
          label: function (value, index, values) {
            // if ((value.yLabel > 0 && value.yLabel < 5)) {
            //   return String.fromCharCode(65 + value.yLabel - 1);
            // } else if (value.yLabel == 5) {
            //   return String.fromCharCode(65 + value.yLabel);
            // }
            return value.xLabel;
          },
        },
      },
      scales: {
        xAxes: [
          {
            barPercentage: 0.5,
            scaleLabel: {
              display: true,
              labelString: "Security Score",
              fontColor: "black",
              fontSize: 16,
            },
            // gridLines: {
            //   tickMarkLength: 1,
            //   color: 'rgba(255, 0, 0, 1)' // makes grid lines from y axis red
            // },
            ticks: {
              min: 0,
              beginAtZero: true,
              steps: 4,
              stepValue: 4,
              // stepSize: 1,
              // max: 5,
              max: 1000,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.usertype,
              fontColor: "black",
              fontSize: 16,
            },
            // gridLines: {
            //   tickMarkLength: 0.5,
            //   color: 'rgba(255, 0, 0, 1)' // makes grid lines from y axis red
            // },
            // ticks: {
            //   min: 0,
            //   beginAtZero: true,
            //   steps: 4,
            //   stepValue: 4,
            //   // stepSize: 1,
            //   // max: 5,
            //   max: 1000,
            // stepHeight: 1,
            // converting into string
            // callback: function (value, index, values) {
            //   if ((value > 0 && value < 5)) {
            //     return String.fromCharCode(65 + value - 1);
            //   } else if (value == 5) {
            //     return String.fromCharCode(65 + value);
            //   }
            // }
            // }
          },
        ],
      },
    };
  }

  goToExternamOsint(domain) {
    window.open("#/new/show-external-score?domain=" + domain, "_blank");
  }

  getUserVendorDomains() {
    this.portfolioSurveyService.getDomainsSearchForUser().subscribe(
      (data: any) => {
        this.previousDomains = data.filter((d) => {
          if (d["domain_type"] == "self") {
            this.currentVendor = d["domain"];
          }
          return d["domain_type"] != "self";
        });
        this.previousDomains.forEach((pDomain, index) => {
          // this.vendorNames.push(pDomain.domain)

          // this.barChartLabels.push(pDomain.domain);
          // this.barChartLabels = Object.assign([], this.barChartLabels);

          this.sortWith = "vendor_risk";
          this.getRisksForDomain(pDomain, index);
          this.getProbableRiskForDomain(pDomain, index);
          this.getOverallRiskScoreForDomain(pDomain, index);
          this.getTopRisk(pDomain);
          // this.getAllDetailsOsint(pDomain, index);
          // this.getBreachedPassByDomain(pDomain, index);
        });
        this.showSpinner = false;
      },
      (error) => {
        this.showSpinner = false;
        this.showError =
          "Error while connecting to server! Please try after some time.";
      }
    );
  }

  getTopRisk(domain) {
    this.portfolioSurveyService
      .getTopRisk(domain["domain"])
      .subscribe((data: any) => {
        this.topRisk.push(...data.data);
        this.topRisk = this.sortpipe.transform(
          this.topRisk,
          "risk_level",
          1,
          true,
          this.sortMapForTopRisk
        );
      });
  }

  public getOverallRiskScoreForDomain(domainDetails, index) {
    this.portfolioSurveyService
      .getOverallScoreForDomain(domainDetails.domain)
      .subscribe((data) => {
        if (data && data["status"] == "pass") {
          data["data"]["domain_name"] = domainDetails["domain"];
          data["data"]["business_criticality"] =
            domainDetails["business_criticality"];
          Object.assign(domainDetails, data["data"]);
          // this.vendorsByRiskScore.push({domainDetails['domain'] : data['data']})
          // this.calculateBusinessRiskDataBasedOnOverallScore(domainDetails, index);
          // this.barChartLabels = Object.assign([], this.barChartLabels);
          // this.barChartData = Object.assign({}, this.barChartData);
          // this.barChartColors = Object.assign({}, this.barChartColors);
          this.previousDomains = this.sortpipe.transform(
            this.previousDomains,
            "overall_score",
            1,
            true
          );
          this.vendorNames.push(domainDetails.domain);
          if (this.vendorNames.length == this.previousDomains.length) {
            this.calculateBusinessRiskDataBasedOnOverallScore(
              domainDetails,
              index
            );
            this.calculateBusinessRiskDataBasedOnBusinessCriticality(
              domainDetails,
              index
            );
          }
        }
      });
  }

  getRisksForDomain(domain_details, index) {
    this.portfolioSurveyService
      .getRisksForDomain(domain_details.domain)
      .subscribe((data) => {
        Object.assign(domain_details, data["data"]);
      });
  }

  getProbableRiskForDomain(domain_details, index) {
    this.portfolioSurveyService
      .getProbableRiskCountForDomain(domain_details.domain)
      .subscribe((data) => {
        Object.assign(domain_details, data["data"]);
      });
  }

  // getAllDetailsOsint(pDomain, index) {
  //   this.portfolioSurveyService.getAllDetailsOsint(pDomain.domain).subscribe(
  //     (data: any) => {
  //       if (data && data.webDetails && data.webDetails.analyze) {
  //         pDomain["webSecurityScore"] = data.webDetails
  //           ? data.webDetails.analyze.grade
  //           : null;
  //         if (pDomain["webSecurityScore"]) {
  //           this.calculateBusinessRiskDataBasedOnWebScore(pDomain, index);
  //         }
  //       } else if (
  //         data.webDetails &&
  //         JSON.parse(data.webDetails).error === "invalid-hostname"
  //       ) {
  //         pDomain["webSecurityScore"] = "NA";
  //       }
  //       if (data && data.osintDetails) {
  //         var phishingData = data.osintDetails.phishingData.phishingDomains;
  //         if (phishingData && phishingData["status"] == "success") {
  //           pDomain["phishingDomains"] =
  //             phishingData["results"] &&
  //             phishingData["results"]["DomainListSize"]
  //               ? phishingData["results"]["DomainListSize"]
  //               : "0";
  //         } else {
  //           pDomain["phishingDomains"] = "0";
  //         }
  //       }
  //       if (data && data.overAllGrade) {
  //         this.sortWith = "domain";
  //         pDomain["overAllGrade"] = data["overAllGrade"]
  //           ? data["overAllGrade"]
  //           : null;
  //       } else {
  //         pDomain["overAllGrade"] = "NA";
  //       }
  //     },
  //     (error) => {
  //       pDomain["webSecurityScore"] = "NA";
  //       pDomain["phishingDomains"] = "0";
  //     }
  //   );
  // }

  getBreachedPassByDomain(pDomain, index) {
    this.portfolioSurveyService
      .getBreachedPassByDomain(pDomain.domain)
      .subscribe(
        (data) => {
          if (data) {
            pDomain["leakedCredentials"] = data[0] ? data[0]["total"] : "0";
          }
        },
        (error) => {
          pDomain["leakedCredentials"] = "0";
        }
      );
  }

  TopVendorRisk(selectedValue: string) {
    this.selectedVendorValue = selectedValue;
    if (this.previousDomains) {
      this.topVendors = [];
      this.topVendors = this.previousDomains.slice(
        0,
        this.previousDomains.length
      );

      if (this.selectedVendorValue === "LeakedCredentials") {
        this.topVendors = this.topVendors.sort(function (a, b) {
          return b.leakedCredentials - a.leakedCredentials;
        });
      } else if (this.selectedVendorValue === "SuspectedPhishingDomains") {
        this.topVendors = this.topVendors.sort(function (a, b) {
          return b.phishingDomains - a.phishingDomains;
        });
      } else if (this.selectedVendorValue === "OverallGrade") {
        this.topVendors = this.topVendors.sort(function (a, b) {
          var a = a.overAllGrade,
            b = b.overAllGrade;
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        });
      }

      this.topVendors = this.topVendors.filter((vendor) => {
        if (vendor.leakedCredentials && vendor.overAllGrade) {
          return vendor;
        }
      });
    }
  }

  calculateBusinessRiskDataBasedOnOverallScore(domain, index) {
    this.previousDomains.forEach((pDomain, i) => {
      this.barChartLabels.push(pDomain.domain);
      // let index2 = this.barChartLabels.indexOf(pDomain['domain']);
      this.barChartData[0].data.push(pDomain["overall_score"]);
      // this.barChartLabels.push(pDomain.domain);
      if (pDomain && pDomain.overall_score && pDomain.overall_score >= 900) {
        this.barChartColors[0].backgroundColor.push("#4dbd74");
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 800
      ) {
        this.barChartColors[0].backgroundColor.push("#63c2de");
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 750
      ) {
        this.barChartColors[0].backgroundColor.push("#ffc107");
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 700
      ) {
        this.barChartColors[0].backgroundColor.push("#FFC0CB");
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 0
      ) {
        this.barChartColors[0].backgroundColor.push("#f86c6b");
      }
      // if (i + 1 == this.previousDomains.length) {
      //   this.barChartLabels = Object.assign([], this.barChartLabels);
      //   this.barChartData = Object.assign({}, this.barChartData);
      //   this.barChartColors = Object.assign({}, this.barChartColors);
      // }

      this.calculateRiskRatingBusinessCriticalityChartBasedOnOverallScore(
        pDomain
      );
    });
  } 
    calculateBusinessRiskDataBasedOnBusinessCriticality(domain, index) {
    var newDomainsSort = Object.assign([], this.previousDomains);
    newDomainsSort = this.sortpipe.transform(
      newDomainsSort,
      "business_criticality",
      1,
      true,
      this.sortMapForBusinessCriticality
    );
    newDomainsSort.forEach((pDomain, i) => {
      this.barChartLabels2.push(
        pDomain.domain + " (" + pDomain["business_criticality"] + ")"
      );
      this.barChartData2[0].data.push(pDomain["overall_score"]);
      if (pDomain && pDomain.overall_score && pDomain.overall_score >= 900) {
        this.barChartColors2[0].backgroundColor.push("#4dbd74");
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 800
      ) {
        this.barChartColors2[0].backgroundColor.push("#63c2de");
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 750
      ) {
        this.barChartColors2[0].backgroundColor.push("#ffc107");
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 700
      ) {
        this.barChartColors2[0].backgroundColor.push("#FFC0CB");
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 0
      ) {
        this.barChartColors2[0].backgroundColor.push("#f86c6b");
      }
      // if (i + 1 == newDomainsSort.length) {
      //   this.barChartLabels2 = Object.assign([], this.barChartLabels2);
      //   this.barChartData2 = Object.assign({}, this.barChartData2);
      //   this.barChartColors2 = Object.assign({}, this.barChartColors2);
      // }
      this.calculateRiskRatingBusinessCriticalityChartBasedOnOverallScore(
        pDomain
      );
    });
  }

  checkDuplicateDomainForRiskRatingBusinessCriticalityChart(domain, index) {
    if (this.riskVendorArray[index].vendorNames.indexOf(domain) == -1) {
      this.riskVendorArray[index].data = this.riskVendorArray[index].data + 1;
      this.riskVendorArray[index].vendorNames.push(domain);
    }
  }

  calculateRiskRatingBusinessCriticalityChartBasedOnOverallScore(pDomain) {
    if (pDomain["business_criticality"] == "low") {
      if (pDomain && pDomain.overall_score && pDomain.overall_score >= 850) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          6
        );
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 750
      ) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          3
        );
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 0
      ) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          0
        );
      }
    } else if (
      pDomain["business_criticality"] == "medium" ||
      pDomain["business_criticality"] == null
    ) {
      if (pDomain && pDomain.overall_score && pDomain.overall_score >= 850) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          7
        );
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 750
      ) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          4
        );
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 0
      ) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          1
        );
      }
    } else if (pDomain["business_criticality"] == "high") {
      if (pDomain && pDomain.overall_score && pDomain.overall_score >= 850) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          8
        );
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 750
      ) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          5
        );
      } else if (
        pDomain &&
        pDomain.overall_score &&
        pDomain.overall_score >= 0
      ) {
        this.checkDuplicateDomainForRiskRatingBusinessCriticalityChart(
          pDomain.domain,
          2
        );
      }
    }
  }

  // calculateBusinessRiskDataBasedOnWebScore(pDomain, index) {
  //   // uncomment this lines from line 163 and remove it from here
  //   // this.barChartLabels.push(pDomain.domain);
  //   // this.barChartLabels = Object.assign([], this.barChartLabels);
  //   console.log("....mmmm...",pDomain)
  //   if (
  //     pDomain &&
  //     pDomain.webSecurityScore &&
  //     ["A", "A-", "A+"].indexOf(pDomain.webSecurityScore) >= 0
  //   ) {
  //     this.barChartData[0].data[index] = 1;
  //     this.barChartColors[0].backgroundColor[index] = "#4dbd74";
  //   } else if (
  //     pDomain &&
  //     pDomain.webSecurityScore &&
  //     ["B", "B-", "B+"].indexOf(pDomain.webSecurityScore) >= 0
  //   ) {
  //     this.barChartData[0].data[index] = 2;
  //     this.barChartColors[0].backgroundColor[index] = "#63c2de";
  //   } else if (
  //     pDomain &&
  //     pDomain.webSecurityScore &&
  //     ["C", "C-", "C+"].indexOf(pDomain.webSecurityScore) >= 0
  //   ) {
  //     this.barChartData[0].data[index] = 3;
  //     this.barChartColors[0].backgroundColor[index] = "#ffc107";
  //   } else if (
  //     pDomain &&
  //     pDomain.webSecurityScore &&
  //     ["D", "D-", "D+"].indexOf(pDomain.webSecurityScore) >= 0
  //   ) {
  //     this.barChartData[0].data[index] = 4;
  //     this.barChartColors[0].backgroundColor[index] = "#FFC0CB";
  //     // } else if (pDomain && pDomain.webSecurityScore && ['E', 'E-', 'E+'].indexOf(pDomain.webSecurityScore) >= 0) {
  //     //   this.barChartData[0].data.push(5)
  //     //   this.barChartColors[0].backgroundColor[index] = 'yellow';
  //   } else if (
  //     pDomain &&
  //     pDomain.webSecurityScore &&
  //     ["F", "F-", "F+"].indexOf(pDomain.webSecurityScore) >= 0
  //   ) {
  //     this.barChartData[0].data[index] = 5;
  //     this.barChartColors[0].backgroundColor[index] = "#f86c6b";
  //   }
  //   this.barChartData = Object.assign({}, this.barChartData);
  //   this.barChartColors = Object.assign({}, this.barChartColors);

  //   if (pDomain["business_criticality"] == "low") {
  //     if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["A", "A-", "A+", "B", "B-", "B+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[6].data = this.riskVendorArray[6].data + 1;
  //       this.riskVendorArray[6].vendorNames.push(pDomain.domain);
  //     } else if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["C", "C-", "C+", "D", "D-", "D+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[3].data = this.riskVendorArray[3].data + 1;
  //       this.riskVendorArray[3].vendorNames.push(pDomain.domain);
  //     } else if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["E", "E-", "E+", "F", "F-", "F+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[0].data = this.riskVendorArray[0].data + 1;
  //       this.riskVendorArray[0].vendorNames.push(pDomain.domain);
  //     }
  //   } else if (
  //     pDomain["business_criticality"] == "medium" ||
  //     pDomain["business_criticality"] == null
  //   ) {
  //     if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["A", "A-", "A+", "B", "B-", "B+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[7].data = this.riskVendorArray[7].data + 1;
  //       this.riskVendorArray[7].vendorNames.push(pDomain.domain);
  //     } else if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["C", "C-", "C+", "D", "D-", "D+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[4].data = this.riskVendorArray[4].data + 1;
  //       this.riskVendorArray[4].vendorNames.push(pDomain.domain);
  //     } else if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["E", "E-", "E+", "F", "F-", "F+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[1].data = this.riskVendorArray[1].data + 1;
  //       this.riskVendorArray[1].vendorNames.push(pDomain.domain);
  //     }
  //   } else if (pDomain["business_criticality"] == "high") {
  //     if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["A", "A-", "A+", "B", "B-", "B+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[8].data = this.riskVendorArray[8].data + 1;
  //       this.riskVendorArray[8].vendorNames.push(pDomain.domain);
  //     } else if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["C", "C-", "C+", "D", "D-", "D+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[5].data = this.riskVendorArray[5].data + 1;
  //       this.riskVendorArray[5].vendorNames.push(pDomain.domain);
  //     } else if (
  //       pDomain &&
  //       pDomain.webSecurityScore &&
  //       ["E", "E-", "E+", "F", "F-", "F+"].indexOf(pDomain.webSecurityScore) >=
  //         0
  //     ) {
  //       this.riskVendorArray[2].data = this.riskVendorArray[2].data + 1;
  //       this.riskVendorArray[2].vendorNames.push(pDomain.domain);
  //     }
  //   }
  // }

  public changeOrder(orderType) {
    var element_vos = document.getElementById("vos");
    var element_vbc = document.getElementById("vbc");
    if (orderType == "business_criticality") {
      element_vbc.style.display = "inline";
      element_vos.style.display = "none";
    } else {
      element_vos.style.display = "inline";
      element_vbc.style.display = "none";
    }
  }
  modalOpen(template: TemplateRef<any>) {
    this.modalHide();
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  modalHide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.userDetails = {};
    }
  }
  sendEmail() {
    var email = this.userDetails["emailText"];
    if (this.currentVendor != null) {
      this.mailBody =
        "At " +
        this.currentVendor +
        ", we take information security very seriously." +
        "This entails securing all our data, whether it is internally managed by " +
        this.currentVendor +
        " or by our partner companies (including people, process, technology touchpoints).%0D%0A%0D%0A" +
        "As part of a preliminary and high level security program initiative - we commissioned a study by our security advisors - Firecompass. As first steps they have done an analysis and will be sharing their findings shortly.%0D%0A%0D%0A" +
        "Wanted to set the context so we can together make our systems more secure - needless to say, anything that you fix in your systems will benefit all your clients and not just " +
        this.currentVendor +
        ".%0D%0A%0D%0A";
    } else {
      this.mailBody =
        "In our organization, we take information security very seriously." +
        "This entails securing all our data, whether it is internally managed by our organization or by our partner companies (including people, process, technology touchpoints).%0D%0A%0D%0A" +
        "As part of a preliminary and high level security program initiative - we commissioned a study by our security advisors - Firecompass. As first steps they have done an analysis and we are sharing their findings with this mail.%0D%0A%0D%0A" +
        "Wanted to set the context so we can together make our systems more secure - needless to say, anything that you fix in your systems will benefit all your clients and not just our organization.%0D%0A%0D%0A";
    }
    let mail = document.createElement("a");
    var subject = "Reporting security concerns for our partner companies";
    mail.href =
      "mailto:" +
      this.userDetails["emailText"] +
      "?cc=dev@firecompass.com&subject=" +
      subject +
      "&body=Hi " +
      this.userDetails["nameText"] +
      " %0D%0A %0D%0A" +
      this.mailBody +
      "Following is the link for the same: %0D%0Ahttps://www.firecompass.com/dashboard/#/new/show-external-score";
    mail.target = "_blank";
    let contactDetails = {
      vendor_name: this.userDetails["nameText"],
      vendor_email: this.userDetails["emailText"],
      vendor_phone: this.userDetails["contactNumber"],
      vendor_domain: this.vendorToContact,
      customer_domain: this.currentVendor,
    };
    this.portfolioSurveyService
      .saveContactDetailsOfUser(contactDetails)
      .subscribe(
        (data) => {
          mail.click();
          this.modalRef.hide();
        },
        (error) => {
          mail.click();
          this.modalRef.hide();
        }
      );
  }

  // Added while migrating from Angular 8 to 9 by Kirthi
  public chartHovered(e: any): void {
    console.log(e);
  }

  public chartClicked(e: any): void {
    console.log(e);
  }
}
