import { Component, OnInit, Input, TemplateRef, ContentChild } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from 'app/_services';
import { delay} from 'rxjs/operators';


@Component({
	selector: 'app-leaked-content',
	templateUrl: './leaked-content.component.html',
	styleUrls: ['./leaked-content.component.scss']
})
export class LeakedContentComponent implements OnInit {
	@Input() mainDomain: any;
	@Input() leakedContents;
	@Input() webDocuments;
	@Input() isUserTrial = false;
	@Input() showExecutiveReport;

	public showItem_i
	public showItem_j
	public showItem_k
	public seeMoreWeb: number = 100;
	public seeMoreLeak: number = 100;
	public showDowloadText: boolean = false;
	public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
	public sortWithPaste = 'source_url';
	public initialSortPaste: boolean = true;
	public orderPaste = 1;
	public sortWithWeb = 'source_url';
	public initialSortWeb: boolean = true;
	public orderWeb = 1;
	public webDocumentsToShow = [];
	public leakedContentsToShow = []
	public loadingRisk = false;

	// public delReason: string = null;
	public deleteType = "web";
	public fieldsToDownloadLeakedContents = {
		'Source URL':'source_url',
		'Created At': 'created_at',
		'Updated At': 'updated_at',
	}
	public fieldsToDownloadWebDocuments = {
		'Source URL':'source_url',
		'Created At': 'created_at',
		'updated At': 'updated_at',
	}
	constructor(
		private commonService: CommonService, public portfolioSurveyService: PortfolioSurveyService,
		private modalService: BsModalService,
		private modalRef: BsModalRef

	) { }


	ngOnInit() {
	}

	openRemoveOptions(template: TemplateRef<any>, type,content) {
		this.deleteType = type
		content.selected = true;
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}

	delleakedContents(reason) {
		this.leakedContents.map(content => {
			if (content.selected == true) {
				content.updated = true;
				content.archive = true;
				content.archive_reason = reason;
				delete content['selected']
				// this.delReason = null;
			}
		})
		this.updateDigitalRisk(this.leakedContents);
	}

	delwebDocuments(reason) {
		this.webDocuments.map(content => {
			if (content.selected == true) {
				content.updated = true;
				content.archive = true;
				content.archive_reason = reason;
				delete content['selected']
				// this.delReason = null;
			}
		})
		this.updateDigitalRisk(this.webDocuments);
	}

	public updateDigitalRisk(lcOrWdArray) {
		this.portfolioSurveyService.updateDigitalRisk(lcOrWdArray, this.mainDomain['domain_name']).subscribe(data => {
			this.modalRef.hide();
			alert("Entity deleted successfully!")

		}, err => {
			this.modalRef.hide();
		});
	}
	delReason(reason){
		if(this.deleteType=='web'){
			this.delwebDocuments(reason)
		} else this.delleakedContents(reason)
	}
	cancelDel(a) {
		this.webDocuments.map(content => {content.selected = false})
		this.leakedContents.map(content => {
			content.selected = false})
	}
	parseLink(link){
		if(link){
			return link.replace("https://api.intelx.io/", "https://2.intelx.io/");
		}
	}
	async updateDataToShowForWebDocument(value) {
		await delay(1000)
		this.webDocumentsToShow = value
	}
	async updateDataToShowForLeakedContent(value) {
		await delay(1000)
		this.leakedContentsToShow =value
	}
	
}