import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { SortPipe } from "../pipes/sort.pipe";
import { SearchPipe } from "../pipes/search.pipe";
import { FilterPipe } from '../pipes/new-filter.pipe';
import { dataPerPage, currentPage, count, pagesToShow, order, changeOrder } from '../_services/globals'

@Component({
    selector: 'new-pagination',
    template: `
    <span class="pagination" *ngIf="count > 0;else noData">
        <!--
        <span>{{ getMin() }} to {{ getMax() }} of {{count}}, </span>
        <span>{{ totalPages() }} pages</span>
        -->
        <ul class="pagination ">
            <li class="page-item" [ngClass]="{ 'disabled' : page === 1 || loading }"><a class="page-link" (click)="onPrev()">Prev</a></li>
            <li class="autoScroll_scrollbar"autoScroll_scrollbar>
                <ul class="pagination over-flow-page">
                    <li class="page-item" [ngClass]="{ 'disabled' : loading, 'active' : page === pageNum }" *ngFor="let pageNum of this.showNoOfPages">
                        <a class="page-link" (click)="doSort(null, null, pageNum)">{{pageNum}}</a>
                    </li>
                </ul>
            </li>
            <li class="page-item" [ngClass]="{ 'disabled' : lastPage()  || loading }"><a class="page-link" (click)="onNext()">Next</a></li>
        </ul>
    </span>
    <ng-template #noData>
        <h5 style="text-align:center;">Currently no data available</h5>
    </ng-template>
  `,
    styles: [
        `
        .autoScroll_scrollbar{
                overflow-x: auto;
                /* Firefox */
        }
        .autoScroll_scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #397524 #DFE9EB;
        }
        .autoScroll_scrollbar::-webkit-scrollbar {
            width: 9px;
            width: 9px;
        }
        .autoScroll_scrollbar::-webkit-scrollbar-track {
            border-radius: 1px;
            background-color: #DFE9EB;
            border: 3px solid #FFFFFF;
        }
        
        .autoScroll_scrollbar::-webkit-scrollbar-track:hover {
            background-color: #B8C0C2;
        }
        
        .autoScroll_scrollbar::-webkit-scrollbar-track:active {
            background-color: #B8C0C2;
        }
        
        .autoScroll_scrollbar::-webkit-scrollbar-thumb {
            border-radius: 0px;
            background-color: #397524;
            border: 3px solid #FFFFFF;
        }
        
        .autoScroll_scrollbar::-webkit-scrollbar-thumb:hover {
            background-color: #62A34B;
        }
        
        .autoScroll_scrollbar::-webkit-scrollbar-thumb:active {
            background-color: #62A34B;
        }  
        .page-item{
            cursor: pointer;
        }
        ul.pagination {}
        .over-flow-page{
            max-width: 215px;
            /* overflow: scroll; */
            scrollbar-width: thin;
        }`]
})

export class NewPaginationComponent implements OnInit, OnChanges {

    public page: number = currentPage; // the current page
    public count: number = count; // how many total items there are in all pages
    public perPage: number = dataPerPage; // how many items we want to show per page
    public pagesToShow: number = pagesToShow; // how many pages between next/prev
    public loading: boolean; // check if content is being loaded
    public changeOrder: number;
    public filterKeys: string;
    public elementsToWorkOn;

    public showNoOfPages = [];

    @Input() elements;
    @Input() sortKey: string;
    @Input() order: number = order;
    @Input() searchKey: string = null;
    @Input() keysToLookForFilter: string = null;
	@Input() keysToLookFor: any;
    @Input() oldElements;
    @Input() filterOut;
    @Input() sortMap;
    @Output() goPrev = new EventEmitter<boolean>();
    @Output() goNext = new EventEmitter<boolean>();
    @Output() goPage = new EventEmitter<number>();
    @Output() elementsToShow = new EventEmitter();
    @Output() elementsFiltered = new EventEmitter();

    private sortpipe = new SortPipe();
    private searchPipe = new SearchPipe();
    private filterPipe = new FilterPipe();


    constructor() { }

    ngOnInit() {
        this.elementsToWorkOn = this.elements
        if (this.elements && this.elements.length) {
			this.count = this.elements.length;
			if(!this.oldElements) {
				this.oldElements = this.elements.slice();
			}
        }
        this.getPages();
        this.generatePagesToShow();
    }
    generatePagesToShow(){;
        let bufferBefore = 2
        let pageToSow = this.pagesToShow - 4;
        let minPage = this.page;
        let noOfPages:any = this.getPages();
            noOfPages = noOfPages[noOfPages.length-1];
        if(minPage>=noOfPages-pageToSow) minPage = (noOfPages-pageToSow<0)?1:noOfPages-pageToSow;
        let maxPage = (minPage + pageToSow >= noOfPages)?noOfPages:(minPage + pageToSow);
         this.showNoOfPages = []; 
        for (var i = minPage; i <= maxPage; i++) {
            this.showNoOfPages.push(i);
        }
        if(minPage>2){
            this.showNoOfPages = [this.showNoOfPages[0]-1,...this.showNoOfPages];
        }
        var index = this.showNoOfPages.indexOf(0);
        if (index !== -1) this.showNoOfPages.splice(index, 1);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sortKey && changes.sortKey['currentValue'] != null) {
            this.sortKey = changes.sortKey['currentValue'];
            if (this.sortKey && this.changeOrder) {
                this.doSort(this.sortKey, this.changeOrder, this.page)
            }
        }
        if (changes.order && this.sortKey != null) {
            this.changeOrder = changes.order['currentValue'];
            if (this.sortKey && this.changeOrder) {
                this.doSort(this.sortKey, this.changeOrder, this.page)
            }
        }
        if (changes.searchKey && changes.searchKey['currentValue'] != null) {
            if (this.oldElements && this.oldElements.length) {
                this.elementsToWorkOn = this.oldElements.slice();
                this.doSearch(this.keysToLookFor, changes.searchKey['currentValue'])
            }
        } else {
            if (this.oldElements && this.oldElements.length)
                this.elementsToWorkOn = this.oldElements.slice();
        }
        if (changes.keysToLookForFilter && changes.keysToLookForFilter['currentValue'] != null) {
            if (this.oldElements && this.oldElements.length) {
                this.elementsToWorkOn = this.oldElements.slice();
                this.filterKeys = changes.keysToLookForFilter['currentValue']
                this.doFilter(this.filterKeys)
            }
        } else {
            if (this.oldElements && this.oldElements.length)
                this.elementsToWorkOn = this.oldElements.slice();
        }
        this.count = this.count >= 0 ? this.count : this.elementsToWorkOn.length;
        this.generatePagesToShow();
    }

    doSort(key, order, page) {
        if (this.searchKey && this.keysToLookFor) {
            this.elementsToWorkOn = this.searchPipe.transform(this.elementsToWorkOn, this.keysToLookFor, this.searchKey);
            this.onPage(this.page);
        } else if (this.filterKeys) {
            this.elementsToWorkOn = this.filterPipe.transform(this.elementsToWorkOn, this.filterKeys,undefined);
            this.count = this.elementsToWorkOn.length;
            this.onPage(this.page);
        }
        this.onPage(page);
        this.generatePagesToShow();
    }

    doSearch(keys, searchKey) {
        this.elementsToWorkOn = this.searchPipe.transform(this.elementsToWorkOn, keys, searchKey);
        this.count = this.elementsToWorkOn.length;
        this.getPages();
        this.onPage(1);
    }

    doFilter(filterKeys) {
        this.elementsToWorkOn = this.filterPipe.transform(this.elementsToWorkOn, filterKeys, undefined);
        this.count = this.elementsToWorkOn.length;
        this.getPages();
        this.onPage(1);
    }

    getMin(): number {
        return ((this.perPage * this.page) - this.perPage) + 1;
    }

    getMax(): number {
        let max = this.perPage * this.page;
        if (max > this.count) {
            max = this.count;
        }
        return max;
    }

    onPage(n: number): void {
        if (this.sortKey && this.changeOrder) {
            this.elementsToWorkOn = this.sortpipe.transform(this.elementsToWorkOn, this.sortKey, this.changeOrder, true, this.sortMap);
        }
        if (this.searchKey && this.keysToLookFor) {
            this.elementsToWorkOn = this.searchPipe.transform(this.elementsToWorkOn, this.keysToLookFor, this.searchKey);
        } else if (this.filterKeys) {
            this.elementsToWorkOn = this.filterPipe.transform(this.elementsToWorkOn, this.filterKeys,undefined);
            this.count = this.elementsToWorkOn.length;
        }
        this.page = n;
        if (this.elementsToWorkOn) {
            var newElements = this.elementsToWorkOn.slice((this.page - 1) * this.perPage, (this.page) * this.perPage);


             console.log("------",newElements)
            this.elementsToShow.emit(newElements)
            this.elementsFiltered.emit(this.elementsToWorkOn)
            this.goPage.emit(n);
        }
        this.generatePagesToShow();
    }

    onPrev(): void {
        if (this.sortKey && this.changeOrder) {
            this.doSort(this.sortKey, this.changeOrder, this.page - 1)
        } else {
            this.onPage(this.page - 1);
        }
        this.goPrev.emit(true);
    }

    onNext(next: boolean): void {
        if (this.sortKey && this.changeOrder) {
            this.doSort(this.sortKey, this.changeOrder, this.page + 1)
        } else {
            this.onPage(this.page + 1);
        }
        this.goNext.emit(next);
    }

    totalPages(): number {
        return Math.ceil(this.count / this.perPage) || 0;
    }

    lastPage(): boolean {
        return this.perPage * this.page >= this.count;
    }

    getPages(): number[] {
        const c = Math.ceil(this.count / this.perPage);
        const p = this.page || 1;
        const pagesToShow = this.pagesToShow || 9;
        const pages: number[] = [];
        pages.push(p);
        // const times = pagesToShow - 1;
        const times = c;
        for (let i = 0; i < times; i++) {
            if (pages.length) {
                if (Math.min.apply(null, pages) > 1) {
                    pages.push(Math.min.apply(null, pages) - 1);
                }
            }
            if (pages.length) {
                if (Math.max.apply(null, pages) < c) {
                    pages.push(Math.max.apply(null, pages) + 1);
                }
            }
        }
        pages.sort((a, b) => a - b);
        return pages;
    }
}
