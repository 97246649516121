import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
let docStyle = getComputedStyle(document.documentElement);


// convert edged for graph
@Injectable({
  providedIn: 'root'
})
export class KgToNgxGraphFormatService {

  public nodesToSkip = ['scan']
  public scanNode
  public colorToNodeType = {
    "ip_address": docStyle.getPropertyValue('--blue'),
  }

  constructor(
    public commonService: CommonService
  ) { }

  convertEdges(edges) {
    let edgesToReturn = []
    edges.forEach(element => {
      if (element['__src'] != this.scanNode['__id']) {
        element['source'] = element['__src']
        element['target'] = element['__dst']
        edgesToReturn.push(element)
      }
    });
    return edgesToReturn
  }
  convertNodes(nodes) {
    let nodesToReturn = []
    console.log(this.scanNode)
    nodes.forEach(element => {
      if (element['label'] != '' && element['typeof'] != "" && (element['__id'] != this.scanNode['__id'])) {
        console.log(element['typeof'])
        element['id'] = element['__id']
        element['label'] = element['data']
        element['data'] = {
          'label': element['label'],
          'initials': this.commonService.returnInitials(element.typeof),
          'hoverText': this.commonService.returnHoverText(element.typeof),
          defaults: {
            backgroundColor : "#FFFFFF",
            color: "#FFFFFF",
            width: this.commonService.returnWidth(element.typeof) || 100
          },
          color : "#FFFFFF",
        }
        nodesToReturn.push(element)
      }
    });
    console.log(nodesToReturn, nodesToReturn.length)
    return nodesToReturn
  }
  getNextNodes(node, elements) {
    let nextNodeEdges = elements.filter(el => el.source == node.id)
    let nextNodes = []
    nextNodeEdges.forEach(nne => {
      nextNodes.push(elements.filter(el => el.id == nne.target)[0])
    });
    return nextNodes
  }
  getPreviousNodes(node, elements) {
    let previousNodeEdges = elements.filter(el => el.target == node.id)
    let previousNodes = []
    previousNodeEdges.forEach(nne => {
      previousNodes.push(elements.filter(el => el.id == nne.source)[0])
    });
    return previousNodes
  }
}
