import { Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "./http.service";

import { JobAutomationConfig } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class JobService {
  public jobAutomationConfig = JobAutomationConfig;

  constructor(public httpSVC: HttpService) {}

  public getAllSchedulingAssessment(domain_name, { page = 1, search = null }) {
    var url =
      this.jobAutomationConfig.url +
      "/jobs/" +
      "?main_domain=" +
      domain_name +
      "&job_type=assessment&page=" +
      page +
      "&archive=" +
      false;
    if (search) url = url + "&search=" + search;
    return this.httpSVC.get(url);
  }
  public getAllSchedulingReports(page) {
    const url =
      this.jobAutomationConfig.url +
      "/jobs/" +
      "?job_type=report_generation&page=" +
      page +
      "&archive=" +
      false;
    return this.httpSVC.get(url);
  }
  public createNewJob(scheduler) {
    const url = this.jobAutomationConfig.url + "/jobs/";
    return this.httpSVC.post(url, scheduler);
  }
  public updateScheduleReport(report_id, jsonToUpdate) {
    const url = this.jobAutomationConfig.url + "/jobs/" + report_id + "/";
    return this.httpSVC.patch(url, jsonToUpdate);
  }
  updateRelatedDomainJobService(domainData) {
    const url = this.jobAutomationConfig.url + "/jobs/bulk-update/";
    return this.httpSVC.post(url, domainData);
  }
  updateAllAseletsForDomain(jobId) {
    const url =
      this.jobAutomationConfig.url + "/jobs/status/?job_run_id=" + jobId;
    return this.httpSVC.get(url);
  }
  ckeckJobStatus(JobId) {
    let count = 1;
    return new Promise((resolve, reject) => {
      let intervaId = setInterval(() => {
        this.updateAllAseletsForDomain(JobId).subscribe(
          (data: any) => {
            console.log("Job", JobId, data);
            count++;
            if (data.job_status == "completed") {
              resolve("completed");
              clearInterval(intervaId);
            } else if (count > 10) {
              resolve("running");
              clearInterval(intervaId);
            }
            // if(data)
          },
          (error) => {
            console.error("Unable to save to job service", error);
            clearInterval(intervaId);
            reject(error);
          }
        );
      }, 5000);
    });
  }
  public createNewReoprtSchedule(scheduler) {
    const url = this.jobAutomationConfig.url + "/jobs/";
    return this.httpSVC.post(url, scheduler);
  }

  // job_type

  public getJobStatus(jobId) {
    const url =
      this.jobAutomationConfig.url + "/jobs/status/?job_run_id=" + jobId;
    return this.httpSVC.get(url);
  }
}
