import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-runbook',
  templateUrl: './runbook.component.html',
  styleUrls: ['./runbook.component.scss']
})
export class RunbookComponent implements OnInit {
public createAttack = false;
public loadAttackList = 0;
public actionParam:any = "";
public params:any = null;
  constructor(
    public route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      this.actionParam = (this.route.snapshot.url && this.route.snapshot.url[0] && this.route.snapshot.url[0].path)?this.route.snapshot.url[0].path:null;
      if (params && params["action"] && params["action"]=='new-assesment') {
        this.createAttack = true;
      }else{
        this.createAttack = false;
      }
    });
  }

  ngOnInit(): void {
  }
  showAttackList(){
    this.createAttack = !this.createAttack
  }
  
 
}
