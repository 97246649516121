import { Component, OnInit, ViewContainerRef } from '@angular/core';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  // public toastr: ToastsManager

  constructor( vcr: ViewContainerRef) {

	// this.toastr.setRootViewContainerRef(vcr)
	// this.toastr['options'] = { 
	// 	positionClass: 'toast-bottom-center',
	// 	animate: 'flyRight',
	// 	newestOnTop: true,

	// }
}
public showNotificationBar:boolean=false

ngOnInit() {
  }

  showSuccess() {
    console.log("Inside Success")
    // this.toastr.success('You are awesome!', 'Success!');
  }

  showError() {
    console.log("Inside Error")
    // this.toastr.error('This is not good!', 'Oops!');
  }

  showWarning() {
    console.log("Inside Warning")
    // this.toastr.warning('You are being warned.', 'Alert!');
  }

  showInfo() {
    console.log("Inside Info")
    // this.toastr.info(' some information .');
  }
  
  showCustom() {
    // this.toastr.custom('<span style="color: red">Message in red.</span>', null, {enableHTML: true});
  }

// showNotification(){
// 	this.showNotificationBar=true
// 	setTimeout(function(this){
// 		console.log("laksjdlkasjd")
// 		this.showNotificationBar=false
// 	 }, 3000)
// }

// showNotification() {
// 	this.showNotificationBar = true;
// 	setTimeout(() => {    
// 		console.log("a;ksd;laskda;slkd", this.showNotificationBar)
// 		this.hideNotification()
// 	}, 3000);
// }

// hideNotification() {
// 	console.log("name");
// 	this.showNotificationBar = false;
// }
}