import { Injectable, EventEmitter } from "@angular/core";

import { HttpService } from "./http.service";

import {
    BenchmarkConfig,
	AssessmentConfig,
	AirtableConfig
} from "../../environments/environment";

@Injectable()
export class AssessmentService {
    public benchmarkBaseUrl = BenchmarkConfig

    public assessmentConfig = AssessmentConfig.url;
	public airTableConfig = AirtableConfig.url

    private headers = new Headers({
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("id_token"),
	});


    constructor(
		public httpSVC: HttpService
	) { }

    public getAttackListDetails({page=1,limit=10,status=null, archive=null, search=null}={}) {
		var url = this.assessmentConfig + "/runbook/assessment" + "?page=" + page + "&ordering=-created_at";
		if(status) url = url + "&status=" + status
		if(archive || archive == false) url = url + "&archive=" + archive
		if(search) url = url + "&search=" + search
		return this.httpSVC.get(url);
	} 

	public getAtomicRunBookDetails({offset=null,page=1,searchKey=null}) {
		var get_all_data_url = this.airTableConfig + "/at/api/modules/" + "?pagesize=10"+ "&sortField=Status&sortDirection=desc";
		var get_search_data_url = this.airTableConfig + "/at/api/modules/search" + "?pagesize=10"+ "&sortField=Status&sortDirection=desc";
		if(searchKey){
			var url = get_search_data_url
			url = url + "&searchField=Title" + "&searchParamLike="+ searchKey

		}
		else{
			var url = get_all_data_url
		}
		if(offset) {
			url = url + "&offset="+offset
		}
		return this.httpSVC.get(url);
	}


	public getAtomicCategoryDetails({offset=null,page=1,searchField="Category",searchParam="Exploit"}) {
		var url = this.airTableConfig + "/at/api/modules/search" + "?pagesize=10" + "&searchField=" + 
		searchField + "&searchParam=" + searchParam + "&sortField=Status&sortDirection=desc"
		if(offset) {
			url = url + "&offset="+offset
		}

		return this.httpSVC.get(url);
	}

	public getAtomicCategoriesDetails() {
		var url = this.airTableConfig + "/at/api/stats/";
		return this.httpSVC.get(url);
	}

	public getMolecularBookDetails({searchKey=null, pageSize=10, industry = null}) {
		var url = this.assessmentConfig + "/runbook/library/" +"?archive=false" + "&status=active" + "&ordering=-updated_at&execution_type=manual";
		if(searchKey) url = url + "&search="+ searchKey
		if (pageSize) url = url + "&page_size="+pageSize
		if (industry) url = url + "&industry="+industry
		return this.httpSVC.get(url);
	}

	public deleteAssessment(id) {
		var url = this.assessmentConfig + "/runbook/assessment/" + id;
		return this.httpSVC.delete(url);
	}
	public updateAssessment(id , dataToSend) {
		var url = this.assessmentConfig + "/runbook/assessment/" + id;
		return this.httpSVC.patch(url,dataToSend);
	}
	public getAssessmentDetails(assessmentId: any) {
		var url = this.assessmentConfig + "/runbook/assessment/"+ assessmentId
		return this.httpSVC.get(url);
	}
	public getAssessmentResults(assessmentId: any, params={}) {
		var url = this.assessmentConfig + "/runbook/engine/result/"+ assessmentId
		if (params && params['summary']) {
			url = url + "?summary="+params['summary']
		}
		return this.httpSVC.get(url)
	}

	public getAssessmentResultsForRunStep(scanId: any, params={}) {
		var url = this.assessmentConfig + "/runbook/engine/result/scan/"+ scanId
		if (params && params['summary']) {
			url = url + "?summary="+params['summary']
		}
		return this.httpSVC.get(url)
	}
	public industryListAndCount(){
		var url = this.assessmentConfig + "/runbook/library/industry_details?archived=false"
		return this.httpSVC.get(url)
	}
	public playBookAssessment(assessmentId){
		var url = this.assessmentConfig + "/runbook/engine/report/assessment/" + assessmentId + "/";
		return this.httpSVC.get(url)
	}
	public runBookScanSignedUrlDetails(scanId){
		var url = this.assessmentConfig + "/runbook/engine/report/scan/" + scanId + "/";
		return this.httpSVC.get(url)
	}

	public getAttackListAssessmentDetails(signedUrl){
		return this.httpSVC.getWithoutBearer(signedUrl)
	}
	public getRunBookScansDetails(scanId: any) {
		var url = this.assessmentConfig + "/runbook/engine/result/scan/"+ scanId
		return this.httpSVC.get(url);
	}
	public getScans(assessment_id: any, runstep_id: any, page:any) {
		let url = this.assessmentConfig + "/runbook/engine/assessment/scan/"+ assessment_id + "/" + runstep_id + "?limit=10";
		if(page){
			url = url + "&page=" + page ;
		}
		return this.httpSVC.get(url);
	}

}