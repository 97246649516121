import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ssl-score',
  templateUrl: './ssl-score.component.html',
  styleUrls: ['./ssl-score.component.scss']
})
export class SslScoreComponent implements OnInit {
@Input() sslScoreDetails: any;

  constructor() { }

  ngOnInit() {
  }

}
