import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attack-surface',
  templateUrl: './attack-surface.component.html',
  styleUrls: ['./attack-surface.component.scss']
})
export class AttackSurfaceComponent implements OnInit {

  public entityToShow = [
    {
      name: "Domains",
      value: "domain",
    },
    {
      name: "Sub Domains",
      value: "subdomain",
    },
    {
      name: "IPs",
      value: "ip",
    },
    {
      name: "Netblocks",
      value: "netblock",
    },
    {
      name: "Services",
      value: "services",
    },
    {
      name: "Web Applications",
      value: "application",
    },
    {
      name: "Leaked Credentials",
      value: "leakcred",
    },
    {
      name: "API Discovery",
      value: "apiDiscovery",
    },
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
