import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "../views/pages/auth.service";
import { RolesService } from "../_services/roles.service";

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router, private authService: AuthService, private rolesService: RolesService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const profile = JSON.parse(localStorage.getItem('profile'));

        // if (localStorage.getItem('id_token')){
        if (this.authService.isAuthenticated()) {
            if (profile) {
                if (this.rolesService.isUserOsint()) {
                    return true;
                } else {
                    // this.router.navigate(['new/self-domains']);
                    alert('Please contact us at contact@firecompass.com');
                    location.href = 'https://www.firecompass.com';
                    return false;
                }
            }
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

}
