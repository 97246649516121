import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PortfolioSurveyService } from './../../_services';

@Component({
  selector: 'app-run-attacks',
  templateUrl: './run-attacks.component.html',
  styleUrls: ['./run-attacks.component.scss']
})
export class RunAttacksComponent implements OnInit {
  public showThisTab = 'onDemand'
  public domain

  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
		private route: ActivatedRoute, private router: Router,
  ) { }

  ngOnInit() {
    this.getDomain()
  }
  getDomain() {
    this.route.queryParams.subscribe(params => {
			if (params && params['domain']) {
				this.domain = params['domain'];
				this.router.navigate(['.'], {
					relativeTo: this.route, queryParams: { domain: this.domain }, queryParamsHandling: 'merge',
					skipLocationChange: true
				});

			} else {
				this.portfolioSurveyService.getDomainsSearchForUser().subscribe((data:any) => {
					data.filter(d => {
						if (d['domain_type'] == 'self') {
							this.domain = d['domain']
						}
					})

				})
			}
		})
  }

}
