import { Component, OnInit, Input, TemplateRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { PortfolioSurveyService } from 'app/_services';
import { SearchPipe } from 'app/pipes/search.pipe';
import { SortPipe } from 'app/pipes/sort.pipe';

@Component({
	selector: 'app-malicious-infra',
	templateUrl: './malicious-infra.component.html',
	styleUrls: ['./malicious-infra.component.scss']
})
export class MaliciousInfraComponent implements OnInit {

	@Input() mainDomain: any;
	@Input() maliciousList;
	@Input() filter;
	@Input() isUserTrial = false;
	@Input() showExecutiveReport;
	@Input() maliciousPercentage;
	@Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
	public seeMore: number = 100;
	public order = 1;
	public sortWith = 'subject_name';
	public initialSort = false;
	public malicious = [];
	public showDowloadText: boolean = false;
	public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
	// public delReason: string = null;
	public oldmaliciousList: any;
	public source_list = {
		"virustotal": "Virus Total",
		"fraudguard": "Fraud Guard",
		"alienvault": "AlienVault",
		"badips": "Bad Ips"
	}
	public ispToTypeIpsDropDown: any = [];
	public showThis = 'ispFilter';
	public openDropdown: Boolean = false;
	public keysToSearch = ['netblock_details']
	public searchPipe = new SearchPipe();
	public sortPipe = new SortPipe();
	public fieldsToDownload = {
		'IP ADDRESS': 'ip_address',
		'Domains': 'hosts',
		'ISP Name': 'asn_details',
	}

	constructor(
		private commonService: CommonService, private portfolioSurveyService: PortfolioSurveyService,
		private modalService: BsModalService,
		private modalRef: BsModalRef
	) { }

	public showImage: boolean = false
	public imageIndex: number = -1;
	public showFilterData = false;

	ngOnInit() {
		// if (this.maliciousList && this.maliciousList.length) {
		// 	this.oldmaliciousList = this.commonService.getJsonCopy(this.maliciousList);
		// 	this.showFilterData = true
		// 	this.maliciousList.forEach(element => {
		// 		// this.checkFilter(element)
		// 		this.getIspToTypeipsDropDown(element);
		// 	});
		// }
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.maliciousList && changes.maliciousList.currentValue && changes.maliciousList.currentValue.length) {
			if (this.maliciousList && this.maliciousList.length) {
				this.oldmaliciousList = this.commonService.getJsonCopy(this.maliciousList);
				this.showFilterData = true
				this.maliciousList.forEach(element => {
					this.getIspToTypeipsDropDown(element);
				});
			}
		}
	}

	getLinkForSource(source, value) {
		if (source.indexOf('virustotal') >= 0) {
			return 'https://www.virustotal.com/#/ip-address/' + value;
		} else {
			return source
		}
	}

	getSourceName(source) {
		if (source) {
			for (var key in this.source_list) {
				// check if the property/key is defined in the object itself, not in parent
				if (this.source_list.hasOwnProperty(key)) {
					if (source.indexOf(key) > 0) {
						return this.source_list[key];
					}
				}
			}
		}
		return "Link";
	}

	checkFilter(risk) {
		if (risk['tags']) {
			var tags = risk['tags'];
			if (this.filter == 'malicious') {
				if (tags.indexOf('malicious') >= 0) {
					this.malicious.push(risk);
				}
			}
		}
	}

	removeDuplicate(array) {
		try {
			return Array.from(new Set(array))
		}
		catch (ex) {
			return []
		}
	}

	getMaliciousTags(malicious) {
		let tags = []
		if (malicious && malicious['tags'] && malicious['tags'][0]) {
			let m_tags = malicious['tags'][0]
			m_tags.forEach(tag => {
				if (tag) {
					if (tag.indexOf(',')) {
						tags.push(...tag.split(','))
					} else {
						tags.push(tag)
					}
				}
			});
		}
		malicious['tag'] = tags;
		let uniqueItems = Array.from(new Set(tags))
		return uniqueItems.join(', ');
	}
	openRemoveOptions(template: TemplateRef<any>, malicious) {
		malicious.selected = true;
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}

	delMalicious(reason) {
		this.maliciousList.map(ph => {
			if (ph.selected == true) {
				ph.updated = true;
				ph.archive = true;
				ph.archive_reason = reason;
				delete ph['selected']
				// this.delReason = null;
			}
		})
		this.updateMalicious();
	}
	public updateMalicious() {
		this.portfolioSurveyService.updateMalicious(this.maliciousList, this.mainDomain['domain_name']).subscribe(data => {
			this.modalRef.hide();
			alert("Suspicious IP deleted successfully!")
			this.refreshCount.emit('ip');
		}, err => {
			this.modalRef.hide();
		});
	}

	getIspToTypeipsDropDown(element) {
		if (element && element.netblock_details && element.netblock_details.asn_name) {
			var serviceObject = { name: '', isp: element.netblock_details.asn_name, count: 1 };
			var index = this.ispToTypeIpsDropDown.findIndex(function (service) {
				return service.isp == serviceObject.isp;
			});
			if (index == -1) {
				this.ispToTypeIpsDropDown.push(serviceObject);
			} else {
				this.ispToTypeIpsDropDown[index]['count']++;
			}
		}
	}

	searchMalicious(value) {
		if (value) {
			this.maliciousList = this.searchPipe.transform(this.oldmaliciousList, this.keysToSearch, value);
		}
	}

	showIspToTypeIps(value) {
		// this.openDropdown = false;
		let flag = true;
		this.maliciousList = this.commonService.getJsonCopy(this.oldmaliciousList);
		if (value == null) {
			this.maliciousList = this.maliciousList.filter(element => {
				element['filter_score'] = 0;
				for (let i = 0; i < this.ispToTypeIpsDropDown.length; i++) {
					if (element && element.netblock_details) {
						if (element.netblock_details.asn_name == this.ispToTypeIpsDropDown[i]['isp'] && this.ispToTypeIpsDropDown[i]['selected'] == true) {
							flag = false;
							element['filter_score'] = element['filter_score'] ? element['filter_score'] + 1 : 1;
							// return true;
						}
					} else if (this.ispToTypeIpsDropDown[i]['selected'] == true) {
						flag = false;
					}
				}
				if (element['filter_score'] > 0) {
					return true;
				}
			})
		} else {
			for (let i = 0; i < this.ispToTypeIpsDropDown.length; i++) {
				this.ispToTypeIpsDropDown[i]['selected'] = false;
			}
		}
		this.sortPipe.transform(this.maliciousList, 'filter_score', -1, true);
		if (flag) {
			this.maliciousList = this.commonService.getJsonCopy(this.oldmaliciousList);
		}
	}
	delReason(reason) {
		this.delMalicious(reason)
	}
	cancelDel(a) {
		this.maliciousList.map(content => {
		   content.selected = false})
	 }
}
