import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { AssetTypePipe } from 'app/pipes/asset-type.pipe';
import { CommonService } from 'app/_services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-assesment-target-list-component',
  templateUrl: './assesment-target-list-component.component.html',
  styleUrls: ['./assesment-target-list-component.component.scss']
})
export class AssesmentTargetListComponentComponent implements OnInit {

  public targetsToShow = []
  @Input() allTargets:any = [];
  public assetTypePipe = new AssetTypePipe();
  constructor(
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  getUnique(targets) {
    if (targets && targets.length) {
      return targets.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
    } else return targets
  }

  showTargets( template: TemplateRef<any>, targets) {
    this.targetsToShow = targets    
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
  }
  targetTypeHoverText(target) {
    const entity:String = this.assetTypePipe.transform(target,true)
    return this.commonService.returnHoverText(entity);
  }
  targetType(target) {
    const entity:String = this.assetTypePipe.transform(target,true)
    return this.commonService.returnInitials(entity);
  }
}
