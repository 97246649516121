import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'new-filter'
})
export class FilterPipe implements PipeTransform {

	transform(list: any, filter_object?: any, count?: any, filterout?: any) {
		if (!list || list.length <= 0 || Object.keys(filter_object).length <= 0) {
			return list;
		}
		let filterData = []
		if(filterout) {
			filterData = list;
		}
		list.forEach((el, i) => {
			el.addedtofilter = false;
			for (let key in filter_object) {
                let value = this.deepGet(el, key.split('.'))
				if (value || value == false) {
					let searchKeys = filter_object[key];
					if(typeof(value) != 'boolean') {
						searchKeys.forEach(search => {
                            let inds = value.indexOf(search);
							if(filterout) {
								if (inds > -1) {
									filterData.splice(i, 1)
								}
							} else {
								if (search == value || inds > -1) {
									if(!el.addedtofilter) {
										el.addedtofilter = true;
										filterData.push(el);
									}
								}
							}
						});
					} else {
						if (searchKeys == value) {
							filterData.push(el)
						}
					}
				}
			}
		});
		return filterData
	}

	deepGet(obj, properties) {
		// If we have reached an undefined/null property
		// then stop executing and return undefined.
		if (obj === undefined || obj === null) {
			return;
		}

		// If the path array has no more elements, we've reached
		// the intended property and return its value.
		if (properties.length === 0) {
			return obj;
		}

		// Prepare our found property and path array for recursion
		let foundSoFar = obj[properties[0]];
		let remainingProperties = properties.slice(1);

		return this.deepGet(foundSoFar, remainingProperties);
	}
}