import { Component, Input, OnInit, TemplateRef, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';
import { PortfolioSurveyService } from '../../../_services';
import{ CommonService} from '../../../_services/common.service'
@Component({
  selector: 'app-api-discovery',
  templateUrl: './api-discovery.component.html',
  styleUrls: ['./api-discovery.component.scss']
})
export class ApiDiscoveryComponent implements OnInit {
  @Input() mainDomain;
  @Input() isUserTrial;
  @Input() filters;
  @Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
  public singleApiDetail = null
  public reasonTodelete = "";
  public page = 1;
  public limit = 10;
  public order = 1;
  public total_pages: number;
  public showSpinnerPagination = true;
  public apiDetails: any = [];
  public searchKey = null;
  public total_api_count = null;
  public csvDetails = null;
  public loadingDownloadData = false;
  public selectedMethods = null;
  public fieldsToDownload = {
    'Base URL': 'base_url',
    'Method': 'method',
    'Path': 'path',
    'API definition file': 'defination_file',
    'Request Schema': 'request_schema',
    'Response Schema': 'response_schema',
    'Headers': 'headers',
    'Query Params': 'query_params'

  }
  public methodsFilter = [{
    name: 'PUT',
    key: 'put',
    selected: false
  },
  {
    name: 'PATCH',
    key: 'patch',
    selected: false
  },
  {
    name: 'DELETE',
    key: 'delete',
    selected: false
  },
  {
    name: 'GET',
    key: 'get',
    selected: false
  },
  {
    name: 'OPTIONS',
    key: 'options',
    selected: false
  },
  {
    name: 'POST',
    key: 'post',
    selected: false
  },

  ]
  public sortKey = null
  constructor(
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService : CommonService) { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {
    const change: SimpleChange = changes.mainDomain;
    if (change && !change.previousValue && change.currentValue) {
      this.getApiDiscovryDetail()
    }
    const changeFilters: SimpleChange = changes.filters
    if (Object.keys(changeFilters.currentValue).length) {
      if (this.filters && this.filters.page) {
        this.page = this.filters.page
        delete this.filters.page
      }
      this.getApiDiscovryDetail()
    }
    // const changeCount: SimpleChange = changes.total_api_count
    //  if (changeCount) {
    //    this.getCSVdata()
    //  }

  }

  modalApiOpen(template: TemplateRef<any>, apiDetail) {
    this.singleApiDetail = apiDetail
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
  }
  getApiDiscovryDetail() {
    this.showSpinnerPagination = true;
    const params = {
      page: this.page,
      limit: this.limit,
      searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
      method: this.selectedMethods && this.selectedMethods.length ? this.selectedMethods : null,
      ...this.filters
    }
    if (this.sortKey) params.sortKey = this.order == -1 ? this.sortKey : '-'+this.sortKey
    this.portfolioSurveyService.getApiDiscovery(this.mainDomain['domain_name'], params).subscribe((data: any) => {
      if (data) {
        if (this.page == 1 && this.total_api_count != data['data'].count) {
          this.total_api_count = data['data'].count
          this.getCSVdata()
        }
        this.total_api_count = data['data'].count
        this.apiDetails = data['data'].results;
        this.showSpinnerPagination = false;
      }
      
    }, error => {
      this.showSpinnerPagination = false;
    })
  }
  searchApiDetail(domain) {
    this.page = 1;
    this.getApiDiscovryDetail()
  }

  goToPage(val) {
    this.page = val;
    this.getApiDiscovryDetail()
  }

  searchAllsearchApiDetail(value) {
    this.page = 1
    if (value === 'search')
      this.searchKey = null
    else if (value === 'methods') {
      this.selectedMethods = null
      this.methodsFilter.forEach(method => {
        method.selected = false
      });
    }
    this.getApiDiscovryDetail()
  }

  openRemoveOptions(template: TemplateRef<any>, api) {
    api.selected = true
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
  }
  public delReason(reason) {
    this.delApiDiscovery(reason)
  }
  delApiDiscovery(reason) {
    this.apiDetails.map((api) => {
      if (api.selected == true) {
        api.updated = true;
        api.archive = true;
        api.archive_reason = reason;
        delete api["selected"];
      }
    });
    this.updateApiDiscovery();
  }
  public updateApiDiscovery() {
    this.portfolioSurveyService
      .updateApiDiscovery(this.apiDetails, this.mainDomain["domain_name"])
      .subscribe(
        (data) => {
          this.modalRef.hide();
          alert("API discovery deleted successfully!");
          this.getApiDiscovryDetail();
          this.refreshCount.emit('api');
        },
        (error) => {
          if (error["status"] === 403) {
            alert(
              "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
            );
          } else {
            alert("Error while updating");
          }
          this.modalRef.hide();
        }
      );
  }
  objectCheck(obj) {
    return obj && obj.length > 2 ? obj : '-'
  }
  cancelDel(a) {
    this.apiDetails.map(api => { api.selected = false })
  }
  changeMethodFilter(method) {
    method['selected'] = !method['selected']

  }
  filerByMethods() {
    const selectedMethodsArr = this.methodsFilter.map(t => {
      if (t.selected) {
        return t.key
      }
    })
    this.selectedMethods = selectedMethodsArr.filter(t => t).join(',')
    this.getApiDiscovryDetail()
  }
  getCSVdata() {
    const params = {
      limit: this.total_api_count,
      page_size : this.total_api_count 
    }
    this.portfolioSurveyService.getApiDiscovery(this.mainDomain['domain_name'], params).subscribe((data: any) => {
      if (data) {
          this.csvDetails = data['data'].results;

      }
    })
  }
}