import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PortfolioSurveyService } from './../_services/portfolio-survey.service';

@Injectable()
@Pipe({
	name: 'maindomian'
})
export class MainDomainPipe implements PipeTransform {
	public domain
	constructor(
		private portfolioSurveyService: PortfolioSurveyService,
		private route: ActivatedRoute, private router: Router,
	) { }
	transform() {
		this.route.queryParams.subscribe(params => {
			if (params && params['domain']) {
				this.domain = params['domain'];
				this.router.navigate(['.'], {
					relativeTo: this.route, queryParams: { domain: this.domain }, queryParamsHandling: 'merge',
					skipLocationChange: true
				});
				console.log("2",this.domain)

				return this.domain
			} else {
				this.portfolioSurveyService.getDomainsSearchForUser().subscribe((data:any) => {
					data.filter(d => {
						if (d['domain_type'] == 'self') {
							this.domain = d['domain']
							console.log("2",this.domain)
							return this.domain
						}
					})

				})
			}
		})

	}
}
