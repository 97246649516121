import { Component, OnInit, Input,TemplateRef } from '@angular/core';
import { CommonService } from '../../_services/common.service'
import { PortfolioSurveyService } from '../../_services/portfolio-survey.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(
    private portfolioSurveyService: PortfolioSurveyService,
    private commonService : CommonService,
    private modalService: BsModalService, private modalRef: BsModalRef
  ) { }


  @Input() mainDomain: any;
	@Input() domain: any;
	@Input() showReports: any;
  public showItem_i;
	public showItem_j;

  public reports=[{
    'json_link':'https://firecompass.atlassian.net/jira/software/projects/FS/boards/6?assignee=5b14fce06d76184b7d9898ce',
    'report_link':'https://firecompass.atlassian.net/jira/software/projects/FS/boards/6?assignee=5b14fce06d76184b7d9898ce',
    'date':'02-05-19'
  },
  {
    'json_link':'https://firecompass.atlassian.net/jira/software/projects/FS/boards/6?assignee=5b14fce06d76184b7d9898ce',
    'report_link':'pqrs.co.in',
    'date':'02-05-19'
  },
  {
    'json_link':'abc.com.in',
    'report_link':'pqrs.co.in',
    'date':'02-05-19'
  },
  {
    'json_link':'abc.com.in',
    'report_link':'pqrs.co.in',
    'date':'02-05-19'
  },
  {
    'json_link':'abc.com.in',
    'report_link':'https://firecompass.atlassian.net/jira/software/projects/FS/boards/6?assignee=5b14fce06d76184b7d9898ce',
    'date':'02-05-19'
  },
  ]

  ngOnInit() {

    // this.showAllReports()
  }
  
  showAllReports(){
    // this.portfolioSurveyService.getReportsForDomain(this.domain['domain_name']).subscribe(data => {
    //   if(data) {
    //     this.showAllReports = data
    //   }
    //   else {
    //     alert("Error while getting the report ")
    //   }
    //   // alert("Report creation started")
    // }, error => {
    //   alert("Error while getting the report")
    // })
    
  }

  modalOpenForAdd(template: TemplateRef<any>, update_data) {
    // this.cancelTopRisk();
    
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
  }
  closeReport(){
    this.modalRef.hide()
  
  }

  submitReport(){
    alert("Your request has been submitted")
    this.modalRef.hide()
  }
}


