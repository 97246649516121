import { Component, OnInit, Input } from '@angular/core';
import { PortfolioSurveyService } from "../../_services";

@Component({
  selector: 'app-poc-touchpoint',
  templateUrl: './poc-touchpoint.component.html',
  styleUrls: ['./poc-touchpoint.component.scss']
})
export class PocTouchpointComponent implements OnInit {
  @Input() modalRef;
  @Input() subject;
  @Input() mainDomain

  public touchpoint = {
    "subject_type": null,
    "subject_name": null,
    "email": null,
    "name": null,
    "main_domain_name": null,
    "creator": null,
    "created_by": null
  }
  public updatedtouchpoints = [];
  public allTouchpoints = [];
  public newTouchpoints = [];
  public editIndex: number;
  public edittouchpoint: boolean = false;
  public showDowloadText: boolean = false;
  public fieldsToDownload = {
    "Assets": "subject_name",
    "Email": "email",
    "Name": "name",
    "Created By": "creator",
    "Created At": "created_at",
    "Updated At": "updated_at"
  };
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,

  ) { }

  ngOnInit(): void {
    this.touchpoint.main_domain_name = this.mainDomain;
    this.touchpoint.subject_name = this.subject['subject_name']
    this.touchpoint.subject_type = this.subject['subject_type']
    this.getPocTouchpoint()
  }
  public getPocTouchpoint() {
    this.portfolioSurveyService.getPocTouchpoint(this.mainDomain, this.subject['subject_name'], this.subject['subject_type']).subscribe((data: any) => {
      this.allTouchpoints = data.data
    })
  }
  updatePocTouchpoint() {
    if (this.updatedtouchpoints && this.updatedtouchpoints.length) {
      this.portfolioSurveyService.updatePocTouchpoint(this.updatedtouchpoints, this.mainDomain).subscribe((data: any) => {
        this.updatedtouchpoints = []
      })
    }
  }
  public doneWithChanges() {
    console.log(this.newTouchpoints, this.updatedtouchpoints)
    this.createPocTouchpoint()
    this.updatePocTouchpoint()
  }
  public createPocTouchpoint() {
    if (this.newTouchpoints && this.newTouchpoints.length) {
      this.portfolioSurveyService.createPocTouchpoint(this.newTouchpoints, this.mainDomain).subscribe((data: any) => {
        this.newTouchpoints = []
      })
    }
  }
  addNewTouchpoint(touchpoint) {
    this.allTouchpoints.push({ ...touchpoint })
    this.newTouchpoints.push({ ...touchpoint })
    this.touchpoint.email = null;
    this.touchpoint.name = null;
  }
  updateTouchpoints(touchpoint) {
    this.updatedtouchpoints.push({ ...touchpoint });
  }

}

