import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PortfolioSurveyService } from 'app/_services';
import { SearchService } from 'app/_services/search.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
import { TrialCheckService } from 'app/_services/trial-check.service';

@Component({
  selector: 'webapp-banner',
  templateUrl: './webapp-banner.component.html',
  styleUrls: ['./webapp-banner.component.scss']
})
export class WebappBannerComponent implements OnInit {
  public showHelpBox: boolean = false;
  public searching: boolean = false;
  public searchKey: string = '';
  public assetData:any = [];
  public showAffiliate = true
  public assetDataCount = null;
  public page = 1;
  public start_time: any = null;
  public end_time: any = null;
  public page_size = 10;
  public downloading = false;
  public today = new Date();
  public currentSearchResult:any = {};
  public tags: any = [];
  public tagsText: any = [];
  public isTrialUser:boolean = true;
  public bsConfig = {
    // dateInputFormat: 'DD/MM/YYYY',
    ranges: [{
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
      label: 'Last 7 Days'
    }, {
      value: [new Date(new Date().setDate(new Date().getDate() - 15)), new Date()],
      label: 'Last 15 Days'
    }, {
      value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      label: 'Last 30 Days'
    }, {
      value: [new Date(new Date().setDate(new Date().getDate() - 60)), new Date()],
      label: 'Last 60 Days'
    }, {
      value: [new Date(new Date().setDate(new Date().getDate() - 90)), new Date()],
      label: 'Last 90 Days'
    }]
  };
  public dateRange = this.bsConfig.ranges[2].value;
  public fieldsToDownload = {
    'Asset Type': 'asset_type',
    'Asset Value': 'asset_value',
    'Service Name': 'service.name',
    'Service Port': 'service.port',
    'Service Protocol': 'service.protocol',
    // 'Validated': 'validated',
    'Timestamp': 'timestamp',
  }
  public bannerJsonToDownload: any = [];
  public addingResult:boolean = false;
  @Input() mainDomain: any;
  @ViewChild("downloadBannerComp") downloadBannerComp;
  public copyText = {
    scan_id: 'scan_id:"xxxxx-xxxxx"',
    scan_id_depth_zero: 'scan_id:"xxxxx-xxxxx" AND url_depth:0',
    scan_id_depth_one: 'scan_id:"xxxxx-xxxxx" AND url_depth:1',
    scan_id_depth_one_text: 'scan_id:"xxxxx-xxxxx" AND url_depth:0 AND "abc"'
  };
  public assetsValue = [
    { key: '', value: 'Select' },
    { key: 'low', value: 'Low' },
    { key: 'medium', value: 'Medium' },
    { key: 'high', value: 'High' }
  ];
  public domain = '';
  constructor(
    public searchService: SearchService,
    public toastr: ToastrService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public route: ActivatedRoute,
    public portfolioService:PortfolioSurveyService,
    public trialCheckService: TrialCheckService

  ) {
    // this.searchKey = "*";
    this.getSearchResults();
    this.getDomainName();
  }

  ngOnInit() {
    this.isTrialUser = this.trialCheckService.isTrailUser();
   }

  public getDomainName() {
    this.route.queryParams.subscribe((params) => {
      if (params && params["domain"]) {
        this.domain = params["domain"];
      }
    });
  }

  clearSearch() {
    this.assetData = []
    this.searchKey = "";
    this.assetDataCount = null;
    this.page = 1;
    this.dateRange = this.bsConfig.ranges[2].value;
    this.getSearchResults();
  }
  isValidKeyword() {
    let key = "";
    if (this.searchKey) {
      key = this.searchKey.trim();
    }
    return (key) ? true : false;
  }
  getSearchResults() {
    // if(this.searchKey){
    this.searching = true;
    let that = this;
    this.start_time = null;
    this.end_time = null;
    if (this.dateRange) {
      this.start_time = new Date(this.dateRange[0]).getTime();
      this.end_time = new Date(this.dateRange[1]).getTime();
    }

    this.searchService.searchByKeyWord(this.searchKey,{asset_type:'url',page:that.page,start_time:this.start_time,end_time:this.end_time,page_size:this.page_size, affiliated:this.showAffiliate}).subscribe(function(data:any){
      that.assetDataCount = data['count'];
      that.assetData = data['hits'];
      if(that.assetData && that.assetData.length>0){
        that.assetData.map((x:any) => { 
          x['showRawBanner'] = false;
          if(x['tls']) x['tls'] = JSON.parse(x['tls']);
          if(x['raw']) x['raw'] = JSON.parse(x['raw']);
       })
      }
      that.searching = false;
      console.log('that.assetData',that.assetData);
    },(error)=>{
      console.log(error);
      that.searching = false;
      that.toastr.error("Something went wrong, Unable to search.");
    })
    // console.log(this.dateRange, start_time, end_time);
    // this.searchService.searchApplicationByKeyWord(this.searchKey, { page: that.page, start_time: this.start_time, end_time: this.end_time, page_size: this.page_size }).subscribe(function (data: any) {
    //   that.assetDataCount = data['count'];
    //   that.assetData = data['hits'];
    //   if (that.assetData && that.assetData.length > 0) {
    //     that.assetData.map((x: any) => {
    //       x['showRawBanner'] = false;
    //       if (x['raw_output']) x['raw_output'] = JSON.parse(x['raw_output']);
    //     })
    //   }
    //   that.searching = false;
    // }, (error) => {
    //   console.log(error);
    //   that.searching = false;
    //   that.toastr.error("Something went wrong, Unable to search.");
    // })
    // this.assetDataCount = 3;
    // this.assetData = [
    //   {
    //     url:"https://tvsmotor.com/iqube",
    //     method:"GET",
    //     technologies:["Bootstrap:260518","IIS:10.0","Microsoft ASP.NET","Windows Server"],
    //     showRawDetails:false,
    //     raw_output:'{"status":"pass","message":"","data":["https: active"," MSF","OPENSOURCE","HTTP Redirection","test tags1","UNKNOWN","HTTPS Redirection","QA5","http: active","HTTP UNREACHABLE","Telefonica","Ds","HTTP Unreachable","Ce","HTTP FORBIDDEN","HTTP ACTIVE","Telenor","Azure","Parked","AWS","Rackspace","aws","PreProd","Email","ELB","CLOUD","HTTPS not_found","HTTPS Unreachable","S3","AKAMAI","unknown","TransientIp","HTTPS FORBIDDEN","HTTP Application","Ec2","Elasticsearch","Level3","CLOUDFRONT"," SaaS","Website","CDN","Internap","Cloud Bucket","test delete tags","Transfer","EdgeCast","LambdaCDN","Tata communications","Incapsula","Cloudflare","HTTPS UNREACHABLE","UAT","HTTPS Server Error","SaaS","Akamai","Fastly","PaaS","Intranet","Demo","MSF","Yahoo","beanstalk","MySQL","HTTP Server Error","Netlify","CE","Cur","SSL","qa","Yottaa","qa2","Google","Not Found","Cloudfront","HTTPS ACTIVE","TRANSIENT","TESt","AppEngine"," BeanStalk","HTTP Forbidden","HTTP Active","Transient","fromconfig","QA1","Ecs","EC2","DomainRegistrarInfra","HTTPS Active","Reverse Proxy","BeanStalk","HTTP not_found","HTTP APPLICATION","REVERSE PROXY","STAGING","GSuite","Cloud","preprod","HTTPS Forbidden","Elb"]}'
    //   },
    //   {
    //     url:"https://tvsmotor.com/iqube/api/pricing",
    //     method:"GET",
    //     technologies:["Microsoft ASP.NET","Windows Server"],
    //     showRawDetails:false,
    //     raw_output:'{"status":"pass","message":"","data":["https: active"," MSF","OPENSOURCE","HTTP Redirection","test tags1","UNKNOWN","HTTPS Redirection","QA5","http: active","HTTP UNREACHABLE","Telefonica","Ds","HTTP Unreachable","Ce","HTTP FORBIDDEN","HTTP ACTIVE","Telenor","Azure","Parked","AWS","Rackspace","aws","PreProd","Email","ELB","CLOUD","HTTPS not_found","HTTPS Unreachable","S3","AKAMAI","unknown","TransientIp","HTTPS FORBIDDEN","HTTP Application","Ec2","Elasticsearch","Level3","CLOUDFRONT"," SaaS","Website","CDN","Internap","Cloud Bucket","test delete tags","Transfer","EdgeCast","LambdaCDN","Tata communications","Incapsula","Cloudflare","HTTPS UNREACHABLE","UAT","HTTPS Server Error","SaaS","Akamai","Fastly","PaaS","Intranet","Demo","MSF","Yahoo","beanstalk","MySQL","HTTP Server Error","Netlify","CE","Cur","SSL","qa","Yottaa","qa2","Google","Not Found","Cloudfront","HTTPS ACTIVE","TRANSIENT","TESt","AppEngine"," BeanStalk","HTTP Forbidden","HTTP Active","Transient","fromconfig","QA1","Ecs","EC2","DomainRegistrarInfra","HTTPS Active","Reverse Proxy","BeanStalk","HTTP not_found","HTTP APPLICATION","REVERSE PROXY","STAGING","GSuite","Cloud","preprod","HTTPS Forbidden","Elb"]}'
    //   },
    //   {
    //     url:"https://tvsmotor.com/ntorq",
    //     method:"GET",
    //     technologies:["Microsoft ASP.NET","Windows Server"],
    //     showRawDetails:false,
    //     raw_output:'{"status":"pass","message":"","data":["https: active"," MSF","OPENSOURCE","HTTP Redirection","test tags1","UNKNOWN","HTTPS Redirection","QA5","http: active","HTTP UNREACHABLE","Telefonica","Ds","HTTP Unreachable","Ce","HTTP FORBIDDEN","HTTP ACTIVE","Telenor","Azure","Parked","AWS","Rackspace","aws","PreProd","Email","ELB","CLOUD","HTTPS not_found","HTTPS Unreachable","S3","AKAMAI","unknown","TransientIp","HTTPS FORBIDDEN","HTTP Application","Ec2","Elasticsearch","Level3","CLOUDFRONT"," SaaS","Website","CDN","Internap","Cloud Bucket","test delete tags","Transfer","EdgeCast","LambdaCDN","Tata communications","Incapsula","Cloudflare","HTTPS UNREACHABLE","UAT","HTTPS Server Error","SaaS","Akamai","Fastly","PaaS","Intranet","Demo","MSF","Yahoo","beanstalk","MySQL","HTTP Server Error","Netlify","CE","Cur","SSL","qa","Yottaa","qa2","Google","Not Found","Cloudfront","HTTPS ACTIVE","TRANSIENT","TESt","AppEngine"," BeanStalk","HTTP Forbidden","HTTP Active","Transient","fromconfig","QA1","Ecs","EC2","DomainRegistrarInfra","HTTPS Active","Reverse Proxy","BeanStalk","HTTP not_found","HTTP APPLICATION","REVERSE PROXY","STAGING","GSuite","Cloud","preprod","HTTPS Forbidden","Elb"]}'
    //   }
    // ]
    // this.assetData.map((x)=>{
    //   x.raw_output = JSON.parse(x.raw_output)
    // })
    // this.searching = false;
  }
  goToPage(val) {
    this.page = val;
    this.getSearchResults();
  }
  formatDate(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let pd = new Date(date);
    let dateString = monthNames[pd.getMonth()] + ' ' + pd.getDate() + ', ' + pd.getFullYear();
    return dateString;
  }
  downloadBanners() {
    if (!this.downloading) {
      let that = this;
      this.downloading = true;
      this.searchService.applicationDownload(this.searchKey, {
        start_time: this.start_time,
        end_time: this.end_time,
        page_size: 10000,
        affiliated:this.showAffiliate,
        fields: 'asset_type,asset_value,name,service.port,service.protocol,timestamp,validated'
      }).subscribe(function (data: any) {
        if (data && data['hits'] && data['hits'].length > 0) {
          that.bannerJsonToDownload = data['hits'];
          that.bannerJsonToDownload.map(x => {
            const date = new Date(x.timestamp);
            x.timestamp = date.toLocaleString("en-US");
          })
          setTimeout(function () {
            that.downloadBannerComp.download();
            that.bannerJsonToDownload = [];
            that.downloading = false;
          }, 1000);
        } else {
          that.bannerJsonToDownload = [];
          that.downloading = false;
        }
      }, (error) => {
        console.log(error);
        that.bannerJsonToDownload = [];
        that.downloading = false;
        that.toastr.error("Something went wrong, Unable to download.");
      })
    }
  }
  formatTimestamp(timestampString) {
    let valid = (new Date(timestampString)).getTime() > 0;
    if (!valid) return '-';
    let k = new Date(timestampString);
    return k.toLocaleString("en-US");
  }
  copyIt(str: any) {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand("copy");
    this.toastr.info(str + ' copied.');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }
  addWebApp(template: TemplateRef<any>, searchResult) {
    this.currentSearchResult = searchResult;
    if(!this.currentSearchResult.confidence) this.currentSearchResult.confidence = 0;
    if(!this.currentSearchResult.asset) this.currentSearchResult.asset = '';
    if(!this.currentSearchResult.comment) this.currentSearchResult.comment = '';
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
    });
  }

  addAsApplication(){
    let dataMap = {
      added: true,
      platform: null,
      subject_name: this.domain,
      score: -1,
      main_domain: this.domain,
      source_url: this.currentSearchResult.asset_value,
      subject_type: "related_domain",
      application_type: "web",
      asset: this.currentSearchResult.asset,
      vulnerabilities: [],
      confidence: this.currentSearchResult.confidence,
      discovered_at: new Date().toISOString().slice(0, 10),
      comment:this.currentSearchResult.comment = '',
      tags:this.tags.join('","')
    }
    console.log(dataMap);
    this.addingResult = true;
    this.portfolioService.addWebapplication([dataMap],this.domain).subscribe((data)=>{
      console.log(data);
      if(data && data['status']=='pass' && data['count']=='1'){
        this.toastr.success('Application added successfully.');
        this.modalRef.hide();
      }else{
        this.toastr.error('Action failed. Unable to add as application.');
      }
      this.addingResult = false;
    },(error)=>{
      console.log(error);
      this.toastr.error('Action failed. Unable to add as application.');
      this.addingResult = false;
    })
  }
  addTag(){
    if(this.tagsText){
      if(this.tags.length>0){
        let tagExist = 0;
        this.tags.map((x)=>{
          if(x.toLowerCase()==this.tagsText.toLowerCase()) tagExist++;
        })
        if(tagExist==0){
          this.tags.push(this.tagsText);
          this.tagsText = '';
        }
      }else{
        this.tags.push(this.tagsText);
        this.tagsText = '';
      }
    }
  } 
  removeTag(i){
    this.tags = this.tags.splice(i, 1);
  }
  addApi(template: TemplateRef<any>, searchResult) {
    this.currentSearchResult = {...searchResult};

    let url = new URL(this.currentSearchResult.asset_value);
    this.currentSearchResult.asset_value = (url.origin)?url.origin:'';
    this.currentSearchResult.path = (url.pathname)?url.pathname:'';
    
    if(!this.currentSearchResult.method) this.currentSearchResult.method = '';
    // if(!this.currentSearchResult.path) this.currentSearchResult.path = searchResult.asset_value;
    if(!this.currentSearchResult.defination_file) this.currentSearchResult.defination_file = '';
    if(!this.currentSearchResult.request_schema) this.currentSearchResult.request_schema = '';
    if(!this.currentSearchResult.response_schema) this.currentSearchResult.response_schema = '';
    if(!this.currentSearchResult.headers) this.currentSearchResult.headers = '';
    if(!this.currentSearchResult.query_params) this.currentSearchResult.query_params = '';
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
    });
  }
  addAsAPI(){
    this.addingResult = true;
    let dataMap = {
      base_url: this.currentSearchResult.asset_value,
      defination_file: this.currentSearchResult.defination_file,
      headers: this.currentSearchResult.headers,
      method: this.currentSearchResult.method,
      path: this.currentSearchResult.path,
      query_params: this.currentSearchResult.query_params,
      request_schema: this.currentSearchResult.request_schema,
      response_schema: this.currentSearchResult.response_schema
    }
    console.log(dataMap);
    this.portfolioService.updateApiDiscovery([dataMap],this.domain).subscribe((data)=>{
      console.log(data);
      this.addingResult = false;
      if(data && data['status']=='pass' && data['data_created']=='1'){
        this.toastr.success('API added successfully.');
        this.modalRef.hide();
      }else if(data && data['status']=='pass' && data['data_updated']=='1'){
        this.toastr.success('API updated successfully.');
        this.modalRef.hide();
      }else{
        this.toastr.error('Action failed. Unable to add as API.');
      }
    },(error)=>{
      this.addingResult = false;
      console.log(error);
      this.toastr.error('Action failed. Unable to add as API.');
    })
  }
  getFavicon(obj){
    let url = new URL(obj.asset_value);
    let baseUrl = `${url.protocol}//${url.hostname}`;
    let slash = (baseUrl.slice(-1)!='/' && (obj.raw.favicon_path && obj.raw.favicon_path.slice(0,1)!="/"))?'/':'';
    return baseUrl+slash+obj.raw.favicon_path;
  }
}

