import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { AuthService } from "../../views/pages/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import jwt_decode from "jwt-decode";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { profile } from "console";
import { dataPerPage } from "app/_services/globals";
import { delay } from "rxjs/operators";
@Component({
  selector: "app-manage-self-domain",
  templateUrl: "./manage-self-domain.component.html",
  styleUrls: ["./manage-self-domain.component.scss"],
})
export class ManageSelfDomainComponent implements OnInit {
  modalRef?: BsModalRef;

  @ViewChild("template")
  private modalTemplate: TemplateRef<any>;
  public domain;
  public previousDomains;
  public showSpinner;
  public showError;
  public showUpgradeWedgit = false;
  public userAllowed = false;
  public allUserDomains;
  public selfDomains;
  public freeTrials = 1;
  public myDomain;
  public checkingLogin = false;
  public userPreference = null;
  public profile: any = null;

  constructor(
    public router: Router,
    public authService: AuthService,
    public portfolioSurveyService: PortfolioSurveyService,
    public aff: AngularFireFunctions,
    public auth: AngularFireAuth,
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService
  ) {
    if (localStorage.getItem("profile")) {
      this.profile = JSON.parse(localStorage.getItem("profile"));
    }
  }

  // ngDoCheck() {
  //   if (this.authService.isAuthenticated()) {
  //     // this.router.navigate(['/']);
  //     this.setDefaultVariabes(0);
  //   }
  // }
  openModal() {
    // alert("Inise Open Modal");
    this.modalRef = this.modalService.show(this.modalTemplate);
  }

  setDefaultVariabes = function (count) {
    console.log("I am getting called", count);
    var profile = JSON.parse(localStorage.getItem("profile"));
    this.myDomain = localStorage.getItem("mydomain");
    if (profile && profile["app_metadata"]) {
      var email = profile.email;
      let newDomain = email.split("@")[1];
      if (this.myDomain != newDomain) {
        localStorage.setItem("mydomain", newDomain);
        this.getDomains();
      }
      var roles = profile["app_metadata"]["roles"];
      var index = roles.indexOf("osint");
      if (index >= 0) {
        this.userAllowed = true;
      }
      var indexadmin = roles.indexOf("admin");
      if (indexadmin >= 0) {
        this.userAllowed = true;
        this.freeTrials = 400;
      }
    }
  };
  ngOnInit() {
    console.log(this.myDomain, "logging in selfDomain");
    localStorage.removeItem("mydomain");
    this.authService.tryingToLoginEmitter.subscribe((data) => {
      this.checkingLogin = data;
      if (!this.checkingLogin) {
        this.checkloginStatus();
      }
    });
    this.showSpinner = true;
    this.getDomains();
    this.checkloginStatus();
    this.userPref();
  }

  getDomains() {
    this.portfolioSurveyService.getDomainsSearchForUser().subscribe(
      (data: any) => {
        this.allUserDomains = data;
        // this.checkloginStatus();
        this.showSpinner = false;
        this.selfDomains = data.filter((a) => {
          return a["domain_type"] === "self";
        });
        // this.myDomain = this.selfDomains && this.selfDomains[0] ? this.selfDomains[0].domain : ''
        if (!this.selfDomains.length) {
          alert(
            "No domains added to user account. Please write to us at contact@firecompass.com to add you domain!"
          );
          // this.setDefaultVariabes(0);
        }
        // this.setDefaultVariabes(0);
        // this.previousDomains = data;
        // if (this.previousDomains && this.previousDomains.length >= this.freeTrials) {
        //   this.showUpgradeWedgit = true;
        // }
      },
      (error) => {
        // this.checkloginStatus();
        this.setDefaultVariabes(0);
        this.showSpinner = false;
        this.showError =
          "Error while connecting to server! Please try after some time.";
      }
    );
  }

  public searchOldDomain(domain) {
    this.showSpinner = true;
    // localStorage.removeItem("domain");
    // localStorage.setItem("domain",domain);
    // this.router.navigateByUrl('/dashboard');
    this.router.navigate(["/dashboard"], { queryParams: { domain: domain } });
  }
  async searchDomain(domain, type, count = 0) {
    await delay(1000);
    if (localStorage.getItem("id_token")) {
      this.ngxService.start();
      const callable = this.aff.httpsCallable("updatePrimaryDomain");
      const updateDomain = callable({ primaryDomain: domain });
      updateDomain.subscribe((res) => {
        if (res == "You are not permitted to perform this action.") {
          this.ngxService.stop();
          alert("You are not permitted to perform this action");
        } else {
          this.authService.getLastestToken();
          this.ngxService.stop();
          this.showSpinner = false;
          this.router.navigate(["/dashboard-attack-surface"], {
            queryParams: { domain: domain },
          });
          // this.router.routeReuseStrategy.shouldReuseRoute = function () {
          //   return false;
          // };
        }
      });

      // })
    } else {
      // this.authService.handleAuthentication();
      if (localStorage.getItem("id_token") && count <= 3) {
        this.searchDomain(domain, type, count++);
      }
    }
  }
  removeDomain(domain) {
    this.portfolioSurveyService
      .deleteDomainForUser(domain)
      .subscribe((data: any) => {
        if (data) {
          var indexOld = this.previousDomains
            .map(function (e) {
              return e.id;
            })
            .indexOf(data.id);

          this.previousDomains.splice(indexOld, 1);
        }
      });
  }

  checkloginStatus() {
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["/pages/login"]);
    }
  }

  setMyDomain(val) {
    console.log("Inside Set my Domain");
    this.myDomain = val;
    localStorage.setItem("mydomain", val);
  }

  userPref() {
    if (this.profile.user_id) {
      this.authService.getUserPref(this.profile.user_id).then((res: any) => {
        console.log(res);
        this.userPreference = res;
      });
    }
  }

  updatePref() {
    const data = {
      ...this.userPreference,
      user_recommendation: { skipForEver: true },
    };
    this.authService.updateUserPref({ pref: data });
    this.modalRef.hide();
  }
}
