import { Component, OnInit } from '@angular/core';
import { PortfolioSurveyService } from 'app/_services/portfolio-survey.service';

@Component({
	selector: 'app-all-notification',
	templateUrl: './all-notification.component.html',
	styleUrls: ['./all-notification.component.scss']
})
export class AllNotificationComponent implements OnInit {
	public seeMoreNoti: number = 2;

	public notificationList
	public readNotifications
	public unReadNotifications
	public showThis = 'unread'

	constructor(
		private portfolioSurveyService : PortfolioSurveyService
	) { }

	ngOnInit() {
		this.getNotifications();
	}
	getNotifications() {
		this.portfolioSurveyService.getNotifications().subscribe(data => {
			this.notificationList = data;
			this.unReadNotifications = data
		})
		this.portfolioSurveyService.getReadNotifications().subscribe(data => {
			this.readNotifications = data;
		})
	}
	// getAlertEntity(alert){
	// 	switch (alert['entity_sub_type']) {
	// 		case 'related_domain':
	// 			return alert['details']['host']
	// 		case 'subdomain':
	// 			return alert['details']['']
	// 		case 'ip_address':
	// 			return alert['details']['']
	// 		case 'database_port':
	// 			return alert['details']['']
	// 		case 'network_port':
	// 			return alert['details']['']
	// 			break;
		
	// 		default:
	// 			break;
	// 	}
	// }
	greatedHoursAgo(createdAt) {
		var timeDiff = Date.now()/1000-createdAt;
		var minutes = Math.round(timeDiff/60)
		if(minutes >= 43800) {
			return "More Then a Month Old"
		} else if (minutes >= 1440) {
			return Math.round((minutes/1440)) + " Day(s) ago"
		} else if (minutes >= 60) {
			return Math.round((minutes/60)) + " Hour(s) ago"
		} else {
			return minutes + " Minutes ago"
		}
	}
}
