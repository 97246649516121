import {
  Component,
  OnInit,
  DoCheck,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PortfolioSurveyService } from "../../_services/portfolio-survey.service";
import { CommonService } from "../../_services/common.service";
import { TabsetComponent } from "ngx-bootstrap/tabs";

@Component({
  selector: 'app-labs-v2',
  templateUrl: './labs-v2.component.html',
  styleUrls: ['./labs-v2.component.scss']
})
export class LabsV2Component implements OnInit {
  @ViewChild("tabSet") tabSet: TabsetComponent;
  filesData: any;
  bucketCsvDetails: any[];
  codeLeaksCount: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private portfolioSurveyService: PortfolioSurveyService,
    private commonService: CommonService
  ) {}
  public domains;
  public cveDetails;
  public domain;
  public digitalRisk = [];
  public screennshots = [
    "https://i2.wp.com/hackersonlineclub.com/wp-content/uploads/2018/02/Networking.png?resize=662%2C396&ssl=1",
    "https://i.ytimg.com/vi/9N2fkh3fa10/maxresdefault.jpg",
  ];
  public showImage: boolean = false;
  public imageIndex: number = -1;
  public phishingDomains: any;
  public loadingRisk = false;
  public loadingPhishing = false;
  public totalLeakCredentialsCount;
  public vulnerabilitiesCount;
  public phishingCount;
  public maliciousCount;
  public leakedContentCount;
  public leakedContentCountNewValue: any;
  public leakedContents;
  public webDocumentCount;
  public webDocumentCountNewValue: any;
  public webDocuments;
  public total_cve_count;
  public subDomainsCount;
  public subDomainsCountOld;
  public whoisDetailsForRds;
  public automatedDone: boolean = false;
  public relatedDomainsHostsOld: any;
  public subDomains;
  public mainDomain;
  public maliciousList = [];
  public totalLeakCredentialsCountOld;
  public domainsLeakedCredentials: any;
  public relatedDomainsLeakCredentialsOld: any;
  public isUserTrial: boolean = false;
  public show3RdPartyTab: boolean = false;
  private userDomains = [];
  public buckets: any;
  public buckets_count: number;
  public codeLeaks: any;
  public showExecutiveReport: boolean = false;
  public setAssetView: boolean = false;
  public serviceVulnerabilties = [];
  public webVulnerabilties = [];
  public mobileVulnerabilties = [];
  public serviceVulCount;
  public mobileVulCount;
  public webVulCount;
  public pasteDataCount = -1;
  public loadMainDomain = false
  public mobileVulLoader=true

  ngOnInit() {
    this.getDomainName();

    // this.route.queryParams.subscribe(params => {
    // 	if (params && params['domain']) {
    // 		this.domain = params['domain'];
    // 		this.router.navigate(['.'], {
    // 			relativeTo: this.route, queryParams: { domain: this.domain }, queryParamsHandling: 'merge',
    // 			// preserve the existing query params in the route
    // 			skipLocationChange: true
    // 			// do not trigger navigation
    // 		});
    // 		this.getPhishing();
    // 		this.getDigitalRisk();
    // 		this.getBucketsForDomains();
    // 		// this.getCveDetailsForDomains()
    // 		// this.getRelatedDomainsSubDomains();
    // 		this.getVulnerabilityDeepc();
    // 		this.getMaliciousRisk();
    // 		this.getRelatedDomainsLeakCredentials();
    // 		this.getUserSelfDomain();
    // 		this.getCodeLeaksForDomain()

    // 	} else {
    // 		this.router.navigate(['/new/self-domains']);
    // 	}
    // })
  }

  public getDomainName() {
    this.route.queryParams.subscribe((params) => {
      if (params && Object.keys(params).length > 0) {
        if (params["domain"]) {
          this.domain = params["domain"];
          this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { domain: this.domain },
            queryParamsHandling: "merge",
            skipLocationChange: true,
          });
          this.getAllData();
        }
        if (params["activetab"]) {
          this.tabSet.tabs[1].active = true;
          this.setAssetView = true;
        }
      } else {
        this.portfolioSurveyService.getDomainsSearchForUser().subscribe(
          (data: any) => {
            data.filter((d) => {
              if (d["domain_type"] == "self") {
                this.domain = d["domain"];
                this.getAllData();
              }
            });
          },
          (error) => {
            this.errorForDomain(error);
          }
        );
      }
    });
  }
  public getAllData() {
    if (this.domain) {
      this.getPhishing();
      // this.getDigitalRisk();
      this.getBucketsForDomains();
      // this.getCveDetailsForDomains()
      // this.getRelatedDomainsSubDomains();
      this.getPasteDataCount();
      this.getVulnerabilityDeepc();
      this.getMaliciousRisk();
      this.getRelatedDomainsLeakCredentials();
      this.getUserSelfDomain();
      this.getCodeLeaksForDomain();
      this.getServiceVulnerability();
      this.getMobileAppVulnerability();
      this.getWebAppVulnerability();
    } else {
      alert(
        "No domain associated with you, Please write us on contact@firecompass.com"
      );
    }
  }

  setUserAccount(userAccount) {
    if (userAccount && userAccount == "trial") {
      this.isUserTrial = true;
    }
  }

  getVulnerabilityDeepc() {
    this.portfolioSurveyService.getVulnerabilityDeepc(this.domain).subscribe(
      (data: any) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.cveDetails = data["data"];
          this.total_cve_count = data["vulnerabilities_count"];
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  // leakContentCountChange(value) {
  //   this.leakedContentCountNewValue = value;
  // }

  ngDoCheck() {
    // this.leakedContentCount = this.leakedContentCountNewValue ? this.leakedContentCountNewValue : '0';
  }

  getRelatedDomainsLeakCredentials() {
    this.portfolioSurveyService
      .getRelatedDomainsLeakCredentials(this.domain)
      .subscribe(
        (data: any) => {
          if (data) {
            if (data["leak_credentials"]) {
              this.setUserAccount(data["userAccount"]);
              this.totalLeakCredentialsCount = data["leak_credentials"][
                "total_leaked_credentials"
              ]
                ? data["leak_credentials"]["total_leaked_credentials"]
                : 0;
              this.domainsLeakedCredentials = data["leak_credentials"]["data"];
              this.mainDomain = data.domain;
              if(this.mainDomain) this.loadMainDomain = true
              if (
                data["leak_credentials"]["status"] == "fail" ||
                !this.totalLeakCredentialsCount
              ) {
                this.getAutomatedData();
              } else {
                this.domainsLeakedCredentials.forEach((element) => {
                  this.commonService.checkCreatedAndUpdated(element);
                });
                this.relatedDomainsLeakCredentialsOld = this.domainsLeakedCredentials.slice();
                this.totalLeakCredentialsCountOld = this.totalLeakCredentialsCount;
              }
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  getMaliciousRisk() {
    this.loadingRisk = true;
    this.portfolioSurveyService.getMaliciousRisk(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.loadingRisk = false;
          this.maliciousCount = data["count"];
          this.maliciousList = data["data"];
        }
      },
      (error) => {
        console.log(
          "Error while loading risks. Please try again after sometime!"
        );
        this.errorForDomain(error);
      }
    );
  }

  getDigitalRisk() {
    this.loadingRisk = true;
    this.portfolioSurveyService.getDigitalRisk(this.domain).subscribe(
      (data: any) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.digitalRisk = data.data;
          this.loadingRisk = false;
          this.leakedContents = data["leak_contents"];
          this.leakedContentCount = data["count_leaked_content"];
          this.webDocuments = data["cloud_documents"];
          this.webDocumentCount = this.webDocuments.length;
        }
      },
      (error) => {
        alert("Error while loading risks. Please try again after sometime!");
        this.errorForDomain(error);
      }
    );
  }

  screenshots(i) {
    this.showImage = true;
  }

  getPhishing() {
    this.loadingPhishing = true;
    this.portfolioSurveyService.getPhishingDomains(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.loadingPhishing = false;
          this.phishingDomains = data["data"];
          this.phishingCount = data["count"];
        }
      },
      (error) => {
        this.loadingPhishing = false;
        this.errorForDomain(error);
      }
    );
  }

  errorForDomain(error) {
    if (error.status == 403) {
      alert(
        "Forbidden! You are not allowed to perform this action. Please contact us at contact@firecompass.com"
      );
      this.router.navigate(["/new/self-domains"]);
    }
  }
  updateTotalCvCount(count) {
    this.total_cve_count = count;
    // if (count != undefined && count != null)
    // 	document.getElementById('totalCveCount').innerText = count;
  }

  getCveDetailsForDomains() {
    this.portfolioSurveyService
      .getCveDetailsForDomains(this.domain)
      .subscribe((data) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.cveDetails = data["data"];
          this.total_cve_count = data["total_cve_count"];
        }
      });
  }
  getBucketsForDomains() {
    this.portfolioSurveyService.getBuckets(this.domain).subscribe((data) => {
      if (data && data["status"] == "pass") {
        this.setUserAccount(data["userAccount"]);
        this.buckets = data["data"];
        this.buckets_count = data["bucket_count"];
        this.getCSVdata()
        this.getFilesCount(this.buckets)
      }
    });
  }
  getFilesCount(bucket_data){
		var bucketNames = []
		var buckeNameStr = null;
		bucket_data.forEach(element => {
		  bucketNames.push(element['bucket_name'])
		  
		});
		buckeNameStr = bucketNames.join()
		this.portfolioSurveyService.getFileCount(this.domain,buckeNameStr).subscribe((data) => {
		  if(data){
			this.filesData=data['data']
	
		  }
		})
	  }

    getCSVdata() {
      this.bucketCsvDetails = []
      const params = {
        limit: this.buckets_count,
        page_size: this.buckets_count
      }
      this.portfolioSurveyService.getBuckets(this.domain, params).subscribe((data: any) => {
        if (data) {
          this.bucketCsvDetails = data['data'];
        }
      })
    }
  getRelatedDomainsSubDomains() {
    this.portfolioSurveyService
      .getRelatedDomainsSubDomains(this.domain)
      .subscribe(
        (data: any) => {
          if (data) {
            this.setUserAccount(data["userAccount"]);
            this.domains = data.related_domains["data"];
            this.mainDomain = data.domain["data"];
            this.subDomainsCount = data.related_domains.total_sub_domain_count;
            this.subDomainsCountOld = this.subDomainsCount;
            this.whoisDetailsForRds = data["additional_RD_details"];
            if (
              data["related_domains"]["status"] == "fail" ||
              !this.domains ||
              !this.domains.length ||
              !this.subDomainsCount
            ) {
              this.getAutomatedData();
            } else {
              this.domains.forEach((element) => {
                this.commonService.checkCreatedAndUpdated(element);
                element.hosts.forEach((host) => {
                  this.commonService.checkCreatedAndUpdated(host);
                });
              });
              this.relatedDomainsHostsOld = JSON.parse(
                JSON.stringify(this.domains)
              );
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  getAutomatedData() {
    // if (!this.automatedDone) {
    this.automatedDone = true;
    // 	this.portfolioSurveyService.getAutomatedDataForDomain(this.domain).subscribe(data => {
    // 		if (data) {
    // 			console.log(data);
    // 		}
    // 	})
    // }
  }

  getUserSelfDomain() {
    this.portfolioSurveyService
      .getDomainsSearchForUser()
      .subscribe((data: any) => {
        var index = -1;
        data.forEach((d, i) => {
          if (d["domain"] == this.domain && d["domain_type"] == "self") {
            this.show3RdPartyTab = true;
            index = i;
          }
        });
        if (index != -1) {
          data.splice(index, 1);
        }
        this.userDomains = data;
      });
  }
  public getCodeLeaksForDomain() {
    this.portfolioSurveyService
      .getCodeLeaks(this.domain)
      .subscribe((data: any) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.codeLeaks = data["data"];
          this.codeLeaksCount = data["count"]
        }
      });
  }
  getServiceVulnerability() {
    this.portfolioSurveyService
      .getServiceVulnerability(this.domain)
      .subscribe((vul) => {
        this.serviceVulnerabilties = vul["data"];
        this.serviceVulCount = this.serviceVulnerabilties.length
          ? this.serviceVulnerabilties.length
          : 0;
      });
    }

  getWebAppVulnerability() {
    this.portfolioSurveyService
      .getWebAppVulnerability(this.domain)
      .subscribe((vul) => {
        this.webVulnerabilties = vul["data"];
        this.webVulCount = this.webVulnerabilties.length
          ? this.webVulnerabilties.length
          : 0;
      });
  }
  getMobileAppVulnerability() {
    this.mobileVulLoader=true
    this.portfolioSurveyService
      .getMobileAppVulnerability(this.domain)
      .subscribe((vul) => {
        this.mobileVulnerabilties = vul["data"];
        this.mobileVulCount = this.mobileVulnerabilties.length
          ? this.mobileVulnerabilties.length
          : 0;
          this.mobileVulLoader=false
      });
  }
  getPasteDataCount() {
    this.portfolioSurveyService
      .getPasteDataCount(this.domain)
      .subscribe((data) => {
        if (data && data["status"] == "pass") {
          this.pasteDataCount = data["count"];
        }
      });
  }
}
