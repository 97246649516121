import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-web-score',
  templateUrl: './web-score.component.html',
  styleUrls: ['./web-score.component.scss']
})
export class WebScoreComponent implements OnInit {

  @Input() webScoreDetails: any;
  public details: any;

  constructor() { }

  ngOnInit() {
    this.details = this.webScoreDetails['detailedAnayze']
  }

}
