import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-newviews",
  templateUrl: "./newviews.component.html",
  styleUrls: ["./newviews.component.scss"],
})
export class NewviewsComponent implements OnInit {
  public scores;
  constructor() {}
  ngOnInit() {}
  public gradeClass(grade) {
    // var grade = this.analysis['grade']
    switch (grade) {
      case "A":
        return "badge-success";
      case "A+":
        return "badge-success";
      case "B":
        return "badge-primary";
      case "B+":
        return "badge-primary";
      case "C":
        return "badge-info";
      case "C+":
        return "badge-info";
      case "D":
        return "badge-primary";
      case "D+":
        return "badge-primary";
      case "E":
        return "badge-warning";
      case "E+":
        return "badge-warning";
      case "F":
        return "badge-danger";
      case "F+":
        return "badge-danger";
      case "I":
        return "badge-danger";
      case "I+":
        return "badge-danger";
    }
  }
}
