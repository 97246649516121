import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ExpertService } from "../../_services/expert.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AssessmentService } from "app/_services";
import { CommonService } from 'app/_services/common.service';
import { KgToNgxGraphFormatService } from "app/_services/kg-to-ngx-graph-format.service"
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-rb-resume",
  templateUrl: "./rb-resume.component.html",
  styleUrls: ["./rb-resume.component.scss"],
})
export class RbResumeComponent implements OnInit {
  public assessmentId;
  public runstepId;
  public showThis;
  public allowedEntities = ['domain_name', 'ip_address', 'netblock', 'sub_domain', 'application', 'vulnerability','service']
  public allEntities = {};
  // public detailEtity = [
  //   {
  //     id: 1294,
  //     assessment_id: "assess_100",
  //     created_at: "2022-03-14T08:07:53.101332Z",
  //     updated_at: "2022-03-14T08:07:53.101341Z",
  //     runstep_id: "runstep_100",
  //     scan_id: "123_abc",
  //     node_id: "dda0d7c70b258579",
  //     data_type: "ip_address",
  //     data: "6a67d85a3740ab6e955afd67cc06d70b48e8b94551b689434b79262256c2843a",
  //     meta_data: null,
  //     archive: true,
  //     archive_reason: null,
  //   },
  //   {
  //     id: 1285,
  //     assessment_id: "assess_100",
  //     created_at: "2022-03-14T08:07:53.085036Z",
  //     updated_at: "2022-03-14T08:07:53.085043Z",
  //     runstep_id: "runstep_100",
  //     scan_id: "123_abc",
  //     node_id: "2d7c9a8c8d47873e",
  //     data_type: "ip_address",
  //     data: "f97016aef7fd8533c7c96eba63025741545a0e9ea050681f2f977883a8d231d2",
  //     meta_data: null,
  //     archive: false,
  //     archive_reason: null,
  //   },
  //   {
  //     id: 1262,
  //     assessment_id: "assess_100",
  //     created_at: "2022-03-14T08:07:53.058470Z",
  //     updated_at: "2022-03-14T08:07:53.058478Z",
  //     runstep_id: "runstep_100",
  //     scan_id: "123_abc",
  //     node_id: "ecdaa9284087063f",
  //     data_type: "ip_address",
  //     data: "5b2fdfa3745dec0e0886aad2e94ce8524a1b9d386d0243a78fa8e3f6930c4759",
  //     meta_data: null,
  //     archive: true,
  //     archive_reason: null,
  //   },
  //   {
  //     id: 1252,
  //     assessment_id: "assess_100",
  //     created_at: "2022-03-14T08:07:53.046715Z",
  //     updated_at: "2022-03-14T08:07:53.046722Z",
  //     runstep_id: "runstep_100",
  //     scan_id: "123_abc",
  //     node_id: "9e19d3a337e00549",
  //     data_type: "ip_address",
  //     data: "0ff0b7fcb090c65d0bdcb2af4bbd2c30f33356b3ce9b117186fa20391ef840a3",
  //     meta_data: null,
  //     archive: false,
  //     archive_reason: null,
  //   },
  //   {
  //     id: 1238,
  //     assessment_id: "assess_100",
  //     created_at: "2022-03-14T08:07:53.029355Z",
  //     updated_at: "2022-03-14T08:07:53.029370Z",
  //     runstep_id: "runstep_100",
  //     scan_id: "123_abc",
  //     node_id: "09c94f73cd2520b1",
  //     data_type: "ip_address",
  //     data: "1b3320b91949742b85caa5930bb7fee2da3e50408dbd8c33e6181cd6d5de7d61",
  //     meta_data: null,
  //     archive: false,
  //     archive_reason: null,
  //   },
  //   {
  //     id: 1229,
  //     assessment_id: "assess_100",
  //     created_at: "2022-03-14T08:07:53.008528Z",
  //     updated_at: "2022-03-14T08:07:53.008546Z",
  //     runstep_id: "runstep_100",
  //     scan_id: "123_abc",
  //     node_id: "91695c26671ac545",
  //     data_type: "ip_address",
  //     data: "d302cdba0fb078cbda7efd4998c8266626593d9a4ade042466b502a668a05c11",
  //     meta_data: null,
  //     archive: false,
  //     archive_reason: null,
  //   },
  // ];
  public detailEtity = [];
  public allConfirmEntities:any = [];
  public tabsStructure = {
    ip_address: "IP",
    web_app: "Web Apps",
    internet_name: "Sub Domain",
    domain_name: "Domains",
    web_app_vulnerability: "Web App Vulnerability",
    vulnerability: "Network Vulnerability",
    netblock: "Netblock",
    exploit: "Exploitation",
    service: "Service",
  };
  public tabsDetailsFieldStructure = {
    ip_address: "IP",
    web_app: "Web Apps",
    internet_name: "Sub Domain",
    domain_name: "Domain",
    web_app_vulnerability: "Web App Vulnerability",
    vulnerability: "Network Vulnerability",
    netblock: "Netblock",
    exploit: "Exploitation",
    service: "IP/Service",
  };
  noDataAvailableForRunstep: boolean;
  runStep: any;
  nextNodes: any[];
  assessmentDetails: Object;
  previousNodes: any[];
  getEntityDetailsSubs: any;
  getingCountResume:boolean = false;
  getingResume:boolean = false;
  total_count:any = 0;
  consumes:any = [];
  public page = 1;
  public loadingData:boolean = true;
  constructor(
    private expertService: ExpertService,
    public router: Router,
    public assessmentService: AssessmentService,
    public route: ActivatedRoute,
    public kgToNgxGraphFormat: KgToNgxGraphFormatService,
    public modalService: BsModalService,
    public commonService: CommonService,
    public modalRef: BsModalRef,
    public tostr:ToastrService
  ) {
  }

  ngOnInit(): void {
    this.assessmentId = this.route.snapshot.paramMap.get("attack_id");
    this.runstepId = this.route.snapshot.paramMap.get("runstep_id");
    // console.log('assessmentId',this.assessmentId)
    // console.log('runstepId',this.runstepId)
    this.getEntityTypes();
  }
  getEntityTypes() {
    this.assessmentService.getAssessmentDetails(this.assessmentId).subscribe(data => {
      this.assessmentDetails = data
      // console.log(data, "I am data")
      if (data && data['runbook'] && data['runbook']['runflow'] && data['runbook']['runflow'][0] && data['runbook']['runflow'][0].elements) {
        // console.log(data['runbook']['runflow'][0], "I am rb")
        this.runStep = data['runbook']['runflow'][0].elements.find(rb => rb.id == this.runstepId)
        this.nextNodes = this.kgToNgxGraphFormat.getNextNodes(this.runStep, data['runbook']['runflow'][0].elements)
        this.previousNodes = this.kgToNgxGraphFormat.getPreviousNodes(this.runStep, data['runbook']['runflow'][0].elements)
      }
      this.nextNodes.map((x)=>{
        console.log('consume',x);
        let module = x?.data?.modules;
        if(module && module.length){
          module.map((x)=>{
            if(x?.consumes) this.consumes.push(...x?.consumes);
          })
        }
      })
      this.expertService.getEntityTypes(this.assessmentId, this.runstepId).subscribe(
        (data) => {
          console.log('getEntityTypes',data);
          if (data && data['data'] && data['data'].length && data['data'][0]["entity_type"]) {
            let checkEntities = data['data'][0]["entity_type"];
            for (let entity in checkEntities) {
              if (entity && this.allowedEntities.includes(entity)) {
                this.allEntities[entity] = checkEntities[entity]
              }
            }
            this.showThis = Object.keys(this.allEntities)[0];
            this.getDetailsEntityWise();
          } else {
            this.noDataAvailableForRunstep = true
          }
        },
        (error) => {
          console.log("error while getting entities", error);
          alert(
            "Something went wrong, please try again after some time or contact us for more details"
          );
        }
      );
    })
  }
  getDetailsEntityWise() {
    this.loadingData = true;
    // console.log(this.showThis, "I am this is show this2222")
    if (this.getEntityDetailsSubs) this.getEntityDetailsSubs.unsubscribe()
    this.getEntityDetailsSubs = this.expertService
      .getEntityDetail(this.assessmentId, this.runstepId, this.showThis, this.page)
      .subscribe(
        (data) => {
          this.loadingData = false;
          // console.log("dadta......................", data);
          // console.log("...show2...........", this.showThis, this.detailEtity);
          if(data && data['data']['count']>0){
            this.total_count = data['data']['count']; 
            this.detailEtity = data['data']['results'];
          }else{
            this.detailEtity = [];
            this.total_count = 0; 
          }
        },
        (error) => {
          console.log(error);
          this.loadingData = false;
        }
      );
  }
  titleForPage() {
    if (this.nextNodes && this.nextNodes.length) {
      if (this.nextNodes[0].data && this.nextNodes[0].data.title != 'End') {
        return `Review targets for ${this.nextNodes[0].data.title} step`
      } else if (this.nextNodes[0].data && this.nextNodes[0].data.title == 'End') {
        return `Review results to index to attack surface`
      } else {
        return `Review`
      }
    }
  }
  archiveUnarchive(action,node){
    // console.log(node);
    node.loading = true;
    let requestBody = {
      "node_ids":[],
      "archive":true, 
      "archive_reason": ""
    };
    if(action=="select"){
      requestBody.node_ids = [node.node_id];
      requestBody.archive = false;
      requestBody.archive_reason = "";
    }else if(action=="ignore"){
      requestBody.node_ids = [node.node_id];
      requestBody.archive = true;
      requestBody.archive_reason = "False positive";
    }
    console.log(requestBody);

    this.expertService.archiveUnarchive(this.assessmentId, this.runstepId,requestBody).subscribe(
      (data) => {
        console.log('archiveUnarchive',data);
        this.getDetailsEntityWise();
        node.loading = false;
        this.tostr.info("Updated successfully.")
      },
      (error) => {
        console.log("error while updating status", error);
        alert(
          "Something went wrong, please try again after some time or contact us for more details"
        );
        node.loading = false;
      }
    );
  }
  getResumeCount(modalRef){
    this.getingCountResume = true;
    this.expertService.getNextStepCount(this.assessmentId, this.runstepId).subscribe(
      (data) => {
        // console.log('getResumeCount',data['data'].length);
        this.allConfirmEntities = [];
        this.getingCountResume = false;
        if(data && data['data'].length){
          for (let entity in data['data']) {
            let thisEntity = data['data'][entity];
            if (thisEntity && this.allowedEntities.includes(thisEntity['data_type'])) {
              this.allConfirmEntities.push(thisEntity);
            }
          }
        }

        this.openModal(modalRef);
      },
      (error) => {
        this.getingCountResume = false;
        console.log("error while updating status", error);
        alert(
          "Something went wrong, please try again after some time or contact us for more details"
        );
      }
    );
  }
  openModal(template: TemplateRef<any>, data=false) {
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  resumeProcess(){
    this.getingResume = true;
    this.expertService.resumeProcess(this.assessmentId, this.runstepId).subscribe(
      (data) => {
        console.log('resumeProcess',data);
        this.getingResume = false;
        if(data && data['status']=="pass"){
          this.tostr.success("Process resumed successfully.");
          this.modalRef.hide();
          this.router.navigate(['/playbook/results/',this.assessmentId]);
        }else{
          this.tostr.error("Failed! Something went wrong, failed to resume process.");
        }
      },
      (error) => {
        this.getingResume = false;
        console.log("error while updating status", error);
        alert(
          "Something went wrong, please try again after some time or contact us for more details"
        );
      }
    );
  }
  goToPage(val) {
    this.page = val;
    this.getDetailsEntityWise();
  }
  showDataTypeCount(count_key,list){
    let count = 0;
    let listData = [];
    if(list && list.length>0){
      list.map((x)=>{
        if(x.data_type==count_key && !listData.includes(x.data)){
          listData.push(x.data);
          count++;
        }
      })
    }
    return count;
  }
  getServiceIp(list){
    let obj =  list.find(o => o.data_type === 'ip_address');
    if(obj && obj.data) return obj.data;
  }
}
