import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  SimpleChanges,
} from "@angular/core";
import { AssessmentService, JobService } from "../../_services";
import { CommonService } from "app/_services/common.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute } from "@angular/router";
import { AssetTypePipe } from "app/pipes/asset-type.pipe";
import { AuthService } from "../../views/pages/auth.service";
import { ReportService } from "app/_services/report.service";
import { SpoceBaseURL } from "../../../environments/environment";

var parser = require("cron-parser");

@Component({
  selector: "app-attack-list",
  templateUrl: "./attack-list.component.html",
  styleUrls: ["./attack-list.component.scss"],
})
export class AttackListComponent implements OnInit {
  @Input() mainDomain;
  @Input() filters: any;
  @Input() reload;
  @Input() actionParam;
  public page = 1;
  public limit = 10;
  public showSpinnerPagination = false;
  public attackListCount = 0;
  public showThis = "active";
  public status;
  public assessmentDetail;
  public confirmStatusAttack;
  public showSpinnerSheduled = false;
  public attackList = [];
  public crontabValues;
  public action = null;
  public actionId = null;
  public actionIndex = -1;
  public totalPausedAssessments;
  newRequest: any;
  userPreference;
  public assetTypePipe = new AssetTypePipe();
  public searchKey;
  public selectedRunStep = null;
  public displayTab:any = {
    Active:true,
    Triage:true,
    Completed:true,
    Archived:true,
    Errored:true,
    Scheduled:true,
  };
  constructor(
    public commonService: CommonService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public assessmentService: AssessmentService,
    public route: ActivatedRoute,
    private authService: AuthService,
    public jobService: JobService,
    public reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.userPreference = JSON.parse(localStorage.getItem("userPreference"));
    this.getAttackList();
    if (this.actionParam != 'completed') this.getPausedAssessmentCount();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reload) {
      if (this.showThis == "schedule") {
        this.getScheduledAttackList();
      } else {
        this.attackList = [];
        this.getAttackList();
      }
    }
    this.setDisplayTab();
  }
  setDisplayTab() {
    for (var key in this.displayTab) {
      this.displayTab[key] = false;
    }
    if (this.actionParam == 'completed') {
      this.displayTab['Completed'] = true;
      this.showThis = 'completed';
      this.getAttackListWithState('completed');
    } else {
      this.displayTab['Active'] = true;
      this.displayTab['Triage'] = true;
      this.displayTab['Errored'] = true;
      this.displayTab['Archived'] = true;
      this.displayTab['Scheduled'] = true;
      this.showThis = 'active';
      this.getAttackListWithState('in_progress');
    }
  }
  modalHide() {
    this.modalRef.hide();
  }
  getTargetModel(template: TemplateRef<any>, data) {
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
    this.assessmentDetail = data;
  }

  targetType(target) {
    const entity: String = this.assetTypePipe.transform(target, true);
    return this.commonService.returnInitials(entity);
  }

  targetTypeHoverText(target) {
    const entity: String = this.assetTypePipe.transform(target, true);
    return this.commonService.returnHoverText(entity);
  }

  goToPage(val) {
    this.page = val;
    this.attackList = [];
    if (this.showThis != "schedule") {
      this.getAttackList();
    } else if (this.showThis == "schedule") {
      this.getScheduledAttackList();
    }
  }
  getAttackListWithState(val) {
    this.attackList = [];
    this.page = 1;
    this.status = val;
    this.getAttackList();
  }
  getScheduledAttackList() {
    this.newRequest ? this.newRequest.unsubscribe() : null;
    this.attackList = [];
    this.showSpinnerSheduled = true;
    this.showSpinnerPagination = true;
    const params = {
      page: this.page,
      limit: this.limit,
      status: this.status,
      ...this.filters,
      search: this.searchKey,
    };
    this.newRequest = this.jobService
      .getAllSchedulingAssessment("firecompass.com", params)
      .subscribe(
        (data: any) => {
          if (data) {
            const scheduledData = data["results"];
            this.attackList = [];
            scheduledData.forEach((data) => {
              var jsonData = {
                name: data["name"],
                created_by_email: data["created_by_email"],
                created_by_id: data["customer_id"],
                target: data["job_config"].assessment_config?.targets,
                runbook: data.job_config.assessment_config?.runbook_detail,
                state: "scheduled",
                status: "scheduled",
                created_at: data["created_at"],
                updated_at: data["updated_at"],
                primary_domain: data["main_domain"],
                job_config: data["job_config"],
                ...data,
              };
              this.attackList.push(jsonData);
              try {
                if (data.schedule?.crontab) {
                  this.crontabValues = [
                    data.schedule.crontab.minute,
                    data.schedule.crontab.hour,
                    data.schedule.crontab.day_of_month,
                    parseInt(data.schedule.crontab.month_of_year) + 1 ||
                      data.schedule.crontab.month_of_year,
                    data.schedule.crontab.day_of_week,
                  ];
                  var interval = parser.parseExpression(
                    this.crontabValues.join(" ")
                  );
                  let a = interval.next();
                  jsonData["cronvalue"] = a;
                }
              } catch (e) {
                console.log(e);
              }
            });
            this.attackListCount = data["count"];
            this.showSpinnerPagination = false;
          }
        },
        (error) => {
          this.showSpinnerPagination = false;
        }
      );
    this.showSpinnerSheduled = false;
  }

  getPausedAssessmentCount() {
    const params = {
      page: this.page,
      limit: 1,
      status: "paused",
      search: this.searchKey,
    };
    this.assessmentService
      .getAttackListDetails(params)
      .subscribe((data: any) => {
        if (data) {
          this.totalPausedAssessments = data.count;
        }
      });
  }
  getAttackList() {
    console.log(Math.random(),this.status)
    this.newRequest ? this.newRequest.unsubscribe() : null;
    this.showSpinnerPagination = true;
    const params = {
      page: this.page,
      limit: this.limit,
      status: this.status,
      ...this.filters,
      search: this.searchKey,
    };
    if (this.status == "archived") {
      params["archive"] = true;
      delete params["status"];
    } else params["archive"] = false;
    this.newRequest = this.assessmentService
      .getAttackListDetails(params)
      .subscribe(
        (data: any) => {
          if (data) {
            this.attackList = data["results"];
            this.attackListCount = data["count"];
            this.showSpinnerPagination = false;
            if (
              this.attackList &&
              this.attackList.length &&
              this.userPreference
            ) {
              if (
                !this.userPreference.user_recommendation ||
                !this.userPreference.user_recommendation.runbook ||
                this.userPreference.user_recommendation.runbookD != "completed"
              ) {
                const data = {
                  ...this.userPreference,
                  user_recommendation: { runbook: "completed" },
                };
                this.authService.updateUserPref({ pref: data });
              }
            }
          }
        },
        (error) => {
          this.showSpinnerPagination = false;
        }
      );
  }

  attachFrequency(attack, type) {
    if (
      attack.job_config &&
      (attack.job_config.frequency || attack.job_config.frequency == 0)
    ) {
      var ISTOffset = 330; // IST offset UTC +5:30
      var ISTTime = new Date(attack.cronvalue.getTime() + ISTOffset * 60000);
      var hours = ISTTime.getHours();
      var minute = ISTTime.getMinutes();
      let frequency = attack.job_config.frequency;
      if (frequency == 0)
        return (
          "One time on " +
          attack.cronvalue.getFullYear() +
          "-" +
          ("0" + attack.schedule.crontab.month_of_year).slice(-2) +
          "-" +
          ("0" + attack.schedule.crontab.day_of_month).slice(-2) +
          " - Time: " +
          ("0" + hours).slice(-2) +
          ":" +
          ("0" + minute).slice(-2)
        );
      if (frequency == 1)
        return (
          "Daily at: " +
          ("0" + hours).slice(-2) +
          ":" +
          ("0" + minute).slice(-2) +
          " (24 hours format)"
        );
      if (frequency == 7)
        return (
          "Weekly on: " +
          this.commonService.getDayOfWeekFromDate(attack.cronvalue) +
          " - Time: " +
          ("0" + hours).slice(-2) +
          ":" +
          ("0" + minute).slice(-2)
        );
      if (frequency == 30)
        return (
          "Monthly on date: " +
          attack.cronvalue.getDate() +
          " - Time: " +
          ("0" + hours).slice(-2) +
          ":" +
          ("0" + minute).slice(-2)
        );
      else return "NA";
    }
  }

  getAssessmentBaseName(assessment_name) {
    return assessment_name.split("_#_")[0];
  }

  getAssessmentSubscript(assessment_name) {
    return assessment_name.split("_#_")[1] || null;
  }
  getDateFromCronValue(cronDate) {
    return cronDate;
  }
  changeStatus(attack) {
    attack.schedule.enabled = !attack.schedule.enabled;
    this.jobService
      .updateScheduleReport(attack.id, attack)
      .subscribe((data) => {
        this.modalRef.hide();
      });
  }

  modalOpenForStatusConfirmation(template: TemplateRef<any>, attack) {
    this.confirmStatusAttack = attack;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "max-width-dialog-modal",
    });
  }
  hideModelStatus() {
    this.modalRef.hide();
  }
  updateAssessment() {
    const dataToSend = {};
    if (this.action == "abort") dataToSend["status"] = "aborted";
    if (this.action == "unarchive") dataToSend["archive"] = false;
    this.assessmentService
      .updateAssessment(this.actionId, dataToSend)
      .subscribe(
        (data: any) => {
          if (this.actionIndex != -1) {
            this.attackList.splice(this.actionIndex, 1);
          }
        },
        (error) => {
          alert("Something went wrong! Please try again.");
        }
      );
    this.modalHide();
  }
  archiveAssessment() {
    this.assessmentService.deleteAssessment(this.actionId).subscribe(
      (data: any) => {
        if (this.actionIndex != -1) {
          this.attackList.splice(this.actionIndex, 1);
        }
      },
      (error) => {
        alert("Something went wrong! Please try again.");
      }
    );
    this.modalHide();
  }
  getState(value) {
    if (value && this.commonService.states[value])
      return this.commonService.states[value];
    else return value;
  }
  openConfirmationModal(template: TemplateRef<any>, id, actionType, i) {
    this.actionIndex = i;
    this.action = actionType;
    this.actionId = id;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "max-width-dialog-modal",
    });
  }
  downloadReport(report) {
    this.reportService.getSignedUrlById(report.report_id).subscribe((data) => {
      var profile = JSON.parse(localStorage.getItem("profile"));
      if (!profile["reportDownload"]) {
        alert(
          "Reports download is disabled for this account. Please write to us at contact@firecompass.com"
        );
      } else {
        if (
          data &&
          data["files"] &&
          data["files"].length &&
          data["files"][0].url
        ) {
          let signedUrl = data["files"][0].url;
          const url = window.open(signedUrl, "_blank");
        } else {
          alert(
            "No report found for this assessment. Please contact Firecompass team!"
          );
        }
      }
    });
  }
  isAlertService(runb){
    if(runb?.config?.target?.source?.value==="alert_service"){
      return true;
    }else{
      return false;
    }
  }
  isDelta(runb) {
    let delta = runb?.config?.target?.delta_attack_surface?.value
      ? true
      : false;
    return delta;
  }
  searchAssessment() {
    if (this.showThis == "schedule") {
      this.getScheduledAttackList();
    } else {
      this.getAttackList();
    }
  }

  openRedirectModal(template: TemplateRef<any>, attack) {
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
    this.selectedRunStep = attack;
  }

  openTriageCenter() {
    console.log(this.selectedRunStep);

    this.selectedRunStep.progress.map((item: any) => {
      if (item.data.name && item.data.name.match(/event_triaging_/g)) {
        window.open(
          SpoceBaseURL.url +
            "assessmentcentre/assessments/expertactions/redirect/" +
            this.selectedRunStep.id +
            "/" +
            item.id,
          "_blank"
        );
        this.modalRef.hide();
      }
    });

    this.modalRef.hide();
  }

  cancelRedirect() {
    this.selectedRunStep = null;
    this.modalRef.hide();
  }
}
