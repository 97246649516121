import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "../../_guards/auth-guard.service";
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from "@angular/forms";

import { DashboardComponent } from "./dashboard.component";
import { ThirdPartyDashboardComponent } from "./third-party-dashboard.component";
import { DashboardTimelineComponent } from "./dashboard-timeline.component";
import { DashboardV3Component } from "./dashboard-v3/dashboard-v3.component";

const routes: Routes = [

  {
    path: "",
    // component: DashboardTimelineComponent,
    component: DashboardV3Component,
    // component : DashboardComponent,
    canActivateChild: [AuthGuardService],
    data: {
      title: " Dashboard / Insights & Risks",
    },
    // children: [
    //   {
    //     path: "time-line",
    //     component: DashboardTimelineComponent,
    //     canActivateChild: [AuthGuardService],
    //     data: {
    //       title: "Timeline",
    //     },
    //   },
    // ],
  },
  {
    path: "third-party",
    component: ThirdPartyDashboardComponent,
    canActivateChild: [AuthGuardService],
    data: {
      title: "3rd Party Dashboard",
    },
  },
  {
    path: "time-line",
    component: DashboardTimelineComponent,
    canActivateChild: [AuthGuardService],
    data: {
      title: "Timeline",
    },
  },
  {
    path: "v3",
    component: DashboardV3Component,
    canActivateChild: [AuthGuardService],
    data: {
      title: "Dashboard",
    },
  },
  {
    path: "delta",
    canActivateChild: [AuthGuardService],
    loadChildren: () =>
      import("../delta-details/delta-details.module").then((m) => m.DeltaDetailsModule),
   }
];

@NgModule({
  imports: [FormsModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
