import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { CommonService } from 'app/_services/common.service';

@Component({
  selector: 'app-common-delete',
  templateUrl: './common-delete.component.html',
  styleUrls: ['./common-delete.component.scss']
})
export class CommonDeleteComponent implements OnInit {

	@Input() modalRef;
  @Output() delReason: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelDel : EventEmitter<any> = new EventEmitter<any>();
  @Input() isUserTrial;
  @Input() alertToShow = null;
	public reason = '';

  constructor(public commonservice: CommonService) { }

  ngOnInit() {
  }
  deleteEntity(){
	  this.delReason.emit(this.reason);
  }
  delCancel(){
    this.cancelDel.emit("I am not deleted");
    this.modalRef.hide();
  }
}