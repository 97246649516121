import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";

import { ReportConfig } from "../../environments/environment"

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    public httpSVC: HttpService,
  ) { }

  public reportServiceConfig = ReportConfig;

  
	public getSignedUrlById(reportId) {
		const getSurveyForUserUrl =
			this.reportServiceConfig.url + "/reports/" + reportId;
		return this.httpSVC.get(getSurveyForUserUrl);
  }
  public getTemplateForSchedulingReports() {
    const url = this.reportServiceConfig.url + "/reports/template/";
    return this.httpSVC.get(url);
  }
  public getDownloadReport(page) {
    const url =
      this.reportServiceConfig.url +
      "/reports/" +
      "?page=" +
      page +
      "&archive=" +
      false;
    return this.httpSVC.get(url);
  }
  public updateReports(report_id, jsonToUpdate) {
    const url = this.reportServiceConfig.url + "/reports/" + report_id + "/";
    return this.httpSVC.patch(url, jsonToUpdate);
  }
  public generateLeakedCredCsv(jobId) {
    const url =
      this.reportServiceConfig.url + "/reports/?archive=False&job_id=" + jobId;
    return this.httpSVC.get(url);
  }
}
