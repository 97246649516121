import {
  Component, OnInit, Input, TemplateRef, OnChanges, SimpleChanges, SimpleChange, ChangeDetectorRef, Output, EventEmitter
} from "@angular/core";
import { PortfolioSurveyService } from "../../_services";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CommonService } from "../../_services/common.service";
import { SortPipe } from "app/pipes/sort.pipe";
import { Router, ActivatedRoute } from '@angular/router';
import { AsyncToasterTriggerService } from '../../_services/async-toaster-trigger.service';
import { JobService }  from "../../_services/job.service"
import { TrialCheckService } from "../../_services/trial-check.service";

@Component({
  selector: "app-domains",
  templateUrl: "./domains.component.html",
  styleUrls: ["./domains.component.scss"],
})
export class DomainsComponent implements OnInit, OnChanges {
  @Input() mainDomain: any;
  // @Input() domains: any;
  // @Input() whoisDetailsForRds: any;
  @Input() isUserTrial: boolean = false;
  // @Input() relatedDomainsCount: any
  @Input() filters: any;
  @Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
  public domains
  public whoisDetailsForRds
  public showSubDomain = false;
  public subjectType = 'related_domain';
  public allDomains: any;
  public allDomainsObsIns: any;
  public openThis = -1;
  public seeMore = 10;
  public openDropdown = false;
  public order = 1;
  public startDay = 7;
  public endDay = 30;
  public openSlider = false;
  public tags: any = [];
  public whoisForDomain: any;
  public infoTagDay = 7;
  public hasExpiredDomains = false;
  public hasNonExpiredDomains = false;
  public newDomain;
  public showDowloadText: boolean = false;
  public addNewDomain = [];
  public monitors = [];
  public pocSubjectTouchPoint;
  public pocSubjectComment;
  public pocSubjectObsIns;
  public selectedTags;
  public showSpinner = true;
  public subDomains;
  public page = 1;
  public limit = 10;
  public searchKey = null;
  public total_domain_count: number = null;
  public subDomainsCount = null
  public oldTags = null;
  public subDomainDetailsForRds;
  public domainsArray = []
  public domainsToDelete = [];
  public sortWithKey = null
  public whoisSpinner = false;
  public subdomainCountSpinner = false;
  public obsInsCountSpinner = false;
  public HighlyTagWithValue = {
    ACTIVE: {
      name: "ACTIVE",
      value: ["active"],
      status: false,
    },
    PREPROD: {
      name: "PRE-PROD",
      value: ["preprod", "uat"],
      status: false,
    },
    DATABASES: {
      name: "DATABASES",
      value: ["databases"],
      status: false,
    },
  };
  public selectedExpiredData:any = null;
  public expiryData: any = [
    { name: "Show All", value: null },
    { name: "Expired", value: 0 },
    { name: "Expiring in 7 days", value: 7 },
    { name: "Expiring in 30 days", value: 30 },
    { name: "Expiring in 60 days", value: 60 },
    { name: "Expiring in 90 days", value: 90 }
  ];
  public selectedDomainExpiredData:any = null;
  public domainExpiryData: any = [
    { name: "Show All", value: null },
    { name: "Expired", value: 0 },
    { name: "Expiring in 7 days", value: 7 },
    { name: "Expiring in 30 days", value: 30 },
    { name: "Expiring in 60 days", value: 60 },
    { name: "Expiring in 90 days", value: 90 }
  ];
  public selectedDomainToUpdate = null;
  public showArchivedDomain = false
  public completeProgressBar = false;
  public messageAlrte;
  public inscopeConfig = null
  public inscopeFlag = null
  public showThis = 'expiry'
  public isTrialUser:boolean = true;
  public checkingExistingDomain:boolean = false;
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public commonService: CommonService,
    public cdr: ChangeDetectorRef,
    public route: ActivatedRoute,
     public router: Router,
    public asyncTrigger: AsyncToasterTriggerService,
    public jobService : JobService,
    public trialCheckService: TrialCheckService
  ) { }
  ngOnInit() {
    this.isTrialUser = this.trialCheckService.isTrailUser();
    this.getRelatedDomainsSubDomains()
    this.getTagsForDomain()
    this.inscopeConfig = localStorage.getItem('scope_config')
    if(this.inscopeConfig == 'true'){
      this.showThis = 'inscope'
    }else{

    }
    this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
			this.getRelatedDomainsSubDomains()
		})
  }

  ngOnChanges(changes: SimpleChanges) {
    const changeFilters: SimpleChange = changes.filters
    if (Object.keys(changeFilters.currentValue).length) {
      if (this.filters && this.filters.page) {
        this.page = this.filters.page
        delete this.filters.page
      }
      this.getRelatedDomainsSubDomains()
    }
  }

  checkPrivateConditions(administrator) {
    if (
      administrator["full_name"] &&
      administrator["full_name"].toLowerCase().indexOf("privacy") >= 0
    ) {
      return false;
    } else if (
      administrator["registrar_name"] &&
      administrator["registrar_name"].toLowerCase().indexOf("privacy") >= 0
    ) {
      return false;
      // } else if (administrator['email_address'] && administrator['email_address'].indexOf('abuse') >= 0) {
      // return false;
    } else {
      return true;
    }
  }

  displayExipryDate(change_domains) {

    this.domains.forEach((rd) => {
      let whoisDetails = change_domains[rd["id"]];
      if (whoisDetails) {
        let expried_date = whoisDetails["expired_date"];
        rd["expired_date"] = expried_date;
        let administrator = whoisDetails["administrative_contact"];
        let registrar = whoisDetails["domain_registrar"];
        let registrant = whoisDetails["registrant_contact"];
        let technicalContact = whoisDetails["technical_contact"];
        if (administrator && administrator["email_address"]) {
          if (this.checkPrivateConditions(administrator)) {
            rd["registrar_email"] = administrator["email_address"];
            rd["registrar_org"] = administrator["company_name"];
          } else {
            rd["registrar_email"] = "Privacy Protected";
          }
        } else if (registrant && registrant["email_address"]) {
          if (this.checkPrivateConditions(registrant)) {
            rd["registrar_org"] = registrant["company_name"];
            rd["registrar_email"] = registrant["email_address"];
          } else {
            rd["registrar_email"] = "Privacy Protected";
          }
        } else if (technicalContact && technicalContact["email_address"]) {
          if (this.checkPrivateConditions(technicalContact)) {
            rd["registrar_org"] = technicalContact["company_name"];
            rd["registrar_email"] = technicalContact["email_address"];
          } else {
            rd["registrar_email"] = "Privacy Protected";
          }
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  displayTags(domain) {
    if (domain.tags && domain.tags.length > 0) {
      return true;
      // } else if (domain.takeover_risk && domain.takeover_risk >= 2.0) {
      //   return true;
    } else {
      return false;
    }
  }

  checkWhoisDetailsForRd(rd) {
    if (
      this.whoisDetailsForRds &&
      this.whoisDetailsForRds[rd["id"]]
    ) {
      return true;
    }
    return false;
  }

  modalOpenForWhois(template: TemplateRef<any>, domain) {
    this.whoisForDomain = {
      name: domain.domain,
      whoisForDomain: this.whoisDetailsForRds[domain["id"]],
    };
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }

  openNewDomain(template: TemplateRef<any>) {
    this.newDomain = '';
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }

  getLodingStatus(duration, days) {
    if (duration == 0) {
      if (!days || days >= -30) {
        this.hasNonExpiredDomains = true;
        return true;
      } else {
        return false;
      }
    } else if (duration == -30) {
      if (days < -30) {
        this.hasExpiredDomains = true;
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  domainAlreadyExist(domain){
    let that = this;
    this.checkingExistingDomain = true;
    return new Promise(function(resolve, reject) { 
      that.portfolioSurveyService.getRelatedDomainsSubDomainsWithFilters(that.mainDomain["domain_name"],{searchKey:domain}).subscribe((resp)=>{
        that.checkingExistingDomain = false;
        resolve(resp);
      },(err)=>{
        that.checkingExistingDomain = false;
        reject(err);
      })
    })
  }
  async addNewDomainBtn() {
    this.addNewDomain = [
      {
        added: true,
        discovered_at: null,
        domain: this.newDomain,
        main_domain_id: this.mainDomain["id"],
        score: "8",
      },
    ];
    let searchResp = await this.domainAlreadyExist(this.newDomain);
    console.log(searchResp);
    if(searchResp && searchResp['status']=='pass' && searchResp['data']  && searchResp['data']['count']==0){
      console.log("Domain not exist.");
    }else{
      alert("Error! Domain already exists.");
      return;
    }
    this.portfolioSurveyService
      .addNewDomain(this.mainDomain["domain_name"], this.addNewDomain)
      .subscribe(
        (data) => {
          this.domains.unshift(this.addNewDomain[0]);

        },
        (error) => {
          if (error["status"] === 403) {
            alert(
              "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
            );
          } else {
            alert("Error while updating");
          }
        }
      );
    this.modalRef.hide();
    this.newDomain = null
  }
  openRemoveOptions(template: TemplateRef<any>, domain) {
    domain.selected = true;
    this.selectedDomainToUpdate = domain['domain']
    this.messageAlrte = "Deleting " + this.selectedDomainToUpdate + " domain will affect Subdomain, IP, Open Ports, Application, PasteData, DigitalRisk, CloudBucket, LeakedCredential which are directly associated."
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  // delRelatedDomain(reason) {
  //   this.domainsToDelete = []
  //   this.domains.map((domain) => {
  //     if (domain.selected == true) {
  //       domain.updated = true;
  //       domain.archive = true;
  //       domain.archive_reason = reason;
  //       delete domain["selected"];
  //       // this.delReason = null;
  //       this.domainsToDelete.push(domain)

  //     }
  //   });
  //   if (this.domainsToDelete) {
  //     this.updateRelatedDomain();
  //   }
  // }
  // public updateRelatedDomain() {
  //   this.portfolioSurveyService
  //     .updateRelatedDomain(this.domainsToDelete, this.mainDomain["domain_name"])
  //     .subscribe(
  //       (data) => {
  //         this.modalRef.hide();
  //         alert("Domain deleted successfully!")
  //       },
  //       (error) => {
  //         if (error["status"] === 403) {
  //           alert(
  //             "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
  //           );
  //         } else {
  //           alert("Error while updating");
  //         }
  //         this.modalRef.hide();
  //       }
  //     );
  // }
  public updateRelatedDomain(domain, reason = null) {
    let archiveState = !this.showArchivedDomain
    let dataForUpdate = {
      "job_type": "osint_bulk_operation",
      "job_config": {
        "resource": "domains",
        "operation": "archive",
        "payload": {
          "archive": archiveState,
          "related_domain": [domain],
          "archive_reason": reason,
        }
      }
    }
    this.jobService.updateRelatedDomainJobService(dataForUpdate).subscribe(
      (data) => {
        this.modalRef.hide();
        let reportJobId = data['job_run_id']
        if (reportJobId) {
          if (!this.showArchivedDomain) {
            alert("Please wait while we are removing all assets related to this domain. We will inform you once it is done! ")
          } else alert("Please wait while we are undeleting all assets related to this domain. We will inform you once it is done! ")

          this.updateAllAselets(reportJobId);
        }
        this.selectedDomainToUpdate = null;
        this.page = 1;
        this.getRelatedDomainsSubDomains();
        this.refreshCount.emit('domain');
      },
      (error) => {
        if (error["status"] === 403) {
          alert(
            "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
          );
        } else {
          alert("Error while updating");
        }
        this.modalRef.hide();
        this.selectedDomainToUpdate = null
      }

    );
  }
  updateAllAselets(reportJobId) {
    // AsyncToaster
    if (!this.showArchivedDomain) {
      this.asyncTrigger.emit({
        id:reportJobId,
        type:'job',
        messages:{
          running:'Deleting domain and related assets.',
          completed:'Domain and related assets deleted successfully!',
          send_to_background:"It's taking longer than expected, will continue in background.",
          error:'Error while deleting'
        }
      });
    }else{
      this.asyncTrigger.emit({
        id:reportJobId,
        type:'job',
        messages:{
          running:'Undeleting domain and related assets.',
          completed:'Domain and related assets restored successfully!',
          send_to_background:"It's taking longer than expected, will continue in background.",
          error:'Error while undeleting'
        }
      });
    }
    let waitingTime = 7;
    let poolingCount = 1;
    const poolingInterval = setInterval(() => {
      this.jobService.updateAllAseletsForDomain(reportJobId).subscribe((data: any) => {
        poolingCount = poolingCount + 1
        if (data) {
          if (data.job_status == 'completed') {
            this.refreshCount.emit('domain');
            if (!this.showArchivedDomain) {
              alert("Domain and related assets deleted successfully!")
            } else alert("Domain and related assets undeleted successfully!")
            this.completeProgressBar = true
            this.getRelatedDomainsSubDomains()
            this.getTagsForDomain()
            clearInterval(poolingInterval);
          } else if (data.job_status == 'failed') {
            alert("Error while updating")
            this.completeProgressBar = true
            clearInterval(poolingInterval);
          } else if (data.job_status == 'running' && poolingCount > waitingTime) {
            this.refreshCount.emit('domain');
            alert("We are still working on updating data. Please refresh your page after some time.")
            this.completeProgressBar = true
            clearInterval(poolingInterval);
          }
        } else if (poolingCount > waitingTime) {
          alert("Error while updating")
          clearInterval(poolingInterval);

        }
      }, (error) => {
        clearInterval(poolingInterval);
        if (error["status"] === 403) {
          alert(
            "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
          );
        } else {
          alert("Error while updating");
        }
      })
    }, 10000)
  }
  delReason(reason) {
    // this.delRelatedDomain(reason);
    this.updateRelatedDomain(this.selectedDomainToUpdate, reason)
  }
  cancelDel(a) {
    this.domains.map(domain => { domain.selected = false })
  }
  openCommentsModule(template: TemplateRef<any>, domain) {
    this.pocSubjectComment = {
      "subject_type": "related-domain",
      "subject_name": domain['domain'],
    }
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  openTouchpointModule(template: TemplateRef<any>, domain) {
    this.pocSubjectTouchPoint = this.pocSubjectComment = {
      "subject_type": "related-domain",
      "subject_name": domain['domain'],
    }
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  getAllComments() {
    this.portfolioSurveyService.getAllComments(this.mainDomain["domain_name"], "related-domain").subscribe((data) => {
      if (!this.allDomains) return
      this.allDomains.forEach(domain => {
        const commentsForDomain = data['data'].filter(a => {
          return a.subject_name == domain.domain
        })
        if (commentsForDomain && commentsForDomain.length) {
          commentsForDomain.forEach(cc => {
            domain.comments ? domain.comments.push(cc.title) : domain.comments = [cc.title]
          })
        }
      });
    })
  }
  getAllObsAndIns() {
    this.portfolioSurveyService.getAllObsAndIns(this.mainDomain["domain_name"], "related-domain").subscribe((data) => {
      if (!this.allDomains) return
      this.allDomains.forEach(domain => {
        const observationsForDomain = data['data'].filter(a => {
          return a.subject_name == domain.domain
        })
        if (observationsForDomain && observationsForDomain.length) {
          observationsForDomain.forEach(cc => {
            domain.observations ? domain.observations.push(cc.observations) : domain.observations = [cc.observations]
          })
        }
      });
    })
  }
  // getAllTouchpoints() {
  //   this.portfolioSurveyService.getAllTouchpoints(this.mainDomain["domain_name"], "related-domain").subscribe((data) => {
  //     this.allDomains.forEach(domain => {
  //       const touchpointsForDomain = data['data'].filter(a => {
  //         return a.subject_name == domain.domain
  //       })
  //       if (touchpointsForDomain && touchpointsForDomain.length) {
  //         touchpointsForDomain.forEach(cc => {
  //           domain.touchpoints ? domain.touchpoints.push(cc.email) : domain.touchpoints = [cc.email]
  //         })
  //       }
  //     });
  //   })
  // }
  getSubdomainData(domain) {
    const params = {
      rd_domain: domain['domain'],
    }
    this.portfolioSurveyService.getIndivualSubdomainDetails(this.mainDomain["domain_name"], params).subscribe(data => {
      if (data) {

        this.subDomains = data['data'].results;
        this.subDomainsCount = data['data'].count
      }
    })
  }
  getRelatedDomainsSubDomains() {
    this.showSpinner = true
    this.domainsArray = []
    const params = {
      page: this.page,
      limit: this.limit,
      searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
      tags: this.selectedTags && this.selectedTags.length ? this.selectedTags : null,
      // scope: this.inscopeFlag,
      // take_over_risk: this.showTakeOverRisk,
      ...this.filters,
      archive: this.showArchivedDomain,
      expire:this.selectedExpiredData,
      domain_expire:this.selectedDomainExpiredData
    }
    if(this.showArchivedDomain && this.inscopeConfig == 'true') params.scope = "ALL" 
		else if(this.showArchivedDomain && this.inscopeConfig == 'false')  delete params.scope 
		else if(!this.showArchivedDomain && this.inscopeConfig == 'true') params.scope = this.inscopeFlag
    if (this.sortWithKey) params.sortKey = this.order == -1 ? this.sortWithKey : '-' + this.sortWithKey
    this.portfolioSurveyService.getRelatedDomainsSubDomainsWithFilters(this.mainDomain['domain_name'], params).subscribe((data: any) => {
      if (data.data.results && data['status'] == 'pass') {
        this.showSpinner = false
        this.getAllComments()
        this.getAllObsAndIns()
        this.isUserTrial = this.commonService.setUserAccount(data['userAccount']);
        this.domains = data.data.results;
        this.total_domain_count = data.data['count']
        this.domains.forEach(d => {
          d.subjectTypeName = {
            "subject_type": this.subjectType,
            "subject_name": d['domain'],
          }
          this.domainsArray.push(d.domain)
          this.commonService.checkCreatedAndUpdated(d);
          this.commonService.calculateCreatedAndUpdated(d.hosts);
        })
        if (this.domainsArray.length) {
          this.getWhoIsForDomainsWithArray(this.domainsArray)
          this.getSubDomainCountsForEachDomain(this.domainsArray)
          this.getObsInsCountsForEachDomain(this.domainsArray)
        }
      } else {
        this.showSpinner = false
        if (data && data['message'] == "Failure while getDomainByNameDb") {
          this.portfolioSurveyService.startScanForDomain(this.mainDomain['domain_name']).subscribe(data => {
            if (data['status'] == "pass") {
              alert("Scan started for new domain or scan is under progress.. Please re-visit after some time.");
            } else {
              alert(data['message']);
            }
            this.router.navigate(['/new/self-domains']);
          })
        }
      }
    }, error => {
      this.commonService.errorForDomain(error);
    })

  }
  getWhoIsForDomainsWithArray(domainsArray) {
    this.whoisSpinner = true
    this.portfolioSurveyService.getWhoisDetailsForPagination(this.mainDomain['domain_name'], domainsArray).subscribe(data => {
      this.whoisSpinner = false
      this.whoisDetailsForRds = data['data']
      this.displayExipryDate(data['data'])
    })
  }
  goToPage(val) {
    this.openThis = -1
    this.page = val;
    this.getRelatedDomainsSubDomains()
  }
  searchAllDomainDetail(value) {
    this.openThis = -1
    if (value === 'search'){
      this.searchKey = null
      this.filters = { ...this.filters, search: null };
    }

    else if (value === 'tags') {
      this.selectedTags = null
      this.tags.forEach(tag => {
        tag.selected = false
      });
    }else if((value === 'expiry')){
      this.selectedExpiredData = null;
    }
    else if((value === 'domainExpiry')){
      this.selectedDomainExpiredData = null;
    }
    this.page = 1;
    this.getRelatedDomainsSubDomains()
  }
  searchDomainDetail(domain) {
    this.openThis = -1
    this.page = 1;
    this.filters = { ...this.filters, search: this.searchKey };

    this.getRelatedDomainsSubDomains()
  }
  changeTagFilterBasedOnHighyTag(tag) {
    if (this.HighlyTagWithValue[tag.name])
      this.HighlyTagWithValue[tag.name].status = !tag["selected"];
    tag["selected"] = !tag["selected"];
  }
  searchDomainsByTag() {
    this.page = 1;
    const selectedTagsArr = this.tags.map(t => {
      if (t.selected) {
        return t.tag_original
      }
    })
    this.selectedTags = selectedTagsArr.filter(t => t).join(',')
    this.filters = { ...this.filters, tags: this.selectedTags };

    this.getRelatedDomainsSubDomains()
  }
  getTagsForDomain() {
    this.portfolioSurveyService.getDomainTagsList(this.mainDomain['domain_name']).subscribe(data => {
      data['data'].forEach((tag) => {
        let tag_uppercase = tag.toUpperCase();
        let tagObject = { name: tag_uppercase, value: [tag_uppercase], tag_original: tag };
        if (
          tag_uppercase == "HTTP ACTIVE" ||
          tag_uppercase == "HTTPS ACTIVE"
        ) {
          tagObject["name"] = "ACTIVE";
        }
        let index = this.tags.findIndex(function (tagOb) {
          return tagOb.name == tagObject.name;
        });
        if (index == -1) {
          this.tags.push(tagObject);
        } else {
          if (this.tags[index]["value"].indexOf(tag_uppercase) == -1) {
            this.tags[index]["value"].push(tag_uppercase);
          }
        }
        if (this.inscopeConfig !='true'&& this.tags.length > 0){
          this.showThis = 'tag'
        }else{
          this.showThis = 'expiry'
        }
      });
    })
  }
  getSubDomainCountsForEachDomain(arrayList) {
    this.subdomainCountSpinner = true
    const params = {
      domain_list: arrayList,
      archive: this.showArchivedDomain
    }
    if(this.inscopeConfig == 'true') params['scope'] = (this.inscopeFlag)?this.inscopeFlag:'IN';
    this.portfolioSurveyService.getSubDomainCountForEachDomain(this.mainDomain['domain_name'], params).subscribe(data => {
      this.subdomainCountSpinner = false
      this.subDomainDetailsForRds = data['data']
      this.domains.forEach((rd) => {
        let domainName = rd.domain
        if (this.subDomainDetailsForRds[domainName]) {
          rd.sub_domain_count = this.subDomainDetailsForRds[domainName].count
        }
      })
    })
  }

  getObsInsCountsForEachDomain(arrayList) {
    this.obsInsCountSpinner = true
    const params = {
      subject_ref_list: arrayList,
      subject_type: this.subjectType
    }
    this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain['domain_name'], params).subscribe(data => {
      this.obsInsCountSpinner = false
      this.subDomainDetailsForRds = data['data']
      this.domains.forEach((rd) => {
        let domainName = rd.domain
        if (this.subDomainDetailsForRds[domainName]) {
          rd.obs_count = this.subDomainDetailsForRds[domainName].count_obs
          rd.ins_count = this.subDomainDetailsForRds[domainName].count_ins
        }
      })
    })
  }

  hideDeleteModel() {
    this.modalRef.hide()
    // this.newDomain = null
  }
  switchDataForArchive() {
    this.showArchivedDomain = !this.showArchivedDomain
    this.selectedTags = []
    this.searchKey = null
    this.page = 1
    this.getRelatedDomainsSubDomains()
  }
  getIconClass(status) {
    let st = (status && status != null) ? status.toLowerCase() : status;
    if (st == 'owned') {
      return 'fa-check-circle text-info';
    } else if (st == 'active') {
      return 'fa-check-circle text-success';
    } else if (st == 'inactive') {
      return 'fa-exclamation-circle text-warning';
    } else if (st == 'expired') {
      return 'fa-times-circle text-danger';
    } else if (st == 'parked') {
      return 'fa-bookmark oragne-text';
    } else {
      return 'fa-info-circle text-warning';
    }
  }
  filterByInscope(value) {
    this.page = 1;
    this.inscopeFlag = value;
    this.filters = { ...this.filters, scope: value };

    this.getRelatedDomainsSubDomains();
    this.openDropdown = false;
  }
  searchDomainsByExpiry($event){
    this.page = 1;
    if(this.showThis == 'expiry'){
    this.filters = { ...this.filters, expire: this.selectedExpiredData };

    }
    else{
      this.filters = { ...this.filters, domain_expire: this.selectedDomainExpiredData };

    }

    this.getRelatedDomainsSubDomains();
  }
}
