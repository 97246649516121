import { Component } from "@angular/core";
import { AuthService } from "../../views/pages/auth.service";
import { RolesService } from "../../_services/roles.service";
import { PortfolioSurveyService } from "app/_services/portfolio-survey.service";
import { ProfileTypePipe } from "app/pipes/profile-type.pipe";
import { CommonService } from "../../_services/common.service";
import { ClickOutsideDirective } from "app/shared/click-outside.directive";

@Component({
  selector: "app-header",
  templateUrl: "./app-header.component.html",
})
export class AppHeader {
  //   public disabled = false;
  //   public status: {isopen: boolean} = {isopen: false};

  //   public toggled(open: boolean): void {
  //     console.log('Dropdown is now: ', open);
  //   }

  //   public toggleDropdown($event: MouseEvent): void {
  //     $event.preventDefault();
  //     $event.stopPropagation();
  //     this.status.isopen = !this.status.isopen;
  //   }
  public showLogo = true;
  public notificationCount = 0;
  public openDropdown: boolean = false;
  public showTopCount = false;
  public notificationList;
  public userEmail = null;
  public accountType = null;
  public isScopeChanged = false;
  public primaryDomain = "";
  public logoUrl =
    "https://www.firecompass.com/wp-content/uploads/2020/10/cropped-FireCompass-Logo-1.png";
  constructor(
    public authService: AuthService,
    public rolesService: RolesService,
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (localStorage.profile) {
      let userDetails = JSON.parse(localStorage.profile);
      this.userEmail = userDetails.email;
      this.accountType = userDetails.account_type;
      this.primaryDomain = userDetails.primary_domain;
      this.isScopeChanged = userDetails.parent
        ? userDetails.parent.isScopeChanged
        : false;
      this.showLogo =
        userDetails["user_metadata"]?.show_logo == false ? false : true;
    }
    this.showTopCount = true;
    // this.getNotifications();

    console.log("Getting Whitelabel Data", localStorage.getItem("whiteLabel"));
    const whiteLabelDetails: any = JSON.parse(
      localStorage.getItem("whiteLabel")
    );

    console.log(whiteLabelDetails);
    if (whiteLabelDetails && whiteLabelDetails.type !== "none") {
      const favIcon: any = document.querySelector("#appIcon");
      console.log(favIcon);
      favIcon.href = whiteLabelDetails.favIcon;

      const title = document.querySelector("title");
      title.innerHTML = whiteLabelDetails.pageTitle;

      const footer = document.querySelector(".app-footer");
      footer.innerHTML = whiteLabelDetails.footerText;

      this.logoUrl = whiteLabelDetails.logoLink;
      // const Test = document.getElementById("app-logo");
      // Test. = whiteLabelDetails.logoLink;
      // console.log(Test);
    }
  }

  // This needs to be re-written
  ngDoCheck() {
    // console.log("Inside ngDoCheck");
    // console.log(localStorage.getItem("profile"));
    if (localStorage.getItem("profile")) {
      let userDetails = JSON.parse(localStorage.getItem("profile"));
      this.userEmail = userDetails.email;
      this.accountType = userDetails.account_type;
      this.primaryDomain = userDetails.primary_domain; // adding this to solve product support #254
      this.isScopeChanged = userDetails.parent
        ? userDetails.parent.isScopeChanged
        : false;
    }
  }

  getNotifications() {
    this.portfolioSurveyService.getNotifications().subscribe((data) => {
      this.notificationList = data;
      this.notificationCount = 0;
      this.notificationList.map((a) => {
        this.notificationCount = this.notificationCount + a["alerts"].length;
      });
    });
  }
  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return "#" + ("000000" + color).slice(-6);
  }
  markDistributionsRead() {
    let ids = [];
    this.notificationList.map((a) => {
      ids.push(a["distribution_ids"]);
    });
    // ids.reduce((a, b) => [...a, ...b], []);
    ids = [].concat(...ids);
    var uniqueIds = ids.filter(function (item, pos) {
      return ids.indexOf(item) == pos;
    });
    console.log(uniqueIds);
    this.portfolioSurveyService
      .markNotificationRead(uniqueIds)
      .subscribe((data) => {
        console.log(data);
      });
  }
}
