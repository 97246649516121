import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, TemplateRef } from '@angular/core';
import { ValidUrlPipe } from 'app/pipes/validate-url.pipe';
import { AssetTypePipe } from 'app/pipes/asset-type.pipe';
import { CommonService } from 'app/_services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TrialCheckService } from "app/_services/trial-check.service";
@Component({
  selector: 'app-rb-target',
  templateUrl: './rb-target.component.html',
  styleUrls: ['./rb-target.component.scss']
})
export class RbTargetComponent implements OnInit {
  public selectedScan = {};
  public showThis: String
  public newEntities = [];
  public domainName
  public scanStarted = false;
  public validUrlPipe = new ValidUrlPipe();
  public assetTypePipe = new AssetTypePipe();
  public showDataSpinner = false;
  public isUserTrial = false
  public domainText
  public targetLimit = false
  public maxEntities = 101
  public targets = []
  public restrictedTargets = []
  public restrictedTargetsRaw = []
  public invalidTargets = []
  public selectAttackSurface = true;
  public primaryDomain
  public entityRemoved
  public activeTab = "";
  public isTrialUser:boolean = true;

  constructor(
    public commonService: CommonService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public trialCheckService: TrialCheckService
  ) { }

  @Output() newAssessment = new EventEmitter<any>();
  @Input() showError: Boolean;
  @Input() targetTypes;
  @Input() selectedRunbook

  ngOnInit() {
    this.showThis = null;
    let profile = localStorage.getItem('profile')
    if (profile && profile.length) {
        this.isTrialUser = this.trialCheckService.isTrailUser();
        profile = JSON.parse(localStorage.getItem('profile'))
        this.primaryDomain =profile['primary_domain']
    }
  }
  isdelta(){
    if(this.selectedRunbook?.config?.target?.delta_attack_surface?.value){
      return true;
    }else{
      return false;
    }
  }
  isAlertService(){
    if(this.selectedRunbook?.config?.target?.source?.value==="alert_service"){
      return true;
    }else{
      return false;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.targetTypes) {
      this.selectedScan['scan_entities'] = []
      this.targets =[]
      this.newEntities = []
      this.invalidTargets = []
      this.targetTypes = changes.targetTypes.currentValue
      this.setTargetType(this.targetTypes)
    }
  }
  removeEntity(entity) {
    this.selectedScan['scan_entities'] = this.selectedScan['scan_entities'].filter(a => a !== entity);
    this.newEntities = this.newEntities.filter(a => a !== entity)
    this.maxTragetCheck(this.newEntities)
    this.newAssessment.emit(this.newEntities)
    this.entityRemoved = entity
    if(this.selectedScan['scan_entities'] && !this.selectedScan['scan_entities'].length) {
      this.showThis = null
    }
  }
  selectedTarget(tab){
    this.activeTab = tab;
  }
  resetEnitities(){
    this.selectedScan['scan_entities'] =  [];
    this.newEntities = []
    this.maxTragetCheck(this.newEntities)
    this.newAssessment.emit(this.newEntities)
    if(this.selectedScan['scan_entities'] && !this.selectedScan['scan_entities'].length) {
      this.showThis = null
    }
  }
  // removeAllEntity() {
  //   this.selectedScan['scan_entities'] = []
  //   this.newEntities = [];
  //   this.maxTragetCheck
  // this.newAssessment.emit(this.newEntities)
  // }
  onListChange(values, targets = null) {
    if (values) {
      this.targetLimit = false
      this.targets = targets ? targets : values.split(/[\n,]/);
      if (!this.maxTragetCheck(this.targets)) return
      const done = this.targets.filter((x, i) => {
        if (this.targets.indexOf(x) === i) {
          x = (x)?x.toLowerCase():x;
          const entity = x.trim();
          if (entity) {
            // ValidNetblockIps
            if(this.assetTypePipe.transform(entity,true)=='netblock' && this.commonService.getRange(entity)>this.commonService.maxAllowedRange){
              this.showThis = 'invalidTargets'
              this.invalidTargets.push(entity.trim())
              return;
            }
            if (this.restrictedTargetsRaw.includes("domain") && !this.restrictedTargetsRaw.includes("sub_domain")) {
              this.restrictedTargetsRaw.push("sub_domain");
            }
            if (!this.restrictedTargetsRaw.length || this.restrictedTargetsRaw.includes(this.assetTypePipe.transform(entity,true))) {
              this.showThis = this.assetTypePipe.transform(entity)
              if (this.selectedScan['scan_entities']) {
                if ((this.selectedRunbook.type=='atomic' && this.selectedScan['scan_entities'].length < 10) || (this.selectedRunbook.type=='molecular' && this.selectedScan['scan_entities'].length < 1000)) {
                  if ((this.selectedScan['scan_entities'].indexOf(entity) < 0) && (entity.length > 0)) {
                    this.selectedScan['scan_entities'].push(entity.trim());
                    this.newEntities.push(entity.trim());
                  }
                } else {
                  this.showThis = 'invalidTargets'
                  this.invalidTargets.push(entity.trim())
                }
              } else {
                if (entity.length > 0) {
                  this.selectedScan['scan_entities'] = [entity.trim()]
                  this.newEntities = [entity.trim()]
                }
              }
            } else {
              this.showThis = 'invalidTargets'
              this.invalidTargets.push(entity.trim())
            }
          }
          this.domainText = null;
          return true;
        }
      })
      this.newAssessment.emit(this.newEntities)
    }
  }
  showEntityTab(entityType) {
    let targetTypeFound = false
    let e
    if (this.selectedScan && this.selectedScan['scan_entities']) {
      for (e of this.selectedScan['scan_entities']) {
        if (entityType == this.assetTypePipe.transform(e)) {
          targetTypeFound = true
          this.showThis = entityType
          break;
        }
      };
      return targetTypeFound
    } else {
      return false
    }
  }
  maxTragetCheck(entities) {
    if (entities.length < this.maxEntities) {
      this.targetLimit = false
      return true
    } else {
      this.targetLimit = true
      return false
    }
  }
  getCount(type) {
    let aLength = 0;
    let assetT
    if (this.selectedScan['scan_entities']) {
      this.selectedScan['scan_entities'].forEach(e => {
        assetT = this.assetTypePipe.transform(e);
        if (assetT === type) {
          aLength++;
        }
      });
    }
    return aLength;
  }
  setTargetType(value) {
    this.restrictedTargets = []
    this.restrictedTargetsRaw = []
    if (value) {
      value.forEach(element => {
        if (this.commonService.targetMaping[element]) {
          this.restrictedTargets.push(this.commonService.targetMaping[element])
        }
        else {
          this.restrictedTargets.push(element)
        }
        if(element == 'ip_address'){
          this.restrictedTargetsRaw.push('ip')
        }
        if (element == 'application') {
          this.restrictedTargetsRaw.push('url')
        }
        this.restrictedTargetsRaw.push(element)
      });
    }
  }
  targetType(target) {
    const entity:String = this.assetTypePipe.transform(target,true)
    return this.commonService.returnInitials(entity);
  }
  targetTypeHoverText(target) {
    const entity:String = this.assetTypePipe.transform(target,true)
    return this.commonService.returnHoverText(entity);
  }
  openEntitiesModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal-medium' });
  }

  consumeMainDomain(){
    if(this.selectedRunbook?.consumes.length ==1 && this.selectedRunbook?.consumes[0] == "primary_domain"){
      this.newAssessment.emit([this.primaryDomain])
      return true;
    }else{
      return false;
    }
  }
  consumeIpPort(){
    return this.selectedRunbook?.consumes.length >= 1 && this.selectedRunbook?.consumes.includes("ip_ports")
  }
}
