import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn:"root"
})
export class AsyncToasterTriggerService {
	public _subject = new BehaviorSubject<any>('');
	emit(data){
		this._subject.next(data);
		this._subject.next('');
	}
	on(){
		return this._subject.asObservable();
	}
}