import { Component, OnInit, TemplateRef } from "@angular/core";
import { PortfolioSurveyService } from "../../_services/portfolio-survey.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CommonService } from "../../_services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ReportService } from "../../_services/report.service";
import { JobService } from "../../_services/job.service";
@Component({
  selector: "app-schedule-report",
  templateUrl: "./schedule-report.component.html",
  styleUrls: ["./schedule-report.component.scss"],
})
export class ScheduleReportComponent implements OnInit {
  public scheduleDatal;
  public page = 1;
  public limit = 10;
  public totalItems = 0;
  public validationCheck = false;
  public generateNowDuration;
  public todaysDate = new Date().toISOString().split("T")[0];
  public showSpinner = false;
  public confirmStatusReport;
  public delReport;
  public openDropdown = false;
  public sectionsSelected = 0;
  public creatingReport = false
  public dayDif;
  public maxDuration = -1;
  public showValidatedVulnerebility = false;
  public selectedReportType = '';

  public createNew = {
    name: null,
    job_type: "report_generation",
    schedule: {
      crontab: {},
      one_off: true,
    },
    job_config: {
      template: {},
      frequency: null,
      duration: {
        fromDate: null,
        toDate: null,
      },
      primary_domain: null,
      asset_insight_risk : true,
      only_validated:false
    },
  };
  public templates;
  public reports;
  public monthDay;
  public timeForScheduler = null;
  public editReportFields = false;
  public editIndex;
  public domain = null;
  public inscopeConfig = localStorage.getItem('scope_config');
    constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public commonService: CommonService,
    public route: ActivatedRoute,
    public router: Router,
    public reportService : ReportService,
    public jobService : JobService
  ) {
    this.inscopeConfig = localStorage.getItem('scope_config');
   }
  ngOnInit(): void {
    this.getAllScheduledReports();
    this.getAllTemplatesFromScheduler();
    Array.from({ length: 10 }, (_, i) => i + 1);
    this.monthDay = Array.from({ length: 31 }, (_, i) => i + 1);
  }
  getAllTemplatesFromScheduler() {
    this.reportService.getTemplateForSchedulingReports().subscribe(
      (data) => {
        this.templates = data["value"];
      },
      (error) => { }
    );
  }
  modalOpenForAdd(template: TemplateRef<any>, update_data) {
    this.inscopeConfig = localStorage.getItem('scope_config');
    this.showValidatedVulnerebility = false;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "max-width-dialog-modal",
    });
  }
  getAllScheduledReports() {
    this.showSpinner = true;
    this.jobService.getAllSchedulingReports(this.page).subscribe(
      (data) => {
        if (data) {
          this.showSpinner = false;
          this.totalItems = data["count"];
          this.reports = data["results"];
        }
      },
      (error) => {
        this.showSpinner = false;
        alert("Something went wrong! Please try again.")
        console.log(error);
      }
    );
  }
  switchDuration() {
    if (this.createNew.schedule.one_off) {
      this.resetCronTab();
    } else {
      this.resetDuration();
    }
  }
  resetCronTab() {
    delete this.createNew.schedule.crontab['minute']
    delete this.createNew.schedule.crontab['hour']
    delete this.createNew.schedule.crontab['day_of_week']
    delete this.createNew.schedule.crontab['day_of_month']
    delete this.createNew.schedule.crontab['month_of_year']
  }
  resetDuration() {
    this.createNew.schedule.crontab = {
      minute: "*",
      hour: "*",
      day_of_week: "*",
      day_of_month: "*",
      month_of_year: "*",
    };
    this.createNew.job_config.frequency = null;
    this.createNew.job_config.duration = {
      fromDate: null,
      toDate: null,
    };
    this.generateNowDuration = null;
  }
  validateReport() {
    // this.createNew.job_config.template = this.templates.find(t => this.createNew.job_config.template == t.key)
    if (!this.createNew.name || this.createNew?.name?.length < 4 || !this.createNew.job_config.template) {
      this.validationCheck = true;
    }
    if (!this.createNew.job_config['report_format'] || !this.createNew?.job_config?.['report_format'].length) {
      this.validationCheck = true;
    }
    if (
      this.createNew.job_config.template &&
      this.createNew.job_config.template["sections"] && 
      this.selectedReportType !== 'Risk'
    ) {
      if (this.sectionsSelected <= 0) {
        this.validationCheck = true;
      }
    }
    if (
      this.createNew.schedule.one_off &&
      !(
        this.createNew.job_config.duration.fromDate &&
        this.createNew.job_config.duration.toDate
      ) &&
      this.generateNowDuration != "all"
    ) {
      this.validationCheck = true;
    } else if (!this.createNew.schedule.one_off) {
      if (!this.createNew.job_config.frequency) {
        this.validationCheck = true;
      } else {
        if (
          this.createNew.job_config.frequency == 1 &&
          !this.timeForScheduler
        ) {
          this.validationCheck = true;
        } else if (
          this.createNew.job_config.frequency == 7 &&
          !(
            this.createNew.schedule.crontab['day_of_week'] && this.timeForScheduler
          )
        ) {
          this.validationCheck = true;
        } else if (
          this.createNew.job_config.frequency == 30 &&
          !(
            this.createNew.schedule.crontab['day_of_month'] &&
            this.timeForScheduler
          )
        ) {
          this.validationCheck = true;
        }
      }
    }
    return this.validationCheck;
  }
  dayDiff(){
    this.createNew.job_config.duration.fromDate = this.createNew.job_config.duration.fromDate? 
    new Date(this.createNew.job_config.duration.fromDate): null;
    this.createNew.job_config.duration.toDate = this.createNew.job_config.duration.toDate? 
    new Date(this.createNew.job_config.duration.toDate): null;
    this.dayDif = (this.createNew.job_config.duration.toDate -this.createNew.job_config.duration.fromDate)/86400000
    if(this.createNew.job_config.duration.fromDate){
      this.createNew.job_config.duration.fromDate = this.createNew.job_config.duration.fromDate.toISOString().split("T")[0]
    }
    if(this.createNew.job_config.duration.toDate){
      this.createNew.job_config.duration.toDate = this.createNew.job_config.duration.toDate.toISOString().split("T")[0]
    }
  }
  createNewReport() {
    console.log('11')
    if (this.commonService.reportDownloadState()) {
      console.log('22')
      this.creatingReport = true
      const nowDateTime = new Date();
      console.log("---1--",this.createNew.job_config);
      const eventRisk = (this.selectedReportType==="Risk")?{event_risk:true}:{};
      this.createNew.job_config.template = {...this.createNew.job_config.template, ...eventRisk};
      console.log("---2--",this.createNew.job_config);
      if (
        this.generateNowDuration == "1" ||
        this.generateNowDuration == "7" ||
        this.generateNowDuration == "30"
      ) {
        this.createNew.job_config.duration.toDate = nowDateTime
        const fromDate = new Date(
          new Date().setDate(new Date().getDate() - this.generateNowDuration)
        );
        this.createNew.job_config.duration.fromDate = fromDate
      }
      else if (this.generateNowDuration == 'custom') {
        this.createNew.job_config.duration.fromDate = new Date(this.createNew.job_config.duration.fromDate)
        this.createNew.job_config.duration.toDate = new Date(this.createNew.job_config.duration.toDate)
      }
      if (this.generateNowDuration == "all") {
        this.createNew.job_config.duration.toDate = null;
        this.createNew.job_config.duration.fromDate = null;
      }
      this.validationCheck = false;
      if (!this.createNew.schedule.one_off) {
        if (this.timeForScheduler) {
          let arr = this.timeForScheduler.split(":");
          nowDateTime.setHours(arr[0]);
          nowDateTime.setMinutes(arr[1]);
          this.createNew.schedule.crontab['hour'] = nowDateTime.getUTCHours() + "";
          this.createNew.schedule.crontab['minute'] =
            nowDateTime.getUTCMinutes() + "";
        }
      } else {
        this.createNew.job_config.duration.fromDate = this.createNew.job_config
          .duration.fromDate
          ? this.createNew.job_config.duration.fromDate.toISOString()
          : null;
        this.createNew.job_config.duration.toDate = this.createNew.job_config
          .duration.toDate
          ? this.createNew.job_config.duration.toDate.toISOString()
          : null;
      }
      if (!this.validateReport()) {
        this.jobService
          .createNewReoprtSchedule(this.createNew)
          .subscribe(
            (data) => {
              this.creatingReport = false;
              this.getAllScheduledReports();
              this.sectionsSelected = 0;
              this.createNew = {
                name: null,
                job_type: "report_generation",
                schedule: {
                  crontab: {
                  },
                  one_off: true,
                },
                job_config: {
                  template: {},
                  frequency: null,
                  duration: {
                    fromDate: null,
                    toDate: null,
                  },
                  primary_domain: null,
                  asset_insight_risk: true,
                  only_validated:false
                },
              };
              this.generateNowDuration = null;
              this.modalRef.hide();
            },
            (error) => {
              console.log(error);
              alert("Something went wrong! Please try again.")
              this.createNew = {
                name: null,
                job_type: "report_generation",
                schedule: {
                  crontab: {
                  },
                  one_off: true,
                },
                job_config: {
                  template: {},
                  frequency: null,
                  duration: {
                    fromDate: null,
                    toDate: null,
                  },
                  primary_domain: null,
                  asset_insight_risk: true,
                  only_validated:false
                },
              };
              this.generateNowDuration = null;
              this.modalRef.hide();
              this.validationCheck = true;
              this.creatingReport = false
            }
          );
      } else {
        this.creatingReport = false
      }
    } else {
      alert("Reports download is disabled for this account. Please write to us at contact@firecompass.com")
      this.hideModel()
    }
  }
  goToPage(val) {
    this.page = val;
    this.getAllScheduledReports();
  }
  changeStatus(report) {
    if (this.commonService.reportDownloadState()) {

      report.schedule.enabled = !report.schedule.enabled;
      this.jobService
        .updateScheduleReport(report.id, report)
        .subscribe((data) => {
          this.modalRef.hide();
        });
    } else {
      alert("Reports download is disabled for this account. Please write to us at contact@firecompass.com")
      this.hideModel()
    }
  }
  editReport(report) {
    this.jobService
      .updateScheduleReport(report.id, { name: report.name })
      .subscribe((data) => { });
  }
  hideModel() {
    this.modalRef.hide();
    this.validationCheck = false;
    this.sectionsSelected = 0;
    this.createNew = {
      name: null,
      job_type: "report_generation",
      schedule: {
        crontab: {
        },
        one_off: true,
      },
      job_config: {
        template: {},
        frequency: null,
        duration: {
          fromDate: null,
          toDate: null,
        },
        primary_domain: null,
        asset_insight_risk: true,
        only_validated:false
      },
    };
  }
  modalOpenForStatusConfirmation(template: TemplateRef<any>, status) {
    this.confirmStatusReport = status;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "max-width-dialog-modal",
    });
  }
  hideModelStatus() {
    this.modalRef.hide();
  }
  delReportModel(template: TemplateRef<any>, report) {
    this.delReport = report;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  delData() {
    if (this.commonService.reportDownloadState()) {
      this.jobService
        .updateScheduleReport(this.delReport.id, { archive: true })
        .subscribe((data: any) => { });
      this.modalRef.hide();
    } else {
      alert("Reports download is disabled for this account. Please write to us at contact@firecompass.com")
      this.hideModel()
  }
}
  cancelDelete() {
    this.delReport = {};
    this.modalRef.hide();
  }
  changeSelected(section) {
    section["selected"] = !section["selected"];
    if (section["selected"]) this.sectionsSelected++;
    else this.sectionsSelected--;
  }
  updateTemplate(event) {
    this.createNew.job_config.template = this.templates.find(
      (temp) => temp.name == event.target.value
    );
    if (
      this.createNew.job_config.template &&
      this.createNew.job_config.template["sections"] &&
      this.createNew.job_config.template["sections"].length
    ) {
      this.createNew.job_config.template["sections"].forEach((ele) => {
        ele.selected = false;
      });
      this.sectionsSelected = 0;
    }
    if (this.createNew.job_config.template['allowed_formats'].length == 1) {
      this.createNew.job_config['report_format'] = this.createNew.job_config.template['allowed_formats'][0]
    }
    if (this.checkAssetInventoryInsight()) {
      this.maxDuration = 44;
    } else this.maxDuration = -1;
    if(event.target.value=='Risk'){
      this.showValidatedVulnerebility = true;
      this.createNew.job_config['only_validated'] = false;
    }else{
      this.showValidatedVulnerebility = false;
      if('only_validated' in this.createNew.job_config) delete this.createNew.job_config.only_validated;
    }
    if(this.createNew.job_config.template['key'] == 'event_report' || this.createNew.job_config.template['key'] == 'active_assessment_report'){
      this.createNew.job_type = "report_generation_v2"
    }
    else{
      this.createNew.job_type = "report_generation"
    }
    console.log(this.createNew.job_config);
  }
  showDuration(duration) {
    if (duration == 1) return "Daily";
    if (duration == 7) return "Weekly";
    if (duration == 30) return "Monthly";
  }
  getTimeForReports(dateTime) {
    dateTime = dateTime.split('T')[1]
    if (dateTime) {
      dateTime = dateTime.split(':')
      if (dateTime)
        return dateTime[0] + ":" + dateTime[1]
    }
    // return dateTime.getHours() + ":" + dateTime.getMinutes()
  }
  showOption() {
    let scheduleInfo = this.createNew.job_config?.template['date_config']?.schedule
    let showSchedule = false
    if (scheduleInfo) {
      for (const key of Object.keys(scheduleInfo)) {
        if (scheduleInfo[key]) {
          showSchedule = true
        }
      }
    }
    return showSchedule
  }
  updateJobScope(scope) {
    if (scope == 'null') {
     delete this.createNew.job_config['scope']
    } else {
      this.createNew.job_config['scope']=scope;
    }
  }

  checkAssetInventoryInsight(){
    return this.createNew.job_config.template['key'] == 'asset_inventory_insight'
  }

  updateAssetRiskInsight(){
    if (this.checkAssetInventoryInsight()){
      this.createNew.job_config.asset_insight_risk = !this.createNew.job_config.asset_insight_risk
    }
  }
}
