import { Component, OnInit ,Input ,TemplateRef} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from 'app/_services';

@Component({
  selector: 'app-common-observation-insight',
  templateUrl: './common-observation-insight.component.html',
  styleUrls: ['./common-observation-insight.component.scss']
})
export class CommonObservationInsightComponent implements OnInit {

  @Input() subject: any;
  @Input() ser: any;
  @Input() flag: any;
  @Input() mainDomain;
  @Input() subjectTypeName;
  public subjectObsIns;

  constructor(
    public modalReff: BsModalRef,
    public modalService: BsModalService,
    public portfolioSurveyService: PortfolioSurveyService,
  ) { }

  ngOnInit() {
  }
  openObsInsModule(template: TemplateRef<any>) {
    this.subjectObsIns = this.subjectTypeName
    this.modalReff = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
}
