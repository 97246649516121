import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortfolioSurveyService } from 'app/_services';
import { CommonService } from 'app/_services/common.service';
import { rejects } from 'assert';
import { resolve } from 'dns';

@Component({
  selector: 'app-dashboard-v3',
  templateUrl: './dashboard-v3.component.html',
  styleUrls: ['./dashboard-v3.component.scss']
})
export class DashboardV3Component implements OnInit {
  public topRisk:any;
  public domain:string;
  public counts = {
    'exposedservice':null,
    'web_app_vulnerability':null,
    'exposeddatabase':null,
    'leakcred':null,
    'takeoverrisk':null,
    'prepodservice':null,
    'network_vulnerability':null
  };
  public ipDetails: boolean = false;
  public selectedEntity:string = 'top_risk_insights';
  public getDataMap = {
    loading:false,
    exposed_databases:{
      key:'exposedDatabase',
      data:[]
    },
    exposedservice:{
      key:'exposedServices',
      data:[]
    },
    preprodServices:{
      key:'preprodServices',
      data:[]
    },
    takeoverRisk:{
      key:'takeoverRisk',
      data:[]
    }
  }
  public noDataFoundTitle = {
    "takeoverRisk":"takeover risks",
    "preprodServices":"preprod services",
    "exposedservice":"exposed services",
    "exposed_databases":"exposed databases"
  }
  public exposedServiceToshow:any = [];
  public exposedDatabaseToshow:any = [];
  public preprodServicesToshow:any = [];
  public inscopeConfig:any = false; 
  public inscopeFlag:any;
  public openDropdown:boolean = false;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    (async ()=>{
      this.getScope(); 
      await this.getDomainName();
      if (this.domain){
        this.getTopRisk();
        this.getAllDataByEntity();
      }
    })();
  }
  getScope(){
    let that = this;
    setTimeout(()=>{
      that.inscopeConfig = localStorage.getItem('scope_config');
    },800)
  }

  public getDomainName() {
    return new Promise((resolve, rejects) => {
      this.route.queryParams.subscribe((params) => {
        if (params && params["domain"]) {
          this.domain = params["domain"];
          resolve('');
          this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { domain: this.domain },
            queryParamsHandling: "merge",
            skipLocationChange: true,
          });
        } else {
          this.portfolioSurveyService
            .getDomainsSearchForUser()
            .subscribe((data: any) => {
              data.filter((d) => {
                if (d["domain_type"] == "self") {
                  this.domain = d["domain"];
                  resolve('');
                }
              });
            });
        }
      });
    });
  }

  public getTopRisk() {
    let scope = this.inscopeFlag;
    this.portfolioSurveyService
      .getTopRisk(this.domain,scope)
      .subscribe((data: any) => {
        this.topRisk = data.data;
      });
  }
  public countEmitor($event){
    if($event && $event.key){
      this.counts[$event.key] = $event.count;
    }
  }
  getAllDataByEntity() {
    let entities = [
      'exposed_databases',
      'exposedservice',
      'preprodServices'
    ];
    entities.map(x => this.getDataByKey(x));
  }
  // setEntityType(entity) {
  //   this.ipDetails = false;
  //   this.selectedEntity = entity;
  //   if(
  //     this.selectedEntity!="top_risk_insights" && 
  //     this.selectedEntity!="network_vulnerability" &&
  //     this.selectedEntity!="web_app_vulnerability"
  //   ){
  //     this.getDataByKey(this.selectedEntity);
  //   }
  // }
  setEntityType(entity) {
    this.ipDetails = false;
    var element:any = document.getElementById(entity);
    var headerOffset = 60;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }
  getDataByKey(key){
    let obj = this.getDataMap[key];
    this.getDataMap.loading = true;
    this.portfolioSurveyService
    .getRiskDetails(this.domain, obj.key, {scope:this.inscopeFlag})
    .subscribe((data: any) => {
      console.log(data);
      if (data["status"] == "pass") {
        obj.data = data["data"]
      } else {
        obj.data = [];
      }
      if(key=='exposed_databases' && this.exposedDatabaseToshow.length<1){
        let slicedData = obj.data.slice(0,10);
        this.exposedDatabaseToshow = slicedData;
      }else if(key=='exposedservice' && this.exposedServiceToshow.length<1){
        let slicedData = obj.data.slice(0,10);
        this.exposedServiceToshow = slicedData;
      }else if(key=='preprodServices' && this.preprodServicesToshow.length<1){
        let slicedData = obj.data.slice(0,10);
        this.preprodServicesToshow = slicedData;
      }
      this.getDataMap.loading = false;
    }, (error) => {
      this.getDataMap.loading = false;
      obj.data = [];
    });
  }
  // showIpDetails(ip) {
  //   this.ipDetails = false;
  //   this.portfolioSurveyService
  //     .getIpDetails(ip, this.domain)
  //     .subscribe((data: any) => {
  //       this.getIpdetails["subjects"] = [];
  //       // this.getIpdetails['ip'] = ""
  //       this.getIpdetails["ip"] = ip;
  //       data.data = data.data.filter((d) => d.host != null);
  //       if (data.data.length == 0) {
  //         this.getIpdetails["subjects"].push({
  //           name: "NA",
  //           discovered_at: "NA",
  //         });
  //       } else {
  //         this.getIpdetails["subjects"] = [];
  //       }
  //       data.data.forEach((ele) => {
  //         if (ele.host) {
  //           this.getIpdetails["subjects"].push({
  //             name: ele.host ? ele.host : "NA",
  //             created_at: ele.created_at,
  //             updated_at: ele.updated_at,
  //             discovered_at: ele.discovered_at ? ele.discovered_at : "NA",
  //           });
  //         }
  //       });

  //       this.getIpdetails["subjects"].forEach((ele) => {
  //         if (ele.name != null) this.ipDetails = true;
  //       });
  //     });
  // }
  filterByInscope(scope){
    this.inscopeFlag = scope;
  }
}
