import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'simple-layout.component.html'
})
export class SimpleLayout implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
