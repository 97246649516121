import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import jwt_decode from "jwt-decode";
import { AuthService } from "../../views/pages/auth.service";
@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.scss"],
})
export class CustomersComponent implements OnDestroy, OnInit {
  data;
  getToken;
  customerList: any = [];
  constructor(
    public router: Router,
    private firestore: AngularFirestore,
    public aff: AngularFireFunctions,
    public auth: AngularFireAuth,
    private ngxService: NgxUiLoaderService,
    public authSVC: AuthService
  ) {
    console.log("Inside Constructor");
  }

  getAllCustomer(domain = null) {
    console.log(domain);
    this.data = this.firestore
      .collection("/metadata/entities/customers/", (ref) => {
        if (domain) {
          return ref
            .where("account_type", "==", "customer")
            .where("primary_domain", "==", domain)
            .orderBy("created_at", "desc");
        } else {
          return ref
            .where("account_type", "==", "customer")
            .orderBy("created_at", "desc");
        }
      })
      .get()
      .subscribe((data) => {
        this.customerList = [];
        data.forEach((element: any) => {
          console.log(element.data());
          this.customerList.push({ ...element.data(), id: element.id });
        });
      });
  }

  ngOnInit(): void {
    console.log("Inside Onnit");
    this.getAllCustomer();
  }

  search(domain) {
    this.getAllCustomer(domain);
  }

  customerLogin(cID, accountType, scope, domain, paidFlag, downloadReports) {
    console.log(cID);
    const profile = JSON.parse(localStorage.getItem("profile"));
    this.ngxService.start();
    const callable = this.aff.httpsCallable("updateClaims");
    const updateClaims = callable({
      user_id: profile.user_id,
      customerID: cID,
      account_type: accountType,
      scope: scope,
      primary_domain: domain,
      custPaidFlag: paidFlag,
      custReport: downloadReports,
    });
    updateClaims.subscribe((res) => {
      console.log(res);
      if (res == "You are not permitted to perform this action.") {
        this.ngxService.stop();
        alert("You are not permitted to perform this action");
      } else {
        this.authSVC.getLastestToken();
        setTimeout(() => {
          this.ngxService.stop();
          localStorage.removeItem("mydomain");
          this.router.navigate(["/new/self-domains"]);
        }, 3000);
      }
    });
  }

  ngOnDestroy() {
    // this.auth.authState;
    // this.auth.
  }
}
