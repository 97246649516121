import { Component, OnInit , Input} from '@angular/core';
import {Router, Route, ActivatedRoute} from "@angular/router";
// import { ModalDirective } from 'ngx-bootstrap/modal/modal.component';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';


@Component({
  selector: 'app-vendor-question',
  templateUrl: './vendor-question.component.html',
  styleUrls: ['./vendor-question.component.scss']
})
export class VendorQuestionComponent implements OnInit {

  @Input() questions:any;
  @Input() type:string;
  @Input() hideCheckBox;
  @Input() sections;
  public uoadteData:boolean=false;
  constructor(
    private portfolioSurveyService: PortfolioSurveyService,
    
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  ngOnInit() {
  }

  checkQuestionType(queType){
    if(this.type != 'custom_question') {
      if(queType == this.type) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  doCheck(event,ind) {
    this.questions[ind]['checked'] = event.target.checked;
  }
  checkAllQuestion(i) {
    this.questions[i].checked = true;
    return true;
  }
  archiveCustomQuestion(ind){
    this.questions[ind]['archived'] = true;
    this.questions.splice(ind,1)

  }
}
