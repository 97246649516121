import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-show-leaked-cred',
  templateUrl: './show-leaked-cred.component.html',
  styleUrls: ['./show-leaked-cred.component.scss']
})
export class ShowLeakedCredComponent implements OnInit {

  // @Input() showExecutiveReport;
  // @Input() mainDomain;
  @Input() breachedCredentials;

  public seeMore;
  constructor() { }

  ngOnInit() {
  }

}
