import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortfolioSurveyService } from './../../_services';
import { AssetTypePipe } from 'app/pipes/asset-type.pipe';
import { Router } from '@angular/router';

@Component({
	selector: 'app-new-domain-discovery',
	templateUrl: './new-domain-discovery.component.html',
	styleUrls: ['./new-domain-discovery.component.scss']
})
export class NewDomainDiscoveryComponent implements OnInit {


	public showThis = 'activity';
	public showEntity = 'domain'
	public showSpinner: boolean = false;
	// public mainDomain: string = '';
	public relatedDomains: any = [];
	public emails: any = [];
	public organisations: any = [];
	public keywords: any = [];
	mainDomainData: any;
	public addNewEmail: boolean = false
	public addNewOrg: boolean = false
	public addNewKey: boolean = false
	public keyword: string = ''
	public email: string = ''
	public organisation: string = ''
	public showSpinnerToGtDomain: boolean = false;
	public emailDomains
	public orgDomains
	public keywordDomains;
	public emailCheck: boolean = false;
	public orgCheck: boolean = false;
	public keywordCheck: boolean = false;
	public bulkCheck: boolean = false;
	public checkChildComponent = false;
	@Input() mainDomain: string = '';
	@Output() returnSelectedDomains: EventEmitter<string[]> = new EventEmitter<string[]>();
	public showMessage: string;
	public selectedDomainsNames: any = []
	public scanStarted = false;
	public assetTypePipe = new AssetTypePipe();



	constructor(
		public portfolioSurveyService: PortfolioSurveyService,
		private router: Router,
	) { }

	ngOnInit() {
		if (this.mainDomain && this.mainDomain.length) {
			this.checkChildComponent = true;
		}
	}

	getDataForDomain(doWhoxy = false) {
		this.checkChildComponent = true;
		this.showSpinner = true
		if (this.mainDomain) {
			this.portfolioSurveyService.getEmailAndDomainsAndKeywordForDomain(this.mainDomain, doWhoxy).subscribe((data) => {
				if (data['status'] == "pass") {
					this.showSpinner = false
					this.mainDomainData = data;
					this.emails = data['data']['emails'];
					this.organisations = data['data']['organisations'];
					this.keywords = data['data']['keywords'];
					if (data['whoisData'] && data['whoisData']['status'] == 0) {
						this.showMessage = "Whoxy call failed with reason: " + data['whoisData']['status_reason']
					}
					if (data['whoisHistory'] && data['whoisHistory']['status'] == 0) {
						this.showMessage = "Whoxy call failed with reason: " + data['whoisData']['status_reason']
					}
					// this.getRelatedDomains();
				} else {
					this.showSpinner = false;
					alert('error for getting domain details')
				}
			}, error => {
				this.showSpinner = false;
				alert('error for getting domain details');
			})
		} else {
			this.showSpinner = false;
			alert('Please enter domain');
		}
	}

	addNewData(value, entity) {
		if (value == 'email') {
			this.email = null
			this.emails.push({ "email": entity, "added": true, "false_positive": false, "archive": false });
			this.updateEmailOrgForActivity();

		} else if (value == 'organisation') {
			this.organisation = null
			this.organisations.push({ "organisation": entity, "added": true, "false_positive": false, "archive": false });

		} else if (value == 'keyword') {

			this.keyword = null
			this.keywords.push({ "keyword": entity, "added": true, "false_positive": false, "archive": false });
		}
	}

	getRelatedDomains() {
		this.portfolioSurveyService.getRelatedDomainsForDomain(this.mainDomain).subscribe((data) => {
			if (data['status'] == "pass") {
				this.relatedDomains = data['data'];
				// this.emailDomains = data['emailDomains'] ? data['emailDomains'] : [];
				// this.orgDomains = data['orgDomains'] ? data['orgDomains'] : [];
				// this.keywordDomains = data['keywordDomains'] ? data['keywordDomains'] : [];
			} else {
				alert("error while getting related domain")
			}
		}, error => {
			alert("error while getting related domain")
		})
	}

	calculateRelatedDomains(discoverMore = false) {
		this.showSpinnerToGtDomain = true;
		this.portfolioSurveyService.getRelatedDomainsForEmailDomainsKeyword(this.mainDomainData['data']).subscribe(data => {
			if (data['status'] == "pass") {
				this.showSpinnerToGtDomain = false
				if (!discoverMore)
					this.showThis = 'related_domain';
				this.getDataForDomain();
				this.relatedDomains = data['data']['data'];
				// alert("Successfully done");
				this.emailDomains = data['data']['emailDomains'] ? data['data']['emailDomains'] : []
				this.orgDomains = data['data']['orgDomains'] ? data['data']['orgDomains'] : []
				this.keywordDomains = data['data']['keywordDomains'] ? data['data']['keywordDomains'] : []
				if (data['emailFromWhoxy'] && data['emailFromWhoxy']['status'] === 0) {
					this.showMessage = "Reverse whoxy call failed with reason: " + data['emailFromWhoxy']['status_reason']
				}
			} else {
				this.showSpinnerToGtDomain = false
				alert("some error");
			}
		}, error => {
			this.showSpinnerToGtDomain = false
			alert("show error");
		})
	}
	SelectAllDomain(type) {
		if ((type === 'all' || type === 'email') && this.emailDomains && this.emailDomains.length) {
			this.emailDomains.forEach(related => {
				related['selected'] = !related['selected']
			})
		}
		if ((type === 'all' || type === 'org') && this.orgDomains && this.orgDomains.length) {
			this.orgDomains.forEach(related => {
				related['selected'] = !related['selected']
			})
		}
		if ((type === 'all' || type === 'keyword') && this.keywordDomains && this.keywordDomains.length) {
			this.keywordDomains.forEach(related => {
				related['selected'] = !related['selected']
			})
		}
		if (type === 'all' && this.relatedDomains && this.relatedDomains.length) {
			this.relatedDomains.forEach(related => {
				related['selected'] = !related['selected']
			})
		}
		if (type === 'all') {
			this.emailCheck = this.bulkCheck;
			this.orgCheck = this.bulkCheck;
			this.keywordCheck = this.bulkCheck;
		}
	}
	updateRelatedDomain() {
		this.portfolioSurveyService.updateRelatedDomains(this.relatedDomains).subscribe((data) => {
			if (data['status'] == "pass") {
				alert("Successfully updated related domain");
			} else {
				alert("error updated related domain");
			}
		}, error => {
			alert("error updated related domain");
		})
	}

	updateEmailOrgForActivity() {
		this.portfolioSurveyService.updateEmailOrgForActivity(this.mainDomainData['data']).subscribe((data) => {
			if (data['status'] == "pass") {
				alert("Successfully updated email or organisation or keyword");
			} else {
				alert("error while updating email or organisation or keyword");
			}
		}, error => {
			alert("error while updating email or organisation or keyword");
		})
	}

	saveRelatedDomains() {
		let dataToSave = {
			"main_domain": this.mainDomain,
			"hosts": [],
			"domains": this.relatedDomains
		}
		this.portfolioSurveyService.postAllShadowDetails(dataToSave, false, false).subscribe(data => {
			if (data && data['status'] == 'pass') {
				alert("successfully uploaded file");
			} else {
				alert("error while uploading file");
			}
		}, error => {
			alert("error while uploading file");
		});
	}
	getSelectedDomains() {
		let selectedDomains = [];
		const selectedOrgDomains = this.orgDomains.filter(a => { return a['selected'] })
		const selectedEmailDomains = this.emailDomains.filter(a => { return a['selected'] })
		const selectedKeywordDomains = this.keywordDomains.filter(a => { return a['selected'] })
		selectedDomains = selectedDomains.concat(selectedEmailDomains, selectedKeywordDomains, selectedOrgDomains)
		this.selectedDomainsNames = selectedDomains.map(a => { return a['domain'] })
		this.showThis = 'add-manually'
		if (this.selectedDomainsNames && this.selectedDomainsNames.length) {
			// this.returnSelectedDomains.emit(this.selectedDomainsNames)
		} else {
			alert("No Domains Selected.Now you can add manually!")
		}
	}
	cancle() {
		this.returnSelectedDomains.emit([])
	}
	addToTheList(values, newDomains = null) {
		newDomains = newDomains ? newDomains : values.split(/[\n, ]/);
		const done = newDomains.filter((x, i) => {
			const domain = x.trim();
			if (this.selectedDomainsNames.indexOf(x) == -1) {
				this.selectedDomainsNames.push(domain)
			}
		})
		// this.showThis = 'final-list'
	}
	removeEntity(entity) {
		this.selectedDomainsNames = this.selectedDomainsNames.filter(a => a !== entity);
		// this.newEntities = this.newEntities.filter(a => a !== entity)
	}
	removeAllEntity() {
		this.selectedDomainsNames = []
		// this.newEntities = [];
	}
	runDeepcScan() {
		if (this.selectedDomainsNames.length) {
			this.scanStarted = true;
			const dataToSend = {
				'main_domain': this.mainDomain,
				'scan_entities': this.selectedDomainsNames,
				'scan_depth': 1000,
				'mode': 'Automated',
				'scan_type': 'deepc_scan',
			    'red_teaming_id': null
			}
			this.portfolioSurveyService.runDeepcScan(dataToSend).subscribe(data => {
				this.scanStarted = false;
				if (data && data['status'] === 'pass') {
					this.selectedDomainsNames = [];
					alert('Scan Queued successfully!')
					this.router.navigate(['/new/red-teaming']);
				}
			}, error => {
				this.scanStarted = false;
				alert('Error While Queuing Scan')
			})
		} else {
			this.scanStarted = false;
			alert('No Change In Scope.')
		}
	}
	getCount(type) {
		let aLength = 0;
		if (this.selectedDomainsNames) {
			this.selectedDomainsNames.forEach(e => {
				let assetT = this.assetTypePipe.transform(e);
				if (assetT === type) {
					aLength++;
				}
			});
		}
		return aLength;
	}
}
