
import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import * as cytoscape from 'cytoscape';
// tooltip
import * as Tippy from 'tippy.js';
// // import * as qtip from 'cytoscape-qtip';
import * as popper from 'cytoscape-popper';
// import klay from ;

// // layout
// // import * as cola from 'cytoscape-cola';
// // import * as spread from 'cytoscape-spread';
import * as coseBilkent from 'cytoscape-cose-bilkent';
// // import * as euler from 'cytoscape-euler';
// // import * as cyspringy from 'cytoscape-springy';
// // import * as cyforcelayout from 'cytoscape-ngraph.forcelayout';
import * as klay from 'cytoscape-klay';
import * as panzoom from 'cytoscape-panzoom';
import { CommonService } from '../../_services/common.service';
import { legendDetail } from '../../_services/common-var.service';

// import * as data from './json_cytoscape.json';
// import * as data from './kg-cyto.json';
//  import * as data from './abc-cyto.json';

// extra we can use
// npm install cytoscape-markov-cluster

// extend the layout or tooltip
cytoscape.use(popper);
cytoscape.use(coseBilkent);
// spread(cytoscape); // register extension
// cytoscape.use(cola);
// cytoscape.use(qtip);
// cytoscape.use(euler);
// cyspringy(cytoscape);
cytoscape.use(klay);
// cyforcelayout(cytoscape); // register extension
panzoom(cytoscape);

@Component({
	selector: 'app-cytoscape-library',
	templateUrl: './cytoscape-library.component.html',
	styleUrls: ['./cytoscape-library.component.scss']
})
export class CytoscapeLibraryComponent implements OnInit {
	public domain: string = 'flipkart.com'
	public addNewJSON = true;
	domains: any;
	mainDomain: any;
	subDomainsCount: any;
	displayData: any;
	public newData;
	public data = {};
	public cy: any;
	public showItem = -1;
	public showSpinner = false;
	public showError = false;
	public showErrorText;
	@Input() showGraph: false
	@Input() entityDetails;
	@Input() graphType;
	legendDetail = legendDetail
	public showData: any
	public initialZoom = 1;
	public showThis = 'graph'
	constructor(
		public portfolioSurveyService: PortfolioSurveyService,
		public commonService: CommonService,
		public modalService: BsModalService,
		public modalRef: BsModalRef,
	) { }

	ngOnInit() {
		// this.getRelatedDomainsSubDomains();
		// this.renderGraph(data['data']);
		// this.renderData()
		// if (this.entityDetails) {
		// 	console.log(this.entityDetails)
		// 	this.portfolioSurveyService.getGraphForEntity(this.entityDetails).subscribe(data => {
		// 		console.log(data, "I am calling");
		// 		this.data = data;
		// 	})
		// 	// this.openModel('openGraphComponent')
		// }
		// if(data != null) {
		// 	this.formatDataAndGenerateGraph(data);			
		// }
	}

	getSubGraph() {
		this.portfolioSurveyService.getGraphForEntity(this.entityDetails).subscribe((data:any) => {
			this.showSpinner = false;
			if (data.status && data.status != '200') {
				this.showError = true;
				this.showErrorText = data['message']
			} else {
				this.showError = false;
				this.formatDataAndGenerateGraph(data);
			}
		}, error => {
			this.showSpinner = false;
			this.showError = true;
			console.log(error)
		})
	}

	getDiscoveryGraph() {
		this.portfolioSurveyService.getDiscoveryPathsForEntity(this.entityDetails).subscribe((data:any) => {
			this.showSpinner = false;
			if (data.status && data.status != '200') {
				this.showError = true;
				this.showErrorText = data['message']
			} else {
				this.showError = false;
				this.formatDataAndGenerateGraph(data);
			}
		}, error => {
			this.showSpinner = false;
			this.showError = true;
		})
	}
	getGraph(){
		if (this.graphType === 'discoveryPath') {
			this.getDiscoveryGraph();
		} else {
			this.getSubGraph();
		}
	}
	openModel(template: TemplateRef<any>) {
		this.showSpinner = true;
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
		this.getGraph()
	}

	formatDataAndGenerateGraph(dataToRender) {
		this.showData = {
			nodes: [],
			edges: []
		};
		dataToRender['nodes'].forEach((element, index) => {
			if (element && element['typeof']!='scan') {
				// if (element['typeof'] != 'error') {
				// if(this.entityDetails['entity_name'] === element['data'][0] && 
				// 	this.entityDetails['entity_type'] === element['typeof'][0]) {
				// 	element['typeof'][0] = 'source';
				// }
				this.showData.nodes.push({
					group: 'nodes',
					data: {
						element: element,
						weight: index,
						id: element['id'],
						type: element['typeof'],
						name: element['data'],
						backgroundColor: this.addColor(element['typeof'][0])
					},
					selected: (this.entityDetails['entity_name'] === element['data'][0] &&
						this.entityDetails['entity_type'] === element['typeof'][0]) ? true : false
				})
				// }
			}
		})
		dataToRender['edges'].forEach((element, index) => {
			if (element && !(element['label']=='has_target' || element['label']=='is_target_of')) {
				this.showData.edges.push({
					group: 'edges',
					data: {
						source: element["outV"],
						target: element["inV"],
						module: element['module_name'],
						label: element['label'],
						backgroundColor: 'yellow',
						value: 100,
					}
				})
			}
		})
		this.renderGraph(this.showData)
	}
	hideModel() {
		this.modalRef.hide()
	}

	uploadFile(event) {
		this.legendDetail.forEach(ele => {
			ele.show = false
		})
		this.displayData = null
		const uploadedFile = document.getElementById('uploadedFile') ? document.getElementById('uploadedFile')['files'][0] : null;
		if (this.domain.trim() !== "" && uploadedFile) {
			this.showSpinner = true;
			if (uploadedFile) {
				const reader:any = new FileReader();
				reader.readAsBinaryString(uploadedFile);
				reader.onload = () => {
					try {
						const result = JSON.parse(reader['result']);
						this.newData = result;
						this.renderData();
						this.showSpinner = false;
						// this.uploadJsonForReconAndDeepc(result, this.domain);
					} catch (e) {
						this.showSpinner = false;
						alert('Please enter valid json file');
					}
				};
			}
		} else {
			alert('Please select a file.');
		}
	}

	renderData() {
		const data = this.newData;
		this.showData = {
			nodes: [],
			edges: []
		};
		data['nodes'].forEach((element, index) => {
			if (element) {
				// if (element['typeof'] != 'error') {
				this.showData.nodes.push({
					group: 'nodes',
					data: {
						element: element,
						weight: index,
						id: element['__id'],
						type: element['typeof'],
						name: element['data'],
						backgroundColor: this.addColor(element['typeof'])
					}
				})
				// }
			}
		})
		data['edges'].forEach((element, index) => {
			if (element) {
				this.showData.edges.push({
					group: 'edges',
					data: {
						source: element["__src"],
						target: element["__dst"],
						module: element["module_name"],
						backgroundColor: 'yellow',
						value: 100,
					}
				})
			}
		})
		this.renderGraph(this.showData)
	}

	zoomLevel() {
		if (this.showData && this.showData['nodes'].length > 30) {
			this.initialZoom = 1;
		} else if (this.showData && this.showData['nodes'].length > 20) {
			this.initialZoom = 2;
		} else if (this.showData && this.showData['nodes'].length > 10) {
			this.initialZoom = 3;
		} else if (this.showData && this.showData['nodes'].length > 1) {
			this.initialZoom = 4;
		}
	}

	renderGraph(elementdata) {
		this.zoomLevel()
		let container = document.getElementById('cy');
		let layoutType = "cose-bilkent"
		if (this.graphType == 'discoveryPath') {
			layoutType = "klay"
		}
		this.cy = cytoscape(
			{
				container: container, // container to render in
				elements: elementdata,
				zoom: this.initialZoom,
				pan: { x: 300, y: 100 },
				minZoom: 0.1,
				maxZoom: 8.0,
				layout: { name: layoutType },
				style: [
					{
						selector: 'node',
						style: {
							height: 7,
							width: 7,
							// 'background-opacity': 1,
							'background-color': 'data(backgroundColor)',
							// 'color': 'black'
							'label': "data(name)",
							"text-valign": "bottom",
							"text-halign": "center",
							"text-max-width": "55px",
							"font-size": "3px",
							"text-wrap": 'ellipsis'
						},
					},

					{
						selector: 'node.mouseover',
						style: {
							// 'background-color': 'data(backgroundColor)',
							height: 10,
							width: 10
						}
					},
					{
						selector: 'node:selected',
						style: {
							"background-color": "black",
							height: 15,
							width: 15

						}
					},
					{
						selector: 'edge',
						style: {
							'width': 1,
							'height': 700,
							'line-color': '#263238',
							// 'source-endpoint': '0% 70%',
							// 'target-endpoint': '70% 0%',
							// 'curve-style': 'control-point-weight',
							'curve-style': 'bezier',
							'target-distance-from-node': 1,
							'target-arrow-shape': 'triangle',
							'arrow-scale': 0.6
						}
					},
					{
						selector: 'edge.mouseover',
						style: {
							// 'width': 10,
							// 'height': 100,
							'line-color': 'red',
							'z-compound-depth': 'bottom'
						}
					}
				],


				// interaction options:
				zoomingEnabled: true,
				userZoomingEnabled: true,
				panningEnabled: true,
				userPanningEnabled: true,
				boxSelectionEnabled: false,
				selectionType: 'single',
				touchTapThreshold: 8,
				desktopTapThreshold: 4,
				autolock: false,
				autoungrabify: false,
				autounselectify: false,
			}

		);

		var defaults = {
			zoomFactor: 0.05, // zoom factor per zoom tick
			zoomDelay: 45, // how many ms between zoom ticks
			minZoom: 0.1, // min zoom level
			maxZoom: 10, // max zoom level
			// fitPadding: 50, // padding when fitting
			panSpeed: 10, // how many ms in between pan ticks
			panDistance: 10, // max pan distance per tick
			panDragAreaSize: 75, // the length of the pan drag box in which the vector for panning is calculated (bigger = finer control of pan speed and direction)
			panMinPercentSpeed: 0.25, // the slowest speed we can pan by (as a percent of panSpeed)
			panInactiveArea: 8, // radius of inactive area in pan drag box
			panIndicatorMinOpacity: 0.5, // min opacity of pan indicator (the draggable nib); scales from this to 1.0
			zoomOnly: false, // a minimal version of the ui only with zooming (useful on systems with bad mousewheel resolution)
			fitSelector: undefined, // selector of elements to fit
			animateOnFit: function () { // whether to animate on fit
				return false;
			},
			fitAnimationDuration: 1000, // duration of animation on fit

			// icon class names
			sliderHandleIcon: 'fa fa-minus',
			zoomInIcon: 'fa fa-plus',
			zoomOutIcon: 'fa fa-minus',
			resetIcon: 'fa fa-expand'
		};

		// add the panzoom control
		// ......this.cy.panzoom(defaults);

		// destroy the panzoom control
		// this.cy.panzoom('destroy');

		this.cy.on('click', 'node', (event) => {
			this.displayData = event.target.data('element');
		});

		// this.filterData(true)

		this.cy.on('mouseover', 'node', (event) => {

			let node = event.target;

			node.addClass('mouseover');
			var connected_edges = node.connectedEdges(); // connected edges for this node
			connected_edges.addClass('mouseover');
			this.tippyTooltip(node, event.type);

		}, event);


		this.cy.on('mouseout', 'node', (event) => {
			let node = event.target;
			node.removeClass('mouseover');

			var connected_edges = node.connectedEdges(); // connected edges for this node
			connected_edges.removeClass('mouseover');
		}, event);

		this.cy.on('mouseover', 'edge', (event) => {

			let edge = event.target;

			edge.addClass('mouseover');
			this.tippyTooltipEdge(edge, event.type);

		}, event);


		this.cy.on('mouseout', 'edge', (event) => {
			let edge = event.target;
			edge.removeClass('mouseover');
		}, event);

	}

	tippyTooltip(node, type) {
		let ref = node.popperRef(); // used only for positioning and ref of cytoscape reference

		// using tippy ^2.0.0
		let tippy = new Tippy(ref, { // tippy options:
			html: (() => {
				let content = document.createElement('div');

				content.innerHTML = node.data().name ? node.data().name : 'Loading...';

				return content;
			})(),
			arrow: true,
			arrowType: 'round', //'sharp'
			delay: 0,
			trigger: 'mouseenter focus', // 'click', 'manual'
		}).tooltips[0];

		tippy.show();

		node.on('mouseover', () => tippy.show());
		node.on('mouseout', () => tippy.hide());

	}
	tippyTooltipEdge(edge, type) {
		let ref = edge.popperRef(); // used only for positioning and ref of cytoscape reference

		// using tippy ^2.0.0
		let tippy = new Tippy(ref, { // tippy options:
			html: (() => {
				let content = document.createElement('div');
				content.innerHTML = edge.data().module ? edge.data().module : edge.data().label;

				return content;
			})(),
			arrow: true,
			arrowType: 'round', //'sharp'
			delay: 0,
			trigger: 'mouseenter focus', // 'click', 'manual'
		}).tooltips[0];

		tippy.show();

		edge.on('mouseover', () => tippy.show());
		edge.on('mouseout', () => tippy.hide());

	}


	filterData(dataType, type) {
		if (dataType && this.cy) {
			if (type == 'check') {
				// var nodes = this.cy.filter('node'); // a cached copy of nodes
				// nodes.removeClass('hidden');
				// nodes = nodes.filter('[type = "' + dataType + '"]').addClass('hidden'); // each time you want to filter

				// other way
				this.cy.nodes().addClass('hidden');
				this.cy.filter('node[typeof = "' + dataType + '"]').select().removeClass('hidden'); // each time you want to filter
			} else {
				this.cy.filter('node[typeop != "' + dataType + '"]').select().removeClass('hidden'); // each time you want to filter
			}
		}
	}
	addColor(type) {
		let indexOfType = this.legendDetail.findIndex(a => {
			return (a.typeof == type)
		})
		if (indexOfType >= 0) {
			this.legendDetail[indexOfType].show = true
			return this.legendDetail[indexOfType].color;
		} else {
			return '#07c'
		}
	}

	getListView(id) {
		let node = this.showData.nodes.filter(n => {
			if (n.data.id == id) {
				return true
			}
		})
		return node[0]['data']['name']
	}

}
