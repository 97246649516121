import { Component, OnInit, Output, Input, EventEmitter, TemplateRef } from "@angular/core";
import { UploadCsvService } from "../../_services/upload-csv.service";
import { PortfolioSurveyService } from "../../_services/portfolio-survey.service";
import { CommonService } from "../../_services/common.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-rb-advance-option",
  templateUrl: "./rb-advance-option.component.html",
  styleUrls: ["./rb-advance-option.component.scss"],
})
export class RbAdvanceOptionComponent implements OnInit {
  public advanceOptions = {};
  public showMessage;
  public fileName;
  public uploadUrl;
  public uploadedFile;
  public uploadDataDetails;
  public showSpinner:any = [];
  public domainsLeakedCredentials;
  public scopeIdentity;
  public mainDomain;
  @Input() selectedRunbook;
  @Output() newAssessment = new EventEmitter<any>();
  public allSelectedSerive:any = {};
  public selectAllService:any = {}; 
  public serviceDropdown:any = {};
  public modalItem:any=null;
  // File Validation
  public mandetoryFiles = [
    "CRED_LIST_GCP_PATH",
    "ATTACK_SURFACE_CRED_LIST_CLOUD_PATH",
    "CRED_LIST_CLOUD_PATH",
    "CRED_USER_LIST_CLOUD_PATH",
    "CRED_PASSWORD_LIST_CLOUD_PATH"
  ];
  public allFiles:any = [];
  public filesUploaded:any = [];
  public clearUpload:any = [];

  constructor(
    public uploadCsvService: UploadCsvService,
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    public toaster: ToastrService
  ) {}

  ngOnInit(): void {
    let profile = localStorage.getItem('profile')
    if (profile && profile.length) {
        profile = JSON.parse(localStorage.getItem('profile'))
        this.mainDomain = profile['primary_domain']
    }
    if (
      this.selectedRunbook &&
      this.selectedRunbook.run_options &&
      this.selectedRunbook.run_options.scope_details
    ) {
      this.selectedRunbook.run_options.scope =
        this.selectedRunbook.run_options.scope || {};
      Object.keys(this.selectedRunbook.run_options.scope_details).forEach(
        (objKey) => {
          this.selectedRunbook.run_options.scope[
            objKey
          ] = this.selectedRunbook.run_options.scope_details[objKey].default;
        }
      );
    }

    if (this.selectedRunbook) {
      this.scopeIdentity = this.selectedRunbook?.run_options?.scopeIdentity || {}
      Object.values(this.scopeIdentity).forEach(scope => {
        if (scope['data_source'] && scope['data_source'] == 'attack_surface' && scope['entity'] == 'leaked_credential') {
          this.getRelatedDomainsLeakCredentials();
        }
      })
      Object.keys(this.selectedRunbook.run_options.scopeIdentity).forEach(
        (objKey) => {
          console.log('objKey',objKey);
          this.selectAllService[objKey] = false;
          this.allSelectedSerive[objKey] = [];
          this.serviceDropdown[objKey] = false;
        }
      );
    }
    this.getFilesList();
  }
  getFilesList(){
    this.allFiles = [];
    if(this.selectedRunbook['run_options']['scope']){
      let allFeilds = Object.keys(this.selectedRunbook['run_options']['scope']);
      allFeilds.forEach(x => {
        if(this.mandetoryFiles.includes(x)){
          this.allFiles.push(x);
        }
      });
    }
  }
  emitChanges() {
    this.newAssessment["advanceOptions"] = this.selectedRunbook;
    this.newAssessment.emit(this.selectedRunbook);
  }
  objectKeys(obj) {
    return Object.keys(obj);
  }
  checkObjectKeyLength(obj) {
    if (obj && Object.keys(obj) && Object.keys(obj).length){
      for(let k of Object.keys(obj)) {
        if (this.scopeIdentity[k] && !this.scopeIdentity[k]['hideToUser']) return true
      }
      return false;
    }
    else return false;
  }
  getSplitValues(commanString) {
    return commanString.split(",");
  }
  getSignedUrl() {
    let random = Math.random().toString(20).substr(2, 6);
    let type = this.commonService.conevrtStringToSnakeCase(
      this.selectedRunbook.name
    );
    
    this.fileName =  this.commonService.conevrtStringToSnakeCase(this.fileName)
    let dataToSend = {
      content_type: "text/csv",
      file_name:
        random +
        "_" + this.fileName
       ,
      sub_folder: type,
    };
    return new Promise((resolve, reject) => {
      this.uploadCsvService
        .getSignedUrlForUploadCSV(
          this.mainDomain,
          dataToSend
        )
        .subscribe(
          (data) => {
            this.uploadUrl = data["data"]["upload_url"];
            this.uploadDataDetails = data["data"];
            resolve(data["data"]["upload_url"]);
          },
          (error) => {
            if (error["status"] === 403) {
              alert(
                "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
              );
              reject("Signed url generation failed");
            } else {
              alert("Error while getting platform counts!");
              reject("Signed url generation failed");
            }
          }
        );
    });
  }
  async uploadFile(event, item) {
    this.uploadedFile = document.getElementById("uploadedFile"+item)
      ? document.getElementById("uploadedFile"+item)["files"][0]
      : null;
    if (!this.uploadedFile || !this.uploadedFile.name ||  this.uploadedFile.type != "text/csv") {
      alert("Add valid csv file");
      return;
    }
    this.showSpinner[item] = true;
    this.fileName = this.uploadedFile.name;
    if (this.fileName) {
      await this.getSignedUrl();
      if (this.uploadedFile && this.uploadUrl) {
        var reader: any = new FileReader();
        reader.readAsText(this.uploadedFile);
        reader.onload = (e) => {
          try {
            let a = e.target.result;
            this.uploadCsvService
              .uploadFileToSignedUrl(this.uploadUrl, a)
              .subscribe(
                (data) => {
                  this.selectedRunbook.run_options.scope[
                    item
                  ] = this.uploadDataDetails['bucket_name'] +"/"+
                  this.uploadDataDetails.blob_name;
                  this.showSpinner[item] = false
                  this.filesUploaded[item] = true;
                  this.toaster.success("File has been uploaded successfully.");
                },
                (error) => {
                  alert("Something went wrong!");
                  this.showSpinner[item] = false;
                  this.filesUploaded[item] = false;
                }
              );
          } catch (e) {
            this.showSpinner[item] = false;
            this.filesUploaded[item] = false;
            console.log(e)
            alert("Please enter valid CSV file");
          }
        };
      } else {
        alert("Somethig went wrong, Please try again!");
        this.showSpinner[item] = false;
        this.filesUploaded[item] = false;
      }
    }
  }
  clearFileUpload(item){
    // document.getElementById("uploadedFile"+item).value = "";
    this.clearUpload[item] = null;
    this.filesUploaded[item] = false;
    this.selectedRunbook.run_options.scope[item] = null;
  }
  cancelUpload() {
    this.showSpinner.map(x=>x=false);
    this.fileName = null;
  }
  getRelatedDomainsLeakCredentials() {
    let params;
    this.portfolioSurveyService
      .getRelatedDomainsLeakCredentials(
        this.mainDomain,
        // "firecompass.com",
        params
      )
      .subscribe(
        (data: any) => {
          if (data["leak_credentials"]) {
            this.domainsLeakedCredentials = data["leak_credentials"]["data"];
          } else this.domainsLeakedCredentials = null;
        },
        (error) => {
          alert(
            "Something went wrong while getting leaked credentials. Please try again!"
          );
        }
      );
  }
  selectedRelatedDomain(event, item) {
    let dataTosend = {
      domain_name: event,
      main_domain: this.mainDomain,
      playbook_name : this.commonService.conevrtStringToSnakeCase(
        this.selectedRunbook.name), 
    };

    this.portfolioSurveyService.uploadLKForRDFromDB(dataTosend).subscribe(
      (data) => {
        if (data && data['blob_name']) {
          this.selectedRunbook.run_options.scope[item] = data['bucket_name'] +"/"+ data['blob_name'];
        }
      },
      (error) => {
        alert(
          "Something went wrong while getting leaked credentials. Please try again!"
        );
      }
    );
  }
  getItemName(item) { 
    if (this.scopeIdentity[item] && this.scopeIdentity[item]['display_name']) {
      return this.scopeIdentity[item]['display_name'];
    } else return item.replaceAll('_'," ");
  }
  isScopeEditable(item) {
    if (this.scopeIdentity[item] && this.scopeIdentity[item]['editable']) {
      return true
    }
    return false
  }
  inputType(item) {
    if (this.scopeIdentity[item]) {
      return this.scopeIdentity[item]['input_type']
    }
    return null
  }
  isMultiselect(item) {
    if (this.scopeIdentity[item]) {
      return this.scopeIdentity[item]['multi_select']
    }
    return null
  }
  checkScopeIdentityKey(item,key){
    if (this.scopeIdentity[item] && this.scopeIdentity[item][key]) {
      return this.scopeIdentity[item][key]
    }
    return null
  }
  getOptions(item) {
    if (this.scopeIdentity[item]) {
      return this.scopeIdentity[item]['options'].split(',')
    }
    return []
  }
  openSampleModal (template: TemplateRef<any>, data) {
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  openSampleModalOther (template: TemplateRef<any>, data, templateItem) {
    if(data == 'CRED_LIST_CLOUD_PATH'){
      this.modalRef = this.modalService.show(templateItem, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }else{
      this.modalItem = data;
      this.modalRef = this.modalService.show(template, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }
  }
  addRemoveSerive(ser:string="", item){
    if(ser && !this.allSelectedSerive[item].includes(ser)){
      this.allSelectedSerive[item].push(ser);
    }else{
      let index = this.allSelectedSerive[item].indexOf(ser);
      if (index !== -1) {
        this.allSelectedSerive[item].splice(index, 1);
        delete this.allSelectedSerive[item][ser];
      }
    }
    this.selectedRunbook.run_options.scope[item] = this.allSelectedSerive[item].join(',');
  }
  isSelected(ser,ele){
    return this.allSelectedSerive[ele].includes(ser);
  }
  serviceSelectAll(items,ele){
    console.log(this.allSelectedSerive,ele,this.selectAllService)
    this.allSelectedSerive[ele] = [];
    if(this.selectAllService[ele]){
      this.allSelectedSerive[ele].push(...items);
      this.allSelectedSerive[ele].map(x=>{
        this.isSelected(x,ele);
        this.allSelectedSerive[ele][x]= true;
      });
    }
    this.selectedRunbook.run_options.scope[ele] = this.allSelectedSerive[ele].join(',');
  }
}