import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  TemplateRef,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PortfolioSurveyService } from "../../_services";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CommonService } from "../../_services/common.service";
import { SearchPipe } from "app/pipes/search.pipe";
import { reasonToDeleteFootPrint } from "app/_services/common-var.service";
import { TrialCheckService } from "../../_services/trial-check.service";

@Component({
  selector: "app-sub-domains",
  templateUrl: "./sub-domains.component.html",
  styleUrls: ["./sub-domains.component.scss"],
})
export class SubDomainsComponent implements OnInit {
  @Input() mainDomain: any;
  // @Input() subDomains: any;
  // @Input() technologies: any;
  // @Input() cveDetails: any = [];
  @Input() isUserTrial: false;
  // @Input() relatedDomain: string = null;
  @Input() showActionBtn: boolean;
  // @Input() subDomainsCount: number = 0
  @Input() filters: any;
  @Input() showHeaderActions: boolean = true
  @Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
  public hostsArray = [];
  public obsInsCountSpinner = false;
  public hostDetailsForsds;
  public subDomains;
  public subjectType = 'host';
  public domain;
  public count = 0;
  public seeMore = 10;
  public openDropdown;
  public order = 1;
  public startDay = 7;
  public endDay = 30;
  public openSlider;
  public tags: any = [];
  public selectedTags;
  // public selectedState = 'active';
  public selectedState;

  public infoTagDay = 7;
  public seeMoreIps = 3;
  public allIpsToShowIndex = -1;
  public showAllCname = -1;
  public seeMoreCnames = 3;
  public showScanBtn: boolean = false;
  public searchPipe = new SearchPipe();
  public keysToSearch = ["host", "ip_address"];
  // public takeover_risk_value: string = "TAKEOVER RISK";
  public showDowloadText: boolean = false;
  public delReason = "";
  public singleDelete = false;
  public multiSelectCount = 0;
  public openPopup = null;
  public bulkArr: any = [];
  public showBulk: boolean = false;
  public reasonToDeleteFootPrint = reasonToDeleteFootPrint;
  public addNewSubDomain = [];
  public newsubd = {};
  public pocSubjectTouchPoint;
  public pocSubjectComment;
  public pocSubjectObsIns;
  public fieldsToDownload = {
    "Sub Domain": "host",
    "Related Domain": "related_domain",
    IPs: "ips.ip_address",
    CName: "c_name",
    "Updated at": "updated_at",
    "Created At": "created_at",
    Comments: "comments",
    // "Touchpoints":"touchpoints"
  };
  public HighlyTagWithValue = {
    ACTIVE: {
      name: "ACTIVE",
      value: ["active"],
      status: false,
    },
    PREPROD: {
      name: "PRE-PROD",
      value: ["preprod", "uat"],
      status: false,
    },
    DATABASES: {
      name: "DATABASES",
      value: ["databases"],
      status: false,
    },
  };
  public showError;
  public showSpinner = true;
  public page = 1;
  public limit = 10;
  public searchKey = null;
  public total_subdomain_count: number = 0;
  public sortKey = null;

  // public showTakeOverRisk = false;
  public subdomainsToDelete = [];
  public subdomainsToUnDelete = [];
  public showArchivedSubDomain = false;
  public subdomainTobeUndelete;
  newRequest: any;
  public inscopeConfig = null
  public inscopeFlag = null
  public showThis = null
  public isTrialUser:boolean = true;
  public selectedExpiredData:any = null;
  public expiryData: any = [
    { name: "Show All", value: null },
    { name: "Expired", value: 0 },
    { name: "Expiring in 7 days", value: 7 },
    { name: "Expiring in 30 days", value: 30 },
    { name: "Expiring in 60 days", value: 60 },
    { name: "Expiring in 90 days", value: 90 }
    ];
  constructor(
    public activatedRoute: ActivatedRoute,
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public cdr: ChangeDetectorRef,
    public modalRef: BsModalRef,
    public commonService: CommonService,
    public trialCheckService: TrialCheckService
  ) {}

  ngOnInit() {
    this.isTrialUser = this.trialCheckService.isTrailUser();
    this.getTagsForSubDomain();
    this.getSubDomain();
    this.inscopeConfig = localStorage.getItem('scope_config')
    if(this.inscopeConfig == 'true'){
      this.showThis = 'inscope'
    } else this.showThis = 'state'
    this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
			this.getSubDomain()
		});
  }
  ngOnChanges(changes: SimpleChanges) {
    const changeFilters: SimpleChange = changes.filters;
    if (Object.keys(changeFilters.currentValue).length) {
      if (this.filters && this.filters.page) {
        this.page = this.filters.page
        delete this.filters.page
      }
      this.getSubDomain();
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  getJson(string) {
    if (string && string.length) {
      return JSON.parse(string);
    } else return false;
  }

  displayTags(domain) {
    if (domain.tags && domain.tags.length > 0) {
      return true;
      // } else if (domain.takeover_risk && domain.takeover_risk >= 2.0) {
      //   return true;
    } else {
      return false;
    }
  }

  checkWebSource(sDomain) {
    if (sDomain["grade"] && typeof sDomain["grade"] == "string") {
      return true;
    } else {
      return false;
    }
  }

  // validateTechnology(domain) {
  //   if (this.technologies && this.technologies[domain.host]) {
  //     domain.technologies = this.technologies[domain.host];
  //     return true;
  //   }
  //   return false;
  // }

  filterSubDomainByTag(value) {
    this.page = 1;
    if (value == "clear") {
      this.tags.forEach((tag) => {
        tag.selected = false;
      });
    }
    let flag = true;
    const selectedTagsArr = this.tags.map((t) => {
      if (t.selected) {
        return t.tag_original;
      }
    });
    this.selectedTags = selectedTagsArr.filter((t) => t).join(",");
    this.filters = { ...this.filters, tags: this.selectedTags };
    this.getSubDomain();
  }
  changeTagFilterBasedOnHighyTag(tag) {
    if (this.HighlyTagWithValue[tag.name])
      this.HighlyTagWithValue[tag.name].status = !tag["selected"];
    tag["selected"] = !tag["selected"];
  }
  // getTags(domain) {
  //   if (domain && domain.tags && domain.tags.length > 0) {
  //     this.filterTagsForDropdown(domain);
  //   }
  //   if (domain.hosts && domain.hosts[0] != null && domain.hosts.length > 0) {
  //     domain.hosts.forEach((host) => {
  //       this.filterTagsForDropdown(host);
  //     });
  //   }
  // }

  filterTagsForDropdown(domain) {
    if (domain) {
      // let takeover_risk = true;
      // if (domain.takeover_risk >= 2.0 && takeover_risk) {
      //   let tagObject = {
      //     name: this.takeover_risk_value,
      //     value: [this.takeover_risk_value],
      //   };
      // let index = this.tags.findIndex(function (tagOb) {
      //   return tagOb.name == tagObject.name;
      // });
      // if (index == -1) {
      //   this.tags.push(tagObject);
      // takeover_risk = false;
      // }
      // }
      if (domain && domain.tags != null && domain.tags.length > 0) {
        domain.tags.forEach((tag) => {
          let tag_uppercase = tag.toUpperCase();
          let tagObject = { name: tag_uppercase, value: [tag_uppercase] };
          if (
            tag_uppercase == "HTTP ACTIVE" ||
            tag_uppercase == "HTTPS ACTIVE"
          ) {
            tagObject["name"] = "ACTIVE";
          }
          let index = this.tags.findIndex(function (tagOb) {
            return tagOb.name == tagObject.name;
          });
          if (index == -1) {
            this.tags.push(tagObject);
          } else {
            if (this.tags[index]["value"].indexOf(tag_uppercase) == -1) {
              this.tags[index]["value"].push(tag_uppercase);
            }
          }
        });

      }
    }
  }

  filterDomainByHighlyTag(value, status) {
    for (let i = 0; i < value.length; i++) {
      this.tags.map((tag) => {
        if (tag.name == value[i].toUpperCase()) {
          tag["selected"] = status;
        }
      });
    }
    this.filterSubDomainByTag(null);
  }
  checkTagInTagsArray(tag) {
    if (
      this.tags.findIndex((t) => tag.includes(t["name"].toLowerCase())) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  // cvForTechnologies(template: TemplateRef<any>, domain) {
  //   domain.technologies.forEach((technologie) => {
  //     if (technologie && technologie.technology_name && technologie.version) {
  //       technologie["cveDetailArray"] = [];
  //       this.cveDetails.forEach((cveDetail) => {
  //         if (cveDetail) {
  //           if (
  //             technologie.technology_name.toLowerCase() ==
  //             cveDetail.technology_name.toLowerCase() &&
  //             technologie.version == cveDetail.version
  //           ) {
  //             technologie["cveDetailArray"] = cveDetail["cves"];
  //           }
  //         }
  //       });
  //     }
  //   });
  //   this.modalOpen(template, domain);
  // }
  selctForBulkAction(value) {
    if (value === true) {
      this.subDomains.forEach((ip) => {
        this.multiSelectCount++;
        ip.selected = true;
      });
    } else {
      this.subDomains.forEach((ip) => {
        ip.selected = false;
      });
      this.multiSelectCount = 0;
    }
  }
  delSubDomain() {
    this.subdomainsToDelete = [];
    this.subDomains.forEach((sd) => {
      if (sd["selected"]) {
        sd.updated = true;
        sd["archive"] = true;
        sd["archive_reason"] = this.delReason;
        this.subdomainsToDelete.push(sd);
      }
    });
    if (this.subdomainsToDelete) {
      this.updateSubdomain();
    }
    this.modalRef.hide();
  }

  selectedNewBox(sdomain) {
    sdomain.selected = !sdomain.selected;
    if (sdomain.selected) {
      this.multiSelectCount++;
    } else {
      this.multiSelectCount =
        this.multiSelectCount > 0 ? this.multiSelectCount - 1 : 0;
    }
  }

  uncheckDelete() {
    this.subDomains.map((a) => {
      a.selected = false;
      a.updated = false;
    });
    this.singleDelete = false;
    this.multiSelectCount = 0;
  }
  openRemoveOptions(template: TemplateRef<any>, sDomain = {}) {
    if (this.singleDelete) {
      this.uncheckDelete();
    }
    if (sDomain && sDomain["host"]) {
      sDomain["selected"] = true;
    }
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  deleteDataCheck(value) {
    this.delReason = value;
    this.delSubDomain();
  }
  openNewSubDomain(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  addNewSubDomainBtn() {
    this.addNewSubDomain = [
      {
        added: true,
        discovered_at: "",
        host: this.newsubd["newSubDomain"],
        ip_address: "",
        related_domain: this.newsubd["relatedDomain"],
        score: "8",
        technologies: [],
      },
    ];
    this.modalRef.hide();
    this.portfolioSurveyService
      .addNewSubDomain(this.mainDomain["domain_name"], this.addNewSubDomain)
      .subscribe(
        (data) => {
          this.newsubd = {};
          this.subDomains.unshift(this.addNewSubDomain[0]);
        },
        (error) => {
          if (error["status"] === 403) {
            alert(
              "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
            );
          } else {
            alert("Error while updating");
          }
        }
      );
  }

  openCommentsModule(template: TemplateRef<any>, sdomain) {
    this.pocSubjectComment = {
      subject_type: "sub-domain",
      subject_name: sdomain["host"],
    };
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }

  openTouchpointModule(template: TemplateRef<any>, sdomain) {
    this.pocSubjectTouchPoint = {
      subject_type: "sub-domain",
      subject_name: sdomain["host"],
    };

    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  getAllComments() {
    if (this.mainDomain) {
      this.portfolioSurveyService
        .getAllComments(this.mainDomain["domain_name"], "sub-domain")
        .subscribe((data) => {
          this.subDomains.forEach((subd) => {
            const commentsForDomain = data["data"].filter((a) => {
              return a.subject_name == subd.host;
            });
            if (commentsForDomain && commentsForDomain.length) {
              commentsForDomain.forEach((cc) => {
                subd.comments
                  ? subd.comments.push(cc.title)
                  : (subd.comments = [cc.title]);
              });
            }
          });
        });
    }
  }
  getAllObsAndIns() {
    if (this.mainDomain) {
      this.portfolioSurveyService
        .getAllObsAndIns(this.mainDomain["domain_name"], "sub-domain")
        .subscribe((data) => {
          this.subDomains.forEach((subd) => {
            const observationsForDomain = data["data"].filter((a) => {
              return a.subject_name == subd.host;
            });
            if (observationsForDomain && observationsForDomain.length) {
              observationsForDomain.forEach((cc) => {
                subd.observationsandinsights
                  ? subd.observations.push(cc.observation)
                  : (subd.observations = [cc.observation]);
              });
            }
          });
        });
    }
  }
  getObsInsCountsForEachHost(hostsArray) {
    this.obsInsCountSpinner = true
    const params = {
      subject_ref_list: hostsArray,
      subject_type: this.subjectType
    }
    this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain['domain_name'], params).subscribe(data => {
      this.obsInsCountSpinner = false
      this.hostDetailsForsds = data['data']
      this.subDomains.forEach((h) => {
        let hostName = h.host
        if (this.hostDetailsForsds[hostName]) {
          h.obs_count = this.hostDetailsForsds[hostName].count_obs
          h.ins_count = this.hostDetailsForsds[hostName].count_ins
        }
      })
    })
  }
  getAllTouchpoints() {
    this.portfolioSurveyService
      .getAllTouchpoints(this.mainDomain["domain_name"], "sub-domain")
      .subscribe((data) => {
        this.subDomains.forEach((subd) => {
          const touchpointsForDomain = data["data"].filter((a) => {
            return a.subject_name == subd.host;
          });
          if (touchpointsForDomain && touchpointsForDomain.length) {
            touchpointsForDomain.forEach((cc) => {
              subd.touchpoints
                ? subd.touchpoints.push(cc.email)
                : (subd.touchpoints = [cc.email]);
            });
          }
        });
      });
  }
  getSubDomain() {
		this.newRequest ? this.newRequest.unsubscribe(): null
    this.showSpinner = true;
    const params = {
      page: this.page,
      limit: this.limit,
      searchKey:
        this.searchKey && this.searchKey.length ? this.searchKey : null,
      tags:
        this.selectedTags && this.selectedTags.length
          ? this.selectedTags
          : null,
      // take_over_risk: this.showTakeOverRisk,
      ...this.filters,
      archive: this.showArchivedSubDomain,
      // scope: this.inscopeFlag,
      state : this.selectedState,
      expire:this.selectedExpiredData

    };
    this.filters = { ...this.filters, expire: this.selectedExpiredData };
    if(this.showArchivedSubDomain && this.inscopeConfig == 'true') params.scope = "ALL" 
		else if(this.showArchivedSubDomain && this.inscopeConfig == 'false')  delete params.scope 
		else if(!this.showArchivedSubDomain && this.inscopeConfig == 'true') params.scope = this.inscopeFlag
    
    if (this.sortKey)
      params.sortKey = this.order == -1 ? this.sortKey : "-" + this.sortKey;

      this.newRequest =  this.portfolioSurveyService
      .getSubDomain(this.mainDomain["domain_name"], params)
      .subscribe(
        (data) => {
          this.showSpinner = false;
          if (data) {
            this.subDomains = data["data"].results;
            this.getAllComments();
            this.getAllObsAndIns()
            this.subDomains.forEach(subd => {
              subd.subjectTypeName = {
                "subject_type": this.subjectType,
                "subject_name": subd["host"]
              }
              this.hostsArray.push(subd.host)
            })
            this.getObsInsCountsForEachHost(this.hostsArray)
            this.total_subdomain_count = data["data"].count;
            this.commonService.calculateCreatedAndUpdated(this.subDomains);
          } else {
          }
        },
        (error) => {
          this.commonService.errorForDomain(error);
        }
      );
  }
  goToPage(val) {
    this.page = val;
    this.getSubDomain();
  }
  searchAllSubDomainDetail() {
    this.searchKey = null;
    this.getSubDomain();
  }
  searchSubDomainDetail(value) {
    this.page = 1;
    this.getSubDomain();
  }
  SubDomainDetail(value){
    if((value === 'expiry')){
      this.selectedExpiredData = null;
    }
  }
  // getTakeOverRisk() {
  //   this.page = 1;
  //   this.showTakeOverRisk = !this.showTakeOverRisk
  //   this.getSubDomain()
  // }
  getTagsForSubDomain() {
    this.portfolioSurveyService
      .getSubDomainTagsList(this.mainDomain["domain_name"])
      .subscribe((data) => {
        this.tags = [];
        data["data"].forEach((tag) => {
          let tag_uppercase = tag.toUpperCase();
          let tagObject = {
            name: tag_uppercase,
            value: [tag_uppercase],
            tag_original: tag,
          };
          if (
            tag_uppercase == "HTTP ACTIVE" ||
            tag_uppercase == "HTTPS ACTIVE"
          ) {
            tagObject["name"] = "ACTIVE";
          }
          let index = this.tags.findIndex(function (tagOb) {
            return tagOb.name == tagObject.name;
          });
          if (index == -1) {
            this.tags.push(tagObject);
          } else {
            if (this.tags[index]["value"].indexOf(tag_uppercase) == -1) {
              this.tags[index]["value"].push(tag_uppercase);
            }
          }
        });
      });
  }
  hideDeleteModel() {
    this.modalRef.hide();
    this.newsubd = {};
  }

  getCnames(sd) {
    if (sd.dns_details && sd.dns_details.length) {
      const cnames = [];
      sd.dns_details.forEach((dns) => {
        if (dns.type == "CNAME") cnames.push(dns.name);
      });
      return cnames;
    } else if (sd["c_name"]) return [sd["c_name"]];
    else return [];
  }
  getARecord(sd) {
    if (sd.dns_details && sd.dns_details.length) {
      const aRecords = [];
      sd.dns_details.forEach((dns) => {
        if (dns.type == "AAAA" || dns.type == "A") {
          aRecords.push(dns.name);
          sd["active"] = true;
        }
      });
      return aRecords;
    } else return [];
  }
  filterBySubdomainState(value) {
    this.page = 1;
    this.filters = { ...this.filters, state: value };
    this.selectedState = value;
    this.getSubDomain();
    this.openDropdown = false;
  }
  switchDataForArchive() {
    this.showArchivedSubDomain = !this.showArchivedSubDomain;
    this.selectedTags = [];
    this.searchKey = null;
    this.page = 1;
    this.getSubDomain();
  }
  updateSubdomain() {
    let subdomain;
    if (this.subdomainsToDelete.length) {
      subdomain = this.subdomainsToDelete;
    } else {
      subdomain = this.subdomainTobeUndelete;
    }
    this.portfolioSurveyService
      .updateSubdomainDomain(subdomain, this.mainDomain["domain_name"])
      .subscribe(
        (data) => {
          if (this.subdomainsToDelete && this.subdomainsToDelete.length && this.subdomainsToDelete.length == 1) {
            alert("Subdomain deleted successfully!")
          } else if (this.subdomainsToDelete && this.subdomainsToDelete.length && this.subdomainsToDelete.length > 1) {
            alert("Subdomains deleted successfully!")
          } else if (this.subdomainTobeUndelete && this.subdomainTobeUndelete.length && this.subdomainTobeUndelete.length == 1) {
            alert("Subdomain undeleted successfully!");
          } else if (this.subdomainTobeUndelete && this.subdomainTobeUndelete.length && this.subdomainTobeUndelete.length > 1) {
            alert("Subdomains undeleted successfully!");
          } else {
            alert("Subdomain updated successfully!");
          }
          this.multiSelectCount = 0;
          this.page = 1;
          this.getSubDomain();
          this.refreshCount.emit("subdomain");
          this.subdomainsToDelete = [];
        },
        (error) => {
          if (error["status"] === 403) {
            alert(
              "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
            );
          } else {
            alert("Error while updating");
          }
          this.subdomainsToDelete = [];
        }
      );
  }
  undeleteSubdomain(subdomain) {
    subdomain["archive"] = false;
    subdomain["updated"] = true;
    this.subdomainTobeUndelete = [subdomain];
    this.updateSubdomain();
  }
  filterByInscope(value) {
    this.page = 1;
    this.filters = { ...this.filters, scope: value };
    this.inscopeFlag = value;
    this.getSubDomain();
    this.openDropdown = false;
  }
}
