import { Component, OnInit } from '@angular/core';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-third-party-dashboard',
  templateUrl: './third-party-dashboard.component.html',
  styleUrls: ['./third-party-dashboard.component.scss']
})
export class ThirdPartyDashboardComponent implements OnInit {

  constructor(
    private router:Router,    
    private portfolioSurveyService: PortfolioSurveyService            
  ) { }

  public previousDomains;
  public showSpinner;
  public showError;
  public sortWith = 'domain';
  public order = 1;
  ngOnInit() {
    // var profile = JSON.parse(localStorage.getItem('profile'));
    this.showSpinner = true;
    this.portfolioSurveyService.getDomainsSearchForUser().subscribe((data:any) => {
      this.previousDomains = data;
      this.previousDomains.forEach(pDomain => {
        this.portfolioSurveyService.getWebSecurity(pDomain.domain).subscribe((res:any) => {
          if(res.analyze && res.analyze.state === "FINISHED") {
            pDomain['webSecurityScore'] = res.analyze.grade;
            } else if(res.error === "invalid-hostname") {
              pDomain['webSecurityScore'] = "NA"
            }
        })
        this.portfolioSurveyService.getBreachedCredentials(pDomain.domain).subscribe((data:any) => {
          console.log(data);
          // if(data && data.results && data.results.breachSize < 10) {
          //   pDomain['leakedCredentials'] = data.results.breachSize;
          // } else if(data && data.results && data.results.breachSize >= 10) {
          //   this.portfolioSurveyService.getBreachedPassByDomain(pDomain.domain).subscribe(data => {
          //     console.log(data)
          //     if(data && data[0] && data[0][0]) {
          //       pDomain['leakedCredentials'] = data[0][0].total;
          //     } else if(data && data[0] && !data[0].length) {
          //       pDomain['leakedCredentials'] = '0';
          //     }
          //   })
          // }
        }, error => {
          console.log(error)
        })
        // this.portfolioSurveyService.getPhishingDomains(pDomain.domain).subscribe(data => {
        //   if(data && data.results && data.results['DomainListSize']) {
        //     pDomain['phishingDomains'] = data.results['DomainListSize'];
        //   }  
        // })
      });
      console.log(this.previousDomains);      
      this.showSpinner = false;
    }, error => {
      this.showSpinner = false;
      this.showError = "Error while connecting to server! Please try after some time.";
    })
  }

  public goToExternamOsint(domain) {
    localStorage.removeItem("domain");
    localStorage.setItem("domain", domain);
    this.router.navigateByUrl('/externalosint/external-osint')
  }
}
