import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
import { CommonService } from 'app/_services/common.service';
import * as d3 from "d3";
import { SearchPipe } from 'app/pipes/search.pipe';


@Component({
	selector: 'app-remediation',
	templateUrl: './remediation.component.html',
	styleUrls: ['./remediation.component.scss']
})
export class RemediationComponent implements OnInit {
	public domain
	public showAllAssets: boolean = false
	public assetDetail = [];
	public keysToSearch = ['risk_name']
	public sortWith = 'risk_name'
	public order = 1;
	gaugemap: any = {};
	public searchPipe = new SearchPipe();
	public dataToUpdate = {};
	public bulkCheck: boolean = false;
	public currentRisk = {}
	public riskCounts = {};
	public probableDigitalRisk;
	public topRisk
	public patchedTopRisk
	public riskDetails = {}
	public dataToDisplay = null;
	public patchDataToDisplay = null;
	public riskType: string = null;
	public breachedCredentials: any = [];
	public patchedBreachedCredentials: any = []
	public getriskForDomain: boolean = false
	public getProbableRisk: boolean = false
	public showSpinner: boolean = false;
	public showSpinnerforChart: boolean = false;
	public isUserTrial: boolean = false;
	public sourceLocation = []
	public seeMoreLoc = 3
	public digitalFootprintScore: any;
	public overAllScore: number;
	public maliciousCount = 0;
	public idsToDelete: any = {
		"archive_reason": null,
		"ids": []
	};
	public showThis = 'remediate';
	public showThisRem = 'remediate';
	public showDowloadText: boolean = false;
	public fieldsToDownload = {
		'Risk': 'risk_name',
		'Suggested Remediation': 'suggested_remediation',
		'Affected Assets': 'asset_count',
		'Severity': 'severity',
	}
	public allRiskDetails = [
		{
			'risk_name': 'LEAKED CREDENTIALS',
			'risk_description': 'Multiple leaked credentials are associated with your organisation. Some of these leaked credentials may still be used by the users either in their original form or some variations of them',
			'risk_code': 'leakedCreds',
			'severity': 'High',
			'risk_counter': 'leakedCredCount',
			'suggested_remediation': 'Blacklist the leaked credentials at various services such as AD, Email, VPN etc. ',
			'asset_count': null
		},
		{
			'risk_name': 'EXPOSED DATABASES',
			'risk_description': 'Digital Attack Surface has various exposed database ports to public internet. Some of these databases can have critical vulnerabilities or weak authentication. ',
			'risk_code': 'exposedDatabase',
			'severity': 'High',
			'risk_counter': 'exposedDatabasesCount',
			'suggested_remediation': 'It is suggested to firewall the database ports so that only required applications can access the required databases. Please strong authentication and authorization at database level to mitigate advance multi stage attacks. ',
			'asset_count': null
		},
		{
			'risk_name': 'IP REPUTATION',
			'risk_description': 'Few assets are found to be generating malicious traffic as flagged by Threat Intelligence sources. It is just an indicator that something might be wrong with the asset. More detail investigation and review is required to confirm malicious behavior',
			'risk_code': 'maliciousEntities',
			'severity': 'High',
			'risk_counter': 'maliciousCount',
			'suggested_remediation': 'Get more detailed forensics performed. Review vulnerabilities, security configurations, firewall rules and other security controls',
			'asset_count': null
		},
		{
			'risk_name': 'PRE-PROD SERVICES',
			'risk_description': 'Multiple pre prod environments are found to be exposed to the public internet. Pre Prod environments generally have less secure configuration and may have few critical vulnerabilities. Attacker can leverage pre prod environments to get initial foothold in your network',
			'risk_code': 'preprodServices',
			'severity': 'High',
			'risk_counter': 'prepodServicesCount',
			'suggested_remediation': 'Review whether the required pre prod environments are supposed to be exposed to the public internet. Sandbox the pre prod environments (separate them from production setup) to mitigate multi stage attacks',
			'asset_count': null
		},
		{
			'risk_name': 'MISCELLANEOUS',
			'risk_description': 'Some risks associate with this domain found by analyst at Firecompass.com while we were triaging your account.',
			'risk_code': 'miscellaneous',
			'severity': 'High',
			'risk_counter': 'miscellaneousCount',
			'suggested_remediation': 'Review each risk and take required actions against each. Write to us at contact@firecompass.com if you have any queries.',
			'asset_count': null
		},
		{
			'risk_name': 'DOMAIN TAKEOVER RISK',
			'risk_description': 'Few domains are found have CNAME Record pointing to non existent / expired services. The attack can leverage the vulnerable setup to perform advance attacks to your end users or even to your internal organisation. ',
			'risk_code': 'takeoverRisk',
			'severity': 'Medium',
			'risk_counter': 'takeoverRisksCount',
			'suggested_remediation': 'It is suggested to review all the DNS records, and specifically remove the mentioned dangling CNAME records to mitigate the risk',
			'asset_count': null
		},
		{
			'risk_name': 'EXPOSED SERVICES',
			'risk_description': 'Multiple network services are exposed to external network. Some of these services may have critical vulnerabilities or weaker authentication and encryption. ',
			'risk_code': 'exposedServices',
			'severity': 'Medium',
			'risk_counter': 'exposedServicesCount',
			'suggested_remediation': 'Review all the network service accessibility from external network, restrict service accessibility and upgrade services if required .',
			'asset_count': null
		},
		{
			'risk_name': 'LOOKALIKE DOMAINS',
			'risk_description': 'Multiple homonymous domains detected. Some these domains may be actively targeting your organisation. ',
			'risk_code': 'phishingDomains',
			'severity': 'Medium',
			'risk_counter': 'phishingDomainsCount',
			'suggested_remediation': 'Review all the possible homonymous domains, If the domains is detected to be malicious, it is suggested to avail take down services to bring down the domain.',
			'asset_count': null
		},
		// {
		// 	'risk_name': 'VULNERABILITIES',
		// 	'risk_description': 'Multiple High & Critical vulnerabilities detected in your external digital attack surface. Some of these vulnerabilities may have exploits in the surface or dark marketplace',
		// 	'risk_code': 'vulnerabilities',
		// 	'severity': 'Medium',
		// 	'risk_counter': 'vulnerabilitiesCount',
		// 	'suggested_remediation': 'Review all the vulnerabilities, and apply required patches or upgrade the version.',
		// 	'asset_count': null
		// },

	]

	public patchedRiskDetails = {}
	public oldallRiskDetails = Object.assign(this.allRiskDetails)
	public remediationList = ['Patched', 'False Positive', 'Upgraded Version', 'Apply Compensatory Control', ' Taken Down', ' Risk Accepted']
	public pieChartOptions = {
		legend: {
			display: true,
			position: 'right',
			label: {
				generateLabels: () => {
					return [{
						text: '50',
						fillStyle: '#42a5f5',
						strokeStyle: '#fff'
					}]
				}
			}
		}

	}
	public pieChartLabels: string[] = [];
	public pieChartData: number[] = [];
	public pieChartType = 'pie';
	public pieChartColor: any = [{
		'backgroundColor': ['rgb(255, 193, 84)', 'rgb(129,154,116)', 'rgb(0, 126, 214)', 'rgb(95,218,214)', 'rgb(246, 109, 68)', 'rgb(115,105,149)', 'rgb(255, 236, 0)', 'rgb(231, 170, 151)', 'rgb(71, 179, 156)', 'rgb(240, 240, 241)', 'rgb(124, 221, 221)', 'rgb(143, 83, 164)', 'rgb(251, 236, 135)']
	}]
	public dataAvailable = false
	public showDataSpinner:number =-1
 
	constructor(
		public portfolioSurveyService: PortfolioSurveyService,
		public route: ActivatedRoute, public router: Router,
		public modalService: BsModalService, public modalRef: BsModalRef,
		public commonService: CommonService
	) { }

	ngOnInit() {
		this.getDomainName()
		// this.getRandomColor()
	}

	getAllData() {
		this.overAllScoreForMeter();
		this.getCount();
		this.getTopRisk()
		this.patchedGetTopRisk()
		this.showSpinnerforChart = true
	}

	setUserAccount(userAccount) {
		if (userAccount && userAccount == 'trial') {
			this.isUserTrial = true;
		}
	}
	overAllScoreForMeter() {
		this.portfolioSurveyService.getOverallScoreForDomain(this.domain).subscribe(data => {
			this.digitalFootprintScore = data['data']
			this.overAllScore = 900 - this.digitalFootprintScore['overall_score'] ? this.digitalFootprintScore['overall_score'] : 200
			if (this.overAllScore) {
				this.draw();
			} else {
				this.overAllScore = 700
				this.draw();
			}
		})
	}

	// getRandomColor() {
	// 	var color;
	// 	var colorArray = [];
	// 	for (var i = 0; i < this.allRiskDetails.length; i++) {
	// 	color = randomColor({
	// 		luminosity: 'random',
	// 		hue: 'random',
	// 		format: 'rgba'
	// 	})
	// 	colorArray.push(color)
	// 	}
	// 	console.log(this.allRiskDetails.length, colorArray)
	// 	this.pieChartColor = [{
	// 		'backgroundColor' : colorArray
	// 	}]
	// 	console.log("list of color", this.pieChartColor)
	// }

	getDomainName() {
		this.route.queryParams.subscribe(params => {
			if (params && params['domain']) {
				this.domain = params['domain'];
				this.router.navigate(['.'], {
					relativeTo: this.route, queryParams: { domain: this.domain }, queryParamsHandling: 'merge',
					skipLocationChange: true
				});
				this.getAllData();
			} else {
				this.portfolioSurveyService.getDomainsSearchForUser().subscribe((data: any) => {
					data.filter(d => {
						if (d['domain_type'] == 'self') {
							this.domain = d['domain']
							this.getAllData();
						}
					})
					if (!this.domain) {
						alert('Please Log in')

					}
				})
			}
		})
	}
	maliciousDetail() {
		this.portfolioSurveyService.getRiskDetails(this.domain, 'maliciousEntities').subscribe(data => {
			this.maliciousCount = data['count']
			this.riskCounts['maliciousCount'] = this.maliciousCount
			this.showDataSpinner = this.showDataSpinner + 1
			this.allRiskDetails.forEach(element => {
				if (element.risk_code == 'maliciousEntities') {
					element.asset_count = this.riskCounts[element.risk_counter]
				}
				this.checkDataAvailable(this.riskCounts)
			})
		})

	}
	getCount() {
		this.showDataSpinner = -1
		this.maliciousDetail()
		this.portfolioSurveyService.getRisksForDomain(this.domain).subscribe((data: any) => {
			if (data['status'] == 'pass') {
			this.showDataSpinner = this.showDataSpinner + 1
				this.riskCounts = Object.assign(this.riskCounts, (data['data']));
				this.allRiskDetails.forEach(element => {
					if (element.risk_code != 'maliciousEntities') {
						element.asset_count = this.riskCounts[element.risk_counter]
					}
				})
				this.getriskForDomain = true
				if (this.getriskForDomain && this.getProbableRisk) {
					this.updatePieChart();
				}
				this.checkDataAvailable(this.riskCounts)
			}
		}, error => {
			alert("You do not have permission to view content for this Domain")
			this.router.navigate(['/new/self-domains']);

		})
		this.portfolioSurveyService.getProbableRiskCountForDomain(this.domain).subscribe((data: any) => {
			if (data['status'] == 'pass') {
				this.probableDigitalRisk = data['data'];
				this.riskCounts = Object.assign(this.riskCounts, (data['data']));
				this.getProbableRisk = true

				if (this.getriskForDomain && this.getProbableRisk) {
					this.updatePieChart();
				}
				this.checkDataAvailable(this.riskCounts)
			}
		})

	}
	updatePieChart() {
		this.allRiskDetails.forEach(rs => {

			let index = this.pieChartLabels.indexOf(rs.risk_name);
			if (index > -1) {
				this.pieChartData[index] = this.riskCounts[rs.risk_counter]
			}
			else if (rs.risk_name && this.riskCounts[rs.risk_counter] && this.riskCounts[rs.risk_counter] != 'NA') {
				this.pieChartLabels.push(rs.risk_name)
				this.pieChartData.push(this.riskCounts[rs.risk_counter])
			}

		})
		this.showSpinnerforChart = false
		this.pieChartLabels = Object.assign([], this.pieChartLabels);
		this.pieChartData = Object.assign([], this.pieChartData);
	}

	searchRisk(value) {
		this.allRiskDetails = this.searchPipe.transform(this.oldallRiskDetails, this.keysToSearch, value);
	}
	selctForBulkAction(selctedValue, type) {
		if (type == 'allcheck') {
			this.dataToDisplay['data'].forEach(d => {
				d.selected = selctedValue;
				this.idsToDelete['ids'].push(d['id']);
				// if (d['takeover_risk']) {
				// 	d['takeover_risk'] = -1.0
				// }
			})
		}
		if (type == 'leakedCredAllCheck') {
			this.breachedCredentials.forEach(d => {
				d.selected = selctedValue;
			})
		}
	}
	saveRemedition(selectedData) {
		if (this.currentRisk['risk_code'] != 'leakedCreds') {
			this.dataToDisplay['data'].forEach(asset => {
				if (asset['selected']) {
					asset['updated'] = true;
					// if (asset['takeover_risk']) {
					// 	asset['takeover_risk'] = -1.0
					// } else {
					asset['archive'] = true;
					asset['archive_reason'] = selectedData['archive_reason'];
					// }
					asset['comment'] = selectedData['comment'];
				}
			})

		} else if (this.currentRisk['risk_code'] == 'leakedCreds') {
			this.breachedCredentials.forEach(asset => {
				if (asset['selected']) {
					asset['updated'] = true;
					// if (asset['takeover_risk']) {
					// 	asset['takeover_risk'] = -1.0
					// } else {
					asset['archive'] = true;
					asset['archive_reason'] = selectedData['archive_reason'];
					// }
					asset['comment'] = selectedData['comment'];
				}
			})
		}
		this.RemediateApiCall()
		this.dataToUpdate = {}
		this.modalRef.hide()

	}

	updateDataValues(cred) {
		cred.selected = !cred.selected;
		if (cred.selected == true) {
			if (cred['id']) {
				this.idsToDelete['ids'].push(cred['id'])
			}
			// if (cred['takeover_risk']) {
			// 	cred['takeover_risk'] = -1.0
			// }
		}
	}

	RemediateApiCall() {
		let risk = this.currentRisk['risk_code']
		switch (risk) {
			case "takeoverRisk": {
				this.portfolioSurveyService.updateSubdomainDomain(this.dataToDisplay['data'], this.domain).subscribe(data => {
					this.idsToDelete = {
						"archive_reason": null,
						"ids": []
					};
					this.getCount();
					this.getApiCall(risk);
				})
				break;
			}
			case "exposedDatabase":
			case "exposedServices": {
				Object.assign(this.idsToDelete, this.dataToUpdate)
				this.portfolioSurveyService.deleteService(this.idsToDelete, this.domain).subscribe(data => {
					this.idsToDelete = {
						"archive_reason": null,
						"ids": []
					};
					this.getCount();
					this.getApiCall(risk);
				})
				break;
			}

			case "leakedCreds": {
				this.portfolioSurveyService.updateBreachedCredentials(this.breachedCredentials, this.domain).subscribe(data => {
					this.getCount();
					this.getApiCall(risk);
				})
				break;
			}
			case "phishingDomains": {
				this.portfolioSurveyService.updatePhishingDomain(this.dataToDisplay['data'], this.domain).subscribe(data => {
					this.getCount();
					this.getApiCall(risk);
				})
				break;
			}
			case "maliciousEntities": {
				this.portfolioSurveyService.updateMalicious(this.dataToDisplay['data'], this.domain).subscribe(data => {
					this.getCount();
					this.getApiCall(risk);
				})
				break;
			}
			case "preprodServices": {
				Object.assign(this.idsToDelete, this.dataToUpdate)
				this.portfolioSurveyService.deleteSubdomainDomain(this.idsToDelete, this.domain).subscribe(data => {
					this.idsToDelete = {
						"archive_reason": null,
						"ids": []
					};
					this.getCount();
					this.getApiCall(risk);
				})
				break;
			}
			case "miscellaneous": {
				this.portfolioSurveyService.updateDigitalRisk(this.topRisk, this.domain).subscribe(data => {
					this.getCount();
					this.getTopRisk();
				})
				break;
			}
			// case "vulnerabilities" :return
			default: {
				console.log("Invalid choice");
				break;
			}
		}
	}
	public getRiskDetails(template: TemplateRef<any>, riskType, count) {
		console.log(riskType, count)
		this.showThisRem = 'remediate';
		this.showThis = 'remediate';
		this.currentRisk = riskType
		this.riskType = riskType.risk_code;
		// this.modalHide();
		if (count && count > 0) {
			if (this.riskDetails && this.riskDetails[riskType.risk_code]) {
				if (this.riskDetails[riskType.risk_code]['data'] == 'Forbidden') {
					this.dataToDisplay = {
						"headers": { "forbiddon": "You do not have permission to view this content" },
						"data": null
					}
				} else {
					this.dataToDisplay = {
						"headers": this.HeadersJson[riskType.risk_code],
						"data": this.riskDetails[riskType.risk_code]
					}
				}
				this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: true, class: 'auto_width' });
			} else {
				this.portfolioSurveyService.getRiskDetails(this.domain, riskType.risk_code).subscribe((data: any) => {
					this.setUserAccount(data['userAccount']);

					if (data && data['status'] == 'pass') {
						if (riskType.risk_code == 'vulnerabilities') {
							let dataToShow = this.calculateVulnerabilityRiskDetails(data);
							this.riskDetails[riskType.risk_code] = dataToShow;
						} else {
							this.riskDetails[riskType.risk_code] = data['data']
						}
						if (data['data'] == 'Forbidden') {
							this.dataToDisplay = {
								"headers": { "forbiddon": "You do not have permission to view this content" },
								"data": null
							}
						} else {

							this.dataToDisplay = {
								"headers": this.HeadersJson[riskType.risk_code],
								"data": this.riskDetails[riskType.risk_code],
							}
						}
						this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: true, class: 'auto_width' });
					}
				})
			}
		}
	}
	public patchedGetRiskDetails(riskType) {
		this.currentRisk = riskType
		this.riskType = riskType.risk_code;
		// this.modalHide();
		this.patchDataToDisplay = null;
		if (riskType.risk_code == 'miscellaneous') {
			this.patchedGetTopRisk()
			return
		}
		this.portfolioSurveyService.patchedGetRiskDetails(this.domain, riskType.risk_code).subscribe((data: any) => {
			this.setUserAccount(data['userAccount']);
			if (data && data['status'] == 'pass') {
				this.patchedRiskDetails[riskType.risk_code] = data['data']
				if (data['data'] == 'Forbidden') {
					this.patchDataToDisplay = {
						"headers": { "forbiddon": "You do not have permission to view this content" },
						"data": null
					}
				} else {

					this.patchDataToDisplay = {
						"headers": this.HeadersJson[riskType.risk_code],
						"data": this.patchedRiskDetails[riskType.risk_code]
					}
				}
			}
		})

	}

	getApiCall(risk_code) {
		this.portfolioSurveyService.getRiskDetails(this.domain, risk_code).subscribe((data: any) => {
			if (data && data['status'] == 'pass') {
				if (risk_code == 'vulnerabilities') {
					let dataToShow = this.calculateVulnerabilityRiskDetails(data);
					this.riskDetails[risk_code] = dataToShow;
				} else {
					this.riskDetails[risk_code] = data['data']
				}
				if (data['data'] == 'Forbidden') {
					this.dataToDisplay = {
						"headers": { "forbiddon": "You do not have permission to view this content" },
						"data": null
					}
				} else {

					this.dataToDisplay = {
						"headers": this.HeadersJson[risk_code],
						"data": this.riskDetails[risk_code]
					}
				}
			}
		})
	}

	getLeakedCredForRd(rd) {
		this.showSpinner = true;
		this.portfolioSurveyService.getLeakedCredentialsPatched(rd.domain, this.domain).subscribe((data: any) => {
			if (data.status == 'pass') {
				this.patchedBreachedCredentials = data['data']
			}
			this.showSpinner = false;
		}, error => {
			this.showSpinner = false;
		})
		this.portfolioSurveyService.getLeakedCredentialsforRd(rd.domain, this.domain).subscribe((data: any) => {
			if (data.status == 'pass') {
				this.breachedCredentials = data['data']
			}
			this.showSpinner = false;
		}, error => {
			this.showSpinner = false;
		})
	}
	calculateVulnerabilityRiskDetails(data) {
		let vulToShow = {};
		let keyCheck;
		data['data'].forEach(v => {
			keyCheck = v['subject_name'] ? v['subject_name'] : v['ip_address'] + "--" + v['target'];
			if (!vulToShow[keyCheck]) {
				vulToShow[keyCheck] = {
					'count': 1,
					'subject_name': v['subject_name'] ? v['subject_name'] : v['ip_address'],
					'service_name': v['target'] ? v['target'] : v['service_name'],
					'cve_id': [v['cve_id']],
					'ip_addresses': v['ip_address'] ? [v['ip_address']] : []
				}
			} else {
				if (v['ip_address'] && vulToShow[keyCheck]['ip_addresses'].indexOf(v['ip_address']) == -1) {
					vulToShow[keyCheck]['ip_addresses'].push(v['ip_address']);
				}
				vulToShow[keyCheck]['count'] = vulToShow[keyCheck]['count'] + 1;
				vulToShow[keyCheck]['cve_id'].push(v['cve_id'])
			}
		})
		let dataToShow = []
		Object.keys(vulToShow).forEach(e => {
			dataToShow.push(vulToShow[e])
		})
		return dataToShow;
	}

	public getHeaders(headers) {
		if (headers) {
			return Object.keys(headers).map(k => headers[k])
		}
	}
	public getHeaderKeys(headers) {
		if (headers) {
			return Object.keys(headers)
		}
	}

	public HeadersJson = {
		"leakedCreds": { "domain": "Domain", "leaked_credentials_count": "Leaked Credentials count" },
		"preprodServices": { "domain": "Domain", "type": "Type", "discovered_at": "Discovered At" },
		"exposedServices": { "ip_address": "IP Address", "service_name": "Service Name" },
		"exposedDatabase": { "ip_address": "IP Address", "service_name": "Service Name" },
		"takeoverRisk": { "domain": "Domain", "takeover_risk": "Takeover Risk" },
		"phishingDomains": { "fraud_domain": "Domain", "expires_at": "Expiring At" },
		"maliciousEntities": { "subject_name": "Ip/Domain", "host_name": "Domain/Netblock" },
		"vulnerabilities": { "subject_name": "Domain/Netblock", "service_name": "Technology/Service", "count": "#Vulnerabilities" },
		"miscellaneous": { "title": "Title", "source": "Source", "remediation": "Remediation" }
	}
	splitTopRiskSource(source) {
		this.sourceLocation = source ? source.split(",") : [];
		return this.sourceLocation
	}

	modalHide() {
		this.modalRef.hide()
	}
	public getTopRisk() {
		this.portfolioSurveyService.getTopRisk(this.domain).subscribe((data: any) => {
			this.topRisk = data.data
			this.riskCounts['miscellaneousCount'] = this.topRisk.length
			this.riskDetails['miscellaneous'] = this.topRisk
		})
	}
	public patchedGetTopRisk() {
		this.portfolioSurveyService.patchedGetTopRisk(this.domain).subscribe((data: any) => {
			this.patchedTopRisk = data.data
			this.patchedRiskDetails['miscellaneous'] = this.patchedTopRisk
			this.patchDataToDisplay = {
				"headers": this.HeadersJson['miscellaneous'],
				"data": this.patchedRiskDetails['miscellaneous']
			}
		})
	}

	// speedometer.....
	draw() {
		var gauge = (container, configuration) => {

			var config = {
				size: 710,
				clipWidth: 200,
				clipHeight: 110,
				ringInset: 20,
				ringWidth: 20,

				pointerWidth: 10,
				pointerTailLength: 3,
				pointerHeadLengthPercent: 0.9,

				minValue: 0,
				maxValue: 10,

				minAngle: -90,
				maxAngle: 90,

				transitionMs: 750,

				majorTicks: 4,
				labelFormat: d3.format('d'),
				labelInset: 10,
				arcColorFn: d3.scaleQuantile()
					.domain([0, 0.2, 0.4, 0.6,0.8])
					.range(['#4dbd74','#20a8d8', '#ffc107', '#f86c6b'])
				// arcColorFn: d3.interpolateHsl(d3.rgb('#4dbd74'), d3.rgb('#ffc107'),d3.rgb('#f86c6b'))
			};
			var range = undefined;
			var r = undefined;
			var pointerHeadLength = undefined;
			var value = 0;

			var svg = undefined;
			var arc = undefined;
			var scale = undefined;
			var ticks = undefined;
			var tickData = undefined;
			var pointer = undefined;

			var donut = d3.pie();

			function deg2rad(deg) {
				return deg * Math.PI / 180;
			}

			function newAngle(d) {
				var ratio = scale(d);
				var newAngle = config.minAngle + (ratio * range);
				return newAngle;
			}

			function configure(configuration) {
				var prop = undefined;
				for (prop in configuration) {
					config[prop] = configuration[prop];
				}

				range = config.maxAngle - config.minAngle;
				r = config.size / 2;
				pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

				// a linear scale this.gaugemap maps domain values to a percent from 0..1
				scale = d3.scaleLinear()
					.range([0, 1])
					.domain([config.minValue, config.maxValue]);

				ticks = scale.ticks(config.majorTicks);
				tickData = d3.range(config.majorTicks).map(() => { return 1 / config.majorTicks; });

				arc = d3.arc()
					.innerRadius(r - config.ringWidth - config.ringInset)
					.outerRadius(r - config.ringInset)
					.startAngle((d, i) => {
						var ratio = d * i;
						return deg2rad(config.minAngle + (ratio * range));
					})
					.endAngle((d, i) => {
						var ratio = d * (i + 1);
						return deg2rad(config.minAngle + (ratio * range));
					});
			}
			this.gaugemap.configure = configure;

			function centerTranslation() {
				return 'translate(' + r + ',' + r + ')';
			}

			function isRendered() {
				return (svg !== undefined);
			}
			this.gaugemap.isRendered = isRendered;

			function render(newValue) {
				svg = d3.select(container)
					.append('svg:svg')
					.attr('class', 'gauge')
					.attr('width', config.clipWidth)
					.attr('height', config.clipHeight);

				var centerTx = centerTranslation();

				var arcs = svg.append('g')
					.attr('class', 'arc')
					.attr('transform', centerTx);

				var labelNew = ['Low', 'Medium', "High","Critical"]
				arcs.selectAll('path')
					.data(tickData)
					.enter().append('path')
					.attr('fill', (d, i) => {
						return config.arcColorFn(d * i);
					})
					.attr('d', arc);

				var lg = svg.append('g')
					.attr('class', 'label')
					.attr('transform', centerTx);
				// lg.selectAll('text')
				//   .data(ticks)
				//   .enter().append('text')
				//   .attr('transform', (d)=> {
				//     var ratio = scale(d);
				//     var newAngle = config.minAngle + (ratio * range);
				//     return 'rotate(' + newAngle + ') translate(0,' + (config.labelInset - r) + ')';
				//   })
				//   .text(config.labelFormat);
				lg.append("text")
					.attr("dy", "-3em")
					.attr("dx", "-9.5em")
					.attr("text-anchor", "left")
					.text("Low");

				lg.append("text")
					.attr("dy", "-8.3em")
					.attr("dx", "-3.5em")
					.attr("text-anchor", "middle")
					.text("Medium");

				lg.append("text")
					.attr("dy", "-8.3em")
					.attr("dx", "3em")
					.attr("text-anchor", "middle")
					.text("High");

				lg.append("text")
					.attr("dy", "-3em")
					.attr("dx", "7em")
					.attr("text-anchor", "right")
					.text("Critical");

				var lineData = [[config.pointerWidth / 2, 0],
				[0, -pointerHeadLength],
				[-(config.pointerWidth / 2), 0],
				[0, config.pointerTailLength],
				[config.pointerWidth / 2, 0]];
				var pointerLine = d3.line().curve(d3.curveLinear)
				var pg = svg.append('g').data([lineData])
					.attr('class', 'pointer')
					.attr('transform', centerTx);

				pointer = pg.append('path')
					.attr('d', pointerLine/*(d)=> { return pointerLine(d) +'Z';}*/)
					.attr('transform', 'rotate(' + config.minAngle + ')');

				update(newValue === undefined ? 0 : newValue);
			}
			this.gaugemap.render = render;
			function update(newValue, newConfiguration?) {
				if (newConfiguration !== undefined) {
					configure(newConfiguration);
				}
				var ratio = scale(newValue);
				var newAngle = config.minAngle + (ratio * range);
				pointer.transition()
					.duration(config.transitionMs)
					.ease(d3.easeElastic)
					.attr('transform', 'rotate(' + newAngle + ')');
			}
			this.gaugemap.update = update;

			configure(configuration);

			return this.gaugemap;
		};
		var powerGauge = gauge('#power-gauge', {
			size: 350,
			clipWidth: 350,
			clipHeight: 350,
			ringWidth: 50,
			maxValue: 900,
			transitionMs: 2000,
		});
		powerGauge.render(this.overAllScore);
	}
	chartClicked(value) {

	}

	getElementsforCsv() {
		let result = this.allRiskDetails.filter(risk => risk.asset_count > 0);
		return result
	}
	checkDataAvailable(risk) {
		Object.keys(risk).forEach(r => {
			if (risk[r] > 0) {
				this.dataAvailable = true
			}
		});
	}
}
