'use strict';

export const portToTypeServiceName = {
    // "http/80": "Web Application",
    // "https/443": "Web Application",
    "pop3s/995": "Email",
    "imaps/993": "Internet Message Access",
    // "imap/143": "Internet Message Access",
    // "smtp/25": "Simple Mail Transfer",
    // "pop3/110": "Post Office",
    // "smb/445": "Server Message Block",
    // "dns/53": "Managed Cloud DNS",
    // "ftp/21": "File Transfer Protocol",
    // "ssh/22": "The Secure Shell (SSH) Protocol",
    // "mysql/3306": "MySQL",
    "http/8080": "HTTP Alternate",
    "20": "FTP",
    "21": "FTP",
    "22": "SSH",
    "23": "Telnet",
    "25": "SMTP",
    "50": "IPSec",
    "51": "IPSec",
    "53": "DNS",
    "67": "DHCP",
    "68": "DHCP",
    "69": "TFTP",
    "80": "HTTP",
    "110": "POP3",
    "119": "NNTP",
    "123": "NTP",
    "135-139": "NETBIOS",
    "143": "IMAP",
    "389": "Light Directory Access Protocol",
    "443": "HTTPS",
    "445": "SMB",
    "3389": "Remote Desktop Protocol",
    "5060": "SIP",
    "1433": "MSSQL",
    "1521": "ORACLE",
    "3306": "MySQL",
    "5432": "Postgres",
    "50000": "DB2 JDBC connection",
    "9200-9300": "elastic search",
    "5984": "Couch db",
    "27017": "Mongo db",
    "27018": "Mongo db",
    "1583": "Pervasive SQL",
    "3351": "Pervasive SQL",
    "6379": "Redis",
    "4369": "RebbitMQ",
    "9160": "Cassandra port",
}
export const technology_base_url = "https://static.firecompass.com/icons/";
export const reasonToDeleteFootPrint = [
    { name: "Does not belong to me" }, { name: "Other reason" }, { name: "Don't Show" }]
export const reasonToDeleteDigitalRisk = [{ name: "Does not belong to me" }, { name: "Fixed it" }, { name: "Other reason" }, { name: "Don't Show" }]

export const tags = [
	{
		"name": "owned",
		"selected": false,
		"value": []
	},
	{
		"name": "affiliate",
		"selected": false,
		"value": [],
		"childTags": ['saas', 'parked', 'reverse proxy', 'virtual hosting', 'aws']
	},
	{
		"name": "saas",
		"selected": false,
		"value": []
    },
    {
		"name": "aws",
		"selected": false,
		"value": []
	},
	{
		"name": "parked",
		"selected": false,
		"value": []
	},
	{
		"name": "reverse proxy",
		"selected": false,
		"value": []
	},
	{
		"name": "virtual hosting",
		"selected": false,
		"value": []
	},

];

export const legendDetail = [
    {
        name: 'Source',
        color: '#000000',
        typeof: 'source',
        show: false
    },
    {
        name: 'Domain',
        color: 'yellow',
        typeof: 'domain_name',
        show: false
    },
    {
        name: 'Error',
        color: '#D3D3D3',
        typeof: 'error',
        show: false
    },
    {
        name: 'Web Url',
        color: 'blue',
        typeof: 'web_url',
        show: false
    },
    {
        name: 'User',
        color: 'green',
        typeof: 'user_name',
        show: false
    },
    {
        name: 'Tag',
        color: 'red',
        typeof: 'tag',
        show: false
    },
    {
        name: 'DNS',
        color: '#F9E79F',
        typeof: 'dns_soa_record',
        show: false

    },
    // {
    // 	name: 'DNS',
    // 	color: '#E67E22',
    // 	typeof: 'dns_soa_record',
    // show: false

    // },
    {
        name: 'Internate Name',
        color: '#73C6B6 ',
        typeof: 'internet_name',
        show: false

    },
    {
        name: 'Vulnerability',
        color: '#E67E22',
        typeof: 'vulnerability',
        show: false

    },
    {
        name: 'Service',
        color: '#5D6D7E',
        typeof: 'service',
        show: false

    },
    {
        name: 'IPv6',
        color: '#D2B4DE  ',
        typeof: 'ipv6_address',
        show: false

    },
    {
        name: 'IP Address',
        color: '#34495E ',
        typeof: 'ip_address',
        show: false

    },
    {
        name: 'Web Grade',
        color: '#8E44AD',
        typeof: 'web_grade_params',
        show: false

    },


]
