import { Component, EventEmitter, Input, OnInit, Output, ViewChild,TemplateRef } from '@angular/core';
import { PortfolioSurveyService } from '../../../../_services/portfolio-survey.service';
import { CommonService } from '../../../../../app/_services/common.service';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { DownloadButtonComponent } from 'app/shared/download.component';
@Component({
  selector: 'app-network-assets-view',
  templateUrl: './network-assets-view.component.html',
  styleUrls: ['./network-assets-view.component.scss']
})
export class NetworkAssetsViewComponent implements OnInit {

  @Output() changeViewType: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateScopeFilter: EventEmitter<string> = new EventEmitter<string>();
  @Input() mainDomain: any;
  @Input() selectedScope:any = '';
  @Input() filters = {};
  @Input() totalCount = 0;
  @Input() assetWiseServiceVulnerabilities: Object = {};
	@ViewChild("filterBlock") filterBlock;
  @ViewChild("getAllNetworkVulnerabilitiesButton") getAllNetworkVulnerabilitiesButton: DownloadButtonComponent;

  // filter
  public openDropdown;
  public openActionDropdown;
  public allObsIns;
  public showThis = 'stateFilter';
  public scopeConfig: boolean = false;
  public serviceVulSubjectObsIns;
  public subjectType = 'service_cve';
  public filterScopes = [
      { selected: true, key: 'IN', value: 'In Scope' },
      { selected: false, key: 'OUT', value: 'Out of Scope' },
      { selected: false, key: 'NOT_DEFINED', value: 'Not Defined' },
      { selected: false, key: 'ALL', value: 'All' }
  ];
  public stateFinalFilter:any = ['open','ready_to_retest'];
  public filter_states = [
    { selected: true, key: 'open', value: 'Open' },
    { selected: true, key: 'ready_to_retest', value: 'Ready To Retest' },
    { selected: false, key: 'closed', value: 'Closed' },
    { selected: false, key: 'resolved', value: 'Resolved' },
    { selected:false, key: 'retest_in_progress', value: 'Retest In Progress' },
  ];
  public severitiesFinalFilter = [];
  public filter_severities = localStorage.getItem('info_vul_config')=="true"?
  [
  { selected:false, key: 'critical', value: 'Critical' },
  { selected:false, key: 'high', value: 'High' },
  { selected:false, key: 'medium', value: 'Medium' },
  { selected:false, key: 'low', value: 'Low' },
  { selected:false, key: 'info', value: 'Info' }
  ]:
  [
  { selected:false, key: 'critical', value: 'Critical' },
  { selected:false, key: 'high', value: 'High' },
  { selected:false, key: 'medium', value: 'Medium' },
  { selected:false, key: 'low', value: 'Low' },
  ];
  public validatedFinalFilter = [true,false];
  public validated_flags = [
    { selected: true, key: 'Validated', value: true },
    { selected: true, key: 'Not Validated', value: false },
  ];
  public ip_validated_flags = [
      { value: true, selected: false, key: 'Validated'},
      { value: false, selected: false, key: 'Not Validated'},
      { value: null, selected: true, key: 'All'},
  ];
  public ip_validated = null;
  public fieldsToDownload = {
		'Title': 'cve',
		'Description': 'summary',
		'Severity': 'severity',
		'IP': 'ip',
		'Port': 'port',
		'State': 'state',
		'Validated': 'validate',
		'Discovered At': 'discovered_at',
    'Last Seen At' : 'last_seen_at',
		'References': 'references',
                'IP Validated': 'ip_validated',
                'Observations': 'observation',
                'Insights': 'insight',
	}
  public allServiceVulnerbity:any = []
  public all_states = {
    'open': 'Open',
    'ready_to_retest': 'Ready To Retest',
    'closed': 'Closed',
    'resolved': 'Resolved',
    'retest_in_progress': 'Retest In Progress'
  };
  public stateComment = "";

  // List
  public ipsToShow: any = [];
  public showSpinner: boolean = false;
  public newRequest: any = null;
  public newRequestall: any = null;
  public page: number = 1;
  public limit: number = 10;
  public searchKey: any = null;
  public selectedTags;
  public selectedIsp;
  public showArchivedIps;
  public sortKey;
  public order;
  public ipTypeToFilter;
  public total_ips_count: number = 0;
  public selectAllRecord = false;
  public noOfSelectedRecord = 0;
  public noOfSelectedCve = 0;
  public confirmaModal: any = null;
  public multipleStateModal: any = null;
  public applyRetestConfirmModal:any = null;
  public currentCve: any = null;
  public manualIdArray : any = [];
  public multipleSelectedCveIdArray = [];
  public multipleCveWithManual=0
  public functionMultipleState = "open";
  public obsInsCountSpinner = false;
  public vulDetailForId;
  public networksIPAdressArray = []
  public retestParam: any = {
    "services": {
      "service_cve_ids": [],
      "cve_ids": [],
      "ips": []
    },
    "web_apps": {
      "application_cve_ids": [],
      "cve_ids": [],
      "application_urls": []
    }
  };
  public allFilterList:any = ['State', 'Validate'];
  public downloadLoading = false;
  public cve_id_text = false
  public pocSubjectComment;
  public impactServ:any = [];
	public editImpact = false;
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
		public toastr:ToastrService
  ) {

  }

  ngOnInit(): void {
    if(this.selectedScope){
			if(!this.allFilterList.includes('Scope')) this.allFilterList.push('Scope');
		}else{
			if(this.filterBlock){
				this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Scope');
			}
		}
    this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
			this.getIpsDetailsFromDeepc()
		});
  }

  public filterIpsByValidity(value: boolean) {
      this.ip_validated = value;
      if (value !== null) {
          if (!this.allFilterList.includes('IP Validated')) {
              this.allFilterList.push('IP Validated')
          }
      } else {
          this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'IP Validated');
      }
      this.getIpsDetailsFromDeepc();
  }
  // ngOnChanges() {
  //     if (this.assetWiseServiceVulnerabilities) {
  //         this.ipsToShow = this.assetWiseServiceVulnerabilities['data'];
  //         this.showSpinner = false;
  //         this.total_ips_count = this.assetWiseServiceVulnerabilities['count'];
  //         this.ipsToShow.map(ip => {
  //             ip.cvelimit = 3;
  //             ip.checked = false;
  //             ip.cves.map(cve => {
  //                 if (!cve.vulnerability_state) {
  //                     cve.vulnerability_state = 'open'
  //                 }
  //                 cve.vulnerability_state_new = cve.vulnerability_state;
  //                 cve.checked = false;
  //             })
  //             ip.cves = ip.cves.reduce((acc, current)=>{
  //                 const x = acc.find(item => item.cve_id === current.cve_id);
  //                 if (!x) {
  //                     return acc.concat([current]);
  //                 } else {
  //                     return acc;
  //                 }
  //             }, []);
  //         });
  //         this.selectAllIp();
  //     }
  // }

  getIpsDetailsFromDeepc() {
    this.newRequest ? this.newRequest.unsubscribe() : null
    this.showSpinner = true;
    this.ipsToShow = [];
    this.networksIPAdressArray = []
    // this.selectedIpType = this.ipTypeToFilter
    const params = {
      page: this.page,
      limit: this.limit,
      searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
      tags: this.selectedTags && this.selectedTags.length ? this.selectedTags : null,
      asn_names: this.selectedIsp && this.selectedIsp.length ? this.selectedIsp : null,
      ...this.filters,
      archive: this.showArchivedIps,
      view : "asset_view"
    }
    if (this.ip_validated !== null) {
        params['validated'] = this.ip_validated;
    }
    if (this.sortKey) params['sortKey'] = this.order == -1 ? this.sortKey : '-' + this.sortKey
    if (this.ipTypeToFilter != 'all') {
      params['ipType'] = this.ipTypeToFilter
    } else delete params['ipType']
    let stateString = '';
		if(this.stateFinalFilter.length){
			stateString = this.joinState(this.stateFinalFilter);			
		}else{
			let allFilter:any = [];
			this.filter_states.map(x=>allFilter.push(x.key));
			stateString = this.joinState(allFilter);
		}
    if (stateString) {
      params['state'] = stateString;
    }
    let severityString = this.joinState(this.severitiesFinalFilter);
    if (severityString) {
      params['severities'] = severityString;
    }
    let validatedString = this.joinState(this.validatedFinalFilter);
    if (validatedString) {
      params['validated_flags'] = validatedString;
    }
    if(this.selectedScope){
      params['scope'] = this.selectedScope;
    }

    this.newRequest = this.portfolioSurveyService.getIpsDetailsPaginated(this.mainDomain, params).subscribe(
      (data) => {
        this.newRequest = null;
        if (data['data']) {
          let profile: any = localStorage.getItem('profile');
          let parsedData = JSON.parse(profile);
          let account_type = parsedData.account_type;
          this.ipsToShow = data['data'];
          this.showSpinner = false;
          if(data['count']){
            this.total_ips_count = data['count'];
          }else{
            this.total_ips_count = this.ipsToShow[0]?.total ? this.ipsToShow[0]?.total : 0;
          }
          this.ipsToShow.map(ip => {
            ip.cvelimit = 3;
            ip.checked = false;
            ip.cves.map(cve => {
              cve.account_type = account_type
              cve.subjectTypeName = {
                "subject_type": this.subjectType,
                "subject_name": ' IP-' + cve.ip_address + ', CVE_Title-' + cve.cve_id,
                "subject_id": cve.service_cve_id  
              }
              let tagsList = cve.tags
              if(tagsList && tagsList.includes("MANUAL")){
                this.manualIdArray.push(cve.service_cve_id)
              }
              if (!cve.vulnerability_state) {
                cve.vulnerability_state = 'open'
              }
              cve.vulnerability_state_new = cve.vulnerability_state;
              cve.checked = false;
            })
            ip.cves = ip.cves.reduce((acc, current)=>{
              const x = acc.find(item => item.cve_id === current.cve_id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
          });
          this.selectAllIp();
          this.ipsToShow.forEach(service_vul => {
            let service_vuls = service_vul.cves
            service_vuls.forEach(vul_detail => {
              this.networksIPAdressArray.push(vul_detail.service_cve_id)
              })
            })
          if (this.networksIPAdressArray.length) {
            this.getObsInsCountsForEachId(this.networksIPAdressArray)
          }
        } else {
          this.showSpinner = false
        }
      },
      (error) => {
        this.newRequest = null;
        this.commonService.errorForDomain(error);
      }
    );
    this.filterCounter(this.mainDomain,params);
  }
  // For CSV Download
  loadDownloadJsonAllData() {
    const params = {
      page: this.page,
      limit: this.limit,
      searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
      tags: this.selectedTags && this.selectedTags.length ? this.selectedTags : null,
      asn_names: this.selectedIsp && this.selectedIsp.length ? this.selectedIsp : null,
      ...this.filters,
      archive: this.showArchivedIps,
      view : "asset_view"
    }
    if(this.ip_validated){
      params['ip_validated'] = this.ip_validated; 
    }
    if(!this.mainDomain) return;
    this.newRequestall ? this.newRequest.unsubscribe() : null;
    this.downloadLoading = true;
    var csvData: any = []
    if (params['page']) params['page'] = 1;
    if (params['limit']) params['limit'] = null;
    if(this.selectedScope) this.fieldsToDownload['Scope'] = "scope";
    this.allServiceVulnerbity = [];
    this.newRequestall = this.portfolioSurveyService.getServiceVulnerability(this.mainDomain, params).subscribe(vul => {
      this.showSpinner=true
      this.portfolioSurveyService.getAllObsAndIns(this.mainDomain, this.subjectType).subscribe((data) => {
        this.allObsIns = data['data']
        this.showSpinner = false
        csvData = vul['data'];
        this.newRequestall = null;
        if (csvData && csvData.length) {
          csvData.forEach(serVul => {
            let severity = this.commonService.confidenceLevel(serVul.cve_score)
            serVul.obs_arr = {}
						serVul.ins_arr = {}
            if (serVul.ips.length) {
              serVul.ips.forEach(ip => {
                  if (ip.services.length) {
                    ip.services.forEach(ser => {
                      var servicecve_id = ''
                      ip.servicecve.forEach(cve => {
                        let id = ser.id
                        if (id == cve.service_id) {
                          servicecve_id = cve.servicecve_id
                          serVul.obs_arr[servicecve_id] = []
                          serVul.ins_arr[servicecve_id] = []
                        }
                      });
                      this.allObsIns.forEach(obsIns => {
                        if (obsIns.subject_id == servicecve_id) {
                          serVul.obs_arr[servicecve_id].push(obsIns.observation)
                          serVul.ins_arr[servicecve_id].push(obsIns.insight)
                        }
                      })
                      this.allServiceVulnerbity.push({
                          ...ser, ...{
                              cve: serVul.cve_id,
                              summary: serVul.cve_summary,
                              severity: severity,
                              impact: serVul.impact,
                              remediation: serVul.remediation,
                              //discovered_at: serVul.discovered_at,
                             //last_seen_at: serVul.servicecve_updated,
                              references: serVul.references,
                              ip: ip.ip_address,
                              scope: ip.scope,
                              ip_validated: ip.ip_validated ? 'Yes': 'No',
                              validate: this.getValidateState(ip.servicecve, ser.id),
                              observation: serVul.obs_arr[servicecve_id],
                              insight: serVul.ins_arr[servicecve_id],
                              ...this.getServiceCveDates(ip.servicecve, ser.id)
                          }
                      })
                    })
                  }
              });
            }
          })
          this.getAllNetworkVulnerabilitiesButton.download();
          this.allServiceVulnerbity = []
        }
        this.downloadLoading = false;
      }, error => {
        this.showSpinner = false;
      })
    })
  }
  // Return Validated Flag
	getValidateState(list,id){
		if(list && list.length>0){
			let vaidated = false;
			list.map((x)=>{
				if(x.service_id==id){
					vaidated = x.validated;
				}
			})
			return (vaidated)?"Yes":"No";
		}else{
			return 'No';
		}
	}

  getServiceCveDates(list,id){
		if(list && list.length>0){
			let data_info  = {};
			list.map((x)=>{
				if(x.service_id==id){
					data_info = {
						'discovered_at' : x.discovered_at,
						'last_seen_at' : x.updated_at
					}
					
				}
			})
			return data_info
		}
	}

  goToPage(val) {
    // this.select10IPs = false
    this.page = val;
    this.getIpsDetailsFromDeepc();
  }
  checkUncheckCve(ip) {
    let checkedStatus = ip.checked;
    if (!checkedStatus) this.selectAllRecord = false;
    if (ip.cves) {
      ip.cves.map(x => x.checked = checkedStatus)
    }
    this.getSelectedRecords();
  }
  selectAllIp() {
    if (this.selectAllRecord) {
      this.ipsToShow.map(x => {
        x.checked = true;
        this.checkUncheckCve(x);
      });
    } else {
      this.ipsToShow.map(x => {
        x.checked = false;
        this.checkUncheckCve(x);
      });
    }
    this.getSelectedRecords();
  }
  getSelectedRecords() {
    let selectedCve:any = [];
    this.multipleCveWithManual = 0
    this.multipleSelectedCveIdArray = [];
    this.noOfSelectedRecord = 0;
    this.noOfSelectedCve = 0;
    this.ipsToShow.map(x => {
      if (x.checked) {
        this.noOfSelectedRecord = this.noOfSelectedRecord + 1;
      }
      x.cves.map(cve => {
        if(cve.checked){
          this.multipleSelectedCveIdArray.push(cve.service_cve_id)
          this.noOfSelectedCve = this.noOfSelectedCve + 1;
          if(this.manualIdArray.includes(cve.service_cve_id) && this.functionMultipleState=="ready_to_retest"){}
          else{
            selectedCve.push(cve.service_cve_id);
          }
          if(this.manualIdArray.includes(cve.service_cve_id)){
            this.multipleCveWithManual = this.multipleCveWithManual +1
          }
        }
      })
    });
    return selectedCve;
  }
  getState(state) {
    if (state) {
      return state;
    } else {
      return 'open';
    }
  }
  // State Change
  changeStateMultiple(modalTemplate,changeStateMultipleTemplateWithManual){
    if(this.multipleCveWithManual >=1){
      this.multipleStateModal = this.modalService.show(changeStateMultipleTemplateWithManual, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }
    else if(!this.multipleCveWithManual ){
      this.multipleStateModal = this.modalService.show(modalTemplate, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }
  }
  validMultipleState(){
    if(this.noOfSelectedCve>0){
      if (this.functionMultipleState != 'open') {
        return (this.strTrim(this.stateComment)) ? true : false;
      } else {
        return true;
      }
    }else{
      return false;
    }
  }
  stateChanged(cve, confirmTemplate) {
    this.currentCve = cve;
    // console.log(cve);
    this.confirmaModal = this.modalService.show(confirmTemplate, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  cancleStateChange() {
    let that = this;
    setTimeout(function () {
      that.currentCve.vulnerability_state_new = that.currentCve.vulnerability_state;
      that.stateComment = "";
    }, 500);
  }
  cancleStateChangeMultiple(){
    if(this.multipleStateModal) { this.multipleStateModal.hide(); }
    this.stateComment = "";
  }
  validState() {
    let thisState = this.currentCve.vulnerability_state_new;
    if (thisState != 'open') {
      return (this.strTrim(this.stateComment)) ? true : false;
    } else {
      return true;
    }
  }
  strTrim(str = '') {
    return str.trim();
  }
  saveStateMultiple(){
    let payload:any = {
      "ids": this.getSelectedRecords(),
      "state": this.functionMultipleState,
      "comment": this.stateComment
    }
    console.log(payload);
    this.saveState(payload);
  }
  saveState(multipleParam=false) {
    let payload:any = {};
    if(multipleParam){
      payload = multipleParam;
    }else{
      payload = {
        "ids": [this.currentCve.service_cve_id],
        "state": this.currentCve.vulnerability_state_new,
        "comment": this.stateComment
      }
    }
    this.portfolioSurveyService.updateState('service', payload, this.mainDomain).subscribe((data: any) => {
      if (data.status == "pass") {
        if (data.message) {
          this.toastr.success('CVE state updated successfully');
        }
        if(this.confirmaModal){ this.confirmaModal.hide(); this.cancleStateChange(); } 
        if(this.multipleStateModal){ this.multipleStateModal.hide(); this.cancleStateChangeMultiple(); } 
        this.getIpsDetailsFromDeepc();
      } else {
        console.log(data);
        this.toastr.info("Unable to update state.");
      }
    },(error) => {
      console.log(error);
      this.toastr.error("Something went wrong.");
    });
  }
  
  // Filter
  hangeSeverityFilter(state) {
    this.severitiesFinalFilter = [];
    this.filter_severities.map((x) => {
      if (x.selected) this.severitiesFinalFilter.push(x.key);
    });
  }
  filterSubDomainBySeverities(action) {
    if (action == 'clear') {
      this.selectAllRecord = false;
      this.severitiesFinalFilter = [];
      this.filter_severities.map((x) => {
        x.selected = false
      });
      this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Severities');
    }
    // Filter send to api
    this.page = 1
    this.getIpsDetailsFromDeepc();
    
  }
  joinState(states, commaSep = true) {
    if (states.length) {
      let state_string = "";
      if (commaSep) {
        state_string = '"' + states.join('","') + '"';
      } else {
        state_string = states.join('');
      }
      return state_string;
    } else {
      return "";
    }
  }
  changeState(filter, index) {
    // console.log(filter, index);
    if (filter == 'severity') {
      this.filter_severities.map(x => {
        x.selected = false;
      });
      this.filter_severities[index].selected = true;
      this.changeSeverityFilter(this.filter_severities[index].key);
      this.filterSubDomainByState('clear');
      this.page = 1
      this.getIpsDetailsFromDeepc();
    }
    if (filter == 'state') {
      this.filter_states.map(x => {
        x.selected = false;
      });
      this.filter_states[index].selected = true;
      this.changeStateFilter(this.filter_states[index].key);
      this.filterSubDomainBySeverities('clear');
      this.page = 1
      this.getIpsDetailsFromDeepc();
    }
  }
  filterSubDomainByState(action = '') {
    if (action == 'clear') {
      this.stateFinalFilter = [];
      this.filter_states.map((x) => {
        x.selected = false
      });
      this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'State');
    }
    // Filter send to api
    this.page = 1
    this.getIpsDetailsFromDeepc();
    
  }
  detectFilters(){
		this.allFilterList = this.filterBlock.checkState(this.stateFinalFilter,this.allFilterList,'State');
		this.allFilterList = this.filterBlock.checkState(this.severitiesFinalFilter,this.allFilterList,'Severities');
    this.allFilterList = this.filterBlock.checkState(this.validatedFinalFilter,this.allFilterList,'Validate');
	}
  changeStateFilter(state) {
    this.stateFinalFilter = [];
    this.filter_states.map((x) => {
      if (x.selected) this.stateFinalFilter.push(x.key);
    });
    this.detectFilters();
  }
  changeSeverityFilter(state) {
    this.severitiesFinalFilter = [];
    this.filter_severities.map((x) => {
      if (x.selected) this.severitiesFinalFilter.push(x.key);
    });
    this.detectFilters();
  }
  // Search
  searchVulnerability() {
    this.page = 1;
    this.getIpsDetailsFromDeepc();
    this.selectAllRecord = false;
    
  }
  clearSearch() {
    this.searchKey = null;
    this.page = 1;
    this.getIpsDetailsFromDeepc();
    this.selectAllRecord = false;
    this.selectAllRecord = false;
  }
  resetRetestValue(){
    this.retestParam = {
      "services": {
        "service_cve_ids": [],
        "cve_ids": [],
        "ips": []
      },
      "web_apps": {
        "application_cve_ids": [],
        "cve_ids": [],
        "application_urls": []
      }
    };
  }
  applyRetest(modalTemplate){
    this.resetRetestValue();
    let ip:any = [];
    let cve_id:any = [];
    let service_cve_id:any = [];
    this.cve_id_text = false
    this.ipsToShow.map(x => {
      x.cves.map(cve => {
        if(cve.checked && cve.vulnerability_state=='ready_to_retest'){
          if (this.cve_id_text != true){
            if (this.isValidCve(cve.cve_id) == false){
              this.cve_id_text = true
            }
          }
          if(!ip.includes(x.ip_address)){
            ip.push(x.ip_address);
          }
          if(!cve_id.includes(cve.cve_id)){
            cve_id.push(cve.cve_id);
          }
          if(!service_cve_id.includes(cve.service_cve_id)){
            service_cve_id.push(cve.service_cve_id);
          }
        }
      })
    });
    this.retestParam.services.service_cve_ids = service_cve_id,
    this.retestParam.services.cve_ids = cve_id,
    this.retestParam.services.ips = ip,
    // console.log(this.retestParam);
    // this.submitForReset(this.retestParam);
    this.applyRetestConfirmModal = this.modalService.show(modalTemplate, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  submitForReset(param={}){
    let profile:any = localStorage.getItem('profile');
    let parsedData = JSON.parse(profile);
    let customer_id = parsedData.cust_id;
    this.openActionDropdown = false;
    this.applyRetestConfirmModal.hide();
    if(param['web_apps']) delete param['web_apps'];
    this.portfolioSurveyService.triggerReset(this.mainDomain, param, customer_id).subscribe((data: any) => {
      // console.log(data);
      this.toastr.success("Applied for retest successfully.");
    },(error) => {
      console.log(error);
      this.toastr.error("Something went wrong.");
    });
  }
  isValidCve(cve_id){
    const regexExp = /CVE-\d{4}-\d{4,7}/
    const found = cve_id.match(regexExp);
    return (found)?true:false
  }
  filterCounter(mainDomain,params){
		// let params:any = {
    //   searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
		// 	...this.filters
		// }
		// let stateString = '';
		// if(this.stateFinalFilter.length){
		// 	stateString = this.joinState(this.stateFinalFilter);			
		// }else{
		// 	let allFilter:any = [];
		// 	this.filter_states.map(x=>allFilter.push(x.key));
		// 	stateString = this.joinState(allFilter);
		// }
		// if(stateString){
		// 	params['state'] = stateString;
		// }
		// let severityString = this.joinState(this.severitiesFinalFilter);
		// if(severityString){
		// 	params['severities'] = severityString;
		// }
    if (this.ip_validated !== null) {
        params['ip_validated'] = this.ip_validated;
    }
		this.portfolioSurveyService.getServiceChartData(mainDomain, params).subscribe((data: any) => {
			// console.log(data);
			let totalCount = 0;
			if(data['by_severity']){
				Object.keys(data['by_severity']).forEach(function (key) {
					totalCount = totalCount + data['by_severity'][key] || 0;
				});
			}
			this.totalCount = totalCount;
			console.log('NewCount',this.totalCount);
		}, (error) => {
			console.log(error);
		});
	}

  changeValidatedFilter() {
    this.validatedFinalFilter = [];
    this.validated_flags.map((x) => {
      if (x.selected) this.validatedFinalFilter.push(x.value);
    });
    this.detectFilters();
  }
  filterValidatedCve(action) {
    if (action == 'clear') {
      this.selectAllRecord = false;
      this.validatedFinalFilter = [];
      this.validated_flags.map((x) => {
        x.selected = false
      });
      this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Validate');
    }
    // Filter send to api
    this.page = 1
    this.getIpsDetailsFromDeepc();
    
  }
  getObsInsCountsForEachId(arrayList) {
      this.obsInsCountSpinner = true
      const params = {
        subject_ref_list: arrayList,
        subject_type: this.subjectType
      }
      this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain, params).subscribe(data => {
        this.obsInsCountSpinner = false
        this.vulDetailForId = data['data']
        this.ipsToShow.forEach((service_vul) => {
          let service_vuls = service_vul.cves
          service_vuls.forEach(vul_detail => {
            let service_cve_id = vul_detail.service_cve_id
            if (this.vulDetailForId[service_cve_id]) {
              vul_detail.obs_count = this.vulDetailForId[service_cve_id].count_obs
              vul_detail.ins_count = this.vulDetailForId[service_cve_id].count_ins
            }
          })
        })
      })
      }
  openCommentsModule(template: TemplateRef<any>, cve) {
    let profile: any = localStorage.getItem('profile');
		let parsedData = JSON.parse(profile);
		let account_type = parsedData.account_type;
    this.pocSubjectComment = {
      "subject_type": cve['subjectTypeName']['subject_type'],
      "subject_name": cve['subjectTypeName']['subject_name'].trim(),
      "subject_id": cve['subjectTypeName']['subject_id'],
      "visible_to": account_type
    }
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  openImpact(template: TemplateRef<any>, ser) {
    console.log(ser);
		this.editImpact = false;
		this.impactServ = ser;
    console.log(this.impactServ);
		// this.impactServ['impact'] = 'Lorem Ipsome';
		if(!this.impactServ['impact']) this.editImpact = true;
		this.modalRef = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}
	updateImpact(){
		let ip = this.impactServ;
		let payload = {
			"ids":[ip['service_cve_id']],
			"impact":ip['impact']
		}
		this.portfolioSurveyService.updateState('service',payload, this.mainDomain).subscribe((data:any) => {
			if(data.status=="pass"){
				if(data.message){
					this.editImpact = false;
					this.toastr.success('Impact updated successfully');
				}
			}else{
				this.toastr.info("Unable to update impact.");
			}
		},
		(error) => {
			console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
  deleteImpct(){
		let ip = this.impactServ;
		let payload = {
			"ids":[ip['service_cve_id']],
			"impact":null
		}
		this.portfolioSurveyService.updateState('service',payload, this.mainDomain).subscribe((data:any) => {
			if(data.status=="pass"){
				if(data.message){
					this.editImpact = true;
					this.toastr.success('Impact deleted successfully');
					ip['impact'] = null;
				}
			}else{
				this.toastr.info("Unable to delete impact.");
			}
		},
		(error) => {
			console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
}
