import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'iterateobject'
})
export class IterateObjectPipe implements PipeTransform {
	transform(value: any): any {
		if (value) {
			return Object.keys(value).map(key => ({
				key: key,
				value: value[key]
			}));
		}
	}
}