import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(array: any, keys: any, searchKey: string) {
        if (!array || array.length <= 0 || !keys || keys.length <= 0 || !searchKey || searchKey.length<=0) {
            return array;
        }
        var searchKeys = searchKey.split(",");
        var arrayToSend = [];
        searchKeys.forEach(search => {
            if (keys && keys.length != 0) {
                if (typeof (keys) == 'string') {
                    array.forEach(element => {
                        if (element[keys] && element[keys].toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                            arrayToSend.push(element);
                        }
                    });
                } else if (typeof (keys) == 'object') {
                    array.forEach(element => {
                        keys.some(key => {
                            var elementTOSearch = element[key] ? JSON.stringify(element[key]) : '';
                            if (elementTOSearch && elementTOSearch.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                                arrayToSend.push(element);
                                return true;
                            } else {

                            }
                        });
                    })
                }
            }
        })
        return arrayToSend;
    }
}
