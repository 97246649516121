import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../_services/common.service';
@Component({
  selector: 'app-ip-details',
  templateUrl: './ip-details.component.html',
  styleUrls: ['./ip-details.component.scss']
})
export class IpDetailsComponent implements OnInit {

  @Input() mainDomain: any;
  @Input() ips: any;
  public oldIps: any;
  public showThis;
  public seeMore = 10;
  public startDay = 7;
  public endDay = 30;
  public openSlider = false;
  public infoTagDay = 7;
  public openPorts=[
	{
		'port':'80',
		'count':'22'
	},
	{
	'port':'443',
	'count':'12'
	},
	{
		'port':'8080',
		'count':'32'
	},
	{
		'port':'800',
		'count':'22'
	},
	{
		'port':'080',
		'count':'22'
	},
	{
		'port':'850',
		'count':'22'
	},
	{
		'port':'830',
		'count':'22'
	},
	{
		'port':'820',
		'count':'22'
	},
	{
		'port':'810',
		'count':'22'
	},
	{
		'port':'890',
		'count':'22'
	},
	{
		'port':'880',
		'count':'22'
	},

  ]
  public domainWithIp=[	
{
	'name':'firecompass.com',
	'count':'20'
},
{
	'name':'amazon.com',
	'count':'30'
},
{
	'name':'flipkart.com',
	'count':'10'
},
{
	'name':'nykaa.com',
	'count':'20'
},
{
	'name':'sisoplatform.com',
	'count':'204'
},
{
	'name':'fireshadows.com',
	'count':'203'
},
{
	'name':'axis.com',
	'count':'210'
},
{
	'name':'bnb.com',
	'count':'220'
},
{
	'name':'sbi.com',
	'count':'20'
},
{
	'name':'png.com',
	'count':'20'
},
{
	'name':'pnb.com',
	'count':'20'
},

  ]
  public seeMorePorts:boolean= false;
  public seeMoreDomain:boolean=false;
  public topResultPort=3;
  public topResultDomain=3;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.ipDetails();
  }

  ipDetails() {
    if (this.ips && this.ips.length > 0) {
      this.ips.forEach((element, index) => {
        if (element['ip_details'] && element['ip_details']['status'] == 'pass' && element['ip_details']['data'] && element['ip_details']['data'].length > 0) {
          element['new_ip_details'] = element['ip_details']['data'][0];
          try {
            var data = element['new_ip_details']['443']['https']['tls']['certificate']['parsed']['extensions']['subject_alt_name'].dns_names;
            element['new_ip_details'].searchResult = data;
            var name = element['new_ip_details']['443']['https']['tls']['certificate']['parsed']['names'];
            element['new_ip_details'].name = name;
            var version = element['new_ip_details']['443']['https']['tls']['version'];
            element['new_ip_details'].version = version;
            var cipher_suite = element['new_ip_details']['443']['https']['tls']['cipher_suite']['name'];
            element['new_ip_details'].cipher_suite = cipher_suite;
            var validation = element['new_ip_details']['443']['https']['tls']['validation']['browser_trusted'];
            element['new_ip_details'].validation = validation;
          } catch (err) {

          }
          try {
            var heartbleed = element['new_ip_details']['443']['https']['heartbleed']['heartbeat_enabled'];
            if (heartbleed) {
              element['new_ip_details'].heartbleed = "Not OK";
            } else {
              element['new_ip_details'].heartbleed = "OK";
            }
          } catch (err) {

          }
          try {
            if (element['new_ip_details']['443']['https']['dhe_export']) {
              var exportDhe = element['new_ip_details']['443']['https']['dhe_export']['support'];
              element['new_ip_details'].exportDhe = exportDhe;
            } else {
              element['new_ip_details'].exportDhe = '-';
            }
            if (element['new_ip_details']['443']['https']['rhe_export']) {
              var exportRhe = element['new_ip_details']['443']['https']['rhe_export']['support'];
              element['new_ip_details'].exportRhe = exportRhe;
            } else {
              element['new_ip_details'].exportRhe = '-';
            }
            if (element['new_ip_details']['443']['https']['dhe']) {
              var dheSupport = element['new_ip_details']['443']['https']['dhe']['support'];
              element['new_ip_details'].dheSupport = dheSupport;
            } else {
              element['new_ip_details'].dheSupport = '-';
            }
          }
          catch (err) {
            console.log(err);
          }
          try {
            var parsed = element['new_ip_details']['443']['https']['tls']['certificate']['parsed'];
            element['new_ip_details'].parsed = parsed;
          } catch (err) {
          }
          try {
            var chain = element['new_ip_details']['443']['https']['tls']['chain']
            element['new_ip_details'].chain = chain;
          } catch (err) { }
        } else {
          element['new_ip_details'] = null;
        }
      });
      this.oldIps = this.ips.slice();
    }
  }

}
