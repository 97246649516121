import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";

@Component({
  selector: "app-manage-vendor-domains",
  templateUrl: "./manage-vendor-domains.component.html",
  styleUrls: ["./manage-vendor-domains.component.scss"],
})
export class ManageVendorDomainsComponent implements OnInit {
  constructor(
    public router: Router,
    public portfolioSurveyService: PortfolioSurveyService
  ) {}
  public domain;
  public previousDomains;
  public showSpinner;
  public showError;
  public showUpgradeWedgit = false;
  public userAllowed = false;
  public freeTrials = 1;
  public myDomain;
  public businessCriticalValue: string = "medium";
  public sortWith = "domain";
  public order;

  ngOnInit() {
    var profile = JSON.parse(localStorage.getItem("profile"));
    if (profile && profile["app_metadata"]) {
      var email = profile.email;
      this.myDomain = email.split("@")[1];
      localStorage.setItem("mydomain", this.myDomain);
      var roles = profile["app_metadata"]["roles"];
      var index = roles.indexOf("osint");
      if (index >= 0) {
        this.userAllowed = true;
      }
      var indexadmin = roles.indexOf("admin");
      if (indexadmin >= 0) {
        this.userAllowed = true;
        this.freeTrials = 400;
      }
    }
    // this.showSpinner = true;
    this.getUserVendorDomains();
  }
  public searchOldDomain(domain) {
    this.showSpinner = true;
    // localStorage.removeItem("domain");
    // localStorage.setItem("domain",domain);
    // this.router.navigateByUrl('/dashboard');
    this.router.navigate(["/dashboard"], { queryParams: { domain: domain } });
  }

  public searchDomain(domain, type) {
    if (domain && this.businessCriticalValue) {
      this.showSpinner = true;
      this.domain = {
        domain: domain.toLowerCase(),
        businessCriticality: this.businessCriticalValue,
      };
      this.portfolioSurveyService
        .addDomainSearchForUser(this.domain)
        .subscribe((data) => {
          this.showSpinner = false;
          // localStorage.removeItem("domain");
          // localStorage.setItem("domain", domain);
          // localStorage.removeItem("mydomain");
          // localStorage.setItem("mydomain", this.myDomain);
          if (type === "myDomain") {
            this.router.navigate(["/new/show-external-score"], {
              queryParams: { domain: domain },
            });

            // this.router.navigateByUrl('/new/vendor-dashboard');
          } else {
            this.router.navigate(["/new/show-external-score"], {
              queryParams: { domain: domain },
            });

            // this.router.navigateByUrl('/new/vendor-dashboard')
          }
        });
      // console.log(this.domain);
    } else {
      alert("Please enter the domain name");
    }
  }
  removeDomain(domain) {
    this.portfolioSurveyService
      .deleteDomainForUser(domain)
      .subscribe((data: any) => {
        console.log(data);
        if (data) {
          var indexOld = this.previousDomains
            .map(function (e) {
              return e.id;
            })
            .indexOf(data.id);
          console.log(indexOld);
          this.previousDomains.splice(indexOld, 1);
        }
      });
  }
  getUserVendorDomains() {
    this.portfolioSurveyService.getDomainsSearchForUser().subscribe(
      (data) => {
        this.previousDomains = data;
        // this.previousDomains.forEach(pDomain => {
        //   this.portfolioSurveyService.getAllDetailsOsint(pDomain.domain).subscribe(data => {
        //     console.log(data);
        //     if(data && data.webDetails && data.webDetails.analyze) {
        //       pDomain['webSecurityScore'] = data.webDetails ? data.webDetails.analyze.grade : null;
        //     } else if(data.webDetails && JSON.parse(data.webDetails).error === "invalid-hostname") {
        //       pDomain['webSecurityScore'] = "NA"
        //     }
        //     if(data && data.osintDetails) {
        //       var phishingData = data.osintDetails.phishingData;
        //       pDomain['phishingDomains'] = phishingData.phishingDomains.results['DomainListSize'];
        //     }
        //   })
        //   this.portfolioSurveyService.getBreachedPassByDomain(pDomain.domain).subscribe(data => {
        //     if(data) {
        //       pDomain['leakedCredentials'] = data[0][0]['total'];
        //     }
        //   }, error => {
        //     console.log(error)
        //   })
        // });
        this.showSpinner = false;
      },
      (error) => {
        this.showSpinner = false;
        this.showError =
          "Error while connecting to server! Please try after some time.";
      }
    );
  }

  updateBusinessCriticalityForDomain(domain) {
    this.portfolioSurveyService
      .updateDomainsForUser(domain)
      .subscribe((data) => {
        console.log(data);
      });
  }
}
