import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {

  public peopleDetails = [{
    "name": "Sourabh Kumar",
    "profile": 'https://media.licdn.com/dms/image/C5603AQGhgAkj9YmuGQ/profile-displayphoto-shrink_800_800/0?e=1568246400&v=beta&t=18zx0HcOc3SsLta1tIr9iUtQvagh3wyV9b2Zes5OAUo',
    "email": 'snandecha@gmail.com',
    "fb_profile": "https://www.facebook.com",
    "linkedIn_profile": 'https://linkedin.com/in/sourabhk45',
    "twitter_profile": 'https://www.twitter.com',
    'status': ["5 Sent", "3 Cliked", '2 Breached'],
    "credentials": [{"username": "subrabhkumar", "pass": "sd****123", "icon":"fb"}],
    "weaknesses": ["3", "4", "5", "1"]
  },
  {
    "name": "Tapadhir Bhattacharya",
    "profile": 'https://media.licdn.com/dms/image/C5103AQFPJDYykX_nXA/profile-displayphoto-shrink_800_800/0?e=1568246400&v=beta&t=Czb08FgbXcUEEmhNH1V7O1Wu2Q939MLVHSC-9_hWdQU',
    "email": 'snandecha@gmail.com',
    "fb_profile": "https://www.facebook.com",
    "linkedIn_profile": 'https://linkedin.com/in/tapadhirbhattacharya',
    "twitter_profile": 'https://www.twitter.com/tapadhir1981',
    'status': ["5 Sent", "0 Cliked", '0 Breached'],
    "credentials": [{"username": "Tbhattacharya", "pass": "tb****2as", "icon":"linkedin"}, {"username": "subhnand", "pass": "sd****123", "icon":"fb"}],
    "weaknesses": ["5", "3", "4", "5"]
  },
  {
    "name": "Manoj K Mishra",
    "profile": 'https://media.licdn.com/dms/image/C4E03AQEyLk3a8FjXAA/profile-displayphoto-shrink_800_800/0?e=1568246400&v=beta&t=JN1VBwRaDXaXDvsrcmkhrF91thZl7s1OZcraGSYAvAo',
    "email": 'snandecha@gmail.com',
    "fb_profile": "https://www.facebook.com",
    "linkedIn_profile": 'https://linkedin.com/in/manojkmishra/',
    "twitter_profile": 'https://www.twitter.com',
    'status': ["7 Sent", "1 Cliked", '0 Breached'],
    "credentials": [{"username": "manojmishra", "pass": "mas****na1", "icon":"fb"}],
    "weaknesses": ["3", "5", "4", "5"]
  },
  {
    "name": "Ranjith (P) Ebanezar",
    "profile": 'https://media.licdn.com/dms/image/C5103AQGmUIkdliSyCQ/profile-displayphoto-shrink_800_800/0?e=1568246400&v=beta&t=ISX7TTUlTbeYmHKPW-3YnH9lg0WUWx3xWcopYI1IZBs',
    "email": 'snandecha@gmail.com',
    "fb_profile": "https://www.facebook.com",
    "linkedIn_profile": 'https://linkedin.com/in/ranjith-ebanezar-4549a7153/',
    "twitter_profile": 'https://www.twitter.com',
    'status': ["6 Sent", "3 Cliked", '0 Breached'],
    "credentials": [{"username": "rrnjith1", "pass": "ra*****21#", "icon":"fb"}],
    "weaknesses": ["7", "3", "4", "5"]
  }]

  public newProfile = {};
  constructor(
    private modalService: BsModalService,
    private modalRef: BsModalRef,
  ) { }

  ngOnInit() {
  }
  deleteThis(indes) {
    console.log(indes)
    this.peopleDetails.splice(indes, 1);
  }
  openAddNew(template: TemplateRef<any>, ip = {}) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
  }
  submitProfile() {
    if (this.newProfile['name'] && this.newProfile['email']) {
      // this.peopleDetails.unshift(this.newProfile);
      this.modalRef.hide();
    }
  }
}
