import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import {Router, Route, ActivatedRoute} from "@angular/router";
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
import { BsModalService,BsModalRef} from 'ngx-bootstrap/modal';
// import { BsModalRef } from 'ngx-bootstrap';
import { FileUtil }from './file.util';
import { Constants } from './test.constants';
@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  public MyModal;
  public selectedProject;
  public questions;
  public customQuestions = [];
  public showQue:boolean=false
  public showProject:boolean=false;
  public vendor_assessment={};
  public templateList;
  public responseType;
  public selectedResponceType;
  public selectedTemplateType;
  public addedOptions=[];
  public templateType;
  public que_set={};
  public vendor_project=[];
  public projects=[];
 public isChecked:boolean = false;
 public hideCheckBox:boolean= false;
 public vendorsList=[];
 public allVendorsForUser = [];
 public profile;  
 public customSections=[];
 public predefinedSections=[];
 public updateData:boolean= false;
 modalRef: BsModalRef;
 @ViewChild('fileImportInput')  fileImportInput: ElementRef;
 
  csvRecords = [];
 constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public router: Router,
    public route: ActivatedRoute,
    public modalService: BsModalService,
    public _fileUtil: FileUtil
  ) { }
  ngOnInit() {

    this. templateList=["SIG","PCI-DSS","ISO-27001"];
    this.responseType=["Yes/No","Text","Options"]
    this.vendor_assessment['maturity']=false;
    this.profile = JSON.parse(localStorage.getItem('profile'));
    this.portfolioSurveyService.getQuestionSetByEmail(this.profile.email).subscribe((data:any) => {
      this.projects=data;
    })
    
    this.portfolioSurveyService.getDomainsSearchForUser().subscribe((data:any) => {
      this.vendorsList = data;
      this.allVendorsForUser = Object.assign(data);
    })
  }
  openModel(myModel) {
    this.modalRef = this.modalService.show(myModel);
}
  getDate(dataTime) {
   if(dataTime){
     return dataTime.split('T')[0];
   }  
  }
  getpreDefine(template) {
    console.log("template...",template)
    this.templateType=template;
    this.vendorsList = this.allVendorsForUser
    this.portfolioSurveyService.getPreDefineQuestionByTemplate(template).subscribe(data => {
      this.questions=data
      this.questions.forEach(element => {
        element.checked = true;
        if(this.predefinedSections.findIndex( x => x.section_name == element.section_name) >= 0) {
        } else {
          this.predefinedSections.push({
            "section_name" : element.section_name,
            "section_sub_type": element.sub_type
          })
        }
      });
      this.showProject=true;
    })
    if(template=='Custom Questions'){
    this.showQue=true;
    }
    // this.portfolioSurveyService.getDomainsSearchForUser().subscribe(data => {
    //   this.vendorsList = data;
    // })
  }

getTemplate(type){
  this.selectedTemplateType=type;
}
getResposeType(type){
  this.selectedResponceType=type;
  if(type === 'Options') {
    this.vendor_assessment['options'] = [];
    this.addedOptions = this.vendor_assessment['options'];
  } else {
    this.vendor_assessment['options'] = null;
  }
}
addOptions(option){
  if(option.length){
   this.addedOptions.push(option);
  }
}
doCheck(event,ind) {
  this.vendorsList[ind]['checked'] = event.target.checked;
}
checkAllQuestion(i) {
  this.questions[i].checked = true;
  return true;
}

submitCutomQuestion(){
  
  var dataToSend = {
    question_serial_no: this.vendor_assessment['question_serial_no'],
    question_content: this.vendor_assessment['question_content'],
    response_type: this.selectedResponceType,
    template_type: this.selectedTemplateType,
    options:  this.addedOptions,
    sub_type:"custom_question",
    additional_info:this.vendor_assessment['additional_info'],
    section_name:this.vendor_assessment['section_name'],
    maturity_score_outof:this.vendor_assessment['maturity_score_outof'],
    is_maturity:this.vendor_assessment['maturity'],
    author_email : this.profile.email,
    question_refrence:this.vendor_assessment['question_refrence']

  };
  this.portfolioSurveyService.postCustomineQuestion(dataToSend).subscribe((data:any) => {
    this.customQuestions.push(data);
      if(this.customSections && this.customSections.length) {
        if(this.customSections.findIndex( x => x.section_name == data.section_name) < 0){
        this.customSections.push({
          "section_name": data.section_name,
          "section_sub_type": 'custom_question'
        })
      }
    } else {
      this.customSections = [{
        "section_name": data.section_name,
        "section_sub_type": 'custom_question'
      }]
    }
    this.showQue = false;
    alert("Added Successfully");
    this.vendor_assessment={}
  })
  }
  public saveQuestionSet(stat) {
    this.questions.forEach(que => {
      if(que.checked){
        if(this.que_set['predefined_questions'] && this.que_set['predefined_questions'].length) {
          this.que_set['predefined_questions'].push(que.question_id)
        } else {
          this.que_set['predefined_questions'] = [que.question_id]
        }
      }
    });
    this.customQuestions.forEach(que => {
        if(!que.archived){
          if(this.que_set['custom_questions'] && this.que_set['custom_questions'].length) {
            this.que_set['custom_questions'].push(que.question_id)
          } else {
            this.que_set['custom_questions'] = [que.question_id]
          }
  
        }
      })

    var dataToSend=
    {
      custom_questions:this.que_set['custom_questions'],
      predefined_questions:this.que_set['predefined_questions'],
      due_date: this.que_set['due_date'],
      author_email : this.profile.email,
      template_type:this.templateType,
      status:stat
    }
    this.vendorsList.forEach(vendor => {
      if(vendor.checked){
        if(vendor.vendor_email || vendor['domain']){
          this.updateData=true;
        }
      }
    })
    if(this.updateData){
      this.portfolioSurveyService.addQuestionSet(dataToSend).subscribe(data => {
        this.vendorsList.forEach(vendor => {
          if(vendor.checked){
            var vendordata= {
              author_email : this.profile.email,
              vendor_name : vendor['domain'],
              vendor_email:  vendor['vendor_email'],
              question_set_id : data['question_set_id'],
              vendor_person_name : vendor['vendor_person_name'],
            }
            this.vendor_project.push(vendordata);
            
          }
        });
        this.portfolioSurveyService.addVendorProjectMapping(this.vendor_project).subscribe(data => {
          location.reload();
        })
        alert("Added Successfully");
      })
    }
    else{
      alert("Please Selct Vendor Properly!")
    }
  }
  public getAllDetailsOfProject(questionSetId){ 
    this.hideCheckBox=true;
    this.showProject = true;
    this.portfolioSurveyService.getProjectDetails(questionSetId).subscribe((data:any) => {
      this.que_set = data;
      this.questions = data.predefined_questions_details;
      this.customQuestions = data.custom_questions_details;
      this.customSections = data.custom_questions_sections;
      this.predefinedSections =data.predefined_questions_sections
    })
    this.portfolioSurveyService.getvendorDetails(questionSetId).subscribe((data:any) => {
      this.vendorsList=data;
    })
 
} 
// changeTab(){
//   document.getElementById("#preDefineQuestions").classList.add('active')
// }
gotoProjectlist(){
  this.showProject=false;
  this.hideCheckBox = false;
  this.questions = [];
  this.customQuestions = [];
  this.customSections = [];
  this.predefinedSections = [];
  this.vendorsList = this.allVendorsForUser;

}
// fileChangeListener($event): void {
 
//   var text = [];
//   var files = $event.srcElement.files;

//   if(Constants.validateHeaderAndRecordLengthFlag){
//     if(!this._fileUtil.isCSVFile(files[0])){
//       alert("Please import valid .csv file.");
//       this.fileReset();
//     }
//   }

//   var input = $event.target;
//   var reader = new FileReader();
//   reader.readAsText(input.files[0]);

//   reader.onload = (data) => {
//     let csvData = reader.result;
//     let csvRecordsArray = csvData.split(/\r\n|\n/);

//     var headerLength = -1;
//     var headersRow;
//     if(Constants.isHeaderPresentFlag){
//       headersRow = this._fileUtil.getHeaderArray(csvRecordsArray, Constants.tokenDelimeter);
//       headerLength = headersRow.length; 
//       // console.log("csvRecordsArray...",csvRecordsArray)
//       // console.log("csvData...",csvData)
//       // console.log("headersRow..",headersRow)
//     }
     
//     this.csvRecords = this._fileUtil.getDataRecordsArrayFromCSVFile(csvRecordsArray, 
//         headerLength, Constants.validateHeaderAndRecordLengthFlag, Constants.tokenDelimeter);
     
//     var csvRecordsObject = this._fileUtil.getDataRecordsObjectFromCSVFile(csvRecordsArray, headersRow
//       , Constants.validateHeaderAndRecordLengthFlag, Constants.tokenDelimeter)
//         // console.log("this.csvRecords....",csvRecordsObject)
//     if(this.csvRecords == null){
//       //If control reached here it means csv file contains error, reset file.
//       this.fileReset();
//     }    
//   }

//   reader.onerror = function () {
//     alert('Unable to read ' + input.files[0]);
//   };
// };

// fileReset(){
//   this.fileImportInput.nativeElement.value = "";
//   this.csvRecords = [];
// }


}
