import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'app/_services/common.service';

@Component({
	selector: 'app-digital-risk',
	templateUrl: './digital-risk.component.html',
	styleUrls: ['./digital-risk.component.scss']
})
export class DigitalRiskComponent implements OnInit {

	@Input() mainDomain: any;
	@Input() digitalRisk;
	@Input() filter;
	@Output() leakContentCount = new EventEmitter();
	@Input() isUserTrial = false;
	public digitalRiskDisplay = [];
	public showImage: boolean = false
	public imageIndex: number = -1;
	public showFilterData = false;
	public count: number = 0;

	constructor(
		private commonService: CommonService
	) { }

	ngOnInit() {
		// if (!this.digitalRisk)
		// 	this.showFilterData = true;
		// else {
		// 	this.showFilterData = true;
		// }
		// this.digitalRisk.forEach(risk => {
		// 	this.checkFilter(risk);
		// })
	}

	checkFilter(risk) {
		if (!risk.false_positive && !risk.archive) {
			var tags = risk['tags'];
			if (tags) {
				if (this.filter == 'all') {
					// if (tags.indexOf('leaked content') >= 0 || tags.indexOf('malicious') >= 0 || tags.indexOf('vulnerability') >= 0) {
					this.digitalRiskDisplay.push(risk);
					this.showFilterData = true;
					// 		return false
					// }
					// else {
					// 		this.showFilterData = true;
					// 		return true
					// }
				}
				else {
					if (tags.indexOf('leaked content') >= 0) {
						this.count = this.count + 1;
						this.showFilterData = true;
						this.digitalRiskDisplay.push(risk);
					}
					// 	var tagIndex = tags.indexOf(this.filter)
					// 	if (tagIndex >= 0) {
					// 		this.showFilterData = true;
					// 		return true;
					// 	} else {
					// 		return false;
					// 	}
				}
				this.leakContentCount.emit(this.count);
			}
			// else {
			// 	return false;
			// }
		}
	}

}
