import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-filters-block',
  templateUrl: './list-filters-block.component.html',
  styleUrls: ['./list-filters-block.component.scss']
})
export class ListFiltersBlockComponent implements OnInit {

  @Input() allFilterList:any = [];
  public showFilterBlock =  false;
  constructor() { }

  ngOnInit(): void {
    this.showFilterBlock = (this.allFilterList.length>0)?true:false;
  }

  checkState(stateFinalFilter,allFilterList,value){
    if(stateFinalFilter && stateFinalFilter.length>0){
			if(!allFilterList.includes(value)){
				allFilterList.push(value);
			}
		}else{
			allFilterList = this.removeFromArray(allFilterList,value);
		}
    return allFilterList;
  }
  removeFromArray(list,value){
    let index = list.indexOf(value);
		if (index > -1) { 
			list.splice(index, 1);
		}
    return list;
  }
}
