import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn:"root"
})
export class LogoutEmmitor {
	public _subject = new BehaviorSubject<any>('');
	emit(data){
		if(data) this._subject.next(data);  this._subject.next(null);
	}
	onChange(){
		return this._subject.asObservable();
	}
}