import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { PortfolioSurveyService } from '../../_services/portfolio-survey.service';
import { CommonService } from 'app/_services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-ip-details-modal',
  templateUrl: './ip-details-modal.component.html',
  styleUrls: ['./ip-details-modal.component.scss']
})
export class IpDetailsModalComponent implements OnInit {

  @Input() domain: any;
  @Input() ip: any;
  @Input() modalRef_:any;
  @Input() mainDomain:any;
  public loading:boolean = true;
  public data:any = [];
  public locationValue:any;
  public show_max_list: number = 3;
  public infoTagDay = 7;
  public attribution = {
		"related_domain": "DNS",
		"host": "DNS",
		"net_block": "Netblock",
		"keyword": "Keyword",
		"manual": "Manual"
	}

  public obsInsCountSpinner = false;
  public ipsDetailsForips:any;
  public ipsArray: any = [];
	public cve_details: any;
	public infoVal = localStorage.getItem('info_vul_config')=="true"?true:false;
  constructor(
    public portfolioSurveyService:PortfolioSurveyService,
    public commonService:CommonService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
  ) {
   }

  ngOnInit(): void {
    console.log(this.domain,this.ip);
    this.getIpDetails();
  }
  ngOnChange(){
  }
  getIpDetails(){
    let ipAttributation = []
    // this.ip = '185.125.190.37';
    let param:any = {searchKey:this.ip, confidence:'High,Medium,Low',scope: 'ALL'};
    this.portfolioSurveyService.getIpsDetailsPaginated(this.domain,param).subscribe((data)=>{
      console.log(data);
      this.data = data['data'];
      if (this.data && this.data.length>0) {
        this.data.forEach(ip => {
          ip.subjectTypeName = {
            "subject_type": 'ip_address',
            "subject_name": ip['ip_address'],
            }
          ipAttributation = []
          ip['ip_details'].forEach((detail:any) => {
          if(detail.attribution && detail.attribution.length){
            ipAttributation.push(...detail.attribution)
          }
          });
          ip.attributionDetails = ipAttributation
          // if (this.selectAllIPs) {
          //   ip.selected = true
          // } else if (this.selectedIps && this.selectedIps.length) {
          //   if (this.selectedIps.indexOf(ip.ip_address) >= 0) {
          //     ip.selected = true
          //   }
          // }
          this.checkCveScope(ip);
          this.ipsArray.push(ip.ip_address)
        });
        this.getObsInsCountsForEachIp(this.ipsArray)
		this.commonService.calculateCreatedAndUpdated(this.data);
		}
      this.loading = false;
    },(error)=>{
      console.log(error);
      this.loading = false;
    })
  }
  displayTags(ip) {
		if (ip.tags && ip.tags.length > 0) {
			return true
		} else if (ip.takeover_risk && ip.takeover_risk >= 2.0) {
			return true
		} else {
			return false
		}
	}
  getLocationByLatestUpdatedAt(ip_details) {
		let convertedDate = ip_details.reduce((max, details) => {
			return max.updated_at > details.updated_at ? max : details
		})
		this.locationValue = ([convertedDate.city, convertedDate.country].filter(a => {
			return a && a != 'None'
		}).join(', '));
		return this.locationValue
	}
  checkCveScope(ip) {
		ip.criticalVul = null
		ip.highVul = null
		ip.mediumVul = null
		ip.lowVul = null
		ip.infoVul = null
		ip.highVulCount = 0
		ip.mediumVulCount = 0
		ip.lowVulCount = 0
		ip.infoVulCount = 0
		ip.criticalVulCount = 0
		ip.cves.forEach(cve => {
			if (cve.cve_score >= 9) {
				ip.criticalVulCount = ip.criticalVulCount ? ip.criticalVulCount + 1 : 1;
				if (ip.criticalVul) {
					ip.criticalVul.push(cve);
				} else {
					ip.criticalVul = [cve];
				}
			} else if (cve.cve_score >= 7) {
				ip.highVulCount = ip.highVulCount ? ip.highVulCount + 1 : 1;
				if (ip.highVul) {
					ip.highVul.push(cve);
				} else {
					ip.highVul = [cve];
				}
			} else if (cve.cve_score >= 4) {
				ip.mediumVulCount = ip.mediumVulCount ? ip.mediumVulCount + 1 : 1;
				if (ip.mediumVul) {
					ip.mediumVul.push(cve);
				} else {
					ip.mediumVul = [cve];
				}
			} else if (cve.cve_score >= 0) {
				ip.lowVulCount = ip.lowVulCount ? ip.lowVulCount + 1 : 1;
				if (ip.lowVul) {
					ip.lowVul.push(cve);
				} else {
					ip.lowVul = [cve];
				}
			} else {
				ip.infoVulCount = ip.infoVulCount ? ip.infoVulCount + 1 : 1;
				if (ip.infoVul) {
					ip.infoVul.push(cve);
				} else {
					ip.infoVul = [cve];
				}
			}
		})
	}
  getObsInsCountsForEachIp(ipsArray) {
		this.obsInsCountSpinner = true
		const params = {
		  subject_ref_list: ipsArray,
		  subject_type: 'ip_address'
		}
		this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain['domain_name'], params).subscribe(data => {
		  this.obsInsCountSpinner = false
		  this.ipsDetailsForips = data['data']
		  this.data.forEach((ip) => {
			let ipName = ip.ip_address
			if (this.ipsDetailsForips[ipName]) {
			  ip.obs_count = this.ipsDetailsForips[ipName].count_obs
			  ip.ins_count = this.ipsDetailsForips[ipName].count_ins
			}
		  })
		})
	}
  modalOpen(template: TemplateRef<any>, vulnerability) {
		this.cve_details = vulnerability;
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
}
