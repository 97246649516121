import { NgModule } from "@angular/core";
// import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NewviewsComponent } from "./newviews.component";
import { NewviewRoutingModule } from "./newviews-routing.module";
import { DashboardComponent } from "./vendor-dashboard/dashboard.component";
import { ExternalScoreComponent } from "./external-score/external-score.component";
import { PortfolioInputComponent } from "./external-score/portfolio-input.component";
import { FootprintComponent } from "./footprint/footprint.component";
import { LeakedCredentialsComponent } from "./footprint/leaked-credentials.component";
import { DomainsComponent } from "./footprint/domains.component";
import { SubDomainsComponent } from "./footprint/sub-domains.component";
import { IpsComponent } from "./footprint/ips.component";
import { ApplicationComponent } from "./footprint/application.component";
import { RelatedDomainsComponent } from "./footprint/related-domains.component";
import { ManageSelfDomainComponent } from "./external-score/manage-self-domain.component";
import { ManageVendorDomainsComponent } from "./vendor-dashboard/manage-vendor-domains.component";
import { IpDetailsComponent } from "./footprint/ip-details.component";
import { UrlScreenshotThumnailComponent } from "../shared/url-screenshot-thumnail/url-screenshot-thumnail.component";
import { DownloadButtonComponent } from "../shared/download.component";
import { UrlScreenshotSmallThumnailComponent } from "../shared/url-screenshot-thumnail/url-screenshot-small-thumnail.component";
import { ChartsModule } from "ng2-charts";
import { QuestionnaireComponent } from "./vendor-assessment/questionnaire.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { ModalsComponent } from "app/views/components/modals.component";
import { VendorAssessmentComponent } from "./vendor-assessment/vendor-assessment.component";
import { VendorQuestionComponent } from "./vendor-assessment/vendor-question.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ProjectDetailsComponent } from "./vendor-assessment/project-details.component";
import { VendorResponseComponent } from "./vendor-response/vendor-response.component";

import { ShadowDomainComponent } from "./footprint/shadow-domain.component";
import { ShadowTableComponent } from "./footprint/shadow-table.component";
import { PhishingDomainComponent } from "./footprint/phishing-domain.component";
import { FileUtil } from "./vendor-assessment/file.util";
import { Constants } from "./vendor-assessment/test.constants";
import { ViewSubmitVendorResponseComponent } from "./vendor-response/view-submit-vendor-response.component";
import { TechnologyEnumerationComponent } from "./footprint/technology-enumeration.component";
import { BsModalRef } from "../../../node_modules/ngx-bootstrap/modal";
import { CriticalVulnerabilityComponent } from "./footprint/critical-vulnerability.component";
import { WhoisDomainComponent } from "./footprint/whois-domain.component";
import { LabsComponent } from "./labs/labs.component";
import { DigitalRiskComponent } from "./labs/digital-risk.component";
import { MaliciousInfraComponent } from "./labs/malicious-infra.component";
import { VulnerablityEnumerationComponent } from "./footprint/vulnerablity-enumeration.component";
import { LeakedContentComponent } from "./labs/leaked-content.component";
import { ServiceComponent } from "./footprint/service.component";
import { WebScoreComponent } from "./footprint/web-score.component";
import { ThirdPartyRiskComponent } from "./labs/third-party-risk.component";
import { NetblockComponent } from "./footprint/netblock.component";
import { S3BucketsComponent } from "./labs/s3-buckets.component";
import { CodeLeaksComponent } from "./labs/code-leaks.component";
import { PipeModule } from "app/pipes/pipe.module";
import { TopRisksComponent } from "./external-score/top-risks.component";
import { SslScoreComponent } from "./footprint/ssl-score.component";
import { ActiveMonitoringDashboardComponent } from "./active-scan/active-monitoring-dashboard.component";
import { RedTeamingComponent } from "./red-teaming/red-teaming.component";
import { NotificationComponent } from "../views/notification/notification.component";
import { CommanActionsComponent } from "./footprint/comman-actions/comman-actions.component";
import { ServiceVulnerabiltyComponent } from "./footprint/vulnerability/service-vulnerabilty.component";
import { ApplicationVulnerabiltyComponent } from "./footprint/vulnerability/application-vulnerabilty.component";
import { RemediationComponent } from "./remediation/remediation.component";
import { TechnologyVulnerabiltyComponent } from "./footprint/vulnerability/technology-vulnerabilty.component";
import { VulnerabilityRemediationComponent } from "./footprint/vulnerability/vulnerability-remediation/vulnerability-remediation.component";
import { ExecutiveReportComponent } from "./executive-report/executive-report.component";
import { ShowLeakedCredComponent } from "./footprint/show-leaked-cred.component";
import { PeopleComponent } from "./labs/people.component";
import { CommonDeleteComponent } from "./common-delete/common-delete.component";
import { ClickOutsideDirective } from "app/shared/click-outside.directive";
import { DnsRecordComponent } from "./footprint/dns-record.component";
import { CytoscapeLibraryComponent } from "./cytoscape-library/cytoscape-library.component";
import { DomainDiscovryComponent } from "./red-teaming/domain-discovry/domain-discovry.component";
import { NewDomainDiscoveryComponent } from "./new-domain-discovery/new-domain-discovery.component";
import { KeywordDataLeaksComponent } from "./labs/keyword-data-leaks/keyword-data-leaks.component";
import { SharedModule } from "../shared/shared.module";
import { ReportsComponent } from "./reports/reports.component";
import { MarkdownModule } from "ngx-markdown";
import { MonitorViewComponent } from "./monitor-view/monitor-view.component";
import { RunAttacksComponent } from "./run-attacks/run-attacks.component";
// import { GoogleChartsModule } from 'angular-google-charts';
import { NewPaginationComponent } from "../shared/newPagination.component";
import { PocCommentsComponent } from "../shared/poc-comments/poc-comments.component";
import { PocTouchpointComponent } from "../shared/poc-touchpoint/poc-touchpoint.component";
import { ApiDiscoveryComponent } from "./footprint/api-discovery/api-discovery.component";
import { PasteDataComponent } from "./labs/paste-data/paste-data.component";
import { TakeOverRiskComponent } from "./labs/take-over-risk/take-over-risk.component";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { CustomersComponent } from "./customers/customers.component";
import { ReportsCsvComponent } from "../shared/reports-csv/reports-csv.component";
import { DomainSubsectionSubdomainComponent } from "./footprint/domain-subsection-subdomain/domain-subsection-subdomain.component";
import { LabsV2Component } from "./labs-v2/labs-v2.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { AttributionSeedComponent } from "../components/attribution-seed/attribution-seed.component"
import {
  SettingsComponent,
  FormatTimePipe,
} from "./settings/settings.component";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { AddTopRiskComponent } from './footprint/vulnerability/add-top-risk/add-top-risk.component';
import { AvatarModule } from 'ngx-avatar';
import { AddVulnerabilityComponent } from "./footprint/add-vulnerability/add-vulnerability.component";
import { AddVulnerabilityWebAppComponent } from "./footprint/add-vulnerability-web-app/add-vulnerability-web-app.component";
import { VulnerabilitiesComponent } from './labs/vulnerabilities/vulnerabilities.component';
import { NetworkAssetsViewComponent } from './footprint/vulnerability/network-assets-view/network-assets-view.component';
import { WebappAssetsViewComponent } from './footprint/vulnerability/webapp-assets-view/webapp-assets-view.component';
import { ListFiltersBlockComponent } from './list-filters-block/list-filters-block.component';
import { MobileApplicationVulnerabilityComponent } from './footprint/vulnerability/mobile-application-vulnerability/mobile-application-vulnerability.component';
import { SpinnerComponent } from "app/seed_config/spinner/spinner.component";
import { ObservationsInsightsComponent } from "app/shared/observations-insights/observations-insights.component";
import { CommonObservationInsightComponent } from "app/shared/observations-insights/common-observation-insight/common-observation-insight.component";
import { SearchComponent } from './footprint/search/search.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BannerComponent } from './footprint/search/banner/banner.component';
import { VulnerabilityEvidenceComponent } from "./footprint/vulnerability/vulnerability-evidence/vulnerability-evidence.component";
import { WebappBannerComponent } from "./footprint/search/webapp-banner/webapp-banner.component";
import { IpDetailsModalComponent } from './ip-details-modal/ip-details-modal.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // GoogleChartsModule,
    ChartsModule,
    NewviewRoutingModule,
    SharedModule,
    ModalModule.forRoot(),
    TabsModule,
    PipeModule,
    MarkdownModule.forRoot(),
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AvatarModule,
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    NewviewsComponent,
    DashboardComponent,
    ExternalScoreComponent,
    FootprintComponent,
    LeakedCredentialsComponent,
    DomainsComponent,
    SubDomainsComponent,
    IpsComponent,
    ApplicationComponent,
    RelatedDomainsComponent,
    ManageSelfDomainComponent,
    ManageVendorDomainsComponent,
    IpDetailsComponent,
    PortfolioInputComponent,
    UrlScreenshotThumnailComponent,
    UrlScreenshotSmallThumnailComponent,
    QuestionnaireComponent,
    ModalsComponent,
    VendorAssessmentComponent,
    VendorQuestionComponent,
    ProjectDetailsComponent,
    VendorResponseComponent,
    ShadowDomainComponent,
    ShadowTableComponent,
    PhishingDomainComponent,
    ViewSubmitVendorResponseComponent,
    TechnologyEnumerationComponent,
    CriticalVulnerabilityComponent,
    WhoisDomainComponent,
    LabsComponent,
    LabsV2Component,
    DigitalRiskComponent,
    MaliciousInfraComponent,
    VulnerablityEnumerationComponent,
    LeakedContentComponent,
    ServiceComponent,
    WebScoreComponent,
    ThirdPartyRiskComponent,
    NetblockComponent,
    S3BucketsComponent,
    CodeLeaksComponent,
    TopRisksComponent,
    SslScoreComponent,
    RedTeamingComponent,
    ServiceVulnerabiltyComponent,
    ApplicationVulnerabiltyComponent,
    ActiveMonitoringDashboardComponent,
    NotificationComponent,
    DownloadButtonComponent,
    CommanActionsComponent,
    RemediationComponent,
    TechnologyVulnerabiltyComponent,
    VulnerabilityRemediationComponent,
    ExecutiveReportComponent,
    ShowLeakedCredComponent,
    PeopleComponent,
    CommonDeleteComponent,
    // ClickOutsideDirective,
    DnsRecordComponent,
    CytoscapeLibraryComponent,
    DomainDiscovryComponent,
    NewDomainDiscoveryComponent,
    KeywordDataLeaksComponent,
    ReportsComponent,
    MonitorViewComponent,
    RunAttacksComponent,
    NewPaginationComponent,
    PocCommentsComponent,
    ObservationsInsightsComponent,
    PocTouchpointComponent,
    ApiDiscoveryComponent,
    PasteDataComponent,
    TakeOverRiskComponent,
    CustomersComponent,
    ReportsCsvComponent,
    DomainSubsectionSubdomainComponent,
    AlertsComponent,
    SettingsComponent,
    FormatTimePipe,
    AddTopRiskComponent,
    AttributionSeedComponent,
    AddVulnerabilityComponent,
    AddVulnerabilityWebAppComponent,
    VulnerabilitiesComponent,
    NetworkAssetsViewComponent,
    WebappAssetsViewComponent,
    ListFiltersBlockComponent,
    MobileApplicationVulnerabilityComponent,
    SpinnerComponent,
    CommonObservationInsightComponent,
    SearchComponent,
    BannerComponent,
    VulnerabilityEvidenceComponent,
    WebappBannerComponent,
    IpDetailsModalComponent
  ],
  exports: [],
  entryComponents: [TechnologyEnumerationComponent],
  providers: [BsModalRef, FileUtil, Constants],
})
export class NewviewsModule {}
