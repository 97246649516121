import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { SecretsComponent } from './secrets.component';
import { SecretsRoutingModule} from './secrets-routing.module';
import { PaginationComponent } from '../shared/pagination.component'


@NgModule({
  declarations: [
    SecretsComponent,
    PaginationComponent,

  ],
  imports: [
    FormsModule,
    CommonModule,
    SecretsRoutingModule,
  ]
})
export class SecretsModule { }
