import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: any, key: string, order?: number, initialSort = false, sortMap = null) {
    if (!array || array.length <= 0 || !key || key.length <= 0 || !initialSort) {
      return array;
    } else {
      if (!order) {
        order = 1;
      }
      if (sortMap == null) {
        array.sort((a: any, b: any) => {
          if (b[key] == undefined || b[key] == null || b[key] == 'NA') {
            return -1;
          } else if (a[key] == undefined || a[key] == null || a[key] == 'NA') {
            return 1;
          } else if ((a[key].constructor == String || a[key] instanceof String) && (b[key].constructor == String || b[key] instanceof String)) {
            return this.sort_a_b(a[key].toLowerCase(), b[key].toLowerCase(), order)
          } else if ((a[key].constructor == Array || a[key] instanceof Array) && (b[key].constructor == Array || b[key] instanceof Array)) {
            return this.sort_a_b(a[key].length, b[key].length, order)
          } else {
            return this.sort_a_b(a[key], b[key], order)
          }
        });
      } else {
        array.sort((a: any, b: any) => {
          if (b[key] == undefined || b[key] == null || b[key] == 'NA') {
            return -1;
          } else if (a[key] == undefined || a[key] == null || a[key] == 'NA') {
            return 1;
          } else {
            return this.sort_a_b(sortMap[a[key]], sortMap[b[key]], order)
          }
        })
      }
    }
    return array;
  }


  sort_a_b(a, b, order) {
    if (a < b) {
      return order * -1;
    } else if (a > b) {
      return order;
    } else {
      return 0;
    }
  }

}
