import { Component, OnInit, Input, TemplateRef, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SortPipe } from 'app/pipes/sort.pipe';
import { SearchPipe } from 'app/pipes/search.pipe';
import { PortfolioSurveyService } from '../../_services';
import { reasonToDeleteFootPrint } from 'app/_services/common-var.service';
import { show_confidence_filter_for_ip } from '../../../environments/environment';

@Component({
	selector: 'app-ips',
	templateUrl: './ips.component.html',
	styleUrls: ['./ips.component.scss']
})
export class IpsComponent implements OnInit {

	@Input() mainDomain: any;
	// @Input() ips: any;
	@Input() isUserTrial: boolean = false;
	@Input() filters: any
	@Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
	@Input() showHeaderActions: boolean = true
	@Input() filterIpsList = []
	public obsInsCountSpinner = false;
	public ipsDetailsForips;
	public ipsArray: any = [];
    public infoVal = localStorage.getItem('info_vul_config')=="true"?true:false
	public order: number = -1;
	public show_max_list: number = 3;
	public infoTagDay = 7;
	public oldips: any = [];
	public cve_details: any;
	public portToTypeips: any = [];
	public portToTypeIpsDropDown: any = [];
	public ispToTypeips: any = [];
	public ispToTypeIpsDropDown: any = [];
	public showThisScan = 'va';
	public openDropdown: Boolean = false;
	public seeMore = 50;
	public sortPipe = new SortPipe();
	public searchPipe = new SearchPipe();
	public showVul = -1;
	public listToScan: any = []
	public keysToSearch = ['ip_address', 'ip_details', 'protocols', 'netblock_details']
	public showBulk: boolean = false
	public reasonToDeleteFootPrint = reasonToDeleteFootPrint;
	public bulkArr: any = [];
	public scanValue: any = {}
	public showScanOption: boolean = false;
	public delReason = '';
	public singleDelete = false;
	public multiSelectCount = 0;
	public IpsToBeDeleted = {};
	public singleSelectMonitoring;
	public openPopup: any;
	public scanProfiles;
	public pingstatus = "  ";
	public ping_status: boolean = false;
	public showToolTip: boolean = false;
	public showDowloadText: boolean = false;
	public filterOut: boolean = false;
	public monitors = [];
	public ipsToShow = []
	public pocSubjectTouchPoint;
	public pocSubjectComment;
	public pocSubjectObsIns;
	public ipsType = [
		{
			"name": "All",
			"value": 'all'
		},
		{
			"name": "DNS",
			"count": null,
			"value": "dns",
		},
		{
			"name": "Netblock",
			"count": null,
			"value": "netblock",
		},
		{
			"name": "By Keyword",
			"count": null,
			"value": "keyword",

		}
	]
	public attribution = {
		"related_domain": "DNS",
		"host": "DNS",
		"net_block": "Netblock",
		"keyword": "Keyword",
		"manual": "Manual"

	}
	public locationValue
	public selectedIpType = 'all';
	public dnsCount: number = 0;
	public netblockCount: number = 0;
	public keywordsCount: number = 0;
	public targetsForBulkIpv4 = []
	public targetsForBulkIpv6 = []
	public allCount: number = 0;
	public tags: any = [];
	public selectedTags;
	public selectedLocations;
	public selectedIpsDetails = []
	public selectedIps = [];
	public selectAllIPs = false;
	public select10IPs = false;
	public unarchiveIpIds = {}
	

	// public fieldsToDownload = {
	// 	'IP Address': 'ip_address',
	// 	'Critical Vulnerability': 'criticalVulCount',
	// 	'High Vulnerability': 'highVulCount',
	// 	'Medium Vulnerability': 'mediumVulCount',
	// 	'Low Vulnerability': 'lowVulCount',
	// 	'Info Vulnerability': 'infoVulCount',
	// 	'Service/Port': 'protocols',
	// 	'Source': 'source',
	// 	'Domains/Netblocks': 'host',
	// 	'ISP': 'asn_name',
	// 	'Created At': 'created_at',
	// 	'Updated At': 'updated_at',
	// 	"Comments": "comments",
	// 	// "Touchpoints": "touchpoints"
	// }
	public HighlyTagWithValue = {
		"ACTIVE": {
			"name": "ACTIVE",
			"value": ['active'],
			"status": false
		},
		"PREPROD": {
			"name": "PRE-PROD",
			"value": ['preprod', 'uat'],
			"status": false
		},
		"DATABASES": {
			"name": "DATABASES",
			"value": ['databases'],
			"status": false
		}
	}
	public showSpinner
	public showError
	public commentData: boolean = false;
	public page = 1;
	public limit = 10;
	public searchKey = null;
	public total_ips_count: number = null;
	public ipTypeToFilter = 'all'
	public sortKey;
	public selectedIsp = [];
	public cloudeProviderDetail;
	public showArchivedIps = false
	public addSingleIp = true
	public searchClicked = false
	newRequest: any;
	public inscopeConfig:any = null
	public inscopeFlag:any = null
	public showThis:any = null
    public validationFilter:string = null
	public confidences:any = [
		{name:'High',selected:true},
		{name:'Medium',selected:true},
		{name:'Low',selected:true},
	]
	public confidencesSelected:any = [];
	public showConfidenceFilter:boolean = show_confidence_filter_for_ip;
	public filterLocations:any = [];

	constructor(public commonService: CommonService,
		public modalService: BsModalService,
		public modalRef: BsModalRef,
		public portfolioSurveyService: PortfolioSurveyService,
	) { }
	ngOnChanges(changes: SimpleChanges) {
		// this.updateIpsToShow();
		this.filterIpsByConfidence();
		if (this.ipsToShow && this.ipsToShow.length > 0 && !this.commentData) {
			this.commentData = true
			this.getAllComments()
			// this.getAllTouchpoints()
			this.getAllObsAndIns()
			this.inscopeConfig = localStorage.getItem('scope_config')
			if(this.inscopeConfig == 'true'){
			this.showThis = 'inscope'
			} 
		}
		const changeFilters: SimpleChange = changes.filters

		if (changeFilters || changes.mainDomain || changes.filterIpsList) {
			if (this.filters && this.filters.page) {
				this.page = this.filters.page
				delete this.filters.page
			}
			if (this.filterIpsList && this.filterIpsList.length) {
				if (!this.filters) {
					this.filters = {}
					this.filters['ips_list'] = this.filterIpsList.join(',');
					// this.inscopeConfig = 'true';
					// this.inscopeFlag = 'ALL';
					console.log(this.filters)
				} else if(changes.filterIpsList){
					this.filters['ips_list'] = changes.filterIpsList.currentValue.join(',');
					// this.inscopeConfig = 'true';
					// this.inscopeFlag = 'ALL';
				}
				this.getIpsDetailsFromDeepc()
			} else {
				this.getIpsDetailsFromDeepc()
			}
			// this.getTagsForIPs()
			if(this.showHeaderActions) {
				this.getIpsIspList()
				this.getTagsForIPs()
			}
		}


	}
	ngOnInit() {
		console.log(this.filterIpsList, "askdjliaksjdlaksd")
		this.selectedIpType = 'all'
		this.inscopeConfig = localStorage.getItem('scope_config')
		if(this.inscopeConfig == 'true'){
		  this.showThis = 'inscope'
		} else {
            this.showThis = 'validation-filter';
		}
		// this.updateIpsToShow()
		this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
			this.getIpsDetailsFromDeepc()
		})
		this.filterLocations = [];
		this.portfolioSurveyService.getLocationsFromIp(this.mainDomain['domain_name']).subscribe(res=>{
			if(res['data']){
				res['data'].map((x)=>{
					if(x && x['country']!='None') this.filterLocations.push(x);
				})
			}
		})
	}
	// updateIpsToShow() {
	// 	this.ips.forEach((ip) => {
	// 		// this.checkCveScope(ip);
	// 		this.getPortToTypeipsDropDown(ip);
	// 		this.getIspToTypeipsDropDown(ip)
	// 	})

	// 	this.ipsToShow = this.ips;
	// 	this.oldips = this.commonService.getJsonCopy(this.ips);
	// }

	changeIpsToShow(rec) {


		// this.selectedIpType = rec
		// if (rec == 'all') {
		// 	this.ipsToShow = this.ips.filter(ip => { return true; });
		// } 
		// else {
		// 	this.ipsToShow = this.ips.filter(ip => {
		// 		if (rec == 'DNS') {
		// 			for (let ipd of ip.ip_details) {
		// 				if (ipd['subject_type'] && (ipd['subject_type'].indexOf('host') >= 0 || ip['subject_type'].indexOf('related_domain') >= 0)) {
		// 					return true;
		// 				}
		// 			}
		// 		} else if (rec == 'Netblock') {
		// 			for (let ipd of ip.ip_details) {
		// 				if (ipd['subject_type'] == 'net_block') {
		// 					return true;
		// 				}
		// 			}
		// 			// return ip.ip_details.forEach(ipd => {
		// 			// })
		// 		} else if (rec == 'By Keyword') {
		// 			for (let ipd of ip.ip_details) {
		// 				if (ipd['subject_type'] == 'keyword') {
		// 					return true;
		// 				}
		// 			}
		// 		}
		// 	})
		// }
	}

	getUniqueDomains(domains) {
		let uniq = []
		domains = new Set(domains)
		uniq.push(...Array.from(domains))
		return uniq.join(', ');
	}

	getMaliciousTags(malicious) {
		let tags = []
		if (malicious) {
			if (malicious['tags']) {
				malicious.tags[0].forEach(tag => {
					if (tag) {
						if (tag.indexOf(',')) {
							tags.push(...tag.split(','))
						} else {
							tags.push(tag)
						}
					}
				});
			}
		}
		let uniqueItems = Array.from(new Set(tags))
		return uniqueItems.join(', ');
	}
	modalOpen(template: TemplateRef<any>, vulnerability) {
		this.cve_details = vulnerability;
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });

	}
	modalScan(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });

	}

	getPortToTypeipsDropDown(element) {
		if (element && element.protocols) {
			element.protocols.forEach((protocol) => {
				if (protocol) {
					var serviceObject = { name: '', port: protocol, count: 1 };
					var index = this.portToTypeIpsDropDown.findIndex(function (service) {
						return service.port == serviceObject.port;
					});
				}
				if (index == -1) {
					this.portToTypeIpsDropDown.push(serviceObject);
				} else {
					this.portToTypeIpsDropDown[index]['count']++;
				}
			});
		}
	}

	// showportToTypeIps(value) {
	// 	// this.openDropdown = false;
	// 	let flag = true;
	// 	this.ips = this.commonService.getJsonCopy(this.oldips);
	// 	if (value == null) {
	// 		this.ips = this.ips.filter(element => {
	// 			element['filter_score'] = 0;
	// 			for (let i = 0; i < this.portToTypeIpsDropDown.length; i++) {
	// 				if (element && element.protocols) {
	// 					if (element.protocols.indexOf(this.portToTypeIpsDropDown[i]['port']) > -1 && this.portToTypeIpsDropDown[i]['selected'] == true) {
	// 						flag = false;
	// 						element['filter_score'] = element['filter_score'] ? element['filter_score'] + 1 : 1;
	// 						// return true;
	// 					}
	// 				} else if (this.portToTypeIpsDropDown[i]['selected'] == true) {
	// 					flag = false;
	// 				}
	// 			}
	// 			if (element['filter_score'] > 0) {
	// 				return true;
	// 			}
	// 		})

	// 	} else {
	// 		for (let i = 0; i < this.portToTypeIpsDropDown.length; i++) {
	// 			this.portToTypeIpsDropDown[i]['selected'] = false;
	// 		}
	// 	}
	// 	this.sortPipe.transform(this.ips, 'filter_score', -1, true);
	// 	if (flag) {
	// 		this.ips = this.commonService.getJsonCopy(this.oldips);
	// 	}
	// }
	// getIspToTypeipsDropDown(element) {
	// 	if (element && element.netblock_details && element.netblock_details.asn_name) {
	// 		var serviceObject = { name: '', isp: element.netblock_details.asn_name, count: 1 };
	// 		var index = this.ispToTypeIpsDropDown.findIndex(function (service) {
	// 			return service.isp == serviceObject.isp;
	// 		});
	// 		if (index == -1) {
	// 			this.ispToTypeIpsDropDown.push(serviceObject);
	// 		} else {
	// 			this.ispToTypeIpsDropDown[index]['count']++;
	// 		}
	// 	}

	// }

	// ClearFilter() {
	// 	this.openDropdown = false;
	// 	this.portToTypeIpsDropDown.map(port => {
	// 		port['selected'] = false;
	// 	})
	// 	this.ispToTypeIpsDropDown.map(isp => {
	// 		isp['selected'] = false;
	// 	})
	// 	this.ips = this.commonService.getJsonCopy(this.oldips);
	// }

	// showIspToTypeIps(value) {
	// 	// this.openDropdown = false;
	// 	let flag = true;
	// 	this.ips = this.commonService.getJsonCopy(this.oldips);
	// 	if (value == null) {
	// 		this.ips = this.ips.filter(element => {
	// 			element['filter_score'] = 0;
	// 			for (let i = 0; i < this.ispToTypeIpsDropDown.length; i++) {
	// 				if (element && element.netblock_details) {
	// 					if (element.netblock_details.asn_name == this.ispToTypeIpsDropDown[i]['isp'] && this.ispToTypeIpsDropDown[i]['selected'] == true) {
	// 						flag = false;
	// 						element['filter_score'] = element['filter_score'] ? element['filter_score'] + 1 : 1;
	// 						// return true;
	// 					}
	// 				} else if (this.ispToTypeIpsDropDown[i]['selected'] == true) {
	// 					flag = false;
	// 				}
	// 			}
	// 			if (element['filter_score'] > 0) {
	// 				return true;
	// 			}
	// 		})
	// 	} else {
	// 		for (let i = 0; i < this.ispToTypeIpsDropDown.length; i++) {
	// 			this.ispToTypeIpsDropDown[i]['selected'] = false;
	// 		}
	// 	}
	// 	this.sortPipe.transform(this.ips, 'filter_score', -1, true);
	// 	if (flag) {
	// 		this.ips = this.commonService.getJsonCopy(this.oldips);
	// 	}
	// }
	// searchIp(value) {
	// 	// if (value) {
	// 	this.ips = this.searchPipe.transform(this.oldips, this.keysToSearch, value);
	// 	if (this.selectedIpType != 'All') {
	// 		this.changeIpsToShow(this.selectedIpType)
	// 	} else {
	// 		this.ipsToShow = this.ips.filter(a => { return true; })
	// 	}
	// 	// }
	// }
	// checkCveScope(ip) {
	// 	ip.criticalVul = null
	// 	ip.highVul = null
	// 	ip.mediumVul = null
	// 	ip.lowVul = null
	// 	ip.infoVul = null
	// 	ip.highVulCount = 0
	// 	ip.mediumVulCount = 0
	// 	ip.lowVulCount = 0
	// 	ip.infoVulCount = 0
	// 	ip.criticalVulCount = 0
	// 	ip.cves.forEach(cve => {
	// 		if (cve.cve_score >= 9) {
	// 			ip.criticalVulCount = ip.criticalVulCount ? ip.criticalVulCount + 1 : 1;
	// 			if (ip.criticalVul) {
	// 				ip.criticalVul.push(cve);
	// 			} else {
	// 				ip.criticalVul = [cve];
	// 			}
	// 		} else if (cve.cve_score >= 7) {
	// 			ip.highVulCount = ip.highVulCount ? ip.highVulCount + 1 : 1;
	// 			if (ip.highVul) {
	// 				ip.highVul.push(cve);
	// 			} else {
	// 				ip.highVul = [cve];
	// 			}
	// 		} else if (cve.cve_score >= 4) {
	// 			ip.mediumVulCount = ip.mediumVulCount ? ip.mediumVulCount + 1 : 1;
	// 			if (ip.mediumVul) {
	// 				ip.mediumVul.push(cve);
	// 			} else {
	// 				ip.mediumVul = [cve];
	// 			}
	// 		} else if (cve.cve_score >= 0) {
	// 			ip.lowVulCount = ip.lowVulCount ? ip.lowVulCount + 1 : 1;
	// 			if (ip.lowVul) {
	// 				ip.lowVul.push(cve);
	// 			} else {
	// 				ip.lowVul = [cve];
	// 			}
	// 		} else {
	// 			ip.infoVulCount = ip.infoVulCount ? ip.infoVulCount + 1 : 1;
	// 			if (ip.infoVul) {
	// 				ip.infoVul.push(cve);
	// 			} else {
	// 				ip.infoVul = [cve];
	// 			}
	// 		}
	// 	})
	// }
	// closeScan() {
	// 	this.listToScan = []
	// 	this.modalRef.hide()
	// }
	// StartScan() {
	// 	alert("Scan Started Successfully !!")
	// 	this.closeScan()
	// }
	// activeScanMontoring(ip) {
	// 	ip.updated = true;
	// 	this.portfolioSurveyService.updateIPsScanProfile(ip, this.mainDomain['domain_name']).subscribe(data => {
	// 		if (data && data['status'] == "pass") {
	// 		} else {
	// 			// alert("Error while updating")
	// 		}
	// 	}, error => {
	// 		if (error['status'] === 403) {
	// 			alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
	// 		} else {
	// 			alert("Error while updating. Please write to us at contact@firecompass.com.")
	// 		}
	// 		// alert("Error while updating")
	// 	})
	// }
	// scanProfileMatrix(value) {
	// 	if (value) {
	// 		const sdsToUpdate = [];
	// 		this.ips.map(sd => {
	// 			if (sd.selected) {
	// 				sd.updated = true;
	// 				sd.scan_profile = []
	// 				Object.keys(value).forEach(v => {
	// 					if (value[v]) {
	// 						sd['monitoring'] = true;
	// 						if (sd.scan_profile.indexOf(v) < 0) {
	// 							sd.scan_profile.push(v)
	// 						}
	// 					}
	// 				})
	// 				sdsToUpdate.push(sd)
	// 				if (sd['scan_profile'].length === 0) {
	// 					sd['monitoring'] = false;
	// 				}
	// 				if (this.singleSelectMonitoring) {
	// 					sd['selected'] = false;
	// 				}
	// 			}
	// 		})
	// 		this.activeScanMontoring(sdsToUpdate)
	// 	} else {
	// 		if (this.singleSelectMonitoring) {
	// 			this.ips.map(sd => {
	// 				if (sd.selected) {
	// 					sd['monitoring'] = this.singleSelectMonitoring === 'selected' ? true : false;
	// 				}
	// 			})
	// 			this.singleSelectMonitoring = null;
	// 		}
	// 	}
	// 	this.openPopup = false;
	// }
	// monitoringProfile(ip, type) {
	// 	ip['scan_profile'] = [];
	// 	if (ip['port_monitoring']) {
	// 		ip['scan_profile'].push('port_monitoring');
	// 	}
	// 	if (ip['va_monitoring']) {
	// 		ip['scan_profile'].push('va_monitoring');
	// 	}
	// 	this.activeScanMontoring([ip]);
	// }

	// monitoringProfileV2(sDomain) {
	// 	sDomain['selected'] = true;
	// 	this.singleSelectMonitoring = !sDomain['monitoring'] ? 'selected' : 'notSelected';
	// 	this.openPopup = 'profileModal'
	// }

	// getMonitoringId(ip) {
	// 	return this.monitors.find(a => {
	// 		if (a['targetSpec'] == ip['ip_address']) {
	// 			return true
	// 		}
	// 	})
	// }

	// monitoringProfileV3(ip, type) {
	// 	let targets = [ip['ip_address']]
	// 	let targetType = ip['type']
	// 	if (ip.monitoring) {
	// 		this.portfolioSurveyService.registerMonitor(this.mainDomain['domain_name'], targets, targetType).subscribe(data => {
	// 			ip.monitoring = true
	// 			ip.monitoringDetails = data['data'][0]
	// 			this.oldips.forEach(i => { if (i['ip_address'] == ip['ip_address']) { i.monitoring = true; i.monitoringDetails = ip.monitoringDetails; } });
	// 			alert("Successfully Registered")
	// 		})
	// 	} else {
	// 		let monitoringId = ip['monitoringDetails']['_id']
	// 		this.portfolioSurveyService.deregisterMonitor(monitoringId).subscribe(data => {
	// 			this.oldips.forEach(i => { if (i['ip_address'] == ip['ip_address']) { i.monitoring = false; } });
	// 			alert("Successfully de-registered")
	// 		})
	// 	}
	// }

	delIp() {
		if (this.selectAllIPs && (this.selectedIsp || this.selectedTags)) {
			let filters = {}
			if (this.selectedTags && this.selectedTags.length) {
				filters = { ...filters, ...{ 'tags': this.selectedTags.split(',') } }
			}
			if (this.selectedIsp && this.selectedIsp.length) {
				this.ispToTypeIpsDropDown.map(t => {
					if (t.selected) {
						this.selectedIsp.push(t.isp_original)
					}
				})
				filters = { ...filters, ...{ 'asn': this.selectedIsp } }
			}
			this.IpsToBeDeleted = {
				filters: filters
			}
		} if (this.selectAllIPs && this.searchKey) {
			this.IpsToBeDeleted['search'] = this.searchKey
		} if (!this.selectAllIPs) {
			this.selectedIpsDetails.forEach(ip => {
				if (ip['selected'] && ip['selected'] == true) {
					if (this.IpsToBeDeleted['ip_address']) {
						this.IpsToBeDeleted['ip_address'].push(ip['ip_address'])

					} else {
						this.IpsToBeDeleted['ip_address'] = [ip['ip_address']]
					}
					// ip['ip_details'].forEach(element => {
					// 	if (this.IpsToBeDeleted['ip_ids']) {
					// 		this.IpsToBeDeleted['ip_ids'].push(element['id'])
					// 	} else {
					// 		this.IpsToBeDeleted['ip_ids'] = [element['id']]
					// 	}
					// });
				}
			})
		}
		this.IpsToBeDeleted['comment'] = this.delReason;
		if (this.singleDelete) {
			this.IpsToBeDeleted['count'] = 1
		} else {
			this.IpsToBeDeleted['count'] = this.selectAllIPs ? this.total_ips_count : this.selectedIpsDetails.length

		}
		this.IpsToBeDeleted['updated_through'] = 'customer'


		this.deleteIPs();
	}

	deleteIPs() {
		this.portfolioSurveyService.deleteIPs(this.IpsToBeDeleted, this.mainDomain['domain_name']).subscribe(data => {
			if (data['status'] == "pass") {
				alert("IP deleted successfully!")
				this.getIpsDetailsFromDeepc()
				this.resetIpsSelection()
				this.refreshCount.emit('ip');
			} else if (data['status'] == "fail") {
				alert(data['message'])
			}
		}, error => {
			if (error['status'] === 403) {
				alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
			} else {
				alert("Error while updating")
			}
			this.IpsToBeDeleted = {};
		});
		this.modalRef.hide();
	}

	selctForBulkAction(value) {
		if (value == true) {
			this.ipsToShow.forEach(ip => {
				this.multiSelectCount++;
				// ip.selected = true
				// this.selectedIpsDetails.push(ip)
				this.selectedNewBox(ip, value)
			})
		} else {
			this.ipsToShow.forEach(ip => {
				this.selectedNewBox(ip, value)
				// ip.selected = false
			})
			this.multiSelectCount = 0;
			// this.selectedIpsDetails = []
		}
	}
	selectedNewBox(ip, value) {
		ip.selected = value
		if (ip.selected) {
			this.multiSelectCount++;
			if (this.selectedIps.indexOf(ip.ip_address) == -1)
				this.selectedIpsDetails.push(ip)
		} else {
			this.multiSelectCount = this.multiSelectCount > 0 ? this.multiSelectCount - 1 : 0;
			this.selectedIpsDetails = this.selectedIpsDetails.filter(a => {
				return ip.ip_address != a.ip_address
			})
			this.selectedIps = this.selectedIps.filter(a => a != ip.ip_address)
			this.selectAllIPs = value
			this.select10IPs = value
		}
		this.selectedIps = [] // need this to collect newly selected ips
		this.selectedIpsDetails.forEach(ip => {
			this.selectedIps.push(ip.ip_address)
		})
	}

	uncheckSingleDelete() {
		this.ipsToShow.map(a => {
			a.selected = false;
			a.updated = false;
		})
		this.singleDelete = false;
		this.multiSelectCount = 0
		this.selectedIps = []
		this.selectedIpsDetails = []
	}
	openRemoveOptions(template: TemplateRef<any>, ip = {}) {
		if (this.singleDelete) {
			this.uncheckSingleDelete();
		}
		if (ip && ip['ip_address']) {
			ip['selected'] = true;
			this.selectedIpsDetails.push(ip)
		}
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	deleteDataCheck(value) {
		this.delReason = value;
		this.delIp()
	}
	getProfileName(spCode) {
		return this.scanProfiles[spCode] ? this.scanProfiles[spCode]['name'] : null
	}
	displayTags(ip) {
		if (ip.tags && ip.tags.length > 0) {
			return true
		} else if (ip.takeover_risk && ip.takeover_risk >= 2.0) {
			return true
		} else {
			return false
		}
	}
	// checkTagInTagsArray(tag) {
	// 	if (this.tags.findIndex(t => tag.includes(t['name'].toLowerCase())) > -1) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }
	// filterIpByHighlyTag(value, status) {
	// 	for (let i = 0; i < value.length; i++) {
	// 		this.tags.map(tag => {
	// 			if (tag.name == value[i].toUpperCase()) {
	// 				tag['selected'] = status;
	// 			}
	// 		})
	// 	}
	// 	this.filterIpByTag(null, true);
	// }
	// filterIpByTag(value, status) {
	// 	// this.openDropdown = false;
	// 	let flag = true;
	// 	this.ips = this.commonService.getJsonCopy(this.oldips)
	// 	if (value == 'clear') {
	// 		this.tags.map((tag) => {
	// 			tag['selected'] = false;
	// 		})
	// 		for (var key in this.HighlyTagWithValue) {
	// 			this.HighlyTagWithValue[key]['status'] = false;
	// 		}
	// 	} else {
	// 		if (value == 'affiliate') {
	// 			this.tags.map(t => {
	// 				if (t.name == "saas" || t.name == "parked" || t.name == "reverse proxy" || t.name == "virtual hosting" || t.name == "aws") {
	// 					t.selected = status;
	// 				}
	// 			})
	// 		}
	// 		if (!this.filterOut) {
	// 			this.ips = this.ips.filter(element => {
	// 				for (let i = 0; i < this.tags.length; i++) {
	// 					if (element.tags && element.tags.some(t => this.tags[i]['value'].includes(t.toUpperCase())) && this.tags[i]['selected'] == true) {
	// 						flag = false;
	// 						return true;
	// 					} else if (this.tags[i]['selected'] == true) {
	// 						flag = false;
	// 					}
	// 				}
	// 			})
	// 		} else {
	// 			this.ips = this.ips.filter(element => {
	// 				for (let i = 0; i < this.tags.length; i++) {
	// 					if (this.tags[i]['selected'] == true) {
	// 						if (element.tags && element.tags.some(t => this.tags[i]['value'].includes(t.toUpperCase()))) {
	// 							flag = false;
	// 							return false;
	// 						}
	// 					}
	// 				}
	// 				flag = false;
	// 				return true;
	// 			})
	// 		}
	// 	}
	// 	if (flag) {
	// 		this.ips = this.commonService.getJsonCopy(this.oldips);
	// 	}
	// }
	// changeTagFilterBasedOnHighyTag(tag) {
	// 	if (this.HighlyTagWithValue[tag.name])
	// 		this.HighlyTagWithValue[tag.name].status = !tag['selected'];
	// 	tag['selected'] = !tag['selected'];
	// }
	// getTags(ip) {
	// 	if (ip && ip.tags && ip.tags.length > 0) {
	// 		this.filterTagsForDropdown(ip);
	// 	}
	// }
	// filterTagsForDropdown(ip) {
	// 	if (ip) {
	// 		if (ip && ip.tags != null && ip.tags.length > 0) {
	// 			ip.tags.forEach((tag) => {
	// 				let tag_uppercase = tag.toUpperCase();
	// 				let tagObject = { name: tag_uppercase, value: [tag_uppercase], selected: false };
	// 				if (tag_uppercase == 'HTTP ACTIVE' || tag_uppercase == 'HTTPS ACTIVE') {
	// 					tagObject['name'] = 'ACTIVE';
	// 				}
	// 				let index = this.tags.findIndex(function (tagOb) {
	// 					return tagOb.name.toLowerCase() == tagObject.name.toLowerCase();
	// 				});
	// 				if (index == -1) {
	// 					this.tags.push(tagObject);
	// 				} else {
	// 					if (this.tags[index]['value'].indexOf(tag_uppercase) == -1) {
	// 						this.tags[index]['value'].push(tag_uppercase)
	// 					}
	// 				}
	// 			});
	// 		}
	// 	}
	// }
	// checkChildTag(tag) {
	// 	tag = tag.toLowerCase()
	// 	let indexOfAffiliated = this.tags.findIndex(ta => {
	// 		return ta.name == 'affiliate'
	// 	})
	// 	return (this.tags[indexOfAffiliated]['childTags'].indexOf(tag) >= 0);
	// }

	// diMonSubscrption(values) {
	// 	if (values.bulkArr) {
	// 		values.bulkArr.forEach(ipDetail => {
	// 			if (ipDetail.type == "ipv4") {
	// 				this.targetsForBulkIpv4.push(ipDetail.ip_address)
	// 				ipDetail.monitoring = true
	// 			} else if (ipDetail.type == "ipv6") {
	// 				this.targetsForBulkIpv6.push(ipDetail.ip_address)
	// 				ipDetail.monitoring = true
	// 			}
	// 		});
	// 		if (this.targetsForBulkIpv4) {
	// 			this.portfolioSurveyService.registerMonitor(this.mainDomain['domain_name'], this.targetsForBulkIpv4, "ipv4").subscribe(data => {
	// 			})
	// 		}
	// 		if (this.targetsForBulkIpv6) {
	// 			this.portfolioSurveyService.registerMonitor(this.mainDomain['domain_name'], this.targetsForBulkIpv6, "ipv6").subscribe(data => {
	// 			})
	// 		}
	// 	}
	// }

	pingStatus(ping_status) {
		if (ping_status) {
			return "Live"
		}
		else {
			return "Unknown"
		}
	}
	openCommentsModule(template: TemplateRef<any>, ip) {
		this.pocSubjectComment = {
			"subject_type": 'ip_address',
			"subject_name": ip['ip_address'],
		}
		this.modalRef = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}
	openTouchpointModule(template: TemplateRef<any>, ip) {
		this.pocSubjectTouchPoint = this.pocSubjectComment = {
			"subject_type": 'ip_address',
			"subject_name": ip['ip_address'],
		}

		this.modalRef = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}
	getAllComments() {
		this.portfolioSurveyService.getAllComments(this.mainDomain["domain_name"], "ip_address").subscribe((data) => {
			this.ipsToShow.forEach(ip => {
				const commentsForDomain = data['data'].filter(a => {
					return a.subject_name == ip.ip_address
				})
				if (commentsForDomain && commentsForDomain.length) {
					commentsForDomain.forEach(cc => {
						ip.comments ? ip.comments.push(cc.title) : ip.comments = [cc.title]
					})
				}
			});
		})
	}
	getAllObsAndIns() {
		this.portfolioSurveyService.getAllObsAndIns(this.mainDomain["domain_name"], "ip_address").subscribe((data) => {
			this.ipsToShow.forEach(ip => {
				const observationsForDomain = data['data'].filter(a => {
					return a.subject_name == ip.ip_address
				})
				if (observationsForDomain && observationsForDomain.length) {
					observationsForDomain.forEach(cc => {
						ip.observations ? ip.observations.push(cc.observations) : ip.observations = [cc.observations]
					})
				}
			});
		})
	}
	getAllTouchpoints() {
		this.portfolioSurveyService.getAllTouchpoints(this.mainDomain["domain_name"], "ip_address").subscribe((data) => {
			this.ipsToShow.forEach(ip => {
				const touchpointsForDomain = data['data'].filter(a => {
					return a.subject_name == ip.ip_address
				})
				if (touchpointsForDomain && touchpointsForDomain.length) {
					touchpointsForDomain.forEach(cc => {
						ip.touchpoints ? ip.touchpoints.push(cc.email) : ip.touchpoints = [cc.email]
					})
				}
			});
		})
	}
	getIpsDetailsForTypeFilter() {
		this.selectedIps = []
		this.selectedIpsDetails = []
		this.selectAllIPs = false
		this.select10IPs = false
		this.getIpsDetailsFromDeepc()
	}
	getIpsByList() {
		console.log(this.filterIpsList)
	}
	//------------------------------
	getIpsDetailsFromDeepc() {
		let ipAttributation = []
		this.newRequest ? this.newRequest.unsubscribe(): null
		this.showSpinner = true
		this.ipsToShow = []
		this.selectedIpType = this.ipTypeToFilter
		const params = {
			page: this.page,
			limit: this.limit,
			searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
			tags: this.selectedTags && this.selectedTags.length ? this.selectedTags : null,
			asn_names: this.selectedIsp && this.selectedIsp.length ? this.selectedIsp : null,
			...this.filters,
			archive: this.showArchivedIps,
			// scope: this.inscopeFlag,

		}
        if (this.validationFilter) {
            params.validated = this.validationFilter === 'validated' ? true : false
        }
		if(this.showArchivedIps && this.inscopeConfig == 'true') params.scope = "ALL" 
		else if(this.showArchivedIps && this.inscopeConfig == 'false')  delete params.scope 
		else if(!this.showArchivedIps && this.inscopeConfig == 'true') params.scope = this.inscopeFlag

		if (this.sortKey) params.sortKey = this.order == -1 ? this.sortKey : '-' + this.sortKey
		if (this.ipTypeToFilter != 'all') {
			params.ipType = this.ipTypeToFilter
		} else delete params.ipType;
		if (this.confidencesSelected) {
            params.confidence = this.confidencesSelected;
        }
		if(this.selectedLocations){
			params.locations = this.selectedLocations;
		}
		console.log(this.filters)
		this.newRequest = this.portfolioSurveyService.getIpsDetailsPaginated(this.mainDomain['domain_name'], params).subscribe(
			(data) => {
				if (data['data']) {
					this.ipsToShow = data['data']
					this.showSpinner = false
					if (this.ipsToShow) {
						this.ipsToShow.forEach(ip => {
							ip.subjectTypeName = {
								"subject_type": 'ip_address',
								"subject_name": ip['ip_address'],
							  }
							ipAttributation = []
							ip['ip_details'].forEach(detail => {
							if(detail.attribution && detail.attribution.length){
								ipAttributation.push(...detail.attribution)
							}
							});
							ip.attributionDetails = ipAttributation
							if (this.selectAllIPs) {
								ip.selected = true
							} else if (this.selectedIps && this.selectedIps.length) {
								if (this.selectedIps.indexOf(ip.ip_address) >= 0) {
									ip.selected = true
								}
							}
							// this.checkCveScope(ip);
							this.ipsArray.push(ip.ip_address)
						});
						this.getObsInsCountsForEachIp(this.ipsArray)
					}
					this.commonService.calculateCreatedAndUpdated(this.ipsToShow);

					if (this.page == 1) {
						this.total_ips_count = data['data'][0]?.total ? data['data'][0]?.total : 0
						let indexOfIpType = this.ipsType.findIndex(t => t['value'] == this.ipTypeToFilter)
						if (indexOfIpType >= 0) {
							this.ipsType[indexOfIpType]['count'] = this.total_ips_count
						}
					}
				} else {
					this.showSpinner = false
				}
			},
			(error) => {
				this.commonService.errorForDomain(error);
			}
		);
	}
	goToPage(val) {
		this.select10IPs = false
		this.page = val;
		this.getIpsDetailsFromDeepc()
	}
	searchAllIpDetails() {
		this.searchKey = null
		this.selectedIps = []
		this.selectedIpsDetails = []
		this.selectAllIPs = false
		this.select10IPs = false
		this.multiSelectCount = 0
		this.searchClicked = false
		this.getIpsDetailsFromDeepc()
	}
	searchIpDetails(domain) {
		this.searchClicked = true
		this.page = 1;
		this.getIpsDetailsFromDeepc()
	}
	getTagsForIPs() {
		this.tags = [];
		let param = {};
		if(this.inscopeConfig == 'true'){
			param['scope'] = (this.inscopeFlag)?this.inscopeFlag:'IN';
		}
        if (this.validationFilter) {
            param['ip_validated'] = this.validationFilter === 'validated' ? true : false
        }
		this.portfolioSurveyService.getIpsTagsList(this.mainDomain['domain_name'],param).subscribe(data => {
			data['data'].forEach((tag) => {
				let tag_uppercase = tag['tag'].toUpperCase();
				let tagObject = { name: tag_uppercase, value: [tag_uppercase], tag_original: tag };
				if (
					tag_uppercase == "HTTP ACTIVE" ||
					tag_uppercase == "HTTPS ACTIVE"
				) {
					tagObject["name"] = "ACTIVE";
				}
				let index = this.tags.findIndex(function (tagOb) {
					return tagOb.name == tagObject.name;
				});
				if (index == -1) {
					this.tags.push(tagObject);
				} else {
					if (this.tags[index]["value"].indexOf(tag_uppercase) == -1) {
						this.tags[index]["value"].push(tag_uppercase);
					}
				}
			});
		})
	}
	filterIpsByTag(value) {
		this.selectedIps = []
		this.selectedIpsDetails = []
		this.selectAllIPs = false
		this.select10IPs = false
		this.page = 1
		if (value == 'clear') {
			this.tags.forEach(tag => {
				tag.selected = false
			})
			this.filters = { ...this.filters, tags: null };

			this.selectedTags = []
		} else {
			const selectedTagsArr = this.tags.map(t => {
				if (t.selected) {
					return t.tag_original['tag']
				}
			})
			this.selectedTags = selectedTagsArr.filter(t => t).join(',')
		}    
		this.filters = { ...this.filters, tags: this.selectedTags };


		this.getIpsDetailsFromDeepc()
	}

	getIpsIspList() {
		this.ispToTypeIpsDropDown = [];
		let param = {};
		if(this.inscopeConfig == 'true'){
			param['scope'] = (this.inscopeFlag)?this.inscopeFlag:'IN';
		}
		if(this.validationFilter){
            param['ip_validated'] = this.validationFilter === 'validated' ? true : false
		}
		this.portfolioSurveyService.getIpsIspList(this.mainDomain['domain_name'],param).subscribe(data => {
			const asnNames = data['data']
			Object.values(asnNames).forEach((isp) => {
				let isp_uppercase = isp['asn_name'].toUpperCase();
				let ispObject = { name: isp_uppercase, isp: [isp_uppercase], isp_original: isp, count: isp['count']};
				this.ispToTypeIpsDropDown.push(ispObject);
			})

			// if (data['data']['tags']) {
			// 	this.collectTagsForFilter(data['data']['tags'])
			// } else {
			// 	this.getTagsForIPs()
			// }
		})
	}
	collectTagsForFilter(tags) {
		Object.keys(tags).forEach(tag => {
			let tag_uppercase = tag.toUpperCase();
			let tagObject = { name: tag_uppercase, value: [tag_uppercase], tag_original: tag, count: tags[tag] };
			if (
				tag_uppercase == "HTTP ACTIVE" ||
				tag_uppercase == "HTTPS ACTIVE"
			) {
				tagObject["name"] = "ACTIVE";
			}
			let index = this.tags.findIndex(function (tagOb) {
				return tagOb.name == tagObject.name;
			});
			if (index == -1) {
				this.tags.push(tagObject);
			} else {
				if (this.tags[index]["value"].indexOf(tag_uppercase) == -1) {
					this.tags[index]["value"].push(tag_uppercase);
				}
			}
		})
	}
	showIspToTypeIps(value) {
		this.selectedIsp = []
		this.selectedIpsDetails = []
		this.selectAllIPs = false
		this.select10IPs = false

		this.page = 1
		if (value == 'clear') {
			this.ispToTypeIpsDropDown.forEach(isp => {
				isp.selected = false
			})
			this.selectedIsp = []
		} else {
			this.ispToTypeIpsDropDown.map(t => {
				if (t.selected) {
					let val_isp = '"' + t.isp_original['asn_name'] + '"'
					this.selectedIsp.push(val_isp)
				}
			})
		}
		this.getIpsDetailsFromDeepc()
	}
	changeSelectedStae(val) {
		val['selected'] = !val['selected'];
	}
	getLocationByLatestUpdatedAt(ip_details) {
		let convertedDate = ip_details.reduce((max, details) => {
			return max.updated_at > details.updated_at ? max : details
		})
		this.locationValue = ([convertedDate.city, convertedDate.country].filter(a => {
			return a && a != 'None'
		}).join(', '));
		return this.locationValue
	}
	checkedIfSelected(ip) {
		return this.selectedIps.indexOf(ip) > -1
	}
	selectAllPages(value) {
		this.selectAllIPs = value
		this.select10IPs = value
		this.selectedIpsDetails = []
		this.selectedIps = []
		this.selctForBulkAction(value)
	}
	hideModel() {
		this.modalRef.hide()
		this.delReason = ''

	}
	openCloudProviderModel(template: TemplateRef<any>, cloude_provider) {
		this.cloudeProviderDetail = cloude_provider;
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });

	}
	switchDataForArchive() {
		this.selectAllIPs = false
		this.select10IPs = false
		this.selectedIpsDetails = []
		this.selectedIps = []
		this.selectedTags = null
		this.selectedIsp = []
		this.showArchivedIps = !this.showArchivedIps
		this.page = 1
		this.getIpsDetailsFromDeepc()
		// this.getTagsForIPs()
		this.getIpsIspList()
	}
	unarchiveIP(ip, position) {
		this.unarchiveIpIds = { ip_address: ip['ip_address'] }
		this.portfolioSurveyService.unarchiveIPs(this.unarchiveIpIds, this.mainDomain['domain_name']).subscribe(data => {
			this.modalRef.hide();
			alert("IP added back successfully!")
			// this.ipsToShow.splice(position, 1)
			if (this.ipsToShow.length == 0 && this.total_ips_count > 10) this.getIpsDetailsFromDeepc()
			this.IpsToBeDeleted = {};
			this.getIpsDetailsFromDeepc()
			this.refreshCount.emit('ip');
		}, error => {
			if (error['status'] === 403) {
				alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
			} else {
				alert("Error while updating")
			}
			this.unarchiveIpIds = {};
		});
	}
	openNewIps(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	// addNewIps(){
	// 	if(!this.addSingleIp){
	// 	}
	// }
	cancelDeleteBulk(value) {
		if (value) {
			this.selectedIps = []
			this.selectedIpsDetails = []
			this.selectAllIPs = false
			this.select10IPs = false
			this.selctForBulkAction(false)
		}
	}
	resetIpsSelection() {
		this.IpsToBeDeleted = {}
		this.singleDelete = false
		this.selectedIpsDetails = []
		this.selectedIps = []
		this.multiSelectCount = 0
	}
	filterByInscope(value) {
		this.page = 1;
		this.inscopeFlag = value;
		this.filters = { ...this.filters, scope: value };
		this.getIpsDetailsFromDeepc();
		this.getIpsIspList()
		this.getTagsForIPs()
		this.openDropdown = false;
	  }

	filterByValidation(value:string) {
		this.page = 1;
		this.validationFilter = value;
		this.filters = { ...this.filters, ip_validated: value };
		this.getIpsDetailsFromDeepc();
		this.getIpsIspList()
		this.getTagsForIPs()
		this.openDropdown = false;
	}
	getObsInsCountsForEachIp(ipsArray) {
		this.obsInsCountSpinner = true
		const params = {
		  subject_ref_list: ipsArray,
		  subject_type: 'ip_address'
		}
		this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain['domain_name'], params).subscribe(data => {
		  this.obsInsCountSpinner = false
		  this.ipsDetailsForips = data['data']
		  this.ipsToShow.forEach((ip) => {
			let ipName = ip.ip_address
			if (this.ipsDetailsForips[ipName]) {
			  ip.obs_count = this.ipsDetailsForips[ipName].count_obs
			  ip.ins_count = this.ipsDetailsForips[ipName].count_ins
			}
		  })
		})
	}

	changeSelectedConfidence(val){
		val['selected'] = !val['selected'];
	}
	filterIpsByConfidence(value="") {
		this.selectedIps = []
		this.selectedIpsDetails = []
		this.selectAllIPs = false
		this.select10IPs = false
		this.confidencesSelected = [];
		this.page = 1
		if (value == 'clear') {
			this.confidences.forEach(tag => {
				tag.selected = false
			})
			this.confidencesSelected = []
		} else {
			const selectedConfidenceArr = this.confidences.map(t => {
				if (t.selected) {
					return t['name']
				}
			})
			this.confidencesSelected = selectedConfidenceArr.filter(t => t).join(',')
		}
		console.log(this.confidencesSelected);
		this.getIpsDetailsFromDeepc()
	}
	filterIpsByLocation(value) {
		this.selectedIps = []
		this.selectedIpsDetails = []
		this.selectAllIPs = false
		this.select10IPs = false
		this.page = 1
		if (value == 'clear') {
			this.filterLocations.forEach(loc => {
				loc.selected = false
			})
			this.selectedLocations = []
		} else {
			const selectedLocationsArr = this.filterLocations.map(t => {
				if (t.selected) {
					return t.country;
				}
			})
			this.selectedLocations = selectedLocationsArr.filter(t => t).join(',')
		}
		console.log(this.selectedLocations);
		this.getIpsDetailsFromDeepc()
	}
}
