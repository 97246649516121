import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
import { CommonService } from 'app/_services/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-active-monitoring-dashboard',
	templateUrl: './active-monitoring-dashboard.component.html',
	styleUrls: ['./active-monitoring-dashboard.component.scss']
})
export class ActiveMonitoringDashboardComponent implements OnInit {

	public seeMore_ips = 5;
	public seeMore_netblock = 5;
	public seeMore_domain = 5;
	public seeLess: boolean = false
	public topRisk
	public domain
	public domainList = []
	public ips = []
	public netblock = []
	public countDetails: any
	public monitors = []
	public appList=[]
	constructor(
		private portfolioSurveyService: PortfolioSurveyService,
		private modalService: BsModalService,
		private modalRef: BsModalRef,
		private route: ActivatedRoute, private router: Router,
		private commonService: CommonService
	) { }
	ngOnInit() {
	// 	this.getDomainName()
	}
	// public getDomainName() {
	// 	this.route.queryParams.subscribe(params => {
	// 		if (params && params['domain']) {
	// 			this.domain = params['domain'];
	// 			this.router.navigate(['.'], {
	// 				relativeTo: this.route, queryParams: { domain: this.domain }, queryParamsHandling: 'merge',
	// 				skipLocationChange: true
	// 			});
	// 			this.getActiveMonitoringData()
	// 		} else {
	// 			this.portfolioSurveyService.getDomainsSearchForUser().subscribe((data:any) => {
	// 				data.filter(d => {
	// 					if (d['domain_type'] == 'self') {
	// 						this.domain = d['domain']
	// 					}
	// 				})
	// 				this.getActiveMonitoringData()
	// 			})
	// 		}
	// 	})
	// }
	// public getActiveMonitoringData() {
	// 	if (this.domain) {
	// 		// this.activeScaning()
	// 		// this.getMonitoring()
	// 		this.avtivelyScanCout()
	// 		this.portfolioSurveyService.getTopRisk(this.domain).subscribe((data:any) => {
	// 			this.topRisk = data.data
	// 		})
	// 	} else {
	// 		alert("No domain associated with you, Please write us on contact@firecompass.com")
	// 	}
	// }
	// public getMonitoring() {
	// 	this.portfolioSurveyService.getMonitorsByDomain(this.domain).subscribe((data:any) => {
	// 		this.monitors = data
	// 		console.log("monitors....", this.monitors)
	// 		this.monitors.forEach((ele) => {
	// 			console.log(ele.targetSpec, ele.targetType)
	// 			if (ele.targetType == "ipv4" || ele.targetType == 'ipv6') {
	// 				this.ips.push(ele.targetSpec)
	// 			} else if (ele.targetType == 'net_block') {
	// 				this.netblock.push(ele.targetSpec)
	// 			}
	// 			else if (ele.targetType == 'domain' || ele.targetType == 'subdomain') {
	// 				this.domainList.push(ele.targetSpec)
	// 			}
	// 			else if (ele.targetType == 'web' || ele.targetType == 'mobile') {
	// 				this.appList.push(ele.targetSpec)
	// 			}
	// 		})
	// 	})
	// }

	// // public activeScaning() {
	// // 	this.portfolioSurveyService.getActiveScanDetails(this.domain).subscribe(data => {
	// // 		if (data.status == "pass") {
	// // 			if(data.ips.length>0 || data.netblocks.length>0 || data.relatedDomains.length>0){
	// // 				this.ips = data.ips
	// // 				this.netblock = data.netblocks
	// // 				data.relatedDomains.forEach(dom => {
	// // 					this.domainList.push(dom['domain'])
	// // 				});
	// // 				data.subDomains.forEach(dom => {
	// // 					this.domainList.push(dom['host'])
	// // 				});
	// // 			} else{
	// // 				alert("Continuous monitoring is not active in your profile, please write us on contact@firecompass.com")
	// // 			}
	// // 		}
	// // 	})
	// // }

	// public avtivelyScanCout() {
	// 	this.portfolioSurveyService.getActiveScanCount(this.domain).subscribe((data:any) => {
	// 		if (data.status == "pass") {
	// 			this.countDetails = data
	// 		}

	// 	})
	// }
	// modalOpen(template: TemplateRef<any>, ) {
	// 	this.modalHide()
	// 	this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	// }
	// modalHide() {
	// 	if (this.modalRef) {
	// 		this.modalRef.hide();
	// 	}
	// }
}
