import { Component, OnInit, Input, TemplateRef, SimpleChange, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { Port2ServiceNamePipe } from 'app/pipes/port2service-name.pipe';
import { SortPipe } from 'app/pipes/sort.pipe';
import { SearchPipe } from 'app/pipes/search.pipe';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from 'app/_services';
import { FilterPipe } from 'app/pipes/filter.pipe';
// import { tags } from 'app/_services/common-var.service'

@Component({
	selector: 'app-service',
	templateUrl: './service.component.html',
	styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

	@Input() services: any;
	@Input() mainDomain: any;
	@Input() isUserTrial: boolean;
	@Input() filters: any;
	@Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
	@Input() showHeaderActions: boolean = true
	public obsInsCountSpinner = false;
	public servicesDetailsForservice;
	public seeMore = 10;
	public oldservices = [];
	public count = 5;
	public show_max_list: number = 3;
	public portToTypeservicesDropDown: any = [];
	public ispToTypeips: any = [];
	public ispToTypeIpsDropDown: any = [];
	public openDropdown: Boolean = false;
	public order = 1;
	public sortWith = 'service_name';
	public startDay: number = 7;
	public infoTagDay = 7;
	public port2ServiceNamePipe = new Port2ServiceNamePipe();
	public sortPipe = new SortPipe();
	public searchPipe = new SearchPipe();
	public filterPipe = new FilterPipe();
	public multiSelectCount = 0;
	public singleDelete = false;
	public delReason = '';
	public servicesToDelete = {};
	public singleSelectMonitoring;
	public openPopup: any;
	// public scanProfiles;
	public listToScan: any = [];
	public showDowloadText: boolean = false;
	public tags = []
	public filterOut = false;
	public showError;
	public showSpinner = true;
	public fieldsToDownload = {
		'IP Address': 'ip_id__ip_address',
		'Service/Port': 'service_name',
		'Domain/Netblock': 'hosts.subject_name',
		'Updated At': 'updated_at',
		'Created At': 'created_at',
		// 'ASN Name': 'asn_name'
	}
	public HighlyTagWithValue = {
		"ACTIVE": {
			"name": "ACTIVE",
			"value": ['active'],
			"status": false
		},
		"PREPROD": {
			"name": "PRE-PROD",
			"value": ['preprod', 'uat'],
			"status": false
		},
		"DATABASES": {
			"name": "DATABASES",
			"value": ['databases'],
			"status": false
		}
	}
	public page = 1;
	public limit = 10;
	public searchKey = null;
	public total_service_count: number = 0;
	public selectedService;
	public selectedTags;
	public sortKey = null;
	public editIndex = -1;
	public serviceSateCheck = null;
	public editState = false;
	public selectedState = 'open';
	public states = [
		{
			'state': 'open',
			'selected': true
		},
		{
			'state': 'closed',
			'selected': false
		},
		{
			'state': 'filtered',
			'selected': false
		}
	]
	public attribution = {
		"related_domain": "DNS",
		"host": "DNS",
		"net_block": "Netblock",
		"keyword": "Keyword",
		"manual": "Manual"
	}
	public showAttrubutionLoader  = false
	public updateServiceState =  null
	public inscopeConfig = null
	public inscopeFlag = null
	public showThis = null
	public pocSubjectObsIns;
	constructor(public commonService: CommonService,
		public modalService: BsModalService,
		public modalRef: BsModalRef,
		public portfolioSurveyService: PortfolioSurveyService,) { }


	ngOnInit() {
		// this.scanProfiles = this.commonService.getScanProfileList();
		if (this.services && this.services.length) {
			// this.services.forEach((service) => {
			// 	this.createDropDownList(service.service_name);
			// 	// this.getPortToTypeServicesDropDown(service);
			// 	this.getIspToTypeServiceDropDown(service)

			// })
			this.services.map(ip => {
				// if (ip['scan_profile'] && ip['scan_profile'].length) {
				// 	ip['monitoring'] = true;
				// }
				this.getTags(ip);
			})
			this.oldservices = this.services.slice(0, this.services.length);
		}
		this.inscopeConfig = localStorage.getItem('scope_config')
		if(this.inscopeConfig == 'true'){
		this.showThis = 'inscope'
		} else this.showThis = 'state'
		// this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
		// 	console.log("I am getting called from somewhere")
		// 	this.getService()
		// });
	}
	ngOnChanges(changes: SimpleChange) {
		const changeFilters: SimpleChange = changes['filters']
		const changeTags: SimpleChange = changes['mainDomain']

		if (changeTags || changeFilters) {
			console.log(changeTags, changeFilters)
			if (this.filters && this.filters.page) {
				this.page = this.filters.page
				delete this.filters.page
			}
			this.getService()
			this.getTagsForOpenPorts();
			this.getServicesForOpenPorts()
		}
	}
	getUniqueDomains(domains) {
		// let uniq = []
		// domains = new Set(domains)
		// uniq.push(...Array.from(domains))
		return domains.join(', ');
	}

	getServicePortCheck(app) {
		if (app.protocols.indexOf("443/https") >= 0 || app.protocols.indexOf("80/http") >= 0) {
			return true;
		} else {
			return false;
		}
	}

	showportToTypeServices(value) {
		// this.openDropdown = false;
		// let flag = true;
		// this.services = this.oldservices.slice(0, this.oldservices.length);
		let searchData = [];
		if (value == null) {
			// this.services = this.services.filter(element => {
			// 	element['filter_score'] = 0;
			for (let i = 0; i < this.portToTypeservicesDropDown.length; i++) {
				if (this.portToTypeservicesDropDown[i]['selected'] == true) {
					searchData.push(this.portToTypeservicesDropDown[i]['port']);
				}
				// 	if (element && element.protocols) {
				// 		if (element.protocols.indexOf(this.portToTypeservicesDropDown[i]['port']) > -1 && this.portToTypeservicesDropDown[i]['selected'] == true) {
				// 			flag = false;
				// 			element['filter_score'] = element['filter_score'] ? element['filter_score'] + 1 : 1;

				// 			// return true;
				// 		}
				// 	} else if (this.portToTypeservicesDropDown[i]['selected'] == true) {
				// 		flag = false;
				// 	}
				// }
				// if (element['filter_score'] > 0) {
				// 	return true;
			}
			// })
			let filter_object = {
				"service_name": searchData
			}
			this.filterService(filter_object);
		} else {
			for (let i = 0; i < this.portToTypeservicesDropDown.length; i++) {
				this.portToTypeservicesDropDown[i]['selected'] = false;
			}
			this.services = this.commonService.getJsonCopy(this.oldservices);
		}
		// this.sortPipe.transform(this.services, 'filter_score', -1, true);

		// if (flag) {
		// this.services = this.oldservices.slice(0, this.oldservices.length);
		// }
	}

	getPortToTypeServicesDropDown(element) {
		if (element && element['ip_details'] && element['ip_details']['data'] && element['ip_details']['data'].length > 0) {
			let service = element['ip_details']['data'][0];
			if (service && service.protocols && service.protocols.length > 0) {
				element['protocols'] = service.protocols;
			}
			else {
				element['protocols'] = [];
			}
		} else {
			// if (element.protocols && element.protocols.length > 0 && element.protocols[0] != null) {

			// } else {
			//   element.protocols = [];
			// }
		}
		let typeService = '';
		element.protocols.forEach((protocol) => {
			this.createDropDownList(protocol);
		});
		if (typeService.length > 0) {
			element['typeService'] = typeService.slice(0, -2);
		} else {
			element['typeService'] = '';
		}
	}


	createDropDownList(protocol) {
		let typeService;
		let port2Service = this.port2ServiceNamePipe.transform(protocol);
		if (port2Service) {
			typeService = typeService + protocol + " (" + port2Service + "), ";
			var serviceObject = { name: port2Service, port: protocol, count: 1 };
			var index = this.portToTypeservicesDropDown.findIndex(function (service) {
				return service.port == serviceObject.port;
			});
		} else {
			if (protocol) {
				typeService = protocol + ", " + typeService;
				serviceObject = { name: '', port: protocol, count: 1 };
				index = this.portToTypeservicesDropDown.findIndex(function (service) {
					return service.port == serviceObject.port;
				});
			}
		}
		if (index == -1) {
			this.portToTypeservicesDropDown.push(serviceObject);
		} else {
			this.portToTypeservicesDropDown[index]['count']++;
		}
	}

	filterService(filter_object) {
		this.services = this.filterPipe.transform(this.oldservices, filter_object);
	}

	ClearFilter() {
		this.openDropdown = false;
		this.portToTypeservicesDropDown.map(port => {
			port['selected'] = false;
		})
		this.ispToTypeIpsDropDown.map(isp => {
			isp['selected'] = false;
		})
		this.services = this.commonService.getJsonCopy(this.oldservices);
	}

	// getIspToTypeServiceDropDown(element) {
	// 	if (element && element.netblock_details && element.netblock_details.asn_name) {
	// 		var serviceObject = { name: '', isp: element.netblock_details.asn_name, count: 1 };
	// 		var index = this.ispToTypeIpsDropDown.findIndex(function (service) {
	// 			return service.isp == serviceObject.isp;
	// 		});
	// 		if (index == -1) {
	// 			this.ispToTypeIpsDropDown.push(serviceObject);
	// 		} else {
	// 			this.ispToTypeIpsDropDown[index]['count']++;
	// 		}
	// 	}
	// }

	showIspToTypeIps(value) {
		let searchData = [];
		if (value == null) {

			for (let i = 0; i < this.ispToTypeIpsDropDown.length; i++) {
				if (this.ispToTypeIpsDropDown[i]['selected'] == true) {
					searchData.push(this.ispToTypeIpsDropDown[i]['isp']);
				}

			}
			let filter_object = {
				'netblock_details.asn_name': searchData
			}
			this.filterService(filter_object);
			// })
		} else {
			for (let i = 0; i < this.ispToTypeIpsDropDown.length; i++) {
				this.ispToTypeIpsDropDown[i]['selected'] = false;
			}
			this.services = this.commonService.getJsonCopy(this.oldservices);
		}
		// this.sortPipe.transform(this.services, 'filter_score', -1, true);
		// if (flag) {
		// 	this.services = this.commonService.getJsonCopy(this.oldservices);
		// }
	}

	openRemoveOptions(template: TemplateRef<any>, service = {}) {
		if (this.singleDelete) {
			this.uncheckDelete();
		}
		if (service && service['service_name']) {
			service['selected'] = true;
		}
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	uncheckDelete(a=false) {
			this.services.map(a => {
				a.selected = false;
				a.updated = false;
			})
			this.singleDelete = false;
			this.multiSelectCount = 0
	}
	delService() {
		this.services.forEach(service => {
			if (service['selected'] && service['selected'] == true) {
				if (this.servicesToDelete['ids'] && this.servicesToDelete['ids'].length) {
					this.servicesToDelete['ids'].push(...service.service_id_arr)
				} else {
					this.servicesToDelete['ids'] = [...service.service_id_arr]
				}
				// service.hosts.forEach((host) => {
				// 	if (this.servicesToDelete['ids']) {
				// 		this.servicesToDelete['ids'].push(host['id'])
				// 	} else {
				// 		this.servicesToDelete['ids'] = [host['id']]
				// 	}
				// })
			}
		})
		this.servicesToDelete['archive_reason'] = this.delReason;
		this.deleteService();
	}

	deleteService() {
		this.portfolioSurveyService.deleteService(this.servicesToDelete, this.mainDomain['domain_name']).subscribe(data => {
			this.modalRef.hide();
			alert("Open port deleted successfully!")
			this.multiSelectCount = 0
			this.servicesToDelete = {};
			this.getService();
			this.refreshCount.emit('openports');
		}, error => {
			this.servicesToDelete = {};
			this.modalRef.hide();
			if (error['status'] === 403) {
				alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
			} else {
				alert("Error while updating")
			}
		});
	}

	modalScan(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });

	}


	selctForBulkAction(value) {
		if (value == true) {
			this.services.forEach(ip => {
				this.multiSelectCount++;
				ip.selected = true
			})
		} else {
			this.services.forEach(ip => {
				ip.selected = false
			})
			this.multiSelectCount = 0;
		}
	}

	selectedNewBox(ip) {
		ip.selected = !ip.selected;
		if (ip.selected) {
			this.multiSelectCount++;
		} else {
			this.multiSelectCount = this.multiSelectCount > 0 ? this.multiSelectCount - 1 : 0;
		}
	}

	// getProfileName(spCode) {
	// 	return this.scanProfiles[spCode] ? this.scanProfiles[spCode]['name'] : null
	// }

	deleteDataCheck(value) {
		this.delReason = value;
		this.delService()
	}
	cancelDel(a=false) {
		this.services.map(ser => {
		  ser.selected = false
		})
		this.singleDelete = false;
		this.multiSelectCount = 0
	  }
	

	displayTags(service) {
		if (service.tags && service.tags.length > 0) {
			return true
		} else if (service.takeover_risk && service.takeover_risk >= 2.0) {
			return true
		} else {
			return false
		}
	}
	checkTagInTagsArray(tag) {
		if (this.tags.findIndex(t => tag.includes(t['name'].toLowerCase())) > -1) {
			return true;
		} else {
			return false;
		}
	}
	filterServiceByHighlyTag(value, status) {
		for (let i = 0; i < value.length; i++) {
			this.tags.map(tag => {
				if (tag.name == value[i].toUpperCase()) {
					tag['selected'] = status;
				}
			})
		}
		this.filterServiceByTag(null, true);
	}
	filterServiceByTag(value, status) {

		// this.openDropdown = false;
		let flag = true;
		this.services = this.commonService.getJsonCopy(this.oldservices)
		if (value == 'clear') {
			this.tags.map((tag) => {
				tag['selected'] = false;
			})
			for (var key in this.HighlyTagWithValue) {
				this.HighlyTagWithValue[key]['status'] = false;
			}
		} else {
			if (value == 'AFFILIATE') {
				this.tags.map(t => {
					if (t.name == "SAS" || t.name == "PARKED" || t.name == "REVERSE PROXY" || t.name == "VIRTUAL HOSTING") {
						t.selected = status;
					}
				})
			}
			this.services = this.services.filter(element => {
				if (!this.filterOut) {
					for (let i = 0; i < this.tags.length; i++) {
						if (element.tags && element.tags.some(t => this.tags[i]['value'].includes(t.toUpperCase())) && this.tags[i]['selected'] == true) {
							flag = false;
							return true;
						} else if (this.tags[i]['selected'] == true) {
							flag = false;
						}
					}
				} else {
					for (let i = 0; i < this.tags.length; i++) {
						if (element.tags && element.tags.some(t => this.tags[i]['value'].includes(t.toUpperCase())) && this.tags[i]['selected'] == true) {
							flag = false;
							return false;
						} else if (this.tags[i]['selected'] == true) {
							flag = false;
						}
					}
					return true;
				}
			})
		}
		if (flag) {
			this.services = this.commonService.getJsonCopy(this.oldservices);
		}
	}
	changeTagFilterBasedOnHighyTag(tag) {
		if (this.HighlyTagWithValue[tag.name])
			this.HighlyTagWithValue[tag.name].status = !tag['selected'];
		tag['selected'] = !tag['selected'];
	}
	getTags(service) {
		if (service && service.tags && service.tags.length > 0) {
			this.filterTagsForDropdown(service);
		}
	}
	filterTagsForDropdown(service) {
		if (service) {
			if (service && service.tags != null && service.tags.length > 0) {
				service.tags.forEach((tag) => {
					let tag_uppercase = tag.toUpperCase();
					let tagObject = { name: tag_uppercase, value: [tag_uppercase], selected: false };
					if (tag_uppercase == 'HTTP ACTIVE' || tag_uppercase == 'HTTPS ACTIVE') {
						tagObject['name'] = 'ACTIVE';
					}
					let index = this.tags.findIndex(function (tagOb) {
						return tagOb.name.toUpperCase() == tagObject.name.toUpperCase();
					});
					if (index == -1) {
						this.tags.push(tagObject);
					} else {
						if (this.tags[index]['value'].indexOf(tag_uppercase) == -1) {
							this.tags[index]['value'].push(tag_uppercase)
						}
					}
				});
			}
		}
	}

	checkChildTag(tag) {
		tag = tag.toLowerCase()
		let indexOfAffiliated = this.tags.findIndex(ta => {
			return ta.name == 'affiliate'
		})
		return indexOfAffiliated >= 0 ? (this.tags[indexOfAffiliated]['childTags'].indexOf(tag) >= 0) : false;
	}
	getService() {
		this.showSpinner = true
		var ips = []
		var serviceArray = []
		if (this.mainDomain["domain_name"]) {
			var params = {
				page: this.page,
				limit: this.limit,
				searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
				service: this.selectedService && this.selectedService.length ? this.selectedService : null,
				...this.filters,
				state: this.selectedState && this.selectedState.length ? this.selectedState : null,
				scope: this.inscopeFlag,

			}
			if (this.filterOut) {
				params.exclude_tags = this.selectedTags && this.selectedTags.length ? this.selectedTags : null
			}
			if (!this.filterOut) {
				params.tags = this.selectedTags && this.selectedTags.length ? this.selectedTags : null
			}

			if (this.sortKey) {
				let sortKeysArr = this.sortKey.split(',')
				sortKeysArr = sortKeysArr.map(key => { return this.order == -1 ? key : '-' + key })
				params.sortKey = sortKeysArr.join(',')
			}
			this.portfolioSurveyService.getService(this.mainDomain["domain_name"], params).subscribe(data => {
				this.showSpinner = false
				if (data) {
					this.services = data['data'].results
					if (data['data'] && (data['data']['count'] || data['data']['count'] == 0))
						this.total_service_count = data['data']['count']
					if (this.services) {
						this.services.forEach(ser => {
							ser.subjectTypeName = {
								subject_type: 'service',
								ip_for_service: ser['ip_id__ip_address'],
								subject_name: ser['ip_id__ip_address'] + '-' + ser['service_name'],
								service_subject_name: ser['service_name']
							  }
							ips.push(ser['ip_id__ip_address'])
							serviceArray.push(ser['ip_id__ip_address']+ '-' + ser['service_name'])
						});
					}
					this.getHostForService(ips)
					this.getObsInsCountsForEachService(serviceArray)

				} else {
				}
			}, error => {
				this.commonService.errorForDomain(error);
			})
		}
	}
	goToPage(val) {
		this.page = val;
		this.getService()
	}
	searchAllOpenPortsDetail(value) {
		this.page = 1;
		if (value === 'search') {
			this.searchKey = null
		} else if (value === 'service') {
			this.selectedService = null
			this.portToTypeservicesDropDown.forEach(ser => {
				ser.selected = false
			});
		}  else if (value === 'state') {
			this.selectedState = 'open'
			this.states.forEach(state => {
				if (state.state == 'open') state.selected = true
				else state.selected = false
			});
		} else if (value === 'tag') {
			this.selectedTags = null
			this.tags.forEach(tag => {
				tag.selected = false
			});
		}
		this.getService()

	}
	searchOpenPortsDetail(domain) {
		this.page = 1;
		this.getService()
	}

	getHostForService(list) {
		this.showAttrubutionLoader =  true
		this.portfolioSurveyService.getHostForService(this.mainDomain['domain_name'], list).subscribe(data => {
			if (this.services) {
				this.services.forEach(ser => {
					let serviceData = data[ser['ip_id__ip_address']]
					if (serviceData) {
						serviceData.forEach(d => {
							if (ser.hosts) {
								ser.hosts.push({ 'host': d.host, "subject_name": d.subject_name, "id": d.id, "subject_type": d.subject_type})
							} else {
								ser.hosts = [{ 'host': d.host, "subject_name": d.subject_name, "id": d.id, "subject_type": d.subject_type }]
							}
						});
					}
				});
			}
			this.showAttrubutionLoader =  false
		},(error) => {
			this.showAttrubutionLoader =  false
            alert("Error while fetching attribution methods");
		})
	}
	getServicesForOpenPorts() {
		this.portfolioSurveyService.getServicesForOpenPorts(this.mainDomain['domain_name']).subscribe(data => {
			this.portToTypeservicesDropDown = data['data']

		},(error) => {
            alert("Error while fetching services");
		})

	}
	getTagsForOpenPorts() {
		this.portfolioSurveyService.getTagsForOpenPorts(this.mainDomain['domain_name']).subscribe(data => {
			this.tags = []
			data['data'].forEach(tag => {
				this.tags.push({
					name: tag,
					selected: false
				})
			})
			// 	let tag_uppercase = tag.toUpperCase();
			// 	let tagObject = { name: tag_uppercase, value: [tag_uppercase], selected: false };
			// 	if (tag_uppercase == 'HTTP ACTIVE' || tag_uppercase == 'HTTPS ACTIVE') {
			// 		tagObject['name'] = 'ACTIVE';
			// 	}
			// 	let index = this.tags.findIndex(function (tagOb) {
			// 		return tagOb.name.toUpperCase() == tagObject.name.toUpperCase();
			// 	});
			// 	if (index == -1) {
			// 		this.tags.push(tagObject);
			// 	} else {
			// 		if (this.tags[index]['value'].indexOf(tag_uppercase) == -1) {
			// 			this.tags[index]['value'].push(tag_uppercase)
			// 		}
			// 	}
			// });
		},(error) => {
            alert("Error while fetching tags");
		})
	}
	filterOpenPortsByServices() {
		this.page = 1;
		const selectedServiceArr = this.portToTypeservicesDropDown.map(t => {
			if (t.selected) {
				return t.service_name
			}
		})
		this.selectedService = selectedServiceArr.filter(t => t).join(',')
		this.filters = { ...this.filters, service_name: this.selectedService };

		this.getService()
	}
	filterOpenPortsByTags() {
		this.page = 1;
		const selectedTagsArr = this.tags.map(t => {
			if (t.selected) {
				return t.name
			}
		})
		this.selectedTags = selectedTagsArr.filter(t => t).join(',')
		this.filters = { ...this.filters, tags: this.selectedTags };
		this.getService()
	}
	updateOpenPorts(data, state) {
		data.state = state
		if (this.serviceSateCheck === data.state) {
			this.editIndex = -1
			this.editState = false
		}
		else {
			this.editIndex = -1
			this.editState = false
			let dataToSend = {
				"id": data.service_id_arr,
				"state": data.state

			}
			this.portfolioSurveyService.updateOpenPorts(this.mainDomain["domain_name"], dataToSend).subscribe(
				(data) => {
					alert("Service updated successfully!")
					this.updateServiceState =null
				},
				(error) => {
					alert("Error while updating!")
					this.updateServiceState =null

				}
			);
		}
	}
	filterOpenPortsByState() {
		this.page = 1;
		const selectedStateArr = this.states.map(t => {
			if (t.selected) {
				return t.state
			}
		})
		this.selectedState = selectedStateArr.filter(t => t).join(',')
		this.filters = { ...this.filters, state: this.selectedTags };

		this.getService()
	}
	showStateInHeader(){
		if (this.selectedState){
			var words = this.selectedState.split(',');  
			var CapitalizedWords = [];  
			words.forEach(element => {  
				CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));  
			});  
			return CapitalizedWords.join(',');  
		} else return "Open"
	}
	filterByInscope(value) {
		this.page = 1;
		this.inscopeFlag = value;
		this.filters = { ...this.filters, scope: this.selectedTags };
		this.getService();
		this.openDropdown = false;
	  }
	getObsInsCountsForEachService(serviceArray) {
		this.obsInsCountSpinner = true
		const params = {
		  subject_ref_list: serviceArray,
		  subject_type: 'service'
		}
		this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain['domain_name'], params).subscribe(data => {
		  this.obsInsCountSpinner = false
		  this.servicesDetailsForservice = data['data']
		  this.services.forEach((service) => {
			let serviceName = service.ip_id__ip_address + '-' + service.service_name
			if (this.servicesDetailsForservice[serviceName]) {
			  service.obs_count = this.servicesDetailsForservice[serviceName].count_obs
			  service.ins_count = this.servicesDetailsForservice[serviceName].count_ins
			}
		  })
		})
	}
}