import { Component, OnInit, OnChanges, SimpleChanges
	, Input, EventEmitter, TemplateRef, Output
	, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from '../../../_services';
import { reasonToDeleteFootPrint } from 'app/_services/common-var.service';
@Component({
  selector: 'app-comman-actions',
  templateUrl: './comman-actions.component.html',
  styleUrls: ['./comman-actions.component.scss']
})
export class CommanActionsComponent implements OnInit {
	
	@Input() showDowloadText;
	@Input() elements;
    @Input() fileName;
	@Input() mainDomainObject;
	@Input() openPopup;
	@Output() deleteData = new EventEmitter<any>();
	@Output() cancelDeleteBulk = new EventEmitter<any>();
	@Input() numberOfEntites = 0
	// @Output() scanProfileMatrix = new EventEmitter<any>();
	@Input() openDeleteRef: Boolean;
	@Input ()filedsJson;
	@Input() isUserTrial;
	public reasonToDeleteFootPrint = reasonToDeleteFootPrint;
	public bulkArr: any = [];
	public scanValue: any = {}
	public showScanOption: Boolean = false;
	public showBulk: Boolean = false
	public delReason = '';
	public scanProfiles;
	checkedPortCheck = false;
	checkedVaCheck = false;
	@ViewChild('policyModal') policyModal1: TemplateRef<any>;

  constructor(
	public commonService: CommonService,
	public modalService: BsModalService,
	public modalRef: BsModalRef,
	public portfolioSurveyService: PortfolioSurveyService,
	public cdr: ChangeDetectorRef
  ) { }

  public dowloadReport:boolean = false;

  ngOnInit() {
    this.scanProfiles = this.commonService.getScanProfileList();
  }

  objectKeys(object2) {
	return Object.keys(object2);
  }
  ngOnChanges(changes: SimpleChanges) {
		if (this.openPopup === 'profileModal') {
			this.openPolicyModal(this.policyModal1);
			this.cdr.detectChanges();
		}
		// this.cdr.detectChanges();
	}

//   ngAfte
	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
  saveBulkOpration(value) {
		this.scanValue={}
		if(value){
			this.showScanOption =true
		}
		this.bulkArr = []
		if (value === 'port_monitoring') {
			this.elements.forEach(ele => {
				if (ele.selected)
					this.bulkArr.push(ele)
			})
			// alert("Active Monitoring Started Successfully !!")
			this.showBulk = false
			this.scanValue['port_monitoring'] = true;
		} else if (value === 'va_monitoring') {
			this.elements.forEach(ele => {
				if (ele.selected)
					this.bulkArr.push(ele)
			})
			// alert("Scan Started Successfully!!")
			this.showBulk = false
			this.scanValue['va_monitoring'] = true;
			
		} else if (value === 'deep_monitoring') {
			this.elements.forEach(ele => {
				if (ele.selected)
					this.bulkArr.push(ele)
			})
			// alert("Scan Started Successfully!!")
			this.showBulk = false
			this.scanValue['deep_monitoring'] = true;
			this.scanValue['bulkArr'] = this.bulkArr;
			
		} else if (value === 'va_port_monitoring') {
			this.elements.forEach(ele => {
				if (ele.selected)
					this.bulkArr.push(ele)
			})
			this.showBulk = false
			this.scanValue['va_monitoring'] = true;
			this.scanValue['port_monitoring'] = true;
		}
		this.showBulk = false

	}

	openPolicyModal(template: TemplateRef<any>) {
		this.scanValue = {};
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
		this.cdr.detectChanges();
	}
	
	hideScanModel() {
		// this.scanProfileMatrix.emit(false)
		this.modalRef.hide()
		this.showScanOption = true
		this.showBulk = false;
		this.delReason = ''
	}
	openRemoveOptions(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	delData() {
		this.deleteData.emit(this.delReason);
		this.hideScanModel();
	}
	cancelDelete() {
		this.cancelDeleteBulk.emit(true);
		this.hideScanModel();
	}

	activeScanMontoring(scanProfile, event) {
		let checked = event['target']['checked'];
		// if(scanProfile === 'port_monitoring') {
		this.scanValue[scanProfile] = checked;
		if(scanProfile == 'deep_monitoring') {
			this.elements.forEach(ele => {
				if (ele.selected)
					this.bulkArr.push(ele)
			})
			// alert("Scan Started Successfully!!")
			this.showBulk = false
			// this.scanValue['deep_monitoring'] = true;
			this.scanValue['bulkArr'] = this.bulkArr;
		}
		// } else {
			// this.scanValue['va_monitoring'] = checked;
		// }
	}
// 	emmitProfileType() {
// 		// this.portfolioSurveyService.createScanProfilesForDomains(this.mainDomainObject['domain_name'], this.scanValue).subscribe(data => {
// 		// })
// 		console.log(this.scanValue)
// 		this.scanProfileMatrix.emit(this.scanValue)
// 		this.hideScanModel();
// 	}
}
