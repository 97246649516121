import { Injectable, EventEmitter } from "@angular/core";

import { HttpService } from "./http.service";

import {
  DWConfig,
} from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})

export class DwInsightsService {
  public dwBaseUrl = DWConfig.url;

  constructor(
    public httpSVC: HttpService,
  ) { }

  public getEntityTimeline(domain, entityType, data) {
    let httpUrl = this.dwBaseUrl + "/stats/"+domain+"/"+entityType;
    if (data.granularity) {
      httpUrl = httpUrl+"?granularity="+data.granularity
    } else httpUrl = httpUrl+"?granularity=weekly"
    if(data.limit) httpUrl = httpUrl + "&limit=" + data.limit;
    if(data.scope_config) httpUrl = httpUrl + "&scope_config=" + data.scope_config;
    if(data.scope_param) httpUrl = httpUrl + "&scope_param=" + data.scope_param;


    return this.httpSVC.get(httpUrl);
  }
}