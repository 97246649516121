import { Component, OnInit, Input } from "@angular/core";
import { CommonService } from "app/_services/common.service";

@Component({
  selector: "app-whois-domain",
  templateUrl: "./whois-domain.component.html",
  styleUrls: ["./whois-domain.component.scss"],
})
export class WhoisDomainComponent implements OnInit {
  @Input() whoisForDomain: any;

  constructor(public commonService: CommonService) {}

  ngOnInit() {}
}
