import {
  Component,
  OnInit,
  DoCheck,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RolesService } from "../../_services/roles.service";
import { ProfileTypePipe } from "app/pipes/profile-type.pipe";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AssessmentService } from '../../_services';
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { AuthService } from "app/views/pages/auth.service";
import { TrialCheckService } from "app/_services/trial-check.service";
import { TrialExpiredService } from "app/_services/trial-expired.service";
import { Idle_logout_popup_emiterService } from "app/_services/idle_logout_popup_emiter";
import { LogoutEmmitor } from "app/_services/logout-emitor.service";
import { alert_center_sidebar } from "../../../environments/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./app-sidebar.component.html",
  styleUrls: ["./app-sidebar.component.scss"],
})
export class AppSidebar implements OnInit, DoCheck {
  public params;
  public usertype;
  public totalPausedAssessments;
  modalRef?: BsModalRef;
  dialogModalRef?: BsModalRef;
  trialExpiredRef?:any = null;
  @ViewChild("template")
  private modalTemplate: TemplateRef<any>;
  public paramDomain:string = '';
  @ViewChild("alertDomainMissmatch") alertDomainMissmatch;
  @ViewChild("trialExpired") trialExpiredModal;
  @ViewChild("idleLogout") idleLogout;
  public isTrialUser:boolean = true;
  public trialExpiredSubscription:any = null;

  public idleLogoutEmmitorSubscription:any = null;
  public idleLogoutEmmitorModalRef:any = null;
  public max_hours_of_inactivity_before_logout;
  public showAlertSidebar  = alert_center_sidebar;
  constructor(
    public route: ActivatedRoute,
    public rolesService: RolesService,
    public assessmentService: AssessmentService,
    private modalService: BsModalService,
    public portfolioSurveyService: PortfolioSurveyService,
    private router: Router,
    public authService:AuthService,
    public trialExpired: TrialExpiredService,
    public trialCheckService: TrialCheckService,
    public idleLogoutEmmitor: Idle_logout_popup_emiterService,
    public logoutEmitor: LogoutEmmitor
  ) {}
  ngOnDestroy() {
      if(this.idleLogoutEmmitorModalRef) this.idleLogoutEmmitorModalRef.hide();
      if(this.trialExpiredRef) this.trialExpiredRef.hide();
      this.trialExpiredSubscription.unsubscribe();
      this.trialExpiredRef = null;
      this.trialExpiredSubscription = null;

      this.idleLogoutEmmitorSubscription.unsubscribe();
      this.idleLogoutEmmitorSubscription = null;
      this.idleLogoutEmmitorModalRef = null;
  }
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params && params["domain"]) {
        this.paramDomain = params["domain"];
        this.params = params;
        this.getPausedAssessmentCount();
        this.getDomainDetails(params['domain'])
        let that = this;
        setTimeout(function(){
          that.checkValidTokenDomain(params["domain"]);
        },1000);
        this.isTrialUser = this.trialCheckService.isTrailUser();
      }
    });
    this.trialExpiredSubscription = this.trialExpired.onChange().subscribe(data => {
      if (!this.trialExpiredRef && data && data == true) {
        this.trialExpiredRef = this.modalService.show(this.trialExpiredModal, { class: "dialog-model", backdrop: 'static', keyboard: false });
      }
    });
    this.checkNavChange();
    this.checkIdleLogout();
  }
  checkNavChange(){
    this.router.events.subscribe((event) => {
      if (event) {
        this.route.queryParams.subscribe((params) => {
          if(!params['domain']) this.params = params;
        });
      }
    });
  }

  ngDoCheck() {
    this.rolesService.setProfile();
    this.usertype = new ProfileTypePipe().transform("usertype");
  }

  openModal() {
    // alert("Inise Open Modal");
    this.modalRef = this.modalService.show(this.modalTemplate);
  }
 

  getPausedAssessmentCount() {
    const params = {
      page: 1,
      limit: 1,
      status: 'paused',
    }
    this.assessmentService.getAttackListDetails(params).subscribe((data: any) => {
      if (data) {
        this.totalPausedAssessments = data.count
      }
    })
  }
  getDomainDetails(domain) {
    this.portfolioSurveyService
            .getMainDomainDetails(domain)
            .subscribe(
              (data) => {
                localStorage.setItem('info_vul_config', data['data'].info_vul_config)
                if (data && data['data'] && data['data'].scope_config) {
                  localStorage.setItem('scope_config', data['data'].scope_config)
                } else {
                  localStorage.setItem('scope_config', 'false')
                }
              },
              (error) => {
                console.log(error)
              }
            );
  }
  checkValidTokenDomain(domain){
    let currentDomain = domain;
    let decodedToken = JSON.parse(localStorage.getItem('profile'));
    let primaryDomain = '';
    if(decodedToken && decodedToken.primary_domain){
      primaryDomain = decodedToken.primary_domain;
    }
    if(currentDomain && primaryDomain){
      if(currentDomain!=primaryDomain){
        console.error(currentDomain,primaryDomain);
        // console.error("Your current domain "+currentDomain+" is not your primary domain, data discrepancy may happen. Please select as your primary domain.");
        if(!this.idleLogoutEmmitorModalRef) // checking session alredy expired or not
        this.dialogModalRef = this.modalService.show(this.alertDomainMissmatch,{ class:"dialog-model", backdrop: 'static', keyboard: false });
      }else{
        console.warn('Domain verified')
      }
    }
  }
  gotoDomainSelectionPage(){
    this.router.navigate(["/new/self-domains"]);
    if(this.dialogModalRef) this.dialogModalRef.hide();
  }
  makeLogout(){
    this.trialExpiredRef.hide();
    if(this.idleLogoutEmmitorModalRef) this.idleLogoutEmmitorModalRef.hide();
    this.logoutEmitor.emit('LogingOut');
    this.authService.logout();
  }
  checkIdleLogout(){
    this.idleLogoutEmmitorSubscription = this.idleLogoutEmmitor.onChange().subscribe(data => {
      if (!this.idleLogoutEmmitorModalRef && data && data > 0) {
        this.max_hours_of_inactivity_before_logout = data;
        let that = this;
        setTimeout(function(){
          if(!that.idleLogoutEmmitorModalRef){
            that.idleLogoutEmmitorModalRef = that.modalService.show(that.idleLogout, { class: "dialog-model", backdrop: 'static', keyboard: false });
          }
        },500)
        localStorage.removeItem('id_token');
      }
    });
  }
  makeIdleLogout(){
    if(this.idleLogoutEmmitorModalRef) this.idleLogoutEmmitorModalRef.hide();
    if(this.trialExpiredRef) this.trialExpiredRef.hide();
    this.logoutEmitor.emit('LogingOut'); 
    this.authService.logout();
  }
}