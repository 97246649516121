import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-runflow',
  templateUrl: './runflow.component.html',
  styleUrls: ['./runflow.component.scss']
})
export class RunflowComponent implements OnInit {

  public nodeSelected 
  public selectedActions = []
  public stepActionVisible
  public layoutSettings = {
    // orientation: 'TB'
  };
  constructor() { }

  @Input() runbookToView
  ngOnInit(): void {
  }

  showNodeDetails(val) {
    this.nodeSelected = val.node
    this.stepActionVisible = val.visibility
  }

  showStepActions(val){
    this.selectedActions = val.actions
    this.stepActionVisible = val.visibility
  }
  
}
