import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-third-party-risk',
  templateUrl: './third-party-risk.component.html',
  styleUrls: ['./third-party-risk.component.scss']
})
export class ThirdPartyRiskComponent implements OnInit {

  @Input() isUserTrial = false;
  @Input() userDomains:any;
  @Input() mainDomain: null;
  @Input() showExecutiveReport;

  public showSpinner = true;
  public showError = '';
  public vendorDomains;
  public seeMore = 20;
  public digitalRiskCountsForDomains;
  public objectToDisplay = [];
  public sortKey = null;
  public order = 1;
  public showNoDomains: boolean = false;
  public initialSort = false;
  public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
  public showDowloadText:boolean = false;
  public maliciousList;

  constructor(
    private portfolioSurveyService: PortfolioSurveyService,
	private modalService: BsModalService, 
	private modalRef: BsModalRef
  	) {}



  ngOnInit() {
  	if(this.userDomains && this.userDomains.length){
  		this.getDomainsCounts(this.userDomains)
  	} else {
	  	this.getUserVendorDomains()
  	}
  }

  getUserVendorDomains() {
    this.portfolioSurveyService.getDomainsSearchForUser().subscribe(data => {
      this.userDomains = data;
      if(this.userDomains && this.userDomains.length <= 1) {
      	this.showNoDomains = true;
      }
      this.getDomainsCounts(data);
    }, error => {
      this.showSpinner = false;
      this.showError = "Error while connecting to server! Please try after some time.";
    })
	}


	getDomainsCounts(domains) {
		this.portfolioSurveyService.getDigitalRiskCountForDomains(domains, this.mainDomain).subscribe(digitalRiskForDomains => {
      	this.digitalRiskCountsForDomains = digitalRiskForDomains;
      	var vdObject;
      	this.userDomains.forEach(vd => {
      		vdObject = {
      			"domain": vd['domain'],
      			"leackedCredsCount" : this.getLeakedCred(vd['domain']),
      			"vulnerabilitiesCount": this.getVulnerablity(vd['domain']),
      			"maliciousCount": this.getMalicious(vd['domain']),
      			"phishingDomainssCount": this.getPhishing(vd['domain']),
      			"mentionsCount": this.getMentions(vd['domain']),
      			"domain_type": vd['domain_type']
      		}
      		this.objectToDisplay.push(vdObject);
      	})
      })
      this.showSpinner = false;
	}

	getLeakedCred(domainName) {
		if(this.digitalRiskCountsForDomains && this.digitalRiskCountsForDomains['leakedCredCount'].length > 0) {
			var arrayToLook = this.digitalRiskCountsForDomains['leakedCredCount'];
			var index = arrayToLook.findIndex(i => i.domain == domainName);
			if(index >= 0 && arrayToLook[index]['count'] && arrayToLook[index]['count'] >= 0)
				return arrayToLook[index]['count'];
			else if (index >= 0 && (!arrayToLook[index]['count'] || arrayToLook[index]['count'] < 0))
				return 'NA'
			else
				return 0;
		} else {
			return 0;
		}
	}
	getVulnerablity(domainName) {
		if(this.digitalRiskCountsForDomains && this.digitalRiskCountsForDomains['vulnerabilityCount'].length > 0) {
			var arrayToLook = this.digitalRiskCountsForDomains['vulnerabilityCount'];
			var index = arrayToLook.findIndex(i => i.domain == domainName);
			if(index >= 0)
				return arrayToLook[index]['count'];
			else
				return 0;
		} else {
			return 0;
		}
	}
	getMalicious(domainName) {
		if(this.digitalRiskCountsForDomains && this.digitalRiskCountsForDomains['maliciousCount'].length > 0) {
			var arrayToLook = this.digitalRiskCountsForDomains['maliciousCount'];
			var index = arrayToLook.findIndex(i => i.domain == domainName);
			if(index >= 0)
				return arrayToLook[index]['count'];
			else
				return 0;
		} else {
			return 0;
		}
	}
	getPhishing(domainName) {
		if(this.digitalRiskCountsForDomains && this.digitalRiskCountsForDomains['phishingCount'].length > 0) {
			var arrayToLook = this.digitalRiskCountsForDomains['phishingCount'];
			var index = arrayToLook.findIndex(i => i.domain == domainName);
			if(index >= 0)
				return arrayToLook[index]['count'];
			else
				return 0;
		} else {
			return 0;
		}
	}
	getMentions(domainName) {
		if(this.digitalRiskCountsForDomains && this.digitalRiskCountsForDomains['mentionCount'].length > 0) {
			var arrayToLook = this.digitalRiskCountsForDomains['mentionCount'];
			var index = arrayToLook.findIndex(i => i.domain == domainName);
			if(index >= 0)
				return arrayToLook[index]['count'];
			else
				return 0;
		} else {
			return 0;
		}
	}
	goToExternamOsint(domain) {
	    window.open("#/new/digital-risk?domain=" + domain, "_blank")
	}
	openRemoveOptions(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
}
