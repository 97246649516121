import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AllNotificationComponent } from "./shared/all-notification/all-notification.component";
import { LabsV2Component } from "./newviews/labs-v2/labs-v2.component";

// Import Containers
import { FullLayout, SimpleLayout } from "./containers";
import { RoleGuard } from "./_guards/role-guard.service";
import { AuthGuardService } from "./_guards/auth-guard.service";
import { AttackSurfaceComponent } from "./views/dashboard/dashboard-v3/attack-surface/attack-surface.component";

export const routes: Routes = [
  {
    path: "",
    component: FullLayout,
    canActivateChild: [AuthGuardService],
    canActivate: [AuthGuardService],
    data: {
      title: "",
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "dashboard-attack-surface",
        component: AttackSurfaceComponent,
        canActivateChild: [AuthGuardService],
        data: {
          title: " Dashboard / Attack Surface",
        },
      },
      {
        path: "portfolio",
        loadChildren: () =>
          import("./views/portfolio/portfolio.module").then(
            (m) => m.PortfolioModule
          ),
      },
      // {
      //   path: 'reports',
      //   loadChildren: './views/reports/reports.module#ReportsModule'
      // },
      // {
      //   path: 'components',
      //   loadChildren: './views/components/components.module#ComponentsModule'
      // },
      // {
      //   path: 'icons',
      //   loadChildren: './views/icons/icons.module#IconsModule'
      // },
      {
        path: "widgets",
        loadChildren: () =>
          import("./views/widgets/widgets.module").then((m) => m.WidgetsModule),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./views/chartjs/chartjs.module").then((m) => m.ChartJSModule),
      },
      {
        path: "externalosint",
        loadChildren: () =>
          import("./views/externalosint/externalosint.module").then(
            (m) => m.ExternalosintModule
          ),
      },
      {
        path: "digital_footprint",
        loadChildren: () =>
          import("./views/digitalfootprint/digitalfootprint.module").then(
            (m) => m.DigitalfootprintModule
          ),
      },
      {
        path: "new",
        loadChildren: () =>
          import("./newviews/newviews.module").then((m) => m.NewviewsModule),
      },
      {
        path: "playbook",
        loadChildren: () =>
          import("./runbook/runbook.module").then((m) => m.RunbookModule),
      },
      {
        path: "tour",
        loadChildren: () =>
          import("./tour/tour.module").then((m) => m.TourModule),
      },
      {
        path: "all_notifications",
        component: AllNotificationComponent,
      },
      {
        path: "labs/risks",
        component: LabsV2Component,
        data: {
          title: "Risk",
        },
      },
      {
        path: "reports",
        data: {
          title: "",
        },
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./automated-reports/automated-reports.module").then(
                (m) => m.AutomatedReportsModule
              ),
          },
        ],
      },
      {
       path: "ecsm",
       loadChildren: () =>
         import("./secrets/secrets.module").then((m) => m.SecretsModule),
      },
      {
        path: "csp",
        data: {
          title: "",
        },
        children: [
          {
            path: "",
            loadChildren: () => import("./cspdata/cspdata.module").then(
              (m) => m.CspdataModule
            ),
          },
        ],
      },
      {
        path: "seed-config",
        data: {
          title: "",
        },
        children: [
          {
            path: "",
            loadChildren: () => import("./seed_config/seedconfig.module").then(
              (m) => m.SeedconfigModule
            ),
          },
        ],
      },
      {
        path: "new-config",
        data: {
          title: "",
        },
        children: [
          {
            path: "",
            loadChildren: () => import("./new-config/new-config.module").then(
              (m) => m.NewConfigModule
            ),
          },
        ],
      }
    ],
  },
  {
    path: "pages",
    component: SimpleLayout,
    data: {
      title: "Pages",
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./views/pages/pages.module").then((m) => m.PagesModule),
      },
    ],
  },
  // {
  //   path: "csp",
  //   component: FullLayout,
  //   canActivateChild: [AuthGuardService],
  //   canActivate: [AuthGuardService],
  //   data: {
  //     title: "Labs",
  //   },
  //   children: [
  //     {
  //       path: "",
  //       data: {
  //         title: "",
  //       },
  //       loadChildren: () =>
  //         import("./cspdata/cspdata.module").then((m) => m.CspdataModule),
  //     },
  //   ],
  // },
  // {
  //   path: "seed-config",
  //   // component: FullLayout,
  //   canActivateChild: [AuthGuardService],
  //   canActivate: [AuthGuardService],
  //   data: {
  //     title: "Configuration",
  //   },
  //   children: [
  //     {
  //       path: "",
  //       data: {
  //         title: "",
  //       },
  //       loadChildren: () =>
  //         import("./seed_config/seedconfig.module").then(
  //           (m) => m.SeedconfigModule
  //         ),
  //     },
  //   ],
  // },
  {
    path: "**",
    redirectTo: "login",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
