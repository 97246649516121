import { Injectable, EventEmitter } from "@angular/core";

import { HttpService } from "./http.service";

import {
    BenchmarkConfig,
	ExpertBotConfig,
	AirtableConfig
} from "../../environments/environment";

@Injectable()
export class ExpertService {
    public benchmarkBaseUrl = BenchmarkConfig

    public expertConfig = ExpertBotConfig.url;

    private headers = new Headers({
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("id_token"),
	});


  constructor(
    public httpSVC: HttpService
  ) { }

  public getEntityTypes(assessment_id,runstep_id){
    // var url = "https://apis-qa.firecompass.com/expertbot/expert/details/ff818029-afc6-49b2-9ce9-91006c06d2c8/dndnode_81pt2x"
    var url = this.expertConfig + "/expert/details/"+ assessment_id + "/" + runstep_id
    return this.httpSVC.get(url);
  }
  public getEntityDetail(assessment_id,runstep_id,entity_type, page){
    // var url = this.expertConfig +  "/expert/entities/7451dec8-2593-4834-8bfe-0d707835dbfb/dndnode_81pt2x?entity_type=" + entity_type //+ assessment_id + "/" + runstep_id + "/" + "?entity_type=" + entity_type
    var url = this.expertConfig +  "/expert/entities/"+ assessment_id + "/" + runstep_id  + "?entity_type=" + entity_type + "&page=" + page 
    return this.httpSVC.get(url);
  }
  public archiveUnarchive(assessment_id,runstep_id,payload){
    var url = this.expertConfig + "/expert/archive/"+ assessment_id + "/" + runstep_id
    return this.httpSVC.put(url,payload);
  }
  public getNextStepCount(assessment_id,runstep_id){
    var url = this.expertConfig + "/expert/assessment/next/step/count/"+ assessment_id + "/" + runstep_id + "?archive=False"
    return this.httpSVC.get(url);
  }
  public resumeProcess(assessment_id,runstep_id){
    var url = this.expertConfig + "/expert/submit/"+ assessment_id + "/" + runstep_id + "?archive=False"
    return this.httpSVC.post(url,{});
  }
}

