import { Component, OnInit, Input, TemplateRef, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from '../../_services';
import { reasonToDeleteFootPrint, technology_base_url } from 'app/_services/common-var.service';
import { TrialCheckService } from "../../_services/trial-check.service";
@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {

    @Input() technologies: any;
    @Input() mobileApplication: any;
    @Input() mainDomain: any;
    @Input() isUserTrial: boolean;
    @Input() filters: any;
    @Input() showHeaderActions: boolean = true
    @Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
    public webApplication
    public infoVal = localStorage.getItem('info_vul_config')=="true"?true:false
    public sortWithWeb = 'vulnerabilities';
    public initialSortWeb: boolean = true;
    public orderWeb = -1;
    public initialSort: boolean = true;
    public seeMoreMobile: number = 20;
    public order = -1;
    public sortWith = 'vulnerabilities';
    public startDay: number = 7;
    public show_max_list: number = 3;
    public showItem_i
    public showItem_j
    public showItem_url
    public vulnerability: any = []
    public showVul = -1;
    public webScoreDetails: any;
    public sslScoreDetails: any;
    public showMobVul = -1;
    public showWebVul = -1;
    public cve_details: any;
    public cve_details_web: any
    public addNewJSON: boolean = false;
    public showFileName: boolean = false
    public appToScan: any = {}
    public scanNowIos: any = {}
    public scanNowButtonDisabled = true;
    public uploadedFiles;
    public showSpinnerWhileUploadFile: Boolean = false;
    public reasonToDeleteFootPrint = reasonToDeleteFootPrint;
    public newMob: any = {};
    public filterObj = { "module": ["deepc"] }
    public appUrls = []
    public technology_base_url = technology_base_url
    public appScansPurchases;
    public count = 0;
    // public servicePurchaseForApp;
    public scanEntityType;
    public userEmail;
    public allServiceUsages = [];
    public usageToCheck;
    public showThis = 'applications';
    public showDowloadText: boolean = false;
    scanEntity: any[];
    public showReloadMobileAppSpinner: boolean = false;
    public showUploadFileInput: boolean = false;
    public showThisScan = null;
    public showScanBtn = true;
    public deleteType = "mobile";
    public monitors = []
    public webArray = []
    public obsInsCountSpinner = false;
    public vulDetailForWeb;
    public pocSubjectTouchPoint;
    public pocSubjectComment;
    public pocSubjectObsIns;
    public total_webApp_count: number = 0;
    public mobileAppFieldsToDownload = {
        'Platform': 'platform',
        'Source Url': 'source_url',
        'No.of Downloads': 'downloads',
        'Critical Vulnerability': 'criticalVulCount',
        'High Vulnerability': 'highVulCount',
        'Medium Vulnerability': 'mediumVulCount',
        'Low Vulnerability': 'lowVulCount',
        'Created At': 'created_at',
        'Updated At': 'updated_at',
        "Comments": "comments",
        // "Touchpoints": "touchpoints"
    }

    public webAppFieldsToDownload = {
        'Source Url': 'source_url',
        // 'SSL Grade': 'ssl_grade',
        // 'Web Grade': 'web_grade',
        'Critical Vulnerability': 'criticalVulCount',
        'High Vulnerability': 'highVulCount',
        'Medium Vulnerability': 'mediumVulCount',
        'Low Vulnerability': 'lowVulCount',
        'Info Vulnerability': 'infoVulCount',
        'Created At': 'created_at',
        'Updated At': 'updated_at',
        "Comments": "comments",
        // "Touchpoints": "touchpoints"
    }
    public VulTabsToShow = [{
        'name': 'Deepc',
        'module': 'deepc'
    },
    {
        'name': 'Appknox',
        'module': 'appknox'
    },
    {
        'name': 'Manual',
        'module': 'manual'
    }]
    public showMod = "deepc";
    public addNewMobFileInput: boolean = false;
    public disableScanButton = false;
    // public delReason: string = null;
    public commentDataWeb: boolean = false
    public commentDataMobile: boolean = false;
    public page = 1;
    public limit = 10;
    public searchKey = null;
    public showSpinner = true
    public technologiesFilter = []
    public openDropdown = false;
    public showThisFilter='expiry'
    public formatTechnologieStr = null;
    public techFilterValues = []
    public scopes = [
        { value:'IN', label:'In scope'},
        { value:'OUT', label:'Out of scope'},
        { value:'NOT_DEFINED', label:'Not Defined'},
        { value:'ALL', label:'All'}
    ];
    public selectedScope = '';
    public isScopeActive:any = localStorage.getItem('scope_config');
    
    public selectedExpiredData:any = null;
    public expiryData: any = [
        { name: "Show All", value: null },
        { name: "Expired", value: 0 },
        { name: "Expiring in 7 days", value: 7 },
        { name: "Expiring in 30 days", value: 30 },
        { name: "Expiring in 60 days", value: 60 },
        { name: "Expiring in 90 days", value: 90 }
    ];
    public trialUser = true;
    constructor(public commonService: CommonService,
        public modalService: BsModalService,
        public modalRef: BsModalRef,
        public trialCheck:TrialCheckService,
        public portfolioSurveyService: PortfolioSurveyService) { 
            if(this.isScopeActive=='true'){
                this.selectedScope = 'IN';
            }
            this.trialUser = trialCheck.isTrailUser();
        }


    ngOnInit() {
        this.getwebapplication()
        this.getTechnologiesName()
        this.forEachMobile();
        let profile = localStorage.getItem('profile')
        if (profile && profile.length) {
            profile = JSON.parse(localStorage.getItem('profile'))
            this.userEmail = profile['email'];
        }
        this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
			this.getwebapplication()
		});
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.mobileApplication && changes.mobileApplication['currentValue'] != null) {
            this.forEachMobile();
        }
        // if (this.webApplication && this.webApplication.length && !this.commentDataWeb) {
        //     this.commentDataWeb = true
        //     // this.getAllComments("web")
        //     // this.getAllTouchpoints("web")
        // }
        if (this.mobileApplication && this.mobileApplication.length && !this.commentDataMobile) {
            this.commentDataMobile = true
            this.getAllComments("mobile")
            // this.getAllTouchpoints("mobile")
        }
        const changeFilters: SimpleChange = changes.filters
        if (changeFilters && changeFilters.currentValue && Object.keys(changeFilters.currentValue).length) {
            if (this.filters && this.filters.page) {
                this.page = this.filters.page
                delete this.filters.page
            }
            this.getwebapplication()
        }
    }
    forEachMobile() {
        if (this.mobileApplication) {
            this.mobileApplication.forEach(mob => {
                this.checkCveScore(mob)
            })
        }
    }
    changeSelectedState(value){
        let index = this.techFilterValues.indexOf(value)

        if( index!== -1){
            this.techFilterValues.splice(index,1)

        }
        else{
            this.techFilterValues.push(value)
        }



    }

    filterTechnologies(value){
        if(value=='clear'){
            this.techFilterValues= []
            this.page=1
            this.formatTechnologieStr=null;
            this.getwebapplication()
        }
        else{
            this.formatTechnologies()

        }

    }
    formatTechnologies(){
        let technologies = []
        this.techFilterValues.forEach(element => {
            technologies.push('"' + element + '"')
            
        });
        this.formatTechnologieStr = technologies.join()
        this.page=1
        this.getwebapplication()

    }
    displayTags(web) {
        if (web.tags && web.tags.length > 0) {
            return true
        } else if (web.takeover_risk && web.takeover_risk >= 2.0) {
            return true
        } else {
            return false
        }
    }

    getTechnologiesName(){   
        this.portfolioSurveyService.getTechnologiesName(this.mainDomain).subscribe(data =>{
            if(data){
                this.technologiesFilter = data['data']

            }

        });
    }
    checkPurchaseAndUsageDetailsForUser() {
        this.showThisScan = (this.appToScan['platform']).toLowerCase();
        this.showUploadFileInput = false;
        this.scanNowButtonDisabled = true;
        this.usageToCheck = null;
        this.portfolioSurveyService.getMobileServicePurchaseForUser(this.userEmail).subscribe(data => {
            this.appScansPurchases = data;
            this.appScansPurchases.forEach(e => {
                this.portfolioSurveyService.getMobileServiceUsageForPurchaseId(e['purchase_id']).subscribe((usageData: any) => {
                    if (usageData && usageData.length) {
                        this.allServiceUsages.push(usageData);
                        usageData.forEach(usage => {
                            if (usage['asset'] && usage['asset'].length) {
                                const indexToCheck = usage['asset'].indexOf(this.appToScan['source_url']) >= 0
                                    ? usage['asset'].indexOf(this.appToScan['source_url'])
                                    : usage['asset'].indexOf(this.appToScan['artifact']);
                                if (indexToCheck >= 0) {
                                    this.usageToCheck = usage;
                                }
                            }
                        });
                    } else {
                        this.usageToCheck = null;
                    }
                    this.scanNowButtonDisabled = false;
                })
            });
            this.scanNowButtonDisabled = false;
        }, error => {
            this.scanNowButtonDisabled = false;
            // if (error['status'] === 403) {
            //     alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
            // } else {
            //     alert("Error while updating")
            // }
        })
    }

    // openScanDetails(template) {
    //     if (this.appScansPurchases && this.appScansPurchases.length) {
    //         if (this.usageToCheck && this.usageToCheck['revalidations_left'] > 0) {
    //             this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    //         } else if (this.usageToCheck && this.usageToCheck['revalidations_left'] === 0) {
    //             alert('You have reached revalidation limit for this App. Any new scan will be counted as new scan.');
    //             this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    //         } else {
    //             if (this.appScansPurchases[0]['service_usage_count'] < this.appScansPurchases[0]['service_count']) {
    //                 this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    //             } else {
    //                 alert('All your purchases are exhausted. Please contact us at contact@firecompass.com')
    //             }
    //         }
    //     } else {
    //         alert('You do not have any purchases for scaning any app. Please contact us at contact@firecompass.com');
    //     }
    // }
    startMobileScan() {
        // alert('Scan details sent. We will inform you once scan started.');
        this.disableScanButton = true;
        this.appToScan['scanEntity'] = this.scanEntity;
        let dataToSend = {
            scanEntity: this.appToScan['scanEntity'],
            recent_scan_entity: this.appToScan['scanEntity'][0],
            scan_type: this.appScansPurchases[0]['name'],
            source_url: this.appToScan['source_url'],
            id: this.appToScan['id'],
            artifacts: this.appToScan['artifacts']
        }
        if (this.usageToCheck && this.usageToCheck['revalidations_left'] !== 0) {
            this.appToScan['service_usage_id'] = this.usageToCheck['usage_id'];
            dataToSend['service_usage_id'] = this.usageToCheck['usage_id'];
        }
        this.appToScan['service_purchase_id'] = this.appScansPurchases[0]['purchase_id'];
        this.appToScan['service_purchase_id'] = this.appScansPurchases[0]['purchase_id'];
        dataToSend['service_purchase_id'] = this.appScansPurchases[0]['purchase_id'];
        this.portfolioSurveyService.startMobileScan(dataToSend, this.mainDomain).subscribe(data => {
            this.checkPurchaseAndUsageDetailsForUser();
            this.disableScanButton = false;
            this.modalRef.hide()
            this.scanEntityType = null;
            // alert('Scan started successfully. Results will be available in a while!');
        }, error => {
            this.scanEntityType = null;
            this.disableScanButton = false;
            this.modalRef.hide()
            alert('Error while starting scan. Please try again later or write to us at contact@firecompass.com!')
        })
    }
    saveNewMobile(app) {
        // give scan
        this.appToScan = {}
        this.scanNowIos = {}

    }
    modalMobOpen(template: TemplateRef<any>, vulnerability) {
        this.cve_details = vulnerability;
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
    }
    modalWebOpen(template: TemplateRef<any>, vulnerability) {
        this.cve_details_web = vulnerability;
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
    }

    showTechnology(template: TemplateRef<any>, application) {
        this.appUrls = []
        this.appUrls = application.pages;
        this.appUrls.unshift({
            url: application['source_url'],
            technologies: application['technologies']
        })
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
    }

    // modalOpenWebScore(template: TemplateRef<any>, application) {
    //     this.modalHide();
    //     application['showSpinnerForWebScore'] = true;
    //     this.portfolioSurveyService.getwebscoreforapplication(this.mainDomain['domain_name'], application['source_url']).subscribe((data: any) => {
    //         if (data['status'] === 'pass') {
    //             this.webScoreDetails = data.data
    //             this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
    //         } else {
    //             alert('no web score details')
    //         }
    //         application['showSpinnerForWebScore'] = false;
    //     }, error => {
    //         application['showSpinnerForWebScore'] = false;
    //     })
    // }

    // modalOpenSllScore(template: TemplateRef<any>, application) {
    //     this.modalHide();
    //     application['showSpinnerForSllScore'] = true;
    //     this.portfolioSurveyService.getsslscoreforapplication(this.mainDomain['domain_name'], application['source_url']).subscribe((data: any) => {
    //         if (data['status'] === 'pass') {
    //             this.sslScoreDetails = data.data
    //             this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
    //         } else {
    //             alert('no ssl score details')
    //         }
    //         application['showSpinnerForSllScore'] = false;
    //     }, error => {
    //         application['showSpinnerForSllScore'] = false;
    //     })

    // }

    modalHide() {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }

    addNewApp(template) {
        this.newMob = {}
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    }
    scanMobApp(template) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
    }
    scanWebApp() {

    }
    Input() {
        //this.newMob
        alert("Admin will approve Mobile Application soon!!")
    }

    checkCveScore(mob) {
        mob.criticalVul = null
        mob.highVul = null
        mob.mediumVul = null
        mob.lowVul = null
        mob.infoVul = null
        mob.criticalVulCount = 0
        mob.highVulCount = 0
        mob.mediumVulCount = 0
        mob.lowVulCount = 0
        mob.infoVulCount = 0
        mob.vulnerabilities.forEach(cve => {
            if (cve.severity >= 9) {
                mob.criticalVulCount = mob.criticalVulCount ? mob.criticalVulCount + 1 : 1;
                if (mob.criticalVul) {
                    mob.criticalVul.push(cve);
                } else {
                    mob.criticalVul = [cve];
                }
            } else if (cve.severity >= 7) {
                mob.highVulCount = mob.highVulCount ? mob.highVulCount + 1 : 1;
                if (mob.highVul) {
                    mob.highVul.push(cve);
                } else {
                    mob.highVul = [cve];
                }
            } else if (cve.severity >= 4) {
                mob.mediumVulCount = mob.mediumVulCount ? mob.mediumVulCount + 1 : 1;
                if (mob.mediumVul) {
                    mob.mediumVul.push(cve);
                } else {
                    mob.mediumVul = [cve];
                }
            } else if (cve.severity >= 0) {
                mob.lowVulCount = mob.lowVulCount ? mob.lowVulCount + 1 : 1;
                if (mob.lowVul) {
                    mob.lowVul.push(cve);
                } else {
                    mob.lowVul = [cve];
                }
            } else {
                mob.infoVulCount = mob.infoVulCount ? mob.infoVulCount + 1 : 1;
                if (mob.infoVul) {
                    mob.infoVul.push(cve);
                } else {
                    mob.infoVul = [cve];
                }
            }
        })
    }
    closeScan() {
        this.modalRef.hide();
    }
    fileChange(element, appToUpdate = null) {
        this.uploadedFiles = element.target.files;
        this.uploadFile(element, appToUpdate);
    }
    uploadFile(event, appToUpdate) {
        appToUpdate = appToUpdate ? appToUpdate : this.appToScan;
        const reader: any = new FileReader();
        this.showSpinnerWhileUploadFile = true;
        if (this.uploadedFiles && this.uploadedFiles.length > 0) {
            const file = this.uploadedFiles[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                const attachmentDetails = {
                    filename: file.name,
                    type: 'osint_report',
                    filetype: file.type,
                    value: reader.result.split(',')[1]
                }
                this.portfolioSurveyService.uploadAppFile(this.mainDomain['domain_name'], attachmentDetails).subscribe(data => {
                    this.showSpinnerWhileUploadFile = false;
                    if (appToUpdate['artifacts'] && appToUpdate['artifacts'].length > 0) {
                        appToUpdate['artifacts'].push({
                            'url': data['publicUrl'],
                            'created_at': this.commonService.getCurrentDateTime(),
                            'scaned_at': this.commonService.getCurrentDateTime(),
                            'selected': true
                        })
                    } else {
                        appToUpdate['artifacts'] = [{
                            'url': data['publicUrl'],
                            'created_at': this.commonService.getCurrentDateTime(),
                            'scaned_at': this.commonService.getCurrentDateTime(),
                            'selected': true
                        }]
                    }
                    appToUpdate['artifact'] = data['publicUrl']
                    this.scanEntity = [data['publicUrl']]
                }, error => {
                    this.showSpinnerWhileUploadFile = false;
                    alert('Error while uploading file. Please try again later or write to us at contact@firecompass.com!')
                })
            }
        } else {
            this.showSpinnerWhileUploadFile = false;
            alert('Please attach file first!')
        }
    }

    openRemoveOptions(template: TemplateRef<any>, deleteType, application) {
        this.deleteType = deleteType;
        application.selected = true
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
    }

    getTechnologies(application) {
        let technologies = []
        if (this.technologies && application.source_url) {
            for (let key in this.technologies) {
                if (key.indexOf(application.source_url) > -1) {
                    technologies.push(...this.technologies[key]);
                }
            }
            application.technologies = this.commonService.getUniqueValues(technologies, 'technology_name')
            return true

        }
        return false
        // let technologies = []
        // application.pages.forEach(page => {
        //     technologies.push(...page.technologies)
        // })
        // technologies.push(...application.technologies)
        // application.technologies = this.commonService.getUniqueValues(technologies, 'technology_name')
    }
    checkChange(ats, dl) {
        this.showScanBtn = false
        this.scanEntity = [dl];
        if (this.scanEntityType == 'source_url') {
            this.showUploadFileInput = false;
        } else {
            this.showUploadFileInput = true;
        }
        ats['artifacts'].map(a => {
            if (a['url'] === dl['url']) {
                a['selected'] = true;
            } else {
                a['selected'] = false;
            }
        })
    }

    
    getmobileapplication() {
        this.showReloadMobileAppSpinner = true
        this.portfolioSurveyService.getmobileapplication(this.mainDomain['domain_name'],{scope:this.selectedScope,}).subscribe(data => {
            if (data) {
                this.mobileApplication = data['data']
                this.forEachMobile()
                this.showReloadMobileAppSpinner = false
            } else {
            }
        })
    }

    public addNewMob() {
        this.newMob['added'] = true;
        let dataToSend = {
            'application_type': "mobile",
            'asset': "high",
            'confidence': "9",
            'discovered_at': this.commonService.getTodaysDate(),
            'main_domain': this.mainDomain['domain_name'],
            'score': 1,
            'subject_name': this.mainDomain['domain_name'],
            'subject_type': "main_domain"
        }
        if (!this.addNewMobFileInput) {
            this.newMob['artifacts'] = [{
                "url": this.newMob['source_url'],
                created_at: this.commonService.getCurrentDateTime(),
                updated_at: this.commonService.getCurrentDateTime()
            }]
        } else {
            this.newMob['source_url'] = this.commonService.create_UUID();
        }
        dataToSend = Object.assign(dataToSend, this.newMob);
        this.portfolioSurveyService.addMobileApp(this.mainDomain['domain_name'], [dataToSend]).subscribe(data => {
            if (data[0] && data[0]['added_to_db']) {
                alert('App Added Successfully')
            } else {
                // this.mobileApplication.unshift(dataToSend)
                this.getmobileapplication();
                alert("App Added Successfully")
            }
        }, error => {
            if (error['status'] === 403) {
                alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
            } else {
                alert("Error while updating")
            }
        });
    }

    delWebApplication(reason) {
        this.webApplication.map(application => {
            if (application.selected == true) {
                application.updated = true;
                application.archive = true;
                application.archive_reason = reason;
                delete application['selected']
                // this.delReason = null;
            }
        })
        this.updatewebapplication(this.webApplication);
    }

    delMobileApplication(reason) {
        this.mobileApplication.map(application => {
            if (application.selected == true) {
                application.updated = true;
                application.archive = true;
                application.archive_reason = reason;
                delete application['selected']
                // this.delReason = null;
            }
        })
        this.updatewebapplication(this.mobileApplication);
    }

    // selctForBulkAction(value) {

    //     if (value == true) {
    //         this.webApplication.forEach(application => {
    //             application.selected = true
    //         })
    //     } else {
    //         this.webApplication.forEach(application => {
    //             application.selected = false
    //         })
    //     }
    // }

    public updatewebapplication(applications) {
        let updateApps = applications.filter(a => a.updated == true)
        this.portfolioSurveyService.updatewebapplication(updateApps, this.mainDomain['domain_name']).subscribe(data => {
            this.modalRef.hide();
            alert("Application deleted successfully!");
            if(updateApps[0]?.application_type=="mobile"){
                this.getmobileapplication();
            }else{
                this.getwebapplication();
            }
            this.refreshCount.emit('application');
        }, error => {
            this.modalRef.hide();
            if (error['status'] === 403) {
                alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
            } else {
                alert("Error while updating")
            }
        });
    }
    public delReason(reason) {
        if (this.deleteType == "mobile") {
            this.delMobileApplication(reason)
        } else {
            this.delWebApplication(reason);
        }
    }
    filterApplicationVul(mod) {
        this.showMod = mod.module;
        this.filterObj['module'] = [mod.module];
        this.count = this.count + 1;
    }
    // monitoringProfileV3(app, type) {
    //     let targets = [app['source_url']]
    //     let targetType = type
    //     if (app.monitoring) {
    //         this.portfolioSurveyService.registerMonitor(this.mainDomain['domain_name'], targets, targetType).subscribe(data => {
    //             app.monitoring = true
    //             app.monitoringDetails = data['data'][0]
    //         })
    //     } else {
    //         let monitoringId = app['monitoringDetails']['_id']
    //         this.portfolioSurveyService.deregisterMonitor(monitoringId).subscribe(data => {
    //         })
    //     }
    // }
    // getMonitoringId(app) {
    //     return this.monitors.find(a => {
    //         if (a['targetSpec'] == app['source_url']) {
    //             return true
    //         }
    //     })
    // }
    cancelDel(a) {
        this.mobileApplication.map(application => {
            application.selected = false
        })

        this.webApplication.map(application => {
            application.selected = false
        })
    }

    openCommentsModule(template: TemplateRef<any>, app, app_type) {
        this.pocSubjectComment = {
            "subject_type": app_type,
            "subject_name": app['source_url'],
        }
        this.modalRef = this.modalService.show(template, {
            backdrop: "static",
            keyboard: false,
            class: "min-width-dialog-modal",
        });
    }
    openTouchpointModule(template: TemplateRef<any>, app, app_type) {
        this.pocSubjectTouchPoint = this.pocSubjectComment = {
            "subject_type": app_type,
            "subject_name": app['source_url'],
        }

        this.modalRef = this.modalService.show(template, {
            backdrop: "static",
            keyboard: false,
            class: "min-width-dialog-modal",
        });
    }
    getAllComments(type) {
        this.portfolioSurveyService.getAllComments(this.mainDomain["domain_name"], type).subscribe((data) => {
            if (type == "web") {
                this.webApplication.forEach(web => {
                    const commentsForApps = data['data'].filter(a => {
                        return a.subject_name == web.source_url
                    })
                    if (commentsForApps && commentsForApps.length) {
                        commentsForApps.forEach(cc => {
                            web.comments ? web.comments.push(cc.title) : web.comments = [cc.title]
                        })
                    }
                });
            } if (type == "mobile") {
                this.mobileApplication.forEach(mobile => {
                    const commentsForApps = data['data'].filter(a => {
                        return a.subject_name == mobile.source_url
                    })
                    if (commentsForApps && commentsForApps.length) {
                        commentsForApps.forEach(cc => {
                            mobile.comments ? mobile.comments.push(cc.title) : mobile.comments = [cc.title]
                        })
                    }
                });

            }
        })
    }
    getAllTouchpoints(type) {
        this.portfolioSurveyService.getAllTouchpoints(this.mainDomain["domain_name"], type).subscribe((data) => {
            if (type == "web") {
                this.webApplication.forEach(web => {
                    const touchpointsForDomain = data['data'].filter(a => {
                        return a.subject_name == web.source_url
                    })
                    if (touchpointsForDomain && touchpointsForDomain.length) {
                        touchpointsForDomain.forEach(cc => {
                            web.touchpoints ? web.touchpoints.push(cc.email) : web.touchpoints = [cc.email]
                        })
                    }
                });
            }
            if (type == "mobile") {
                this.mobileApplication.forEach(mobile => {
                    const touchpointsForDomain = data['data'].filter(a => {
                        return a.subject_name == mobile.source_url
                    })
                    if (touchpointsForDomain && touchpointsForDomain.length) {
                        touchpointsForDomain.forEach(cc => {
                            mobile.touchpoints ? mobile.touchpoints.push(cc.email) : mobile.touchpoints = [cc.email]
                        })
                    }
                });
            }
        })
    }
    getwebapplication() {
        this.showSpinner = true
        this.webArray = []
        if (this.mainDomain["domain_name"]) {
            var params = {
                page: this.page,
                limit: this.limit,
                scope:this.selectedScope,
                searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
                ...this.filters,
                expire:this.selectedExpiredData
            }
            if(this.formatTechnologieStr){
                params['technology'] = this.formatTechnologieStr
            }
            this.portfolioSurveyService.getwebapplication(this.mainDomain["domain_name"], params).subscribe(data => {
                this.showSpinner = false

                if (data) {
                    this.webApplication = data['data']
                    this.total_webApp_count = data['count_apps']
                    this.commentDataWeb = true
                    this.getAllComments("web")
                    this.webApplication.forEach(web => {
                        this.checkCveScore(web);
                        web.subjectTypeName = {
                            "subject_type": 'web',
                            "subject_name": web['source_url']
                        }
                        // this.getTechnologies(web);
                    })
                    this.webApplication.forEach(d => {
                        this.webArray.push(d.source_url)
                      })
                    if (this.webArray.length) {
                    this.getObsInsCountsForEachWeb(this.webArray)
                    }
                } else {
                }
            }, error => {
                this.commonService.errorForDomain(error);
            })
        }
    }
    getSSLUrlWithoutHttp(sourceUrl) {
        let url = "https://www.ssllabs.com/ssltest/analyze.html?d="
        return url + sourceUrl.replace("http://", '').replace('https://', '')
    }
    getWebUrlWithoutHttp(sourceUrl) {
        let url = "https://observatory.mozilla.org/analyze?host="
        return url + sourceUrl.replace("http://", '').replace('https://', '')
    }
    goToPage(val) {
        this.page = val;
        this.getwebapplication()
    }
    searchAllWebAppDetail() {
        this.page = 1;
        this.searchKey = null
        this.getwebapplication()
    }
    searchWebAppDetail(domain) {
        this.page = 1;
        this.getwebapplication()
    }
    checkState(tech){
        if(this.techFilterValues.indexOf(tech) ==-1){
            return false
        }
        else{
            return true
        }
    }
    scopeChanged($e){
        this.page = 1;
        this.getwebapplication();
        this.getmobileapplication();
    }
    searchByExpiry($event){
        this.page = 1;
        this.getwebapplication();
    }
    clearExpiry(){
        this.page = 1;
        this.selectedExpiredData  = null;
        this.getwebapplication();
    }
    getObsInsCountsForEachWeb(arrayList) {
        this.obsInsCountSpinner = true
		const params = {
            subject_ref_list: arrayList,
            subject_type: "web"
		}
		this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain["domain_name"], params).subscribe(data => {
		  this.obsInsCountSpinner = false
		  this.vulDetailForWeb = data['data']
		  this.webApplication.forEach((rd) => {
			let url = rd.source_url
			if (this.vulDetailForWeb[url]) {
			  rd.obs_count = this.vulDetailForWeb[url].count_obs
			  rd.ins_count = this.vulDetailForWeb[url].count_ins
			}
		  })
		})
	  }
}