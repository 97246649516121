import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { of } from "rxjs";
import {
  NgWizardConfig,
  NgWizardService,
  StepChangedArgs,
  StepValidationArgs,
  STEP_STATE,
  THEME,
  TOOLBAR_POSITION,
} from "ng-wizard";
import { JobService } from "../../_services/job.service";
import { AuthService } from "../../views/pages/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-assesment",
  templateUrl: "./assesment.component.html",
  styleUrls: ["./assesment.component.scss"],
})
export class AssesmentComponent implements OnInit {
  public showThis = "runbook";
  public runbookFinished = 0;
  public stepPosition;
  public newAssessment = {};
  public formValidated = false;
  public error = false;
  public scopeAdvanceOption;
  public creatingAssessment = false;
  userPreference;
  public params:any = null;

  @Output() showAssessmentList = new EventEmitter<any>();

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarPosition: TOOLBAR_POSITION.top, 
      showPreviousButton: true,
      showNextButton: true,
      toolbarExtraButtons: [
        {
          text: "Finish",
          class: "btn btn-info ng-wizard-btn-finish disabled",
          event: () => {
            if (this.formValidated) {
              this.createAssesment();
            }
          },
        },
      ],
    },
  };
  public createNew = {
    name: null,
    delta_duration: '',
    job_type: "assessment",
    schedule: {
      crontab: {
        minute: "*",
        hour: "*",
        day_of_week: "*",
        day_of_month: "*",
        month_of_year: "*",
      },
      one_off: true,
      duration: ''
    },
    job_config: {
      frequency: null,
      assessment_config: {
        advance_option: null,
        runbook_detail: null,
        targets: null,
      },
    },
  };
  constructor(
    private ngWizardService: NgWizardService,
    private authService: AuthService,
    public jobService: JobService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userPreference = JSON.parse(localStorage.getItem("userPreference"));
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    this.stepPosition = args.position;
    this.checkFinalButtonState();
  }
  isDelta() {
    let runb = this.newAssessment['runbook'];
    let delta = (runb?.config?.target?.delta_attack_surface?.value) ? true : false;
    return delta;
  }
  isValidDelta() {
    console.log(this.newAssessment['schedule']);
    let runb = this.newAssessment['runbook'];
    let delta = (runb?.config?.target?.delta_attack_surface?.value) ? true : false;
    // if delta assesment
    if (delta) {
      if (this.newAssessment['schedule'].schedule.one_off_dupe) { // if oncetime
        console.log('Delta Start Now');
        return (this.newAssessment['schedule']?.delta_duration) ? true : false;
      } else if (this.isValidSchedule(this.newAssessment['schedule'].job_config.frequency, this.newAssessment['schedule'].schedule.crontab)) { // if scheduled
        console.log('Delta Scheduled');
        if (this.newAssessment['schedule'].job_config.frequency == 0) {
          return (this.newAssessment['schedule']?.delta_duration) ? true : false;
        } else if (this.newAssessment['schedule'].job_config.frequency >= 1) {
          return true;
        } else {
          return false;
        }
      }
      // Not delta & schedule
    } else if (!delta && !this.newAssessment['schedule'].schedule.one_off_dupe) { // if not delta
      console.log('Non Delta Scheduled');
      if (this.isValidSchedule(this.newAssessment['schedule'].job_config.frequency, this.newAssessment['schedule'].schedule.crontab)) {
        return true;
      } else {
        return false;
      }
    } else {
      console.log('Non Delta One Time');
      // not delta & One time
      return true;
    }
  }
  isValidSchedule(frequency, crontab) {
    if (frequency == 0) {
      if (
        crontab.day_of_month && crontab.day_of_month != "*" && crontab.day_of_month != "NaN" &&
        crontab.day_of_week && crontab.day_of_week != "*" && crontab.day_of_week != "NaN" &&
        crontab.hour && crontab.hour != "*" && crontab.hour != "NaN" &&
        crontab.minute && crontab.minute != "*" && crontab.minute != "NaN" &&
        crontab.month_of_year && crontab.month_of_year != "*" && crontab.month_of_year != "NaN"
      ) return true; else false;
    } else if (frequency == 1) {
      if (
        crontab.hour && crontab.hour != "*" && crontab.hour != "NaN" &&
        crontab.minute && crontab.minute != "*" && crontab.minute != "NaN"
      ) return true; else false;
    } else if (frequency == 7) {
      if (
        crontab.day_of_week && crontab.day_of_week != "*" && crontab.day_of_week != "NaN" &&
        crontab.hour && crontab.hour != "*" && crontab.hour != "NaN" &&
        crontab.minute && crontab.minute != "*" && crontab.minute != "NaN"
      ) return true; else false;
    } else if (frequency == 30) {
      if (
        crontab.day_of_month && crontab.day_of_month != "*" && crontab.day_of_month != "NaN" &&
        crontab.hour && crontab.hour != "*" && crontab.hour != "NaN" &&
        crontab.minute && crontab.minute != "*" && crontab.minute != "NaN"
      ) return true; else false;
    } else {
      return false;
    }
  }
  checkFinalButtonState() {
    const finishButton = document.getElementsByClassName(
      "ng-wizard-btn-finish"
    );
    if (
      !this.creatingAssessment &&
      this.stepPosition == "final" &&
      this.createNew.name &&
      this.createNew.name.trim().length > 3 &&
      this.createNew.name.length < 101 &&
      this.isValidDelta()
    ) {
      if (finishButton && finishButton.length) {
        this.formValidated = true;
        finishButton[0].classList.replace("disabled", "enabled");
        finishButton[1].classList.replace("disabled", "enabled");
      }
    } else {
      this.formValidated = false;
      if (finishButton && finishButton.length) {
        finishButton[0].classList.replace("enabled", "disabled");
        finishButton[1].classList.replace("enabled", "disabled");
      }
    }
  }
  isValidTypeBoolean: boolean = true;

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }
  goToAttackList() { }
  resetOtherFields() {
    this.newAssessment = {
      runbook: { ...this.newAssessment["runbook"] },
    };
    this.createNew.job_config.assessment_config = {
      runbook_detail: this.newAssessment["runbook"],
      advance_option: null,
      targets: null,
    };
  }
  startScan(key, value) {
    this.newAssessment[key] = value;
    if (key == "runbook") {
      this.createNew.job_config.assessment_config.runbook_detail =
        this.newAssessment["runbook"];
      this.resetOtherFields();
    } else if (key == "targets") {
      this.createNew.job_config.assessment_config.targets =
        this.newAssessment["targets"];
    } else if (key == "advanceOptions") {
      this.createNew.job_config.assessment_config.advance_option =
        this.newAssessment["advanceOptions"];
    } else if (key == "schedule") {
      // console.log(key, this.newAssessment[key]);
      this.createNew.schedule = this.newAssessment["schedule"].schedule;
      this.createNew.name = this.newAssessment["schedule"].name;
      this.createNew.job_config.frequency =
        this.newAssessment["schedule"].job_config.frequency;

      if (this.isDelta()) {
        let freqTime = null;
        if(this.newAssessment["schedule"]?.schedule?.one_off_dupe && this.newAssessment['schedule']?.delta_duration){
          freqTime = this.createNew.job_config.frequency = this.newAssessment['schedule']?.delta_duration;
        }else{
          freqTime = this.newAssessment['schedule']?.delta_duration;
        }
        if (freqTime) {
          const nowDateTime = new Date();
          const fromDate = new Date(
            new Date().setDate(new Date().getDate() - freqTime)
          );
          this.createNew.job_config['duration'] = {
            fromDate: fromDate,
            toDate: nowDateTime
          }
        }
        if(this.newAssessment["schedule"]?.schedule?.one_off_dupe){
          this.createNew.job_config.frequency = null;
        }
      }
      // console.log('payload',this.createNew)
      this.checkFinalButtonState();
    }
  }
  createAssesment() {
    if (!this.creatingAssessment) {
      this.creatingAssessment = true;
      // this.createNew.job_config.assessment_config.runbook_detail.type = 'molecular'
      this.jobService.createNewJob(this.createNew).subscribe(
        (data) => {
          this.creatingAssessment = false;
          if (!this.userPreference.user_recommendation || !this.userPreference.user_recommendation.runbook || this.userPreference.user_recommendation.runbook != 'completed') {
            const data = {
              ...this.userPreference,
              user_recommendation: { runbook: 'completed' },
            }
            this.authService.updateUserPref({ pref: data });
          }
          this.showAssessmentList.emit();
          this.router.navigate(["playbook/active"], {queryParams: { domain: this.params['domain'] }});
        },
        (error) => {
          if (error && error.status === 403) {
            alert(
              "You do not have permission to perform this action. Please write to us for more details"
            );
          } else alert("Something went wrong! Please try again.")
        }
      );
    }
    this.checkFinalButtonState();
  }
  checkValidation(args: StepValidationArgs) {
    let isDelta = this.createNew.job_config.assessment_config.runbook_detail?.config?.target?.delta_attack_surface?.value;
    let skipabel = this.createNew.job_config.assessment_config.runbook_detail?.config?.target?.complete_attack_surface?.value;
    let isAlertService = (this.createNew.job_config.assessment_config.runbook_detail?.config?.target?.source?.value==="alert_service")?true:false;
    // console.log('args',args);
    // console.log('this.runbookFinished',this.runbookFinished,skipabel);
    if (args && args.direction == "forward") {
      if (
        this.runbookFinished == 0 &&
        this.createNew.job_config.assessment_config.runbook_detail != null
      ) {
        this.runbookFinished++;
        this.error = false;
        return true;
      } else if (
        this.runbookFinished == 1 && (isDelta || isAlertService || skipabel || (
          this.createNew.job_config.assessment_config.targets &&
          this.createNew.job_config.assessment_config.targets.length))
      ) {
        this.runbookFinished++;
        this.error = false;
        return true;
      } else if (this.runbookFinished == 2) {
        this.runbookFinished++;
        this.error = false;
        return true;
      } else if (this.runbookFinished == 3 && this.createNew.name) {
        this.runbookFinished++;
        this.error = false;
        return true;
      } else {
        this.error = true;
        return false;
      }
    } else if (args && args.direction == "backward") {
      this.runbookFinished--;
      this.error = false;
      return true;
    }
  }
  triggerFinal(){
    if (this.formValidated) {
      this.createAssesment();
    }
  }
}
