import { Pipe, PipeTransform } from '@angular/core';
import * as validator from 'validator';

@Pipe({
	name: 'validateDomain'
})
export class ValidDomainPipe implements PipeTransform {

	constructor() { }

	transform(domain: string) {
		if (domain != null) {
			domain = domain.trim()
			return validator.isFQDN(domain);
		}
	}
}
