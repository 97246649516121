import { Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "./http.service";
import {
  StorageAccessConfig,
  JobAutomationConfig,
} from "../../environments/environment";
import {
  HttpHeaders,
  HttpClient
} from "@angular/common/http";
@Injectable()
export class UploadCsvService {
  public storageAccessConfig = StorageAccessConfig.spUrl;
  public jobAutomationConfig = JobAutomationConfig;

  private headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("id_token"),
  });
  constructor(
    public httpSVC: HttpService,
    private http: HttpClient
  ) {}
  public uploadFileToBucket(url: string, file) {
    const options = {
      headers: new HttpHeaders({
        skip: "true",
        "Content-Type": "text/csv",
      }),
    }
    return this.http.put(url, file, options);
  }
  public getSignedUrlForUploadCSV(
    domain,dataToSend
  ) {
    const url =
      this.storageAccessConfig +
      "/shadowit/signed_url/assessment/" +
      domain
    return this.httpSVC.post(url,dataToSend);
  }
  public uploadFileToSignedUrl(signedUrl, file) {
    if (!signedUrl || !file) throw Error("File or signed url missing");
    else {
      return this.httpSVC.uploadFileToBucket(signedUrl, file);
    }
  }
}
