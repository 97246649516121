import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-attribution-seed",
  templateUrl: "./attribution-seed.component.html",
  styleUrls: ["./attribution-seed.component.scss"],
})
export class AttributionSeedComponent implements OnInit {
  constructor(
    public modalService: BsModalService,
    public modalRef: BsModalRef
  ) {}
  public seedAttr;
  public palybookAtt = [];
  public idForIcon = Math.floor(Math.random() * 100000) + "attribution";
  public idplaybook = Math.floor(Math.random() * 100000) + "plybook";

  @Input() attribution;
  @Input() type;
  public showDataInput = 0;
  public showDataDiscoverd = 0;
  public profile:any = localStorage.getItem('profile');

  ngOnInit(): void {
    if(this.profile){
      this.profile = JSON.parse(this.profile);
    }
    this.checkAttributionSeed();
    this.checkPlaybookAttribution();
  }
  checkAttributionSeed() {
    if (this.attribution && this.attribution.length) {
      let seedTrue;
      this.attribution.forEach((att) => {
        if (!seedTrue || seedTrue != "manual") {
          if (att.scan_type == "seed_management_discovery") {
            seedTrue = "discovery";
            this.seedAttr = att;
          } else if (att.scan_type == "seed_management_manual") {
            seedTrue = "manual";
            this.seedAttr = att;
          }
          return seedTrue;
        }
      });
    }
  }
  mouseHoverToDiv() {
    let showData;
    let source = null;
    if (this.type == "domain") {
      source = "Whois";
    } else if (this.type == "netblock") {
      source = "IPWhois";
    }
    if (this.seedAttr.scan_type == "seed_management_discovery") {
      showData =
        `
      Data Source: ` +
        source +
        ` Via: Configuration ` +
        `${
          this.seedAttr.parent_entity_type.charAt(0).toUpperCase() +
          this.seedAttr.parent_entity_type.slice(1)
        }: ${this.seedAttr.parent_entity}`;
    }
    if (this.seedAttr.scan_type == "seed_management_manual") {
      showData = `
      Data Source: Manual 
      Via: Configuration`;
    }
    const divById = document.getElementById(this.idForIcon);
    divById.setAttribute("gloov", showData);
  }
  checkPlaybookAttribution() {
    if (this.attribution && this.attribution.length) {
      this.attribution.forEach((att) => {
        // if (att.scan_type == "runbook_input") {
        //   this.palybookAtt.push(att);
        // }
        if (att.scan_type == "runbook_discovery" && this.checkProfile(att)) {
          let index = this.palybookAtt.findIndex((p) => {
            return p.assessment_id == att.assessment_id
          })
          if(index < 0){
            this.palybookAtt.push(att);
          }
        }
      });

    }
  }
  checkProfile(attr){
    if(attr.customer_id && this.profile['cust_id'] && attr.customer_id==this.profile['cust_id']){
      return true;
    }else if(this.profile['account_type'] && this.profile['account_type']=="service_provider"){
      return false;
    }else{
      return false;
    }    
  }
  mouseHoverToDivPlaybookAtt() {
    
    let showHoverData;
    let showInputText;
    let showDiscoverdText = '';
    let showDataInput = 0
    let showDataDiscoverd = 0;
    this.palybookAtt.forEach((att) => {
      if (att.scan_type == "runbook_input") showDataInput = showDataInput + 1;
      if (att.scan_type == "runbook_discovery")
        showDataDiscoverd = showDataDiscoverd + 1;
    });
    if (showDataInput == 1) {
      showInputText = `Used as target for ${showDataInput} playbook assessment`;
    } else if (showDataInput > 1) {
      showInputText = `Used as target for ${showDataInput} playbook assessments`;
    }
    if (showDataDiscoverd == 1) {
      showDiscoverdText = `Discovered via 1 playbook assessment`;
    } else if (showDataDiscoverd > 1) {
      showDiscoverdText = `Discovered via ${showDataDiscoverd} playbook assessments`;
    }

    showHoverData =  showInputText ? showInputText : ` `
    showHoverData = showHoverData+ ` ` + showDiscoverdText;
    const divById = document.getElementById(this.idplaybook);
    divById.setAttribute("gloov", showHoverData);
  }
  openAttributionModal(template: TemplateRef<any>, status = false) {
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
    });
  }
}