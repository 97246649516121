import { Component, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EventEmitter } from "@angular/core";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { AuthService } from "../../views/pages/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import jwt_decode from "jwt-decode";
import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
  selector: "app-main-domain-selection",
  templateUrl: "./main-domain-selection.component.html",
  styleUrls: ["./main-domain-selection.component.scss"],
})
export class MainDomainSelectionComponent implements OnInit {
  isServiceProvider = false;
  isError = false;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthService,
    private portfolioSurveyService: PortfolioSurveyService,
    public aff: AngularFireFunctions,
    public auth: AngularFireAuth,
    private ngxService: NgxUiLoaderService
  ) {
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (profile.account_type === "service_provider") {
      this.isServiceProvider = true;
    }
  }
  public domain;
  public previousDomains;
  public showSpinner;
  public showError;
  public showUpgradeWedgit = false;
  public userAllowed = false;
  public allUserDomains;
  public selfDomains;
  public freeTrials = 1;
  public myDomain;
  public selectedDomain: string;
  public checkingLogin = false;
  @Output() saveDone: EventEmitter<any> = new EventEmitter<any>();
  public extraParams:any = null;

  setDefaultVariabes = function (count) {
    const profile = JSON.parse(localStorage.getItem("profile"));
    console.log(profile);

    this.myDomain = localStorage.getItem("mydomain");
    if (profile && profile["app_metadata"]) {
      var email = profile.email;
      let newDomain = email.split("@")[1];
      if (this.myDomain != newDomain) {
        localStorage.setItem("mydomain", newDomain);
        this.getDomains();
      }
      var roles = profile["app_metadata"]["roles"];
      var index = roles.indexOf("osint");
      if (index >= 0) {
        this.userAllowed = true;
      }
      var indexadmin = roles.indexOf("admin");
      if (indexadmin >= 0) {
        this.userAllowed = true;
        this.freeTrials = 400;
      }
    }
  };
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
        this.extraParams = [];
        let checkFiedls = ["entity","inscopeConfig","showdata","totalRow","view","week","weekNumber"];
        checkFiedls.forEach(ele=>{
          if(params[ele]) this.extraParams[ele] = params[ele];
        })
    });
    this.authService.tryingToLoginEmitter.subscribe((data) => {
      this.checkingLogin = data;
      if (!this.checkingLogin) {
        this.checkloginStatus();
      }
    });
    this.showSpinner = true;
    this.getDomains();
    this.checkloginStatus();
  }

  getDomains() {
    this.portfolioSurveyService.getDomainsSearchForUser().subscribe(
      (data: any) => {
        this.allUserDomains = data;
        // this.checkloginStatus();
        this.showSpinner = false;
        this.selfDomains = data.filter((a) => {
          return a["domain_type"] === "self";
        });
        if (!this.selfDomains.length) {
          alert(
            "No domains added to user account. Please write to us at contact@firecompass.com to add you domain!"
          );
        }
        this.myDomain =
          this.selfDomains && this.selfDomains[0]
            ? this.selfDomains[0].domain
            : "";
        this.selectedDomain = (this.isServiceProvider) ? '' : this.myDomain;
      },
      (error) => {
        this.setDefaultVariabes(0);
        this.showSpinner = false;
        this.showError =
          "Error while connecting to server! Please try after some time.";
      }
    );
  }

  public searchDomain(domain, type, count = 0) {
    if (domain) {
      this.ngxService.start();
      const callable = this.aff.httpsCallable("updatePrimaryDomain");
      const data = callable({ primaryDomain: domain });
      data.subscribe(async (res) => {
        if (res == "You are not permitted to perform this action.") {
          this.ngxService.stop();
          this.isError = true;
        } else {
          await this.authService.getLastestToken();
          this.ngxService.stop();
          localStorage.setItem("mydomain", domain);
          this.router.navigate([], { queryParams: { domain: domain, ...this.extraParams } });
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
          this.saveDone.emit();
        }
      });
    }
  }

  checkloginStatus() {
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["/pages/login"]);
    }
  }

  setMyDomain(val) {
    this.myDomain = val.toLowerCase();
    this.myDomain = this.myDomain.trim()
    localStorage.setItem("mydomain", this.myDomain);
  }
}
