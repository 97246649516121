import { Component, OnInit } from '@angular/core';
import { PortfolioSurveyService } from 'app/_services/portfolio-survey.service';
import { JobService } from "../_services/job.service";
import { AsyncToasterTriggerService } from '../_services/async-toaster-trigger.service';
import { ReportService } from 'app/_services/report.service' 
@Component({
  selector: 'app-async-toaster',
  templateUrl: './async-toaster.component.html',
  styleUrls: ['./async-toaster.component.scss']
})
export class AsyncToasterComponent implements OnInit {

  allJobs:any = [];

  constructor(
    public jobservice:JobService,
    public portfolioSurveyService:PortfolioSurveyService,
    public asyncTrigger: AsyncToasterTriggerService,
    public reportService : ReportService
  ) {
    this.asyncTrigger.on().subscribe(data => {
      // console.log(data);
      if(data){
        this.addNewJob(data.id,data.type,data.messages);
      }
    });
   }

  ngOnInit(): void {
  }

  // addRandomJob(){
  //   this.addNewJob(Math.floor(Math.random() * 300000));
  // }

  addNewJob(jobId,type="job",messages=null){
    // console.log(messages);
    this.allJobs.push({jobid:jobId,status:'running',messages:messages});
    let thisKey = this.getKeyByValue(this.allJobs,jobId);
    // console.log(thisKey,this.allJobs);
    if(type=="job"){
      this.checkjobstatus(jobId);
    }else if(type=="download"){
      this.generateCsv(jobId);
    }
  }
  getMessage(i){
    let message = "";
    let overRideMessages = i.messages;
    if(i?.status=="running"){
      if(overRideMessages && overRideMessages.running){
        message = overRideMessages.running;
      }else{
        message = "Task in running..";
      }
    }else if(i?.status=="completed"){
      if(overRideMessages && overRideMessages.completed){
        message = overRideMessages.completed;
      }else{
        message = "Task completed";
      }
    }else if(i?.status=="send_to_background"){
      if(overRideMessages && overRideMessages.send_to_background){
        message = overRideMessages.send_to_background;
      }else{
        message = "Task is taking longer than expected, task will be completed in background";
      }
    }else if(i?.status=="error"){
      if(overRideMessages && overRideMessages.error){
        message = overRideMessages.error;
      }else{
        message = "Task failed, something went wrong.";
      }
    }
    return message;
  }

  generateCsv(jobId) {
    let waitingTime = 30;
    let poolingCount = 1;
    const poolingInterval = setInterval(() => {
      let thisKey = this.getKeyByValue(this.allJobs,jobId);
      if(!this.allJobs[thisKey]){
        clearInterval(poolingInterval);
        return;
      }
      this.allJobs[thisKey].poolingInterval = poolingInterval;
      this.reportService.generateLeakedCredCsv(jobId).subscribe((data: any) => {
        poolingCount = poolingCount + 1
        if (data && data.results && data.results.length || poolingCount > waitingTime) {
          if (data.results[0]?.report_status == 'completed') {
            this.jobCompleted(jobId);
            clearInterval(poolingInterval);
          } else if (data.results[0]?.report_status == 'failed') {
            this.jobGotError(jobId)
            clearInterval(poolingInterval);
          } else if (data.results[0]?.report_status == 'running' && poolingCount > waitingTime) {
            this.sentJobToBackground(jobId);
            clearInterval(poolingInterval);
          }

        }
        if (!data?.results?.length && poolingCount > waitingTime) {
          this.sentJobToBackground(jobId);
          clearInterval(poolingInterval);
        }
      }, (error) => {
        this.jobGotError(jobId)
      })
    }, 10000)
  }

  checkjobstatus(jobId){
    this.jobservice.ckeckJobStatus(jobId).then((res) => {
      if(res=="completed"){
        this.jobCompleted(jobId);
      }else if(res=="running"){
        this.sentJobToBackground(jobId);
      }
    }).catch((err) => {
      console.log(err);
      this.jobGotError(jobId)
    });

  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key].jobid == value);
  }

  jobCompleted(jobId){
    let thisKey = this.getKeyByValue(this.allJobs,jobId);
    if(this.allJobs[thisKey]){
      this.allJobs[thisKey].status = 'completed'; 
    }
    this.removeJobFromList(jobId);
  }
  sentJobToBackground(jobId){
    let thisKey = this.getKeyByValue(this.allJobs,jobId);
    if(this.allJobs[thisKey]){ this.allJobs[thisKey].status = 'send_to_background'; }
    this.removeJobFromList(jobId);
  }
  jobGotError(jobId){
    let thisKey = this.getKeyByValue(this.allJobs,jobId);
    if(this.allJobs[thisKey]){ this.allJobs[thisKey].status = 'error'; }
    this.removeJobFromList(jobId);
  }
  removeJobFromList(jobId){
    let that = this;
    let thisKey = that.getKeyByValue(that.allJobs,jobId);
    // console.log('Remove',jobId,thisKey);
    setTimeout(function(){
      delete that.allJobs[thisKey];
    },3000);
  }
  hideMessage(i){
    let that = this;
    let thisKey = that.getKeyByValue(that.allJobs,i.jobid);
    clearInterval(that.allJobs[thisKey].poolingInterval)
    delete that.allJobs[thisKey];
  }
}
