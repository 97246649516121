import {
  Component, OnInit, ChangeDetectorRef, Input, TemplateRef, SimpleChanges, SimpleChange
} from "@angular/core";
import { PortfolioSurveyService } from "../../../_services";
import { CommonService } from "../../../_services/common.service";


@Component({
  selector: 'app-domain-subsection-subdomain',
  templateUrl: './domain-subsection-subdomain.component.html',
  styleUrls: ['./domain-subsection-subdomain.component.scss']
})
export class DomainSubsectionSubdomainComponent implements OnInit {

  @Input() mainDomain: any;
  @Input() isUserTrial: false;
  @Input() relatedDomain;
  @Input() inscopeConfig = null;
  @Input() inscopeFlag = null;
  public subDomains
  public showSpinner = true;
  public seeMoreIps = 3;
  public allIpsToShowIndex = -1
  public showAllCname = -1;
  public seeMoreCnames = 3;
  public page = 1;
  public limit = 10;
  public searchKey = null;
  public total_subdomain_count: number = 0;

  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
   if(this.relatedDomain &&  this.mainDomain)
    this.getIndivualSubdomainDetails()
  }

  displayTags(domain) {
    if (domain.tags && domain.tags.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getIndivualSubdomainDetails() {
    this.showSpinner = true;
    let params = { rd_domain: this.relatedDomain['domain'], page: this.page, limit: this.limit, searchKey: this.searchKey };
    if(this.inscopeConfig == 'true'){
      params['scope'] = (this.inscopeFlag)?this.inscopeFlag:'IN';
    }
    console.log(this.inscopeConfig,params['scope']);
    this.portfolioSurveyService.getIndivualSubdomainDetails(this.mainDomain["domain_name"], params).subscribe(data => {
      if (data) {
        this.subDomains = data['data'].results;
        this.total_subdomain_count = data['data'].count
        this.commonService.calculateCreatedAndUpdated(this.subDomains)
      } else {
      }
      this.showSpinner = false
    }, error => {
      this.commonService.errorForDomain(error);
    })
  }
  goToPage(val) {
    this.page = val;
    this.getIndivualSubdomainDetails()
  }
  searchAllSubDomainDetail() {
    this.searchKey = null
    this.getIndivualSubdomainDetails()

  }
  searchSubDomainDetail(domain) {
    this.page = 1;
    this.getIndivualSubdomainDetails()

  }
  getCnames(sd) {
    if (sd.dns_details && sd.dns_details.length) {
      const cnames = []
      sd.dns_details.forEach(dns => {
        if(dns.type == 'CNAME') cnames.push(dns.name)
      });
      return cnames;
    } else if (sd['c_name']) return [sd['c_name']]
    else return []
  }
  getARecord(sd) {
    if (sd.dns_details && sd.dns_details.length) {
      const aRecords = []
      sd.dns_details.forEach(dns => {
        if(dns.type == 'AAAA' || dns.type == 'A') aRecords.push(dns.name)
      });
      return aRecords;
    } else return []
  }
}
