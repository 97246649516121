import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-vulnerability-remediation",
  templateUrl: "./vulnerability-remediation.component.html",
  styleUrls: ["./vulnerability-remediation.component.scss"],
})
export class VulnerabilityRemediationComponent implements OnInit {
  @Output() dataToUpdate = new EventEmitter<any>();
  @Output() closeModel = new EventEmitter<any>();
  public remediationList = [
    "Patching",
    "False Positive",
    "Asset Removal",
    "Version Upgrade",
    "Security Control",
    "Take Down",
    " Risk Accepted",
  ];
  constructor() {} // public dataToUpdate;
  ngOnInit() {}
  saveRemediation(data) {
    this.dataToUpdate.emit(data);
  }
  closeRemediateModel() {
    this.closeModel.emit(true);
  }
}
