import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { CommonService } from "../../_services/common.service";
import { reasonToDeleteDigitalRisk } from "app/_services/common-var.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { PortfolioSurveyService } from "app/_services";

@Component({
  selector: "app-phishing-domain",
  templateUrl: "./phishing-domain.component.html",
  styleUrls: ["./phishing-domain.component.scss"],
})
export class PhishingDomainComponent implements OnInit {
  @Input() mainDomain: any;
  @Input() phishingDomainData: any;
  @Input() isUserTrial: boolean = false;
  @Input() showExecutiveReport;
  @Input() phishingPercentage;
  public fieldsToDownload = {
    "Lookalike Domain": "fraud_domain",
    "Registrar Email": "contact_email",
    "Created At": "domain_created_at",
    "Expires On": "expires_at",
  };
  public seeMore = 10;
  public oldPhishingDomainData: any;
  public order = 1;
  public sortWith = "status";
  public initialSort: boolean = true;
  public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
  //   public delReason: string = null;
  public leaks;
  public showError;
  public showSpinner;

  constructor(
    public commonService: CommonService,
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef
  ) { }

  ngOnInit() {
    if (this.phishingDomainData && this.phishingDomainData.length > -1) {
      this.oldPhishingDomainData = this.phishingDomainData.slice();
    } else {
      this.oldPhishingDomainData = [];
    }
  }

  searchPhishingDomains(searchValue) {
    // if (this.phishingDomainData && this.phishingDomainData.length > -1) {
    var newDomain = [];
    this.phishingDomainData = this.oldPhishingDomainData.slice();
    this.phishingDomainData.forEach((element) => {
      if (
        element.fraud_domain.toLowerCase().indexOf(searchValue.toLowerCase()) >=
        0
      ) {
        newDomain.push(element);
      }
    });
    this.phishingDomainData = newDomain;
    // }
  }
  confidenceLevel(data) {
    var level = this.commonService.confidenceLevel(data);
    return level;
  }

  checkPrivateConditions(name) {
    if (name && name.indexOf("PRIVACY") >= 0) {
      return "Privacy Protected";
    } else if (name && name.indexOf("PRIVACY") >= 0) {
      return "Privacy Protected";
    } else if (name && name.indexOf("abuse") >= 0) {
      return "Privacy Protected";
    } else {
      return name;
    }
  }
  openRemoveOptions(template: TemplateRef<any>, phishing) {
    phishing.selected = true;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }

  delPhishingDomain(reason) {
    this.phishingDomainData.map((ph) => {
      if (ph.selected == true) {
        ph.updated = true;
        ph.archive = true;
        ph.archive_reason = reason;
        delete ph["selected"];
        // this.delReason = null;
      }
    });
    this.updatePhishingDomain();
  }

  public updatePhishingDomain() {
    this.portfolioSurveyService
      .updatePhishingDomain(
        this.phishingDomainData,
        this.mainDomain["domain_name"]
      )
      .subscribe(
        (data) => {
          this.modalRef.hide();
        alert("Lookalike domain deleted successfully!")
        },
        (error) => {
          this.modalRef.hide();
          if (error["status"] === 403) {
            alert(
              "You do not have permission to perform this action. Please write to us at contact@firecompass.com."
            );
          } else {
            alert("Error while updating");
          }
        }
      );
  }
  delReason(reason) {
    this.delPhishingDomain(reason);
  }
  cancelDel(a) {
    this.phishingDomainData.map(content => {
      content.selected = false
    })
  }
}
