import {
  Component,
  OnInit,
  DoCheck,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PortfolioSurveyService } from "./../../../_services/portfolio-survey.service";
import { CommonService } from "../../../_services/common.service";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-vulnerabilities',
  templateUrl: './vulnerabilities.component.html',
  styleUrls: ['./vulnerabilities.component.scss']
})
export class VulnerabilitiesComponent implements OnInit, DoCheck {
  @ViewChild("tabSet") tabSet: TabsetComponent;
  @ViewChild("serviceComponent") serviceComponent;
  @ViewChild("webappComponent") webappComponent;

  public infoFlag: any = false
  public domains;
  public filters = {};
  public cveDetails;
  public domain;
  public digitalRisk = [];
  public screennshots = [
    "https://i2.wp.com/hackersonlineclub.com/wp-content/uploads/2018/02/Networking.png?resize=662%2C396&ssl=1",
    "https://i.ytimg.com/vi/9N2fkh3fa10/maxresdefault.jpg",
  ];
  public showImage: boolean = false;
  public imageIndex: number = -1;
  public phishingDomains: any;
  public loadingRisk = false;
  public loadingPhishing = false;
  public totalLeakCredentialsCount;
  public vulnerabilitiesCount;
  public phishingCount;
  public maliciousCount;
  public leakedContentCount;
  public leakedContentCountNewValue: any;
  public leakedContents;
  public webDocumentCount;
  public webDocumentCountNewValue: any;
  public webDocuments;
  public total_cve_count;
  public subDomainsCount;
  public subDomainsCountOld;
  public whoisDetailsForRds;
  public automatedDone: boolean = false;
  public relatedDomainsHostsOld: any;
  public subDomains;
  public mainDomain;
  public maliciousList = [];
  public totalLeakCredentialsCountOld;
  public domainsLeakedCredentials: any;
  public relatedDomainsLeakCredentialsOld: any;
  public isUserTrial: boolean = false;
  public show3RdPartyTab: boolean = false;
  private userDomains = [];
  public buckets: any;
  public buckets_count: number;
  public codeLeaks: any;
  public showExecutiveReport: boolean = false;
  public setAssetView: boolean = false;
  public serviceVulnerabilties = [];
  public webVulnerabilties = [];
  public mobileVulnerabilties = [];
  public serviceVulCount;
  public mobileVulCount;
  public webVulCount;
  public pasteDataCount = -1;
  public loadMainDomain = false
  public showSpinnerForBucket = false
  public bucketCsvDetails = []
  public page = 1;
  public startDay: number = 365;
  public codeLeaksCount = 0;
  public filesData = {};
  public serviceLoader = true;
  public webVulLoader = true;
  public assetWiseServiceVulnerabilities: Object = {};
  public totalNetworkSum = 0;
  public totalWebAppSum = 0;
  // Chart js
  public severityBgColor = [
    {
      backgroundColor: [
        '#F06A6A',
        '#FDC107',
        '#2FA8D8',
        '#5DBE75',
        '#d38e20',
      ]
    }
  ];
  public severityLevelBgColor = [
    {
      backgroundColor: [
        '#F06A6A',
        '#FDC107',
        '#2FA8D8',
        '#5DBE75',
        '#adb3b8',
      ]
    }
  ];
  public pieChartOptions:ChartOptions = {
    responsive: true,
    legend:{
      position:'left'
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }
        if (tooltipModel.body) {
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          innerHtml += '</thead><tbody>';
          bodyLines.forEach(function (body, i) {
            let sp1 = body[0].split(' (');
            let sp2 = sp1[1].split(':');
            let labelBody = sp1[0]+' :'+sp2[1];
            innerHtml += '<tr><td>' + labelBody + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public pieChartType: ChartType = 'doughnut';
  public pieChartLegend = true;
  // service severity
  public service_by_severity_pieChartLabels = ['Critical', 'High', 'Medium', 'Low']
  public service_by_severity_pieChartLabels_mod = [];
  public service_by_severity_pieChartData = [0, 0, 0, 0];
  // service state
  public service_by_state_pieChartLabels = ['Open', 'Ready To Retest', 'Closed', 'Resolved', 'Retest In Progress'];
  public service_by_state_pieChartLabels_mod = [];
  public service_by_state_pieChartData = [0, 0, 0, 0];
  // Webapp
  public webapp_by_severity_pieChartLabels = ['Critical', 'High', 'Medium', 'Low']
  public webapp_by_severity_pieChartLabels_mod = [];
  public webapp_by_severity_pieChartData = [0, 0, 0, 0];
  // service state
  public webapp_by_state_pieChartLabels = ['Open', 'Ready To Retest', 'Closed', 'Resolved', 'Retest In Progress'];
  public webapp_by_state_pieChartLabels_mod = [];
  public webapp_by_state_pieChartData = [0, 0, 0, 0];
  public serviceChartLoading = true;
  public webappChartLoading = true;
  public showThis = 'scopeFilters';
  public openDropdown = false;
  public console = console;
  public selectedScope = '';
  public scopeConfig = false;

  public filterScopes = [
      { selected: true, key: 'IN', value: 'In Scope' },
      { selected: false, key: 'OUT', value: 'Out of Scope' },
      { selected: false, key: 'NOT_DEFINED', value: 'Not Defined' },
      { selected: false, key: 'ALL', value: 'All' }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private portfolioSurveyService: PortfolioSurveyService,
    private commonService: CommonService
  ) {
    this.setScopeStatus();
   }

  ngOnInit() {
  }
  async setScopeStatus() {
    await this.commonService.delay(2000);
    this.scopeConfig = localStorage.getItem('scope_config') === 'true';
    this.infoFlag = localStorage.getItem('info_vul_config')
    if (this.infoFlag === 'true') {
      this.service_by_severity_pieChartLabels = ['Critical', 'High', 'Medium', 'Low', 'Info'];
      this.service_by_severity_pieChartData = [0, 0, 0, 0, 0];
      this.webapp_by_severity_pieChartLabels = ['Critical', 'High', 'Medium', 'Low', 'Info'];
      this.webapp_by_state_pieChartData = [0, 0, 0, 0, 0];
    }
    if (this.scopeConfig) {
      this.selectedScope = 'IN';
    } else {
      this.selectedScope = '';
    }
    this.filters['scope'] = this.selectedScope;
    this.getDomainName();
  }
  
  filterByScope (scope: string) {
      this.filters['scope'] = scope;
      this.selectedScope = scope;
      this.getAllData();
      if(this.serviceComponent) this.serviceComponent.refreshData();
      if(this.webappComponent) this.webappComponent.refreshData();
  }

  updateScopeFilter(scope) {
      this.filters['scope'] = scope;
      this.getChartsData();
  }
  filterDataOnDay(value) {
    const todayDate = new Date();
    if (value == null) {
      // this.filterRdsHosts();
      this.filters = {
        ...this.filters,
        ...{
          fromDate: new Date(
            todayDate.getTime() - this.startDay * 24 * 3600 * 1000
          ).toISOString(),
          toDate: new Date(todayDate.setDate(todayDate.getDate() + 1)).toISOString(),
        },
      };
    }
    else {
      this.filters = {}
      this.startDay = 365
    }
    if(this.serviceComponent) this.serviceComponent.refreshData();
    if(this.webappComponent) this.webappComponent.refreshData();
    this.getAllData()
  }
  public getDomainName() {
    this.route.queryParams.subscribe((params) => {
      if (params && Object.keys(params).length > 0) {
        if (params["domain"]) {
          this.domain = params["domain"];
          this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { domain: this.domain },
            queryParamsHandling: "merge",
            skipLocationChange: true,
          });
          this.getAllData();
        }
        if (params["activetab"]) {
          this.tabSet.tabs[1].active = true;
          this.setAssetView = true;
        }
      } else {
        this.portfolioSurveyService.getDomainsSearchForUser().subscribe(
          (data: any) => {
            data.filter((d) => {
              if (d["domain_type"] == "self") {
                this.domain = d["domain"];
                this.getAllData();
              }
            });
          },
          (error) => {
            this.errorForDomain(error);
          }
        );
      }
    });
  }
  public getAllData() {
    if (this.domain) {
      // this.getPhishing();
      // this.getDigitalRisk();
      // this.getBucketsForDomains();
      // this.getCveDetailsForDomains()
      // this.getRelatedDomainsSubDomains();
      // this.getPasteDataCount();
      // this.getVulnerabilityDeepc();
      // this.getMaliciousRisk();
      // this.getRelatedDomainsLeakCredentials();
      // this.getUserSelfDomain();
      // this.getCodeLeaksForDomain();
      // this.getServiceVulnerability();
      // this.getMobileAppVulnerability();
      // this.getWebAppVulnerability();
      this.getChartsData();
      // this.webappComponent.refreshData();
    } else {
      alert(
        "No domain associated with you, Please write us on contact@firecompass.com"
      );
    }
  }
  getTotalValue(arr=[]){
    return arr.reduce((a, b) => a + b, 0);
  }
  getChartsData() {
    if(!this.domain) return;
    this.serviceChartLoading = true;
    this.webappChartLoading = true;
    const params = {
      ...this.filters
    }
    this.portfolioSurveyService.getServiceChartData(this.domain,params).subscribe(
      (data: any) => {
        this.service_by_severity_pieChartData = [
          data.by_severity?.critical || 0, 
          data.by_severity?.high || 0,
          data.by_severity?.medium || 0, 
          data.by_severity?.low || 0,
          data.by_severity?.info || 0
        ];

        this.service_by_severity_pieChartLabels_mod = this.concatLabel(
          this.service_by_severity_pieChartLabels,
          this.service_by_severity_pieChartData);
        this.service_by_state_pieChartData = [
          data.by_state?.open || 0, 
          data.by_state?.ready_to_retest || 0,
          data.by_state?.closed || 0, 
          data.by_state?.resolved || 0,
          data.by_state?.retest_in_progress || 0,
        ];
        this.service_by_state_pieChartLabels_mod = this.concatLabel(
          this.service_by_state_pieChartLabels,
          this.service_by_state_pieChartData);
        this.serviceChartLoading = false;  

        let values = Object.values(this.service_by_severity_pieChartData);
        this.totalNetworkSum = values.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);   
      },
      (error) => {
        console.log(error);
        // this.errorForDomain(error);
        this.serviceChartLoading = false;
      }
    );
    this.portfolioSurveyService.getWebChartData(this.domain, params).subscribe(
      (data: any) => {
        this.webapp_by_severity_pieChartData = [
          data.by_severity?.critical || 0, 
          data.by_severity?.high || 0,
          data.by_severity?.medium || 0,
          data.by_severity?.low || 0,
          data.by_severity?.info || 0
        ];
        this.webapp_by_severity_pieChartLabels_mod = this.concatLabel(
          this.webapp_by_severity_pieChartLabels,
          this.webapp_by_severity_pieChartData);

        this.webapp_by_state_pieChartData = [
          data.by_state?.open || 0, 
          data.by_state?.ready_to_retest || 0,
          data.by_state?.closed || 0,
          data.by_state?.resolved || 0,
          data.by_state?.retest_in_progress || 0,
        ];
        this.webapp_by_state_pieChartLabels_mod = this.concatLabel(
          this.webapp_by_state_pieChartLabels,
          this.webapp_by_state_pieChartData);
        this.webappChartLoading = false;
        let values = Object.values(this.webapp_by_severity_pieChartData);
        this.totalWebAppSum = values.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0); 
      },
      (error) => {
        console.log(error);
        // this.errorForDomain(error);
        this.webappChartLoading = false;
      }
    );
  }
  setUserAccount(userAccount) {
    if (userAccount && userAccount == "trial") {
      this.isUserTrial = true;
    }
  }
  getVulnerabilityDeepc() {
    const params = {
      ...this.filters
    }
    this.portfolioSurveyService.getVulnerabilityDeepc(this.domain, params).subscribe(
      (data: any) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.cveDetails = data["data"];
          this.total_cve_count = data["vulnerabilities_count"];
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }
  // leakContentCountChange(value) {
  //   this.leakedContentCountNewValue = value;
  // }
  ngDoCheck() {
    // this.leakedContentCount = this.leakedContentCountNewValue ? this.leakedContentCountNewValue : '0';
  }

  getRelatedDomainsLeakCredentials() {
    this.portfolioSurveyService
      .getRelatedDomainsLeakCredentials(this.domain)
      .subscribe(
        (data: any) => {
          if (data) {
            if (data["leak_credentials"]) {
              this.setUserAccount(data["userAccount"]);
              this.totalLeakCredentialsCount = data["leak_credentials"][
                "total_leaked_credentials"
              ]
                ? data["leak_credentials"]["total_leaked_credentials"]
                : 0;
              this.domainsLeakedCredentials = data["leak_credentials"]["data"];
              this.mainDomain = data.domain;
              if (this.mainDomain) this.loadMainDomain = true
              if (
                data["leak_credentials"]["status"] == "fail" ||
                !this.totalLeakCredentialsCount
              ) {
                this.getAutomatedData();
              } else {
                this.domainsLeakedCredentials.forEach((element) => {
                  this.commonService.checkCreatedAndUpdated(element);
                });
                this.relatedDomainsLeakCredentialsOld = this.domainsLeakedCredentials.slice();
                this.totalLeakCredentialsCountOld = this.totalLeakCredentialsCount;
              }
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  getMaliciousRisk() {
    const params = {
      ...this.filters
    }
    this.loadingRisk = true;
    this.portfolioSurveyService.getMaliciousRisk(this.domain, params).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.loadingRisk = false;
          this.maliciousCount = data["count"];
          this.maliciousList = data["data"];
        }
      },
      (error) => {
        console.log(
          "Error while loading risks. Please try again after sometime!"
        );
        this.errorForDomain(error);
      }
    );
  }

  getDigitalRisk() {
    this.loadingRisk = true;
    this.portfolioSurveyService.getDigitalRisk(this.domain).subscribe(
      (data: any) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.digitalRisk = data.data;
          this.loadingRisk = false;
          this.leakedContents = data["leak_contents"];
          this.leakedContentCount = data["count_leaked_content"];
          this.webDocuments = data["cloud_documents"];
          this.webDocumentCount = this.webDocuments.length;
        }
      },
      (error) => {
        alert("Error while loading risks. Please try again after sometime!");
        this.errorForDomain(error);
      }
    );
  }

  screenshots(i) {
    this.showImage = true;
  }

  getPhishing() {
    this.loadingPhishing = true;
    this.portfolioSurveyService.getPhishingDomains(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.loadingPhishing = false;
          this.phishingDomains = data["data"];
          this.phishingCount = data["count"];
        }
      },
      (error) => {
        this.loadingPhishing = false;
        this.errorForDomain(error);
      }
    );
  }

  errorForDomain(error) {
    if (error.status == 403) {
      alert(
        "Forbidden! You are not allowed to perform this action. Please contact us at contact@firecompass.com"
      );
      this.router.navigate(["/new/self-domains"]);
    }
  }
  updateTotalCvCount(count) {
    this.total_cve_count = count;
    // if (count != undefined && count != null)
    // 	document.getElementById('totalCveCount').innerText = count;
  }

  getCveDetailsForDomains() {
    this.portfolioSurveyService
      .getCveDetailsForDomains(this.domain)
      .subscribe((data) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.cveDetails = data["data"];
          this.total_cve_count = data["total_cve_count"];
        }
      });
  }
  getBucketsForDomains() {
    this.showSpinnerForBucket = true
    const params = {
      "page": this.page,
      ...this.filters
    }
    this.portfolioSurveyService.getCloudBuckets(this.domain, params).subscribe((data) => {
      if (data) {
        // this.setUserAccount(data["userAccount"]);
        this.buckets = data["results"];
        this.buckets_count = data["count"];
        this.getCSVdata()
        this.getFilesCount(this.buckets)
        this.showSpinnerForBucket = false
      } else this.showSpinnerForBucket = false
    }, (error) => {
      this.showSpinnerForBucket = false
    }
    );
  }


  getFilesCount(bucket_data) {
    var bucketNames = []
    var buckeNameStr = null;
    bucket_data.forEach(element => {
      bucketNames.push(element['bucket_name'])

    });
    buckeNameStr = bucketNames.join()
    this.portfolioSurveyService.getFileCount(this.domain, buckeNameStr).subscribe((data) => {
      if (data) {
        this.filesData = data['data']

      }
    })
  }




  getCSVdata() {
    this.bucketCsvDetails = []
    const params = {
      limit: this.buckets_count,
      page_size: this.buckets_count
    }
    this.portfolioSurveyService.getBuckets(this.domain, params).subscribe((data: any) => {
      if (data) {
        this.bucketCsvDetails = data['data'];
      }
    })
  }
  getRelatedDomainsSubDomains() {
    this.portfolioSurveyService
      .getRelatedDomainsSubDomains(this.domain)
      .subscribe(
        (data: any) => {
          if (data) {
            this.setUserAccount(data["userAccount"]);
            this.domains = data.related_domains["data"];
            this.mainDomain = data.domain["data"];
            this.subDomainsCount = data.related_domains.total_sub_domain_count;
            this.subDomainsCountOld = this.subDomainsCount;
            this.whoisDetailsForRds = data["additional_RD_details"];
            if (
              data["related_domains"]["status"] == "fail" ||
              !this.domains ||
              !this.domains.length ||
              !this.subDomainsCount
            ) {
              this.getAutomatedData();
            } else {
              this.domains.forEach((element) => {
                this.commonService.checkCreatedAndUpdated(element);
                element.hosts.forEach((host) => {
                  this.commonService.checkCreatedAndUpdated(host);
                });
              });
              this.relatedDomainsHostsOld = JSON.parse(
                JSON.stringify(this.domains)
              );
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  getAutomatedData() {
    // if (!this.automatedDone) {
    this.automatedDone = true;
    // 	this.portfolioSurveyService.getAutomatedDataForDomain(this.domain).subscribe(data => {
    // 		if (data) {
    // 			console.log(data);
    // 		}
    // 	})
    // }
  }

  getUserSelfDomain() {
    this.portfolioSurveyService
      .getDomainsSearchForUser()
      .subscribe((data: any) => {
        var index = -1;
        data.forEach((d, i) => {
          if (d["domain"] == this.domain && d["domain_type"] == "self") {
            this.show3RdPartyTab = true;
            index = i;
          }
        });
        if (index != -1) {
          data.splice(index, 1);
        }
        this.userDomains = data;
      });
  }
  public getCodeLeaksForDomain() {
    const params = {
      "page": 1,
      "limit": 10,
      ...this.filters
    }
    this.portfolioSurveyService
      .getCodeLeaks(this.domain, params)
      .subscribe((data: any) => {
        if (data && data["status"] == "pass") {
          this.codeLeaksCount = data["count"]
          this.codeLeaks = data["data"];
          console.log("I am done printing", this.mainDomain, this.codeLeaks)
          this.setUserAccount(data["userAccount"]);
        }
      }, error => {
        if (error['status'] === 403) {
          alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
        } else {
          alert("Error while getting code leaks!")
        }

      });
  }
  getServiceVulnerability() {
    if(!this.mainDomain) return;
    var params = {
      "view": "asset_view",
      "page": this.page,
      "limit": 10,
      ...this.filters
    }
    this.portfolioSurveyService
      .getServiceVulnerability(this.domain, params)
      .subscribe((vul) => {
        this.serviceVulnerabilties = vul["data"];
        this.serviceVulCount = this.serviceVulnerabilties.length
          ? this.serviceVulnerabilties[0]['total']
          : 0;
        this.serviceLoader = false
      });
    // this.portfolioSurveyService.getIpsDetailsPaginated(this.domain, params).subscribe( data => {
    //     this.assetWiseServiceVulnerabilities = data;
    // });
  }
  
  getWebAppVulnerability() {
    var params = {
      "page": this.page,
      "limit": 10,
      ...this.filters
    }
    this.portfolioSurveyService
      .getWebAppVulnerability(this.domain, params)
      .subscribe((vul) => {
        this.webVulnerabilties = vul["data"];
        this.webVulCount = this.webVulnerabilties.length
          ? this.webVulnerabilties[0]['total']
          : 0;
        this.webVulLoader = false
      });
  }
  getMobileAppVulnerability() {
    this.portfolioSurveyService
      .getMobileAppVulnerability(this.domain)
      .subscribe((vul) => {
        this.mobileVulnerabilties = vul["data"];
        this.mobileVulCount = this.mobileVulnerabilties.length
          ? this.mobileVulnerabilties.length
          : 0;
      });
  }
  getPasteDataCount() {
    const params = {
      ...this.filters
    }
    this.portfolioSurveyService
      .getPasteDataCount(this.domain, params)
      .subscribe((data) => {
        if (data && data["status"] == "pass") {
          this.pasteDataCount = data["count"];
        }
      });
  }
  refreshCount(e) {
    console.log('refreshCount', e);
    if (e == "network") {
      this.getServiceVulnerability();
    } else if (e == "webapp") {
      this.getWebAppVulnerability();
    } else if (e == "ip") {
      this.getMaliciousRisk();
    }
  }
  filterList(tab, filter, event) {
    if (event && event.active && event.active[0]) {
      const index = event.active[0]._index;
      if(index>=0 && tab>=0 && filter){
        this.tabSet.tabs[tab].active = true;
        this.prepareFilter(tab,filter,index);
      }
    }
  }
  prepareFilter(tab, filterType, filterIndex) {
    console.log(tab, filterType, filterIndex);
    if(tab == 0){ //network
      this.serviceComponent.changeState(filterType, filterIndex);
    }else if(tab == 1) { // webapp
      this.webappComponent.changeState(filterType, filterIndex);
    }
  }
  concatLabel(label:any,data:any){
    let mod:any = [];
    let count = 0;
    label.map(x => {
      mod.push(x+' ('+data[count]+')');
      count++;
    });
    return mod;
  }
}