import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'profileType'
})
export class ProfileTypePipe implements PipeTransform {
	public userDetails;
	public getUserType;
	public userType
	transform(user: string) {
		if (user == 'usertype') {
			try {
				if (localStorage.profile) {
					this.userDetails = JSON.parse(localStorage.profile);
					if (this.userDetails['user_metadata'].user_type) {
						this.getUserType = this.userDetails['user_metadata'].user_type
						this.userType = this.getUserType.charAt(0).toUpperCase() + this.getUserType.slice(1);
					} else {
						return 'Vendor'
					}
					return this.userType;
				} else {
					return 'User'
				}
			} catch (e) {
				return 'Vendor'
			}
		}
	}
}
