import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortfolioSurveyService } from '../../../_services/portfolio-survey.service';

@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.scss']
})
export class NewDashboardComponent implements OnInit {

  public selectedDash = "Insights&Risks";
  public domain;

  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.getDomainName();
  }

  public getDomainName() {
    this.route.queryParams.subscribe((params) => {
      if (params && params["domain"]) {
        this.domain = params["domain"];
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { domain: this.domain },
          queryParamsHandling: "merge",
          skipLocationChange: true,
        });
      } else {
        this.portfolioSurveyService
          .getDomainsSearchForUser()
          .subscribe((data: any) => {
            data.filter((d) => {
              if (d["domain_type"] == "self") {
                this.domain = d["domain"];
              }
            });
          });
      }
    });
  }

}
