import { Component, OnInit, TemplateRef } from '@angular/core';
import { PortfolioSurveyService } from '../../_services/portfolio-survey.service'
import { CommonService } from '../../_services/common.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ReportService } from "../../_services/report.service"

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
 
  public reports
  public page = 1;
  public limit = 10;
  public totalItems = 0;
  public showSpinner = false;
  public delReport;

  constructor(public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public commonService: CommonService,
    public reportService : ReportService) { }

    ngOnInit(): void {
    this.getDownloadReport()
  }
  getDownloadReport() {
    this.showSpinner = true
    this.reportService.getDownloadReport(this.page).subscribe(data => {

      if (data) {
        this.showSpinner = false;
        this.totalItems = data['count']
        this.reports = data['results']
      }
    }, error => {
      this.showSpinner = false;
      console.log(error.error.name)

    })
  }
  goToPage(val) {
    this.page = val;
    this.getDownloadReport()
  }
  delReportModel(template: TemplateRef<any>, report) {

    this.delReport = report
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  delData() {
    this.delReport.archive = true
    this.showSpinner = true
    this.reportService.updateReports(this.delReport.id,{archive : true}).subscribe((data: any) => {
      this.modalRef.hide()
      this.goToPage(this.page)
    })

  }
  cancelDelete() {
    this.delReport = {}
    this.modalRef.hide()
  }
}

