import { Component } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./app-footer.component.html",
})
export class AppFooter {
  public currentYear = null;
  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  ngDoCheck() {
    const whiteLabelDetails: any = JSON.parse(
      localStorage.getItem("whiteLabel")
    );
    // console.log(whiteLabelDetails);

    if (whiteLabelDetails && whiteLabelDetails.type !== "none") {
      const footer = document.querySelector(".app-footer");
      footer.innerHTML = whiteLabelDetails.footerText;
    }
  }
}
