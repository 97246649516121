import { Injectable } from "@angular/core";
import { AuthService } from "../views/pages/auth.service";
import { Injectable as Injectable_1 } from "@angular/core";

@Injectable_1()
@Injectable()
export class RolesService {
  public profile;

  constructor(private authService: AuthService) {
    this.setProfile();
  }

  setProfile() {
    if (this.authService.isAuthenticated()) {
      this.profile = JSON.parse(localStorage.getItem("profile"));
      this.isUserAdmin();
      this.isUserOsint();
      this.isUserUser();
      this.isUserVendor();
      this.isUserVendorAssessment();
    }
  }

  isUserAdmin() {
    if (
      this.profile.account_type === "service_provider" ||
      this.profile.account_type === "customer"
    ) {
      if (this.profile && this.profile.roles) {
        if (this.profile.roles.indexOf("*.admin") > -1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  isUserOsint() {
    // if (localStorage.getItem('id_token')){
    if (
      this.profile.account_type === "service_provider" ||
      this.profile.account_type === "customer"
    ) {
      if (this.profile && this.profile.roles) {
        if (
          this.profile.roles.indexOf("osint") > -1 ||
          this.profile.roles.indexOf("*.admin") > -1
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  isUserUser() {
    // if (localStorage.getItem('id_token')){
    if (
      this.profile.account_type === "service_provider" ||
      this.profile.account_type === "customer"
    ) {
      if (this.profile && this.profile.roles) {
        if (this.profile.roles.indexOf("user") > -1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  isUserVendor() {
    // if (localStorage.getItem('id_token')){
    if (
      this.profile.account_type === "service_provider" ||
      this.profile.account_type === "customer"
    ) {
      if (this.profile && this.profile.roles) {
        if (this.profile.roles.indexOf("vendor") > -1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  isUserVendorAssessment() {
    if (
      this.profile.account_type === "service_provider" ||
      this.profile.account_type === "customer"
    ) {
      if (this.profile && this.profile.roles) {
        if (
          this.profile.roles.indexOf("vendor_assessment") > -1 ||
          this.profile.roles.indexOf("*.admin") > -1
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  isServiceProvider() {
    if (this.profile.account_type === "service_provider") {
      return true;
    }
  }
}
