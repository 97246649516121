import { Pipe, PipeTransform } from '@angular/core';
import * as validator from 'validator';

@Pipe({
	name: 'validateIpPort'
})
export class ValidIpPortPipe implements PipeTransform {
	constructor() { }
	transform(ip_port: string) {
		if (ip_port != null && ip_port.includes(":")) {
			let data = ip_port.split(":")
			if (validator.isIP(data[0]) == true){
				let ports = data[1].split("|")
				for (let i = 0; i < ports.length; i++) {
					if (!Number.isInteger(Number(ports[i]))){
						return false
					}
				  }
				return true
			}
			else if (ip_port != null && ip_port.includes("-")) {
				let data2 = ip_port.split("-")
				if (validator.isIP(data2[0]) == true){
					let ports2 = data2[1].split("|")
					for (let i = 0; i < ports2.length; i++) {
						if (!Number.isInteger(Number(ports2[i]))){
							return false
						}
					  }
					return true
				}
			}
			
		} 
	}
}