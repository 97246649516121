import { Injectable, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

import { HttpService } from "./http.service";
import { PelagoService } from "./pelago.service";

import {
  BenchmarkConfig,
  ProductConfig,
  OsintConfig,
  SiumConfig,
  GrapiConfig,
  DataLeaksConfig,
  NotificationConfig,
  JobAutomationConfig,
  ReportConfig,
  DWConfig
} from "../../environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class PortfolioSurveyService {
  public benchmarkBaseUrl = BenchmarkConfig.url;
  public productServiceBaseUrl = ProductConfig.url;
  public osintConfigUrl = OsintConfig.url;
  public siumConfigUrl = SiumConfig.url;
  public grapiUrl = GrapiConfig.url;
  public dataleakUrl = DataLeaksConfig.url;
  public notificationUrl = NotificationConfig.url;
  public jobAutomationConfig = JobAutomationConfig;
  public reportServiceConfig = ReportConfig;
  public dwUrl = DWConfig.url
  public _vulenrbilitySubject = new BehaviorSubject<any>(false);
  private headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("id_token"),
  });

  public headcountOptions = ["<5", "5-10", "11-25", "26-50", ">50"];
  public revenueOptions = ["<10 Cr", "10-50 Cr", "50-100 Cr", ">100 Cr"];

  public industryOptions = [
    "Energy and Power",
    "Large Scale Bank",
    "Large Scale FSI",
    "Large Scale Telecom",
    "Major IT/ITES",
    "Manufacturing",
    "Minor IT/ITES",
    "Online Travel",
    "Small Scale Bank",
    "Small Scale FSI",
    "Small Scale Telecom",
    "Others",
  ];

  // public industriesListObj = [
  //     {'name': 'Energy and Power', 'selected': false},
  //     {'name': 'Large Scale Bank', 'selected': false},
  //     {'name': 'Large Scale FSI', 'selected': false},
  //     {'name': 'Large Scale Telecom'},
  //     {'name': 'Major IT/ITES', 'selected': false},
  //     {'name': 'Manufacturing', 'selected': false},
  //     {'name': 'Minor IT/ITES', 'selected': false},
  //     {'name': 'Online Travel', 'selected': false},
  //     {'name': 'Small Scale Bank', 'selected': false},
  //     {'name': 'Small Scale FSI', 'selected': false},
  //     {'name': 'Small Scale Telecom', 'selected': false},
  //     {'name': 'Others'}
  // ];
  constructor(
    public httpSVC: HttpService,
    public pelagoSVC: PelagoService,
    public location: Location,
    public router: Router
  ) {}
  public getSurveyForUser() {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const getSurveyForUserUrl =
      this.benchmarkBaseUrl + "/survey/user/" + profile["user_id"];
    return this.httpSVC.get(getSurveyForUserUrl);
  }
  public searchProducts(searchString) {
    const getProductsUrl =
      this.productServiceBaseUrl +
      '/product?where={"product_name": {"contains":"' +
      searchString +
      '"}}&limit=20';
    return this.httpSVC.get(getProductsUrl);
  }
  public postSurveyForUser(data) {
    const postSurveyForUserUrl = this.benchmarkBaseUrl + "/survey";
    return this.httpSVC.post(postSurveyForUserUrl, data);
  }
  public getInPlaceNotInPlaceData(data) {
    const getInNOtInCharturl =
      this.benchmarkBaseUrl + "/survey/user/chart/in_notin_place?q=" + data;
    return this.httpSVC.get(getInNOtInCharturl);
  }
  public getPortfolioData() {
    const portfolioUrl =
      this.benchmarkBaseUrl + "/survey/user/chart/security_check";
    return this.httpSVC.get(portfolioUrl);
  }
  public getMaturityDetails() {
    const maturityDataUrl =
      this.benchmarkBaseUrl + "/survey/user/maturity/abcd@123.com";
    // const maturityDataUrl = this.benchmarkBaseUrl + '/survey/user/maturity/'+ profile['email'];
    return this.httpSVC.get(maturityDataUrl);
  }
  public getMaturityById(id) {
    var url = this.benchmarkBaseUrl + "/survey/maturity/" + id;
    return this.httpSVC.get(url);
  }
  public getOsintForCurrentUser() {
    const url = this.benchmarkBaseUrl + "/survey/osint/currentuser";
    return this.httpSVC.get(url);
  }
  public getStorageById(storageId) {
    const url = this.benchmarkBaseUrl + "/survey/storage/" + storageId;
    return this.httpSVC.get(url);
  }
  public submitMaturityReport(dataToSend) {
    const url = this.benchmarkBaseUrl + "/survey/user/create/request";
    return this.httpSVC.post(url, dataToSend);
  }
  public duediligenceErrorEvent = new EventEmitter();
  public duediligenceDataEvent = new EventEmitter();
  public getDueDiligenceReport() {
    const duediligenceUrl =
      this.benchmarkBaseUrl + "/survey/user/due_deligence/ZebPay";
    this.httpSVC.get(duediligenceUrl).subscribe(
      (data) => {
        this.duediligenceDataEvent.emit(data);
      },
      (error) => {
        if (error && error.status === 403) {
          alert("Please login in.");
        }
        this.duediligenceErrorEvent.emit(error);
      }
    );
  }
  public addDomainSearchForUserAndScan(domain) {
    // {
    //     "useremail": "sangeet.kumar@firecompass.com",
    //     "domain": "gmail.com",
    //     "userid": "",
    //     "paid": false,
    //     "permission": "osint"
    //   }
    const url = this.benchmarkBaseUrl + "/survey/user/domainEmail/add";
    return this.httpSVC.post(url, domain);
  }
  public addDomainSearchForUser(domain) {
    const url = this.benchmarkBaseUrl + "/survey/user/domain/add";
    // var dataToSend = {"domain": domain, "businessCriticality": businessCriticalValue};
    return this.httpSVC.post(url, domain);
  }
  public deleteDomainForUser(domain) {
    const url =
      this.benchmarkBaseUrl + "/survey/user/domain/delete/" + domain.id;
    return this.httpSVC.delete(url);
  }
  public getDomainsSearchForUser() {
    const url = this.benchmarkBaseUrl + "/survey/user/domain/get";
    return this.httpSVC.get(url);
  }
  public updateDomainsForUser(jsonToUpdate) {
    const url = this.benchmarkBaseUrl + "/survey/user/domain/update";
    return this.httpSVC.put(url, jsonToUpdate);
  }
  public getWebSecurity(domain) {
    // this.headers = new Headers({
    // 	'Content-Type': 'application/json',
    // 	'Authorization': 'Bearer ' + localStorage.getItem('id_token')
    // })
    const footprintUrl =
      this.benchmarkBaseUrl + "/survey/breachmz/analyze/" + domain;
    return this.httpSVC.get(footprintUrl);
  }
  public getAllDetailsOsint(domain) {
    // this.headers = new Headers({
    // 	'Content-Type': 'application/json',
    // 	'Authorization': 'Bearer ' + localStorage.getItem('id_token')
    // })
    const url = this.benchmarkBaseUrl + "/survey/osint/aggregate/" + domain;
    return this.httpSVC.get(url);
  }
  public getAllShadowDetails(domain_name) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/json/get/" +
      domain_name +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getBreachedCredentials(domain) {
    const url =
      this.benchmarkBaseUrl + "/survey/breachns/breacheddomain/" + domain;
    return this.httpSVC.get(url);
  }
  public getBreachedPassByDomain(domain, getScore = false) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/breachbq/domain/" +
      domain +
      "?source=" +
      getScore;
    return this.httpSVC.get(url);
  }
  public getBreachedPassByEmail(email) {
    const url = this.benchmarkBaseUrl + "/survey/breachhibp/email/" + email;
    return this.httpSVC.get(url);
  }
  public getWebScoreByDomain(domain) {
    const url = this.benchmarkBaseUrl + "/survey/osint/webscore/" + domain;
    return this.httpSVC.get(url);
  }
  public getPhishingDomains(domain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/breachns/phishingdomain/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getBlackListedIPs(domain) {
    const url =
      this.benchmarkBaseUrl + "/survey/breachns/blacklistip/" + domain;
    return this.httpSVC.get(url);
  }
  public getDomainDNSRecords(domain) {
    const url = this.benchmarkBaseUrl + "/survey/breachst/domain/" + domain;
    return this.httpSVC.get(url);
  }
  public getDomainSubDomainRecords(domain) {
    const url = this.benchmarkBaseUrl + "/survey/breachst/subdomain/" + domain;
    return this.httpSVC.get(url);
  }
  public getDomainWhoisRecords(domain) {
    const url = this.benchmarkBaseUrl + "/survey/breachst/whois/" + domain;
    return this.httpSVC.get(url);
  }
  // public getDomainTechRecords(domain) {
  // this.headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + localStorage.getItem('id_token')
  // })
  //     var url = this.benchmarkBaseUrl+'/survey/breachst/domain/'+domain;
  //     return this.httpSVC.get(url, { headers: this.headers }).map((response : Response) => response.json());
  // }
  public getPreDefineQuestionByTemplate(template) {
    const url =
      this.benchmarkBaseUrl + "/survey/va/template_question/" + template;
    return this.httpSVC.get(url);
  }
  public postCustomineQuestion(dataToSend) {
    const url = this.benchmarkBaseUrl + "/survey/va/custom_que";
    return this.httpSVC.post(url, dataToSend);
  }
  public addQuestionSet(dataToSend) {
    const url = this.benchmarkBaseUrl + "/survey/va/que_set";
    return this.httpSVC.post(url, dataToSend);
  }
  public addVendorResponse(dataToSend) {
    const url = this.benchmarkBaseUrl + "/survey/va/vendor_response";
    return this.httpSVC.post(url, dataToSend);
  }
  public updateVendorResponse(dataToSend) {
    const url = this.benchmarkBaseUrl + "/survey/va/update_vendor_response";
    return this.httpSVC.put(url, dataToSend);
  }
  public addVendorProjectMapping(dataToSend) {
    const url = this.benchmarkBaseUrl + "/survey/va/vendor_project_mapping";
    return this.httpSVC.post(url, dataToSend);
  }
  public getQuestionSetByEmail(user_email) {
    const url = this.benchmarkBaseUrl + "/survey/va/que_set/" + user_email;
    return this.httpSVC.get(url);
  }
  public getProjectDetails(setId) {
    const url = this.benchmarkBaseUrl + "/survey/va/que_set/set_id/" + setId;
    return this.httpSVC.get(url);
  }
  public getvendorDetails(setId) {
    const url = this.benchmarkBaseUrl + "/survey/va/vendors/" + setId;
    return this.httpSVC.get(url);
  }
  public getVendorDetailsByEmail() {
    // const profile = JSON.parse(localStorage.getItem('profile'));
    const getSurveyForUserUrl =
      this.benchmarkBaseUrl + "/survey/va/vendors_details/user";
    return this.httpSVC.get(getSurveyForUserUrl);
  }
  public getvendorResponceForProject(v_q_set_id) {
    const getSurveyForUserUrl =
      this.benchmarkBaseUrl +
      "/survey/va/vendor_response_project/" +
      v_q_set_id;
    return this.httpSVC.get(getSurveyForUserUrl);
  }

  public getRelatedDomainsLeakCredentials(
    domain,
    { toDate = null, fromDate = null, sortKey = null } = {}
  ) {
    // var url = this.benchmarkBaseUrl + "/survey/shadowit/get/domain/leakcredential/" + domain + "?false_positive=" + false + "&is_premium=" + false;
    var url =
      this.osintConfigUrl +
      "/shadowit/get/domain/leakedcredentials/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    return this.httpSVC.get(url);
  }
  public getDomainsLeakedCredentialsFromPelago(domain, count = 10, nextFrom) {
    return this.pelagoSVC.get(
      `query leakedMaskCreds(
				$domain: String!
				$count: Int = 10
				$nextFrom: String!
				) {
					leakedMaskCreds(domain: $domain, count: $count, nextFrom: $nextFrom) {
					uid
					email
					username
					password
					timestamp
					passwordtype
					sourcelong
					sourceshort
					}
				}`,
      {
        domain: domain,
        count: count,
        nextFrom: nextFrom || "",
      }
    );
  }
  public getDomainsLeakCredentials(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/relateddomain/leakedCredentials/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getDomainsLeakCredentialsPatched(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/relateddomain/leakedCredentials/" +
      domain +
      "?false_positive=" +
      true +
      "&is_premium=" +
      false +
      "&archive=" +
      true;
    return this.httpSVC.get(url);
  }
  public getRelatedDomainsSubDomains(
    domain,
    page = 1,
    limit = 10,
    searchKey = null
  ) {
    // const url = this.benchmarkBaseUrl + '/survey/shadowit/get/domain/subdomain/' + domain + "?false_positive=" + false + "&is_premium=" + false;
    var url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/get/domain/subdomain/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&archive=" +
      false;
    if (searchKey) {
      url = url + "&search=" + searchKey;
    }
    return this.httpSVC.get(url);
  }
  public getRelatedDomainsSubDomainsWithFilters(
    domain,
    {
      page = 1,
      page_size = 10,
      searchKey = null,
      tags = null,
      take_over_risk = false,
      toDate = null,
      fromDate = null,
      sortKey = null,
      archive = false,
      scope = null,
      expire = null,
      domain_expire = null
    } = {}
  ) {
    // var url = this.benchmarkBaseUrl + '/survey/shadowit/get/domain/subdomain/' + domain + "?page=" + page + "&limit=" + limit + "&false_positive=" + false + "&archive=" + false;
    var url =
      this.osintConfigUrl +
      "/shadowit/get/domain/details/" +
      domain +
      "?page=" +
      page +
      "&page_size=" +
      page_size +
      "&false_positive=" +
      false +
      "&archive=" +
      archive;
    if (searchKey) url = url + "&search=" + searchKey;
    if (tags) url = url + "&tags=" + tags;
    if (take_over_risk) url = url + "&take_over_risk=" + take_over_risk;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (scope) url = url + "&scope=" + scope;
    if (expire!==null) url = url + "&expire=" + expire;
    if (domain_expire!==null) url = url + "&domain_expire=" + domain_expire;

    return this.httpSVC.get(url);
  }
  public startScanForDomain(domain) {
    let data = { domain: domain };
    const url = this.benchmarkBaseUrl + "/survey/new/scan/" + domain;
    return this.httpSVC.post(url, data);
  }
  public getIps(domain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/get/ip/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getApplications(domain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/get/application/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getImageForDomainWithUrl(domain, url1) {
    const url = this.benchmarkBaseUrl + "/survey/getfile/storage/" + domain;
    const body = { publicUrl: url1 };
    return this.httpSVC.post(url, body);
  }

  public getAutomatedDataForDomain(domain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/get/automated/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getCveDetailsForDomains(domain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/get/cvedetails/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getDigitalRisk(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/digitalrisk/givendomain/domain/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getRelatedDomainWhois(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/related_domain/" +
      domain +
      "/whois?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getMaliciousRisk(domain, { fromDate = null, toDate = null } = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/malicious/givendomain/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false +
      "&archive=" +
      false;

    if (fromDate || toDate) {
      url = url + "&filter_date_by=" + "created_at";
    }
    if (fromDate) {
      url = url + "&from_date=" + fromDate;
    }
    if (toDate) {
      url = url + "&to_date=" + toDate;
    }

    return this.httpSVC.get(url);
  }
  public getIpsDetailsFromDeepc(domain, {scope = null} = {}) {
    var url =
      this.osintConfigUrl + "/shadowit/get/ip_details/givendomain/" + domain;
      if (scope)  url = url + "?scope=" + scope;
  

    return this.httpSVC.get(url);
  }
  public getIpsDetailsPaginated(
    domain,
    {
      page = 1,
      limit = 10,
      searchKey = null,
      toDate = null,
      fromDate = null,
      ipType = null,
      sortKey = null,
      tags = null,
      asn_names = null,
      archive = false,
      scope = null,
      state = null,
      severities = null,
      validated_flags=null,
      view = null,
      ips_list = null,
      fp_ar = null,
      port_state = null,
      validated = null,
      confidence = null,
      locations = null
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/ips/details/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&is_premium=" +
      false +
      "&archive=" +
      archive;
    if (ipType) url = url + "&ip_type=" + ipType;
    if (searchKey) url = url + "&search=" + encodeURIComponent(searchKey);
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (tags) url = url + "&tags=" + encodeURIComponent(tags);
    if (asn_names) url = url + "&asn_names=" + encodeURIComponent(asn_names);
    if (scope) url = url + "&scope=" + scope;
    if (state) url = url + "&vulnerability_states=" + state;
    if (severities) url = url + "&severity_levels=" + severities;
    if (validated_flags) url = url + "&validated=" + validated_flags;
    if (view) url = url + "&view=" + view;
    if (ips_list) url = url + "&ips_list=" + ips_list;
    if (fp_ar) url = url + "&fp_ar=" + fp_ar;
    if (port_state) url = url + "&port_state=" + port_state;
    if (validated !== null) url = url + "&ip_validated=" + validated;
    if (confidence !== null) url = url + "&confidence=" + confidence;
    if (locations !== null) url = url + "&locations=" + locations;
    return this.httpSVC.get(url);
  }
  public getIpsVulDetailsPaginated(
    domain,
    {
      page = 1,
      limit = 10,
      searchKey = null,
      toDate = null,
      fromDate = null,
      ipType = null,
      sortKey = null,
      tags = null,
      asn_names = null,
      archive = false,
      scope = null
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/deepc/ipVulnerabilityDetails/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&archive=" +
      archive;
    if (ipType) url = url + "&ip_type=" + ipType;
    if (searchKey) url = url + "&search=" + searchKey;
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (tags) url = url + "&tags=" + encodeURIComponent(tags);
    if (asn_names) url = url + "&asn_names=" + encodeURIComponent(asn_names);
    if (scope) url = url + "&scope=" + scope;

    return this.httpSVC.get(url);
  }
  public getDigitalFootprintScore(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/digitalfootprint/scores/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getDashboardRisksForDomain(domain, entity, {scope = null} = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/dashboard/risk/count/"+domain+"/"+entity+
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    if (scope) url = url + "&scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getDashboardProbableRisksForDomain(domain, entity, {scope = null} = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/dashboard/probable/risk/count/"+domain+"/"+entity+
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    if (scope) url = url + "&scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getRisksForDomain(domain, {scope = null} = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/risk/counts/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    if (scope) url = url + "&scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getOverallScoreForDomain(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/score/dashboard/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getProbableRiskCountForDomain(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/probable/risk/counts/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getVulnerabilityDeepc(
    domain,
    { fromDate = null, toDate = null } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/allvulnerability/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    if (fromDate || toDate) {
      url = url + "&filter_date_by=" + "created_at";
    }
    if (fromDate) {
      url = url + "&from_date=" + fromDate;
    }
    if (toDate) {
      url = url + "&to_date=" + toDate;
    }
    return this.httpSVC.get(url);
  }
  public getLeakedCredentialsforRd(domain, main_domain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/osint/breachpass/domain/" +
      main_domain +
      "/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);

  }
  public getLeakedCrentialsForMainDomain(main_domain, {page=1, limit=10}) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/leakedcredentials/paginate/details/" +
      main_domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false +
      "&is_premium=" +
      false +
      "&page=" + 
      page + 
      "&limit=" + limit;
    return this.httpSVC.get(url);
  }

  public getLeakedCredentialsPatched(domain, main_domain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/osint/breachpass/domain/" +
      main_domain +
      "/" +
      domain +
      "?false_positive=" +
      true +
      "&is_premium=" +
      false +
      "&archive=" +
      true;
    return this.httpSVC.get(url);
  }
  public getLeakedCrentialsPassword(domain_name, main_domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/leakedCredentials/password/" +
      main_domain +
      "/" +
      domain_name +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
 
  public getwebapplication(
    domain,
    {
      page = 1,
      limit = 10,
      searchKey = null,
      toDate = null,
      fromDate = null,
      technology = null,
      state = null,
      severities = null,
      view = null,
      scope = null,
      validated_flags = null,
      expire = null,
      apps_list = null,
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/webapplication/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&archive=" +
      false;
    if (searchKey) url = url + "&search=" + searchKey;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (technology) url = url + "&technology=" + encodeURIComponent(technology);
    if (state) url = url + "&cve_states=" + state;
    if (severities) url = url + "&severity_levels=" + severities;
    if (view) url = url + "&view=" + view;
    if (scope) url = url + "&scope=" + scope;
    if (validated_flags) url = url + "&validated=" + validated_flags;
    if (expire!==null) url = url + "&expire=" + expire;
    if (apps_list!==null) url = url + "&apps_list=" + apps_list;
    return this.httpSVC.get(url);
  }
  public getmobileapplication(domain,{scope=null} = {}) {
    let url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/mobileapplication/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false +
      "&archive=" +
      false;
    if (scope) url = url + "&scope=" + scope;
    return this.httpSVC.get(url);
  }
  public addMobileApp(mainDomain, mobileApp) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      mainDomain +
      "/webapplication";
    return this.httpSVC.put(url, mobileApp);
  }
  public getwebscoreforapplication(domain, application_id) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/webscore/" +
      domain +
      "?source_url=" +
      encodeURIComponent(application_id) +
      "&false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getsslscoreforapplication(domain, application_id) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/sslscore/" +
      domain +
      "?source_url=" +
      encodeURIComponent(application_id) +
      "&false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getDigitalRiskCountForDomains(domains, main_domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/domains/digitalrisk/count/" +
      main_domain;
    return this.httpSVC.post(url, domains);
  }
  public getNetblocks(domain) {
    var url = this.osintConfigUrl + "/shadowit/deepc/get/netblocks/" + domain;

    return this.httpSVC.get(url);
  }
  public getTgasforFilter(domain) { 
    var url = this.osintConfigUrl + "/shadowit/get/netblock/tags/list/" + domain;
    return this.httpSVC.get(url);
  }
  public getIpsForSubdomainsByDomain(relateddomain, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/relateddomain/subdomain/ips/" +
      domain_name +
      "/" +
      relateddomain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getService(
    domain,
    {
      page = 1,
      limit = 10,
      searchKey = null,
      toDate = null,
      fromDate = null,
      sortKey = null,
      service = null,
      tags = null,
      exclude_tags = null,
      state = null,
      ids = null,
      scope = null
    } = {}
  ) {
    // var url =this.osintConfigUrl + "/shadowit/deepc/get/service/" +domain +"?page=" + page + "&limit=" + limit + "&false_positive=" + false + "&archive=" + false + "&is_premium=" + false;
    var url =
      this.osintConfigUrl +
      "/shadowit/get/open/ports/details/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&archive=" +
      false +
      "&is_premium=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (searchKey) url = url + "&search=" + searchKey;
    if (service) url = url + "&service_name=" + service;
    if (tags) url = url + "&tags=" + tags;
    if (exclude_tags) url = url + "&exclude_tags=" + exclude_tags;
    if (state) url = url + "&state=" + state;
    if (scope) url = url + "&scope=" + scope;
    if (ids) url = url + "&ids=" + ids;

    return this.httpSVC.get(url);
  }
  public getTopRisk(domain,scope=null) {
    let url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/toprisk/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if(scope){
      url = url +'&scope='+scope;
    }
    return this.httpSVC.get(url);
  }
  public patchedGetTopRisk(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/toprisk/" +
      domain +
      "?false_positive=" +
      true +
      "&is_premium=" +
      true +
      "&archive=" +
      true;
    return this.httpSVC.get(url);
  }
  public getAllLeakedCredsForDomain(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/domain/leakedcredentials/all/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getBuckets(
    domain,
    {
      page = 1,
      limit = 10,
      searchKey = null,
      fromDate = null,
      toDate = null,
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/buckets/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&archive=" +
      false;
    if (searchKey) url = url + "&search=" + searchKey;
    if (fromDate || toDate) {
      url = url + "&filter_date_by=" + "created_at";
    }

    if (fromDate) {
      url = url + "&from_date=" + fromDate;
    }
    if (toDate) {
      url = url + "&to_date" + toDate;
    }
    return this.httpSVC.get(url);
  }

  public getCloudBuckets(
    domain,
    {
      page = 1,
      limit = 10,
      searchKey = null,
      fromDate = null,
      toDate = null,
      sortKey = null,
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/cloud/buckets/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&archive=" +
      false;
    if (searchKey) url = url + "&search=" + searchKey;
    if (fromDate || toDate) {
      url = url + "&filter_date_by=" + "created_at";
    }

    if (fromDate) {
      url = url + "&from_date=" + fromDate.split("T")[0];
    }
    if (toDate) {
      url = url + "&to_date=" + toDate.split("T")[0];
    }
    if (sortKey) {
      url = url + "&ordering=" + sortKey;
    }
    return this.httpSVC.get(url);
  }

  public getFileCount(domain, bucket_names) {
    console.log(domain);
    var url =
      this.osintConfigUrl +
      "/shadowit/cloud/buckets/files/count/" +
      domain +
      "?buckets=" +
      bucket_names;
    "&false_positive=" + false + "&archive=" + false;
    return this.httpSVC.get(url);
  }

  public getCodeLeaks(
    domain,
    {
      page = 1,
      limit = null,
      fromDate = null,
      toDate = null,
      searchKey = null,
      sortKey = null,
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/codeleak/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;

    if (limit) {
      url = url + "&page=" + page + "&limit=" + limit;
    }
    if (fromDate || toDate) {
      url = url + "&filter_date_by=" + "created_at";
    }
    if (fromDate) {
      url = url + "&from_date=" + fromDate;
    }
    if (toDate) {
      url = url + "&to_date=" + toDate;
    }
    if (searchKey) {
      url = url + "&search=" + searchKey;
    }
    if (sortKey) {
      url = url + "&ordering=" + sortKey;
    }
    return this.httpSVC.get(url);
  }
  public calculateScoreForDomain(domain) {
    const url = this.osintConfigUrl + "/shadowit/compute/score/" + domain;
    return this.httpSVC.post(url, { main_domain: domain });
  }
  public getRiskDetails(domain, riskType, { scope = null } ={}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/risk/details/" +
      riskType +
      "/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
      if (scope) url = url + "&scope=" + scope ;

    return this.httpSVC.get(url);
  }
  public patchedGetRiskDetails(domain, riskType) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/risk/details/" +
      riskType +
      "/" +
      domain +
      "?false_positive=" +
      true +
      "&is_premium=" +
      false +
      "&archive=" +
      true;
    return this.httpSVC.get(url);
  }
  public getActiveScanDetails(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/actively-scan/entities/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getActiveScanCount(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/actively-scan/counts/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public saveContactDetailsOfUser(userDetails) {
    const url =
      this.osintConfigUrl +
      "/shadowit/save/customer/vendor/contact/" +
      userDetails["customer_domain"];
    return this.httpSVC.post(url, userDetails);
  }
  public startMobileScan(mobileApp, mainDomain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/mobile/scan/startnew/" +
      mainDomain["domain_name"];
    return this.httpSVC.post(url, mobileApp);
  }
  public getSubDomain(
    domain,
    {
      page = 1,
      page_size = 10,
      searchKey = null,
      tags = null,
      take_over_risk = false,
      toDate = null,
      fromDate = null,
      sortKey = null,
      state = null,
      archive = false,
      scope = null,
      expire = null,
      host_list = null,
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/subdomain/" +
      domain +
      "?page=" +
      page +
      "&page_size=" +
      page_size +
      "&false_positive=" +
      false +
      "&archive=" +
      archive;
    if (searchKey) url = url + "&search=" + searchKey;
    if (tags) url = url + "&tags=" + tags;
    if (take_over_risk) url = url + "&take_over_risk=" + take_over_risk;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (state) url = url + "&state=" + state;
    if (scope) url = url + "&scope=" + scope;
    if (expire!==null) url = url + "&expire=" + expire;
    if (host_list !== null) url = url + "&host_list=" + host_list;

    return this.httpSVC.get(url);
  }
  public getTechnology(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/technology/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getTechnologyVulnerability(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/technology/vulnerability/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  //-------------------
  public getNetblocksCount(domain, { toDate = null, fromDate = null, scope = null } = {}) {
    let url =
      this.osintConfigUrl +
      "/shadowit/get/netblock/count/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (scope) url = url + "&scope=" + scope;

    return this.httpSVC.get(url);
  }
  //----------------
  public getipsCount(domain, { toDate = null, fromDate = null, scope = null } = {}) {
    let url =
      this.osintConfigUrl +
      "/shadowit/get/ips/details/count/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (scope) url = url + "&scope=" + scope;

    return this.httpSVC.get(url);
  }
  public getServiceVulnerability(
    domain,
    {
      limit = null,
      page = 1,
      fromDate = null,
      toDate = null,
      searchKey = null,
      sortKey = null,
      state = null,
      severities =null,
      scope = null,
      validated_flags=null,
      ip_validated = null,
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/service/vulnerability/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    if (limit) url = url + "&page=" + page + "&limit=" + limit;
    if (fromDate || toDate) url = url + "&filter_date_by=" + "created_at";
    if (fromDate) url = url + "&from_date=" + fromDate;
    if (toDate) url = url + "&to_date=" + toDate;
    if (searchKey) url = url + "&search=" + searchKey;
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (state) url = url + "&cve_states=" + state;
    if (severities) url = url + "&severities=" + severities;
    if (scope) url = url + "&scope=" + scope;
    if (validated_flags) url = url + "&validated=" + validated_flags;
    if (ip_validated !== null) url += `&ip_validated=${ip_validated}`;

    return this.httpSVC.get(url);
  }
  public getMobileAppVulnerability(domain) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/mobileapplication/vulnerability/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    return this.httpSVC.get(url);
  }
  public getWebAppVulnerability(
    domain,
    {
      limit = null,
      page = 1,
      fromDate = null,
      toDate = null,
	    searchKey = null,
      sortKey = null,
      state = null,
      severities = null,
      scope=null,
      validated_flags=null
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/deepc/get/webapplication/vulnerability/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false;
    if (limit) url = url + "&page=" + page + "&limit=" + limit;
    if (fromDate || toDate) url = url + "&filter_date_by=" + "created_at";
    if (fromDate) url = url + "&from_date=" + fromDate;
    if (toDate) url = url + "&to_date=" + toDate;
  	if (searchKey) url = url + "&search=" + searchKey;
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (state) url = url + "&cve_states=" + state;
    if (severities) url = url + "&severities=" + severities;
    if (scope) url = url + "&scope=" + scope;
    if (validated_flags) url = url + "&validated=" + validated_flags;
	
    return this.httpSVC.get(url);
  }
  public updateSubdomainDomain(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/subdomain";
    return this.httpSVC.put(url, dataToSend);
  }
  public deleteSubdomainDomain(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/delete/" +
      domain_name +
      "/subdomain";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateIPs(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl + "/shadowit/deepc/update/" + domain_name + "/ip";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateIPsScanProfile(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/ip/scan_profile";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateServiceScanProfile(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/service/scan_profile";
    return this.httpSVC.put(url, dataToSend);
  }
  public deleteIPs(dataToSend, domain_name) {
    // const url =
    // this.osintConfigUrl + "/shadowit/deepc/delete/" + domain_name + "/ip";
    // return this.httpSVC.put(url, dataToSend);
    const url = this.osintConfigUrl + "/shadowit/ips/" + domain_name;
    return this.httpSVC.delete(url, dataToSend);
  }
  public updatePhishingDomain(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/phishingdomain";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateMalicious(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/malicious";
    return this.httpSVC.put(url, dataToSend);
  }
  public deleteServiceVulnerability(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/delete/service/" +
      domain_name +
      "/vulnerability";
    return this.httpSVC.put(url, dataToSend);
  }
  public updatewebapplication(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/webapplication";
    return this.httpSVC.put(url, dataToSend);
  }
  public deleteTechnologyVulnerability(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/delete/technology/" +
      domain_name +
      "/vulnerability";
    return this.httpSVC.put(url, dataToSend);
  }
  public deletewebapplicationvulnerability(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/delete/" +
      domain_name +
      "/webapplication/vulnerability";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateNetblocks(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/netblocks";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateBuckets(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/buckets";
    return this.httpSVC.put(url, dataToSend);
  }

  public updateCloudBuckets(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl + "/shadowit/cloud/buckets/update/" + domain_name;
    return this.httpSVC.put(url, dataToSend);
  }
  public updateCodeLeaks(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/codeleak";
    return this.httpSVC.put(url, dataToSend);
  }
  public deleteService(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/delete/" +
      domain_name +
      "/service";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateBreachedCredentials(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/relateddomain/" +
      domain_name +
      "/leakedCredentials";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateDigitalRisk(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl + "/shadowit/update/" + domain_name + "/digitalrisk";
    return this.httpSVC.put(url, dataToSend);
  }
  public uploadAppFile(domain, file) {
    const dataToSend = file;
    const url = this.benchmarkBaseUrl + "/survey/upload/file/" + domain;
    return this.httpSVC.post(url, dataToSend);
  }
  public getMobileServicePurchaseForUser(user_email) {
    const url =
      this.siumConfigUrl +
      "/purchase/customer/" +
      user_email +
      "/inventory/AppKnox";
    return this.httpSVC.get(url);
  }
  public getMobileServiceUsageForPurchaseId(purchaseId) {
    const url = this.siumConfigUrl + "/usage/purchaseid/" + purchaseId;
    return this.httpSVC.get(url);
  }
  public getIpDetails(ip, domain_name) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/toosint/shadowit/get/details/ip/" +
      domain_name +
      "/" +
      ip;
    return this.httpSVC.get(url);
  }
  public runDeepcScan(scanDetails) {
    const url =
      this.osintConfigUrl +
      "/shadowit/redteaming/" +
      scanDetails["main_domain"];
    return this.httpSVC.post(url, scanDetails);
  }
  public getScansForUserAndDomain(domainName) {
    const url = this.osintConfigUrl + "/shadowit/get/scan/" + domainName;
    return this.httpSVC.get(url);
  }
  public createScanProfilesForDomains(domainName, scanProfiles) {
    const dataToSend = scanProfiles;
    const url =
      this.benchmarkBaseUrl +
      "/survey/toosint/shadowit/scanprofile/domain/" +
      domainName;
    return this.httpSVC.post(url, dataToSend);
  }
  public addNewDomain(mainDomain, dataToSend) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/add/" +
      mainDomain +
      "/related_domain";
    return this.httpSVC.put(url, dataToSend);
  }
  public addNewSubDomain(mainDomain, dataToSend) {
    const url =
      this.osintConfigUrl + "/shadowit/deepc/add/" + mainDomain + "/subdomain";
    return this.httpSVC.put(url, dataToSend);
  }
  public updateRelatedDomain(dataToSend, domain_name) {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/" +
      domain_name +
      "/related_domain";
    return this.httpSVC.put(url, dataToSend);
  }
  getGraphForEntity(entityDetails: object) {
    const graphDepth = entityDetails["graph_depth"]
      ? entityDetails["graph_depth"]
      : 1;
    const entityType = entityDetails["entity_type"];
    const entityName = entityDetails["entity_name"];
    const url =
      this.grapiUrl +
      "/" +
      entityDetails["domain_name"] +
      "/subgraph?entity_name=" +
      entityName +
      "&entity_type=" +
      entityType +
      "&graph_depth=" +
      graphDepth;
    return this.httpSVC.get(url);
  }
  getDiscoveryPathsForEntity(entityDetails: object) {
    const graphDepth = entityDetails["graph_depth"]
      ? entityDetails["graph_depth"]
      : 1;
    const entityType = entityDetails["entity_type"];
    const entityName = entityDetails["entity_name"];
    const url =
      this.grapiUrl +
      "/" +
      entityDetails["domain_name"] +
      "/discovery_path?entity_name=" +
      entityName +
      "&entity_type=" +
      entityType +
      "&graph_depth=" +
      graphDepth;
    return this.httpSVC.get(url);
  }
  public getEmailAndDomainsAndKeywordForDomain(domain, doWhoxy = false) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/domainenum/get/activity/" +
      domain +
      "?do_whoxy=" +
      doWhoxy;
    return this.httpSVC.get(url);
  }
  public getRelatedDomainsForDomain(domain) {
    const url =
      this.benchmarkBaseUrl + "/survey/domainenum/get/relateddomains/" + domain;
    return this.httpSVC.get(url);
  }
  public getRelatedDomainsForEmailDomainsKeyword(domain, doWhoxy = false) {
    const url =
      this.benchmarkBaseUrl + "/survey/domainenum/get/wxyreverse/domains";
    return this.httpSVC.post(url, domain);
  }
  public updateRelatedDomains(domain) {
    const url =
      this.benchmarkBaseUrl + "/survey/domainenum/updated/relateddomains";
    return this.httpSVC.put(url, domain);
  }
  public updateEmailOrgForActivity(domain) {
    const url =
      this.benchmarkBaseUrl + "/survey/domainenum/updae/email/org/keyword/";
    return this.httpSVC.put(url, domain);
  }
  public postAllShadowDetails(domainsDetails, refreshData, isPremium) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/json/save" +
      "?is_premium=" +
      isPremium +
      "&refresh_data=" +
      refreshData;
    return this.httpSVC.post(url, domainsDetails);
  }
  public keywordSearchDataLeaksJobId(value, timeStamp) {
    const url =
      this.dataleakUrl +
      "/open_data/data_leaks/_search?q=" +
      value +
      "&from_time=" +
      timeStamp +
      "&limit=100";
    return this.httpSVC.get(url);
  }
  public keywordSearchDataLeaksStatus(id) {
    const url = this.dataleakUrl + "/open_data/jobs/" + id + "/state";
    return this.httpSVC.get(url);
  }
  public keywordSearchDataLeaksResult(id, page) {
    const url =
      this.dataleakUrl +
      "/open_data/jobs/" +
      id +
      "/results?index=" +
      page +
      "&count=10";
    return this.httpSVC.get(url);
  }
  public getNotifications() {
    const url = this.notificationUrl + "/alert-store/get-alert";
    return this.httpSVC.get(url);
  }
  public getReadNotifications() {
    const url = this.notificationUrl + "/alert-store/get-alert/read";
    return this.httpSVC.get(url);
  }
  public markNotificationRead(ids) {
    const url = this.notificationUrl + "/alert-store/mark-read";
    const data = { ids: ids };
    return this.httpSVC.put(url, data);
  }
  // public getMonitorsByDomain(mainDomain, targetType = 'ip') {
  // 	const url = this.benchmarkBaseUrl + "/survey/monitoring/ip-net/" + mainDomain + "?targetType=" + targetType;
  // 	return this.httpSVC.get(url)
  // }
  // public registerMonitor(mainDomain, targets, tagetType) {
  // 	const url = this.benchmarkBaseUrl + "/survey/monitoring/ip-net/" + mainDomain;
  // 	return this.httpSVC.post(url, { "targets": targets, "target_type": tagetType })
  // }

  // public deregisterMonitor(targetId) {
  // 	const url = this.benchmarkBaseUrl + "/survey/monitoring/ip-net/" + targetId;
  // 	return this.httpSVC.delete(url)
  // }

  public dnsRecordSearch(
    mainDomain,
    searchParam,
    page = 1,
    limit = 0,
    { fromDate = null, toDate = null } = {},
    orderingByName = false
  ) {
    if (!searchParam) {
      var url =
        this.osintConfigUrl +
        "/shadowit/get/dns/" +
        mainDomain +
        "?page=" +
        page +
        "&limit=" +
        limit;
    } else {
      var url =
        this.osintConfigUrl +
        "/shadowit/get/dns/" +
        mainDomain +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        searchParam;
    }
    if (orderingByName) {
      url = url + "&ordering=" + orderingByName;
    }
    if (fromDate) {
      url = url + "&from_date=" + fromDate;
    }
    if (toDate) {
      url = url + "&to_date=" + toDate;
    }
    return this.httpSVC.get(url);
  }
  public getMonitoringStatus(mainDomain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/get/redteaming/is_ca_enabled/" +
      mainDomain;
    return this.httpSVC.get(url);
  }

  updateContinuousMonitoringProfile(monitoring, mainDomain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/update/redteaming/update_ca_profile/" +
      mainDomain;
    return this.httpSVC.put(url, monitoring);
  }

  getContinuousMonitoringProfile(mainDomain) {
    const url =
      this.benchmarkBaseUrl +
      "/survey/shadowit/get/redteaming/is_ca_enabled/" +
      mainDomain;
    return this.httpSVC.get(url);
  }
  public getPocComments(mainDomain, subjectName, subjectType, subjectId=null, visibleTo=null) {
    const url = `${this.osintConfigUrl}/shadowit/comments/manage/${mainDomain}?subject_type=${subjectType}&subject_name=${subjectName}&subject_id=${subjectId}&visible_to=${visibleTo}`;
    return this.httpSVC.get(url);
  }
  public updatePocComments(comments, mainDomain) {
    const url = this.osintConfigUrl + "/shadowit/comments/manage/" + mainDomain;
    return this.httpSVC.put(url, comments);
  }
  public createPocComments(comments, mainDomain) {
    const url = this.osintConfigUrl + "/shadowit/create/comments/" + mainDomain;
    return this.httpSVC.post(url, comments);
  }
  public getPocObsIns(mainDomain, subjectName, subjectId, subjectType) {
    subjectName = encodeURIComponent(subjectName)
    const url = `${this.osintConfigUrl}/shadowit/observationsandinsights/manage/${mainDomain}?subject_type=${subjectType}&subject_id=${subjectId}&subject_name=${subjectName}`;
    return this.httpSVC.get(url);
  }
  public updatePocObsIns(observationsandInsights, mainDomain) {
    const url = this.osintConfigUrl + "/shadowit/observationsandinsights/manage/" + mainDomain;
    return this.httpSVC.put(url, observationsandInsights);
  }
  public createPocObsIns(observationsandInsights, mainDomain) {
    const url = this.osintConfigUrl + "/shadowit/create/observationsandinsights/" + mainDomain;
    return this.httpSVC.post(url, observationsandInsights);
  }
  public getVulObsIns(mainDomain, subjectName, subjectId, subjectType) {
    const url = `${this.osintConfigUrl}/shadowit/get/observationsandinsights/vul/${mainDomain}?subject_type=${subjectType}&subject_id=${subjectId}&subject_name=${subjectName}`;
    return this.httpSVC.get(url);
  }
  public getPocTouchpoint(mainDomain, subjectName, subjectType) {
    const url = `${this.osintConfigUrl}/shadowit/touchpoints/manage/${mainDomain}?subject_type=${subjectType}&subject_name=${subjectName}`;
    return this.httpSVC.get(url);
  }
  public updatePocTouchpoint(touchpoints, mainDomain) {
    const url =
      this.osintConfigUrl + "/shadowit/touchpoints/manage/" + mainDomain;
    return this.httpSVC.put(url, touchpoints);
  }
  public createPocTouchpoint(touchpoints, mainDomain) {
    const url =
      this.osintConfigUrl + "/shadowit/create/touchpoints/" + mainDomain;
    return this.httpSVC.post(url, touchpoints);
  }
  public getAllComments(mainDomain, subjectType) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/all/comments/" +
      mainDomain +
      "/" +
      subjectType +
      "?archive=" +
      false;
    return this.httpSVC.get(url);
  }
  public getAllObsAndIns(mainDomain, subjectType) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/all/observationsandinsights/" +
      mainDomain +
      "/" +
      subjectType +
      "?archive=" +
      false;
    return this.httpSVC.get(url);
  }
  public getAllTouchpoints(mainDomain, subjectType) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/all/touchpoints/" +
      mainDomain +
      "/" +
      subjectType;
    return this.httpSVC.get(url);
  }
  public getNetblockdomain(mainDomain, netblockKey, netblockValue, ip_address) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/netblock/domain/details/" +
      mainDomain +
      "?key=" +
      encodeURIComponent(netblockKey) +
      "&net_block=" +
      encodeURIComponent(ip_address) +
      "&value=" +
      encodeURIComponent(netblockValue);
    return this.httpSVC.get(url);
  }
  //--------------
  public getNetblockIspForDomains(mainDomain, {scope = null} = {}) {
    var url =  this.osintConfigUrl + "/shadowit/get/netblock/isp/details/" + mainDomain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getNetblockIps(mainDomain, netblockKey, netblockValue, ip_address,scope=null) {
    let url =
      this.osintConfigUrl +
      "/shadowit/get/netblock/ips/details/" +
      mainDomain +
      "?key=" +
      encodeURIComponent(netblockKey) +
      "&net_block=" +
      encodeURIComponent(ip_address) +
      "&value=" +
      encodeURIComponent(netblockValue);
      if(scope){
        url = url+"&scope="+scope;
      }
    return this.httpSVC.get(url);
  }
  //-------------------------------------
  public getRelatedDomainsCount(
    mainDomain,
    { toDate = null, fromDate = null, scope = null } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/domains/details/count/" +
      mainDomain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (scope) url = url + "&scope=" + scope;

    return this.httpSVC.get(url);
  }
  //---------------
  public getSubdomainCount(
    mainDomain,
    { toDate = null, fromDate = null, scope = null } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/subdomains/details/count/" +
      mainDomain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (scope) url = url + "&scope=" + scope;
    
    return this.httpSVC.get(url);
  }

  public LeakedCredentialsWithPagination(
    domain,
    domain_rd,
    {
      limit = null,
      page = 1,
      fromDate = null,
      toDate = null,
	    searchKey = null,
	  
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/leakedcredentials/paginate/details/" +
      domain +
      "/" +
      domain_rd +
      "?page=" +
      page +
      "&limit=" +
      limit;
    if (searchKey) {
      url = url + "&search=" + searchKey;
    }
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    return this.httpSVC.get(url);
  }
  public getApiDiscovery(
    domain,
    {
      page = 1,
      limit = 10,
      searchKey = null,
      method = null,
      toDate = null,
      fromDate = null,
      page_size = 10,
      sortKey = null,
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/api-discovery/details/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&archive=" +
      false +
      "&page_size=" +
      page_size;
    if (searchKey) url = url + "&search=" + searchKey;
    if (method) url = url + "&method=" + method;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (sortKey) url = url + "&ordering=" + sortKey;
    return this.httpSVC.get(url);
  }
  public updateApiDiscovery(apiDetail, domain_name) {
    var url =
      this.osintConfigUrl +
      "/shadowit/update/api-discovery/details/" +
      domain_name;
    return this.httpSVC.put(url, apiDetail);
  }
  public getApiDiscoveryCount(domain, { toDate = null, fromDate = null } = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/api-discovery/count/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    return this.httpSVC.get(url);
  }
  public getIndivualSubdomainDetails(
    main_domain,
    { rd_domain = null, page = 1, limit = 10, searchKey = null, scope = null } = {}
  ) {
    let url =
      this.osintConfigUrl +
      "/shadowit/get/each/domain/subdomain/details/" +
      main_domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&domain=" +
      rd_domain;
    if (searchKey) url = url + "&search=" + searchKey;
    if (scope) url = url + "&scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getWhoisDetailsForPagination(domain, domain_list) {
    const url =
      this.osintConfigUrl +
      "/shadowit/get/relateddomain/paginate/whois/" +
      domain +
      "?domain_list=" +
      domain_list;
    return this.httpSVC.get(url);
  }

  getLeakedCredCount(domain, { toDate = null, fromDate = null } = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/leakedcredentials/count/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    return this.httpSVC.get(url);
  }
//-------------
  getOpenPortCount(domain, { toDate = null, fromDate = null, scope = null } = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/open/ports/count/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (scope) url = url + "&scope=" + scope;
    return this.httpSVC.get(url);
  }

  getApplicationCount(domain, { toDate = null, fromDate = null, scope = null } = {},app_type=null) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/applications/count/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (scope) url = url + "&scope=" + scope;
    if (app_type) url = url + "&app_type=" + app_type
    return this.httpSVC.get(url);
  }
  getPhishingCount(domain) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/phishing/count/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    return this.httpSVC.get(url);
  }

  public getDomainTagsList(domain) {
    var url = this.osintConfigUrl + "/shadowit/get/domain/tags/" + domain;
    return this.httpSVC.get(url);
  }
  public getSubDomainTagsList(domain) {
    var url = this.osintConfigUrl + "/shadowit/get/subdomain/tags/" + domain;
    return this.httpSVC.get(url);
  }

  public getPasteData(
    domain,
    { page = 1, limit = 100, sortKey = null, searchKey = null } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/pastedata/details/" +
      domain +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (searchKey) url = url + "&search=" + searchKey;
    return this.httpSVC.get(url);
  }
  public getPasteDataCount(domain, { fromDate = null, toDate = null } = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/pastedata/count/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;

    if (fromDate || toDate) {
      url = url + "&filter_date_by=" + "created_at";
    }
    if (fromDate) {
      url = url + "&from_date=" + fromDate;
    }
    if (toDate) {
      url = url + "&to_date=" + toDate;
    }
    return this.httpSVC.get(url);
  }

  public getObsInsCountForEachDomain(
    domain,
    { subject_ref_list = null, subject_type = null} = {}
  ) {
    subject_ref_list=subject_ref_list.map(url=>encodeURIComponent(url))
    var url =
      this.osintConfigUrl +
      "/shadowit/get/domain/obsIns/count/" +
      domain +
      "?subject_ref_list=" +
      subject_ref_list +
      "&subject_type=" +
      subject_type ;
    return this.httpSVC.get(url);
  }

  public getSubDomainCountForEachDomain(
    domain,
    { domain_list = null, archive = false, scope = null } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/domain/subdomains/count/" +
      domain +
      "?domain_list=" +
      domain_list +
      "&archive=" +
      archive;
    if (scope) url = url + "&scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getMainDomainDetails(domain) {
    var url = this.osintConfigUrl + "/shadowit/get/main_domain/" + domain;
    return this.httpSVC.get(url);
  }
  public getMainDomainContinuesMonitoringStatus(domain) {
    var url = this.osintConfigUrl + "/shadowit/get/redteaming/is_ca_enabled/" + domain;
    return this.httpSVC.get(url);
  }
  public getReportCSVs(domain, { type = null, from_date = null }) {
    var url =
      this.osintConfigUrl + "/report-generator/csv/" + domain + "?type=" + type;
    if (from_date) url = url + "&from_date=" + from_date;
    return this.httpSVC.get(url);
  }
  getTakeoverRisk(domain, { fromDate = null, toDate = null } = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/takeoverrisk/details/" +
      domain +
      "?false_positive=" +
      false +
      "&archive=" +
      false;
    if (fromDate) {
      url = url + "&from_date=" + fromDate;
    }
    if (toDate) {
      url = url + "&to_date=" + toDate;
    }
    if (fromDate || toDate) {
      url = url + "&filter_date_by=" + "created_at";
    }

    return this.httpSVC.get(url);
  }
  public getFilesForBuckets(domain, bucket, { page = 1, limit = 10 } = {}) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/bucket/files/details/" +
      domain +
      "/" +
      bucket +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&false_positive=" +
      false +
      "&archive=" +
      false;
    return this.httpSVC.get(url);
  }
  public getNetblocksPaginated(
    domain,
    {
      page = 1,
      page_size = 10,
      searchKey = null,
      netblockType = null,
      toDate = null,
      fromDate = null,
      sortKey = null,
      summary = null,
      asn_name = null,
      scope = null,
      tags = null
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/netblock/details/" +
      domain +
      "?page=" +
      page +
      "&page_size=" +
      page_size +
      "&false_positive=" +
      false +
      "&archive=" +
      false +
      "&is_premium=" +
      false;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (searchKey) url = url + "&search=" + encodeURIComponent(searchKey);
    if (netblockType) url = url + "&netblock_type=" + netblockType;
    if (tags!=null) url = url + "&tags=" + tags;
    if (asn_name) url = url + "&asn_name=" + asn_name;
    if (summary) url = url + "&summary=" + summary;
    if (scope) url = url + "&scope=" + scope;

    return this.httpSVC.get(url);
  }

  public getHostForService(domain, ip_list) {
    const url =
      this.osintConfigUrl + "/shadowit/ips/hosts/" + domain + "?ips=" + ip_list;
    return this.httpSVC.get(url);
  }
  public getServicesForOpenPorts(domain,  {scope = null}={}) {
    var url = this.osintConfigUrl + "/shadowit/get/open/ports/name/" + domain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getTagsForOpenPorts(domain) {
    const url = this.osintConfigUrl + "/shadowit/get/open/ports/tags/" + domain;
    return this.httpSVC.get(url);
  }
  public upadtePasteData(domain, dataToSend) {
    const url = this.osintConfigUrl + "/shadowit/update/paste/data/" + domain;
    return this.httpSVC.put(url, dataToSend);
  }
  public getIpsTagsList(domain, {scope = null, ip_validated = null}={}) {
    var url = this.osintConfigUrl + "/shadowit/get/ips/tags/" + domain;
    let separator = '?'
    if (scope) {
        url = url + "?scope=" + scope;
        separator = '&'
    }
    if (ip_validated !== null) {
        url += `${separator}ip_validated=${ip_validated}`
    }
    return this.httpSVC.get(url);
  }
  public getIpsIspList(domain,{scope = null, ip_validated = null}={}) {
    var url = this.osintConfigUrl + "/shadowit/get/ips/isp/details/" + domain;
    let separator = '?'
    if (scope) {
        url = url + "?scope=" + scope;
        separator = '&'
    }
    if (ip_validated !== null) {
        url += `${separator}ip_validated=${ip_validated}`
    }
    return this.httpSVC.get(url);
  }
  public unarchiveIPs(dataToSend, domain) {
    var url = this.osintConfigUrl + "/shadowit/ips/" + domain;
    return this.httpSVC.put(url, dataToSend);
  }

  public updateNetblockType(domain, dataToSend) {
    const url = this.osintConfigUrl + "/shadowit/netblock/" + domain;
    return this.httpSVC.put(url, dataToSend);
  }
  public updateOpenPorts(domain, dataToSend) {
    const url = this.osintConfigUrl + "/shadowit/open-port/" + domain;
    return this.httpSVC.put(url, dataToSend);
  }

  //------------------job service-------------------------
  // public getAllSchedulingReports(page) {
  //   const url =
  //     this.jobAutomationConfig.url +
  //     "/jobs/" +
  //     "?job_type=report_generation&page=" +
  //     page +
  //     "&archive=" +
  //     false;
  //   return this.httpSVC.get(url);
  // }
  // public createNewReoprtSchedule(scheduler) {
  //   const url = this.jobAutomationConfig.url + "/jobs/";
  //   return this.httpSVC.post(url, scheduler);
  // }
  // public updateScheduleReport(report_id, jsonToUpdate) {
  //   const url = this.jobAutomationConfig.url + "/jobs/" + report_id + "/";
  //   return this.httpSVC.patch(url, jsonToUpdate);
  // }
  // updateRelatedDomainJobService(domainData) {
  //   const url = this.jobAutomationConfig.url + "/jobs/bulk-update/";
  //   return this.httpSVC.post(url, domainData);
  // }
  // updateAllAseletsForDomain(jobId) {
  //   const url =
  //     this.jobAutomationConfig.url + "/jobs/status?job_run_id=" + jobId;
  //   return this.httpSVC.get(url);
  // }

  //------------------Report Service----------------------
  // public getTemplateForSchedulingReports() {
  //   const url = this.reportServiceConfig.url + "/reports/template/";
  //   return this.httpSVC.get(url);
  // }
  // public getDownloadReport(page) {
  //   const url =
  //     this.reportServiceConfig.url +
  //     "/reports/" +
  //     "?page=" +
  //     page +
  //     "&archive=" +
  //     false;
  //   return this.httpSVC.get(url);
  // }
  // public updateReports(report_id, jsonToUpdate) {
  //   const url = this.reportServiceConfig.url + "/reports/" + report_id + "/";
  //   return this.httpSVC.patch(url, jsonToUpdate);
  // }
  // public generateLeakedCredCsv(jobId) {
  //   const url =
  //     this.reportServiceConfig.url + "/reports/?archive=False&job_id=" + jobId;
  //   return this.httpSVC.get(url);
  // }
  public topRiskMap(dataToSend, entity_type) {
    const url = this.osintConfigUrl + "/shadowit/top/risk/map/" + entity_type;
    return this.httpSVC.post(url, dataToSend);
  }

  public addTopRisk(dataToSend, entityType) {
    const url = this.osintConfigUrl + "/shadowit/top/risk/" + entityType + "/";
    return this.httpSVC.post(url, dataToSend);
  }

  public validateTopRiskSource(domainName, entityType, searchParam) {
    const url =
      this.osintConfigUrl +
      "/shadowit/top/risk/source/validate/" +
      domainName +
      "/" +
      entityType +
      "/" +
      "?search=" +
      searchParam;
    return this.httpSVC.get(url);
  }

  public getRepoCodeLeaks(
    domain,
    repo_url,
    code_leak_length,
    { page = 1, limit = null, searchKey = null } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/repo/code/leaks/" +
      domain +
      "?repo_url=" +
      repo_url +
      "&page_size=" +
      code_leak_length +
      "&false_positive=" +
      false +
      "&archive=" +
      false;

    console.log("url=", url);
    return this.httpSVC.get(url);
  }

  public getTechnologiesName(domainName) {
    const url =
      this.osintConfigUrl +
      "/shadowit/technologies/name/" +
      domainName['domain_name']
    return this.httpSVC.get(url);
  }

  public addVulnerebility(type,dataToSend, domain){
    const url = this.osintConfigUrl + "/shadowit/deepc/addVulnerability/"+type+"/"+domain;
    return this.httpSVC.post(url, dataToSend);
  }
  public updateState(type,dataToSend, domain){
    const url = this.osintConfigUrl + "/shadowit/deepc/updateVulnerability/"+type+"/"+domain;
    return this.httpSVC.post(url, dataToSend);
  }
  public searchCveId(type,searchKey,domain){
    //const url = this.osintConfigUrl + "/shadowit/deepc/cve/"+type+"/"+domain+"?cve_id="+searchKey;
    const url = this.osintConfigUrl +
      "/shadowit/deepc/get/"+type+"/vulnerability/" +
      domain +
      "?false_positive=" +
      false +
      "&is_premium=" +
      false+"&search=" + searchKey;
    return this.httpSVC.get(url);
  }
  uploadLKForRDFromDB(dataToSend){
    const url = this.osintConfigUrl + "/shadowit/upload/leakcreds"
    return this.httpSVC.post(url, dataToSend)
  }
  //-------
  public domainStatusCount(domain,{ scope = null }={}) {
    var url = this.osintConfigUrl + "/shadowit/get/domain/status/count/"+domain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url);
  }
  public domainTagsCount(domain,{ scope = null }={}) {
    var url = this.osintConfigUrl + "/shadowit/get/domain/tags/count/"+domain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url)
  }
  public subdomainStatusCount(domain,{ scope = null }={}) {
    var url = this.osintConfigUrl + "/shadowit/get/subdomain/status/count/"+domain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url);
  }
  public subdomainTagsCount(domain,{ scope = null }={}) {
    var url = this.osintConfigUrl + "/shadowit/get/subdomain/tags/count/"+domain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url)
  }
  public netblockStatusCount(domain,{ scope = null }={}) {
    var url = this.osintConfigUrl + "/shadowit/get/netblock/status/count/"+domain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url)
  }
  //---------------
  public getServiceCountDetails(domain,{scope = null}={}){
    var url =
      this.osintConfigUrl +
      "/shadowit/get/service/count/details/" +
      domain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getApplicationByTechnilogy(domain, { scope = null } = {}) {
    var url = this.osintConfigUrl + "/shadowit/get/application/technologies/count/" + domain
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url);
  }
  public getApplicationCveCount(domain, { scope = null } = {}) {
    var url = this.osintConfigUrl + "/shadowit/get/application/cve/count/" + domain;
    if (scope) url = url + "?scope=" + scope;
    return this.httpSVC.get(url);
  }
  public  leakcredPasswordCount(domain){
  var url = this.osintConfigUrl+"/shadowit/get/leakcred/passwords/count/"+ domain
  return this.httpSVC.get(url);
  }
  public  getApiDiscoveryMethodsCount(domain){
    var url = this.osintConfigUrl+"/shadowit/get/api/methods/count/"+ domain
    return this.httpSVC.get(url);
  }
  public getServiceChartData(domain, { 
    fromDate = null, 
    toDate = null, 
    scope = null ,
    searchKey = null,
    sortKey = null,
    state = null,
    severities =null,
    ip_validated =null,
    validated_flags = null
  } = {}){
    var url = this.osintConfigUrl + `/shadowit/get/networkcve/stats/${domain}`;
    let paramArray:any = [];
    if (fromDate || toDate) paramArray.push("filter_date_by=" + "created_at");
    if (fromDate)paramArray.push("from_date=" + fromDate);
    if (toDate)paramArray.push("to_date=" + toDate);
    if (scope) paramArray.push("scope=" + scope);
    if (sortKey) paramArray.push("ordering=" + sortKey);
    if (searchKey) paramArray.push("search=" + searchKey);
    if (state) paramArray.push("cve_states=" + state);
    if (severities) paramArray.push("severities=" + severities);
    if (ip_validated!=null) paramArray.push("ip_validated=" + ip_validated);
    if (validated_flags) paramArray.push("validated_flags=" + validated_flags);
    if(paramArray.length>0){
      let paramString = paramArray.join('&')
      url = url+'?'+paramString;
    }
    return this.httpSVC.get(url);
  }
  public getWebChartData(domain, { 
    fromDate = null, 
    toDate = null, 
    scope = null ,
    searchKey = null,
    sortKey = null,
    state = null,
    severities =null,
    validated_flags = null
  } = {}){
    var url = this.osintConfigUrl + "/shadowit/get/webappcve/stats/" + domain;
    let paramArray:any = [];
    if (fromDate || toDate) paramArray.push("filter_date_by=" + "created_at");
    if (fromDate)paramArray.push("from_date=" + fromDate);
    if (toDate)paramArray.push("to_date=" + toDate);
    if (scope) paramArray.push("scope=" + scope);
    if (sortKey) paramArray.push("ordering=" + sortKey);
    if (searchKey) paramArray.push("search=" + searchKey);
    if (state) paramArray.push("cve_states=" + state);
    if (severities) paramArray.push("severities=" + severities);
    if (validated_flags) paramArray.push("validated_flags=" + validated_flags);
    if(paramArray.length>0){
      let paramString = paramArray.join('&')
      url = url+'?'+paramString;
    }
    return this.httpSVC.get(url);
  }
  public triggerReset(domain, param, customer_id = null) {
    var url = this.osintConfigUrl + "/shadowit/trigger/vulnerability_management_action/" + domain + "/TriggerRetest";
    if (customer_id) {
      url = url + "?customer_id=" + customer_id;
    }
    return this.httpSVC.post(url, param);
  }
  // Timeline functions
  public getDeltaDetailsByentity(
    domain,
    entity,
    {
      page = 1,
      page_size = 10,
      searchKey = null,
      tags = null,
      take_over_risk = false,
      toDate = null,
      fromDate = null,
      sortKey = null,
      archive = false,
      scope = null,
      expire = null,
      action = null,
      week = null,
      scope_config = null
    } = {}
  ) {
    // var url = this.benchmarkBaseUrl + '/survey/shadowit/get/domain/subdomain/' + domain + "?page=" + page + "&limit=" + limit + "&false_positive=" + false + "&archive=" + false;
    var url =
      this.dwUrl +
      "/entity/details/" +
      domain +
      "/"+entity+"?page=" +
      page +
      "&limit=" +
      page_size;
    if (searchKey) url = url + "&search=" + searchKey;
    if (tags) url = url + "&tags=" + tags;
    if (take_over_risk) url = url + "&take_over_risk=" + take_over_risk;
    if (toDate) url = url + "&to_date=" + toDate.split("T")[0];
    if (fromDate) url = url + "&from_date=" + fromDate.split("T")[0];
    if (sortKey) url = url + "&ordering=" + sortKey;
    if (scope) url = url + "&scope=" + scope;
    if (expire!==null) url = url + "&expire=" + expire;
    if (action!==null) url = url + "&action=" + action;
    if (week!==null) url = url + "&week=" + week;
    if (scope_config!==null) url = url + "&scope_config=" + scope_config;
    return this.httpSVC.get(url);
  }

  public getIpsDetailsDelta(domain,
    {
      page = 1,
      limit = 10,
      scope = null,
      scope_config = null,
      ips_list = null,
      fp_ar = null
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/ips/details/" +
      domain +
      // "?page=" +
      // page +
      "?limit=" +
      limit;
    if (scope) url = url + "&scope=" + scope;
    if (scope_config!==null) url = url + "&scope_config=" + scope_config;
    if (ips_list) url = url + "&ips_list=" + ips_list;
    if (fp_ar) url = url + "&fp_ar=" + fp_ar;
    return this.httpSVC.get(url);
  }
  public getDomainDetailsDelta(domain,
    {
      page = 1,
      limit = 10,
      scope = null,
      scope_config = null,
      domain_list = null,
      fp_ar = null
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/domain/details/" +
      domain +
      // "?page=" +
      // page +
      "?limit=" +
      limit;
    if (scope) url = url + "&scope=" + scope;
    if (scope_config!==null) url = url + "&scope_config=" + scope_config;
    if (domain_list) url = url + "&domain_list=" + domain_list;
    if (fp_ar) url = url + "&fp_ar=" + fp_ar;
    return this.httpSVC.get(url);
  }
  public getSubdomainDetailsDelta(domain,
    {
      page = 1,
      limit = null,
      page_size = 100,
      scope = null,
      scope_config = null,
      host_list = null,
      fp_ar = null
    } = {}
  ) {
    var url =
      this.osintConfigUrl +
      "/shadowit/get/subdomain/" +
      domain +
      // "?page=" +
      // page +
      "?page_size=" +
      page_size;
    if (scope) url = url + "&scope=" + scope;
    if (limit) url = url + "&limit=" + limit;
    if (scope_config!==null) url = url + "&scope_config=" + scope_config;
    if (host_list) url = url + "&host_list=" + host_list;
    if (fp_ar) url = url + "&fp_ar=" + fp_ar;
    return this.httpSVC.get(url);
  }
  getSearchResults(domain, searchKey, assetTypes, page, dateRange) {
    console.log(searchKey, assetTypes, page)
    var url = this.osintConfigUrl+"/shadowit/search/"+domain+'?q='+searchKey+'&page='+page
    if (assetTypes.length) {
      url = url+"&assetType="+assetTypes
    }
    if (dateRange) {
      let fromDate = new Date(dateRange[0]);
      let toDate = new Date(dateRange[1]);
      let fromDateFormated = fromDate.toLocaleDateString("en-UK")
      let toDateFormated = toDate.toLocaleDateString("en-UK")
      url = url+"&fromDate="+fromDateFormated+"&toDate="+toDateFormated
    }
    return this.httpSVC.get(url);
  }
  getLocationsFromIp(domain:string) {
    const url = this.osintConfigUrl + "/shadowit/get/ips/location/count/details/" + domain;
    return this.httpSVC.get(url);
  }
  public searchCveIdV2(type,searchKey,domain){
    const url = this.osintConfigUrl +
      "/shadowit/get/cve/details/" + domain + "?cve_id=" + searchKey;
    return this.httpSVC.get(url);
  }
  public addWebapplication(dataToSend, domain_name="") {
    const url =
      this.osintConfigUrl +
      "/shadowit/deepc/update/"+domain_name+"/webapplication";
    return this.httpSVC.put(url, dataToSend);
  }
}
