import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from "@angular/router";
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module'

import { AutomatedReportsRoutingModule } from './automated-reports-routing.module';
import { ScheduleReportComponent } from './schedule-report/schedule-report.component';
import { DownloadReportComponent } from './download-report/download-report.component';
const routes: Routes = [
	{
		path: '',
		data: {
			title: 'Reports'
	},
	children: [
		{
				path: 'schedule',
				component: ScheduleReportComponent,
				data: {
					title: 'Generate'
				}
	  },
	  {
		path: 'download',
		component: DownloadReportComponent,
		data: {
			title: 'Download'
		}
		},
    ]
  }
]

@NgModule({
  declarations: [
	  ScheduleReportComponent,
	  DownloadReportComponent
	],
	imports: [
		CommonModule,
		SharedModule,
	FormsModule,
	AutomatedReportsRoutingModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class AutomatedReportsModule { }
