import { Component, OnInit, Input } from '@angular/core';
import { PortfolioSurveyService } from './../../_services';

@Component({
  selector: 'app-monitor-view',
  templateUrl: './monitor-view.component.html',
  styleUrls: ['./monitor-view.component.scss']
})
export class MonitorViewComponent implements OnInit {

  @Input() mainDomain: any;

  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
  ) { }
  public monitoringEnabled = false;
  public monitorOption;
  public monitorOptionOld = [
    {
      'category': 'Port Monitoring',
      'options': [
        {
          'monitoring_id': 'top_ports',
          'monitoring_entity': 'Top Ports',
          'monitoring_time': 'daily',
          'monitoring_state': true
        },
        {
          'monitoring_id':'all_ports',
          'monitoring_entity': 'All Ports',
          'monitoring_time': 'weekly',
          'monitoring_state': true
        },
        {
          'monitoring_id':'shodan_exposure',
          'monitoring_entity': 'Shodan Exposure',
          'monitoring_time': 'daily',
          'monitoring_state': true
        }
      ]
    },
    {
      'category': 'Certificate Monitoring',
      'options': [
        {
          'monitoring_id':'certificate_transparency_logs',
          'monitoring_entity': 'Certificate Transparency Logs',
          'monitoring_time': 'daily',
          'monitoring_state': true
        }]
    },
    {
      'category': 'Data Leaks Monitoring',
      'options': [
        {
          'monitoring_id':'exposed_database',
          'monitoring_entity': 'Exposed Database',
          'monitoring_time': 'daily',
          'monitoring_state': true
        },
        {
          'monitoring_id':'3rd_party_exposed_data',
          'monitoring_entity': '3rd Party Exposed Data',
          'monitoring_time': 'daily',
          'monitoring_state': true
        },
        {
          'monitoring_id':'mobile_apps_code',
          'monitoring_entity': 'Mobile Apps Code',
          'monitoring_time': 'weekly',
          'monitoring_state': true
        },
        {
          'monitoring_id':'web_application',
          'monitoring_entity': 'Web Application',
          'monitoring_time': 'weekly',
          'monitoring_state': true
        }]
    },
    {
      'category': 'Code Leaks Monitoring',
      'options': [
        {
          'monitoring_id':'github_gitlab_bitbucket',
          'monitoring_entity': 'Github, Gitlab, Bitbucket',
          'monitoring_time': 'daily',
          'monitoring_state': true
        }]
    },
    {
      'category': 'Sub-Domains Monitoring',
      'options': [
        {
          'monitoring_id':'passive_sub_domains_enumeration',
          'monitoring_entity': 'Passive Sub-Domains Enumeration',
          'monitoring_time': 'daily',
          'monitoring_state': true
        },
        {
          'monitoring_id':'sub_domain_brute_forcing',
          'monitoring_entity': 'Sub-Domain Brute-Forcing',
          'monitoring_time': 'Weekly',
          'monitoring_state': true
        }]
    }]


  ngOnInit() {
    this.getMonitoringStatus();
    this.getMonitoring();
  }
  getMonitoringStatus() {
    this.portfolioSurveyService.getMonitoringStatus(this.mainDomain).subscribe((data:any) => {
      if (data && data.status == 'pass' && data.data) {
        if (data.data.enabled  && new Date(data.data.expire_at)>new Date())
          this.monitoringEnabled = data.data.enabled
        else {
          this.monitorOption = JSON.parse(JSON.stringify(this.monitorOptionOld))
        }
      } else if (data && data.status == "fail") {
          this.monitorOption = JSON.parse(JSON.stringify(this.monitorOptionOld))
      }
    }, error => {
      alert("Error while getting infomation of continous!")
    })
  }
  updateMonitoring() {
    this.portfolioSurveyService.updateContinuousMonitoringProfile(this.monitorOption, this.mainDomain).subscribe((data:any) => {
      if (data && data.status == 'pass') {
        alert("Updated successfully!")
      }
    }, error => {
      console.log(error)
      alert("Error while upadteing continous!")
    })
  }
  getMonitoring() {
    this.portfolioSurveyService.getContinuousMonitoringProfile(this.mainDomain).subscribe((data:any) => {
      if (data && data.status == 'pass' && data.data && Array.isArray(data.data.profile)) {
          this.monitorOption = data.data.profile
          if (!this.monitoringEnabled) {
            this.monitorOption = JSON.parse(JSON.stringify(this.monitorOptionOld))
          }
      } else {
        this.monitorOption = JSON.parse(JSON.stringify(this.monitorOptionOld))

      }
    }, error => {
      console.log(error)
      alert("Error while getting infomation of continous!")
    })
  }
}