import { Component, OnInit, TemplateRef, Output, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PortfolioSurveyService } from '../../_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../_services/common.service';
// import { s } from '@angular/core/src/render3';
import { ValidUrlPipe } from 'app/pipes/validate-url.pipe';
import { AssetTypePipe } from 'app/pipes/asset-type.pipe';

@Component({
	selector: 'app-red-teaming',
	templateUrl: './red-teaming.component.html',
	styleUrls: ['./red-teaming.component.scss']
})
export class RedTeamingComponent implements OnInit {
	public scanName = '';
	public showScanList: boolean = true;
	public selectedScan;
	public domainName;
	public showThis = 'domain'
	public newDomain = {}
	public domainText
	public addListOfDoamin: boolean = false
	public allscan_Entities = {}
	public showRemove = false
	public scanList = [];
	public seperateScanList = { "In Progress": [], "Completed": [], "New": [] }
	public newEntities = [];
	public scanStarted = false;
	public keywords = ['page', 'domains', 'code'];
	public codeRepos = ['https://github.com/Clickpost/clickpost-order-creation', 'https://github.com/Clickpost/slate', 'https://github.com/Clickpost/pyck-widget'];
	public validUrlPipe = new ValidUrlPipe();
	public assetTypePipe = new AssetTypePipe();
	public showDataSpinner = false;
	public isUserTrial = false
	public exploreDomain: boolean;
	public selectAdvanceOption = false;
	public techniqueCovered = false;
	public key_i = null;
	public runScan = false
	public accountTypeCheck = null;
	public defaultOptions = { "exploitation": "exploitation_unsafe", "intensity": "slow_stealthy", "ports": "all_ports" };
	public scanProfiles = [
		{
			"intent": 'Comprehensive Red Teaming',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "Comprehensive Red Teaming simulates real life hackers and attackers on the external attack surface and IT infrastructure. Steps include Attack surface enumeration via passive and active reconnaissance, active vulnerability assessment of the enumerated IT Assets, launching of exploits and multi-stage attacks,  spear phishing campaigns and Comprehensive Risk Assessment.",
			"advance_option": { "exploitation": "exploitation_unsafe", "intensity": "slow_stealthy", "ports": "all_ports" },
			"require_field": ["domain"]
		},
		{
			"intent": 'Comprehensive Passive Reconnaissance',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "Comprehensive Passive Enumeration uses techniques used by real life hackers and attackers to enumerate the external attack surface and IT infrastructure of a target organisation via reconnaissance. This scan does not send any active scanning packets to the target organisation's servers and is purely passive in nature. Coverage can be improved by providing domain names, sub-domains, ip addresses, urls, email addresses and keywords or brand names.",
			"advance_option": { "exploitation": "NA", "intensity": "NA", "ports": "NA" },
			"require_field": ["domain"]
		},
		{
			"intent": 'Ransomware Susceptibility Assessment',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "The objective of this scan profile is to identify risks which may be targeted by Ransomware. Steps include Attack surface enumeration via passive and active reconnaissance, active vulnerability assessment of the enumerated IT Assets and Comprehensive Risk Assessment of the Attack Surface.",
			"advance_option": { "exploitation": "exploitation_unsafe", "intensity": "slow_stealthy", "ports": "all_ports" },
			"require_field": ["domain"],

		},
		{
			"intent": 'Web Applications Vulnerability Assessment',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "Web Applications Vulnerability Assessment is run on all web applications and urls which are provided as part of the scope of the scan. This covers OWASP top 10 attacks as well as advanced business logic vulnerabilities. If credentials are provided, privilege escalation vulnerabilities are also tested.",
			"advance_option": { "exploitation": "exploitation_safe", "intensity": "slow_stealthy", "ports": "web_ports" },
			"require_field": ["url"],

		},
		{
			"intent": 'Network Vulnerability Assessment',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "Network Vulnerability Assessment is run on all Domains, Sub-domains, IP addresses and ports which are provided as part of the scope of the scan and are externally reachable from our scanners. This uses the NIST Standard to test for known vulnerabilities and provides a list of vulnerabilities.",
			"advance_option": { "exploitation": "exploitation_safe", "intensity": "fast_noisy", "ports": "top_1000_ports" },
			"require_field": ["domain", "ip"],

		},
		{
			"intent": 'Android Mobile Application Assessment',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": 'Android Mobile Application Assessment is run on Android mobile applications which are provided as part of the scope of the scan and are available for download from the Android play store. This scan downloads the mobile apps from the play-store, extracts the binary, decompiles the same and runs a Static Application Security test on the application. ',
			"advance_option": { "exploitation": "exploitation_safe", "intensity": "NA", "ports": "NA" },
			"require_field": ["url"],
		},
		{
			"intent": 'People -  Spear Phishing Attacks',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "Launch phishing campaigns to check the awareness levels of employees or key personnel in order to test their susceptibility to spear phishing attacks. ",
			"advance_option": { "exploitation": "exploitation_safe", "intensity": "slow_stealthy", "ports": "NA" },
			"require_field": ["email"],

		},
		{
			"intent": 'API Security Assessment(Coming Soon!)',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "API Security Assessment",
			"advance_option": { "exploitation": "exploitation_safe", "intensity": "slow_stealthy", "ports": "web_ports" },
			"require_field": [],
			"disabled": true

		},


		{
			"intent": 'IOS Mobile Application Assessment(Coming Soon!)',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "IOS Mobile Application Assessment is run on IOS mobile applications which are provided as part of the scope of the scan and are available for download from the IOS App Store. This scan runs a Dynamic Application Security test by running the mobile app in a real device.",
			"advance_option": { "exploitation": "exploitation_safe", "intensity": "NA", "ports": "NA" },
			"require_field": [],
			"disabled": true
		},

		{
			"intent": 'Credential Stuffing Assessment(Coming Soon!)',
			"modules": ['mod_bruteforce_s3_buckets'],
			"scan_type": 'red_teaming',
			"tooltip": "Credential Stuffing Assessment",
			"advance_option": { "exploitation": "exploitation_safe", "intensity": "fast_noisy", "ports": "top_1000_ports" },
			"require_field": [],
			"disabled": true
		},



	]
	public selectedScanType = null;
	public advanceOptions = [
		{
			'opt_id': 'exploitation',
			'name': 'Exploitation Level',
			'options': [{
				'id': 'exploitation_safe',
				'opt_name': 'Safe'
			},
			{
				'id': 'exploitation_unsafe',
				'opt_name': 'Unsafe'
			}]
		},
		{

			'opt_id': 'intensity',
			'name': 'Intensity Level',
			'options': [{
				'id': 'fast_noisy',
				'opt_name': 'Fast and Noisy'
			},
			{
				'id': 'slow_stealthy',
				'opt_name': 'Slow and Stealthy'
			}]
		},
		{
			'opt_id': 'ports',
			'name': 'Ports Covered',
			'options': [
				{
					'id': 'all_ports',
					'opt_name': 'All Ports'
				},
				{
					'id': 'top_100_ports',
					'opt_name': 'Top 100 Ports'
				},
				{
					'id': 'top_1000_ports',
					'opt_name': 'Top 1000 Ports'
				},
				{
					'id': 'web_ports',
					'opt_name': 'Web Ports (80, 443, 8080, etc)'
				}],
		},
		// {
		// 'opt_id':'cve',
		// 	'name': 'CVEs',
		// 	'options': [{
		// 		'opt_name': 'NA',
		// 		'module': ''
		// 	},
		// 	{
		// 		'opt_name': '',
		// 		'module': ''
		// 	}]
		// },

	]
	public scanProfile = null;
	public selectedAdvanceOptions = [];

	constructor(
		public commonService: CommonService,
		public portfolioSurveyService: PortfolioSurveyService,
		public route: ActivatedRoute,
		public modalService: BsModalService,
		public modalRef: BsModalRef
	) { }

	ngOnInit() {
		this.accountTypeCheck= JSON.parse(localStorage.getItem('profile')).account_type
		if (this.accountTypeCheck == 'customer'){
		this.getAllScanEntities()

		}
		this.selectedScanType = this.scanProfiles[0]
	 	this.scanProfile = this.scanProfiles[0];
		this.route.queryParams.subscribe(params => {
			if (params && params['explore']) {
				this.exploreDomain = params['explore'];
			}
			if (params && params['domain']) {
				this.domainName = params['domain']
				this.showDataSpinner = true
				this.getAllScansForUser(this.domainName);
			} else {
				this.commonService.getDomainName()
			}
		})
		this.commonService.domainNameEmmiter.subscribe(data => {
			this.domainName = data;
			this.showDataSpinner = true
			this.getAllScansForUser(this.domainName);
		})
	}
	showAdvanceOptions(options) {
		let optionSelected = false
		if (options && Array.isArray(options) && options.length) {
			options.forEach(element => {
				if (element && element.selected_option) optionSelected = true
			});
		}
		return optionSelected
	}
	setUserAccount(userAccount) {
		if (userAccount && userAccount == 'trial') {
			this.isUserTrial = true;
		}
	}
	getAllScansForUser(dName) {
		this.portfolioSurveyService.getScansForUserAndDomain(dName).subscribe(data => {
			if (data && data['status'] === 'pass') {
				// this.setUserAccount(data['userAccount']);
				this.scanList = data['data'];
				this.showDataSpinner = false
				this.scanList.forEach(sl => {
					sl['status'] = 'completed'
					sl['scans'].forEach(scan => {
						sl['scan_entities'] = sl['scan_entities'] ?
							sl['scan_entities'].concat(scan['scan_entities']) : scan['scan_entities'];
						if (scan['status'] !== 'completed') {
							sl['status'] = scan['status'];
						}
					})
				});
				this.scanList.forEach(scan => {
					if (Object.keys(this.seperateScanList).includes(scan['status']))
						this.seperateScanList[scan['status']].push(scan)
				})
				if (this.exploreDomain) {
					this.getAllScanEntities()
				}
			}
		}, error => {
			this.showDataSpinner = false
			console.log(error);
		})
	}

	getScanDetails(scan) {
		this.showRemove = false
		this.showScanList = false
		this.showDataSpinner = false
		this.selectedScan = scan
	}
	// addDomain(element) {
	// 	this.newDomain = {}
	// 	this.selectedScan['scan_entities'].push(element)
	// }
	onListChange(values, newDomains = null) {
		newDomains = newDomains ? newDomains : values.split(/[\n, ]/);
		const done = newDomains.filter((x, i) => {
			if (newDomains.indexOf(x) === i) {
				const domain = x.trim();
				if (this.selectedScan['scan_entities']) {
					if ((this.selectedScan['scan_entities'].indexOf(domain) < 0) && (domain.length > 0)) {
						this.selectedScan['scan_entities'].push(domain.trim());
						this.newEntities.push(domain.trim());
					}
				} else {
					if (domain.length > 0) {
						this.selectedScan['scan_entities'] = [domain.trim()]
						this.newEntities = [domain.trim()]
					}
				}
				this.domainText = null;
				return true;
			}
		})
	}
	getAllScanEntities() {
		this.showRemove = true
		this.showScanList = false
		this.selectedScan = {};
		this.selectedScan['scan_entities'] = [];
		this.newEntities = [];
		if (this.scanList && this.scanList.length) {
			this.scanList.forEach(scan => {
				this.selectedScan['scan_entities'] = this.selectedScan['scan_entities'].concat(scan['scan_entities'])
				this.newEntities = this.newEntities.concat(scan['scan_entities']);
			})
		}
		this.selectedScan['scan_entities'] = this.selectedScan['scan_entities'].filter((x, i) => {
			if (this.selectedScan['scan_entities'].indexOf(x) === i) {
				return x;
			}
		})
		this.newEntities = this.newEntities.filter((x, i) => {
			if (this.newEntities.indexOf(x) === i) {
				return x;
			}
		})
	}
	removeEntity(entity) {
		this.selectedScan['scan_entities'] = this.selectedScan['scan_entities'].filter(a => a !== entity);
		this.newEntities = this.newEntities.filter(a => a !== entity)
	}
	removeAllEntity() {
		this.selectedScan['scan_entities'] = []
		this.newEntities = [];
	}
	startScan() {
		if (this.newEntities.length) {
			this.newEntities.forEach(e => {
				let assetT = this.assetTypePipe.transform(e);
				if (this.selectedScanType['require_field'] && this.selectedScanType['require_field'].length) {
					let findRequird = this.selectedScanType['require_field'].find(ind => {
						return ind == assetT
					});
					if (findRequird) {
						this.runScan = true
					}
				} else {
					this.runScan = true
				}
			});
			if (this.runScan) {
				this.runDeepcScan();
			} else(
				alert("Add at least one "+this.selectedScanType['require_field'])
			)
		} else {
			this.scanStarted = false;
			alert('No Change In Scope.')
			// if ()
			// alert("Add at least one "+this.selectedScanType['require_field'])
		}
	}

	getSelectedOptionsFormat() {
		let objectToReturn = []
		this.advanceOptions.forEach((av, i) => {
			objectToReturn[i] = {...av}
			if (this.selectedAdvanceOptions[i]) {
				objectToReturn[i]["selected_option"] = this.selectedAdvanceOptions[i]
			} else {
				objectToReturn[i]["selected_option"] = av.options.find(opt => {
					return opt.id === this.selectedScanType['advance_option'][av['opt_id']]
				})
			}
		})
		return objectToReturn
	}

	runDeepcScan() {
		this.scanStarted = true;
		const selectedO = this.getSelectedOptionsFormat()
		const dataToSend = {
			'main_domain': this.domainName,
			'scan_entities': this.newEntities,
			'scan_depth': 1000,
			'mode': 'Automated',
			'scan_type': 'red_teaming',
			'red_teaming_id': this.selectedScan['red_teaming_id'] ? this.selectedScan['red_teaming_id'] : null,
			'attack_profile': this.selectedScanType['intent'],
			'scan_name': this.scanName,
			'advance_options': selectedO,
		}
		this.portfolioSurveyService.runDeepcScan(dataToSend).subscribe(data => {
			this.scanStarted = false;
			if (data && data['status'] === 'pass') {
				this.newEntities = [];
				// if (!this.selectedScan['red_teaming_id']) {
				// 	data['scanDetails']['data']['last_scan_created_at'] = data['scanDetails']['data']['created_at']
				// 	this.scanList.unshift(data['scanDetails']['data'])
				// }
				this.scanList = []
				this.seperateScanList = { "In Progress": [], "Completed": [], "New": [] }
				this.getAllScansForUser(this.domainName)
				this.showScanList = true
				this.selectedScanType = this.scanProfiles[0]
				this.scanProfile = this.scanProfiles[0];
				this.runScan = false;
				alert('Scan Queued successfully!')
			}
		}, error => {
			this.selectedScanType = this.scanProfiles[0]
			this.scanProfile = this.scanProfiles[0];
			this.scanStarted = false;
			this.runScan = false;
			alert('Error While Queuing Scan')
		})

	}
	addNew(type, inputId) {
		let value = inputId.value;
		if (type == 'keyword') {
			if (this.keywords.indexOf(value) == -1) {
				this.keywords.push(value)
				inputId == ''
			}
		} else {
			if (this.validUrlPipe.transform(value) && this.codeRepos.indexOf(value) == -1) {
				this.codeRepos.push(value)
				inputId == ''
			}
		}
	}

	getCount(type) {
		let aLength = 0;
		if (this.selectedScan['scan_entities']) {
			this.selectedScan['scan_entities'].forEach(e => {
				let assetT = this.assetTypePipe.transform(e);
				if (assetT === type) {
					aLength++;
				}
			});
		}
		return aLength;
	}

	modalOpenForAddNew(template: TemplateRef<any>) {
		if(this.accountTypeCheck== "customer"){
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
		}
		else{
			this.modalRef.hide();
		}
	}
	getEntitiesForScan(newEntities) {
		this.modalRef.hide();
		if (newEntities && newEntities.length) {
			this.onListChange(null, newEntities)
			// this.domainsAdded = true;
		}
	}
	getScanProfileFromIntent(scanIntent) {
		this.scanProfile = this.scanProfiles.find(a => a.intent == scanIntent)
	}
	getOptions(option, optionsChanged, index) {
		const workingOption = this.advanceOptions[index]
		this.selectedAdvanceOptions[index] = workingOption.options.find(w => w.opt_name == option)
	}
	mouseHoverToDiv(divId, sp) {
		const divById = document.getElementById(sp.intent)
		divById.setAttribute('gloov', sp.tooltip)
	}
}
