import { Component, Input, EventEmitter, SimpleChanges, AfterViewInit, Output, OnChanges } from '@angular/core';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-pagination',
	template: `
	<div style="width:100%; float:left">
		<div  class="pull-left"  *ngIf="numberOfPage">
			<div class="input-group ml-2 mb-3">
			<div class="input-group-append">
					<button class="pointer-initial btn text-dark">Page: </button>
				</div>
				<input min="1" [(ngModel)]="manualPage" (keydown.enter)="goToPageManuall(manualPage)" style="max-width: 6rem;" type="number" class="form-control" placeholder="Goto" aria-label="Goto">
				<div class="input-group-append">
					<button class="pointer-initial btn text-dark"> of {{numberOfPage.length}}</button>
				</div>
				<!-- <div class="input-group-append">
					<button class="btn btn-primary" type="button" (click)="goToPageManuall(manualPage)">Go To</button>
				</div> -->
			</div>
		</div>
		<div  class="pull-right" style="margin-right: 20px;" *ngIf="numberOfPage">
			<nav aria-label="Page navigation example">
				<ul class="pagination">
					<li class="page-item">
						<button class="page-link" [disabled]="(onPage === 1)" [ngClass]="{ 'disabledCss' : onPage === 1}" (click)="goToPage(onPage-1)">Previous</button>
					</li>
					<li class="page-item" *ngFor="let in of pagesToShow(); let i = index;">
						<button class="page-link" [ngStyle] = "{color:(in)===onPage ? 'red' : ' #20a8d8'}" (click)="goToPage(in)">{{in}}</button>
					</li>
					<li class="page-item">
						<button class="page-link"  [ngClass]="{ 'disabledCss' : onPage === numberOfPage.length}" (click)="goToPage(onPage+1)">Next</button>
					</li>
				</ul>
			</nav>
		</div>
	<div>
  `,
	styles: [`
        ul.pagination {}
        .page-item {
            cursor: pointer;
		}
		.disabledCss{
			color:#ddd;
			cursor:no-drop
		}
		.pointer-initial{
			cursor: initial !important;
		}
    `
	]
})

export class PaginationComponent implements OnChanges {
	@Input() totalCount: number;
	@Input() currentPageInput: number;
	@Input() perPage: number = 10;
	public numberOfPage
	public onPage = 1;
	public manualPage = 1;
	public userTrialCheck = false;
	public trialUserPageLimit = 2;
	public userProfile = null;
	ngOnInit() {
		this.userProfile = JSON.parse(localStorage.getItem('profile'))
		this.userTrialCheck = this.userProfile.paid
		this.trialUserPageLimit = this.userProfile.noOfPages ? this.userProfile.noOfPages : 2
	}
	@Output() goPage = new EventEmitter<boolean>();
	constructor(
		public toastr:ToastrService
	) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.currentPageInput) {
			this.onPage = this.currentPageInput;
			this.manualPage = this.onPage;
		}
		this.numberOfPage = this.createArray(1, Math.ceil(this.totalCount / this.perPage));
	}
	goToPageManuall(page){
		if(page>0 && Number.isInteger(page)){
			this.goToPage(page);
		}else{
			this.manualPage = this.onPage;
			this.toastr.info('Please enter a valid page number.');
		}
	}
	goToPage(pageNo) {
		console.log(pageNo,this.numberOfPage.length)
		if (pageNo <= this.numberOfPage.length) {

			this.onPage = pageNo;
			this.manualPage = this.onPage;
			if (!this.userTrialCheck) {
				if (this.onPage > this.trialUserPageLimit) {
					alert("You have reached your free trial limit. Please contact us at contact@firecompass.com")
				} else {
					this.goPage.emit(pageNo)
					this.pagesToShow()
				}
			}
			else {
				this.goPage.emit(pageNo)
				this.pagesToShow()
			}
		}
	}

	createArray(start, arrayLength) {
		let arr = []
		for (let i = start; i < (arrayLength + start); i++) {
			arr.push(i)
		}
		return arr;
	}


	pagesToShow() {
		if (this.numberOfPage.length < 5) {
			return this.createArray(1, this.numberOfPage.length)
		} else {
			if (this.onPage <= 2) {
				return this.createArray(1, 5)
			} else if (this.onPage < this.numberOfPage.length - 2) {
				return this.createArray(this.onPage - 2, 5)
			} else {
				return this.createArray(this.numberOfPage.length - 4, 5)
			}
		}
	}

	pagination(c) {
		var current = c,
			last = this.numberOfPage.length,
			delta = 2,
			left = current - delta,
			right = current + delta + 1,
			range = [],
			rangeWithDots = [],
			l;
		// console.log(last,delta, left,right)

		for (let i = 1; i <= last; i++) {
			if (i == 1 || i == last || i >= left && i < right) {
				range.push(i);
			}
		}

		for (let i of range) {
			if (l) {
				if (i - l === 2) {
					rangeWithDots.push(l + 1);
				} else if (i - l !== 1) {
					rangeWithDots.push('...');
				}
			}
			rangeWithDots.push(i);
			l = i;
		}

		return rangeWithDots;
	}
}
