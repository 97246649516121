import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { CommonService } from "../../_services/common.service";
import { AssessmentService } from "../../_services/assessment.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
@Component({
  selector: "app-attack-list-assessment-details",
  templateUrl: "./attack-list-assessment-details.component.html",
  styleUrls: ["./attack-list-assessment-details.component.scss"],
})
export class AttackListAssessmentDetailsComponent implements OnInit {
  public emailLC = [];
  public emailLC_failed:any = [];
  public failedIndexed:any = [];
  public openThis = -1;
  sortWith: string = "attempt";
  public order: number = 1;
  public initialSort: boolean = true;
  //   public lcReport = {
  //     "_metadata": {
  //         "version": 2.0,
  //         "source": {
  //             "tool": "deepc",
  //             "version": "2.x"
  //         }
  //     },
  //     "details": {
  //       "reverse_sections": {},
  //       "sections": {
  //           "web_app": {
  //               "c76b4548eff54446": {
  //                   "entity": {
  //                       "label": "entity",
  //                       "data": "https://en-gb.facebook.com/login.php",
  //                       "typeof": "web_app",
  //                       "data_type": "string",
  //                       "data_digest": "d607b914e7ce6c32",
  //                       "discovered_at": 1649222746442,
  //                       "digest": "c76b4548eff54446"

  //                   },
  //                   "has": {
  //                       "username_password": {
  //                           "374ee4a44ed08853": {
  //                               "entity": {
  //                                   "label": "entity",
  //                                   "data": "p00f@protonmail.com:5p00fing",
  //                                   "typeof": "username_password",
  //                                   "data_type": "string",
  //                                   "data_digest": "5939ee5b8501e05f",
  //                                   "discovered_at": 1649222746446,
  //                                   "digest": "374ee4a44ed08853",
  //                                   "incoming_edges": [
  //                                       {
  //                                           "module": "mod_credover_mock",
  //                                           "name": "leads_to",
  //                                           "confidence": 8.0
  //                                       }
  //                                   ]
  //                               },
  //                               "has": {
  //                                   "summary": {
  //                                       "66467a27de91d336": {
  //                                           "entity": {
  //                                               "label": "entity",
  //                                               "data": "successful",
  //                                               "typeof": "summary",
  //                                               "data_type": "string",
  //                                               "data_digest": "8313c50d709da48a",
  //                                               "discovered_at": 1649222746446,
  //                                               "digest": "66467a27de91d336",
  //                                               "incoming_edges": [
  //                                                   {
  //                                                       "module": "mod_credover_mock",
  //                                                       "name": "leads_to",
  //                                                       "confidence": 8.0
  //                                                   }
  //                                               ]
  //                                           },
  //                                           "has": {}
  //                                       }
  //                                   }
  //                               }
  //                           }
  //                       }
  //                   }
  //               },
  //               "c76b4548eff54446s": {
  //                   "entity": {
  //                       "label": "entity",
  //                       "data": "https://en-gb.fb.com/login.php",
  //                       "typeof": "web_app",
  //                       "data_type": "string",
  //                       "data_digest": "d607b914e7ce6c32",
  //                       "discovered_at": 1649222746442,
  //                       "digest": "c76b4548eff54446"
  //                   },
  //                   "has": {
  //                       "username_password": {
  //                           "374ee4a44ed08853": {
  //                               "entity": {
  //                                   "label": "entity",
  //                                   "data": "p00f@abc.com,5p00fing",
  //                                   "typeof": "username_password",
  //                                   "data_type": "string",
  //                                   "data_digest": "5939ee5b8501e05f",
  //                                   "discovered_at": 1649222746446,
  //                                   "digest": "374ee4a44ed08853",
  //                                   "incoming_edges": [
  //                                       {
  //                                           "module": "mod_credover_mock",
  //                                           "name": "leads_to",
  //                                           "confidence": 8.0
  //                                       }
  //                                   ]
  //                               },
  //                               "has": {
  //                                   "summary": {
  //                                       "66467a27de91d336": {
  //                                           "entity": {
  //                                               "label": "entity",
  //                                               "data": "unsuccessful",
  //                                               "typeof": "summary",
  //                                               "data_type": "string",
  //                                               "data_digest": "8313c50d709da48a",
  //                                               "discovered_at": 1649222746446,
  //                                               "digest": "66467a27de91d336",
  //                                               "incoming_edges": [
  //                                                   {
  //                                                       "module": "mod_credover_mock",
  //                                                       "name": "leads_to",
  //                                                       "confidence": 8.0
  //                                                   }
  //                                               ]
  //                                           },
  //                                           "has": {}
  //                                       }
  //                                   }
  //                               }
  //                           },
  //                           "374ee4a44ed088531": {
  //                             "entity": {
  //                                 "label": "entity",
  //                                 "data": "p001f@abc.com",
  //                                 "typeof": "username_password",
  //                                 "data_type": "string",
  //                                 "data_digest": "5939ee5b8501e05f",
  //                                 "discovered_at": 1649222746446,
  //                                 "digest": "374ee4a44ed08853",
  //                                 "incoming_edges": [
  //                                     {
  //                                         "module": "mod_credover_mock",
  //                                         "name": "leads_to",
  //                                         "confidence": 8.0
  //                                     }
  //                                 ]
  //                             },
  //                             "has": {
  //                                 "summary": {
  //                                     "66467a27de91d336": {
  //                                         "entity": {
  //                                             "label": "entity",
  //                                             "data": "successful",
  //                                             "typeof": "summary",
  //                                             "data_type": "string",
  //                                             "data_digest": "8313c50d709da48a",
  //                                             "discovered_at": 1649222746446,
  //                                             "digest": "66467a27de91d336",
  //                                             "incoming_edges": [
  //                                                 {
  //                                                     "module": "mod_credover_mock",
  //                                                     "name": "leads_to",
  //                                                     "confidence": 8.0
  //                                                 }
  //                                             ]
  //                                         },
  //                                         "has": {}
  //                                     }
  //                                 }
  //                             }
  //                         }
  //                       }
  //                   }
  //               }
  //           },

  //       }
  //     },
  //     "summary": {
  //         "stats": {
  //             "risks": {
  //                 "count": {
  //                     "total": {
  //                         "vulnerability": {
  //                             "group_by": {
  //                                 "severity": {}
  //                             }
  //                         },
  //                         "web_app_vulnerability": {
  //                             "group_by": {
  //                                 "severity": {}
  //                             }
  //                         }
  //                     }
  //                 }
  //             },
  //             "assists": {
  //                 "count": {
  //                     "total": {
  //                         "domain_name": 0,
  //                         "ip_address": 0,
  //                         "internet_name": 2,
  //                         "web_app": 1,
  //                         "netblock": 0,
  //                         "exploitation": 0,
  //                         "exploit_evidence": 0,
  //                         "summary": {
  //                             "group_by": {
  //                                 "data": {
  //                                     "unsuccessful": 1,
  //                                     "successful": 1
  //                                 }
  //                             }
  //                         }
  //                     }
  //                 }
  //             }
  //         }
  //     }
  // }
//  lcReport1 = {
//   "_metadata": {
//     "version": 2.0,
//     "source": {
//       "tool": "deepc",
//       "version": "2.x"
//     }
//   },
//   "details": {
//     "sections": {
//       "ip_address": {
//         "a34b6eae575068ef": {
//           "entity": {
//             "label": "entity",
//             "data": "192.168.29.14",
//             "typeof": "ip_address",
//             "data_type": "string",
//             "data_digest": "4f64dc9733ef321c",
//             "discovered_at": 1652664823587,
//             "digest": "a34b6eae575068ef",
//             "_inference": {
//               "_source": [],
//               "_techniques": [],
//               "_roles": [],
//               "_ownership": "owned",
//               "_fp": false
//             }
//           },
//           "has": {
//             "service": {
//               "0cfa96d192374fd5": {
//                 "entity": {
//                   "label": "entity",
//                   "data": "3306",
//                   "typeof": "service",
//                   "data_type": "string",
//                   "data_digest": "16fc18d787294ad5",
//                   "discovered_at": 1652664831395,
//                   "digest": "0cfa96d192374fd5",
//                   "incoming_edges": [
//                     {
//                       "module": "mod_credover_generic",
//                       "name": "leads_to",
//                       "confidence": 8.0
//                     }
//                   ]
//                 },
//                 "has": {
//                   "service_name": {
//                     "835ec4b79d2ae6e2": {
//                       "entity": {
//                         "label": "entity",
//                         "data": "mysql",
//                         "typeof": "service_name",
//                         "data_type": "string",
//                         "data_digest": "81c3b080dad537de",
//                         "discovered_at": 1652664831396,
//                         "digest": "835ec4b79d2ae6e2",
//                         "incoming_edges": [
//                           {
//                             "module": "mod_credover_generic",
//                             "name": "leads_to",
//                             "confidence": 8.0
//                           }
//                         ]
//                       },
//                       "has": {
//                         "username_password": {
//                           "d6ed61159d46baef": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:openauditrootuserpassword",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "4c32db6aa3102270",
//                               "discovered_at": 1652664831396,
//                               "digest": "d6ed61159d46baef",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "0ae726930928b690": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "admin,admin",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "d2abaa37a7c3db11",
//                               "discovered_at": 1652664831397,
//                               "digest": "0ae726930928b690",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "9e78847d4b54b584": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "395b509c00cc3444",
//                               "discovered_at": 1652664831398,
//                               "digest": "9e78847d4b54b584",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "4ffec007314f8e82": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "3fefaed99b3a6847",
//                               "discovered_at": 1652664831399,
//                               "digest": "4ffec007314f8e82",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "7207b80dbbb4e0b8": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "cloudera:cloudera",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "c4dd8b4adbf2933e",
//                               "discovered_at": 1652664831401,
//                               "digest": "7207b80dbbb4e0b8",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "b3b6b64d0b86212e": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:mysql",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "6633ec0c6876233a",
//                               "discovered_at": 1652664831402,
//                               "digest": "b3b6b64d0b86212e",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "99dbe91ca70c8216": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "successful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "802024b279b21588",
//                                     "discovered_at": 1652664831402,
//                                     "digest": "99dbe91ca70c8216",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "5413f15666ec5d4b": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:usbw",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "68c13c4f39a83f87",
//                               "discovered_at": 1652664831403,
//                               "digest": "5413f15666ec5d4b",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "c83726a7002964fc": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:cloudera",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "10d5f94934b349ea",
//                               "discovered_at": 1652664831405,
//                               "digest": "c83726a7002964fc",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "3fa7d5bad33a09e7": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:123qweASD#",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "949323c45db4ca34",
//                               "discovered_at": 1652664831406,
//                               "digest": "3fa7d5bad33a09e7",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "ca868ef24d406e09": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:moves",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "f291bc5c1d68cb3f",
//                               "discovered_at": 1652664831407,
//                               "digest": "ca868ef24d406e09",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "d40e1e3f8e29efee": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:root",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "792b3556d2e86799",
//                               "discovered_at": 1652664831409,
//                               "digest": "d40e1e3f8e29efee",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "92bce3caa817a930": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:raspberry",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "06056aa4fc1d4563",
//                               "discovered_at": 1652664831410,
//                               "digest": "92bce3caa817a930",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "8b9fa49c58b3f851": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "moves:moves",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "88a7dff5743a8cd3",
//                               "discovered_at": 1652664831411,
//                               "digest": "8b9fa49c58b3f851",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "d196607e03e9cd8a": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:vagrant",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "34ac65fe531cad81",
//                               "discovered_at": 1652664831412,
//                               "digest": "d196607e03e9cd8a",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "a42d1b957022fc13": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:123",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "790edaffaf1df20f",
//                               "discovered_at": 1652664831413,
//                               "digest": "a42d1b957022fc13",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "8d14b69722cf84ff": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "e9cac60f263dd70e",
//                               "discovered_at": 1652664831414,
//                               "digest": "8d14b69722cf84ff",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "dd52a6e287653a50": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "dbuser:123",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "2e684e84dc6a22ad",
//                               "discovered_at": 1652664831416,
//                               "digest": "dd52a6e287653a50",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "99dbe91ca70c8216": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "successful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "802024b279b21588",
//                                     "discovered_at": 1652664831402,
//                                     "digest": "99dbe91ca70c8216",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "7cff95dfdb96f89e": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "asteriskuser:eLaStIx.asteriskuser.2oo7",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "9a223fc1196782b3",
//                               "discovered_at": 1652664831417,
//                               "digest": "7cff95dfdb96f89e",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "fd595970372f5697": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:testpw",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "93b1c58662ee8ec0",
//                               "discovered_at": 1652664831418,
//                               "digest": "fd595970372f5697",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "edc380274879fd22": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:mktt",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "698eec6e5b0d4421",
//                               "discovered_at": 1652664831419,
//                               "digest": "edc380274879fd22",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "48d8eda90babda21": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "root:nagiosxi",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "460c106b2af71fc9",
//                               "discovered_at": 1652664831420,
//                               "digest": "48d8eda90babda21",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "3b5dc45dee617f51": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "asteriskuser:amp109",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "adb6bbd97fa7c643",
//                               "discovered_at": 1652664831421,
//                               "digest": "3b5dc45dee617f51",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           },
//                           "d940d3f0cc58667d": {
//                             "entity": {
//                               "label": "entity",
//                               "data": "mcUser:medocheck123",
//                               "typeof": "username_password",
//                               "data_type": "string",
//                               "data_digest": "6f5094a8bb298bd9",
//                               "discovered_at": 1652664831421,
//                               "digest": "d940d3f0cc58667d",
//                               "incoming_edges": [
//                                 {
//                                   "module": "mod_credover_generic",
//                                   "name": "leads_to",
//                                   "confidence": 8.0
//                                 }
//                               ]
//                             },
//                             "has": {
//                               "summary": {
//                                 "ad965a2e9d70ddb4": {
//                                   "entity": {
//                                     "label": "entity",
//                                     "data": "unsuccessful",
//                                     "typeof": "summary",
//                                     "data_type": "string",
//                                     "data_digest": "957c7fd1b561f2f3",
//                                     "discovered_at": 1652664831396,
//                                     "digest": "ad965a2e9d70ddb4",
//                                     "incoming_edges": [
//                                       {
//                                         "module": "mod_credover_generic",
//                                         "name": "leads_to",
//                                         "confidence": 8.0
//                                       }
//                                     ]
//                                   },
//                                   "has": {}
//                                 }
//                               }
//                             }
//                           }
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     },
//     "reverse_sections": {}
//   },
//   "summary": {
//     "stats": {
//       "risks": {
//         "count": {
//           "total": {
//             "vulnerability": {
//               "group_by": {
//                 "severity": {}
//               }
//             },
//             "web_app_vulnerability": {
//               "group_by": {
//                 "severity": {}
//               }
//             }
//           }
//         }
//       },
//       "assists": {
//         "count": {
//           "total": {
//             "domain_name": 0,
//             "ip_address": 1,
//             "internet_name": 0,
//             "web_app": 0,
//             "netblock": 0,
//             "exploitation": 0,
//             "exploit_evidence": 0,
//             "summary": {
//               "group_by": {
//                 "data": {
//                   "unsuccessful": 1,
//                   "successful": 1
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
  public lcSuccessfullLogin = 0;
  public lcUnsuccessfullLogin = 0;
  public totalLCStuffinigCount = 0;
  public showMoreDataLcStuff = false;
  public showThis = "all";
  public showData: any = [
    { name: "all", value: "test" },
    { name: "all-2", value: "test-2" },
  ];
  public assessmentData;
  public showTabs = [];
  public signedUrl = null;
  public ip_services = {};
  public ipDates = {};
  public webAppDates = {};
  public dataLoader = true;
  public signedLoader = true;
  public servicesCount = {};
  public assessmentId = null;
  public show_max_list = 3;
  public tabsStructure = {
    ip_address: "IP Address",
    web_app: "Web Apps",
    internet_name: "Sub Domain",
    domain_name: "Domains",
    web_app_vulnerability: "Web App Vulnerability",
    vulnerability: "Vulnerability",
    netblock: "Netblock",
    exploit: "Exploitation",
    lc_stuffing: "LC Stuffing",
    other_vulnerability: "Other Vulnerability"
  };
  ipKeyName: any;
  lcData: any;
  public targets = [];
  public runbook: any = {};
  public ipsVul = {};
  public scanId = null;
  public showScanResult = false;
  public summary = {};
  public modalDetails: any = {
    getExploitEvidence: [],
    // getCommunicationSetup:[],
    // getPayload:[]
  };
  public reqResModalRef:any;
  public activeResRes:any = [];
  public targets_cve: any = [];
  constructor(
    private portfolioSurveyService: PortfolioSurveyService,
    private commonService: CommonService,
    private assessmentService: AssessmentService,
    public router: Router,
    public route: ActivatedRoute,
    public modalService: BsModalService,
    public modalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    console.log("I am on init")
    if (this.route.snapshot.routeConfig.path.includes("scan")) {
      this.scanId = this.route.snapshot.paramMap.get("id");
      this.showScanResult = true;
      this.getRunBookScanSignedUrlDetails();
      this.getRunBookScansDetails();
    } else {
      this.showScanResult = false;
      if (this.route.snapshot.paramMap.get("attack_id")) {
        this.assessmentId = this.route.snapshot.paramMap.get("attack_id");
      } else {
        this.assessmentId = this.route.snapshot.paramMap.get("id");
      }
      this.getPlayBookAssessmentDetails();
      this.getAssessmentDetails();
    }
  }
  getTabsName(assessmentData) {
    var tabs = [];
    // if(!this.hasExploit()){
    if (this.hasExploit()) {
      tabs.push("exploit");
    } else if (this.hasLCStuffing(assessmentData)) {
      tabs.push("lc_stuffing");
    } else {
      tabs = Object.keys(assessmentData["details"]["sections"]);
      tabs = tabs.concat(
        Object.keys(assessmentData["details"]["reverse_sections"])
      );
    }
    if(this.targets_cve && this.targets_cve.length>0 && tabs.includes('vulnerability')){
      tabs.push("other_vulnerability");
    }
    this.showThis = tabs[0];
    return tabs;
  }
  hasExploit() {
    // return (this.summary && this.summary['exploitation'] && this.summary['exploitation']['count'])
    return (
      (this.runbook && this.runbook.type == "atomic") ||
      (this.summary &&
        this.summary["exploitation"] &&
        this.summary["exploitation"]["count"])
    );
  }
  getRunBookScansDetails() {
    this.assessmentService.getRunBookScansDetails(this.scanId).subscribe(
      (data: any) => {
        console.log(data, "I am data")
        if (data) {
          this.targets = data["target"];
          this.assessmentId = data.assessment_id;
          this.runbook = data?.runbook;
          this.summary = data?.summary;
        }
      },
      (error) => {
        alert("error while getting report details");
      }
    );
  }
  getAssessmentDetails() {
    this.assessmentService.getAssessmentDetails(this.assessmentId).subscribe(
      (data: any) => {
        if (data) {
          this.targets_cve = [];
          this.targets = data["target"];
          this.runbook = data?.runbook;
          this.summary = data?.summary;
          // get target cves
          let prog = { ...data['progress'] };
          if (prog && Object.keys(prog).length > 0) {
            let objs = Object.keys(prog);
            objs.forEach(e => {
              if (prog[e]['data'] && prog[e]['data']['modules']) {
                let modules = prog[e]['data']['modules'];
                if (modules && modules.length > 0) {
                  modules.forEach((m) => {
                    if (m['options']) {
                      Object.keys(m['options']).forEach((k) => {
                        if (m['options'][k] && m['options'][k]['value']) {
                          let exploded = m['options'][k]['value'].split(',');
                          exploded.map((e) => {
                            if (e) {
                              let spaceSplit = e.split(' ');
                              spaceSplit = spaceSplit.filter((o) =>{
                                let checkExiist = o.toLowerCase().indexOf('cve');
                                if(checkExiist>-1) this.targets_cve.push(o);
                              });
                            }
                          })
                        }
                      });
                    }
                  })
                }
              }
            });
          }
        }
      },
      (error) => {}
    );
  }
  checkTargetStatus(value) {
    return this.targets.includes(value);
  }
  getRunBookScanSignedUrlDetails() {
    this.assessmentService.runBookScanSignedUrlDetails(this.scanId).subscribe(
      (data: any) => {
        if (data) {
          console.log(data, "this.getRunBookScanSignedUrlDetails")
          this.signedUrl = data["url"];
          if (this.signedUrl) {
            this.assessmentService
              .getAttackListAssessmentDetails(this.signedUrl)
              .subscribe((assessmentData: any) => {
                if (assessmentData) {
                  console.log(assessmentData, "I am assessment data")
                  this.assessmentData = assessmentData["details"];
                  this.showTabs = this.getTabsName(assessmentData);
                  this.signedLoader = false;
                  this.getAssessmentReportData(this.showThis);
                }
              });
          }
        }
      },
      (error) => {
        this.signedLoader = false;
        alert("Report not found");
      }
    );
  }

  getPlayBookAssessmentDetails() {
    console.log("I am getting details for IPs")
    this.assessmentService.playBookAssessment(this.assessmentId).subscribe(
      (data: any) => {
        if (data) {
          this.signedUrl = data["url"];
          if (this.signedUrl) {
            this.assessmentService
              .getAttackListAssessmentDetails(this.signedUrl)
              .subscribe((assessmentData: any) => {
                if (assessmentData) {
                  console.log(assessmentData);
                  // assessmentData = this.lcReport1;
                  this.assessmentData = assessmentData["details"];
                  this.showTabs = this.getTabsName(assessmentData);
                  this.signedLoader = false;
                  this.getAssessmentReportData(this.showThis);
                }
              });
          }
        }
      },
      (error) => {
        this.signedLoader = false;
        alert("Report not found");
      }
    );
  }

  getSeverity(vul) {
    if (vul.severity === null) {
      return vul.cvss;
    }
    return vul.severity;
  }

  getService(service) {
    var ports = null;
    for (var key in service) {
      ports = service[key]["entity"]["data"];
    }
    return ports;
  }

  getIPAddress(service) {
    var ips = null;
    for (var key in service) {
      ips = service[key]["followed_by"]["ip_address"];
    }
    var ip = null;
    for (var key in ips) {
      ip = ips[key]["entity"]["data"];
    }
    return ip;
  }

  getIpsService(services) {
    const serviceName = [];
    if (services["service"]) {
      Object.values(services["service"]).forEach((element) => {
        let ele = [];
        if(element["entity"].name) ele.push(element["entity"].name);
        if(element["entity"].data) ele.push(element["entity"].data);
        if(element && element["has"] && element["has"]["service_state"]){
          Object.values(element["has"]["service_state"]).forEach((element) => {
            if (element['entity']['data']) {
              if (element['entity']['data'] == "tcpwrapped") {
                ele.push("filtered")
              }
              else {
                ele.push(element['entity']['data'])
              }
            }
          });
        }
        if(ele.length>0) serviceName.push(ele.join(' - '));
      });
    }
    return serviceName;
  }
  getIpsVulnerabities(ip_address, ip_data) {
    var count = 0;
    if (ip_data.hasOwnProperty("service")) {
      Object.values(ip_data["service"]).forEach((element) => {
        if (element["has"]) {
          if (element["has"]["vulnerability"]) {
            count =
              count + Object.keys(element["has"][["vulnerability"]]).length;
          }
        }
      });
    }
    this.ipsVul[ip_address] = count;
  }
  getIpsData() {
    this.dataLoader = true;
    this.showData = Object.values(
      this.assessmentData["sections"]["ip_address"]
    );
    this.showData.forEach((element) => {
      const ip_address = element["entity"].data;
      const services = element["has"];
      // console.log('services',services)
      this.getIpsVulnerabities(ip_address, services);
      this.ipDates[ip_address] = new Date(
        element["entity"].discovered_at
      ).toLocaleString();
      if (Object.keys(services).length > 0) {
        this.ip_services[ip_address] = this.getIpsService(services);
      } else {
        this.ip_services[ip_address] = [];
      }
      // console.log('services',this.ip_services)
      this.servicesCount[ip_address] = Object.keys(
        this.ip_services[ip_address]
      ).length;
    });
    this.dataLoader = false;
  }
  getWebAppsData() {
    this.showData = [];
    this.showData = Object.values(this.assessmentData["sections"]["web_app"]);
    this.showData.forEach((element) => {
      const ip_address = element["entity"].data;
      const services = element["has"];
      this.webAppDates[ip_address] = new Date(
        element["entity"].discovered_at
      ).toLocaleString();
    });
  }
  getWebAppVulnerability() {
    this.showData = [];
    this.dataLoader = true;
    this.showData = Object.values(
      this.assessmentData["reverse_sections"]["web_app_vulnerability"]
    );
    this.showData.forEach((element) => {
      element["discovered_at_new"] = new Date(
        element["entity"].discovered_at
      ).toLocaleString();
    });
    this.dataLoader = false;
  }
  getVulnerabilities(targetedCve:boolean) {
    this.showData = [];
    this.dataLoader = true;
    let vuln = Object.values(
      this.assessmentData["reverse_sections"]["vulnerability"]
    );
    if(this.targets_cve.length>0){
      if (targetedCve) {
        let index = 0;
        vuln.forEach((e) => {
          if (this.targets_cve.includes(e['entity']['title'])) {
            this.showData.push(vuln[index]);
          }
          index++;
        })
      } else {
        let index = 0;
        vuln.forEach((e) => {
          // console.log(e);
          if (!this.targets_cve.includes(e['entity']['title'])) {
            this.showData.push(vuln[index]);
          }
          index++;
        })
      }
    }else{
      this.showData = vuln;
    }
    // this.showData.forEach((element) => {
      // element['discovered_at_new'] = new Date(element['entity'].discovered_at).toLocaleString();
    // });
    this.dataLoader = false;
  }

  getAssessmentReportData(value) {
    console.log(value, "I am value")
    if (value == "ip_address") {
      this.getIpsData();
    } else if (value == "web_app_vulnerability") {
      this.getWebAppVulnerability();
    } else if (value == "vulnerability") {
      this.getVulnerabilities(true);
    } else if (value == "other_vulnerability") {
      this.getVulnerabilities(false);
    } else if (value == "internet_name") {
      this.dataLoader = true;
      this.showData = Object.values(
        this.assessmentData["sections"]["internet_name"]
      );
      this.dataLoader = false;
    } else if (value == "domain_name") {
      this.dataLoader = true;
      this.showData = Object.values(
        this.assessmentData["sections"]["domain_name"]
      );
      this.dataLoader = false;
    } else if (value == "netblock") {
      this.dataLoader = true;
      this.showData = Object.values(
        this.assessmentData["sections"]["netblock"]
      );
      this.dataLoader = false;
    } else if (value == "web_app") {
      this.dataLoader = true;
      this.showData = Object.values(this.assessmentData["sections"]["web_app"]);
      this.dataLoader = false;
    } else if (value == "exploit") {
      this.dataLoader = true;
      // this.showData = this.getNewJsonData();
      this.showData = this.assessmentData;
      this.ipKeyName = this.getExploiteIpKeys(this.showData);

      this.dataLoader = false;
    } else if (value == "lc_stuffing") {
      this.showData = this.assessmentData;
      this.lcData = this.getKeysForLCStuffing(this.showData);
      this.dataLoader = false;
    } else {
      this.dataLoader = true;
      this.showData = Object.values(this.assessmentData["sections"]["web_app"]);
      this.dataLoader = false;
    }
  }
  getKeysForLCStuffing(data) {
    console.log(data, "I am data at this level")
    if (data?.sections?.web_app) {
      return Object.keys(data?.sections?.web_app);
    } else if (data?.sections?.ip_address) {
      return Object.keys(data?.sections?.ip_address);
    }
  }
  getExploiteIpKeys(data) {
    return Object.keys(data?.sections?.ip_address);
  }
  getDomainSubdomainCount(domainData) {
    if (Object.keys(domainData["has"]).length > 0) {
      if (domainData["has"]["internet_name"]) {
        return Object.keys(domainData["has"]["internet_name"]).length;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getDomainIpsCount(domainData) {
    if (Object.keys(domainData["has"]).length > 0) {
      if (domainData["has"]["ip_address"]) {
        return Object.keys(domainData["has"]["ip_address"]).length;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  severityOfRiskLevel(riskLevel) {
    switch (true) {
      case riskLevel == "High":
        return "badge-danger";
      case riskLevel == "Low":
        return "badge-success";
      case riskLevel == "Medium":
        return "badge-warning";

      default:
        return "badge-primary";
    }
  }
  getConvertedDate(epocDate) {
    return new Date(epocDate).toLocaleString();
  }

  //exploitation['entity']['data']
  getExploitation(ip_entity) {
    let exploitations_dict = ip_entity["has"]["exploitation"];
    let exploitation = [];
    if (exploitations_dict) {
      for (let key in exploitations_dict) {
        let status = this.getExploitSuccessStatus(exploitations_dict[key]);
        exploitations_dict[key]["entity"]["success"] = status;
        exploitation.push(exploitations_dict[key]);
      }
    }
    return exploitation;
  }

  getExploitEvidence(exploit_entity) {
    let payloads = exploit_entity["has"]["payload"];
    let exploit_evidence = [];
    for (let payload in payloads) {
      let exploit_evidences_dict = payloads[payload]["has"]["exploit_evidence"];
      if (exploit_evidences_dict) {
        for (let key in exploit_evidences_dict) {
          exploit_evidence.push({
            data: exploit_evidences_dict[key]["entity"]["data"],
            output:
              exploit_evidences_dict[key]["entity"]["attribute"]["output"],
            payload: payloads[payload]["entity"]["data"],
          });
        }
      }
    }
    return exploit_evidence;
  }

  getCommunicationSetup(payload_entity) {
    let communication_setup = null;
    for (let key in payload_entity) {
      let communication_setups =
        payload_entity[key]["has"]["communication_setup"];
      if (communication_setups) {
        for (let key in communication_setups) {
          communication_setup = communication_setups[key]["entity"]["data"];
        }
      }
    }
    return communication_setup;
  }

  getPayload(exploit_entity) {
    let payloads = exploit_entity["has"]["payload"];
    let payload = [];
    if (payloads) {
      for (let key in payloads) {
        payload.push(payloads[key]["entity"]["data"]);
      }
    }
    return payload;
  }
  getExploitationCount(ip_entity) {
    let exploitation_dict = ip_entity["has"]["exploitation"];
    if (exploitation_dict) {
      return Object.keys(exploitation_dict).length;
    } else {
      return 0;
    }
  }

  // severityOfRiskLevel(riskLevel) {
  //   switch (true) {
  //     case (riskLevel == 'Critical'): return 'badge-danger';
  //     case (riskLevel == 'High'): return 'badge-warning';
  //     case (riskLevel == 'Medium'): return 'badge-primary';
  //     case (riskLevel == 'Low'): return 'badge-success';

  //     default: return 'badge-primary';
  //   }
  // }
  getExploitEvidenceCount(exploit_entity) {
    let payloads = exploit_entity["has"]["payload"];
    let count = 0;
    if (payloads) {
      for (let key in payloads) {
        let exploit_evidences_dict = payloads[key]["has"]["exploit_evidence"];
        if (exploit_evidences_dict) {
          count = count + Object.keys(exploit_evidences_dict).length;
        }
      }
      return count;
    }
    return 0;
  }

  getExploitSuccessStatus(exploit_entity) {
    let status = "failed";
    if (exploit_entity.has && exploit_entity.has.payload) {
      let payload = Object.values(exploit_entity.has.payload);
      if (
        payload.length &&
        payload[0]["has"] &&
        payload[0]["has"].exploit_evidence
      ) {
        let evidence = Object.values(payload[0]["has"].exploit_evidence);
        evidence.forEach((e) => {
          if (
            e["entity"] &&
            e["entity"].attribute &&
            e["entity"].attribute.output != "None"
          ) {
            status = "success";
          } else if (
            e["entity"] &&
            e["entity"].attribute &&
            e["entity"].attribute.output == "None" &&
            status != "success"
          ) {
            status = "non-determinant";
          }
        });
      } else {
        status = "failed";
      }
    }
    return status;
  }

  getEvidenceStatus(ip_entity) {
    let exploitations_dict = ip_entity["has"]["exploitation"];
    let success = 0;
    let failure = 0;
    if (exploitations_dict) {
      for (let key in exploitations_dict) {
        let status = this.getExploitSuccessStatus(exploitations_dict[key]);
        if (status == "success" || status == "non-determinant") {
          success = success + 1;
        } else {
          failure = failure + 1;
        }
        return {
          sucessCount: success,
          failCount: failure,
        };
      }
    }
  }

  showExploidDetails(template: TemplateRef<any>, activeExploit) {
    this.modalDetails.getExploitEvidence = this.getExploitEvidence(
      activeExploit
    );
    // this.modalDetails.getCommunicationSetup = this.getCommunicationSetup(activeExploit);
    // this.modalDetails.getPayload = this.getPayload(activeExploit);
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  getFailCount(total, success) {
    return total - success;
  }
  // getNewJsonData(){
  //   let json = '';
  //   return json['details'];
  // }
  hasLCStuffing(assessData) {
    console.log("access data", assessData.summary.stats.assists.count.total.summary.group_by.data["\"successful\""])
    let lcSuccessfullLogin =
      assessData?.summary?.stats?.assists?.count?.total?.summary?.group_by?.data
        ?.successful ||
      assessData?.summary?.stats?.assists?.count?.total?.summary?.group_by?.data
        ?.successful ||
      assessData?.summary?.stats?.assists?.count?.total?.summary?.group_by?.data
        ?.["\"successful\""] ||
      0;
    let lcUnsuccessfullLogin =
      assessData?.summary?.stats?.assists?.count?.total?.summary?.group_by?.data
        ?.unsuccessful ||
      assessData?.summary?.stats?.assists?.count?.total?.summary?.group_by?.data
        ?.unsuccessful ||
      assessData?.summary?.stats?.assists?.count?.total?.summary?.group_by?.data
        ?.["\"unsuccessful\""] ||
      0;
    console.log(lcSuccessfullLogin, lcUnsuccessfullLogin, "lcSuccessfullLogin")
    if (lcSuccessfullLogin > 0 || lcUnsuccessfullLogin > 0) return true;
    else false;
  }
  getTotalCount(data, i) {
    
    this.totalLCStuffinigCount = 0;
    this.lcSuccessfullLogin = 0;
    this.lcUnsuccessfullLogin = 0;
    this.getDetailForStuding(data, i);
    this.emailLC.forEach((ele) => {
      if (ele.index == i) {
        this.totalLCStuffinigCount = this.totalLCStuffinigCount + 1;
        if (ele.attempt == "successful" || ele.attempt == "successful") {
          this.lcSuccessfullLogin = this.lcSuccessfullLogin + 1;
        } else if (
          ele.attempt == "unsuccessful" ||
          ele.attempt == "unsuccessful"
        ) {
          this.lcUnsuccessfullLogin = this.lcUnsuccessfullLogin + 1;
        }
      }
    });
  }
  getDetailForStuding(data, i) {
    if (data.entity["typeof"] == "web_app") {
      let a = Object.keys(data["has"]["username_password"]);
      a.forEach((ele) => {
        let b = Object.keys(
          data["has"]["username_password"][ele]["has"]["summary"]
        );
        b.forEach((att) => {
          this.emailLC.push({
            index: i,
            mail: data["has"]["username_password"][ele]["entity"].data,
            attempt:
              data["has"]["username_password"][ele]["has"]["summary"][att][
                "entity"
              ].data,
          });
        });
      });
      this.emailLC = this.emailLC.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          this.emailLC.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
    } else if (data.entity["typeof"] == "ip_address") {
      let a, b, c, d;
      data.entity['service_detail'] = []
      let services = data["has"]["service"]
      if (services) {
        a = Object.keys(services);
        a.forEach((aele) => {
          let service_name = services[aele]["has"]["service_name"]
          if (service_name) {
            console.log(aele, "I am aele")
            b = Object.keys(service_name);
            b.forEach((ele) => {
              data.entity['service_detail'].push({'service': services[aele]["entity"]["data"], 'service_name':service_name[ele]["entity"]["data"]}) 
              let userNamePass = service_name[ele]["has"]["username_password"];
              let failedUserNamePass = service_name[ele]["has"]["username_password_unsuccessful_count"];
              if (userNamePass) {
                c = Object.keys(
                  userNamePass
                );
                c.forEach((att) => {
                  let summary = userNamePass[att]["has"]["summary"]
                  if(summary) {
                    d = Object.keys(
                      userNamePass[att]["has"]["summary"]
                    );
                    d.forEach((final) => {
                      this.emailLC.push({
                        index: i,
                        servicePort: service_name[ele]["entity"]["data"] + ":" +  services[aele]["entity"]["data"],
                        mail:
                          userNamePass[att]["entity"].data,
                        attempt:
                          userNamePass[att]["has"]["summary"][final]["entity"].data,
                      });
                    });
                  }
                });
                if(failedUserNamePass){
                  c = Object.keys(
                    failedUserNamePass
                  );
                  c.forEach(att => {
                    // console.log(this.failedIndexed,att,this.failedIndexed.includes(att));
                    if(!this.failedIndexed.includes(att)){
                      this.failedIndexed.push(att);
                      let ip = failedUserNamePass[att]['entity']['ip'];
                      if(!this.emailLC_failed[ip]) this.emailLC_failed[ip] = 0;
                      this.emailLC_failed[ip] = this.emailLC_failed[ip] + parseInt(failedUserNamePass[att]['entity']['data']);
                    }
                  });
                }
              }
            });
          }
        });
      }
      this.emailLC = this.emailLC.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          this.emailLC.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
    }
  }
  showPassword(pass){
    let a = pass.split(/[:,]+/)[1]
    if (a) return a
    else return "NA"
  }
  showUser(user){
    let a = user.split(/[:,]+/)[0]
    if (a) return a
    else return "NA"
  }
  getUnSuccessful_count(data,ip,entity){
    return (data[ip])?data[ip]:0;
  }
  showReqRes(modalComponent,entity){
    //, { class: "dialog-model", backdrop: 'static', keyboard: false }
    this.activeResRes['request'] = (entity['request'])?entity['request']:[];
    this.activeResRes['response'] = (entity['response'])?entity['response']:[];
    this.reqResModalRef = this.modalService.show(modalComponent);
  }
  checkEmpty(obj){
    return (obj)?Object.keys(obj).length:null;
  }
  displayService(service){
    if (service.includes('?')){
      service = service.replaceAll('?','')
    }
    return service
  }
}