import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { AssetTypePipe } from "app/pipes/asset-type.pipe";
import { CommonService } from "app/_services/common.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { technology_base_url } from 'app/_services/common-var.service';

@Component({
  selector: "app-attck-surface",
  templateUrl: "./attck-surface.component.html",
  styleUrls: ["./attck-surface.component.scss"],
})
export class AttckSurfaceComponent implements OnInit, OnChanges {
  @Input() domain;
  @Input() targetTypes;
  @Input() entityRemoved;
  @Input() playbookType;
  @Output() removeTargetFromAttackSurface = new EventEmitter<any>();
  @Output() addTargetFromAttackSurface = new EventEmitter<any>();
  @Output() resetTargetForAttackSurface = new EventEmitter<any>();
  @Output() selectedTarget = new EventEmitter<any>();

  public page;
  public limit = 10;
  public selectAllLimit = 1000;
  public remainingEntities = 1000;
  public searchKey;
  public showSpinner = false;
  public showSpinnerForTop1000 = false;
  public showThis;
  public assetTypePipe = new AssetTypePipe();
  public show_max_list: number = 3;
  public pageForSelectAll;
  public showDetails = {
    domain: [],
    sub_domain: [],
    ip_address: [],
    netblock: [],
    application: [],
    keywords: [],
    ip_ports : []
  };
  public showDetailsCount = {
    domain: 10,
    sub_domain: 10,
    ip_address: 10,
    netblock: 10,
    application: 10,
    keywords: 10,
    ip_ports: 10,
  };
  public showDetailsSpinner = {
    domain: false,
    sub_domain: false,
    ip_address: false,
    netblock: false,
    application: false,
    keywords: false,
    ip_ports : false,
  };
  public showDetailsSearch = {
    domain: null,
    sub_domain: null,
    ip_address: null,
    netblock: null,
    application: null,
    keywords: null,
    ip_ports: null,
  };
  public attribution = {
    related_domain: "DNS",
    host: "DNS",
    net_block: "Netblock",
    keyword: "Keyword",
    manual: "Manual",
  };
  public targetsForAssessment = [];
  public allIps = [];
  public allDomains = [];
  public allSubdomains = [];
  public allNetblocks = [];
  public allApplications = [];
  public allIpPorts = [];
  public allIpChecked = false;
  public allDomainChecked = false;
  public allSubdomainChecked = false;
  public allNetblockChecked = false;
  public allApplicationChecked = false;
  public allIpPortsChecked =false;
  public modalAttackPageRange = 1;
  public modalAttackName = "";
  public clearPreviousEntities = false;
  public technologies = []
  public technology_show_max_list: number = 3;
  public technology_base_url = technology_base_url
  constructor(
    public commonService: CommonService,
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    console.log("targetType=",changes.targetTypes)
    if (changes.targetTypes) {
      this.targetsForAssessment = [];
      this.showDetails = {
        domain: [],
        sub_domain: [],
        ip_address: [],
        netblock: [],
        application: [],
        keywords: [],
        ip_ports:[],
      };
      this.targetTypes =
        this.targetTypes && this.targetTypes.length
          ? this.targetTypes
          : ["domain", "ip_address", "sub_domain", "netblock", "application"];
      this.targetTypes.forEach((each) => {
        this.getDetials(each);
      });
      this.showThis = this.targetTypes[0];
    }
    if (changes.entityRemoved) {
      if (this.entityRemoved) {
        this.removeFromAssessment(this.entityRemoved);
        const entityType = this.assetTypePipe.transform(this.entityRemoved);
        if (entityType == "ip") {
          this.removeEntityFromTarget("ip_address", this.entityRemoved);
        } else if (entityType == "url") {
          this.removeEntityFromTarget("application", this.entityRemoved);
        } else if (entityType == "domain") {
          this.removeEntityFromTarget("domain", this.entityRemoved);
          this.removeEntityFromTarget("sub_domain", this.entityRemoved);
        } else if (entityType == "netblock") {
          this.removeEntityFromTarget("netblock", this.entityRemoved);
        } else {
          this.removeEntityFromTarget("ip_address", this.entityRemoved);
          this.removeEntityFromTarget("application", this.entityRemoved);
          this.removeEntityFromTarget("domain", this.entityRemoved);
          this.removeEntityFromTarget("sub_domain", this.entityRemoved);
          this.removeEntityFromTarget("netblock", this.entityRemoved);
        }
        // this.allDomainChecked = false
        // this.allSubdomainChecked = false
        // this.allIpChecked = false
        // this.allApplicationChecked = false
        // this.allNetblockChecked = false
      }
    }
    this.selectedTarget.emit(this.showThis)
  }
  changeTab(){
    this.selectedTarget.emit(this.showThis)
  }
  getRange(ip){
    return this.commonService.getRange(ip);
  }
  removeEntityFromTarget(type, entity) {
    if (this.showDetails[type] && this.showDetails[type].length) {
      const entityFound = this.showDetails[type].find((a) => a.name == entity);
      if (entityFound) entityFound.addedToTarget = false;
    }
  }
  onScroll() {
    if (this.showThis == "domain" && !this.showDetailsSpinner["domain"]) {
      this.getDomainDetails();
    }
    if (
      this.showThis == "ip_address" &&
      !this.showDetailsSpinner["ip_address"]
    ) {
      this.getIpDetails();
    }
    if (
      this.showThis == "sub_domain" &&
      !this.showDetailsSpinner["sub_domain"]
    ) {
      this.getSubDomainDetails();
    }
    if (this.showThis == "netblock" && !this.showDetailsSpinner["netblock"]) {
      this.getNetblockDetails();
    }
    if (
      this.showThis == "application" &&
      !this.showDetailsSpinner["application"]
    ) {
      this.getApplictionDetails();
    }
    if (
      this.showThis == "ip_ports" &&
      !this.showDetailsSpinner["ip_ports"]
    ) {
      this.getIpPortsDetails();
    }
  }
  getDetials(value) {
    if (value == "domain") {
      this.getDomainDetails();
    }
    if (value == "ip_address") {
      this.getIpDetails();
    }
    if (value == "sub_domain") {
      this.getSubDomainDetails();
    }
    if (value == "netblock") {
      this.getNetblockDetails();
    }
    if (value == "application") {
      this.getTechnologiesData()
      this.getApplictionDetails();
    }
    if (value == "ip_ports") {
      this.getIpPortsDetails();
    }
  }
  public isTargetType(type) {
    if (!this.targetTypes || !this.targetTypes.length) {
      return true;
    }
    return this.targetTypes.includes(type);
  }
  getPageNumber(entities) {
    return Math.floor(entities.length / this.limit) + 1;
  }
  public getDomainDetails() {
    if (
      this.showDetails["domain"].length >= this.showDetailsCount["domain"] ||
      this.showDetailsSpinner["domain"]
    )
      return;
    this.showDetailsSpinner["domain"] = true;
    const params = {
      page: this.getPageNumber(this.showDetails["domain"]),
      limit: this.limit,
      searchKey: this.showDetailsSearch["domain"],
      // take_over_risk: this.showTakeOverRisk,
    };
    this.portfolioSurveyService
      .getRelatedDomainsSubDomainsWithFilters(this.domain, params)
      .subscribe((data: any) => {
        this.showDetailsSpinner["domain"] = false;
        if (data.data.results && data["status"] == "pass") {
          data.data.results.forEach((domain) => {
            if (this.targetsForAssessment.includes(domain.domain)) {
              domain.addedToTarget = true;
            }
            this.showDetails["domain"].push({
              ...domain,
              ...{ name: domain.domain },
            });
          });

          if (data["data"] && data["data"].count)
            this.showDetailsCount["domain"] = data["data"].count;
        }
      });
  }
  public getIpDetails() {
    if (
      this.showDetails["ip_address"].length >=
        this.showDetailsCount["ip_address"] ||
      this.showDetailsSpinner["ip_address"]
    )
      return;
    this.showDetailsSpinner["ip_address"] = true;
    const params = {
      page: this.getPageNumber(this.showDetails["ip_address"]),
      limit: this.limit,
      searchKey: this.showDetailsSearch["ip_address"],
      validated: true,
    };
    this.portfolioSurveyService
      .getIpsDetailsPaginated(this.domain, params)
      .subscribe((data) => {
        this.showDetailsSpinner["ip_address"] = false;
        if (data["data"]) {
          data["data"].forEach((ip) => {
            if (this.targetsForAssessment.includes(ip.ip_address)) {
              ip.addedToTarget = true;
            }
            this.showDetails["ip_address"].push({
              ...ip,
              ...{ name: ip.ip_address },
            });
          });
          if (data["data"] && data["data"][0]?.total)
            this.showDetailsCount["ip_address"] = data["data"][0].total;
        }
      });
  }
  public getSubDomainDetails() {
    if (
      this.showDetails["sub_domain"].length >=
        this.showDetailsCount["sub_domain"] ||
      this.showDetailsSpinner["sub_domain"]
    )
      return;
    this.showDetailsSpinner["sub_domain"] = true;
    const params = {
      page: this.getPageNumber(this.showDetails["sub_domain"]),
      limit: this.limit,
      searchKey: this.showDetailsSearch["sub_domain"],
    };

    this.portfolioSurveyService
      .getSubDomain(this.domain, params)
      .subscribe((data) => {
        this.showDetailsSpinner["sub_domain"] = false;
        if (data["data"]) {
          data["data"].results.forEach((sub) => {
            if (this.targetsForAssessment.includes(sub.host)) {
              sub.addedToTarget = true;
            }
            this.showDetails["sub_domain"].push({
              ...sub,
              ...{ name: sub.host },
            });
          });

          if (data["data"] && data["data"].count)
            this.showDetailsCount["sub_domain"] = data["data"].count;
        }
      });
  }
  public getNetblockDetails() {
    if (
      this.showDetails["netblock"].length >=
        this.showDetailsCount["netblock"] ||
      this.showDetailsSpinner["netblock"]
    )
      return;
    this.showDetailsSpinner["netblock"] = true;
    var params = {
      page: this.getPageNumber(this.showDetails["netblock"]),
      limit: this.limit,
      searchKey: this.showDetailsSearch["netblock"],
      netblockType: "OWNED",
    };
    this.portfolioSurveyService
      .getNetblocksPaginated(this.domain, params)
      .subscribe((data) => {
        this.showDetailsSpinner["netblock"] = false;
        if (data) {
          if (data["data"]) {
            data["data"].results.forEach((net) => {
              if (this.targetsForAssessment.includes(net.net_block)) {
                net.addedToTarget = true;
              }
              this.showDetails["netblock"].push({
                ...net,
                ...{ name: net.net_block },
              });
            });

            if (data["data"] && data["data"].count)
              this.showDetailsCount["netblock"] = data["data"].count;
          }
        }
      });
  }
  public getApplictionDetails() {
    if (
      this.showDetails["application"].length >=
        this.showDetailsCount["application"] ||
      this.showDetailsSpinner["application"]
    )
      return;
    this.showDetailsSpinner["application"] = true;
    const params = {
      page: this.getPageNumber(this.showDetails["application"]),
      limit: this.limit,
      searchKey: this.showDetailsSearch["application"],
    };
    this.portfolioSurveyService
      .getwebapplication(this.domain, params)
      .subscribe((data) => {
        this.showDetailsSpinner["application"] = false;
        if (data["data"]) {
          data["data"].forEach((app) => {
            if (this.targetsForAssessment.includes(app.source_url)) {
              app.addedToTarget = true;
            }
            this.showDetails["application"].push({
              ...app,
              ...{ name: app.source_url },
            });
          });
          if (data["data"] && data["count_apps"])
            this.showDetailsCount["application"] = data["count_apps"];
        }
      });
      
  }

  public getIpPortsDetails(){
    if (
      this.showDetails["ip_ports"].length >=
        this.showDetailsCount["ip_ports"] ||
      this.showDetailsSpinner["ip_ports"]
    )
      return;
    this.showDetailsSpinner["ip_ports"] = true;
    const params = {
      page: this.getPageNumber(this.showDetails["ip_ports"]),
      limit: this.limit,
      port_state: "all",
      validated : "true",
      searchKey: this.showDetailsSearch["ip_ports"],
    };
    this.portfolioSurveyService
      .getIpsDetailsPaginated(this.domain, params)
      .subscribe((data) => {
        this.showDetailsSpinner["ip_ports"] = false;
        if (data["data"]) {
          data["data"].forEach((ip) => {
            let ip_ports_details = this.get_ip_ports(ip)
            if (ip_ports_details){
              if (this.targetsForAssessment.includes(ip_ports_details.ip_ports)) {
                ip.addedToTarget = true;
              }
              this.showDetails["ip_ports"].push({
                ...ip,
                ...ip_ports_details,
                ...{ name: ip_ports_details.ip_ports }
              });
            }
            });
            if (data["data"] && data["data"][0]?.total)
              this.showDetailsCount["ip_ports"] = data["data"][0].total;
        }
      });
  }

  getTechnologiesData(){
    this.portfolioSurveyService
      .getTechnology(this.domain)
      .subscribe((techData) =>{
        this.technologies = techData['data']
      },
      );
  }
  getTechnologies(application) {
    let technologies = []
    if (this.technologies && application.source_url) {
        for (let key in this.technologies) {
            if (key.indexOf(application.source_url) > -1) {
                technologies.push(...this.technologies[key]);
            }
        }
        application.technologies = this.commonService.getUniqueValues(technologies, 'technology_name')
        return true

    }
    return false
}
  addToAssessment(entity,type="") {
    if (this.targetsForAssessment.length >= 1000) return;
    if (this.targetsForAssessment.length >=10 && this.playbookType=='atomic') return;
    if(type=="netblock"){
      if(this.getRange(entity.net_block)<=this.commonService.maxAllowedRange){
        this.targetsForAssessment.push(entity.name.trim());
        entity.addedToTarget = true;
      }else{
        this.removeFromAssessment(entity);
        entity.addedToTarget = false;
      }
    }else if (entity.name && !this.targetsForAssessment.includes(entity.name)) {
      this.targetsForAssessment.push(entity.name.trim());
      entity.addedToTarget = true;
    }
    this.addTargetFromAttackSurface.emit(entity.name);
  }
  filterUniqueTargets() {
    this.targetsForAssessment = [...new Set(this.targetsForAssessment)]
    this.targetsForAssessment = this.targetsForAssessment.filter((a, i) => {
      return this.targetsForAssessment.indexOf(a) == i
    })
  }
  removeFromAssessment(entity) {
    let index;
    if (entity.name) {
      index = this.targetsForAssessment.indexOf(entity.name);
      if (index >= 0) {
        this.targetsForAssessment.splice(index, 1);
        entity.addedToTarget = false;
      }
      this.removeTargetFromAttackSurface.emit(entity.name);
    } else {
      index = this.targetsForAssessment.indexOf(entity);
      let removedTraget = {
        name: entity,
        addedToTarget: false,
      };
      if (index >= 0) {
        this.targetsForAssessment.splice(index, 1);
        removedTraget.addedToTarget = false;
      }
      this.removeTargetFromAttackSurface.emit(removedTraget.name);
    }
  }
  entitiesToShow(value) {
    return this.showDetails[value];
  }
  searchTarget(value, allData = null) {
      this.showDetails[value]= []
    // this.showDetails = {
    //   domain: [],
    //   sub_domain: [],
    //   ip_address: [],
    //   netblock: [],
    //   application: [],
    //   keywords: [],
    // };
    this.getDetials(value);
  }

  public selectAllIps(pageRange) {
    this.allIps.forEach((entity) => {
      this.removeFromAssessment(entity);
    });
    this.showSpinnerForTop1000 = true;
    this.showDetailsSpinner['ip_address'] = true;
    this.allIps = [];
    const params = {
      page: pageRange,
      limit: this.selectAllLimit,
      searchKey: this.showDetailsSearch["ip_address"],
      validated: true,
    };
    this.portfolioSurveyService
      .getIpsDetailsPaginated(this.domain, params)
      .subscribe(
        (data) => {
          if (data["data"]) {
            data["data"].forEach((ip) => {
              var entity = { ...ip, ...{ name: ip.ip_address } };
              this.allIps.push(entity);
              this.addToAssessment(entity);
            });
            this.showDetails["ip_address"] = this.allIps;
            this.modalRef.hide();
            this.showSpinnerForTop1000 = false;
            this.showDetailsSpinner['ip_address'] = false;
            this.filterUniqueTargets()
          }
        },
        (error) => {
          alert(
            "Error while fetching 1000 IPs for page " +
              pageRange +
              ". Please select from listed IPs"
          );
          this.modalRef.hide();
          this.showSpinnerForTop1000 = false;
          this.showDetailsSpinner['ip_address'] = false;
        }
      );
  }
  public selectAllIpPorts(pageRange) {
    this.allIpPorts.forEach((entity) => {
      this.removeFromAssessment(entity);
    });
    this.showSpinnerForTop1000 = true;
    this.showDetailsSpinner['ip_ports'] = true;
    this.allIpPorts = [];
    const params = {
      page: pageRange,
      limit: this.selectAllLimit,
      searchKey: this.showDetailsSearch["ip_ports"],
      validated: true,
      port_state: "all"
    };
    this.portfolioSurveyService
      .getIpsDetailsPaginated(this.domain, params)
      .subscribe(
        (data) => {
          if (data["data"]) {
            data["data"].forEach((ip) => {
              let ip_ports_details = this.get_ip_ports(ip)
              if (ip_ports_details) {
              var entity = {...ip,
                ...ip_ports_details,
                ...{ name: ip_ports_details.ip_ports }}
              this.allIpPorts.push(entity);
              if (ip_ports_details.ports.length > 0) {
              this.addToAssessment(entity);
              }
              }
            });
            this.showDetails["ip_ports"] = this.allIpPorts;
            this.modalRef.hide();
            this.showSpinnerForTop1000 = false;
            this.showDetailsSpinner['ip_ports'] = false;
            this.filterUniqueTargets()
          }
        },
        (error) => {
          alert(
            "Error while fetching 1000 IPs for page " +
              pageRange +
              ". Please select from listed IPs"
          );
          this.modalRef.hide();
          this.showSpinnerForTop1000 = false;
          this.showDetailsSpinner['ip_ports'] = false;
        }
      );
  }

  public selectAllDomains(pageRange) {
    this.allDomains.forEach((entity) => {
      this.removeFromAssessment(entity);
    });
    this.showSpinnerForTop1000 = true;
    this.showDetailsSpinner['domain'] = true;
    this.allDomains = [];
    const params = {
      page: pageRange,
      page_size: this.selectAllLimit,
      searchKey: this.showDetailsSearch["domain"],
    };
    this.portfolioSurveyService
      .getRelatedDomainsSubDomainsWithFilters(this.domain, params)
      .subscribe(
        (data: any) => {
          if (data.data.results && data["status"] == "pass") {
            data.data.results.forEach((domain) => {
              var entity = { ...domain, ...{ name: domain.domain } };
              this.allDomains.push(entity);
              this.addToAssessment(entity);
            });
            this.showDetails["domain"] = this.allDomains;
          }
          this.showSpinnerForTop1000 = false;
          this.showDetailsSpinner['domain'] = false;
          this.filterUniqueTargets()
          this.modalRef.hide();
        },
        (error) => {
          alert(
            "Error while fetching 1000 related domains for page " +
              pageRange +
              ". Please select from listed related domains"
          );
          this.modalRef.hide();
          this.showSpinnerForTop1000 = false;
          this.showDetailsSpinner['domain'] = false;
        }
      );
  }

  public selectAllSubDomains(pageRange) {
    this.allSubdomains.forEach((entity) => {
      this.removeFromAssessment(entity);
    });
    this.showSpinnerForTop1000 = true;
    this.showDetailsSpinner['sub_domain'] = true;
    this.allSubdomains = [];
    const params = {
      page: pageRange,
      page_size: this.selectAllLimit,
      searchKey: this.showDetailsSearch["sub_domain"],
    };
    this.portfolioSurveyService.getSubDomain(this.domain, params).subscribe(
      (data) => {
        if (data["data"]) {
          data["data"].results.forEach((sub) => {
            var entity = { ...sub, ...{ name: sub.host } };
            this.allSubdomains.push(entity);
            this.addToAssessment(entity);
          });
          this.showSpinnerForTop1000 = false;
          this.showDetails["sub_domain"] = this.allSubdomains;
          this.showDetailsSpinner['sub_domain'] = false;
          this.filterUniqueTargets()
          this.modalRef.hide();
        }
      },
      (error) => {
        alert(
          "Error while fetching 1000 sub-domains for page " +
            pageRange +
            ". Please select from listed sub-domains"
        );
        this.modalRef.hide();
        this.showSpinnerForTop1000 = false;
        this.showDetailsSpinner['sub_domain'] = false;
      }
    );
  }

  public selectAllNetblocks(pageRange) {
    this.allNetblocks.forEach((entity) => {
      this.removeFromAssessment(entity);
    });
    this.showSpinnerForTop1000 = true;
    this.showDetailsSpinner['netblock'] = true;
    this.allNetblocks = [];
    const params = {
      page: pageRange,
      page_size: this.selectAllLimit,
      searchKey: this.showDetailsSearch["netblock"],
      netblockType: "OWNED",
    };
    this.portfolioSurveyService
      .getNetblocksPaginated(this.domain, params)
      .subscribe(
        (data) => {
          if (data) {
            if (data["data"]) {
              data["data"].results.forEach((net) => {
                var entity = { ...net, ...{ name: net.net_block } };
                this.allNetblocks.push(entity);
                if(this.getRange(net.net_block)<=this.commonService.maxAllowedRange){
                  this.addToAssessment(entity,'netblock');
                }
              });
              this.showDetails["netblock"] = this.allNetblocks;
              this.modalRef.hide();
              this.showSpinnerForTop1000 = false;
              this.showDetailsSpinner['netblock'] = false;
              this.filterUniqueTargets()
            }
          }
        },
        (error) => {
          alert(
            "Error while fetching 1000 netblocks for page " +
              pageRange +
              ". Please select from listed netblocks"
          );
          this.modalRef.hide();
          this.showSpinnerForTop1000 = false;
          this.showDetailsSpinner['netblock'] = false;
        }
      );
  }

  public selectAllApplications(pageRange) {
    this.allApplications.forEach((entity) => {
      this.removeFromAssessment(entity);
    });
    this.showSpinnerForTop1000 = true;
    this.showDetailsSpinner['application'] = true;
    this.allApplications = [];
    const params = {
      page: pageRange,
      limit: this.selectAllLimit,
      searchKey: this.showDetailsSearch["application"],
    };
    this.portfolioSurveyService
      .getwebapplication(this.domain, params)
      .subscribe(
        (data) => {
          if (data["data"]) {
            data["data"].forEach((app) => {
              var entity = { ...app, ...{ name: app.source_url } };
              this.allApplications.push(entity);
              this.addToAssessment(entity);
            });
            this.showDetails["application"] = this.allApplications;
            this.modalRef.hide();
            this.showSpinnerForTop1000 = false;
            this.showDetailsSpinner['application'] = false;
            this.filterUniqueTargets()

          }
        },
        (error) => {
          alert(
            "Error while fetching 1000 netblocks for page " +
              pageRange +
              ". Please select from listed netblocks"
          );
          this.modalRef.hide();
          this.showSpinnerForTop1000 = false;
          this.showDetailsSpinner['application'] = false;
        }
      );
  }

  public openModal(event, template: TemplateRef<any>, entity) {
    if (event.target.checked) {
      this.remainingEntities = this.selectAllLimit - this.targetsForAssessment.length
      if (entity == "ipaddress") {
        // this.selectAllIps(1)
        this.modalAttackName = "ipaddress";
        this.modalAttackPageRange = Math.ceil(
          this.showDetailsCount["ip_address"] / this.selectAllLimit
        );
        this.allIpChecked = true;
        
        if (this.showDetailsCount['ip_address'] > this.remainingEntities)
          this.modalRef = this.modalService.show(template);
        else {
          this.showDetails["ip_address"] = []
          this.selectAttackRange(entity, 1)
        }
      } else if (entity == "domain") {
        //  this.selectAllDomains(1)
        this.modalAttackName = "domain";
        this.modalAttackPageRange = Math.ceil(
          this.showDetailsCount["domain"] / this.selectAllLimit
        );
        this.allDomainChecked = true;
        if (this.showDetailsCount['domain'] > this.remainingEntities)
          this.modalRef = this.modalService.show(template);
        else {
          this.showDetails["domain"] = []
          this.selectAttackRange(entity, 1)
        }
      } else if (entity == "subdomain") {
        // this.selectAllSubDomains(1)
        this.modalAttackName = "subdomain";
        this.modalAttackPageRange = Math.ceil(
          this.showDetailsCount["sub_domain"] / this.selectAllLimit
        );
        this.allSubdomainChecked = true;
        if (this.showDetailsCount['sub_domain'] > this.remainingEntities)
          this.modalRef = this.modalService.show(template);
        else {
          this.showDetails["sub_domain"] = []
          this.selectAttackRange(entity, 1)
        }
      } else if (entity == "netblock") {
        // this.selectAllNetblocks(1)
        this.modalAttackName = "netblock";
        this.modalAttackPageRange = Math.ceil(
          this.showDetailsCount["netblock"] / this.selectAllLimit
        );
        this.allNetblockChecked = true;
        if (this.showDetailsCount['netblock'] > this.remainingEntities)
          this.modalRef = this.modalService.show(template);
        else {
          this.showDetails["netblock"] = []
          this.selectAttackRange(entity, 1)
        }
      } else if (entity == "application") {
        // this.selectAllApplications(1)
        this.modalAttackName = "application";
        this.modalAttackPageRange = Math.ceil(
          this.showDetailsCount["application"] / this.selectAllLimit
        );
        this.allApplicationChecked = true;
        if (this.showDetailsCount['application'] > this.remainingEntities)
          this.modalRef = this.modalService.show(template);
        else {
          this.showDetails["application"] = []
          this.selectAttackRange(entity, 1)
        }
      } else if (entity == "ip_ports") {
        this.modalAttackName = "ip_ports";
        this.modalAttackPageRange = Math.ceil(
          this.showDetailsCount["ip_ports"] / this.selectAllLimit
        );
        this.allIpPortsChecked = true;
        
        if (this.showDetailsCount['ip_ports'] > this.remainingEntities)
          this.modalRef = this.modalService.show(template);
        else {
          this.showDetails["ip_ports"] = []
          this.selectAttackRange(entity, 1)
        }
      }
    } else {
      if (entity == "ipaddress") {
        this.allIps.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allIps = [];
        this.allIpChecked = false;
      } else if (entity == "domain") {
        this.allDomains.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allDomains = [];
        this.allDomainChecked = false;
      } else if (entity == "subdomain") {
        this.allSubdomains.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allSubdomains = [];
        this.allSubdomainChecked = false;
      } else if (entity == "netblock") {
        this.allNetblocks.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allNetblocks = [];
        this.allNetblockChecked = false;
      } else if (entity == "application") {
        this.allApplications.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allApplications = [];
        this.allApplicationChecked = false;
      } else if (entity == "ip_ports") {
        this.allIpPorts.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allIpPorts = [];
        this.allIpPortsChecked = false;
      }
    }
  }

  public selectAttackRange(entity, pageRange) {
    // this.unselectPreviousSelected(entity);
    if (entity == "ipaddress") {
      this.selectAllIps(pageRange);
    } else if (entity == "domain") {
      this.selectAllDomains(pageRange);
    } else if (entity == "subdomain") {
      this.selectAllSubDomains(pageRange);
    } else if (entity == "netblock") {
      this.selectAllNetblocks(pageRange);
    } else if (entity == "application") {
      this.selectAllApplications(pageRange);
    } else if (entity == "ip_ports") {
      this.selectAllIpPorts(pageRange);
    }
    
  }

  public unselectAll() {
    if (this.modalAttackName == 'ipaddress') {
      this.allIpChecked = false;
    } else if (this.modalAttackName == 'application') {
      this.allApplicationChecked = false;
    } else if (this.modalAttackName == 'domain') {
      this.allDomainChecked = false;
    } else if (this.modalAttackName == 'subdomain') {
      this.allSubdomainChecked = false;
    } else if (this.modalAttackName == 'netblock') {
      this.allNetblockChecked = false;
    } 
    this.modalRef.hide();
  }
  public unselectPreviousSelected(entity) {
    if (this.targetsForAssessment) {
      if (entity != 'application') {
        this.allApplicationChecked = false;
        this.allApplications.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allApplications = [];
      }
      if (entity != 'domain') {
        this.allDomainChecked = false;
        this.allDomains.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allDomains = [];
      }
      if (entity != 'ipaddress') {
        this.allIpChecked = false;
        this.allIps.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allIps = [];
      }
      if (entity != 'subdomain') {
        this.allSubdomainChecked = false;
        this.allSubdomains.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allSubdomains = [];
      }
      if (entity != 'netblock') {
        this.allNetblockChecked = false;
        this.allNetblocks.forEach((entity) => {
          this.removeFromAssessment(entity);
        });
        this.allNetblocks = [];
      }
    }
    this.resetTargetForAttackSurface.emit()
  }

  get_ip_ports(ip){
    //if (ip.protocols.length > 0){
    let ports = []
    ip.protocols.forEach((protocol) => {
      const port=protocol.split("/")[1]
      if (port){
        ports.push(port)
      }
    })
    //if (ports.length > 0) {
      if (ip.ip_address.includes(":")){
        let ip_v6_port_string = ip.ip_address + "-" + ports.join("|")
         return {"ports" : ports, "ip_ports" : ip_v6_port_string}

      } else {
        let ip_port_string = ip.ip_address + ":" + ports.join("|")
        return {"ports" : ports, "ip_ports" : ip_port_string}
      }
    //}
  //}
}
}
