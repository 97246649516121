import { Component, Input, OnInit } from '@angular/core';
import { PortfolioSurveyService } from '../../../_services';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public technologies:any = [];
  public showThis:string = "text-search";
  searching: boolean = false;
  public openDropdown = false;
  public missingSearchKey = false;
  public searchKey = '';
  public searchCompleted = false;
  public assetData = {};
  public searchDataloading = false;
  public showDetailsOf = null;
  @Input() mainDomain: any;
  public alreadyDisplayed = new Set()
  public today = new Date();
  public dateRange = "";
  public bsConfig = { 
    // dateInputFormat: 'DD/MM/YYYY',
    ranges: [{
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
      label: 'Last 7 Days'
    },{
      value: [new Date(new Date().setDate(new Date().getDate() - 15)), new Date()],
      label: 'Last 15 Days'
    },{
      value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      label: 'Last 30 Days'
    },{
      value: [new Date(new Date().setDate(new Date().getDate() - 60)), new Date()],
      label: 'Last 60 Days'
    },{
      value: [new Date(new Date().setDate(new Date().getDate() - 90)), new Date()],
      label: 'Last 90 Days'
    }]
  };
  public selectedEntity = null;
  public selectedEntityCount = null;
  public selectAllEntity = true;
  public continuesMonitoringStatus = false;
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public toastr: ToastrService
  ) { }

  public entityMap = {
    'subdomain': {
      "id": "Subdomain",
      "name": "Sub domains",
      "selected": true,
      "actions": ['delete', 'comment', 'obsIns'],
      "page":1,
      "callApi":true
    }, 'ip': {
      "id": "IP",
      "name": "IPs",
      "selected": true,
      "actions": ['delete', 'comment', 'obsIns'],
      "page":1,
      "callApi":true
    }, 'service': {
      "id": "Port",
      "name": "Open Ports",
      "selected": true,
      "actions": ['delete', 'comment'],
      "page":1,
      "callApi":true
    }, 'webapp': {
      "id": "WebApp",
      "name": "Applications",
      "selected": true,
      "actions": ['delete', 'comment', 'obsIns'],
      "page":1,
      "callApi":true
    }
  }
  public entityArray:any = [];
  ngOnInit(): void {
    if(!this.mainDomain){
      let profile = localStorage.getItem('profile');
      if(profile && profile.length) {
        profile = JSON.parse(profile);
        // this.mainDomain = {domain_name:profile['primary_domain']};
        let domain_name = profile['primary_domain']
        this.portfolioSurveyService.getMainDomainDetails(domain_name) .subscribe((data) => {
          this.mainDomain = data["data"];
          this.getTechnology(this.mainDomain['domain_name']);
          this.getContinuesMonitoringStatus(this.mainDomain['domain_name']);
          this.entityArray = Object.values(this.entityMap);
          this.getSelectedEntity();
        })
      }
    }
    this.entityArray = Object.values(this.entityMap);
    this.getSelectedEntity();
    if(this.mainDomain && this.mainDomain['domain_name']) this.getTechnology(this.mainDomain['domain_name']);
  }
  getContinuesMonitoringStatus(domain){
    this.portfolioSurveyService.getMainDomainContinuesMonitoringStatus(domain).subscribe(
      (data) => {
        console.log(data);
        if(data['status']=="pass"){
          this.continuesMonitoringStatus = (data['data'] && data['data']['enabled'])?data['data']['enabled']:false;
        }else{
          this.continuesMonitoringStatus = false;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getTechnology(domain) {
    this.portfolioSurveyService.getTechnology(domain).subscribe(
      (data) => {
        if (data) {
          this.technologies = data["data"];
        } else {
        }
      },
      (error) => {
        this.toastr.error("Unable to load technologies.");
      }
    );
  }
  changeSelected(method) {
    method.selected = !method.selected;
    this.getSelectedEntity();
  }

  getSearchResults(update=false) {
    this.searching = true;
    // let assetTypes = []
    if(!update){
      this.alreadyDisplayed = new Set()
      if (!this.searchKey) {
        this.missingSearchKey = true;
        this.toastr.error("Please enter valid keyword to perform search.");
        return
      }    
      this.assetData = {}
      this.showDetailsOf = null;
      this.entityArray.map(x => { x.callApi = true; x.page = 1;})
    }
    this.entityArray.forEach(e => {
      if (e.selected && e.callApi) {
        // assetTypes.length ? assetTypes.push(e.id) : assetTypes = [e.id]
        this.portfolioSurveyService
          .getSearchResults(this.mainDomain.domain_name, this.searchKey, e.id, e.page, this.dateRange)
          .subscribe((data) => {
            e.callApi = false;
            this.searching = false;
            if (data && data['status'] == 'pass') {
              this.assetData[e.id] = data['data'];
              if (e.id == 'Port') {
                this.assetData[e.id+"_list"] = data['data'].map(value => value['asset_id']);
              } else {
                this.assetData[e.id+"_list"] = data['data'].map(value => value['assetname']);
              } 
              this.assetData[e.id+"_count"] = data['count'];
            }
          }, (error) => {
            this.toastr.error("Api failed for search of " + e.name);
            delete this.assetData[e.id];
          });
      }
    })
  }
  clearSearch() {
    this.entityArray.forEach(e => e.selected = false)
    this.assetData = []
    this.alreadyDisplayed = new Set()
    this.searchKey = "";
    this.showDetailsOf = null;
    this.dateRange = "";
    this.selectAllEntity = false;
    this.getSelectedEntity();
  }
  isValidKeyword() {
    let key = "";
    if (this.searchKey) {
      key = this.searchKey.trim();
    }
    return (key && this.selectedEntityCount>0) ? true : false;
  }
  assetDataCount(entity) {
    if (this.assetData[entity] && this.assetData[entity].length) {
      return this.assetData[entity][0]['total'] || 0
    }
    return 0
  }
  getListOfAsset(assetT) {
    return this.assetData[assetT+'_list']
  }
  goToPage(val,entity){
    entity.page = val;
    entity.callApi = true;
    this.getSearchResults(true);
  }

  // checkUncheckEntity(){
  //   let selectAllEntity = this.selectAllEntity;
  //   this.entityArray.map(function(x){
  //     x.selected = selectAllEntity;
  //   })
  //   this.getSelectedEntity();
  // }
  getSelectedEntity(){
    let tempSelectedEntity = 0;
    this.entityArray.map(function(x){
      if(x.selected) tempSelectedEntity++;
    })
    let temptext = "";
    let entityString = (tempSelectedEntity>1)?"entities":"entity";
    temptext = (this.entityArray.length==tempSelectedEntity)?"All "+entityString+" selected":tempSelectedEntity+" "+entityString+" selected"; 
    this.selectedEntity = temptext;
    this.selectedEntityCount = tempSelectedEntity;
  }
}