import {
  Component,
  OnInit,
  DoCheck,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { CommonService } from "../../_services/common.service";
import { TabsetComponent } from "ngx-bootstrap/tabs";

@Component({
  selector: "app-executive-report",
  templateUrl: "./executive-report.component.html",
  styleUrls: ["./executive-report.component.scss"],
})
export class ExecutiveReportComponent implements OnInit, DoCheck {
  @ViewChild(TabsetComponent) tabSet: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService
  ) {}

  public domains;
  public cveDetails;
  public domain;
  public digitalRisk = [];
  public screennshots = [
    "https://i2.wp.com/hackersonlineclub.com/wp-content/uploads/2018/02/Networking.png?resize=662%2C396&ssl=1",
    "https://i.ytimg.com/vi/9N2fkh3fa10/maxresdefault.jpg",
  ];
  public showImage: boolean = false;
  public imageIndex: number = -1;
  public phishingDomains: any;
  public loadingRisk = false;
  public loadingPhishing = false;
  public totalLeakCredentialsCount;
  public vulnerabilitiesCount;
  public phishingCount;
  public maliciousCount;
  public leakedContentCount;
  public leakedContentCountNewValue: any;
  public leakedContents;
  public webDocumentCount;
  public webDocumentCountNewValue: any;
  public webDocuments;
  public total_cve_count;
  public subDomainsCount;
  public subDomainsCountOld;
  public whoisDetailsForRds;
  public automatedDone: boolean = false;
  public relatedDomainsHostsOld: any;
  public subDomains;
  public mainDomain;
  public maliciousList = [];
  public totalLeakCredentialsCountOld;
  public domainsLeakedCredentials: any;
  public relatedDomainsLeakCredentialsOld: any;
  public isUserTrial: boolean = false;
  public show3RdPartyTab: boolean = false;
  private userDomains = [];
  public buckets: any;
  public buckets_count: number;
  public codeLeaks: any;
  public showExecutiveReport: boolean = true;
  public showThis = "domains";
  public noPremission: boolean = false;
  public startDay: number = 7;
  public openSlider: boolean = false;
  public services;
  public uploadedIps;
  public thirdPartyData: any = [];
  public uploadedIpsOld: any;
  public servicesOld: any;
  public phishingDomainsOld: any;
  public relatedDomainsCount: number;
  public relatedDomainsCountOld: number;
  public servicesCount: number;
  public servicesCountOld: number;
  public ipsCount: number;
  public ipsCountOld: number;
  public subDomainsOld;
  public ips_with_vul_mal: any;
  public ips_with_vul_mal_old: any;
  public webApplication: any;
  public mobileApplication: any;
  public netblocks: any = [];
  public webApplicationOld: any;
  public mobileApplicationOld: any;
  public netblocksOld: any = [];
  public netblocks_count: number = 0;
  public appCount: number = 0;
  public mobileAppCount: number = 0;
  public maliciousPercentage;
  public phishingPercentage;
  public bucketPercentage;
  public topRisk;
  public breachedCredentials;
  public phishingDomainData;

  ngOnInit() {
    this.getDomainName();
  }

  getVulnerabilityDeepc() {
    this.portfolioSurveyService.getVulnerabilityDeepc(this.domain).subscribe(
      (data: any) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          data["data"] = data["data"].filter((d) => {
            return d["cve_score"] == 99;
          });
          this.cveDetails = data["data"];
          console.log("this.cveDetails....", this.cveDetails);
          this.total_cve_count = data["vulnerabilities_count"];
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  leakContentCountChange(value) {
    this.leakedContentCountNewValue = value;
  }

  ngDoCheck() {
    // this.leakedContentCount = this.leakedContentCountNewValue ? this.leakedContentCountNewValue : '0';
  }

  getRelatedDomainsLeakCredentials() {
    this.portfolioSurveyService
      .getRelatedDomainsLeakCredentials(this.domain)
      .subscribe(
        (data: any) => {
          if (data) {
            if (data["leak_credentials"]) {
              this.setUserAccount(data["userAccount"]);
              this.totalLeakCredentialsCount = data["leak_credentials"][
                "total_leaked_credentials"
              ]
                ? data["leak_credentials"]["total_leaked_credentials"]
                : 0;
              this.domainsLeakedCredentials = data["leak_credentials"]["data"];
              this.mainDomain = data.domain;
              if (
                data["leak_credentials"]["status"] == "fail" ||
                !this.totalLeakCredentialsCount
              ) {
                this.getAutomatedData();
              } else {
                this.domainsLeakedCredentials.forEach((element) => {
                  this.commonService.checkCreatedAndUpdated(element);
                });
                this.relatedDomainsLeakCredentialsOld = this.domainsLeakedCredentials.slice();
                this.totalLeakCredentialsCountOld = this.totalLeakCredentialsCount;
              }
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  getMaliciousRisk() {
    this.loadingRisk = true;
    this.portfolioSurveyService.getMaliciousRisk(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.loadingRisk = false;
          data["data"] = data["data"].filter((d) => {
            return d["confidence"] == 99;
          });
          this.maliciousList = data["data"];
          this.maliciousCount = data["count"];
          this.maliciousPercentage = this.percentage(
            this.maliciousList.length,
            this.maliciousCount
          );
        }
      },
      (error) => {
        alert("Error while loading risks. Please try again after sometime!");
        this.errorForDomain(error);
      }
    );
  }

  getDigitalRisk() {
    this.loadingRisk = true;
    this.portfolioSurveyService.getDigitalRisk(this.domain).subscribe(
      (data: any) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.digitalRisk = data.data;
          this.loadingRisk = false;
          data["leak_contents"] = data["leak_contents"].filter((d) => {
            return d["confidence"] == 99;
          });
          this.leakedContents = data["leak_contents"];
          this.leakedContentCount = data["count_leaked_content"];
          this.webDocuments = data["cloud_documents"];
          data["cloud_documents"] = data["cloud_documents"].filter((d) => {
            return d["confidence"] == 99;
          });
          this.webDocuments = data["cloud_documents"];
          this.webDocumentCount = this.webDocuments.length;
        }
      },
      (error) => {
        alert("Error while loading risks. Please try again after sometime!");
        this.errorForDomain(error);
      }
    );
  }

  screenshots(i) {
    this.showImage = true;
  }

  getPhishing() {
    this.loadingPhishing = true;
    this.portfolioSurveyService.getPhishingDomains(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.loadingPhishing = false;
          data["data"] = data["data"].filter((d) => {
            return d["fraud_score"] == 99;
          });
          this.phishingDomains = data["data"];
          this.phishingCount = data["count"];
          this.phishingPercentage = this.percentage(
            this.phishingDomains.length,
            this.phishingCount
          );
        }
      },
      (error) => {
        this.loadingPhishing = false;
        this.errorForDomain(error);
      }
    );
  }

  errorForDomain(error) {
    if (error["status"] == 403) {
      alert(
        "Forbidden! You are not allowed to perform this action. Please contact us at contact@firecompass.com"
      );
      this.router.navigate(["/new/self-domains"]);
    }
  }
  updateTotalCvCount(count) {
    this.total_cve_count = count;
    // if (count != undefined && count != null)
    // 	document.getElementById('totalCveCount').innerText = count;
  }

  getCveDetailsForDomains() {
    this.portfolioSurveyService
      .getCveDetailsForDomains(this.domain)
      .subscribe((data) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.cveDetails = data["data"];
          this.total_cve_count = data["total_cve_count"];
        }
      });
  }
  getBucketsForDomains() {
    this.portfolioSurveyService.getBuckets(this.domain).subscribe((data) => {
      if (data && data["status"] == "pass") {
        this.setUserAccount(data["userAccount"]);
        data["data"] = data["data"].filter((d) => {
          return d["confidence"] == 99;
        });
        this.buckets = data["data"];
        this.buckets_count = data["bucket_count"];
        this.bucketPercentage = this.percentage(
          this.buckets.length,
          this.buckets_count
        );
      }
    });
  }
  getRelatedDomainsSubDomains() {
    this.portfolioSurveyService
      .getRelatedDomainsSubDomains(this.domain)
      .subscribe(
        (data: any) => {
          if (data && data["domain"] && data["domain"]["status"] == "pass") {
            this.setUserAccount(data["userAccount"]);
            this.domains = data.related_domains["data"];
            // this.domains.forEach(domain=>{
            // 	if(domain.hosts && domain.hosts.length) {
            // 		this.subDomains = [...this.subDomains, ...domain.hosts];
            // 	}
            // })
            this.relatedDomainsCount = data.related_domains[
              "related_domains_count"
            ]
              ? data.related_domains["related_domains_count"]
              : this.domains.length;
            this.relatedDomainsCountOld = data.related_domains[
              "related_domains_count"
            ]
              ? data.related_domains["related_domains_count"]
              : this.domains.length;
            this.mainDomain = data.domain["data"];
            this.subDomainsCount = data.related_domains.total_sub_domain_count;
            this.subDomainsCountOld = this.subDomainsCount;
            this.whoisDetailsForRds = data["additional_RD_details"];
            if (
              data["related_domains"]["status"] == "fail" ||
              !this.domains ||
              !this.domains.length ||
              !this.subDomainsCount
            ) {
              this.getAutomatedData();
            } else {
              this.domains.forEach((element) => {
                this.commonService.checkCreatedAndUpdated(element);
                this.calculateCreatedAndUpdated(element.hosts);
              });
              this.relatedDomainsHostsOld = JSON.parse(
                JSON.stringify(this.domains)
              );
            }
          } else {
            if (
              data["domain"] &&
              data["domain"]["message"] == "Failure while getDomainByNameDb"
            ) {
              // start scan
              this.portfolioSurveyService
                .startScanForDomain(this.domain)
                .subscribe((data) => {
                  if (data["status"] == "pass") {
                    alert(
                      "Scan started for new domain or scan is under progress.. Please re-visit after some time."
                    );
                  } else {
                    alert(data["message"]);
                  }
                  this.router.navigate(["/new/self-domains"]);
                });
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }

  getAutomatedData() {
    // if (!this.automatedDone) {
    this.automatedDone = true;
    // 	this.portfolioSurveyService.getAutomatedDataForDomain(this.domain).subscribe(data => {
    // 		if (data) {
    // 			console.log(data);
    // 		}
    // 	})
    // }
  }

  getUserSelfDomain() {
    this.portfolioSurveyService
      .getDomainsSearchForUser()
      .subscribe((data: any) => {
        var index = -1;
        data.forEach((d, i) => {
          if (d["domain"] == this.domain && d["domain_type"] == "self") {
            this.show3RdPartyTab = true;
            index = i;
          }
        });
        if (index != -1) {
          data.splice(index, 1);
        }
        this.userDomains = data;
      });
  }
  public getCodeLeaksForDomain() {
    this.portfolioSurveyService
      .getCodeLeaks(this.domain)
      .subscribe((data: any) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          data["data"] = data["data"].filter((d) => {
            return d["confidence"] == 99;
          });
          this.codeLeaks = data["data"];
        }
      });
  }

  public getDomainName() {
    this.route.queryParams.subscribe((params) => {
      if (params && params["domain"]) {
        this.domain = params["domain"];
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { domain: this.domain },
          queryParamsHandling: "merge",
          skipLocationChange: true,
        });
        this.getAllData();
      } else {
        this.portfolioSurveyService
          .getDomainsSearchForUser()
          .subscribe((data: any) => {
            data.filter((d) => {
              if (d["domain_type"] == "self") {
                this.domain = d["domain"];
              }
            });
            this.getAllData();
          });
      }
    });
  }
  public getAllData() {
    if (this.domain) {
      this.getRelatedDomainsSubDomains();
      // this.getIps();
      this.getIpsDetailsFromDeepc();
      this.getApplications();
      this.getCveDetailsForDomains();
      this.getwebapplication();
      this.getmobileapplication();
      // this.getAutomatedData();
      this.getNetblocks();
      this.getSubDomain();
      this.getPhishing();
      this.getDigitalRisk();
      this.getBucketsForDomains();
      // this.getCveDetailsForDomains()
      this.getVulnerabilityDeepc();
      this.getMaliciousRisk();
      this.getRelatedDomainsLeakCredentials();
      this.getUserSelfDomain();
      this.getCodeLeaksForDomain();
      this.getTopRisk();
      this.getLeakedCreds();
    } else {
      alert(
        "No domain associated with you, Please write us on contact@firecompass.com"
      );
    }
  }

  setUserAccount(userAccount) {
    if (userAccount && userAccount == "trial") {
      this.isUserTrial = true;
    }
  }

  getSubDomain() {
    this.portfolioSurveyService.getSubDomain(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.subDomains = data["data"];
          this.calculateCreatedAndUpdated(this.subDomains);
          this.subDomainsOld = this.commonService.getJsonCopy(this.subDomains);
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getwebapplication() {
    this.portfolioSurveyService.getwebapplication(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.webApplication = data["data"];
          this.appCount = this.appCount + this.webApplication.length;
          this.calculateCreatedAndUpdated(this.webApplication);
          this.webApplicationOld = this.webApplication.slice();
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getmobileapplication() {
    this.portfolioSurveyService.getmobileapplication(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.mobileApplication = data["data"];
          this.appCount = this.appCount + this.mobileApplication.length;
          this.calculateCreatedAndUpdated(this.mobileApplication);
          this.mobileApplicationOld = this.mobileApplication.slice();
        } else {
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getIpsDetailsFromDeepc() {
    this.portfolioSurveyService.getIpsDetailsFromDeepc(this.domain).subscribe(
      (data) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.ips_with_vul_mal = data["data"];
          this.ipsCount = data["ips_count"]
            ? data["ips_count"]
            : data["data"].length;
          this.ipsCountOld = data["ips_count"]
            ? data["ips_count"]
            : data["data"].length;
          this.calculateCreatedAndUpdated(this.ips_with_vul_mal);
          this.ips_with_vul_mal_old = this.ips_with_vul_mal.slice();
        } else {
          this.getIps();
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getIps() {
    this.portfolioSurveyService.getIps(this.domain).subscribe(
      (data: any) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.uploadedIps = data["ips"]["data"];
          this.mainDomain = data.domain["data"];
          if (data["ips"]["status"] == "fail" || !this.uploadedIps.length) {
            this.getAutomatedData();
          } else {
            this.calculateCreatedAndUpdated(this.uploadedIps);
            this.uploadedIpsOld = this.uploadedIps.slice();
          }
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getApplications() {
    this.portfolioSurveyService.getApplications(this.domain).subscribe(
      (data: any) => {
        if (data) {
          this.setUserAccount(data["userAccount"]);
          this.services = data["applications"]["data"];
          this.servicesCount = data["applications"]
            ? data["applications"]["count"]
            : data["applications"]["data"].length;
          this.servicesCountOld = data["applications"]
            ? data["applications"]["count"]
            : data["applications"]["data"].length;
          this.mainDomain = data.domain["data"];
          if (
            data["applications"]["status"] == "fail" ||
            !this.services.length
          ) {
            this.getAutomatedData();
          } else {
            this.calculateCreatedAndUpdated(this.services);
            this.servicesOld = this.services.slice();
          }
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  calculateCreatedAndUpdated(domain_list) {
    domain_list.forEach((element) => {
      this.commonService.checkCreatedAndUpdated(element);
    });
  }

  getNetblocks() {
    this.portfolioSurveyService.getNetblocks(this.domain).subscribe(
      (data) => {
        if (data && data["status"] == "pass") {
          this.setUserAccount(data["userAccount"]);
          this.netblocks = data["data"];
          this.netblocks_count = data["netblock_count"];
          this.calculateCreatedAndUpdated(this.netblocks);
          this.netblocksOld = this.netblocks.slice();
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }
  percentage(num, per) {
    var a = Math.round((num * 100) / per);
    return a;
  }
  public getTopRisk() {
    this.portfolioSurveyService
      .getTopRisk(this.domain)
      .subscribe((data: any) => {
        this.topRisk = data.data;
      });
  }

  getLeakedCreds() {
    console.log(this.domain);
    this.portfolioSurveyService
      .getAllLeakedCredsForDomain(this.domain)
      .subscribe(
        (data) => {
          console.log(data);
          if (data) {
            this.setUserAccount(data["userAccount"]);
            if (data["status"] == "pass" && data["data"].length) {
              console.log("I am in");
              this.breachedCredentials = data["data"].filter((a) => {
                if (a["confidence"] && a["confidence"] == 99) {
                  return a;
                }
              });
              console.log(this.breachedCredentials);
            } else {
              this.calculateCreatedAndUpdated(this.services);
              this.servicesOld = this.services.slice();
            }
          }
        },
        (error) => {
          this.errorForDomain(error);
        }
      );
  }
}
