import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import * as d3 from "d3";
import * as moment from "moment";
import { LoadingPipe } from "app/pipes/loading.pipe";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { CommonService } from "../../_services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DwInsightsService } from "./../../_services/dw-insights.service";
import { SortPipe } from "app/pipes/sort.pipe";
import { ToastrService } from "ngx-toastr";
import { MultiErrorEmitService } from 'app/_services/multi-error-emit.service';
@Component({
  selector: "app-dashboard-timeline",
  templateUrl: "./dashboard-timeline.component.html",
  styleUrls: ["./dashboard-timeline.component.scss"],
})
export class DashboardTimelineComponent implements OnInit {

  // variables
  public sortPipe = new SortPipe();
  totalEntityCount: any;
  relatedDomainsCount: any;
  subdomainsCount: any;
  netblocksCount: any;
  ipsCount: any;
  appCount: any;
  servicesCount: any;
  totalLeakCredentialsCount: any;
  apiDiscovryCount: any
  serviceTimelineData: {};
  netblockTimelineData: {};
  ipTimelineData: {};
  topRiskIPs: any = [];
  IpsIspList: any = [];
  IpsTagsList: any = [];
  subdomainTimelineData: {};
  relatedDomainTimelineData: {};
  webAppTimelineData: {}
  leakedCredTimelineData: {}
  apiDiscovryTimelineData: {}
  openDropdown = false
  showThis = 'inscope'

  public viewEntities = [0, 1, 2, 3, 4]
  public totalEntities = 8;
  public currentCounter = 1;
  public graphDataPoints = 5;
  public domainStats: any;
  public subdomainStats: any;
  public netblckStats: any;
  public domainList = [];
  public subdomainsList = [];
  public netblockList = [];
  public ispToTypeIpsDropDown = [];
  public applicationTechnology = [];
  public applicationCveCount = [];
  public leakcredPasswordCount = [];
  public recentApiDiscovery = [];
  public apiDiscoveryMethodsCount = [];
  public leakcredList = []
  public inscopeConfig: string;
  public inscopeFlag
  selectedEntityValue: any;

  public loadingPipe = new LoadingPipe();
  public selectedEntityData;
  public entityToShow = [
    {
      name: "Domains",
      value: "domain",
    },
    {
      name: "Sub Domains",
      value: "subdomain",
    },
    {
      name: "Active IPs",
      value: "ip",
    },
    {
      name: "Netblocks",
      value: "netblock",
    },
    {
      name: "Services",
      value: "services",
    },
    {
      name: "Web Applications",
      value: "application",
    },
    {
      name: "Leaked Credentials",
      value: "leakcred",
    },
    {
      name: "API Discovery",
      value: "apiDiscovery",
    },
  ];
  viewMap = {
    "domain": "domains",
    "subdomain": "sub-domains",
    "ip": "ips",
    "netblock": "netblocks",
    "application": "application",
    "leakcred": "leaked-credentials",
    "services": "service",
    "apiDiscovery": "api-discovery"
  }
  public dataLoaders: any = {
    "domain": true,
    "subdomain": true,
    "ip": true,
    "netblock": true,
    "app": true,
    "lk": true,
    "services": true
  }
  public dataValues: any = {
    "domain": null,
    "subdomain": null,
    "ip": null,
    "netblock": null,
    "app": null,
    "lk": null,
    "services": null
  }
  @Input() selectedEntity = this.entityToShow[0];
  public dateRange = [];
  public domain;
  public mainDomain;
  public gaugemap: any = {};
  public brandPrimary = "#20a8d8";
  public brandSuccess = "#4dbd74";
  public brandInfo = "#63c2de";
  public brandWarning = "#f8cb00";
  public brandDanger = "#f86c6b";
  public brandMusterd = "#dbb634";
  public brandBlue = "#5890d5";
  public brandGreen = "#50ba49";


  // dropdown buttons
  public status: { isopen } = { isopen: false };
  public getServiceCountDetails: any;
  public getService: any;
  public getServicesForOpenPorts: any;
  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  // convert Hex to RGBA
  public convertHex(hex: string, opacity: number) {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const rgba = "rgba(" + r + ", " + g + ", " + b + ", " + opacity / 100 + ")";
    return rgba;
  }

  // events
  public chartClicked(e: any): void {
    // console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }

  // domainDW
  public domainDWData: Array<any> = [
    {
      data: [],
      created: [],
      updated: [],
      archived: [],
      label: "Domains",
    },
  ];
  public domainDWLabels: Array<any> = [];
  public domainDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "transparent",
            zeroLineColor: "transparent",
          },
          ticks: {
            fontSize: 2,
            fontColor: "transparent",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
            // min: 40 - 5,
            // max: 84 + 5,
          },
        },
      ],
    },
    elements: {
      line: {
        tension: 0.00001,
        borderWidth: 1,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';
          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public domainDWColours: Array<any> = [
    {
      // grey
      backgroundColor: this.brandPrimary,
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  public domainDWLegend = false;
  public domainDWType = "line";

  // Total Entity
  public totalEntityDWData: Array<any> = [
    {
      data: [0, 0, 0, 0, 0, 0, 0],
      created: [0, 0, 0, 0, 0, 0, 0],
      updated: [0, 0, 0, 0, 0, 0, 0],
      archived: [0, 0, 0, 0, 0, 0, 0],
      label: [],
    },
  ];
  public totalEntityDWLabels: Array<any> = [];
  public totalEntityDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "transparent",
            zeroLineColor: "transparent",
          },
          ticks: {
            fontSize: 2,
            fontColor: "transparent",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
            // min: 40 - 5,
            // max: 84 + 5,
          },
        },
      ],
    },
    elements: {
      line: {
        tension: 0.00001,
        borderWidth: 1,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }
        function getKeyByValue(object, value) {
          return Object.keys(object).find(key => object[key] === value);
        }
        if (tooltipModel.body) {
          var titleLines = tooltipModel.title || [];
          var bodyLines = tooltipModel.body.map(getBody);
          var innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';
          titleLines.forEach(function (body, i) {
            let parsed = bodyLines[0][0].split('}:');
            let parsedData = JSON.parse(parsed[0] + '}');
            let keyItem: any = (getKeyByValue(parsedData.lable, body));
            innerHtml += '<tr><td>Domain: ' + parsedData['domains'][keyItem] + '</td></tr>';
            innerHtml += '<tr><td>Subdomains: ' + parsedData['subdomain'][keyItem] + '</td></tr>';
            innerHtml += '<tr><td>IPs: ' + parsedData['ips'][keyItem] + '</td></tr>';
            innerHtml += '<tr><td>Netblocks: ' + parsedData['netblock'][keyItem] + '</td></tr>';
            innerHtml += '<tr><td>Services: ' + parsedData['services'][keyItem] + '</td></tr>';
            innerHtml += '<tr><td>Web Applications: ' + parsedData['applications'][keyItem] + '</td></tr>';
            innerHtml += '<tr><td>Leaked Credentials: ' + parsedData['leaked'][keyItem] + '</td></tr>';
            innerHtml += '<tr><td>API Discovery: ' + parsedData['api'][keyItem] + '</td></tr>';
            innerHtml += '<tr><td>Total Entities: ' + parsed[1] + '</td></tr>';
          });
          innerHtml += '</tbody>';
          var tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public totalEntityDWColours: Array<any> = [{
    borderColor: '#ffffff',
    backgroundColor: 'rgba(255,255,255,0.6)'
  },];
  public totalEntityDWLegend = false;
  public totalEntityDWType = 'line';
  public totalCallTime = 0;
  public totalCallTimeCount = 0;
  // subdomainDW
  public subdomainDWData: Array<any> = [
    {
      data: [],
      created: [],
      updated: [],
      archived: [],
      label: "Sub-domain",
    },
  ];
  public subdomainDWLabels: Array<any> = [];
  public subdomainDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "transparent",
            zeroLineColor: "transparent",
          },
          ticks: {
            fontSize: 2,
            fontColor: "transparent",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
          },
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 1,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';
          bodyLines.forEach(function (body, i) {
            if (body && body[0]) {
              body[0] = body[0].replaceAll('Sub-domain', 'Sub Domains');
            }
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public subdomainDWColours: Array<any> = [
    {
      // grey
      backgroundColor: this.brandSuccess,
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  public subdomainDWLegend = false;
  public subdomainDWType = "line";

  // ipDW
  public ipDWData: Array<any> = [
    {
      data: [],
      created: [],
      updated: [],
      archived: [],
      label: "IP",
    },
  ];
  public ipDWLabels: Array<any> = [];
  public ipDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "transparent",
            zeroLineColor: "transparent",
          },
          ticks: {
            fontSize: 2,
            fontColor: "transparent",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
          },
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 1,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';
          bodyLines.forEach(function (body, i) {
            if (body && body[0]) {
              body[0] = body[0].replaceAll('IP', 'IPs');
            }
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public ipDWColours: Array<any> = [
    {
      backgroundColor: "#F6C014",
      // backgroundColor: "rgba(255,255,255,.2)",
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  public ipDWLegend = false;
  public ipDWType = "line";

  //netblockDW
  public netblockDWData: Array<any> = [
    {
      data: [],
      created: [],
      updated: [],
      archived: [],
      label: "Netblock",
    },
  ];
  public netblockDWLabels: Array<any> = [];
  public netblockDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';
          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public netblockDWColours: Array<any> = [
    {
      // backgroundColor: "rgba(255,255,255,.2)",
      backgroundColor: this.brandInfo,
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  public netblockDWLegend = false;
  public netblockDWType = "line";
  //serviceDW
  public serviceDWData: Array<any> = [
    {
      data: [],
      created: [],
      updated: [],
      archived: [],
      label: "Service",
    },
  ];
  public serviceDWLabels: Array<any> = [];
  public serviceDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';
          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public serviceDWColours: Array<any> = [
    {
      backgroundColor: this.brandDanger,
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  public serviceDWLegend = false;
  public serviceDWType = "line";

  //Leaked cred
  public leakedCredDWData: Array<any> = [
    {
      data: [],
      created: [],
      updated: [],
      archived: [],
      label: "leakedCred",
    },
  ];
  public leakedCredDWLabels: Array<any> = [];
  public leakedCredDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';
          bodyLines.forEach(function (body, i) {
            if (body && body[0]) {
              body[0] = body[0].replaceAll('leakedCred', 'Leaked Credentials');
            }
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public leakedCredDWColours: Array<any> = [
    {
      // backgroundColor: "rgba(255,255,255,.2)",
      backgroundColor: this.brandBlue,
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  public leakedCredDWLegend = false;
  public leakedCredDWType = "line";
  //Web-apps
  public webAppDWData: Array<any> = [
    {
      data: [],
      created: [],
      updated: [],
      archived: [],
      label: "webApp",
    },
  ];
  public webAppDWLabels: Array<any> = [];
  public webAppDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';

          bodyLines.forEach(function (body, i) {
            if (body && body[0]) {
              body[0] = body[0].replaceAll('webApp', 'Web Applications');
            }
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public webAppDWColours: Array<any> = [
    {
      // backgroundColor: "rgba(255,255,255,.2)",
      backgroundColor: this.brandMusterd,
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  public webAppDWLegend = false;
  public webAppDWType = "line";
  //APIs
  public apiDiscoveryDWData: Array<any> = [
    {
      data: [],
      created: [],
      updated: [],
      archived: [],
      label: "Netblock",
    },
  ];
  public apiDiscoveryDWLabels: Array<any> = [];
  public apiDiscoveryDWOptions: any = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl: any = document.getElementById('chartjs-tooltip');
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          let innerHtml = '<thead>';
          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';
          bodyLines.forEach(function (body, i) {
            if (body && body[0]) {
              body[0] = body[0].replaceAll('Netblock', 'API Discovery');
            }
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';
          let tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        var position = this._chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.background = 'rgba(0,0,0,0.9)';
        tooltipEl.style['border-radius'] = '5px';
        tooltipEl.style.color = '#fff';
      }
    }
  };
  public apiDiscoveryDWColours: Array<any> = [
    {
      // backgroundColor: "rgba(255,255,255,.2)",
      backgroundColor: this.brandGreen,
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  public apiDiscoveryDWLegend = false;
  public apiDiscoveryDWType = "line";

  public lineChart1DWData: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: "Domans",
    },
  ];
  public lineChart1DWColours: Array<any> = [
    {
      // grey
      backgroundColor: "#B4EBF9",
      borderColor: "rgba(255,255,255,.55)",
    },
  ];
  // mainChart

  public random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public mainChartElements = 10;
  public mainChartData1: Array<number> = [
    1000,
    940,
    553,
    833,
    460,
    700,
    500,
    750,
    922,
  ];
  public mainChartData2: Array<number> = [
    500,
    713,
    210,
    430,
    310,
    599,
    245,
    466,
  ];

  public mainChartData: Array<any> = [
    {
      data: this.mainChartData1,
      label: "Overall",
    },
    {
      data: this.mainChartData2,
      label: "Active",
    },
  ];
  /* tslint:disable:max-line-length */
  public mainChartLabels: Array<any> = [
    "Apr 18-24",
    "Apr 11-17",
    "Apr 4-10",
    "Mar 28-Apr 03",
    "Mar 27-21",
  ];
  /* tslint:enable:max-line-length */
  public mainChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            callback: function (value: any) {
              return value;
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 8,
            stepSize: Math.ceil(250 / 5),
            max: 1200,
          },
        },
      ],
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      },
    },
    legend: {
      display: false,
    },
  };
  public mainChartColours: Array<any> = [
    {
      // brandInfo
      backgroundColor: this.convertHex(this.brandInfo, 10),
      borderColor: this.brandInfo,
      pointHoverBackgroundColor: "#fff",
    },
    {
      // brandSuccess
      backgroundColor: "transparent",
      borderColor: this.brandSuccess,
      pointHoverBackgroundColor: "#fff",
    },
    {
      // brandDanger
      backgroundColor: "transparent",
      borderColor: this.brandDanger,
      pointHoverBackgroundColor: "#fff",
      borderWidth: 1,
      borderDash: [8, 5],
    },
  ];
  public mainChartLegend = false;
  public mainChartType = "line";
  // VARIABLE ENDS
  public relatedSubdomainCountList = [];
  @Input() showingFrom:any = "";
  @Input() showCards:boolean = true;
  @Input() showDescTable:boolean = true;
  @Input() clickAction:string = 'changeComponent';
  constructor(
    public commonService: CommonService,
    public dwInsightsService: DwInsightsService,
    public portfolioSurveyService: PortfolioSurveyService,
    public router: Router,
    public route: ActivatedRoute,
    public toaster: ToastrService,
    public multiError: MultiErrorEmitService
  ) { }
  ngOnInit(): void {
    var startDate = moment().startOf("isoWeek");
    var endDate = moment().endOf("isoWeek");
    let previousWeeksStartTime = startDate,
      previousWeeksEndTime = endDate,
      weekNumber = moment(moment().toDate(), "MM-DD-YYYY").isoWeek();
    this.dateRange.push({
      startDate: startDate.format("MMM DD"),
      endDate: endDate.format("DD"),
      endDateWithMoth: endDate.format("MMM DD"),
      weekNumber: weekNumber,
    });
    for (var i = 0; i <= this.graphDataPoints; i++) {
      previousWeeksEndTime = previousWeeksEndTime.subtract(7, "days");
      previousWeeksStartTime = previousWeeksStartTime.subtract(7, "days");
      this.dateRange.push({
        startDate: previousWeeksStartTime.format("MMM DD"),
        endDate: previousWeeksEndTime.format("DD"),
        endDateWithMoth: previousWeeksEndTime.format("MMM DD"),
        weekNumber: weekNumber - i - 1 < 1 ? 52 - i : weekNumber - i - 1,
      });
    }
    for (let i = 0; i <= this.mainChartElements; i++) {
      this.mainChartData1.push(this.random(50, 200));
      this.mainChartData2.push(this.random(80, 100));
    }
    this.draw();
    this.getDomain();
  }
  public getDomain() {
    this.route.queryParams.subscribe((params) => {
      if (params && params["domain"]) {
        this.domain = params["domain"];
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { domain: this.domain },
          queryParamsHandling: "merge",
          skipLocationChange: true,
        });
        if (this.domain) {
          this.portfolioSurveyService
            .getMainDomainDetails(this.domain)
            .subscribe(
              (data) => {
                this.mainDomain = data["data"];
                if (this.mainDomain) {
                  localStorage.setItem("scope_config", this.mainDomain['scope_config'])
                  this.inscopeConfig = localStorage.getItem('scope_config')
                  this.getAllData();
                }
              },
              (error) => {
                this.errorForDomain(error);
              }
            );
        }
      } else {
        this.portfolioSurveyService.getDomainsSearchForUser().subscribe(
          (data: any) => {
            data.filter((d) => {
              if (d["domain_type"] == "self") {
                this.domain = d["domain"];
              }
            });
            if (this.domain) {
              this.portfolioSurveyService
                .getMainDomainDetails(this.domain)
                .subscribe((data) => {
                  this.mainDomain = data["data"];
                  if (this.mainDomain) {
                    localStorage.setItem("scope_config", this.mainDomain['scope_config'])
                    this.inscopeConfig = localStorage.getItem('scope_config')
                    this.getAllData();
                  }
                });
            }
          },
          (error) => {
            this.errorForDomain(error);
          }
        );
      }
    });
  }
  getAllData() {
    this.getRelatedDomainCount();
    this.getRelatedDomainsSubDomains();
    this.getSubdomainCount();
    this.getSubDomain();
    this.getnetblocksCount();
    this.getNetblock();
    this.getipsCount();
    this.getserviceCount();
    this.getApplicationsCount();
    this.getLeakedCredCount();
    this.getApiDiscoveryCount();
  }
  getInscopeEntities() {
    this.getRelatedDomainCount();
    this.getRelatedDomainsSubDomains();
    this.getSubdomainCount();
    this.getSubDomain();
    this.getnetblocksCount();
    this.getNetblock();
    this.getipsCount();
    this.getserviceCount();
    this.getApplicationsCount();
    this.getApiDiscoveryCount();
  }

  errorForDomain(error) {
    if (error.status == 403) {
      alert(
        "Forbidden! You are not allowed to perform this action. Please contact us at contact@firecompass.com"
      );
      this.router.navigate(["/new/self-domains"]);
    }
  }
  @ViewChild("widgetsContent", { read: ElementRef })
  public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: "smooth",
    });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: "smooth",
    });
  }
  draw() {
    var gauge = (container, configuration) => {
      var config = {
        size: 710,
        clipWidth: 100,
        clipHeight: 110,
        ringInset: 20,
        ringWidth: 20,

        pointerWidth: 10,
        pointerTailLength: 3,
        pointerHeadLengthPercent: 0.9,

        minValue: 0,
        maxValue: 10,

        minAngle: -90,
        maxAngle: 90,

        transitionMs: 750,

        majorTicks: 8,
        labelFormat: d3.format("d"),
        labelInset: 10,
        arcColorFn: d3
          .scaleQuantile()
          .domain([0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6])
          .range([
            "#4dbd74",
            "#e83e8c",
            "#20a8d8",
            "#3e89b2",
            "#ffc107",
            "#f0d40b",
            "#f86c6b",
            "#e81710",
          ]),
        // arcColorFn: d3.interpolateHsl(d3.rgb('#4dbd74'), d3.rgb('#ffc107'),d3.rgb('#f86c6b'))
      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return (deg * Math.PI) / 180;
      }

      function newAngle(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + ratio * range;
        return newAngle;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3
          .scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(() => {
          return 1 / config.majorTicks;
        });

        arc = d3
          .arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle((d, i) => {
            var ratio = d * i;
            return deg2rad(config.minAngle + ratio * range);
          })
          .endAngle((d, i) => {
            var ratio = d * (i + 1);
            return deg2rad(config.minAngle + ratio * range);
          });
      }
      this.gaugemap.configure = configure;

      function centerTranslation() {
        return "translate(" + r + "," + r + ")";
      }

      function isRendered() {
        return svg !== undefined;
      }
      this.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3
          .select(container)
          .append("svg:svg")
          .attr("class", "gauge")
          .attr("width", config.clipWidth)
          .attr("height", config.clipHeight);

        var centerTx = centerTranslation();

        var arcs = svg
          .append("g")
          .attr("class", "arc")
          .attr("transform", centerTx);

        // var labelNew = ['Low', 'Medium', "High","Critical"]
        arcs
          .selectAll("path")
          .data(tickData)
          .enter()
          .append("path")
          .attr("fill", (d, i) => {
            return config.arcColorFn(d * i);
          })
          .attr("d", arc);

        var lg = svg
          .append("g")
          .attr("class", "label")
          .attr("transform", centerTx);
        // lg.selectAll('text')
        //   .data(ticks)
        //   .enter().append('text')
        //   .attr('transform', (d)=> {
        //     var ratio = scale(d);
        //     var newAngle = config.minAngle + (ratio * range);
        //     return 'rotate(' + newAngle + ') translate(0,' + (config.labelInset - r) + ')';
        //   })
        //   .text(config.labelFormat);
        lg.append("text")
          .attr("dy", "-2em")
          .attr("dx", "-8.5em")
          .attr("text-anchor", "left");
        // .text("Low");

        lg.append("text")
          .attr("dy", "-7.3em")
          .attr("dx", "-2.5em")
          .attr("text-anchor", "left");
        // .text("Med");

        lg.append("text")
          .attr("dy", "-7.3em")
          .attr("dx", "2em")
          .attr("text-anchor", "left");
        // .text("High");

        lg.append("text")
          .attr("dy", "-3em")
          .attr("dx", "7em")
          .attr("text-anchor", "middle");
        // .text("Cri");

        lg.append("text")
          .attr("dy", "-3em")
          .attr("dx", "7em")
          .attr("text-anchor", "middle");
        // .text("ABC");

        lg.append("text")
          .attr("dy", "-4em")
          .attr("dx", "5em")
          .attr("text-anchor", "right");
        // .text("EFG");

        lg.append("text")
          .attr("dy", "-5em")
          .attr("dx", "6em")
          .attr("text-anchor", "right");
        // .text("HIJ");

        lg.append("text")
          .attr("dy", "-3em")
          .attr("dx", "7em")
          .attr("text-anchor", "right");
        // .text("KLM");

        var lineData = [
          [config.pointerWidth / 2, 0],
          [0, -pointerHeadLength],
          [-(config.pointerWidth / 2), 0],
          [0, config.pointerTailLength],
          [config.pointerWidth / 2, 0],
        ];
        var pointerLine = d3.line().curve(d3.curveLinear);
        var pg = svg
          .append("g")
          .data([lineData])
          .attr("class", "pointer")
          .attr("transform", centerTx);

        pointer = pg
          .append("path")
          .attr("d", pointerLine /*(d)=> { return pointerLine(d) +'Z';}*/)
          .attr("transform", "rotate(" + config.minAngle + ")");

        update(newValue === undefined ? 0 : newValue);
      }
      this.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + ratio * range;
        pointer
          .transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr("transform", "rotate(" + newAngle + ")");
      }
      this.gaugemap.update = update;

      configure(configuration);

      return this.gaugemap;
    };
    var powerGauge = gauge("#power-gauge", {
      size: 350,
      clipWidth: 350,
      clipHeight: 200,
      ringWidth: 50,
      maxValue: 900,
      transitionMs: 2000,
    });
    powerGauge.render(100);
  }
  public getIntials(value) {
    let a = this.commonService.returnInitials(value);
    return a;
  }
  public setEntityType(value, params = {}) {
    let indexEntity = this.entityToShow.findIndex((t) => t["value"] == value);
    if (!params || !params['scope']) {
      if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') params = { scope: this.inscopeFlag }
    }
    if (indexEntity >= 0) {
      this.selectedEntity = this.entityToShow[indexEntity];
    }
    this.selectedEntityValue = value
    if (document.getElementById('entitySelect'))
      document.getElementById('entitySelect')['value'] = value
    if (value == "domain") {
      this.selectedEntityData = {
        data: this.domainDWData,
        label: this.domainDWLabels,
      };
      if (!this.domainStats) {
        this.portfolioSurveyService.domainStatusCount(this.domain, params).subscribe(
          (data) => {
            if (data && data["status"] == "pass" && data["data"]) {
              this.domainStats = {
                ...this.domainStats,
                ...{ status: data["data"] },
              };
            }
          },
          (err) => {
            console.log(err);
          }
        );
        this.portfolioSurveyService.domainTagsCount(this.domain, params).subscribe(
          (data) => {
            if (data && data["status"] == "pass" && data["data"]) {
              this.domainStats = {
                ...this.domainStats,
                ...{ tags: this.commonService.sortObject(data["data"]) },
              };
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else if (value == "subdomain") {
      this.selectedEntityData = {
        data: this.subdomainDWData,
        label: this.subdomainDWLabels,
      };
      if (!this.subdomainStats) {
        this.portfolioSurveyService.subdomainStatusCount(this.domain, params).subscribe(
          (data) => {
            if (data && data["status"] == "pass" && data["count"]) {
              this.subdomainStats = {
                ...this.subdomainStats,
                ...{ status: data["count"] },
              };
            }
          },
          (err) => {
            console.log(err);
          }
        );
        this.portfolioSurveyService.subdomainTagsCount(this.domain, params).subscribe(
          (data) => {
            if (data && data["status"] == "pass" && data["data"]) {
              this.subdomainStats = {
                ...this.subdomainStats,
                ...{ tags: this.commonService.sortObject(data["data"]) },
              };
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else if (value == "ip") {
      this.selectedEntityData = { data: this.ipDWData, label: this.ipDWLabels };
    } else if (value == "netblock") {
      this.selectedEntityData = {
        data: this.netblockDWData,
        label: this.netblockDWLabels,
      };
      if (!this.netblckStats) {
        this.portfolioSurveyService.netblockStatusCount(this.domain, params).subscribe(
          (data) => {
            if (data && data["status"] == "pass" && data["count"]) {
              this.netblckStats = {
                ...this.netblckStats,
                ...{ status: data["count"] },
              };
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else if (value == "services") {
      this.selectedEntityData = {
        data: this.serviceDWData,
        label: this.serviceDWLabels,
      };
    } else if (value == "leakcred") {
      this.selectedEntityData = { data: this.leakedCredDWData, label: this.leakedCredDWLabels };
    } else if (value == "apiDiscovery") {
      this.selectedEntityData = { data: this.apiDiscoveryDWData, label: this.apiDiscoveryDWLabels };
    } else if (value == "application") {
      this.selectedEntityData = { data: this.webAppDWData, label: this.webAppDWLabels };
    }
    this.dataValues[value] = [this.selectedEntityData];
  }
  // DOMAIN COUNT
  getRelatedDomainCount() {
    this.dataLoaders.domain = true;
    let params = {}
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') {
      params = { scope: this.inscopeFlag }
    }
    this.portfolioSurveyService.getRelatedDomainsCount(this.domain, params)
      .subscribe((data) => {
        this.relatedDomainsCount = data["count"];
        this.totalCallTimeCount++;
        // this.getDomainTimelineData(params);
        this.calculateDomianTimelineData(params);
      }, error => {
        console.log(error);
        this.multiError.emit({ message: 'Error' });
      });
    this.getDomainTimelineData(params);
  }
  // DOMAIN TIMELINE
  getDomainTimelineData(params) {
    this.dwInsightsService
      .getEntityTimeline(this.domain, "domain", { limit: 7, scope_config: this.inscopeConfig, scope_param: this.inscopeFlag }).subscribe((data) => {
        this.relatedDomainTimelineData = data['data'];
        this.calculateDomianTimelineData(params);
        this.getTotalEntityData(this.domainDWData[0], 'domains');
      }, error => {
        console.log(error);
        this.multiError.emit({ message: 'Error' });
      })
  }
  // DOMAIN CALCULATION
  calculateDomianTimelineData(params){
    if(this.relatedDomainTimelineData && (this.relatedDomainsCount || this.relatedDomainsCount>=0)){
      let timeLineDataArray = this.commonService.fillMissingTimelineData(
        this.relatedDomainTimelineData,
        this.dateRange
      );
      this.commonService.getTimelineData(
        this.relatedDomainsCount,
        timeLineDataArray
      );
    
      let rd;
      this.domainDWLabels = [];
      let weekNumber = 0;
      this.domainDWData[0]["data"] = [];

      for (var i = 0; i < this.dateRange.length; i++) {
        weekNumber = this.dateRange[i]["weekNumber"];
        rd = timeLineDataArray[i];
        this.domainDWData[0]["data"].unshift(rd["totalCount"] || 0);
        this.domainDWData[0]["created"].unshift(rd["created"] || 0);
        this.domainDWData[0]["updated"].unshift(rd["updated"] || 0);
        this.domainDWData[0]["archived"].unshift(rd["archived"] || 0);
        this.domainDWLabels.unshift(rd["label"]);
      }
      this.domainDWData[0]["maxTotal"] = Math.max(
        ...this.domainDWData[0]["data"]
      );
      this.domainDWData[0]["maxCreated"] = Math.max(
        ...this.domainDWData[0]["created"]
      );
      this.domainDWData[0]["maxUpdated"] = Math.max(
        ...this.domainDWData[0]["updated"]
      );
      this.domainDWData[0]["maxArchived"] = Math.max(
        ...this.domainDWData[0]["archived"]
      );
      // this.setEntityType("domain");
      this.dataLoaders.domain = false;
      this.setEntityType('domain', params);
    }
  }
  // SUBDOMAIN COUNT
  getSubdomainCount() {
    this.dataLoaders.subdomain = true;
    let params = {}
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') {
      params = {
        scope: this.inscopeFlag
      }
    }
    this.portfolioSurveyService.getSubdomainCount(this.domain, params)
      .subscribe(data => {
        this.subdomainsCount = data["count"];
        this.totalCallTimeCount++;
        this.calculateSubdomainTimelineData(params);
      }, error => {
        console.log(error);
        this.multiError.emit({ message: 'Error' });
      })
    this.getSubdomainTimelineData(params);
  }
  // SUBDOMAIN TIMELINE
  getSubdomainTimelineData(params) {
    this.dwInsightsService.getEntityTimeline(this.domain, "host", { limit: 7, scope_config: this.inscopeConfig, scope_param: this.inscopeFlag })
      .subscribe((data) => {
        this.subdomainTimelineData = data["data"];
        this.calculateSubdomainTimelineData(params);
        this.getTotalEntityData(this.subdomainDWData[0]);
      }, error => {
        console.log(error);
        this.multiError.emit({ message: 'Error' });
      });
  }
  // DOMAIN SUBDOMAIN
  calculateSubdomainTimelineData(params){
    if(this.subdomainTimelineData && (this.subdomainsCount || this.subdomainsCount>=0)){
      let timeLineDataArray = this.commonService.fillMissingTimelineData(
        this.subdomainTimelineData,
        this.dateRange
      );
      this.commonService.getTimelineData(
        this.subdomainsCount,
        timeLineDataArray
      );
      let rd;
      this.subdomainDWLabels = [];
      let weekNumber = 0;
      this.subdomainDWData[0]["data"] = [];

      for (var i = 0; i < this.dateRange.length; i++) {
        weekNumber = this.dateRange[i]["weekNumber"];
        rd = timeLineDataArray[i];
        this.subdomainDWData[0]["data"].unshift(rd["totalCount"] || 0);
        this.subdomainDWData[0]["created"].unshift(rd["created"] || 0);
        this.subdomainDWData[0]["updated"].unshift(rd["updated"] || 0);
        this.subdomainDWData[0]["archived"].unshift(rd["archived"] || 0);
        this.subdomainDWLabels.unshift(rd["label"]);
      }
      this.subdomainDWData[0]["maxTotal"] = Math.max(
        ...this.subdomainDWData[0]["data"]
      );
      this.subdomainDWData[0]["maxCreated"] = Math.max(
        ...this.subdomainDWData[0]["created"]
      );
      this.subdomainDWData[0]["maxUpdated"] = Math.max(
        ...this.subdomainDWData[0]["updated"]
      );
      this.subdomainDWData[0]["maxArchived"] = Math.max(
        ...this.subdomainDWData[0]["archived"]
      );
      this.dataLoaders.subdomain = false;
      this.setEntityType('subdomain', params);
    }
  }
  // NETBLOCK COUNT
  getnetblocksCount() {
    this.dataLoaders.netblock = true;
    let params = {}
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') {
      params = {
        scope: this.inscopeFlag
      }
    }
    this.portfolioSurveyService.getNetblocksCount(this.domain, params)
      .subscribe(data => {
        this.netblocksCount = data["count"];
        this.totalCallTimeCount++;
        this.calculateNetblockTimelineData(params);
      }, error => {
        console.log(error);
        this.multiError.emit({ message: 'Error' });
      });
    this.getNetblockTimeline(params);
  };
  // NETBLOCK TIMELINE
  getNetblockTimeline(params) {
    this.dwInsightsService.getEntityTimeline(this.domain, "netblock", { limit: 7, scope_config: this.inscopeConfig, scope_param: this.inscopeFlag })
      .subscribe(data => {
        this.netblockTimelineData = data["data"];
        this.calculateNetblockTimelineData(params);
        this.getTotalEntityData(this.netblockDWData[0]);
      }, error => {
        console.log(error);
        this.multiError.emit({ message: 'Error' });
      });
  }
  calculateNetblockTimelineData(params){
    if(this.netblockTimelineData && (this.netblocksCount || this.netblocksCount>=0)){
      let timeLineDataArray = this.commonService.fillMissingTimelineData(
        this.netblockTimelineData,
        this.dateRange
      );
      this.commonService.getTimelineData(
        this.netblocksCount,
        timeLineDataArray
      );
      let rd;
      this.netblockDWLabels = [];
      let weekNumber = 0;
      this.netblockDWData[0]["data"] = [];

      for (var i = 0; i < this.dateRange.length; i++) {
        weekNumber = this.dateRange[i]["weekNumber"];
        rd = timeLineDataArray[i];
        this.netblockDWData[0]["data"].unshift(rd["totalCount"] || 0);
        this.netblockDWData[0]["created"].unshift(rd["created"] || 0);
        this.netblockDWData[0]["updated"].unshift(rd["updated"] || 0);
        this.netblockDWData[0]["archived"].unshift(rd["archived"] || 0);
        this.netblockDWLabels.unshift(rd["label"]);
      }
      this.netblockDWData[0]["maxTotal"] = Math.max(
        ...this.netblockDWData[0]["data"]
      );
      this.netblockDWData[0]["maxCreated"] = Math.max(
        ...this.netblockDWData[0]["created"]
      );
      this.netblockDWData[0]["maxUpdated"] = Math.max(
        ...this.netblockDWData[0]["updated"]
      );
      this.netblockDWData[0]["maxArchived"] = Math.max(
        ...this.netblockDWData[0]["archived"]
      );
      this.dataLoaders.netblock = false;
      this.setEntityType('netblock', params);
    }
  }
  // IP COUNT
  getipsCount() {
    this.dataLoaders.ip = true;
    let params = {}
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') {
      params = {
        scope: this.inscopeFlag
      }
    }
    let ipParams = {
      sortKey: "cves",
      archive: false,
      view: 'dashboard'

    }
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') ipParams['scope'] = this.inscopeFlag

    this.portfolioSurveyService.getipsCount(this.domain, params).subscribe(data => {
      this.ipsCount = data["count"];
      this.totalCallTimeCount++;
      this.calculateIpTimelineData(params);      
    }, error => {
      console.log(error);
      this.multiError.emit({ message: 'Error' });
    })
    this.getipsCountTimeline(params, ipParams);
  }
  // IP TIMELINE
  getipsCountTimeline(params, ipParams) {
    Promise.all([
      this.dwInsightsService
        .getEntityTimeline(this.domain, "ip", { limit: 7, scope_config: this.inscopeConfig, scope_param: this.inscopeFlag })
        .toPromise(),
      this.portfolioSurveyService
        .getIpsDetailsPaginated(this.domain, ipParams)
        .toPromise(),
      this.portfolioSurveyService.getIpsIspList(this.domain, params).toPromise(),
      this.portfolioSurveyService.getIpsTagsList(this.domain, params).toPromise(),
    ]).then((data) => {
      this.ipTimelineData = data[0]["data"];
      this.topRiskIPs = data[1]["data"];
      this.IpsIspList = data[2]["data"];
      this.IpsTagsList = data[3]["data"];
      this.calculateIpTimelineData(params);
      this.getTotalEntityData(this.ipDWData[0]);
    }).catch((error)=>{
      console.log(error);
      this.multiError.emit({ message: 'Error' });
    });
  }
  calculateIpTimelineData(params){
    if(this.ipTimelineData && (this.ipsCount || this.ipsCount>=0)){
      let timeLineDataArray = this.commonService.fillMissingTimelineData(
        this.ipTimelineData,
        this.dateRange
      );
      this.commonService.getTimelineData(this.ipsCount, timeLineDataArray);
      let rd;
      this.ipDWLabels = [];
      let weekNumber = 0;
      this.ipDWData[0]["data"] = [];

      for (var i = 0; i < this.dateRange.length; i++) {
        weekNumber = this.dateRange[i]["weekNumber"];
        rd = timeLineDataArray[i];
        this.ipDWData[0]["data"].unshift(rd["totalCount"] || 0);
        this.ipDWData[0]["created"].unshift(rd["created"] || 0);
        this.ipDWData[0]["updated"].unshift(rd["updated"] || 0);
        this.ipDWData[0]["archived"].unshift(rd["archived"] || 0);
        this.ipDWLabels.unshift(rd["label"]);
      }
      this.ipDWData[0]["maxTotal"] = Math.max(...this.ipDWData[0]["data"]);
      this.ipDWData[0]["maxCreated"] = Math.max(...this.ipDWData[0]["created"]);
      this.ipDWData[0]["maxUpdated"] = Math.max(...this.ipDWData[0]["updated"]);
      this.ipDWData[0]["maxArchived"] = Math.max(
        ...this.ipDWData[0]["archived"]
      );
      // Format Risk Data
      this.ipDWData[0]["risk_data"] = [];
      this.ipDWData[0]["anyrisk"] = 0;
      if (this.topRiskIPs["length"] > 0) {
        this.topRiskIPs.map((x) => {
          x.cve_count = x.cve_count || { 'critical': 0 };
          let ipRisk = {
            ip: x.ip_address,
            risk: x.cve_count,
            totalRisk: Object.values(x.cve_count).reduce(
              (a: any, b: any) => a + b
            ),
          };
          this.ipDWData[0]["risk_data"].push(ipRisk);
          this.ipDWData[0]["anyrisk"] = this.ipDWData[0]["anyrisk"] + x.cve_count['critical'];
        });
      }
      // sort isp
      this.ipDWData[0]["isp"] = [];
      if (this.IpsIspList["length"] > 0) {
        this.ipDWData[0]["isp"] = this.IpsIspList.sort(
          (a, b) => b.count - a.count
        );
      }
      this.ipDWData[0]["tags_list"] = [];
      if (this.IpsTagsList["length"] > 0) {
        this.ipDWData[0]["tags_list"] = this.IpsTagsList.sort(
          (a, b) => b.count - a.count
        );
      }
      this.dataLoaders.ip = false;
      this.setEntityType('ip', params);
    }
  }
  // SERVICE COUNT
  getserviceCount() {
    let params = {}
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') {
      params = {
        scope: this.inscopeFlag
      }
    }
    this.dataLoaders.services = true;
    this.portfolioSurveyService.getOpenPortCount(this.domain, params)
      .subscribe(data => {
        this.servicesCount = data["count"];
        this.totalCallTimeCount++;
        this.getServiceTimelineCount(params);
      },error=>{
        console.log(error);
        this.multiError.emit({ message: 'Error' });
      })
      this.getserviceTimeline(params);
  }
  // SERVICE TIMELINE
  getserviceTimeline(params) {
    Promise.all([
      // this.portfolioSurveyService.getOpenPortCount(this.domain, params).toPromise(),
      this.dwInsightsService
        .getEntityTimeline(this.domain, "service", { limit: 7, scope_config: this.inscopeConfig, scope_param: this.inscopeFlag })
        .toPromise(),
      this.portfolioSurveyService
        .getServiceCountDetails(this.domain, params)
        .toPromise(),
      this.portfolioSurveyService.getService(this.domain, params).toPromise(),
      this.portfolioSurveyService
        .getServicesForOpenPorts(this.domain, params)
        .toPromise(),
    ]).then((data) => {
      this.serviceTimelineData = data[0]["data"];
      this.getServiceCountDetails = data[1]["data"];
      this.getService = data[2]["data"];
      this.getServicesForOpenPorts = data[3]["data"];
      this.getServiceTimelineCount(params);
      this.getTotalEntityData(this.serviceDWData[0]);
    }).catch((error)=>{
      console.log(error);
      this.multiError.emit({ message: 'Error' });
    });
  }
  getServiceTimelineCount(params){
    if(this.serviceTimelineData && (this.servicesCount || this.servicesCount>=0)){
      this.dataLoaders.services = false;
      let timeLineDataArray = this.commonService.fillMissingTimelineData(
        this.serviceTimelineData,
        this.dateRange
      );
      this.commonService.getTimelineData(this.servicesCount, timeLineDataArray);
      let rd;
      this.serviceDWLabels = [];
      let weekNumber = 0;
      this.serviceDWData[0]["data"] = [];

      for (var i = 0; i < this.dateRange.length; i++) {
        weekNumber = this.dateRange[i]["weekNumber"];
        rd = timeLineDataArray[i];
        this.serviceDWData[0]["data"].unshift(rd["totalCount"] || 0);
        this.serviceDWData[0]["created"].unshift(rd["created"] || 0);
        this.serviceDWData[0]["updated"].unshift(rd["updated"] || 0);
        this.serviceDWData[0]["archived"].unshift(rd["archived"] || 0);
        this.serviceDWLabels.unshift(rd["label"]);
      }
      this.serviceDWData[0]["maxTotal"] = Math.max(
        ...this.serviceDWData[0]["data"]
      );
      this.serviceDWData[0]["maxCreated"] = Math.max(
        ...this.serviceDWData[0]["created"]
      );
      this.serviceDWData[0]["maxUpdated"] = Math.max(
        ...this.serviceDWData[0]["updated"]
      );
      this.serviceDWData[0]["maxArchived"] = Math.max(
        ...this.serviceDWData[0]["archived"]
      );
      // Format Service Data
      if(this.getServiceCountDetails){
        this.serviceDWData[0]["tagsPort"] = this.getServiceCountDetails['state_count'];
        // this.serviceDWData[0]["tagsPortSorted"] = Object.keys(
        //   this.getServiceCountDetails['state_count']
        // ).sort(function (a, b) {        
        //   return this.getServiceCountDetails['state_count'][b] - this.getServiceCountDetails['state_count'][a];
        // });
      }
      this.serviceDWData[0]["listOpenPort"] = this.getService.results;
      this.serviceDWData[0]["countPort"] = this.getServicesForOpenPorts;
      
      this.setEntityType('services', params);
    }
  }
  // APPLICATION COUNT
  getApplicationsCount() {
    this.dataLoaders.application = true;
    let params = {}
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') {
      params = {
        scope: this.inscopeFlag
      }
    }
    // this.portfolioSurveyService.getApplicationCount(this.domain,params, 'web').subscribe(data => {
    this.portfolioSurveyService.getwebapplication(this.domain,params).subscribe(data => {
      this.appCount = data["count_apps"];
      this.totalCallTimeCount++;
      this.calculateGetApplicationTimeline();
    }, error => {
      console.log(error);
      this.multiError.emit({ message: 'Error' });
    })
    this.getApplicationsTimeline(params);
  }
  // APPLICATION TIMELINE
  getApplicationsTimeline(params) {
    Promise.all([
      // this.portfolioSurveyService.getApplicationCount(this.domain).toPromise(),
      this.dwInsightsService
        .getEntityTimeline(this.domain, "application", { limit: 7, scope_config: this.inscopeConfig, scope_param: this.inscopeFlag })
        .toPromise(),
      this.portfolioSurveyService
        .getApplicationByTechnilogy(this.domain, params)
        .toPromise(),
      // this.portfolioSurveyService.getwebapplication(this.domain).toPromise(),
      this.portfolioSurveyService.getApplicationCveCount(this.domain,params).toPromise(),
    ]).then((data) => {
      // this.appCount = data[0]["count"];
      this.webAppTimelineData = data[0]["data"];
      this.applicationTechnology = data[1]["count"]
      this.applicationCveCount = data[2]["count"]
      this.calculateGetApplicationTimeline();
      this.getTotalEntityData(this.webAppDWData[0]);
    }).catch((error)=>{
        console.log(error);
        this.multiError.emit({ message: 'Error' });
    });
  }
  calculateGetApplicationTimeline(){
    if(this.webAppTimelineData && (this.appCount || this.appCount>=0)){
      let timeLineDataArray = this.commonService.fillMissingTimelineData(
        this.webAppTimelineData,
        this.dateRange
      );
      this.commonService.getTimelineData(
        this.appCount,
        timeLineDataArray
      );
      let rd;
      this.webAppDWLabels = [];
      let weekNumber = 0;
      this.webAppDWData[0]["data"] = [];

      for (var i = 0; i < this.dateRange.length; i++) {
        weekNumber = this.dateRange[i]["weekNumber"];
        rd = timeLineDataArray[i];
        this.webAppDWData[0]["data"].unshift(rd["totalCount"] || 0);
        this.webAppDWData[0]["created"].unshift(rd["created"] || 0);
        this.webAppDWData[0]["updated"].unshift(rd["updated"] || 0);
        this.webAppDWData[0]["archived"].unshift(rd["archived"] || 0);
        this.webAppDWLabels.unshift(rd["label"]);
      }
      this.webAppDWData[0]["maxTotal"] = Math.max(
        ...this.webAppDWData[0]["data"]
      );
      this.webAppDWData[0]["maxCreated"] = Math.max(
        ...this.webAppDWData[0]["created"]
      );
      this.webAppDWData[0]["maxUpdated"] = Math.max(
        ...this.webAppDWData[0]["updated"]
      );
      this.webAppDWData[0]["maxArchived"] = Math.max(
        ...this.webAppDWData[0]["archived"]
      );
      this.dataLoaders.application = false;
      this.setEntityType('application', {});
    }
  }
  // Leaked Cred COUNT
  getLeakedCredCount() {
    this.dataLoaders.leakcred = true;
    this.portfolioSurveyService.getLeakedCredCount(this.domain).subscribe(data => {
      this.totalLeakCredentialsCount = data["leak_credentials"]["total_leaked_credentials"];
      this.totalCallTimeCount++;
      this.countGetLeakedCredTimeline();
    }, error => {
      console.log(error);
      this.multiError.emit({ message: 'Error' });
    })
    this.getLeakedCredTimeline();
  }
  // Leaked Cred Timeline
  getLeakedCredTimeline() {
    Promise.all([
      // this.portfolioSurveyService.getLeakedCredCount(this.domain).toPromise(),
      this.dwInsightsService
        .getEntityTimeline(this.domain, "leakcred", { limit: 7 })
        .toPromise(),
      this.portfolioSurveyService.leakcredPasswordCount(this.domain).toPromise(),
      this.portfolioSurveyService.getRelatedDomainsLeakCredentials(this.domain).toPromise()
    ]).then((data) => {
      // this.totalLeakCredentialsCount = data[0]["leak_credentials"]["total_leaked_credentials"];
      this.leakedCredTimelineData = data[0]["data"];
      this.leakcredPasswordCount = data[1]["count"];
      this.leakcredList = data[2]["leak_credentials"]["data"];
      this.countGetLeakedCredTimeline();
      this.getTotalEntityData(this.leakedCredDWData[0]);
    }).catch((error)=>{
      console.log(error);
      this.multiError.emit({ message: 'Error' });
    });
  }
  countGetLeakedCredTimeline(){
    if(this.leakedCredTimelineData && (this.totalLeakCredentialsCount || this.totalLeakCredentialsCount>=0)){
      let timeLineDataArray = this.commonService.fillMissingTimelineData(
        this.leakedCredTimelineData,
        this.dateRange
      );
      this.commonService.getTimelineData(
        this.totalLeakCredentialsCount,
        timeLineDataArray
      );
      let rd;
      this.leakedCredDWLabels = [];
      let weekNumber = 0;
      this.leakedCredDWData[0]["data"] = [];

      for (var i = 0; i < this.dateRange.length; i++) {
        weekNumber = this.dateRange[i]["weekNumber"];
        rd = timeLineDataArray[i];
        this.leakedCredDWData[0]["data"].unshift(rd["totalCount"] || 0);
        this.leakedCredDWData[0]["created"].unshift(rd["created"] || 0);
        this.leakedCredDWData[0]["updated"].unshift(rd["updated"] || 0);
        this.leakedCredDWData[0]["archived"].unshift(rd["archived"] || 0);
        this.leakedCredDWLabels.unshift(rd["label"]);
      }
      this.leakedCredDWData[0]["maxTotal"] = Math.max(
        ...this.leakedCredDWData[0]["data"]
      );
      this.leakedCredDWData[0]["maxCreated"] = Math.max(
        ...this.leakedCredDWData[0]["created"]
      );
      this.leakedCredDWData[0]["maxUpdated"] = Math.max(
        ...this.leakedCredDWData[0]["updated"]
      );
      this.leakedCredDWData[0]["maxArchived"] = Math.max(
        ...this.leakedCredDWData[0]["archived"]
      );
      this.dataLoaders.leakcred = false;
      this.setEntityType('leakcred', {});
    }
  }
  // Api Discovery COUNT
  getApiDiscoveryCount() {
    this.dataLoaders.apiDiscovery = true;
    let order
    order = -1 * order;
    let sortKey = 'updated_at'
    sortKey = order == -1 ? sortKey : '-' + sortKey

    this.portfolioSurveyService.getApiDiscoveryCount(this.domain).subscribe(data => {
      this.apiDiscovryCount = data["count"];
      this.totalCallTimeCount++;
      this.countGetApiDiscoveryTimeline();
    }, error => {
      console.log(error);
      this.multiError.emit({ message: 'Error' });
    })
    this.getApiDiscoveryTimeline(sortKey);
  }
  // Api Discovery Timeline
  getApiDiscoveryTimeline(sortKey) {
    Promise.all([
      // this.portfolioSurveyService.getApiDiscoveryCount(this.domain).toPromise(),
      this.dwInsightsService
        .getEntityTimeline(this.domain, "apidiscovery", { limit: 7 })
        .toPromise(),
      this.portfolioSurveyService.getApiDiscovery(this.domain, { sortKey }).toPromise(),
      this.portfolioSurveyService.getApiDiscoveryMethodsCount(this.domain).toPromise(),

    ]).then((data) => {
      // this.apiDiscovryCount = data[0]["count"];
      this.apiDiscovryTimelineData = data[0]["data"];
      this.recentApiDiscovery = data[1]["data"]["results"]
      this.apiDiscoveryMethodsCount = data[2]["count"]
      this.countGetApiDiscoveryTimeline();
      this.getTotalEntityData(this.apiDiscoveryDWData[0]);
    }).catch((error)=>{
      console.log(error);
      this.multiError.emit({ message: 'Error' });
    });
  }
  countGetApiDiscoveryTimeline(){
    if(this.apiDiscovryTimelineData && (this.apiDiscovryCount || this.apiDiscovryCount>=0)){
      let timeLineDataArray = this.commonService.fillMissingTimelineData(
        this.apiDiscovryTimelineData,
        this.dateRange
      );
      this.commonService.getTimelineData(
        this.apiDiscovryCount,
        timeLineDataArray
      );
      let rd;
      this.apiDiscoveryDWLabels = [];
      let weekNumber = 0;
      this.apiDiscoveryDWData[0]["data"] = [];

      for (var i = 0; i < this.dateRange.length; i++) {
        weekNumber = this.dateRange[i]["weekNumber"];
        rd = timeLineDataArray[i];
        this.apiDiscoveryDWData[0]["data"].unshift(rd["totalCount"] || 0);
        this.apiDiscoveryDWData[0]["created"].unshift(rd["created"] || 0);
        this.apiDiscoveryDWData[0]["updated"].unshift(rd["updated"] || 0);
        this.apiDiscoveryDWData[0]["archived"].unshift(rd["archived"] || 0);
        this.apiDiscoveryDWLabels.unshift(rd["label"]);
      }
      this.apiDiscoveryDWData[0]["maxTotal"] = Math.max(
        ...this.apiDiscoveryDWData[0]["data"]
      );
      this.apiDiscoveryDWData[0]["maxCreated"] = Math.max(
        ...this.apiDiscoveryDWData[0]["created"]
      );
      this.apiDiscoveryDWData[0]["maxUpdated"] = Math.max(
        ...this.apiDiscoveryDWData[0]["updated"]
      );
      this.apiDiscoveryDWData[0]["maxArchived"] = Math.max(
        ...this.apiDiscoveryDWData[0]["archived"]
      );
      this.dataLoaders.apiDiscovery = false;
      this.setEntityType('apiDiscovery', {});
    }
  }
  getMax(arr) {
    return Math.max(...arr);
  }
  getWidth(data, i, type, maxRef) {
    const maxVal = data[maxRef] || 1;
    return (data[type][data[type].length - 1 - i] / maxVal) * 100;
  }
  getRelatedDomainsSubDomains() {
    // if (this.domainSortWithKey) params.sortKey = this.order == -1 ? this.domainSortWithKey : '-' + this.domainSortWithKey
    let params = {}
    if (this.inscopeConfig == 'true') {
      params = {
        scope: (this.inscopeFlag)?this.inscopeFlag:'IN'
      }
    }
    this.portfolioSurveyService
      .getRelatedDomainsSubDomainsWithFilters(this.domain, params)
      .subscribe(
        (data: any) => {
          if (data.data.results && data.data.results.length && data["status"] == "pass") {
            this.domainList = data.data.results;
            let domainList = [];
            this.domainList.map((x)=>{
              domainList.push(x.domain);
            })
            this.getSubDomainCountForEachDomain(domainList,params);
          }else{
            this.relatedSubdomainCountList = [];
          }
        },
        (error) => {
          this.commonService.errorForDomain(error);
        }
      );
  }
  getSubDomainCountForEachDomain(domainList = [],params={}){
    if(domainList && domainList.length>0){
      let domain_list = domainList.join(',');
      let params = { domain_list: domain_list };
      if (this.inscopeConfig == 'true') {
        params['scope'] = (this.inscopeFlag)?this.inscopeFlag:'IN';
      }
      this.portfolioSurveyService.getSubDomainCountForEachDomain(this.domain,params)
      .subscribe((data: any) => {
        if (data && data["status"] == "pass" && data.data) {
          let subdomains = data.data;
          let finalData = [];
          let keys = Object.keys(subdomains);
          keys.forEach(element => {
            let domain = {
              domain: element,
              host_count: subdomains[element].count,
            }
            finalData.push(domain);
          });
          this.relatedSubdomainCountList = this.commonService.sortByKey(finalData,'host_count',1);
        }
      },(error) => {
        this.commonService.errorForDomain(error);
      });
    }
  }
  getSubDomain() {
    let params = {}
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') {
      params = {
        scope: this.inscopeFlag
      }
    }
    this.portfolioSurveyService.getSubDomain(this.domain, params).subscribe(
      (data) => {
        this.subdomainsList = data["data"].results;
      },
      (error) => {
        this.commonService.errorForDomain(error);
      }
    );
  }
  getNetblock() {
    let params = {}
    if (this.inscopeConfig == 'true' && this.inscopeFlag != 'null') {
      params = {
        scope: this.inscopeFlag
      }
    }
    this.portfolioSurveyService.getNetblocksPaginated(this.domain, params).subscribe(
      (data) => {
        this.netblockList = data["data"].results;
      },
      (error) => {
        this.commonService.errorForDomain(error);
      }
    );
    this.portfolioSurveyService.getNetblockIspForDomains(this.domain, params).subscribe(
      (ispData) => {
        if(ispData["summary_data"] || ispData["asn_data"]){
          this.ispToTypeIpsDropDown = [
            ...ispData["summary_data"],
            ...ispData["asn_data"],
          ];
        }
      },
      (error) => {
        this.commonService.errorForDomain(error);
      }
    );
  }
  showIspName(isp) {
    if (isp["asn_name"]) return isp["asn_name"];
    else return isp["summary"];
  }

  getIpCountByType(data, index, type) {
    let archived = data.archived[index];
    let total = data.data[index];
    let created = data.created[index];
    if (type == "lastweek") {
      return total - created + archived;
    } else if (type == "delta") {
      return created - archived;
    } else if (type == "thisweek") {
      return total;
    }
  }
  getIpCountByTypeIndex(data, index, type) {
    let lableIndex = data["label"].length - 1 - index;
    let archived = data["data"][0].archived[lableIndex];
    let total = data["data"][0].data[lableIndex];
    let created = data["data"][0].created[lableIndex];
    let delta = created - archived;
    let lastweek = total - created + archived;
    if ((created - archived) > total) {
      delta = total;
    }
    if (type == "lastweek") {
      return (lastweek > 0) ? lastweek : 0;
    } else if (type == "delta") {
      return delta;
    } else if (type == "thisweek") {
      return total;
    }else if(type == "archived"){
      return archived;
    }else if(type == "created"){
      return created;
    }
  }
  scrollToRight() {
    if (this.currentCounter <= 5) {
      const firstNumber = this.viewEntities.shift()
      this.viewEntities.push(firstNumber + 5)
      this.currentCounter += 1;
    }
  }
  scrollToLeft() {
    if (this.currentCounter >= 2) {
      const lastNumber = this.viewEntities.pop()
      this.viewEntities.unshift(lastNumber - 5)
      this.currentCounter -= 1;
    }
  }
  checkScroll(val) {
    return this.viewEntities.includes(val)
  }
  resetInitialValues() {
    this.relatedDomainsCount = null;
    this.domainStats = null
    this.subdomainsCount = null
    this.subdomainStats = null
    this.netblocksCount = null
    this.netblckStats = null
    this.ipsCount = null
    this.servicesCount = null
    this.appCount = null
  }
  filterByInscope(scope) {
    if (scope == 'null') {
      delete this.inscopeFlag
    } else {
      this.inscopeFlag = scope;
    }
    this.totalCallTime = 2;
    this.totalCallTimeCount = 2;
    this.resetInitialValues()
    this.getInscopeEntities()
  }
  getTotalEntityData(singleData = [], title = '') {
    this.totalCallTime++;
    let allRecord = [
      this.domainDWData[0],
      this.subdomainDWData[0],
      this.ipDWData[0],
      this.netblockDWData[0],
      this.serviceDWData[0],
      this.webAppDWData[0],
      this.leakedCredDWData[0],
      this.apiDiscoveryDWData[0]
    ];
    let total = [0, 0, 0, 0, 0, 0, 0];
    let created = [0, 0, 0, 0, 0, 0, 0];
    let archived = [0, 0, 0, 0, 0, 0, 0];
    let updated = [0, 0, 0, 0, 0, 0, 0];
    this.totalEntityDWData[0]['label'] = "Total Entity";
    total.map(function (num, idx) {
      allRecord.map(data => {
        if (data['data'][idx]) {
          total[idx] = total[idx] + (data['data'][idx] || 0);
          created[idx] = created[idx] + (data['created'][idx] || 0);
          archived[idx] = archived[idx] + (data['archived'][idx] || 0);
          updated[idx] = updated[idx] + (data['updated'][idx] || 0);
        }
      })
    })
    this.totalEntityDWData[0]['data'] = total;
    this.totalEntityDWData[0]['created'] = created;
    this.totalEntityDWData[0]['archived'] = archived;
    this.totalEntityDWData[0]['updated'] = updated;
    let lables = {
      lable: this.domainDWLabels,
      domains: this.domainDWData[0][`data`],
      subdomain: this.subdomainDWData[0][`data`],
      ips: this.ipDWData[0][`data`],
      netblock: this.netblockDWData[0][`data`],
      services: this.serviceDWData[0][`data`],
      applications: this.webAppDWData[0][`data`],
      api: this.apiDiscoveryDWData[0][`data`],
      leaked: this.leakedCredDWData[0][`data`]
    };
    this.totalEntityDWData[0]['label'] = JSON.stringify(lables);
    this.totalEntityCount = this.totalEntityDWData[0]['data'][6] || 0;
  }
  getTotalCounts(){
    let sum = 0;
    sum = sum + this.relatedDomainsCount || 0;
    sum = sum + this.subdomainsCount || 0;
    sum = sum + this.ipsCount || 0;
    sum = sum + this.netblocksCount || 0;
    sum = sum + this.servicesCount || 0;
    sum = sum + this.appCount || 0;
    sum = sum + this.totalLeakCredentialsCount || 0;
    sum = sum + this.apiDiscovryCount || 0;
    return sum;
  }
  linkToDelta(entityName){
    // console.log("linkToDelta",entityName);
    let allowdEntity = [
      "domain",
      "ip",
      "services",
      "application",
      "subdomain",
      "leakcred",
      "apiDiscovery",
      "netblock"
    ]
    return allowdEntity.includes(entityName);
  }
  deltaDisplay(d){ 
    return [d["startDate"],d["endDateWithMoth"]].join(" - ");
  }
  showingFromCheck(){
    if(this.showingFrom=='new-dashboard'){
      return 'show-card-block col-md-4';
    }
  }
  goTo(entity){
    var element:any = document.getElementById(entity);
    if(element){
      var headerOffset = 60;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
    }
  }
}