import { Component, OnInit, Input } from '@angular/core';
import { getThumURL } from 'thum.io';
// import { Http } from '@angular/http'
import * as validator from 'validator';

@Component({
  selector: 'app-url-screenshot-thumnail',
  templateUrl: './url-screenshot-thumnail.component.html',
  styleUrls: ['./url-screenshot-thumnail.component.scss']
})
export class UrlScreenshotThumnailComponent implements OnInit {

  @Input() ip: any;
  @Input() url: any;
  @Input() showImage?: boolean = false;
  screenShotUrl: string;
  thumURL: string;

  constructor() { }

  ngOnInit() {
    if (this.ip) {
      this.screenShotUrl = 'http://' + this.ip;
    } else {
      if (this.url.search('http') == -1)
        this.screenShotUrl = 'http://' + this.url;
      else
        this.screenShotUrl = this.url
    }
    // if (!validator.isURL(this.screenShotUrl)){
      this.thumURL = getThumURL({
        url: this.screenShotUrl,
        // auth: {
        //   type: 'raw',
        //   secret: 'security',
        //   keyId: '1526',
        // },
      });
    // }
  }
  showImageClick(event, value) {
    this.showImage = value;
    event.stopPropagation();
  }

}
