import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { PortfolioSurveyService } from 'app/_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../_services/common.service';
 
@Component({
  selector: 'app-observations-insights',
  templateUrl: './observations-insights.component.html',
  styleUrls: ['./observations-insights.component.scss']
})
export class ObservationsInsightsComponent implements OnInit {
  @Input() modalRef;
  @Input() subject;
  @Input() mainDomain

  public obsIns = {
    "subject_type": null,
    "subject_type2_service":null,
    "subject_name": null,
    "observation": null,
    "insight": null,
    "main_domain_name": null,
    "creator": null,
    "ip_for_service": null,
    "subject_id": null
  }

  public updatedObsIns = [];
  public allObsIns = [];
  public newObsIns = [];
  public clickedObs;
  public clickedIns;
  public editIndex: number;
  public editObsIns: boolean = false;
  public insight: boolean = false;
  public showDowloadText: boolean = false;
  public delObsIns
  public showSpinner: boolean = false;
  public hasObservation: boolean = false;
  public fieldsToDownload = {
    "Assets": "subject_name",
    "Observation": "observation",
    "Insight": "insight",
    "Commented By": "creator",
    "Commented At": "created_at",
    "Updated At": "updated_at"
  };
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRefhere: BsModalRef,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.obsIns.main_domain_name = this.mainDomain;
    this.obsIns.subject_name = this.subject['subject_name']
    this.obsIns.subject_type = this.subject['subject_type']
    if (this.subject['subject_id']) {
      this.obsIns.subject_id = this.subject['subject_id']
      }
    else {
      this.subject['subject_id'] = ''
    }
    if(this.subject['subject_type'] === 'service'){
      this.obsIns.subject_type = this.subject['subject_type'][0].toUpperCase() + this.subject['subject_type'].substr(1).toLowerCase()
      this.obsIns.subject_type2_service = 'IP: '
      this.obsIns.ip_for_service = this.subject['ip_for_service'] + ', '
      this.obsIns.subject_name = this.subject['service_subject_name']
    }
    this.getPocObsIns()
  }
  public getPocObsIns() {
    this.showSpinner=true
    this.hasObservation = false
    this.portfolioSurveyService.getPocObsIns(this.mainDomain, this.subject['subject_name'], this.subject['subject_id'], this.subject['subject_type']).subscribe((data: any) => {
      this.allObsIns = data.data
      for(let obsIns of this.allObsIns){
        if(obsIns.observation){
          this.hasObservation = true
        }
      }
      this.showSpinner = false
    }, error => {
      this.showSpinner = false;
    })
  }
  updatePocObsIns() {
    if (this.updatedObsIns && this.updatedObsIns.length) {
      this.portfolioSurveyService.updatePocObsIns(this.updatedObsIns, this.mainDomain).subscribe((data: any) => {
        this.updatedObsIns = []
        this.portfolioSurveyService._vulenrbilitySubject.next(true)
      })
    }
  }

  public doneWithChanges() {
    this.createPocObsIns()
    this.updatePocObsIns()
  }
  public createPocObsIns() {
    if (this.newObsIns && this.newObsIns.length) {
      this.portfolioSurveyService.createPocObsIns(this.newObsIns, this.mainDomain).subscribe((data: any) => {
        this.newObsIns = []
        this.portfolioSurveyService._vulenrbilitySubject.next(true)
      })
    }
  }
  addNewObsIns(obsins) {
    if(this.subject['subject_type'] === 'service'){
      this.obsIns.subject_type = this.subject['subject_type']
      this.obsIns.subject_name = this.subject['subject_name']
    }
    this.allObsIns.push({ ...obsins })
    if ((obsins.observation && obsins.observation.replace(/\s/g, "")) || (obsins.insight && obsins.insight.replace(/\s/g, ""))){
      this.newObsIns.push({ ...obsins })
    }
    this.obsIns.observation = null;
    this.obsIns.insight = null;
  }
  updateObsIns(obsins) {
    this.updatedObsIns.push({ ...obsins });
  }
  delObsInsModel(template: TemplateRef<any>, obsins) {
    this.delObsIns = obsins
    this.modalRefhere = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  insightModal(template: TemplateRef<any>,ins) {
    this.clickedIns = ins
    this.modalRefhere = this.modalService.show(template);
  }
  observationModal(template: TemplateRef<any>,obs) {
    this.clickedObs = obs
    this.modalRefhere = this.modalService.show(template);
  }
  delObsInsData() {
    this.delObsIns.archive = true
    this.portfolioSurveyService.updatePocObsIns([this.delObsIns], this.mainDomain).subscribe((data: any) => {
      this.portfolioSurveyService._vulenrbilitySubject.next(true);
    })
    this.modalRefhere.hide()
  }
  cancelDelete() {
    this.delObsIns = {}
    this.modalRefhere.hide()
  }
  closeInsight(){
    this.modalRefhere.hide()
  }
  closeObservation(){
    this.modalRefhere.hide()
  }
}