import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';

@Component({
  selector: 'app-portfolio-input',
  templateUrl: 'portfolio-input.component.html'
})
export class PortfolioInputComponent implements OnInit {
  public surveyData;
  public marketList;
  public previousSurveyData;
  public checkingSurveyForUser: boolean;
  public headcountOptions = this.portfolioSurveyService.headcountOptions;
  public revenueOptions = this.portfolioSurveyService.revenueOptions;
  public industryOptions = this.portfolioSurveyService.industryOptions;
  // public industriesListObj = this.portfolioSurveyService.industriesListObj;
  public searchProduct;
  public products = [];
  public implementedProducts = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public portfolioSurveyService: PortfolioSurveyService
  ) { }

  ngOnInit() {
    this.surveyData = {};
    this.portfolioSurveyService.getSurveyForUser().subscribe(
      (data:any) => {
        this.checkingSurveyForUser = false;
        if (data && data.length) {
          this.previousSurveyData = data;
          this.surveyData = JSON.parse(data[0].response_raw).orgDetails;
          this.marketList = JSON.parse(data[0].response_raw).marketList || [];
          this.marketList.forEach(element => {
            if (element.productImplemented) {
              element.productImplemented.forEach( p => {
                this.implementedProducts.push(p);
              });
            }
          });
        } else {

        }
      },
      (error) => {

      }
    )
  }

  searchProducts(searchString) {
    if (searchString.length > 2) {
        this.portfolioSurveyService.searchProducts(searchString).subscribe((data:any) => {
            // console.log(data);
            this.products = data;
        });
    } else {
      this.products = [];
    }
  }
  selectProduct(product) {
    const index = this.implementedProducts.findIndex( item => (item.product_id === product.product_id));
    if ( index === -1 ) {
      this.implementedProducts.push(product);
    }
    this.searchProduct = '';
    this.products = [];
  }

  removeProduct(product) {
    this.implementedProducts = this.implementedProducts.filter( item => (item.product_id !== product.product_id));
  }

  submitSurveyData(data) {
    this.marketList = [];
    this.implementedProducts.forEach(element => {
      const index = this.marketList.findIndex( item => ( item['category_domain_sname'] === element.domain_id.domain_sname));
      if (index === -1) {
        this.marketList.push({
          'category_domain_id': element.domain_id.domain_id,
          'category_domain_name': element.domain_id.domain_name,
          'category_domain_sname': element.domain_id.domain_sname,
          'category_domain_url_name': element.domain_id.domain_url_name,
          'productImplemented': [ element ],
          'implemented': true
        });
      }else {
        this.marketList[index]['productImplemented'].push(element);
      }
    });
    this.portfolioSurveyService.postSurveyForUser({ marketList: this.marketList, orgDetails: this.surveyData })
      .subscribe( (done) => {
        this.router.navigate(['/portfolio/analysis']);
      }, error => {
        // alert('Error Occurred');
        // this.router.navigate(['/']);
      }
  )
  }
}
