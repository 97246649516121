import { Component, OnInit, Input, TemplateRef, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SearchPipe } from 'app/pipes/search.pipe';
import { SortPipe } from 'app/pipes/sort.pipe';
import { reasonToDeleteFootPrint } from 'app/_services/common-var.service';
import { PortfolioSurveyService } from '../../_services';
import { FilterPipe } from 'app/pipes/filter.pipe';

@Component({
    selector: 'app-netblock',
    templateUrl: './netblock.component.html',
    styleUrls: ['./netblock.component.scss']
})
export class NetblockComponent implements OnInit {

    @Input() mainDomain: any;
    @Input() netblocks: any = [];
    @Input() isUserTrial: boolean = false;
    @Input() filters: any;
    @Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();

    public order = 1;
    public seeMore: number = 100;
    public domainsList = []
    public ipList = []
    public ispToTypeips: any = [];
    public ispToTypeIpsDropDown: any = [];
    // public ispTypeFilter = { 'owned': false, "affiliated": false }
    public showVul = -1;
    public listToScan: any = []
    public keysToSearch = ['asn_name', 'summary', 'net_block', 'netblock_details']
    public showBulk: boolean = false
    public reasonToDeleteFootPrint = reasonToDeleteFootPrint;
    public bulkArr: any = []
    public searchPipe = new SearchPipe();
    public filterPipe = new FilterPipe();
    public sortPipe = new SortPipe();
    // public oldNetblocks;
    public showDowloadText: boolean = false;
    public delReason = '';
    public multiSelectCount = 0;
    public singleDelete: Boolean = false;
    public singleSelectMonitoring;
    public openPopup: any;
    public scanProfiles: any;
    public editNetblockType = false;
    public editIndex = -1;
    public fieldsToDownload = {
        'ISP': 'asn_name',
        'IP Range': 'net_block',
        'No.Of Domains': 'domains',
        'No.Of IPs': 'ips',
        'Created At': 'created_at',
        'Updated At': 'updated_at'
    }
    public monitors = []
    public openDropdown;
    public openSlider;
    public showSpinner = true;
    public showError;
    public page = 1;
    public limit = 10;
    public searchKey = null;
    public total_netblock_count: number = 0;
    // public selectedTag = "OWNED"
    public selectedTag
    public selectedSummary = []
    public selectedAsnName = []
    public tags = []
    public sortKey = null;
    public NetBlockTypeCheck = null;
    public dataToSend = {};
    public inscopeConfig = null
	public inscopeFlag = null
	public showThis = null
    public loadingIps:boolean = false;
    public loadingDomains:boolean = false;
    public tagsFilterList:any = [];
    public selectedFilterTags:any = []
    public reportFilterAsnName:any = []
    public reportFilterSummary:any = []



    constructor(public commonService: CommonService,
        public modalService: BsModalService,
        public portfolioSurveyService: PortfolioSurveyService,
        public modalRef: BsModalRef,
        public portfolioService:PortfolioSurveyService
    ) { }



    ngOnInit() {
        this.inscopeConfig = localStorage.getItem('scope_config')
			if(this.inscopeConfig == 'true'){
			  this.showThis = 'inscope'
			} else this.showThis = 'tag'

    }

    ngOnChanges(changes: SimpleChanges) {
        const changeFilters: SimpleChange = changes.filters
        if (changeFilters) {
            if (this.filters && this.filters.page) {
                this.page = this.filters.page
                delete this.filters.page
            }
            this.getNetblocks()
        }
        if (changes && changes.mainDomain) {
            this.portfolioSurveyService.getNetblockIspForDomains(this.mainDomain.domain_name).subscribe(ispData => {
                this.ispToTypeIpsDropDown = [...ispData['summary_data'], ...ispData['asn_data']]

            });
            this.getTgasforFilter(this.mainDomain.domain_name);
        }
    }
    getTgasforFilter(domain) {
        this.portfolioService.getTgasforFilter(domain).subscribe((data: any) => {
            console.log('getTgasforFilter', data);
            let tags = [];
            // data.data = ['tag1','tag2','tag3'];
            if(data.data && data.data.length>0){
                
                data.data.map((x)=>{
                    let tagObj = {
                        name:x,
                        selected:false
                    }
                    tags.push(tagObj);
                });
            }
            this.tagsFilterList = tags;
        }, (error) => {
            console.error("tags error", error);
        });
    }
    changeSelectedStae(val) {
		val['selected'] = !val['selected'];
	}
    filterIpsByTag(value) {
		this.page = 1
		if (value == 'clear') {
			this.tagsFilterList.forEach(tag => {
				tag.selected = false
			})

			this.selectedFilterTags = []
		} else {
			const selectedTagsArr = this.tagsFilterList.map(t => {
				if (t.selected) {
					return t['name']
				}
			})
			this.selectedFilterTags = selectedTagsArr.filter(t => t).join(',')
            this.filters = { ...this.filters, tags: this.selectedFilterTags };

		}

		this.getNetblocks()
	}
    subdomainOpenModal(template: TemplateRef<any>, data) {
        this.modalHide()
        if (data.domains_count <= 0) return
        this.domainsList = []

        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
        if (data.domains_count > 0) {
            var netblockFilterValue;
            var netblockFilterKey;
            if (data.summary) {
                netblockFilterValue = data.summary
                netblockFilterKey = 'summary'
            }
            else if (data.asn_name) {
                netblockFilterValue = data.asn_name
                netblockFilterKey = 'asn_name'
            }
            else {
                netblockFilterKey = null
                netblockFilterValue = null
            }
            this.loadingDomains = true;
            this.portfolioSurveyService.getNetblockdomain(this.mainDomain['domain_name'], netblockFilterKey, netblockFilterValue, data.net_block).subscribe(element => {
                this.domainsList = element[0].domains;
                this.loadingDomains = false;
            },(err)=>{
                this.loadingDomains = false;
            })
        }
    }
    ipOpenModal(template: TemplateRef<any>, data) {
        this.modalHide()
        if (data.ips_count <= 0) return
        this.ipList = []
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
        if (data.ips_count > 0) {
            var netblockFilterValue;
            var netblockFilterKey;
            if (data.summary) {
                netblockFilterValue = data.summary
                netblockFilterKey = 'summary'
            }
            else if (data.asn_name) {
                netblockFilterValue = data.asn_name
                netblockFilterKey = 'asn_name'
            }
            else {
                netblockFilterKey = null
                netblockFilterValue = null
            }
            this.loadingIps = true;
            this.portfolioSurveyService.getNetblockIps(this.mainDomain['domain_name'], netblockFilterKey, netblockFilterValue, data.net_block,'ALL').subscribe(element => {
                this.ipList = element[0].ips;
                this.loadingIps = false;
            },(err)=>{
                this.loadingIps = false;
            })

        }
    }
    modalHide() {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
    selctForBulkAction(value) {
        if (value === true) {
            this.netblocks.forEach(net => {
                net.selected = true
                this.multiSelectCount++;
            })
        } else {
            this.netblocks.forEach(net => {
                net.selected = false
            })
            this.multiSelectCount = 0;
        }
    }
    uncheckDelete(a = false) {
        this.netblocks.map(a => {
            a.selected = false;
            a.updated = false;
        })
        this.singleDelete = false;
        this.multiSelectCount = 0
        this.delReason = ''

    }
    openRemoveOptions(template: TemplateRef<any>, netblk = {}) {
        if (this.singleDelete) {
            this.uncheckDelete();
        }
        if (netblk && netblk['net_block']) {
            netblk['selected'] = true;
        }
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
    }
    // searchNetblock(value) {
    //     this.netblocks = this.searchPipe.transform(this.oldNetblocks, this.keysToSearch, value);
    // }
    // filterNetblock(filter_object) {
    //     this.netblocks = this.filterPipe.transform(this.oldNetblocks, filter_object);
    // }
    selectedNewBox(nblk) {
        nblk.selected = !nblk.selected;
        if (nblk.selected) {
            this.multiSelectCount++;
        } else {
            this.multiSelectCount = this.multiSelectCount > 0 ? this.multiSelectCount - 1 : 0;
        }
    }
    delNetBlock() {
        this.netblocks.forEach(sd => {
            if (sd['selected']) {
                sd.updated = true;
                sd['archive'] = true;
                sd['archive_reason'] = this.delReason;
            }
        })
        this.portfolioSurveyService.updateNetblocks(this.netblocks, this.mainDomain['domain_name']).subscribe(data => {
            alert("Netblock deleted successfully!")
            this.multiSelectCount = 0;
            this.getNetblocks();
            this.refreshCount.emit('netblock');
        }, error => {
            if (error['status'] === 403) {
                alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
            } else {
                alert("Error while updating")
            }
        })
        this.modalRef.hide()
    }
    deleteDataCheck(value) {
        this.delReason = value;
        this.delNetBlock()
    }
    getProfileName(spCode) {
        return this.scanProfiles[spCode] ? this.scanProfiles[spCode]['name'] : null
    }
    getNetblocks() {
        this.showSpinner = true
        if (this.mainDomain["domain_name"]) {
            var params = {
                page: this.page,
                limit: this.limit,
                searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
                ...this.filters,
                scope: this.inscopeFlag,
                netblockType : this.selectedTag ?  this.selectedTag : null,
                tags:this.selectedFilterTags ?  this.selectedFilterTags : null

            }
            if (this.sortKey) params.sortKey = this.order == -1 ? '-' + this.sortKey : this.sortKey
            this.portfolioSurveyService.getNetblocksPaginated(this.mainDomain["domain_name"], params).subscribe(data => {
                this.showSpinner = false

                if (data) {
                    this.netblocks = data['data'].results
                    this.total_netblock_count = data['data']['count']
                } else {
                }
            }, error => {
                this.commonService.errorForDomain(error);
            })
        }
    }
    goToPage(val) {
        this.page = val;
        this.getNetblocks()
    }
    searchAllNetblockDetail() {
        this.searchKey = null
        this.getNetblocks()
    }
    searchNetblockDetail(domain) {
        this.page = 1;
        this.getNetblocks()
    }
    filterIpsByType(value) {
        this.page = 1
        this.selectedTag = value
        if (this.selectedTag) {
            this.filters = { ...this.filters, netblockType: this.selectedTag }
        } else delete this.filters.netblockType
        this.getNetblocks()
        this.openDropdown = false
    }
    showIspName(isp) {
        if (isp['asn_name']) return isp['asn_name']
        else return isp['summary']
    }
    showIspToTypeIps(tag) {
        if (tag.selected) {
            this.tags.push(tag)
        }
    }
    searchNetblocgByIsp() {
        this.selectedAsnName = []
        this.selectedSummary = []
        this.page = 1;
        this.tags.forEach(t => {
            if (t.selected) {
                if (t.hasOwnProperty('asn_name')) {
                    let val_asn = '"' + encodeURIComponent(t.asn_name) + '"'
                    this.selectedAsnName.push(val_asn)
                    this.reportFilterAsnName.push(t.asn_name)

                } else if (t.hasOwnProperty('summary')) {
                    let val_summary = '"' + encodeURIComponent(t.summary) + '"'
                    this.selectedSummary.push(val_summary)
                    this.reportFilterSummary.push(t.summary)


                }
            }
        })
        this.filters = {
            ...this.filters,
            asn_name: this.selectedAsnName && this.selectedAsnName.length ? this.selectedAsnName : null,
            summary: this.selectedSummary && this.selectedSummary.length ? this.selectedSummary : null,
            asn_names: this.reportFilterAsnName && this.reportFilterAsnName.length ? this.reportFilterAsnName : null,
            summaries: this.reportFilterSummary && this.reportFilterSummary.length ? this.reportFilterSummary : null
            
        }
        this.getNetblocks()
    }
    getAllData() {
        delete this.filters.summary
        delete this.filters.asn_name
        this.selectedSummary = []
        this.selectedAsnName = []
        this.tags.forEach(t => {
            t.selected = false;
        })
        this.getNetblocks()
    }

    updateNetblockType(data) {
        if (this.NetBlockTypeCheck === data.netbock_type) {
            this.editIndex = -1
            this.editNetblockType = false
        }
        else {
            this.editIndex = -1
            this.editNetblockType = false
            this.dataToSend = {
                "id": data.id,
                "netblock_type": data.netblock_type

            }
            this.portfolioSurveyService.updateNetblockType(this.mainDomain["domain_name"], this.dataToSend).subscribe(
                (data) => {
                    alert("Netblock Type updated successfully!")
                },
                (error) => {
                    alert("Error while updating!")
                }
            );
        }

    }
    filterByInscope(value) {
		this.page = 1;
		this.inscopeFlag = value;
		this.filters = { ...this.filters, scope: value };
		this.getNetblocks()
		this.openDropdown = false;
	  }
}
