import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import { temporaryAllocator } from "@angular/compiler/src/render3/view/util";
import { take, switchMap } from "rxjs/operators";
// import { AuthService } from "../views/pages/auth.service";
import jwt_decode from "jwt-decode";
import { fcCloudStorageClient, max_hours_of_inactivity_before_logout } from "../../environments/environment"
import { TrialCheckService } from "app/_services/trial-check.service"
import { Session_expire_commonService } from "app/_services/session_expire_common.service"

let lastActivityTimestamp;
let apiSkipCounter = 0;
@Injectable()
export class HttpService {
  public headers = {
    headers: {
      "Content-Type": "application/json",
      // "Content-Type": "text/csv",
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    },
  };
  constructor(private http: HttpClient) {}

  public get(url: string) {
    const data = this.http.get(url);
    return data;
  }

  public getWithoutBearer(url: string) {
    const options = {
      headers: new HttpHeaders({
        skip: "true",
        "Content-Type": "text/csv",
      })
    }
    return this.http.get(url, options)
  }

  public post(url: string, dataToSend: any) {
    return this.http.post(url, dataToSend);
  }

  public uploadFile(url: string, dataToSend: any) {
    const options = {
      headers: new HttpHeaders({
        mimeType: "multipart/form-data",
        "Content-Type": "multipart/form-data",
      }),
    };
    return this.http.post(url, dataToSend);
  }

  public put(url: string, dataToSend: any) {
    return this.http.put(url, dataToSend);
  }

  public delete(url: string, dataToSend: any = null) {
    if (dataToSend) {
      const options = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        body: dataToSend,
      };
      return this.http.delete(url, options);
    } else return this.http.delete(url);
  }
  public patch(url: string, dataToSend: any) {
    return this.http.patch(url, dataToSend, this.headers);
  }
  public uploadFileToBucket(url: string, file) {
    let options = {};
    if (fcCloudStorageClient.storage == "GOOGLE_STORAGE") {
      options = {
        headers: new HttpHeaders({
          skip: "true",
          "Content-Type": "text/csv"
        }),
      }
    } else {
      options = {
        headers: new HttpHeaders({
          skip: "true",
          "Content-Type": "text/csv",
          "x-ms-blob-type": "BlockBlob"
        }),
      }
    }
    return this.http.put(url, file, options);
  }

  public uploadFileToBucketV2(url: string, file, content_type : string) {
    let options = {};
    if (fcCloudStorageClient.storage == "GOOGLE_STORAGE") {
      options = {
        headers: new HttpHeaders({
          skip: "true",
          "Content-Type": content_type
        }),
      }
    } else {
      options = {
        headers: new HttpHeaders({
          skip: "true",
          "Content-Type": content_type,
          "x-ms-blob-type": "BlockBlob"
        }),
      }
    }
    return this.http.put(url, file, options);
  }
}
@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  token: string;
  private max_hours_of_inactivity_before_logout:number =  max_hours_of_inactivity_before_logout.hour; // hour
  constructor(public auth: AngularFireAuth,
    public trialCheckService:TrialCheckService,
    public sessionExpireCommonService: Session_expire_commonService
    ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get("skip")) {
      req = req.clone({
        headers: req.headers.delete('skip', 'true')
      })
      return next.handle(req)
    }
    const token: any = jwt_decode(localStorage.getItem("id_token"));
    const currentTime:any = new Date().getTime() / 1000;
    if (req.method == 'GET') {
      apiSkipCounter = 0;
      if (!this.sessionExpireCommonService.isSessionTimedOut()) {
        localStorage.setItem("lasActivity", currentTime);
      }
    } else {
      apiSkipCounter++;
    }
    if (token.exp > currentTime) {
      req = req.clone({
        setHeaders: {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        },
      });
      return next.handle(req);
    } else {
      console.log("Making Token Request");
      return this.auth.idToken.pipe(
        take(1), // <-------------- only emit the first value!
        switchMap((token: any) => {
          if (token) {
            req = req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
          }
          this.trialCheckService.trialPeriodOver();
          return next.handle(req);
        })
      );
    }
  }
}
