import { Component, OnInit, Input } from '@angular/core';
import { technology_base_url } from 'app/_services/common-var.service';

@Component({
  selector: 'app-vulnerablity-enumeration',
  templateUrl: './vulnerablity-enumeration.component.html',
  styleUrls: ['./vulnerablity-enumeration.component.scss']
})
export class VulnerablityEnumerationComponent implements OnInit {

  public showThis = 'applications';
  @Input() technologies: any;
  @Input() showVulnerablities: false;
  @Input() isUserTrial: false;
  public technology_base_url = technology_base_url;

  constructor() { }

  ngOnInit() {
  }

  getKeys = Object.keys;

  lowCve(cv) {
    if (cv.cve_score >= 0 && cv.cve_score < 4) {
      return true;
    }
    return false;
  }

  mediumCve(cv) {
    if (cv.cve_score >= 4 && cv.cve_score < 7) {
      return true;
    }
    return false;
  }

  highCve(cv) {
    if (cv.cve_score >= 7 && cv.cve_score < 9) {
      return true;
    }
    return false;
  }

  criticalCve(cv) {
    if (cv.cve_score >= 9 && cv.cve_score <= 10) {
      return true;
    }
    return false;
  }
  verifyCve(cv){
    const regexExp = /CVE-\d{4}-\d{4,7}/
    const found = cv.cve_id.match(regexExp);
    if(found) return true
    else return false
  }
  displayCve(cv) {
    if (this.technologies && this.technologies.type) {
      if (this.technologies.type == 'total') {
        return true;
      } else if (this.technologies.type == 'low') {
        return this.lowCve(cv);
      } else if (this.technologies.type == 'medium') {
        return this.mediumCve(cv);
      } else if (this.technologies.type == 'high') {
        return this.highCve(cv);
      } else if (this.technologies.type == 'critical') {
        return this.criticalCve(cv);
      } else {
        return false;
      }
    } else {
      return true
    }
  }
  checkCveType(application) {
    if (application['cveDetail'] && this.technologies && this.technologies.type) {
      var cveDetails = application['cveDetail'] ? application['cveDetail'] : {};
      if (this.technologies.type == 'total') {
        if (cveDetails.count) {
          return true;
        }
      } else if (this.technologies.type == 'low') {
        if (cveDetails['low'] && cveDetails['low'].length) {
          return true;
        }
      } else if (this.technologies.type == 'medium') {
        if (cveDetails['medium'] && cveDetails['medium'].length) {
          return true;
        }
      } else if (this.technologies.type == 'high') {
        if (cveDetails['high'] && cveDetails['high'].length) {
          return true;
        }
      } else if (this.technologies.type == 'critical') {
        if (cveDetails['critical'] && cveDetails['critical'].length) {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
    return false;
  }

}
