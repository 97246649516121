import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-graph-runstep',
  templateUrl: './graph-runstep.component.html',
  styleUrls: ['./graph-runstep.component.scss']
})
export class GraphRunstepComponent implements OnInit {

  @Input() view: any = [];
  @Input() links: any = [];
  @Input() nodes: any = [];
  @Input() enableZoom:boolean = true;
  @Input() autoCenter:boolean = true;
  @Input() autoZoom:boolean = true;
  @Input() layoutSettings: any = {
    orientation: 'LR'
  };
  @Output() showNodeDetails = new EventEmitter<any>();
  @Output() sendDescription = new EventEmitter<any>();
  @Output() showStepActions = new EventEmitter<any>();

  selectedStepAction:any = {};
  selectedStepActions:any = [];

  constructor() {
    console.log('view',this.view);
    console.log('links',this.links);
    console.log('nodes',this.nodes);
    console.log('layoutSettings',this.layoutSettings);
   }

  ngOnInit(): void {
  }

  emitShowNodeDetails(node,visibility){
    this.showNodeDetails.emit({node,visibility});
  }

  emitShowStepActions(actions,visibility){
    this.showStepActions.emit({actions,visibility})
  }

  subHeight(index,height,yOffset){
    return (height*index)+yOffset;
  }
  getMaxWidthOfNode(node){
    if(node.data.stepactions){
      let lengths:any = [];
      lengths.push(node.label.length);
      node.data.stepactions.forEach(nd => {
        lengths.push(nd.name.length);
      });
      return Math.max(Math.max(...lengths)*8,node.dimension.width);
    }else{
      return node.dimension.width;
    }
  }
}
