import { Injectable, EventEmitter, Inject, PLATFORM_ID } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Location, isPlatformBrowser, isPlatformServer } from "@angular/common";
import { PortfolioSurveyService } from "app/_services";
// Avoid name not found warnings
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import jwt_decode from "jwt-decode";
import { timeout } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { resolve } from "@angular/compiler-cli/src/ngtsc/file_system";
import { rejects } from "assert";
import * as firebase from "firebase";
import { promises } from "dns";
import { ToastrService } from "ngx-toastr";
import { TrialCheckService } from "app/_services/trial-check.service";
import { Auth_logoutService } from "app/_services/auth_logout.service";
@Injectable()
export class AuthService {
  public loginError: Boolean = false;
  public resetSuccess: Boolean = false;
  showLoginPageEvent = new EventEmitter<boolean>();
  showSignupPageEvent = new EventEmitter<boolean>();
  public tryingToLoginEmitter = new EventEmitter<boolean>();
  public signUpErrorUserAlreadyExists: Boolean = false;
  public user_id = null;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public auth: AngularFireAuth,
    public portfolioSurveyService: PortfolioSurveyService,
    public aff: AngularFireFunctions,
    private ngxService: NgxUiLoaderService,
    private firestore: AngularFirestore,
    private toastr: ToastrService,
    public trialCheckService: TrialCheckService,
    public logoutService:Auth_logoutService
  ) {
    this.tryingToLogin(true);
    if (localStorage.getItem("profile")) {
      const data = JSON.parse(localStorage.getItem("profile"));
      this.user_id = data.user_id;
    }
  }

  public async getLastestToken() {
    return new Promise((resolve,reject)=>{

      localStorage.setItem("forceTokenUpdate", "true"); // Flag to prevent calling onIdTokenChanged multiple times
      console.log("getLastestToken");
      let that = this;
      this.auth.onIdTokenChanged(function (user) {
        console.log("Inside  onIdTokenChanged");
        //&& localStorage.getItem("forceTokenUpdate") === "true"
        if (user && localStorage.getItem("forceTokenUpdate") === "true") {
          console.log(
            "User Token Updated",
            user.multiFactor.enrolledFactors.length
          );

          if (user.multiFactor.enrolledFactors.length >= 1) {
            localStorage.setItem("isMFAEnabled", "true");
          } else {
            localStorage.setItem("isMFAEnabled", "false");
          }

          user.getIdToken(true).then((data) => {
            localStorage.setItem("id_token", data);
            resolve('done');
            const token: any = jwt_decode(data);
            console.log(token);
            // this.user_id = token.user_id;
            localStorage.setItem("profile", JSON.stringify(token));
            localStorage.setItem("forceTokenUpdate", "false");
            that.trialCheckService.trialPeriodOver();
          });
        }
      });
    
    });
  }

  public login(userEmail: string, password: string): Promise<any> {
    let username = userEmail.toLowerCase();
    return new Promise((resolve, reject) => {
      this.auth.signInWithEmailAndPassword(username, password).then(
        (res: any) => {
          this.loginError = false;
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    }); // End of Promise
  }

  public sendOTP(phoneOpts, appVerifier) {
    const phoneAuthProvider = new firebase.default.auth.PhoneAuthProvider();

    return new Promise((resolve, reject) => {
      phoneAuthProvider.verifyPhoneNumber(phoneOpts, appVerifier).then(
        (result) => {
          console.log(result);
          this.toastr.success(
            "OTP has been sent to your registered Mobile Number"
          );
          resolve(result);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  public getmultiFactorAssertion(verificationCode, OTP) {
    const cred = firebase.default.auth.PhoneAuthProvider.credential(
      verificationCode,
      OTP
    );
    const multiFactorAssertion =
      firebase.default.auth.PhoneMultiFactorGenerator.assertion(cred);

    return multiFactorAssertion;
  }
  public validateOTP(verificationCode, OTP, resolver) {
    this.toastr.info("Validating OTP");
    const multiFactorAssertion = this.getmultiFactorAssertion(
      verificationCode,
      OTP
    );
    return new Promise((resolve, reject) => {
      console.log("Inside Promise");
      resolver.resolveSignIn(multiFactorAssertion).then(
        (res) => {
          console.log(res);
          this.toastr.success("OTP has been verified Successfully");
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public getWhitelableDetails(cID) {
    console.log("getWhitelableDetails", cID);

    this.firestore
      .collection("/metadata/entities/customers/")
      .doc(cID)
      .get()
      .subscribe((res) => {
        const customerDetails: any = res.data();
        if (
          customerDetails.whiteLabel &&
          customerDetails.whiteLabel.type !== "none"
        ) {
          localStorage.setItem(
            "whiteLabel",
            JSON.stringify(customerDetails.whiteLabel)
          );
          this.router.navigate(["new/self-domains"]);
        } else {
          this.router.navigate(["new/self-domains"]);
        }
      });
  }

  public forgotPassword(userEmail): void {
    const email = userEmail.toLowerCase();
    this.auth.sendPasswordResetEmail(email);
  }

  public isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("isAuthenticated")) {
        return true;
      } else {
        return false;
      }
    }
  }

  logoutEmmiter = new EventEmitter<boolean>();

  public logout(): void {
    console.log("Logout");
    this.logoutService.logout();
  }

  private setUser(authResult): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("access_token", authResult.accessToken);
      localStorage.setItem("id_token", authResult.idToken);
    }
  }

  public showLoginPage(value: boolean) {
    this.showLoginPageEvent.emit(value);
  }

  public showSignupPage(value: boolean) {
    this.showSignupPageEvent.emit(value);
  }

  public tryingToLogin(val: boolean) {
    this.tryingToLoginEmitter.emit(val);
  }

  public checkEmail() {
    this.auth.currentUser.then((res) => {
      console.log(res);
      res.sendEmailVerification().then(
        (res) => {
          this.toastr.info("Email verification link has been sent");
          console.log(res);
        },
        (err) => {
          this.toastr.error("Error in sending email verification link");
          console.log(err);
        }
      );
    });
  }

  public getUserSession(currentUser) {
    return new Promise((resolve, reject) => {
      currentUser.multiFactor.getSession().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public revertBack() {
    this.ngxService.start();
    const callable = this.aff.httpsCallable("rollBackClaims");
    const reverBackClaims = callable({});
    reverBackClaims.subscribe((res) => {
      console.log("Rollback Claims Response");
      console.log(res);
      if (res.customClaims.parent.isScopeChanged === false) {
        this.getLastestToken();

        setTimeout(() => {
          this.ngxService.stop();
          localStorage.removeItem("mydomain");
          this.router.navigate(["/new/self-domains"]);
        }, 3000);
      }
    });
  }

  public getUserPref(uID) {
    console.log("Reading User Preference for ", uID);

    return new Promise((resolve, reject) => {
      this.firestore
        .collection("/users/")
        .doc(uID)
        .get()
        .subscribe((res) => {
          const userPreference: any = res.data();
          localStorage.setItem(
            "userPreference",
            JSON.stringify(userPreference.pref)
          );
          resolve(userPreference.pref);
        });
    });
  }

  public updateUserPref(data) {
    console.log("updateUserPref for ", this.user_id);
    this.firestore.collection("/users/").doc(this.user_id).update(data);
  }
}
