import { Injectable } from "@angular/core";
import { TrialExpiredService } from "./trial-expired.service";
import jwt_decode from "jwt-decode";

@Injectable({
	providedIn: 'root'
})
export class TrialCheckService {

	public expireCheckTime: any = null;
	constructor(
		public trialExpired: TrialExpiredService
	) { }

	public trialPeriodOver() {
		//console.log('Expired', this.isTrialOver());
		if (this.isTrialOver()) {
			let that = this;
			if (!this.expireCheckTime) {
				this.expireCheckTime = setTimeout(function () {
					that.trialExpired.emit(true);
					clearTimeout(this.expireCheckTime);
					that.expireCheckTime = null;
				}, 2000);
			}
			//console.log('Your trial period has expired.', this.isTrialOver());
		} else {
			//console.log('Profile active', this.isTrialOver());
		}
	}
	public isTrailUser(){
		let token_string: any = localStorage.getItem('id_token');
		let token:any = jwt_decode(token_string);
		return (token['paid'])?false:true;
	}
	public isTrialOver() {
		let token_string: any = localStorage.getItem('id_token');
		let token = jwt_decode(token_string);
		//console.log('Paid?', token['paid']);
		//console.log('TrailEndDate', token['trailEndDate']);
		if (!token['paid'] && token['trailEndDate']) {
			let endDate = new Date(token['trailEndDate']);
			let today = new Date();
			//console.log('Times', today.getTime(), endDate.getTime());
			if (today.getTime() > endDate.getTime()) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}
