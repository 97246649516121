import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {

	transform(list: any, filter_object?: any, count?: any) {
		if (!list || list.length <= 0 || Object.keys(filter_object).length <= 0) {
			return list;
		}
		let filterData = []
		list.forEach(el => {
			for (let key in filter_object) {
				let value = this.deepGet(el, key.split('.'))
				if (value) {
					let searchKeys = filter_object[key];
					searchKeys.forEach(search => {
						if (search == value) {
							filterData.push(el);
						}
					});
				}
			}
		});
		return filterData
	}

	deepGet(obj, properties) {
		// If we have reached an undefined/null property
		// then stop executing and return undefined.
		if (obj === undefined || obj === null) {
			return;
		}

		// If the path array has no more elements, we've reached
		// the intended property and return its value.
		if (properties.length === 0) {
			return obj;
		}

		// Prepare our found property and path array for recursion
		let foundSoFar = obj[properties[0]];
		let remainingProperties = properties.slice(1);

		return this.deepGet(foundSoFar, remainingProperties);
	}
}