import { Component, OnInit, Input, TemplateRef, SimpleChanges } from "@angular/core";
import { PortfolioSurveyService } from "app/_services";
import { CommonService } from '../../../_services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: "app-paste-data",
  templateUrl: "./paste-data.component.html",
  styleUrls: ["./paste-data.component.scss"],
})
export class PasteDataComponent implements OnInit {
  @Input() mainDomain: any;
  @Input() isUserTrial = false;
  constructor(public portfolioSurveyService: PortfolioSurveyService,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    public commonService: CommonService) { }
  public pasteData: any;
  public showIntelxUrl = false
  public showItem_i = -1
  public showSpinner = true
  public page = 1;
  public limit = 10;
  public order = -1;
  public sortKey = null
  public pasteDataCount;
  public deteleSingleData = null
  public searchKey = null
  public showDowloadText: boolean = false;
	public fieldsToDownloadLeakedContents = {
    'Link':'cached_data_url',
    'Name':'subject_name',
    'Category': 'source',
    'Relevance Score':'rev_x_score',
    'Last Seen At': 'discovered_at',
		'Created At': 'created_at',
		'Updated At': 'updated_at',
	}
  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.mainDomain) {
      this.getPasteData()
    }
  }
  getPasteData() {
    this.showSpinner = true
    const params = {
      page: this.page,
      limit: this.limit,
      searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
    }
    if (this.sortKey) params['sortKey'] = this.order == -1 ? this.sortKey : '-' + this.sortKey
   
    this.portfolioSurveyService.getPasteData(this.mainDomain['domain_name'], params).subscribe((data) => {
        this.showSpinner = false
        this.pasteData = data['data'].results
        this.pasteDataCount = data['data'].count
      });
  }

  goToPage(val) {
    this.page = val;
    this.getPasteData()
  }
  openRemoveOptions(template: TemplateRef<any>, paste) {
    paste.selected = true;
    this.deteleSingleData = paste
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
  }
  delReason(reason) {
    this.delPasteData(reason)

  }
  delPasteData(reason) {
    if (this.deteleSingleData.selected == true) {
      this.deteleSingleData.updated = true;
      this.deteleSingleData.archive = true;
      this.deteleSingleData.archive_reason = reason;
      delete this.deteleSingleData['selected']
    }
    this.upadtePasteData();
  }

  public upadtePasteData() {
    this.portfolioSurveyService.upadtePasteData(this.mainDomain['domain_name'], [this.deteleSingleData]).subscribe(data => {
      this.modalRef.hide();
      alert("Enitity deleted successfully!")

    }, err => {
      this.modalRef.hide();
    });
  }
  cancelDel(a) {
    this.pasteData.data.results.map(content => {
      content.selected = false
    })
  }
  searchAllIpDetails() {
    this.searchKey = null
    this.getPasteData()
  }
  searchIpDetails(domain) {
    this.page = 1;
    this.getPasteData()
  }
  isIntelxUrl(url) {
    return url.includes('https://intelx.io')
  }
}
