import { Component, Input, OnInit } from '@angular/core';
import { PortfolioSurveyService } from 'app/_services/portfolio-survey.service';
import { CommonService } from "app/_services/common.service";
@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {

  @Input() domain: any;

  public viewEntities = [0, 1, 2, 3, 4];
  public currentCounter = 1;
  public selectedEntity = "top_risk_insights";
  public topRisk;
  public sortWith: string = "cves";
  public order: number = -1;
  public initialSort: boolean = true;
  public counts = {
    'exposedservice':null,
    'web_app_vulnerability':null,
    'exposeddatabase':null,
    'leakcred':null,
    'takeoverrisk':null,
    'prepodservice':null,
    'network_vulnerability':null
  };
  public noDataFoundTitle = {
    "takeoverRisk":"takeover risks",
    "preprodServices":"preprod services",
    "exposedservice":"exposed services",
    "exposed_databases":"exposed databases"
  }
  public getDataMap = {
    loading:false,
    exposed_databases:{
      key:'exposedDatabase',
      data:[]
    },
    exposedservice:{
      key:'exposedServices',
      data:[]
    },
    preprodServices:{
      key:'preprodServices',
      data:[]
    },
    takeoverRisk:{
      key:'takeoverRisk',
      data:[]
    }
  }
  public ipDetails: boolean = false;
  public getIpdetails: any = {};

  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService
  ) {

  }

  ngOnInit(): void {
    this.getTopRisk();
  }

  setEntityType(entity) {
    this.ipDetails = false;
    this.selectedEntity = entity;
    if(
      this.selectedEntity!="top_risk_insights" && 
      this.selectedEntity!="network_vulnerability" &&
      this.selectedEntity!="web_app_vulnerability"
    ){
      this.getDataByKey(this.selectedEntity);
    }
  }
  getDataByKey(key){
    let obj = this.getDataMap[key];
    this.getDataMap.loading = true;
    this.portfolioSurveyService
    .getRiskDetails(this.domain, obj.key, {})
    .subscribe((data: any) => {
      console.log(data);
      if (data["status"] == "pass") {
        obj.data = data["data"]
      } else {
        obj.data = [];
      }
      this.getDataMap.loading = false;
    }, (error) => {
      this.getDataMap.loading = false;
      obj.data = [];
    });
  }
  scrollToRight() {
    if (this.currentCounter <= 2) {
      const firstNumber: any = this.viewEntities.shift()
      this.viewEntities.push(firstNumber + 5)
      this.currentCounter += 1;
    }
  }
  scrollToLeft() {
    if (this.currentCounter >= 1) {
      const lastNumber: any = this.viewEntities.pop()
      this.viewEntities.unshift(lastNumber - 5)
      this.currentCounter -= 1;
    }
  }
  checkScroll(val) {
    return this.viewEntities.includes(val)
  }
  

  public getTopRisk() {
    this.portfolioSurveyService
      .getTopRisk(this.domain)
      .subscribe((data: any) => {
        this.topRisk = data.data;
      });
  }
  public countEmitor($event){
    if($event && $event.key){
      console.log($event);
      this.counts[$event.key] = $event.count;
    }
  }
  showIpDetails(ip) {
    this.ipDetails = false;
    this.portfolioSurveyService
      .getIpDetails(ip, this.domain)
      .subscribe((data: any) => {
        this.getIpdetails["subjects"] = [];
        // this.getIpdetails['ip'] = ""
        this.getIpdetails["ip"] = ip;
        data.data = data.data.filter((d) => d.host != null);
        if (data.data.length == 0) {
          this.getIpdetails["subjects"].push({
            name: "NA",
            discovered_at: "NA",
          });
        } else {
          this.getIpdetails["subjects"] = [];
        }
        data.data.forEach((ele) => {
          if (ele.host) {
            this.getIpdetails["subjects"].push({
              name: ele.host ? ele.host : "NA",
              created_at: ele.created_at,
              updated_at: ele.updated_at,
              discovered_at: ele.discovered_at ? ele.discovered_at : "NA",
            });
          }
        });

        this.getIpdetails["subjects"].forEach((ele) => {
          if (ele.name != null) this.ipDetails = true;
        });
      });
  }
}
