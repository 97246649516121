'use strict';
export const currentPage: number = 1; // the current page
export const dataPerPage = 10; // how many items we want to show per page
export const count: number = 1; // how many total items there are in all pages
export const pagesToShow: number = 7; // how many pages between next/prev
export const order: number = -1;
export const changeOrder: number = -1;
export const scanProfiles = [
    {
        "intent": "All",
        "modules" : null,
        "scan_type": 'deepc_scan'
    },
    {
        "intent": "Nessus",
        "modules" : ['mod_nessus', 'mod_dns_resolve'],
        "scan_type": 'active_scan'
    },
    {
        "intent": "Openvas",
        "modules": ['mod_openvas', 'mod_dns_resolve'],
        "scan_type": 'active_scan'
    },
    {
        "intent": 'Portscan',
        "modules": ['mod_nmap', 'mod_dns_resolve'],
        "scan_type": 'active_scan'
    },
    {
        "intent": 'Router Sploit',
        "modules": ['mod_routersploit', 'mod_dns_resolve'],
        "scan_type": 'active_scan'
    },
    {
        "intent": 'Jok3r Network',
        "modules": ['mod_active_network_va_rekoj', 'mod_nmap', 'mod_dns_resolve'],
        "scan_type": 'active_scan'
    },
    {
        "intent": 'Amass Bruteforce',
        "modules": ['mod_bruteforce_amass'],
        "scan_type": 'active_scan'
    },
    {
        "intent": 'Arachani',
        "modules": ['mod_arachni'],
        "scan_type": 'active_scan'
    },
    {
        "intent": 'Jok3r Webapp',
        "modules": ['mod_active_http_ports_va_rekoj', 'mod_active_webapp_va_rekoj', 'mod_dns_resolve', 'mod_nmap'],
        "scan_type": 'active_scan'
    },
    {
        "intent": 'Bucket Bruteforce',
        "modules": ['mod_bruteforce_s3_buckets'],
        "scan_type": 'active_scan'
    },
]