import { Component, OnInit, TemplateRef } from '@angular/core';
import { EcsmService } from '../_services';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CommonService } from '../_services/common.service';
// import { KeyValuePipe } from "@angular/common";


@Component({
  selector: 'app-secrets',
  templateUrl: './secrets.component.html',
  styleUrls: ['./secrets.component.scss']
})
export class SecretsComponent implements OnInit {
  public records
  public inputsToShow
  public showSpinner = false
  public credential = {}
  public recordToSend
  public keyName;
  public platformCounts;
  public secretsToShow;
  public showUploadButton = false;
  showMandoatoryError: boolean;
  public loading
  public spinnerForSecrets = false;
  public listView = false;
  public allRecords;
  public showRecordDetails = false
  public showExtendedSecrets = false;
  public openThis
  public secrteToUpdate = null
  public state = null
  public disableAdd = false;
  public disabledDel = false;
  public disabledState = false
  public page;
  public platformIdToShow = null;
  public totalSecrets;
  public showEye: boolean = false;



  // public credValue
  constructor(
    public ecsmService: EcsmService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public modalRefSecrtes :BsModalRef,
    public commonService: CommonService,
    // public keyValuePipe: KeyValuePipe
  ) { }

  ngOnInit(): void {
    this.getEcsmDetails()
    // this.getAllSectrets()
  }

  getEcsmDetails() {
    this.loading = true
    this.ecsmService.getEcsmDetails().subscribe((data: any) => {
      this.records = data.records
      this.loading = false
      this.getSecretCount()
      this.records.forEach(rec => {
        if (rec.fields?.Schema) {
          rec.fields.Schema = JSON.parse(rec.fields.Schema)
        }
      });
    }, error => {
      if (error['status'] === 403) {
        alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
      } else {
        alert("Error while getting platform counts!")
      }
      this.loading = false
    })
  }
  checkFields(value) {
    if (Object.keys(value).length) {
      return true
    }
  }
  openInputModel(template: TemplateRef<any>, rec) {
    this.showUploadButton = false
    this.showMandoatoryError = false
    this.keyName = null
    if (rec?.Schema) {
      this.inputsToShow = rec?.Schema
      this.recordToSend = rec
    }
    let schemaValues = Object.values(this.inputsToShow)
    schemaValues.forEach(a => {
      if (a['data_type'] == 'file') this.showUploadButton = true
    })


    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal"
    });
  }
  openSecretsModel(template: TemplateRef<any>, id) {
    this.getSecrets(id)
    console.log("id...........",id)
    this.modalRefSecrtes = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal"
    });
  }
  uploadFile() {
    this.showMandoatoryError = false
    let uploadedFile = document.getElementById("uploadedFile")
      ? document.getElementById("uploadedFile")["files"][0]
      : null;
    if (!uploadedFile || !this.keyName) {
      this.showMandoatoryError = true
      return
    }
    this.showSpinner = true;
    this.recordToSend = JSON.stringify(this.recordToSend)
    let formData: FormData = new FormData();
    formData.append('file', uploadedFile, uploadedFile.name);
    formData.append('platform_details', this.recordToSend)
    formData.append('key_name', this.keyName)
    this.ecsmService.addSecretsFile(formData).subscribe(data => {
      this.showSpinner = false;
      if (data) {
        alert("Data added Successfully!")
        this.credential = {}
        this.keyName = null
        formData = null
        this.modalRef.hide()
        this.getSecretCount()

      }
    }, error => {
      if (error['status'] === 403) {
        alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
      } else {
        alert(error.error.message)
      }
      this.showSpinner = false;
      this.modalRef.hide()
      this.credential = {}
      this.keyName = null
      formData = null
    })

  }
  AddEcsm() {
    let dataToSend
    dataToSend = {
      "credentials": this.credential,
      "platform_details": this.recordToSend,
      "key_name": this.keyName
    }

    if (this.validateReport()) {
      this.disableAdd = true
      this.ecsmService.addSecrets(dataToSend).subscribe((data: any) => {
        if (data) {
          alert("Data added Successfully!")
          this.modalRef.hide()
          this.credential = {}
          this.keyName = null
          dataToSend = {
            "credentials": null,
            "platform_details": null,
            "file": null,
            "key_name": null

          }
          this.getSecretCount()
          this.disableAdd = false

        }
      }, error => {
        if (error['status'] === 403) {
          alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
        } else {
          alert("Error while adding secret!")
        }
        this.modalRef.hide()
        this.credential = {}
        this.keyName = null
        dataToSend = {
          "credentials": null,
          "platform_details": null,
          "file": null,
          "key_name": null

        }
        this.disableAdd = false
      });
    } else {
      this.showMandoatoryError = true
    }
  }
  hideModel() {
    this.modalRef.hide()
    this.credential = {}
    this.keyName = null
    this.disableAdd = false


  }
  getFirstLetter(string) {
    return string.charAt(0)
  }
  // uploadJsonForSecrtes(result) {
  //   this.ecsmService.addSecrets(result).subscribe((data: any) => {
  //     if (data) {
  //       alert("Data added Successfully!")
  //       this.modalRef.hide()
  //       this.fileToUpload = null
  //       this.fileUpload = false
  //     }
  //   }, (error) => {
  //     console.log("er.............",error)
  //     if (error['status'] === 403) {
  //       alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
  //     } else {
  //       alert("Error while adding secret!")
  //     }
  //     this.modalRef.hide()
  //     this.fileToUpload = null
  //     this.fileUpload = false
  //   });
  // }
  validateReport() {
    Object.keys(this.credential).forEach(element => {
      this.credential[element] = (this.credential[element]).trim()
    });
    if (!this.keyName || !this.keyName.length) return false
    for (let key of Object.keys(this.inputsToShow)) {
      if (this.inputsToShow[key] && this.inputsToShow[key].mandatory) {
        if (!this.credential[key]) {
          return false
        }
      }
    }
    return true
  }
  getSecrets(id) {
    this.spinnerForSecrets = true;
    this.secretsToShow = null;
    var params = {
      page: this.page,
      id: id
    }
    this.ecsmService.getPlatformDetails(params).subscribe((data: any) => {
      this.secretsToShow = data.results
      this.spinnerForSecrets = false;
      // this.platformIdToShow = null 

    }, error => {
      if (error['status'] === 403) {
        alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
      } else {
        alert("Error while getting platform counts!")
      }
      this.spinnerForSecrets = false;
    })
  }
  getSecretCount(callback=null) {
    this.ecsmService.getPlatformCount().subscribe((data: any) => {
      this.platformCounts = data.data
      if(callback) callback();
    }, error => {
      if (error['status'] === 403) {
        alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
      } else {
        alert("Error while getting platform counts!")
      }
    })

  }
  mouseHoverToDiv(rec) {
    const divById = document.getElementById(rec)
    let keys = Object.keys(rec.Schema)
    // divById.setAttribute('gloov',Object.keys(rec.Schema))
  }
  getCount(id) {
    let indexOfPlatform = this.platformCounts?.findIndex(pc => pc.platform_id == id)
    if (indexOfPlatform > -1) {
      return this.platformCounts[indexOfPlatform].total
    } else {
      return 0
    }
  }
  async hideSecretModel() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    this.modalRefSecrtes.hide()
      await delay(3000);
      this.secretsToShow = null

  }
  public openRecordDetials(id, i) {
    this.getSecrets(id)
    this.openThis != i + '_' + id ? this.openThis = i + '_' + id : this.openThis = -1;
    this.showExtendedSecrets = !this.showExtendedSecrets
  }
  gutCurrentPageFromCount(count){
    let noOfRowsPerPage = 10;
    let pageNo = Math.ceil(count/noOfRowsPerPage);
    return (pageNo)?pageNo:1;
  }
  public deleteRec() {
    this.disabledDel = true
    this.ecsmService.deletSecrateRecord(this.secrteToUpdate.id).subscribe(async (data: any) => {
      this.getSecretCount(()=>{
        this.totalSecrets = this.getCount(this.platformIdToShow);
        let maxPageNo = this.gutCurrentPageFromCount(this.totalSecrets);
        if(this.page > maxPageNo) this.page = maxPageNo;
        this.secrteToUpdate.archive = true
        this.secrteToUpdate = null
        this.getSecrets(this.platformIdToShow);
        alert("Record deleted successfully!")
        this.disabledDel = false
      });
    }, error => {
      if (error['status'] === 403) {
        alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
      } else {
        alert("Error while deleting record!")
      }
      this.disabledDel = false
    })
    this.modalRef.hide()
  }
  openDeleteModal(template: TemplateRef<any>, rec) {
    this.secrteToUpdate = rec
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal"
    });
  }
  cancelDelete() {
    this.modalRef.hide()
    this.secrteToUpdate = null;
    this.disabledDel = false
  }
  modalOpenForStatusConfirmation(template: TemplateRef<any>, sec, state) {
    this.secrteToUpdate = sec
    this.state = state
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "max-width-dialog-modal",
    });
  }
  hideModelStatus() {
    this.modalRef.hide();
    this.state = null
    this.secrteToUpdate = null
  }
  changeStatus() {
    this.disabledState = true
    let dataToSend
    if (this.state == 'active') {
      dataToSend = {
        "status": 'inactive'
      }
    } else {
      dataToSend = {
        "status": 'active'
      }
    }
    this.ecsmService.updateSecret(this.secrteToUpdate.id, dataToSend).subscribe(data => {
      if (this.state == 'active') {
        this.secrteToUpdate.status = 'inactive'
      } else {
        this.secrteToUpdate.status = 'active'
      }
      this.state = null
      this.secrteToUpdate = null
      this.disabledState = false

    }, error => {
      if (error['status'] === 403) {
        alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
      } else {
        alert("Error while deleting record!")
      }
      this.state = null
      this.secrteToUpdate = null
      this.disabledState = false

    })
    this.hideModel()
  }
  goToPage(val) {
    this.page = val;
    this.getSecrets(this.platformIdToShow);
  }
}
