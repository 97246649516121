import { Injectable } from "@angular/core";

@Injectable()
export class FileUtil {
  constructor() {}

  isCSVFile(file) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr, tokenDelimeter) {
    let headers = csvRecordsArr[0].split(tokenDelimeter);
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  validateHeaders(origHeaders, fileHeaaders) {
    if (origHeaders.length != fileHeaaders.length) {
      return false;
    }

    var fileHeaderMatchFlag = true;
    for (let j = 0; j < origHeaders.length; j++) {
      if (origHeaders[j] != fileHeaaders[j]) {
        fileHeaderMatchFlag = false;
        break;
      }
    }
    return fileHeaderMatchFlag;
  }

  getDataRecordsArrayFromCSVFile(
    csvRecordsArray,
    headerLength,
    validateHeaderAndRecordLengthFlag,
    tokenDelimeter
  ) {
    var dataArr = [];

    for (let i = 0; i < csvRecordsArray.length; i++) {
      let data = csvRecordsArray[i].split(tokenDelimeter);

      if (validateHeaderAndRecordLengthFlag && data.length != headerLength) {
        if (data == "") {
          alert(
            "Extra blank line is present at line number " +
              i +
              ", please remove it."
          );
          return null;
        } else {
          alert(
            "Record at line number " +
              i +
              " contain " +
              data.length +
              " tokens, and is not matching with header length of :" +
              headerLength
          );
          return null;
        }
      }

      let col = [];
      for (let j = 0; j < data.length; j++) {
        col.push(data[j]);
      }
      dataArr.push(col);
    }
    return dataArr;
  }

  getDataRecordsObjectFromCSVFile(
    csvRecordsArray,
    headersRow,
    validateHeaderAndRecordLengthFlag,
    tokenDelimeter
  ) {
    var dataArr = [];
    for (let i = 0; i < csvRecordsArray.length; i++) {
      let data = csvRecordsArray[i].split(tokenDelimeter);

      if (
        validateHeaderAndRecordLengthFlag &&
        data.length != headersRow.length
      ) {
        if (data == "") {
          alert(
            "Extra blank line is present at line number " +
              i +
              ", please remove it."
          );
          return null;
        } else {
          alert(
            "Record at line number " +
              i +
              " contain " +
              data.length +
              " tokens, and is not matching with header length of :" +
              headersRow.length
          );
          return null;
        }
      }

      let col = [];
      let dataToPush = {};
      for (let j = 0; j < data.length; j++) {
        console.log(data[j], headersRow[j]);
        let key = headersRow[j];
        dataToPush[key] = data[j];
      }
      dataArr.push(dataToPush);
    }
    return dataArr;
  }
}
