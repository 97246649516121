import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { Port2ServiceNamePipe } from 'app/pipes/port2service-name.pipe';
import { SearchPipe } from 'app/pipes/search.pipe';
import { SortPipe } from 'app/pipes/sort.pipe';
import { ProfileTypePipe } from 'app/pipes/profile-type.pipe';
import { IterateObjectPipe } from './iterate-object.pipe';
import { ValidEmailPipe } from './email-validation.pipe';
import { ValidDomainPipe } from './valid-domain.pipe';
import { ValidUrlPipe } from './validate-url.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ValidateInternetNamePipe } from './validate-internetname.pipe';
import { ValidIpPipe } from './validate-ip.pipe';
import { ValidateNetblockPipe } from './validate-netblok.pipe';
import { AssetTypePipe } from './asset-type.pipe';
import { TrimPipe } from './trim.pipe';
import { FilterPipe } from './filter.pipe';
import { MainDomainPipe } from './main-domain.pipe';
import { LoadingPipe } from './loading.pipe';
​
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SortPipe,
        ProfileTypePipe,
        Port2ServiceNamePipe,
        SearchPipe,
        IterateObjectPipe,
        ValidEmailPipe,
        ValidDomainPipe,
        ValidUrlPipe,
		SafeHtmlPipe,
		ValidateInternetNamePipe,
		ValidIpPipe,
		ValidateNetblockPipe,
		ValidUrlPipe,
		AssetTypePipe,
		TrimPipe,
		FilterPipe,
		MainDomainPipe,
        LoadingPipe
    ],
    exports: [SortPipe,
        ProfileTypePipe,
        Port2ServiceNamePipe,
        SearchPipe,
        IterateObjectPipe,
        ValidEmailPipe,
        ValidDomainPipe,
        ValidUrlPipe,
		SafeHtmlPipe,
		ValidateInternetNamePipe,
		ValidIpPipe,
		ValidateNetblockPipe,
		ValidUrlPipe,
		AssetTypePipe,
		TrimPipe,
		FilterPipe,
		MainDomainPipe,
        LoadingPipe
    ]
})
export class PipeModule { }