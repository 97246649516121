import { Component, OnInit, Input } from "@angular/core";
import { PortfolioSurveyService } from "../../_services";

@Component({
  selector: "app-shadow-domain",
  templateUrl: "./shadow-domain.component.html",
  styleUrls: ["./shadow-domain.component.scss"],
})
export class ShadowDomainComponent implements OnInit {
  @Input() shadowData: any;
  @Input() tabType: string = "ip";
  public seeMore = 10;
  public oldShadowData: any;
  public showThis: boolean = false;
  public showError;
  public showSpinner;

  constructor(public portfolioSurveyService: PortfolioSurveyService) {}

  ngOnInit() {
    this.oldShadowData = Object.assign({}, this.shadowData);
  }

  removeShadowData(data, index) {
    console.log(data, index);
    this.shadowData.domains.splice(index, 1);
  }

  searchDomain(searchValue) {
    if (searchValue) {
      let data = this.shadowData.domains.filter((el) => {
        console.log(el.domain.toLowerCase().indexOf(searchValue.toLowerCase()));
        return el.domain.indexOf(searchValue) >= 0;
      });
      this.shadowData.domains = data;
    } else {
      this.shadowData = Object.assign({}, this.oldShadowData);
    }
  }
}
