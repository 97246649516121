import { Component, OnInit, Input, OnChanges, TemplateRef, SimpleChanges, Output, EventEmitter, SimpleChange } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { PortfolioSurveyService } from 'app/_services';

@Component({
  selector: 'app-critical-vulnerability',
  templateUrl: './critical-vulnerability.component.html',
  styleUrls: ['./critical-vulnerability.component.scss']
})
export class CriticalVulnerabilityComponent implements OnInit, OnChanges {

  @Input() mainDomain: any;
  @Input() domains: any;
  @Input() cveDetails: any = [];
  @Input() isUserTrial: boolean = false;
  @Output() total_cve_count = new EventEmitter<number>();
  @Input() showExecutiveReport;
  @Input() setAssetView: boolean = false;

  public allDomains: any;
  public allcveDetails: any;
  public initialSortDomain: boolean = false;
  public orderDomain: number = 1;
  public sortWithDomain = 'total_cve';
  public initialSortSub: boolean = false;
  public orderSub: number = 1;
  public sortWithSub = 'total_cve';
  public displayArray: any;
  public processVul: boolean = false;
  public showThisScan = 'network_vulnerabilities';
  public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
  public showDowloadText: boolean = false;
  public technologyVulnerabilties = [];
  public serviceVulnerabilties = [];
  public webVulnerabilties = [];
  public mobileVulnerabilties = [];
  public page = 1

  constructor(
    private commonService: CommonService, private portfolioSurveyService: PortfolioSurveyService,
    private modalService: BsModalService,
    private modalRef: BsModalRef
  ) { }

  ngOnInit() {
    if (this.mainDomain['domain_name']) {
      this.getTechnologyVulnerability();
      this.getServiceVulnerability();
      this.getMobileAppVulnerability();
      this.getWebAppVulnerability();
    }
    if (this.cveDetails && this.cveDetails.length) {
      if (this.processVul == false) {
        this.processVul = true;
        this.cveForDomainHost();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const change: SimpleChange = changes.cveDetails;
    if (change && !change.previousValue && change.currentValue) {
      if (this.processVul == false) {
        this.processVul = true;
        this.cveForDomainHost();
      }
    }
  }

  cveForDomainHost() {
    let total_cve = 0;
    if (this.cveDetails && this.cveDetails.length > -1) {
      this.cveDetails.map(element => {
        if (element) {
          element['total_cve'] = element['total_cve'] ? element['total_cve'] : 0;
          // element['low_cve'] = element['low_cve'] ? element['low_cve'] : 0;
          element['medium_cve'] = element['medium_cve'] ? element['medium_cve'] : 0;
          element['high_cve'] = element['high_cve'] ? element['high_cve'] : 0;
          element['critical_cve'] = element['critical_cve'] ? element['critical_cve'] : 0;
          // this.cvForTechnologies(element);
          element['total_cve_p'] = element['total_cve'];
          // element['low_cve_p'] = element['low_cve'];
          element['medium_cve_p'] = element['medium_cve'];
          element['high_cve_p'] = element['high_cve'];
          element['critical_cve_p'] = element['critical_cve'];
          if (element['sub_domains']) {
            element['sub_domains'].map(host => {
              if (host) {
                // this.cvForTechnologies(host);
                if (host.total_cve) {
                  if (host.host != element.related_domain) {
                    element['total_cve'] = element['total_cve'] + host['total_cve'];
                    // element['low_cve'] = element['low_cve'] + host['low_cve'];
                    element['medium_cve'] = element['medium_cve'] + host['medium_cve'];
                    element['high_cve'] = element['high_cve'] + host['high_cve'];
                    element['critical_cve'] = element['critical_cve'] + host['critical_cve'];
                  }
                }
              }
            });
          }
          // total_cve = total_cve + element['high_cve'] + element['critical_cve'];
          if (element['total_cve'])
            total_cve = total_cve + element['total_cve'];
        }
      });
      // this.total_cve_count.emit(total_cve);
      this.allcveDetails = JSON.parse(JSON.stringify(this.cveDetails));
    } else {
      this.allDomains = [];
    }
  }

  cvForTechnologies(domain) {
    let total_cve = 0;
    // let low_cve = 0;
    let medium_cve = 0;
    let high_cve = 0;
    let critical_cve = 0;
    domain.technologies.forEach((technologie) => {
      if (technologie && technologie.technology_name && technologie.version) {
        technologie['cveDetailArray'] = [];
        this.cveDetails.forEach((cveDetail) => {
          if (cveDetail) {
            if (technologie.technology_name.toLowerCase() == cveDetail.technology_name.toLowerCase() && technologie.version == cveDetail.version) {
              technologie['cveDetail'] = cveDetail['cveDetail'];
              total_cve = total_cve + cveDetail['cveDetail']['count'];
              // low_cve = low_cve + cveDetail['cveDetail']['low'].length;
              medium_cve = medium_cve + cveDetail['cveDetail']['medium'].length;
              high_cve = high_cve + cveDetail['cveDetail']['high'].length;
              critical_cve = critical_cve + cveDetail['cveDetail']['critical'].length;

              technologie['cveDetailArray'].push(...cveDetail['cveDetail']['critical']);
              technologie['cveDetailArray'].push(...cveDetail['cveDetail']['high']);
              technologie['cveDetailArray'].push(...cveDetail['cveDetail']['medium']);
              technologie['cveDetailArray'].push(...cveDetail['cveDetail']['low']);
            }
          }
        })
      }
    });
    // total_cve = total_cve + medium_cve + high_cve;
    domain['total_cve'] = total_cve;
    // domain['low_cve'] = low_cve;
    domain['medium_cve'] = medium_cve;
    domain['high_cve'] = high_cve;
    domain['critical_cve'] = critical_cve;
  }

  modalOpen(template: TemplateRef<any>, domain, cveType = 'total', domainType) {
    let name = '';
    if (domainType == 'domain') {
      name = domain.related_domain;
    } else {
      name = domain.host;
    }
    let technologies = {}
    domain['vulnerabilities'].forEach(vul => {
      let target = vul['target'];
      if (technologies[target]) {
        technologies[target].push(vul);
      } else {
        technologies[target] = [vul];
      }
    })
    this.displayArray = {
      "name": name,
      "technologies": technologies,
      "type": cveType
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }

  searchDomain(domain) {
    var newcveDetails = [];
    this.cveDetails = JSON.parse(JSON.stringify(this.allcveDetails));
    this.cveDetails.forEach(element => {
      if (element.related_domain && element.related_domain.toLowerCase().indexOf(domain.toLowerCase()) >= 0) {
        newcveDetails.push(element);
      }
    });
    this.cveDetails = newcveDetails;
  }

  searchHost(searchHost, domain, index) {
    var newDomain = [];
    let subDomains = JSON.parse(JSON.stringify(this.allcveDetails[index]['sub_domains']));
    subDomains.forEach(element => {
      if (element.host && element.host.toLowerCase().indexOf(searchHost.toLowerCase()) >= 0) {
        newDomain.push(element);
      }
    });
    domain['sub_domains'] = newDomain;
  }

  getTechnologyVulnerability() {
    this.portfolioSurveyService.getTechnologyVulnerability(this.mainDomain['domain_name']).subscribe(vul => {
      this.technologyVulnerabilties = vul['data'];
    })
  }
  goToPage(val) {
    this.page = val;
    this.getServiceVulnerability()
  }
  getServiceVulnerability() {
  
     this.portfolioSurveyService.getServiceVulnerability(this.mainDomain['domain_name']).subscribe(vul => {
      this.serviceVulnerabilties = vul['data'];

    })
  }

  getMobileAppVulnerability() {
    this.portfolioSurveyService.getMobileAppVulnerability(this.mainDomain['domain_name']).subscribe(vul => {
      this.mobileVulnerabilties = vul['data'];
    })
  }

  getWebAppVulnerability() {
    this.portfolioSurveyService.getWebAppVulnerability(this.mainDomain['domain_name']).subscribe(vul => {
      this.webVulnerabilties = vul['data'];
    })
  }

}
