import { Pipe, PipeTransform } from '@angular/core';
import { portToTypeServiceName } from 'app/_services/common-var.service'

@Pipe({
    name: 'port2servicename'
})
export class Port2ServiceNamePipe implements PipeTransform {

    transform(portAndProtocol: any) {
        let port: string = '';
        let protocol: string = '';
        try {
            let value = portAndProtocol.split('/')
            port = value[1]
            protocol = value[0]
            return portToTypeServiceName[port]
        } catch (ex) {
            return '';
        }
    }

}
