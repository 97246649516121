import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";
import { discoverConfig } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(public http: HttpService) {}

  searchByKeyWord(keyword,{asset_type = '',page=1,page_size=null,start_time=null,end_time=null, affiliated=false}={}) {
    if(keyword=='') keyword = '';
    if(asset_type) keyword = ((keyword) ? keyword + ' AND ':'')+'asset_type:'+asset_type
    if (asset_type == 'url' && !affiliated) {
      keyword = `(${keyword}) AND (!tag:affiliated)`
    }
    let url = discoverConfig.url + "/bannersearch/get?query="+keyword;
    if(page) url = url+'&page='+page;
    if(page_size) url = url+'&page_size='+page_size;
    if(start_time) url = url+'&start_time='+start_time;
    if(end_time) url = url+'&end_time='+end_time;
    return this.http.get(url);
  }
  searchApplicationByKeyWord(keyword,{page=1,page_size=null,start_time=null,end_time=null}={}) {
    if(keyword=='') keyword = '*';
    let url = discoverConfig.url + "/bannersearch/get/url?query="+keyword;
    if(page) url = url+'&page='+page;
    if(page_size) url = url+'&page_size='+page_size;
    if(start_time) url = url+'&start_time='+start_time;
    if(end_time) url = url+'&end_time='+end_time;
    return this.http.get(url);
  }
  // bannerDownload(keyword,{start_time=null,end_time=null}={}) {
  //   if(keyword=='') keyword = '*';
  //   let url = discoverConfig.url + "/bannersearch/download?query="+keyword;
  //   if(start_time) url = url+'&start_time='+start_time;
  //   if(end_time) url = url+'&end_time='+end_time;
  //   return this.http.get(url);
  // }
  bannerDownload(keyword,{asset_type='ip',page=1,page_size=null,start_time=null,end_time=null,fields=null}={}) {
    if(keyword=='') keyword = '';
    if(asset_type) asset_type = ((keyword)?' AND ':'')+'asset_type:'+asset_type
    let url = discoverConfig.url + "/bannersearch/get?query="+keyword+asset_type;
    if(page) url = url+'&page='+page;
    if(page_size) url = url+'&page_size='+page_size;
    if(start_time) url = url+'&start_time='+start_time;
    if(end_time) url = url+'&end_time='+end_time;
    if(fields) url = url+'&fields='+fields;
    return this.http.get(url);
  }
  applicationDownload(keyword,{asset_type = 'url',page=1,page_size=null,start_time=null,end_time=null,fields=null,affiliated=false}={}) {
    if(keyword=='') keyword = '';
    if(asset_type) keyword = ((keyword) ? keyword + ' AND ':'')+'asset_type:'+asset_type
    if (asset_type == 'url' && !affiliated) {
      keyword = `(${keyword}) AND (!tag:affiliated)`
    }
    let url = discoverConfig.url + "/bannersearch/get?query="+keyword;
    if(page) url = url+'&page='+page;
    if(page_size) url = url+'&page_size='+page_size;
    if(start_time) url = url+'&start_time='+start_time;
    if(end_time) url = url+'&end_time='+end_time;
    if(fields) url = url+'&fields='+fields;
    return this.http.get(url);
  }
}