import { Pipe, PipeTransform } from '@angular/core';
import { ValidIpPipe } from './validate-ip.pipe';
import { ValidDomainPipe } from './valid-domain.pipe';
import { ValidateNetblockPipe } from './validate-netblok.pipe'
import { ValidUrlPipe } from './validate-url.pipe'
import { TrimPipe } from './trim.pipe'
import { ValidEmailPipe } from './email-validation.pipe';
import { ValidIpPortPipe } from './validate-ip-ports.pipe';

@Pipe({
	name: 'assetType'
})
export class AssetTypePipe implements PipeTransform {
	constructor() { }
	transform(input: string, checkSubdomain=false) {
		if (typeof (input) == 'string') {
			if (input != null) {
				input = new TrimPipe().transform(input)
				let validIpPipe = new ValidIpPipe().transform(input);
				let validdomainPipe = new ValidDomainPipe().transform(input)
				let validCidrPipe = new ValidateNetblockPipe().transform(input)
				let validUrlPipe = new ValidUrlPipe().transform(input)
				let validEmailPipe = new ValidEmailPipe().transform(input)
				let validIpPortPipe = new ValidIpPortPipe().transform(input)

				if (validIpPipe) {
					return 'ip'
				} else if (validdomainPipe) {
					if (checkSubdomain) {
						let tld = extractHostname(input, true)
						if (tld == input)
							return 'domain'
						else return 'sub_domain'
					} else return 'domain'
				} else if (validCidrPipe) {
					return 'netblock'
				} else if (validEmailPipe) {
					return 'email'
				} else if (validIpPortPipe) {
					return 'ip_ports'
				} else if (validUrlPipe) {
					return 'url'
				} else return 'keyword'
			}
		} else return 'NA'
	}
}

function extractHostname(url,tld) {
	let hostname;

	//find & remove protocol (http, ftp, etc.) and get hostname
	if (url.indexOf("://") > -1) {
		hostname = url.split('/')[2];
	}else {
		hostname = url.split('/')[0];
	}

	//find & remove port number
	hostname = hostname.split(':')[0];

	//find & remove "?"
	hostname = hostname.split('?')[0];

	if(tld){
	  let hostnames = hostname.split('.');
	  hostname = hostnames[hostnames.length-2] + '.' + hostnames[hostnames.length-1];
	}

	return hostname;
}