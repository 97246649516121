import { Component, OnInit, Input, TemplateRef, ApplicationRef, enableProdMode } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { CommonService } from "app/_services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { dataPerPage, currentPage, order } from "app/_services/globals";

enableProdMode()
@Component({
  selector: "app-top-risks",
  templateUrl: "./top-risks.component.html",
  styleUrls: ["./top-risks.component.scss"],
})
export class TopRisksComponent implements OnInit {
  @Input() topRisk: any;
  @Input() domain: any;
  @Input() maliciousCount: number;
  @Input() showVendorColumn: string = null;

  app:ApplicationRef;

  public risk;
  public filteredTopRisk;
  public sourceLocation = [];
  public ipDetails: boolean = false;
  public getIpdetails: any = {};
  public indexOfIp: number;
  public getriskForDomain: boolean = false;
  public getProbableRisk: boolean = false;
  public riskCounts = {};
  public probableDigitalRisk;
  public currentRisk = {};
  public riskDetails = {};
  public dataToDisplay = null;
  public riskType: string = null;
  public breachedCredentials: any = [];
  public dataToShow = [];
  public assetCount: number = -1;
  public seeMoreLoc = 5;
  public seeMoreRem = 5;
  public updateRisk = null;
  public updateRiskState = null;
  public sortWith: string;
  public order: number = 1;
  public initialSort: boolean = true;
  public sortMap = null;
  public page = 1;
  public limit = 10;
  public savelimit = 10;
  public topRiskToShow;
  public openDropdown = false;
  public filteredElements;
  public filterKeys = {};
  public infoFlag ;
  public filterMapForRisk = [
    {"name": "Critical", "selected": false},
    {"name": "High", "selected": false},
    {"name": "Medium", "selected": false},
    {"name": "Low", "selected": false}
  ];
  public sortMapForTopRisk = {
    Critical: 1,
    High: 2,
    Medium: 3,
    Low: 4,
  };
  public fieldsToDownload = {
    "Risk Title": "title",
    "Description": "description",
    "Remediation": "remediation",
    "Source": "source",
    "Risk Level": "risk_level",
    "State": "state",
    "Created At": "created_at",
    "Updated At": "updated_at",
  };
  public allRiskDetails = [
    {
      risk_name: "LEAKED CREDENTIALS",
      risk_description:
        "Multiple leaked credentials are associated with your organisation. Some of these leaked credentials may still be used by the users either in their original form or some variations of them",
      risk_code: "leakedCreds",
      severity: "High",
      risk_counter: "leakedCredCount",
      suggested_remediation:
        "Blacklist the leaked credentials at various services such as AD, Email, VPN etc. ",
    },
    {
      risk_name: "EXPOSED DATABASES",
      risk_description:
        "Digital Attack Surface has various exposed database ports to public internet. Some of these databases can have critical vulnerabilities or weak authentication. ",
      risk_code: "exposedDatabase",
      severity: "High",
      risk_counter: "exposedDatabasesCount",
      suggested_remediation:
        "It is suggested to firewall the database ports so that only required applications can access the required databases. Please strong authentication and authorization at database level to mitigate advance multi stage attacks. ",
    },
    {
      risk_name: "MALICIOUS",
      risk_description:
        "Few assets are found to be generating malicious traffic as flagged by Threat Intelligence sources. It is just an indicator that something might be wrong with the asset. More detail investigation and review is required to confirm malicious behavior",
      risk_code: "maliciousEntities",
      severity: "High",
      risk_counter: "maliciousCount",
      suggested_remediation:
        "Get more detailed forensics performed. Review vulnerabilities, security configurations, firewall rules and other security controls",
    },
    {
      risk_name: "PRE-PROD SERVICES",
      risk_description:
        "Multiple pre prod environments are found to be exposed to the public internet. Pre Prod environments generally have less secure configuration and may have few critical vulnerabilities. Attacker can leverage pre prod environments to get initial foothold in your network",
      risk_code: "preprodServices",
      severity: "High",
      risk_counter: "prepodServicesCount",
      suggested_remediation:
        "Review whether the required pre prod environments are supposed to be exposed to the public internet. Sandbox the pre prod environments (separate them from production setup) to mitigate multi stage attacks",
    },
    {
      risk_name: "DOMAIN TAKEOVER RISK",
      risk_description:
        "Few domains are found have CNAME Record pointing to non existent / expired services. The attack can leverage the vulnerable setup to perform advance attacks to your end users or even to your internal organisation. ",
      risk_code: "takeoverRisk",
      severity: "Medium",
      risk_counter: "takeoverRisksCount",
      suggested_remediation:
        "It is suggested to review all the DNS records, and specifically remove the mentioned dangling CNAME records to mitigate the risk",
    },

    {
      risk_name: "EXPOSED SERVICES",
      risk_description:
        "Multiple network services are exposed to external network. Some of these services may have critical vulnerabilities or weaker authentication and encryption. ",
      risk_code: "exposedServices",
      severity: "Medium",
      risk_counter: "exposedServicesCount",
      suggested_remediation:
        "Review all the network service accessibility from external network, restrict service accessibility and upgrade services if required .",
    },

    {
      risk_name: "LOOKALIKE DOMAINS",
      risk_description:
        "Multiple homonymous domains detected. Some these domains may be actively targeting your organisation. ",
      risk_code: "phishingDomains",
      severity: "Medium",
      risk_counter: "phishingDomainsCount",
      suggested_remediation:
        "Review all the possible homonymous domains, If the domains is detected to be malicious, it is suggested to avail take down services to bring down the domain.",
    },

    {
      risk_name: "VULNERABILITIES",
      risk_description:
        "Multiple High & Critical vulnerabilities detected in your external digital attack surface. Some of these vulnerabilities may have exploits in the surface or dark marketplace",
      risk_code: "vulnerabilities",
      severity: "Medium",
      risk_counter: "vulnerabilitiesCount",
      suggested_remediation:
        "Review all the vulnerabilities, and apply required patches or upgrade the version.",
    },
  ];
  public evidences = []
  constructor(
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public portfolioSurveyService: PortfolioSurveyService,
    public commonService: CommonService,
    public route: ActivatedRoute,
    public router: Router,
    app: ApplicationRef,
  ) {
    this.app = app;
  }

  ngOnInit() {
    this.sortMap = this.sortMapForTopRisk;
    this.getDomainName();
    // 		this.domain = new MainDomainPipe().transform();
    // console.log("domain..",this.domain)
    if (this.topRisk) {
      this.topRisk.sort((a,b) => {
        return this.sortMapForTopRisk[a.risk_level] - this.sortMapForTopRisk[b.risk_level]
      })
      this.filteredElements = this.topRisk.slice()
      this.topRiskToShow = this.topRisk.slice(
        (currentPage - 1) * dataPerPage,
        currentPage * dataPerPage
      );
      // this.filteredTopRisk = this.topRisk.slice(0)
    }
    this.sortWith = 'risk_level'
    this.order = 1
    this.infoFlag=localStorage.getItem('info_vul_config')
    if(this.infoFlag=="true"){
      this.filterMapForRisk.push({"name": "Info", "selected": false})
    }
  }

  getDomainName() {
    this.route.queryParams.subscribe((params) => {
      if (params && params["domain"]) {
        this.domain = params["domain"];
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { domain: this.domain },
          queryParamsHandling: "merge",
          skipLocationChange: true,
        });
        // this.getCount();
      } else {
        this.portfolioSurveyService
          .getDomainsSearchForUser()
          .subscribe((data: any) => {
            data.filter((d) => {
              if (d["domain_type"] == "self") {
                this.domain = d["domain"];
                // this.getCount();
              }
            });
          });
      }
    });
  }
  modalOpenForAdd(template: TemplateRef<any>, risk) {
    this.currentRisk = null;
    this.dataToShow = [];
    this.risk = risk;
    this.sourceLocation = risk.source ? risk.source.split(",") : [];
    this.evidences = risk.evidences ? risk.evidences : []
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
    });
  }

  public stateOfRisk(state) {
    switch (true) {
      case state.toLowerCase() == "open":
        return "text-danger";
      case state.toLowerCase() == "resolved":
        return "text-success";
      case state.toLowerCase() == "inprogress":
        return "text-warning";

      default:
        return "text-primary";
    }
  }
  showIpDetails(ip) {
    this.ipDetails = false;
    this.portfolioSurveyService
      .getIpDetails(ip, this.domain)
      .subscribe((data: any) => {
        this.getIpdetails["subjects"] = [];
        // this.getIpdetails['ip'] = ""
        this.getIpdetails["ip"] = ip;
        if (data.data.length == 0) {
          this.getIpdetails["subjects"].push({
            name: "NA",
            discovered_at: "NA",
          });
        } else {
          this.getIpdetails["subjects"] = [];
        }
        data.data.forEach((ele) => {
          if (ele.host) {
            this.getIpdetails["subjects"].push({
              name: ele.host ? ele.host : "NA",
              created_at: ele.created_at,
              updated_at: ele.updated_at,
              discovered_at: ele.discovered_at ? ele.discovered_at : "NA",
            });
          }
        });
        if (this.getIpdetails["subjects"].length > 0) {
          this.ipDetails = true;
        }
      });
  }
  getCount() {
    this.portfolioSurveyService.getRisksForDomain(this.domain).subscribe(
      (data: any) => {
        if (data["status"] == "pass") {
          this.getriskForDomain = true;
          this.riskCounts = Object.assign(this.riskCounts, data["data"]);
          if (this.getriskForDomain && this.getProbableRisk) {
          }
        }
      },
      (error) => {
        alert("You do not have permission to view content for this Domain");
        this.router.navigate(["/new/self-domains"]);
      }
    );
    this.portfolioSurveyService
      .getProbableRiskCountForDomain(this.domain)
      .subscribe((data: any) => {
        if (data["status"] == "pass") {
          this.getProbableRisk = true;
          this.probableDigitalRisk = data["data"];
          this.riskCounts = Object.assign(this.riskCounts, data["data"]);
          if (this.getriskForDomain && this.getProbableRisk) {
          }
        }
      });
  }

  public getRiskDetails(template: TemplateRef<any>, riskType, count) {
    this.assetCount = count;
    this.risk = null;
    this.sourceLocation = null;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
    });
    this.currentRisk = riskType;
    this.riskType = riskType.risk_code;
    this.portfolioSurveyService
      .getRiskDetails(this.domain, this.riskType)
      .subscribe((data: any) => {
        if (data && data["status"] == "pass") {
          let dataSelected = data["data"];
          if (this.riskType == "leakedCreds") {
            this.dataToShow = [];
            dataSelected.forEach((ele) => {
              this.dataToShow.push(ele["domain"]);
            });
          }
          if (this.riskType == "takeoverRisk") {
            this.dataToShow = [];
            dataSelected.forEach((ele) => {
              this.dataToShow.push(ele["domain"]);
            });
          }
          if (this.riskType == "exposedDatabase") {
            this.dataToShow = [];
            dataSelected.forEach((ele) => {
              this.dataToShow.push(ele["ip_address"]);
            });
          }
          if (this.riskType == "exposedServices") {
            this.dataToShow = [];
            dataSelected.forEach((ele) => {
              this.dataToShow.push(ele["ip_address"]);
            });
          }
          if (this.riskType == "maliciousEntities") {
            this.dataToShow = [];
            dataSelected.forEach((ele) => {
              this.dataToShow.push(ele["subject_name"]);
            });
          }
          if (this.riskType == "phishingDomains") {
            this.dataToShow = [];
            dataSelected.forEach((ele) => {
              this.dataToShow.push(ele["fraud_domain"]);
            });
          }
          if (this.riskType == "preprodServices") {
            this.dataToShow = [];
            dataSelected.forEach((ele) => {
              this.dataToShow.push(ele["domain"]);
            });
          }
          if (this.riskType == "vulnerabilities") {
            this.dataToShow = [];
            dataSelected.forEach((ele) => {
              this.dataToShow.push(ele["subject_name"]);
            });
          }
        }
      });
    // this.hideModel()
  }

  hideModel() {
    this.ipDetails = false;
    this.modalRef.hide();
  }
  changeStateTopRisk(risk, riskState) {
    risk.updated = true;
    risk["state"] = riskState.toLowerCase();
    this.portfolioSurveyService
      .updateDigitalRisk([risk], this.domain)
      .subscribe((data) => {
        if (data["status"] == "pass") {
          risk.updated_at = new Date().toISOString();
          risk.state = this.updateRiskState;
        } else {
          alert("Error while updating Top risk status");
        }
      });
    this.modalRef.hide();
  }
  changeRiskstatus(template: TemplateRef<any>, risk) {
    this.updateRisk = risk;
    this.updateRiskState = risk.state.toLowerCase();
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: true,
      class: "auto_width",
    });
  }
  severityOfRiskLevel(riskLevel) {
    switch (true) {
      case riskLevel == "Critical":
        return "badge-danger";
      case riskLevel == "High":
        return "badge-warning";
        case riskLevel == "Medium":
          return "badge-primary"
      case riskLevel == "Low":
        return "badge-success";
      case riskLevel == "Info":
        return "badge-secondary";
     
      

      default:
        return "badge-primary";
    }
  }
  objectKeys(object2) {
    return Object.keys(object2);
  }

  checkRisk(objRisk) {
    let dataExists = false;
    Object.keys(objRisk).forEach((key) => {
      if (objRisk[key] != "NA" && objRisk[key] > 0) {
        dataExists = true;
      }
    });
    return dataExists;
  }

  changeRiskTypeFilterBasedOnRiskType(risk) {
    risk.selected = !risk.selected
  }

  filterSubDomainByTag(type = null) {
    if (type == 'clear') {
      this.filteredTopRisk = this.topRisk.slice(0);
      this.filterMapForRisk.forEach(risk => {
        risk.selected = false
      })
      this.filterKeys = {}
    } else {
      const selectedRiskType = []
      this.filterMapForRisk.forEach(risk => {
        if (risk.selected) selectedRiskType.push(risk.name)
      })
      if (selectedRiskType && selectedRiskType.length) {
        this.filteredTopRisk = this.topRisk.filter(risk => {
          return selectedRiskType.includes(risk.risk_level)
        })
        this.filterKeys = {"risk_level": selectedRiskType}
      } else {
        this.filteredTopRisk = this.topRisk.slice(0)
        this.filterKeys = {}
      }
    }
  }
  getImpacts(source,impact){
    let imps = [];
    impact.map((x)=>{
      if(x.service_id__ip_id__ip_address==source){
        imps.push(x);
      }
    })
    return imps;
  }
  getImpacts_webapp(source,impact){
    let skip = false;
    let imps = [];
    impact.map((x)=>{
      if(x.application_url==source && x.impact && !skip){
        imps.push(x);
        skip = true;
      }
    })
    return imps;
  }
}