import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFirestore} from "@angular/fire/firestore";
import { CommonService } from 'app/_services/common.service';

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  alerts: any = [];
  loading: boolean = false;
  perPage: number = 25;
  lastAlert
  loadMore = true
  priority:any = {
    "1":"Low",
    "2":"Low",
    "3":"Medium",
    "4":"Medium",
    "5":"Medium",
    "6":"High",
    "7":"High",
    "8":"High",
    "9":"Critical",
    "10":"Critical"
  }
  constructor(
    private firestore: AngularFirestore,
    public aff: AngularFireFunctions,
    public auth: AngularFireAuth,
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.loading = true
    setTimeout(function(t){
      t.getUserAlerts()
    }, 3000, this)
  }
  getUserAlerts() {
    const profile = JSON.parse(localStorage.getItem('profile'))
    this.firestore
    .collection("/alerts/classified/"+profile['user_id'], ref => {
        if (this.lastAlert) {
          return ref.orderBy("generated_at", "desc")
          .limit(this.perPage)
          .startAfter(this.lastAlert)
        } else {
          return ref.orderBy("generated_at", "desc")
          .limit(this.perPage)
        }
      })
      .get()
      .subscribe((data) => {
        let i = 0
        if (!data.docs.length) this.loadMore = false
        data.forEach((element: any) => {
          if (this.showAlertDecision(element.data()))
            this.alerts.push(element.data())
        });
        this.lastAlert = data.docs[data.docs.length-1]
        this.loading = false
      });
  }
  nextPage() {
    this.loading = true
    this.getUserAlerts()
  }
  mouseHoverToDiv(i, sp) {
		const divById = document.getElementById(sp.title+"_"+i)
		divById.setAttribute('gloov', sp.description)
	}
  showAlertDecision(alert) {
    if (!alert || !alert.msg || !alert.msg.title) return false
    else if (alert.msg.title.includes('Weekly Assessments Summary')) return false
    else return true
  }
}
