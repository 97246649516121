export const environment = {
  production: true,
};

// Firebase Configuration
export const firebase = {
  apiKey: "AIzaSyBd7-swotcILxaDoMqm_u3hz22JqhW_ftg",
  authDomain: "reflected-post-164312.firebaseapp.com",
  databaseURL: "https://reflected-post-164312.firebaseio.com",
  projectId: "reflected-post-164312",
  storageBucket: "reflected-post-164312.appspot.com",
  messagingSenderId: "863929079072",
  appId: "1:863929079072:web:fd1672762ecee58a6ff345",
};

// Firebase region to be configured different for India portal
export const firebaseRegion = 'us-central1'

/** Product Service Configurations */

export const ProductConfig = {
  url: "https://apis.firecompass.com/ainv",
};

/** Review Service Configurations */

export const ReviewConfig = {
  url: "https://apis.firecompass.com/ainv",
};

/** Benchmark Service Configurations */

export const BenchmarkConfig = {
  url: "https://apis.firecompass.com/ainv",
};

/** Vendor Service Configurations */

export const VendorConfig = {
  url: "https://vendor.firecompass.com",
};

/** Procurement Service Configurations */

export const ProcurementConfig = {
  url: "https://rfp.firecompass.com",
};

/** Osint Service Configurations */

export const OsintConfig = {
  url: "https://apis.firecompass.com/ainv/survey/toosint",
};

/** Sium Service Configurations */

export const SiumConfig = {
  url: "https://apis.firecompass.com/ainv/sium",
};

export const DataLeaksConfig = {
  url: "https://mapi.firecompass.com",
};

export const NotificationConfig = {
  url: "https://apis.firecompass.com/ainv/survey/tonotification",
};

export const GrapiConfig = {
  url: "https://apis.firecompass.com/ainv/survey/tograpi",
};

// Job Service configuration
export const JobAutomationConfig = {
  url: "https://apis.firecompass.com",
};

//report service configuration
export const ReportConfig = {
  url: "https://apis.firecompass.com",
};

export const PelegoConfig = {
  url: "https://apis.firecompass.com/pelago",
};

export const AssessmentConfig = {
  url: "https://apis.firecompass.com",
};

export const AirtableConfig = {
  url: "https://apis.firecompass.com/kb",
};

export const seedConfig = {
  url: "https://apis.firecompass.com/config-mgmt/config/v2",
};

export const discoverConfig = {
  url: "https://apis.firecompass.com/kb",
};

export const ExpertBotConfig = {
  url: "https://apis.firecompass.com/expertbot",
};
/**  Bucket Configurations */
export const StorageAccessConfig = {
  spUrl: "https://apis.firecompass.com/ainv/survey/toosint",
};
export const DWConfig = {
  url: "https://apis.firecompass.com/timeline",
};
export const fcCloudStorageClient = {
  storage: "GOOGLE_STORAGE",
};
export const max_hours_of_inactivity_before_logout = {
  hour: 1,
};
export const SpoceBaseURL = {
  url: "https://spoc.firecompass.com/",
};

export const alert_center_sidebar:boolean = true;
export const show_confidence_filter_for_ip:boolean = true;
