import { Injectable } from '@angular/core';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache,ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import {Apollo, gql} from 'apollo-angular';

import {
	PelegoConfig
} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PelagoService {
  constructor(private apollo: Apollo) {}

  public get(query, variables) {
    return this.apollo.watchQuery({query: gql`${query}`, variables: variables}).valueChanges
  }
}

export function createApollo(httpLink: HttpLink) {
  const uri = PelegoConfig.url+'/graphql';
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
      "Content-Type": "application/json",
    }
  }));

  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('token');

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }
  });

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}
