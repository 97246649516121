import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as json2csv from 'json2csv';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../_services/common.service'

@Component({
	selector: 'button-download',
	template: `
	<button [disabled]="loading" *ngIf="!showDowloadText" class= "btn-primary" style="margin-left: 10px;margin-right: 10px;" (click)="download()" [title]="tooltip ? tooltip: ''">
		<i class="fa fa-download" *ngIf="!loading" aria-hidden="true"></i>
		<i class="fa fa-spinner" *ngIf="loading" aria-hidden="true"></i>
	</button>
	`,
	styles: [
		'ul.pagination {}']
})
// line 7 --->
// <span *ngIf="showDowloadText"> 
// 	<a  name="bulkRemove" class="link_text a_class" (click)="download()">Download CSV</a> 
// </span>

export class DownloadButtonComponent implements OnInit, OnChanges {

	@Input() firstDownload: boolean = false;
	@Input() showDowloadText;
	@Input() elements;
	@Input() fileName;
	@Input() filedsJson;
	@Input() mainDomainObject;
	@Input() sortKey;
	@Input() tooltip;
	@Input() loading = false;

	constructor(
		public commonService: CommonService,
		public toaster: ToastrService
	) { }

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.elements && changes.elements.previousValue && changes.elements.currentValue) {
			if (this.firstDownload && this.elements && this.elements.length) {
				this.download()
			}
		}
	}

	splitTheValues(data, keys) {
		let arrayToPush = []
		let stringToReturn
		if (keys[1] == 'count') {
			return data.length;
		} else {
			if (Array.isArray(data)) {
				data.forEach(element => {
					arrayToPush.push(element[keys[1]])
					stringToReturn = arrayToPush.join(',  ')
				});
				return stringToReturn
			} else if (data[keys[1]] && ['created_at', 'domain_created_at', 'updated_at', 'discovered_at', 'expired_at', 'expiry_date', 'expires_at', 'expired_date'].includes(keys[1])) {
				return data[keys[1]].split('T')[0]
			} else {
				return data[keys[1]];
			}
		}
	}
	prepareJsonToDownload() {
		let jsonToDownload = []
		let value
		if (this.sortKey) {
			this.elements.sort((a, b) => { return a[this.sortKey] > b[this.sortKey] ? 1 : -1 })
		}
		this.elements.forEach(ele => {
			let objectToPush = {}
			Object.keys(this.filedsJson).forEach((key) => {
				let keyVal = this.filedsJson[key].split('.')
				if (keyVal.length == 1) {
					if (Array.isArray(ele[this.filedsJson[key]])) {
						value = ele[this.filedsJson[key]].join(',  ')
						objectToPush = Object.assign(objectToPush, objectToPush = {
							[key]: value
						})
					} else if (ele[this.filedsJson[key]] && ['created_at', 'domain_created_at', 'updated_at', 'discovered_at', 'expired_at', 'expiry_date', 'expires_at', 'expired_date', 'last_seen_at', 'applicationcve_created', 'applicationcve_updated_at'].includes(this.filedsJson[key])) {
						objectToPush = Object.assign(objectToPush, objectToPush = {
							[key]: (ele[this.filedsJson[key]]).split('T')[0]
						})
					} else
						objectToPush = Object.assign(objectToPush, objectToPush = {
							[key]: ele[this.filedsJson[key]]
						})
				} else if (keyVal.length > 1 && ele[keyVal[0]]) {
					objectToPush = Object.assign(objectToPush, objectToPush = {
						[key]: this.splitTheValues(ele[keyVal[0]], keyVal)
					})
				}
			});
			jsonToDownload.push(objectToPush)
		})
		return jsonToDownload

	}
	download() {
		console.log("I am getting called", this.commonService.reportDownloadState())
		if (this.commonService.reportDownloadState()) {
			if (this.elements && this.elements.length) {
				let result
				if (this.filedsJson && Object.keys(this.filedsJson).length) {
					this.prepareJsonToDownload()
					result = json2csv.parse(this.prepareJsonToDownload());
				} else {
					result = json2csv.parse(this.elements);
				}
				let blob = new Blob([result], { type: 'text/csv' });
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement('a');
				a.href = url;
				if (this.mainDomainObject['domain_name']) {
					a.download = this.mainDomainObject['domain_name'] + "_" + this.fileName + '.csv';
				} else if (this.mainDomainObject['subject_name']) {
					a.download = this.mainDomainObject['subject_name'] + "_" + this.fileName + '.csv';
				} else {
					a.download = this.mainDomainObject + "_" + this.fileName + '.csv';
				}
				this.toaster.info("Downloading is in progress.");
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}
		} else {
			alert("Reports download is disabled for this account. Please write to us at contact@firecompass.com.")
		}
	}
}

