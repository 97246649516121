import { Component, OnInit, Input, Output, EventEmitter, OnChanges, TemplateRef } from '@angular/core';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { CommonService } from 'app/_services/common.service';
import { ReportService } from './../../_services/report.service';
import { JobService } from './../../_services/job.service';




@Component({
  selector: 'app-leaked-credentials',
  templateUrl: './leaked-credentials.component.html',
  styleUrls: ['./leaked-credentials.component.scss']
})
export class LeakedCredentialsComponent implements OnInit, OnChanges {

  @Input() mainDomain: any;
  @Input() domains: any;
  @Input() showExecutiveReport;
  @Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
  @Input() filters: any;


  // @Input() breachedCredentials: any;
  // @Output() getSource: EventEmitter<any> = new EventEmitter();
  // getSource: EventEmitter<any> = new EventEmitter();
  public seeMore = 10;
  public seeMoreDomain = 10;
  public getSource = false;
  public breachedCredentials: any = [];
  public showSpinner = false;
  public allDomains: any;
  public showError;
  public domain;
  public allBreachse;
  public showLeakCreadentials: boolean = false;
  public count = 5;
  public order = 1;
  public sortWith = 'domain';
  public isUserTrial = false;
  public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
  public showDowloadText: boolean = false;
  public dataToDownload = [];
  public firstDownload: boolean = false;
  public relatedDomainName
  public show_max_list: number = 3;
  public showViewChange = false;
  public showDataByRD = true;
  public leakedCredsFromPelago = [];
  public showShowMore = true;
  public loadingLC = false
  public forNoSource = {
    'showThis': false,
    'source': "Firecompass proprietary",
    'dateToShow': "2019"
  }
  public fieldsToDownload = {
    "Password": "passwords",
  }
  public subFieldsToDownload = {
    'Email Address': 'user_email',
    'Created At': 'created_at',
    'Updated At': 'updated_at',
  }
  public initialSort;
  public openThis;
  public reasonTodelete = "";
  public singleDelete = false;
  public multiSelectCount = 0;
  public page = 1;
  public limit = 10;
  public total_pages: number;
  public showSpinnerPagination;
  public leakedCerd: any = [];
  public searchLeakedCredParam = null
  // public keysToSearch = ['domain', 'type', 'name', 'discovered_at']
  public total_leaked_cred = null
  public showDataGroupByDomain = true
  public csvLoader = false
  public onlyPasswordCsv = true
  public ReportJobId
  public csvDurationOption = [{
    'title': 'Last Day',
    'value': 1,
    'duration': null
  },
  {
    'title': 'Last Week',
    'value': 7,
    'duration': null
  },
  {
    'title': 'Last Month',
    'value': 30,
    'duration': null
  },
  {
    'title': 'All',
    'value': 'all',
    'duration': null
  }
  ]
  public createNewLeackedPass = {
    "name": "Leaked password CSV download", "job_type": "report_generation", "schedule": { "crontab": {}, "one_off": true }, "job_config": { "template": { "name": "Download Leaked Passwords", "allowed_formats": ["excel"], "id": "15a74f8c-316e-4369-a267-f38e57477079", "require_datetime": false, "key": "download_leaked_passwords", "sections": null, "date_config": { "schedule": { "daily": false, "weekly": false, "monthly": false }, "on_demand": { "all": true, "last_1_day": true, "custom_date": false, "last_1_week": true, "last_1_month": true } } }, "frequency": null, "duration": { "fromDate": null, "toDate": null }, "primary_domain": null, "generate_report_summary" : false }
  }
  public createNewLeackedCred = {
    "name": "Leaked credentials CSV download", "job_type": "report_generation", "schedule": { "crontab": {}, "one_off": true }, "job_config": { "template": { "name": "Download Leaked Credentials", "allowed_formats": ["excel"], "id": "15a74f8c-316e-4369-a267-f38e57477079", "require_datetime": false, "key": "download_leaked_credentials", "sections": null, "date_config": { "schedule": { "daily": false, "weekly": false, "monthly": false }, "on_demand": { "all": true, "last_1_day": true, "custom_date": false, "last_1_week": true, "last_1_month": true } } }, "frequency": null, "duration": { "fromDate": null, "toDate": null }, "primary_domain": null, "generate_report_summary" : false }
  }
  public leakCredsDataForMainDomain = []
  public passwordListLeakMainDomain = {}
  public leakCredCountForMainDomain = 0;
  public pageLeakCredMainDomain=1;
  public passwordsList = {};
  public csvDuration = this.csvDurationOption[0];
  public disbleSubmit = false
  public delDomain:any;
  constructor(
    public commonService: CommonService,
    public portfolioSurveyService: PortfolioSurveyService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public reportService : ReportService,
    public jobService : JobService
  ) { }

  ngOnInit() {
    if (this.domains && this.domains.length > 0) {
      this.allDomains = this.domains.slice();
      // this.dataToDowLoad()
    } else {
      this.allDomains = [];
    }
    // this.getLeakedCredsForMainDomain()
    this.getLeakedCredsDataForMainDomain()
  }
  getLeakedCredsDataForMainDomain(){
    let params = {
      "page":this.pageLeakCredMainDomain,
      "limit":this.limit
    
    }
    this.portfolioSurveyService.getLeakedCrentialsForMainDomain(this.mainDomain['domain_name'],params)
    .subscribe(data => {
      this.loadingLC = true
      if(data && data['data']){

        this.leakCredsDataForMainDomain = data['data']
        this.leakCredCountForMainDomain = this.leakCredsDataForMainDomain[0]['total']
        this.getPasswordsListLeakMainDomain()
        this.loadingLC=false;
        this.showViewChange=true
  
      }
    
    });

  }

  getLeakedCredsForMainDomain() {
    let nextFrom = null;
    if (this.leakedCredsFromPelago && this.leakedCredsFromPelago.length) {
      nextFrom = this.leakedCredsFromPelago[this.leakedCredsFromPelago.length - 1]['uid']
    }
    this.loadingLC = true
    this.portfolioSurveyService.getDomainsLeakedCredentialsFromPelago(this.mainDomain.domain_name, 10, nextFrom).subscribe(data => {
      if (data && data.data && data.data['leakedMaskCreds']) {
        if (!data.data['leakedMaskCreds'].length) this.showViewChange = false
        else {
          if (data.data['leakedMaskCreds'].length < 9) this.showShowMore = false
          this.showViewChange = true;
          this.leakedCredsFromPelago = [...this.leakedCredsFromPelago, ...data.data['leakedMaskCreds']]
        }
      } else {
        this.showViewChange = false;
        this.showShowMore = false
      }
      this.loadingLC = false
    }, error => {
      this.showViewChange = false;
    })
  }

  getLeakedCredentialsforRd(domainData) {
    this.breachedCredentials = [];
    this.showLeakCreadentials = true;
    this.showSpinner = true;
    this.seeMore = 10;
    return new Promise((resolve) => {
      this.portfolioSurveyService.getLeakedCredentialsforRd(domainData.domain, this.mainDomain['domain_name']).subscribe(data => {
        this.breachedCredentials = data['data'];
        this.isUserTrial = data['userAccount'] == 'trial' ? true : false;
        this.allBreachse = this.breachedCredentials.slice();
        resolve(this.breachedCredentials.slice());
        this.relatedDomainName = domainData.domain
        this.showSpinner = false;
      }, error => {
        this.showSpinner = false;
      })
    })
  }

  getLeakedCrentialsPassword(domainData) {
    this.breachedCredentials = [];
    this.showLeakCreadentials = true;
    this.showSpinner = true;
    this.seeMore = 10;
    return new Promise((resolve) => {
      this.portfolioSurveyService.getLeakedCrentialsPassword(domainData.domain, this.mainDomain['domain_name']).subscribe(data => {
        this.breachedCredentials = data['data'];
        this.isUserTrial = data['userAccount'] == 'trial' ? true : false;
        this.allBreachse = this.breachedCredentials.slice();
        resolve(this.breachedCredentials.slice());
        this.showSpinner = false;
      }, error => {
        this.showSpinner = false;
      })
    })
  }
  getPasswords(domainData) {
    return new Promise((resolve) => {
      this.portfolioSurveyService.getLeakedCrentialsPassword(domainData.domain, this.mainDomain['domain_name']).subscribe(data => {
        resolve(data['data'].slice());
      }, error => {
      })
    })
  }
  dataToDowLoad() {
    if (!this.dataToDownload.length) {
      this.dataToDownload = [];
      let promises = []
      this.domains.forEach(element => {
        promises.push(this.getPasswords(element));
      })
      Promise.all(promises)
        .then((results) => {

          results.unshift([{ "domain_name": "INFO", "user_email": "Showing only top 100 credentials for each domain" }])
          this.dataToDownload = [].concat(...results);
          this.firstDownload = true;
        })
        .catch((e) => {
          console.log("error while getting leaked cred!")
        });
    }
  }

  // searchCred(cred) {
  //   var newArray = []
  //   this.breachedCredentials = this.allBreachse.slice();
  //   this.breachedCredentials = this.breachedCredentials.forEach(element => {
  //     if (element.user_email.toLowerCase().indexOf(cred.toLowerCase()) >= 0) {
  //       newArray.push(element);
  //     }
  //   });
  //   this.breachedCredentials = newArray;
  // }

  emitGetSource(credential) {
    credential.showSpinnerGetSource = true;
    this.portfolioSurveyService.getBreachedPassByEmail(credential.user_email).subscribe(data => {
      credential['sources'] = data['data'];
      credential.showSpinnerGetSource = false;
      credential.checked = true;
    }, error => {
      credential.showSpinnerGetSource = false;
    })
  }
  ngOnChanges() {
    this.showSpinner = false;
  }

  getDate(sources) {
    if (sources && sources.length) {
      var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
      // sources.forEach(element => {
      //   let convertedDat = new Date(element.breached_date);
      //   element['convertedDate'] = mL[convertedDat.getMonth()] + " " + convertedDat.getFullYear();
      // })
      let convertedDat = new Date(Math.max.apply(null, sources.map((source) => {
        return new Date(source.breached_date);
      })));
      return convertedDat.getDate() + " " + mL[convertedDat.getMonth()] + " " + convertedDat.getFullYear();
    }
  }

  // callLeakCreaddential(domainData) {
  //   // if (domainData.leaked_credentials_count && domainData.leaked_credentials_count > 0) {
  //   this.breachedCredentials = [];
  //   this.showLeakCreadentials = true;
  //   this.showSpinner = true;
  //   // this.domain = domainName

  //   this.portfolioSurveyService.getBreachedPassByDomain(domainData.domain, this.getSource).subscribe(data => {
  //     this.breachedCredentials = data;
  //     // var domain = localStorage.getItem("domain");
  //     // this.domain = domain;
  //     // if (this.breachedCredentials && this.breachedCredentials.length) {
  //     this.allBreachse = this.breachedCredentials.slice();
  //     //   this.showSpinner = false;
  //     // } else if (this.breachedCredentials && !this.breachedCredentials.length) {
  //     //   this.showSpinner = false;
  //     //   this.showError = "No breached credentials for this domain."
  //     // } else {
  //     this.showSpinner = false;
  //     //   this.showError = "Domain not selected for this page. Please go to 'Start' menu on left menubar and select 'Add Domain'."
  //     // }
  //   })
  //   // }
  // }

  searchDomain(domainName) {
    // if (this.domains && this.domains.length > -1) {
    var newDomain = [];
    this.domains = this.allDomains.slice();
    this.domains.forEach(element => {
      if (element.domain && element.domain.toLowerCase().indexOf(domainName.toLowerCase()) >= 0) {
        newDomain.push(element);
      }
    });
    this.domains = newDomain;
    // }
  }
  openRemoveOptions(template: TemplateRef<any>, leak) {
    leak.selected = true;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
  }

  delLeakCredential(reason) {
    this.leakedCerd.map(lk => {
      if (lk.selected == true) {
        lk.updated = true;
        lk.archive = true;
        lk.archive_reason = reason;
        delete lk['selected']
        // this.delReason = null;
      }
    })
    this.updateBreachedCredentials();
  }

  public updateBreachedCredentials() {
    this.portfolioSurveyService.updateBreachedCredentials(this.leakedCerd, this.mainDomain['domain_name']).subscribe(data => {
      if (!this.delDomain) {
        this.delDomain = this.domains[this.openThis]
      }
      if (data && data['count']) {
        this.delDomain.leaked_credentials_count = this.delDomain.leaked_credentials_count - data['count']
      }
      this.modalRef.hide();
      this.LeakedCredentialsWithPagination(this.delDomain, this.limit);
      this.multiSelectCount = 0;
      this.refreshCount.emit('Leakedcreds');
      alert("Leaked credentials deleted successfully!")
    }, error => {
      this.modalRef.hide();
      if (error['status'] === 403) {
        alert("You do not have permission to perform this action. Please write to us at contact@firecompass.com.")
      } else {
        alert("Error while updating")
      }
    });
  }

  mouseHoverToDiv(divId, i, t = 'none') {
    document.getElementById(`source-tooltip_${i}`).style.display = t;
  }
  cancelDel(a=false) {
    this.leakedCerd.map(content => {
      content.selected = false
    })
    this.singleDelete = false;
    this.multiSelectCount = 0
  }

  deleteDataCheck(value) {
    this.delLeakCredential(value);
  }
  //-------------------------------------------------------------
  LeakcredPaginateOnclik(domainRd) {
    if (domainRd['leaked_credentials_count'] > 0) {
      this.LeakedCredentialsWithPagination(domainRd, this.limit)
    }
    else this.showSpinnerPagination = false;
  }
  getPasswordsList(){
    this.leakedCerd.forEach(element => {
      let passwordList = []
      if(element?.lk_breached_details){
      element?.lk_breached_details.forEach(password => {
        if(password?.passwords && passwordList.indexOf(password?.passwords)==-1){
          passwordList.push(password?.passwords)
        }
      });
      this.passwordsList[element.user_email] = passwordList
    }
    else{
      this.passwordsList[element.user_email] = []

    }
      
    });
  }
  getPasswordsListLeakMainDomain(){
    this.leakCredsDataForMainDomain.forEach(element => {
      let passwordList = []
      if(element?.lk_breached_details){
      element?.lk_breached_details.forEach(password => {
        if(password?.passwords && passwordList.indexOf(password?.passwords)==-1){
          passwordList.push(password?.passwords)
        }
      });
      this.passwordListLeakMainDomain[element.user_email] = passwordList
    }
    else{
      this.passwordListLeakMainDomain[element.user_email] = []

    }
      
    });
  }
  LeakedCredentialsWithPagination(domainRd, limit) {
    this.showSpinnerPagination = true;
    this.leakedCerd = []
    let params = {
      page : this.page,
      limit :  limit, 
      searchKey : this.searchLeakedCredParam,
      ...this.filters,
    }
    this.portfolioSurveyService.LeakedCredentialsWithPagination(this.mainDomain['domain_name'], domainRd['related_domain__domain'], params).subscribe((data: any) => {
      if (data && data.data.length) {
        if (limit > 0) {
          this.total_leaked_cred = data.data[0].total
          this.leakedCerd = data['data'];
          this.getPasswordsList()
          this.showSpinnerPagination = false;
        }
      }else{
        this.total_leaked_cred = 0;
        this.leakedCerd = [];
        this.showSpinnerPagination = false;
      }
    }, error => {
      this.showSpinnerPagination = false;
    })
  }
  searchLeakedCred(domain) {
    this.page = 1;
    this.LeakedCredentialsWithPagination(domain, this.limit)
  }

  goToPage(domain, val) {
    this.page = val;
    this.LeakedCredentialsWithPagination(domain, this.limit)
  }
  goToPageleakCredMainDomain(val) {
    this.pageLeakCredMainDomain = val;
    this.getLeakedCredsDataForMainDomain()
  }

  searchAllsearchLeakedCred(domain) {
    this.searchLeakedCredParam = null
    this.LeakedCredentialsWithPagination(domain, this.limit)
  }
  selctForBulkAction(value) {
    if (value == true) {
      this.leakedCerd.forEach(cred => {
        this.multiSelectCount++;
        cred.selected = true
      })
    } else {
      this.leakedCerd.forEach(cred => {
        cred.selected = false
      })
      this.multiSelectCount = 0;
    }
  }

  selectedNewBox(cred) {
    cred.selected = !cred.selected;
    if (cred.selected) {
      this.multiSelectCount++;
    } else {
      this.multiSelectCount = this.multiSelectCount > 0 ? this.multiSelectCount - 1 : 0;
    }
  }
  downloadCsvModel(template: TemplateRef<any>,) {
    if (this.commonService.reportDownloadState()){ this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
    this.onlyPasswordCsv = true
    this.csvDuration = this.csvDurationOption[0]
    this.disbleSubmit = false;
    } else {
      alert("You have reached your free trial limit. Please contact us at contact@firecompass.com")
    }
  }
  getCsv() {
    const nowDateTime = new Date();
    let fromDate= null
    let createJob = null
    this.createNewLeackedPass.job_config.duration.fromDate = null
    this.createNewLeackedPass.job_config.duration.toDate = null
    this.createNewLeackedCred.job_config.duration.fromDate = null
    this.createNewLeackedCred.job_config.duration.toDate = null

    if (this.onlyPasswordCsv) {
      createJob = this.createNewLeackedPass;
    } else {
      createJob = this.createNewLeackedCred
    }

    if (
      this.csvDuration.value == 1 ||
      this.csvDuration.value == 7 ||
      this.csvDuration.value == 30
    ) {
      fromDate = new Date(
        new Date().setDate(new Date().getDate() - this.csvDuration.value)
      );
      fromDate = fromDate.toISOString()
      createJob.job_config.duration.fromDate = fromDate
      createJob.job_config.duration.toDate = nowDateTime.toISOString()
    }
    this.csvLoader = true
    this.jobService
      .createNewReoprtSchedule(createJob)
      .subscribe(
        (data) => {
          this.ReportJobId = data['id']
          if (this.ReportJobId) {
            this.generateCsv();
          } else {
            alert("Error while downloading leaked credential CSV")
          }
        }
      );
    this.modalRef.hide()
    this. disbleSubmit =false;

  }
  cancelDownload() {
    this.modalRef.hide()
    this.onlyPasswordCsv = true
    this.csvDuration = this.csvDurationOption[0]
  }

  viewChange() {
      this.csvDuration = this.csvDurationOption[0]
  }
  generateCsv() {
    let poolingCount = 1;
    const poolingInterval = setInterval(() => {
      this.reportService.generateLeakedCredCsv(this.ReportJobId).subscribe((data: any) => {
        poolingCount = poolingCount + 1
        if (data && data.results && data.results.length || poolingCount > 12) {
          if (data.results[0].report_status == 'completed') {
            this.csvLoader = false
            window.open(data.results[0].files[0]['url'], '_blank');
          } else if (data.results[0].report_status == 'failed') {
            this.csvLoader = false
            alert("Error while downloading leaked credential CSV")
          }
          clearInterval(poolingInterval);
        } else if (data && data.results && !data.results.length && poolingCount > 12) {
          alert("Error while downloading leaked credential CSV")
        }
      })
    }, 10000)
  }
}
