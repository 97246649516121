import { Component, OnInit, Input } from '@angular/core';
import { PortfolioSurveyService } from '../../_services';
import { CommonService } from '../../_services/common.service';

@Component({
	selector: 'app-dns-record',
	templateUrl: './dns-record.component.html',
	styleUrls: ['./dns-record.component.scss']
})
export class DnsRecordComponent implements OnInit {
	@Input() mainDomain: any;
	@Input() domains: any;
	@Input() allDns = [];
	@Input() downloadAllDns: any;
	@Input() isUserTrial: boolean = false;
	@Input() filters = {};
	public page = 1;
	public limit = 10;
	public savelimit = 10;
	public totalItems = 0;
	public initialSort = false;
	public enableDownload = false;
	public sortWith = 'domain';
	public order = 1;
	public dnsRec: any;
	public seeMoreDns: number = 20;
	public keysToSearch = ['domain', 'type', 'name', 'discovered_at']
	public searchdns: any;
	public seeMore = 4;
	public showSpinnerPagination = true
	public orderingByName = false;

	constructor(public commonService: CommonService,
		public portfolioSurveyService: PortfolioSurveyService) { }

	public fieldsToDownload = {
		'Domain': 'name',
		'Value': 'dns_details.name',
		'Type': 'dns_details.type',
		'Discovered At': 'dns_details.discovered_at'
	}

	ngOnInit() {
		this.callDnsService(this.limit);
	}
	ngOnChanges(){
		this.page = 1;
		this.limit = 10;
		this.callDnsService(this.limit);
	}
	callDnsService(limit) {
		this.showSpinnerPagination = true
		this.portfolioSurveyService.dnsRecordSearch(this.mainDomain['domain_name'], this.searchdns, this.page, 10, this.filters, this.orderingByName).subscribe((data: any) => {
			this.showSpinnerPagination = false
			if (data && data.length) {
				if (limit > 0) {
					this.totalItems = data[0].total
					this.allDns = data;
				} else {
					this.downloadAllDns = [];
					for (var i = 0; i < data[0].total; i++) {
						for (var j = 0; j < data[i]['dns_details'].length; j++) {
							this.downloadAllDns.push({ 'name': data[i].name, 'dns_details': data[i]['dns_details'][j] });
						}
					}
					this.enableDownload = true;
				}
			}else{
				this.totalItems=0;
				this.allDns =[];
			}

		})
	}

	searchDns() {
		this.page = 1;
		this.callDnsService(this.limit)
	}

	goToPage(val) {
		this.page = val;
		this.callDnsService(this.limit)
	}
	searchAllDns() {
		this.searchdns = null
		this.callDnsService(this.limit)
	}

}
