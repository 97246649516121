import { Injectable, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { PortfolioSurveyService } from 'app/_services/portfolio-survey.service';
import { AssetTypePipe } from 'app/pipes/asset-type.pipe';

// ip-cidr
const IPCIDR = require("ip-cidr");
const BigInteger = require("jsbn").BigInteger;

@Injectable()
export class CommonService {
    
    public assetTypeP = new AssetTypePipe();
    public profile;
    public maxAllowedRange = 256;
    public domainNameEmmiter = new EventEmitter();
    constructor(
        public portfolioSurveyService: PortfolioSurveyService,
        public route: ActivatedRoute, public router: Router,
    ) { }

    public states = {
        "in_progress" : "In progress",
        "completed": "Completed",
        "new": "New",
        "aborted":"Aborted",
        "errored": "Errored	",
        "paused": "Paused",
        "suspended": "Suspended",
        "queued": "Queued"
      }
      public targetMaping = {
        "domain" : "Domain",
        "ip_address" : "IP address",
        "application" : "Application",
        "netblock" : "Netblock",
        "email" : "Email",
        "keyword" : "Keyword",
        "sub_domain" : "Sub-domain",
        }
    public entitiesProfile = {
        "start": {
            initials: "S",
            smallCase: "start",
            hoverText: "Start",
            color: "Start",
            width: "60"
        },
        "ip": {
            initials: "IP",
            smallCase: "ip",
            hoverText: "IP address",
            color: "IP address",
            width: "150"
        },
        "ip_ports": {
            initials: "IP:P",
            smallCase: "ip:ports",
            hoverText: "IP:Ports",
            color: "Port",
            width: "1"
        },
        "ip_address": {
            initials: "IP",
            smallCase: "ip",
            hoverText: "IP address",
            color: "IP address",
            width: "150"
        },
        "service": {
            initials: "P",
            smallCase: "service",
            hoverText: "Port",
            color: "Port",
            width: "90",
            entities:["protocol","port","host_id"],
        },
        "service_state": {
            initials: "SS",
            smallCase: "service_state",
            hoverText: "Service state",
            color: "Service state",
            width: "80"
        },
        "domain": {
            initials: "D",
            smallCase: "domain",
            hoverText: "Domain",
            color: "Domain",
            width: "200"
        },
        "email": {
            initials: "E",
            smallCase: "start",
            hoverText: "Email",
            color: "Email",
            width: "200"
        },
        "sub_domain": {
            initials: "SD",
            smallCase: "email",
            hoverText: "Sub domain",
            color: "Sub domain",
            width: "200"
        },
        "url": {
            initials: "A",
            smallCase: "url",
            hoverText: "Url/Application",
            color: "Url/Application",
            width: "150"
        },
        "web_app": {
            initials: "A",
            smallCase: "web_app",
            hoverText: "Application",
            color: "Application",
            width: "150"
        },
        "web_app_vulnerability": {
            initials: "V",
            smallCase: "web_app_vulnerability",
            hoverText: "Web app vulnerability",
            color: "web_app_vulnerability",
            width: "150"
        },
        "netblock": {
            initials: "NB",
            smallCase: "netblock",
            hoverText: "Netblock",
            color: "Netblock",
            width: "150"
        },
        "keyword": {
            initials: "KW",
            smallCase: "keyword",
            hoverText: "Keyword",
            color: "Keyword",
            width: "150"
        },
        "cloud_bucket":{
            "smallCase":"cloud_bucket",
            "initials":"CB",
            "hoverText":"Cloud Bucket",
            "width":150,
            "entities":["data"]
        },
        "file_path":{
            "smallCase":"file_path",
            "initials":"FP",
            "hoverText":"File Path",
            "width":150,
            "entities":["data"]
        },
        "internet_name":{
            "smallCase":"internet_ame",
            "initials":"IN",
            "hoverText":"Internet Name",
            "width":150,
            "entities":["data"]
        },
        "email_domain":{
            "smallCase":"Domain",
            "initials":"D",
            "hoverText":"Email Domain",
            "width":150,
            "entities":["data"]
        },
        "certificate_san_entry":{
            "smallCase":"certificate_san_entry",
            "initials":"CS",
            "hoverText":"Certificate SAN Entry",
            "width":150,
            "entities":["data"]
        },
        "dns_txt_record":{
            "smallCase":"dns_txt_record",
            "initials":"DT",
            "hoverText":"DNS Text Record",
            "width":150,
            "entities":["data"]
        },
        "dns_soa_record":{
            "smallCase":"dns_soa_record",
            "initials":"DS",
            "hoverText":"DNS SOA Record",
            "width":150,
            "entities":["data"]
        },
        "email_address":{
            "smallCase":"email_address",
            "initials":"EA",
            "hoverText":"Email Address",
            "width":150,
            "entities":["data"]
        },
        "domain_name":{
            "smallCase":"domain",
            "initials":"D",
            "hoverText":"Domain",
            "width":150,
            "entities":["data"]
        },
        "org_name":{
            "smallCase":"Organization Name",
            "initials":"Organization Name",
            "hoverText":"Organization Name",
            "width":150,
            "entities":["data"]
        },
        "dns_sec_ext":{
            "smallCase":"dns_sec_ext",
            "initials":"DE", // **** TD change it to 4 letter
            "hoverText":"DNS Security Extensions",
            "width":150,
            "entities":["data"]
        },
        "date":{
            "smallCase":"date",
            "initials":"D",
            "hoverText":"Date",
            "width":150,
            "entities":["data"]
        },
        "registrar_name":{
            "smallCase":"registrar_name",
            "initials":"RN",
            "hoverText":"Registrar Name",
            "width":150,
            "entities":["data"]
        },
        "country":{
            "smallCase":"country",
            "initials":"C",
            "hoverText":"Country",
            "width":150,
            "entities":["data"]
        },
        "cpe":{
            "smallCase":"cpe",
            "initials":"CP",
            "hoverText":"CPE",
            "width":150,
            "entities":["data"]
        },
        "leaked_content":{
            "smallCase":"leaked_content",
            "initials":"LC",
            "hoverText":"Leaked Content",
            "width":150,
        },
        "credential":{
            "smallCase":"credential",
            "initials":"C",
            "hoverText":"Credential",
            "width":150,
            "entities":["email","password_plaintext","username"]
        },
        "organisation_account":{
            "smallCase":"organisation_account",
            "initials":"OA",
            "hoverText":"Organization Account",
            "width":150,
            "entities":["avatar_url","name","data"]
        },
        "static_code_vulnerability":{
            "smallCase":"static_code_vulnerability",
            "initials":"SC", // **** TO DO change to 3 laters
            "hoverText":"Static Code Vulnerability",
            "width":150,
        },
        "social_account":{
            "smallCase":"social_account",
            "initials":"SA",
            "hoverText":"Social Account",
            "width":150,
            "entities":["data","description","avatar_url"]
        },
        "secret_key":{
            "smallCase":"secret_key",
            "initials":"SK",
            "hoverText":"Secret Key",
            "width":150,
            "entities":["data","vendor","product","key_type","key","secret","token","location"]
        },
        "code_repo":{
            "smallCase":"code_repo",
            "initials":"CR",
            "hoverText":"Code Repository",
            "width":150,
            "entities":["data","type","name","url","description","fork","has_downloads","forks_count","size"]
        },
        "plugin":{
            "smallCase":"plugin",
            "initials":"P",
            "hoverText":"Plugin",
            "width":150,
            "entities":["data","name","uid","family","type"]
        },
        "cve":{
            "smallCase":"cve",
            "initials":"CV",
            "hoverText":"CVE",
            "width":150,
            "entities":["data","host_id","service_id","vulnerability_id"],
            "computed_data":[{"key":"service_id","key_to_save":"data","key_name_to_save":"service"},
                            {"key":"host_id","key_to_save":"data","key_name_to_save":"ip_address"},
                            {"key":"vulnerability_id","key_to_save":"data","key_name_to_save":"vulnerability"}
    
                ]
        },
        "vulnerability":{
            "smallCase":"vulnerability",
            "initials":"V",
            "hoverText":"Vulnerability",
            "width":150,
        },
        "ipv6_address":{
            "smallCase":"ipv6_address",
            "initials":"IP",
            "hoverText":"IPV6 Address",
            "width":150,
            "entities":["data"]
        },
    }
    returnInitials(entity) {
        if (this.entitiesProfile[entity]) {
            return this.entitiesProfile[entity]['initials']
        } else return (entity.substring(0, 2)).toUpperCase()
    }
    returnWidth(entity) {
        if (this.entitiesProfile[entity]) {
            return this.entitiesProfile[entity]['width']
        } else return 150
    }
    returnHoverText(entity) {
        if (this.entitiesProfile[entity]) {
            return this.entitiesProfile[entity]['hoverText']
        } else return entity
    }
    getScanProfileList() {
        return {
            'deep_monitoring': {
                'name': 'Deep Monitoring',
                'code': 'deep_monitoring',
                'checked': false
            },
            'va_monitoring_openvas': {
                'name': 'VA Monitoring - OpenVAS',
                'code': 'va_monitoring_openvas',
                'checked': false
            }
        }
    }
    getJsonCopy(json) {
        return JSON.parse(JSON.stringify(json));
    }

    getNoOfDays(date) {
        if (date) {
            let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            let dateFormat = new Date(date).getTime();
            let todayDate = new Date().getTime();
            let diffDays = Math.round(Math.abs((todayDate - dateFormat) / (oneDay)));
            return diffDays;
        }
    }

    getNoOfDays_nonabsolute(date) {
        if (date) {
            let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            let dateFormat = new Date(date).getTime();
            let todayDate = new Date().getTime();
            let diffDays = Math.round((dateFormat - todayDate) / (oneDay));
            return diffDays;
        }


    }

    checkCreatedAndUpdated(domain) {
        if (domain && domain.created_at) {
            domain['createdNoOfDays'] = this.getNoOfDays(domain.created_at);
        }
        if (domain && domain.updated_at) {
            domain['updatedNoOfDays'] = this.getNoOfDays(domain.updated_at);;
        }
    }

    checkDateIsSameorNot(application, mainDomain) {
        if (application && mainDomain) {
            if (application.created_at && mainDomain.created_at) {
                let applicationdate = new Date(application.created_at);
                let mainDomainDate = new Date(mainDomain.created_at);
                if (applicationdate.toDateString() !== mainDomainDate.toDateString()) {
                    // if (this.getNoOfDays(application.created_at) > 7 && this.getNoOfDays(application.updated_at) > 7) {
                    //   return false;
                    // } else {
                    return true;
                    // }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getDate(dateTime) {
        if (dateTime) {
            if (dateTime.includes("T")) {
                return dateTime.split('T')[0];
            } else if (dateTime.includes(' ')) {
                return dateTime.split(' ')[0];
            } else {
                return dateTime;
            }
        } else {
            return null;
        }
    }

    
    getDateWithTime(dateTime) {
        if (dateTime) {
            if (dateTime.includes("T")) {
                return new Date(dateTime)
            } else if (dateTime.includes(' ')) {
                return dateTime
            } else {
                return dateTime;
            }
        } else {
            return null;
        }
    }


    getTodaysDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }
    getCurrentDateTime() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        return yyyy + "-" + mm + "-" + dd + "T" + time;
    }

    confidenceLevel(data) {
        data = parseFloat(data)
        let confidence = "Low";
        if (data) {
        if (data < 0.0) {
            confidence = "Info";
            return confidence;
        } else if (data >= 0.0 && data < 4.0) {
            confidence = "Low";
            return confidence;
        } else if (data >= 4.0 && data < 7.0) {
            confidence = "Medium";
            return confidence;
        } else if (data >= 7.0 && data < 9.0) {
            confidence = "High";
            return confidence;
        } else if (data >= 9.0) {
            confidence = "Critical";
            return confidence;
        }
        }
        return confidence;
    }

    getUniqueValues(data_list, key) {
        let unique = []
        if (data_list && data_list.length) {
            data_list.forEach(value => {
                if (key) {
                    if (unique.findIndex(u => value[key] == u[key]) == -1) {
                        unique.push(value);
                    }
                } else {
                    if (unique.findIndex(u => value == u) == -1) {
                        unique.push(value);
                    }
                }
            })
        }
        return unique;
    }

    public getDomainName() {
        this.portfolioSurveyService.getDomainsSearchForUser().subscribe((data: any) => {
            let domain: String
            data.filter(d => {
                if (d['domain_type'] === 'self') {
                    domain = d['domain']
                }
            })
            if (domain) {
                this.domainNameEmmiter.emit(domain);
            } else {
                alert(`Please check if you are logged. If you are still facing the 
                    issue please right to us @ contact@firecompass.com`)
                this.domainNameEmmiter.emit(null);
                // return null;
            }
        }, err => {
            alert(`Please check if you are logged. If you are still facing the 
                issue please right to us @ contact@firecompass.com`)
        })
    }
    create_UUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
    public showVulColor(severity) {
        switch (true) {
            case (severity >= 9): return 'badge-danger !important';
            case (severity >= 7 && severity < 9): return 'badge-warning !important';
            case (severity >= 4 && severity < 7): return 'badge-primary !important';
            case (severity >= 0 && severity < 4): return 'badge-success !important';
            case (severity < 0): return 'badge-secondary !important';
            default: return 'badge-success';
        }
    }
    getTimeStamp(noOfDay) {
        const oneDayInMilli = 86400000
        let toCalculateOn = noOfDay * oneDayInMilli
        var currentMilli = new Date().getTime();
        var finalMilli = currentMilli - toCalculateOn
        return finalMilli

    }
    calculateCreatedAndUpdated(domain_list) {
        if (domain_list && typeof (domain_list) == 'object' && domain_list.length) {
            domain_list.forEach(element => {
                this.checkCreatedAndUpdated(element);
            });
        }
    }
    setUserAccount(userAccount) {
        if (userAccount && userAccount == 'trial') {
            return true
        }
    }
    errorForDomain(error) {
        if (error['status'] == 403) {
            alert("Forbidden! You are not allowed to perform this action. Please contact us at contact@firecompass.com")
            this.router.navigate(['/new/self-domains']);
        }
    }
    validReason(reason) {
        return reason.trim().length
    }
    reportDownloadState() {
        var profile = JSON.parse(localStorage.getItem("profile"))
        if (profile['reportDownload']) return profile['reportDownload']
        else return false
    }
    public weekDays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    getDayOfWeekFromDate(date) {
        try {
            if (date) {
                return this.weekDays[date.getDay()]
            } return 'NA'
        } catch (e) {
            return 'NA'
        }
    }
    generateRandomAlphaNumericKey(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    severityOfRiskLevel(riskLevel) {
        switch (true) {
          case (riskLevel == 'Critical'): return 'badge-danger';
          case (riskLevel == 'High'): return 'badge-warning';
          case (riskLevel == 'Medium'): return 'badge-primary';
          case (riskLevel == 'Low'): return 'badge-success';
          case (riskLevel == 'Info'): return 'badge-secondary';
          case (riskLevel == 'None'): return 'badge-light text-dark';
    
          default: return 'badge-primary';
        }
    }
    severityLableOfScore(score){
        let severity = '';
        if(score<0){
            severity = 'Info';
        }else if(score>=0 && score<4){
            severity = 'Low';
        }else if(score>=4 && score<7){
            severity = 'Medium';
        }else if(score>=7 && score<9){
            severity = 'High';
        }else if(score>=9){
            severity = 'Critical';
        }
        return severity;
    }
    conevrtStringToSnakeCase(string){
        return (string.split(" ").join("_")).toLowerCase();
    }
    convertSnakeCaseToString(string) {
        return string.toLowerCase().split('_').map(function(word) {
            return word.replace(word[0], word[0].toUpperCase());
        }).join(' ');
    }
    fillMissingTimelineData(timeLine, dateRange) {
        // Adding empty key value for missing timeline data
        let timeLineDataArray = []
        for (let i = 0; i<dateRange.length; i++) {
            let tr = dateRange[i]
            if (!timeLine[tr['weekNumber']]) {
                timeLine[tr['weekNumber']] = {created: 0, archived: 0}
            }
            timeLine[tr['weekNumber']]['label'] = tr['startDate'] + " - " + tr['endDate']
            timeLineDataArray.push({...tr, ...timeLine[tr['weekNumber']]})
        }
        return timeLineDataArray
    }
    getTimelineData(currentCount, timeLine) {
        // Computing total from on current value and timeline data
        // lastweek.total = thisweeks.total - thisweek.created + thisweek.removed 
        timeLine.forEach(d => {
            d.totalCount = currentCount>0 ? currentCount : 0
            currentCount = currentCount - d.created + d.archived
        })
    }
    getPercentage(val, total) {
        if (total)
            return Math.round((val/total*100)*100)/(100)
        else {
            return val
        }
    }
    sortObject(obj, sortOrder = -1) {
        /*
        input obj = {
            a: 10,
            b: 15,
            c: 20
        }
        returns [[10, a], [15, b], [20, c]]
        */
        if (obj) {
            let sortable = [];
            for (var vehicle in obj) {
                sortable.push([obj[vehicle], vehicle]);
            }

            sortable = sortable.sort(function(a, b) {
                return sortOrder*(a[0] - b[0]);
            });
            return sortable;
        }
    }
    maskPassword(password){
        if (password.length > 3) {
          return (
            password.substring(0, 2) +
            password.substring(1, password.length - 1).replace(/./g, "*") +
            password.substring(password.length - 1)
          );
        }
        return (
          password.substring(0, 1) +
          password.substring(1, password.length - 1).replace(/./g, "*") +
          password.substring(password.length - 1)
        );
    }
    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }
    sortByKey(array, key, order = 0) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            if(order){
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }else {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
        });
    }
    getRange(ip){
        let max = this.maxAllowedRange + 1;
        let cidr = new IPCIDR(ip);
        let range = cidr.toArray({ from: 0, limit: new BigInteger(max.toString()) });
        return range.length;
      }
    matchAllParamValue(_arr1, _arr2) {
        if (
            !Array.isArray(_arr1)
            || !Array.isArray(_arr2)
            || _arr1.length !== _arr2.length
        ) {
            return false;
        }
        const arr1 = _arr1.concat().sort();
        const arr2 = _arr2.concat().sort();
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }
    parseValidate(serVul) {
        if (serVul && serVul.applications) {
            serVul.applications.map(x => {
                let validated = (x.validated[0]) ? 'Yes' : 'No';
                x.source_url = x.source_url + ' (' + validated + ')';
            })
            return serVul;
        }
    }
    getScopeStatus(scopeStatus){
        let status:any = {
            "1":"In To Out",
            "2":"In To Not Defined",
            "3":"Out To In",
            "4":"Out To Not Defined",
            "5":"Not Defined To Out",
            "6":"Not Defined To In"
        };
        return (status[scopeStatus])?status[scopeStatus]:null;
    }
    checkDeltaActionString(e,newData:any=null){
        if(e.action){
            let actionString = e.action;
            if(newData){
                if(e.action=="archived"){
                    if(newData.archive==false && newData.false_positive==true){
                        actionString = 'False Positive'
                    }
                }
            }else{
                if(e.action=="archived"){
                    if(e.archive==false && e.false_positive==true){
                        actionString = 'False Positive'
                    }
                }
            }

            return this.convertSnakeCaseToString(actionString);
        }
    }
    getParsedTarget(target) {
        if (this.assetTypeP.transform(target, true) == 'ip_ports') {
            target = target.replaceAll('|', ", ")
        }
        return target
    }
}
