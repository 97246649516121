import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit {
  @Input() bannerText
  showText: Boolean = true
  
  constructor() { }
  
  ngOnInit(): void {
  }

}
