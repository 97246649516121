import { Injectable } from "@angular/core";
import { max_hours_of_inactivity_before_logout } from "../../environments/environment";
import { Idle_logout_popup_emiterService } from "./idle_logout_popup_emiter";

@Injectable({
	providedIn: 'root'
})
export class Session_expire_commonService {
	private max_hours_of_inactivity_before_logout:number =  max_hours_of_inactivity_before_logout.hour; // hour
	constructor(
		public idleLogoutEmmitor: Idle_logout_popup_emiterService
	) { }

	isSessionTimedOut(){
		const currentTime:any = new Date().getTime() / 1000;
		let lastActivityTimestamp:any = localStorage.getItem("lasActivity");
		if (lastActivityTimestamp && (currentTime-lastActivityTimestamp)> (this.max_hours_of_inactivity_before_logout * 3600)) {
			this.idleLogoutEmmitor.emit(this.max_hours_of_inactivity_before_logout);
			return true;
		} else {
			return false;
		}
	}
}