import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SearchService } from 'app/_services/search.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
import { TrialCheckService } from 'app/_services/trial-check.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  public showHelpBox: boolean = false;
  public searching: boolean = false;
  public searchKey:string = '';
  public assetData = [];
  public assetDataCount = null;
  public page = 1;
  public start_time:any = null;
  public end_time:any = null;
  public page_size = 10;
  public downloading = false; 
  public today = new Date();
  public isTrialUser:boolean = true;
  public bsConfig = { 
    // dateInputFormat: 'DD/MM/YYYY',
    ranges: [{
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
      label: 'Last 7 Days'
    },{
      value: [new Date(new Date().setDate(new Date().getDate() - 15)), new Date()],
      label: 'Last 15 Days'
    },{
      value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      label: 'Last 30 Days'
    },{
      value: [new Date(new Date().setDate(new Date().getDate() - 60)), new Date()],
      label: 'Last 60 Days'
    },{
      value: [new Date(new Date().setDate(new Date().getDate() - 90)), new Date()],
      label: 'Last 90 Days'
    }]
  };
  public dateRange = this.bsConfig.ranges[2].value;
  public fieldsToDownload = {
    'Asset Type':'asset_type',
    'Asset Value':'asset_value',
    'Service Name':'service.name',
    'Service Port':'service.port',
    'Service Protocol':'service.protocol',
    'Validated':'validated',
    'Timestamp':'timestamp',
  }
  public bannerJsonToDownload: any = [];
  @Input() mainDomain: any;
  @ViewChild("downloadBannerComp") downloadBannerComp;
  // @ViewChild('searchKeyword') SearchInput: ElementRef;
  // public searchDropdown:any = [
  //   {"title":"Apache","query":"apache"},
  //   {"title":"Security","query":"security"},
  //   {"title":"http","query":"http"},
  //   {"title":"tcp","query":"tcp"},
  //   {"title":"Shodan","query":"shodan"},
  //   {"title":"WordPress","query":"wordPress"},
  //   {"title":"Service Port (443)","query":"service.port:443"},
  // ];
  public copyText = {
    outputBanner:'output.banner:"PostgreSQL"',
    joomlaServer: '(1627330242) OR ("-1950415971") OR  (366524387) OR joomla',
    microsoftServer: 'output.banner: "220 Microsoft FTP Service"',
    wordpress:'("wp-admin") OR ("wp-content")'
  };
  public selectedIp = "";
  public selectedDomain = "";
  constructor(
    public searchService:SearchService,
    public toastr: ToastrService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public trialCheckService: TrialCheckService
  ) {
    // this.searchKey = "*";
    this.getSearchResults();
  }

  ngOnInit() {
    this.isTrialUser = this.trialCheckService.isTrailUser();
  }

  clearSearch() {
    this.assetData = []
    this.searchKey = "";
    this.assetDataCount = null;
    this.page = 1;
    this.dateRange = this.bsConfig.ranges[2].value;
    this.getSearchResults();
  }
  isValidKeyword() {
    let key = "";
    if (this.searchKey) {
      key = this.searchKey.trim();
    }
    return (key) ? true : false;
  }
  getSearchResults(){
    // if(this.searchKey){
      this.searching = true;
      let that = this;
      this.start_time = null;
      this.end_time = null;
      if(this.dateRange){
        this.start_time = new Date(this.dateRange[0]).getTime();
        this.end_time = new Date(this.dateRange[1]).getTime();
      }
      // console.log(this.dateRange, start_time, end_time);
      this.searchService.searchByKeyWord(this.searchKey,{asset_type:'ip',page:that.page,start_time:this.start_time,end_time:this.end_time,page_size:this.page_size}).subscribe(function(data:any){
        that.assetDataCount = data['count'];
        that.assetData = data['hits'];
        if(that.assetData && that.assetData.length>0){
          that.assetData.map((x:any) => { 
            x['showRawBanner'] = false;
            if(x['raw_output']) x['raw_output'] = JSON.parse(x['raw_output']);
         })
        }
        that.searching = false;
      },(error)=>{
        console.log(error);
        that.searching = false;
        that.toastr.error("Something went wrong, Unable to search.");
      })
    // }
  }
  goToPage(val){
    this.page = val;
    this.getSearchResults();
  }
  formatDate(date){
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let pd = new Date(date);
    let dateString = monthNames[pd.getMonth()]+' '+pd.getDate()+', '+pd.getFullYear();
    return dateString;
  }
  downloadBanners(){
    if(!this.downloading){
      let that = this;
      this.downloading = true;
      this.searchService.bannerDownload(this.searchKey,{
        start_time:this.start_time,
        end_time:this.end_time,
        page_size:10000,
        fields:'asset_type,asset_value,name,service.port,service.protocol,timestamp,validated'
      }).subscribe(function(data:any){
        if(data && data['hits'] && data['hits'].length>0){
          that.bannerJsonToDownload = data['hits'];
          that.bannerJsonToDownload.map(x=>{
            const date = new Date(x.timestamp);
            x.timestamp = date.toLocaleString("en-US");
          })
          setTimeout(function(){
            that.downloadBannerComp.download();
            that.bannerJsonToDownload = [];
            that.downloading = false;
          },1000);
        }else{
          that.bannerJsonToDownload = [];
          that.downloading = false;
        }
      },(error)=>{
        console.log(error);
        that.bannerJsonToDownload = [];
        that.downloading = false;
        that.toastr.error("Something went wrong, Unable to download.");
      })
    }
  }
  formatTimestamp(timestampString){
    let valid = (new Date(timestampString)).getTime() > 0;
    if(!valid) return '-';
    let k = new Date(timestampString);
    return k.toLocaleString("en-US");   
  }
  // async onSearchBannerChange() {
  //   let key = (<HTMLInputElement>document.getElementById("searchKeyword")).value.toLowerCase();
  //   let newCountryCodes = this.searchDropdown.filter((s) => {
  //     //`${code.title} (+${code.query})`.includes(key));
  //     let string = s.title.toLowerCase()+' '+s.query.toLowerCase();
  //     return string.includes(key);
  //   })
  //   let dropdown:any = document.getElementById("searchDropdown")
  //   dropdown.style.display = "block";
  //   dropdown.innerHTML = "";
  //   newCountryCodes.forEach((value, index, array) => {
  //     let li = document.createElement('li');
  //     li.style.padding = "3px 1px 3px 1px";
  //     li.style.margin = "2px"; 
  //     li.style.borderBottom = "1px #eee solid";
  //     li.value = Number(value.query);
  //     li.innerHTML = `${value.title}`;
  //     li.addEventListener('click', (e) => {
  //       this.selectSearchBanner(value);
  //     });
  //     dropdown.appendChild(li);
  //   });
  // }
  // async closeSearchBannerDropdown() {
  //   await new Promise(f => setTimeout(f, 200));
  //   document.getElementById("searchDropdown").style.display="none";
  // }
  // async openSearchBannerDropdown() {
  //   document.getElementById("searchDropdown").style.display="block";
  // }
  // async selectSearchBanner(s:any) {
  //   this.searchKey = s.query;
  //   this.SearchInput.nativeElement.value = s.query;
  //   this.closeSearchBannerDropdown();
  // }
  copyIt(str:any){
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand("copy");
    this.toastr.info(str+' copied.');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  showIPDetails(ipDetailsTemplate,ip){
    this.selectedIp = ip;
    this.selectedDomain = this.mainDomain['domain_name'];
    this.modalRef = this.modalService.show(ipDetailsTemplate, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
}
