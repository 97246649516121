import { Component, OnInit, Input } from "@angular/core";
import { PortfolioSurveyService } from "../../_services";

@Component({
  selector: "app-shadow-table",
  templateUrl: "./shadow-table.component.html",
  styleUrls: ["./shadow-table.component.scss"],
})
export class ShadowTableComponent implements OnInit {
  @Input() shadowData: any;
  @Input() tabType: string = "third-party";
  public seeMore = 10;
  public oldShadowData: any;
  public showSpinner;
  public showError;

  constructor(public surveyService: PortfolioSurveyService) {}

  ngOnInit() {
    console.log(this.shadowData[0]["ip_address"]);
    this.oldShadowData = Object.assign([], this.shadowData);
    // if (this.shadowData && this.shadowData.hosts) {
    //   this.shadowData.hosts.sort(function (a, b) {
    //     if ((a.host === null) && (b.host === null)) {
    //       return 1;
    //     } else if (a.host === null) {
    //       return 1;
    //     } else if (b.host === null) {
    //       return -1;
    //     }
    //   });
    // }
  }

  removeShadowData(data, index) {
    this.shadowData.splice(index, 1);
  }

  searchDomain(searchValue) {
    if (searchValue.trim()) {
      this.oldShadowData = this.oldShadowData.filter((el) => {
        if (el.host)
          return el.host.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
      });
    } else {
      this.oldShadowData = Object.assign([], this.shadowData);
    }
  }
}
