import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { AssessmentService } from "../../_services";
import { CommonService } from "app/_services/common.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { RbToNgxGraphFormatService } from "app/_services/rb-to-ngx-graph-format.service";
import { RolesService } from "app/_services/roles.service";
import { unified } from "unified";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";

import remarkRehype from "remark-rehype";
import remarkStringify from "rehype-stringify";

@Component({
  selector: "app-rb-selection",
  templateUrl: "./rb-selection.component.html",
  styleUrls: ["./rb-selection.component.scss"],
})
export class RbSelectionComponent implements OnInit {
  public showThis = "molecular";
  public runBookListToShow;
  public offset;
  public page = 1;
  public atomicRunbookToShow = [];
  public showSpinnerPagination = false;
  public molecularRunBookToShow = [];
  public runbookList = [];
  public searchValue;
  public searchKey;
  public searchCategory = "Category";
  public scope = {
    PORTS: ["8080", "8000"],
    PLUGIN_SEARCH_EXPRESSION: "test_expression",
    PLUGINS_IDS: ["100", "200"],
  };
  public industries;
  public categories = {};
  public keys = [];
  public categoriesLength = -1;
  public showSpinner = true;
  public callAtomicCount = true;
  public descriptionToShow;
  public openDropdown = false;
  public quickSearch:any = [
    {searchKey:'Credential', title:'Credential Stuffing'},
    {searchKey:'Web App', title:'Web Application'},
    {searchKey:'Network Vul', title:'Network vul'},
    {searchKey:'Ransomware', title:'Ransomware'},
  ]

  @Output() newAssessment = new EventEmitter<any>();
  @Input() showError: Boolean;
  public selectedRB = {};
  public runbookToView = {};
  selectedIndustries: any[];
  constructor(
    public commonService: CommonService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    private rbToNgxGraphFormat: RbToNgxGraphFormatService,
    public airTableService: AssessmentService,
    public roleService: RolesService
  ) {}

  ngOnInit(): void {
    this.runBookListToShow = this.runbookList;
    this.getAllRunbookDetails();
    this.getListOfIndusties();
  }
  onScroll() {
    if (this.showThis != "molecular") this.goToPage(this.page + 1);
  }
  showLatestRunBook() {
    this.runBookListToShow = this.runbookList;
  }
  setRunbook() {
    this.runBookListToShow = this.runbookList;
  }

  getStatus() {
    if (this.showThis == "molecular" && !this.showSpinnerPagination) {
      if (
        this.molecularRunBookToShow.length == 0 ||
        this.runBookListToShow.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.showThis == "all" && !this.showSpinnerPagination) {
      if (this.runBookListToShow.length == 0) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.showThis == "atomic_assessment" &&
      !this.showSpinnerPagination
    ) {
      if (this.atomicRunbookToShow.length == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  searchRunbooks() {
    // if (this.showThis == "all") {
    //   this.getAllRunbookDetails();
    // } else if (this.showThis == "atomic_assessment") {
    //   this.offset = null;
    //   this.runBookListToShow = [];
    //   this.getAtomicRunbookPageDetails();
    // } else {
    //   this.getMolecularRunBookSearchDetails();
    // }
    this.getAllRunbookDetails();
    this.getListOfIndusties();
  }

  getAllRunbookDetails() {
    this.showSpinnerPagination = true;
    this.runBookListToShow = [];
    this.molecularRunBookToShow = [];
    this.atomicRunbookToShow = [];
    var runBookList = [];
    this.searchValue = null;
    this.offset = null;
    Promise.all([
      this.getMolecularRunBook(),
      this.getAtomicRunbookDetails(),
    ]).then(([responseMolecular, responseAtomic]) => {
      if (responseMolecular["results"].length > 0) {
        runBookList = responseMolecular["results"];
        this.molecularRunBookToShow = Object.assign([], runBookList);
      }

      let atomicRunbookData = responseAtomic["records"];
      this.offset = responseAtomic["offset"];

      // atomicRunbookData.forEach((data) => {
      //   let jsonData = this.formatAtomicRB(data);
      //   this.atomicRunbookToShow.push(jsonData);
      //   runBookList.push(jsonData);
      // });
      this.showSpinnerPagination = false;
      this.runBookListToShow = runBookList;
      this.rbToNgxGraphFormat.convertElementsToNodesAndEdges(runBookList);
      // this.setAtomicRunbookList()
    });
  }

  formatAtomicRB(data) {
    let scope;
    try {
      scope = JSON.parse(data.fields.RunStepOptions);
    } catch (e) {
      scope = {};
    }

    return {
      run_options: {
        scope_details: {
          ...scope, //input and output different
          module_type: { default: data.fields.Category, hideToUser: true },
          module_name: {
            default: data.fields["Module Source Path"],
            hideToUser: true,
          },
          module_title: { default: data.fields["Title"], hideToUser: true },
          module_description: {
            default: data.fields["Description"],
            hideToUser: true,
          },
        },
        // module_name: data.fieds["Module Source Path"],
      },

      consumes: data.fields.Consumes,
      archive: false,
      ///created_by_email: "snandecha@firecompass.com"
      ///created_by_id: "QQQ6s1Apbl1TYuF8lYs7"
      ///created_by_name: null
      id: data.id,
      /// industry: ["All"]
      ///zoom: 1,
      status: data.fields.Status ? data.fields.Status.toLowerCase() : null,
      //updated_at: "2022-02-09T05:57:43.588548Z"
      version: "V1",
      primary_domain: "firecompass.com",
      config: {
        indexing: {
          merge_results: {
            title: "Merge Final Result",
            value: true,
            display: true,
            editable: false,
          },
          save_raw_data: {
            title: "Save RAW Data",
            value: false,
            display: false,
            editable: false,
          },
          intermediate_indexing: {
            title: "Index Intermediate Results",
            value: false,
            display: false,
            editable: false,
          },
        },
        reporting: {
          generate_pdf: {
            title: "Generate PDF Report",
            value: false,
            display: false,
            editable: false,
          },
        },
      },

      key: "atomic_" + Math.random().toString(36).substring(7),
      type: "atomic",
      name: data.fields.Title || data.fields.Name,
      description: data.fields.Description,
      category: data.fields.Category,
      tags: data.fields.Tags,
      created_at: data.fields["Creation Date"],
      // selected: false,
      fields: data.fields,
    };
  }
  async getMolecularRunBook() {
    const params = {
      searchKey: this.searchKey,
      pageSize: 100,
    };
    var molecularRunBook = [];
    let molecualrRunbookResponse = await this.airTableService.getMolecularBookDetails(
      params
    );
    return molecualrRunbookResponse.toPromise();
  }

  goToPage(val) {
    console.log(val, this.showSpinnerPagination);
    if (!this.showSpinnerPagination) {
      this.showSpinnerPagination = true;
      this.page = val;
      if (this.searchValue) {
        if (this.offset) {
          this.getAtomicCategoryDetails();
        } else {
          this.showSpinnerPagination = false;
        }
      } else {
        this.getAtomicRunbookPageDetails();
      }
    }
  }

  setAllData() {
    this.getAllRunbookDetails();
  }

  getMolecularRunBookSearchDetails() {
    this.runBookListToShow = [];
    this.showSpinnerPagination = true;
    const params = {
      searchKey: this.searchKey,
      pageSize: 100,
    };
    if (this.selectedIndustries && this.selectedIndustries.length)
      params["industry"] = this.selectedIndustries
        .map((a) => '"' + encodeURIComponent(a["industry_name"]) + '"')
        .join(",");
    console.log(params["industry"]);
    this.airTableService
      .getMolecularBookDetails(params)
      .subscribe((data: any) => {
        if (data) {
          this.runBookListToShow = data["results"];
          this.showSpinnerPagination = false;
        }
      });
    // this.showSpinnerPagination = false
  }
  getAtomicRunbookPageDetails() {
    let search = this.searchKey
    if (search) {
      let spChars = "/[!@#$%^&*()_+\-=\[\]{};':\\|,.<>\/?]+/;\""
      let length = search.length
      for (var i = 0; i < length; i++) {
        if (spChars.indexOf(search.charAt(i)) != -1) {
           search = search.replace(search.charAt(i),"\\"+search.charAt(i))
           i = i+1;
           length = length+1;
        }
     }
    }
    this.showSpinnerPagination = true;
    var atomicRunbook = [];
    const params = {
      offset: this.offset,
      page: this.page,
      searchKey: search,
    };
    this.airTableService.getAtomicRunBookDetails(params).subscribe(
      (data: any) => {
        if (data) {
          this.offset = data["offset"];
          const atomicList = data["records"];
          atomicList.forEach((data) => {
            var jsonData = this.formatAtomicRB(data);
            atomicRunbook.push(jsonData);
          });
          this.rbToNgxGraphFormat.convertElementsToNodesAndEdges(atomicRunbook);
          this.runBookListToShow = [
            ...this.runBookListToShow,
            ...atomicRunbook,
          ];
          this.showSpinnerPagination = false;
        }
      },
      (error) => {
        // this.showSpinner = false;
      }
    );
  }

  setMolecularRunbookList() {
    this.runBookListToShow = Object.assign([], this.molecularRunBookToShow);
    this.showSpinnerPagination = false;
  }

  setAtomicRunbookList() {
    this.page = 1;
    if (this.showThis == "all" || this.showThis == "atomic_assessment") {
      this.runBookListToShow = Object.assign([], this.atomicRunbookToShow);
      this.showSpinnerPagination = false;
    }
  }

  getAtomicRunbookDetails() {
    let search = this.searchKey
    if (search) {
      let spChars = "/[!@#$%^&*()_+\-=\[\]{};':\\|,.<>\/?]+/;\""
      let length = search.length
      for (var i = 0; i < length; i++) {
        if (spChars.indexOf(search.charAt(i)) != -1) {
           search = search.replace(search.charAt(i),"\\"+search.charAt(i))
           i = i+1;
           length = length+1;
        }
      }
     }
    const params = {
      offset: this.offset,
      page: this.page,
      searchKey: search,
    };
    return this.airTableService.getAtomicRunBookDetails(params).toPromise();
  }

  getAtomicCategoriesCount() {
    if (this.callAtomicCount) {
      this.airTableService
        .getAtomicCategoriesDetails()
        .subscribe((data: any) => {
          if (data) {
            this.categories = JSON.parse(
              data["records"][0]["fields"]["Details"]
            );
            this.keys = Object.keys(this.categories);
            this.categoriesLength = this.keys.length;
            this.showSpinner = false;
          }
        });
    }
    this.setAtomicRunbookList();
    this.callAtomicCount = false;
  }

  categoryChange(key) {
    this.searchValue = key;
    this.offset = null;
    this.runBookListToShow = [];
    this.atomicRunbookToShow = [];
    this.getAtomicCategoryDetails();
  }

  clearRunBooks() {
    // if (this.showThis == "all") {
    //   this.searchKey = null;
    //   this.getAllRunbookDetails();
    // } else if (this.showThis == "atomic_assessment") {
    //   this.searchKey = null;
    //   this.runBookListToShow = [];
    //   this.getAtomicRunbookPageDetails();
    // } else {
    //   this.searchKey = null;
    //   this.runBookListToShow = [];
    //   this.setMolecularRunbookList();
    // }
    this.searchKey = null;
    this.getAllRunbookDetails();
    this.getListOfIndusties();
  }

  getAtomicCategoryDetails() {
    this.showSpinnerPagination = true;
    var atomicRunbook = [];
    this.searchKey = null;
    const params = {
      offset: this.offset,
      page: this.page,
      searchField: this.searchCategory,
      searchParam: this.searchValue,
    };
    this.airTableService.getAtomicCategoryDetails(params).subscribe(
      (data: any) => {
        if (
          data &&
          (this.showThis == "all" || this.showThis == "atomic_assessment")
        ) {
          const atomicList = data["records"];
          this.offset = data["offset"];
          atomicList.forEach((data) => {
            var jsonData = this.formatAtomicRB(data);
            atomicRunbook.push(jsonData);
          });
          this.rbToNgxGraphFormat.convertElementsToNodesAndEdges(atomicRunbook);
          this.atomicRunbookToShow = atomicRunbook;
          this.runBookListToShow = [
            ...this.runBookListToShow,
            ...atomicRunbook,
          ];
        }
        this.showSpinnerPagination = false;
      },
      (error) => {
        alert("Error while fetching data. Please try again later");
        this.showSpinnerPagination = false;
      }
    );
  }

  runbookChanged(selectedRunbook) {
    this.selectedRB = {};
    this.runBookListToShow.forEach((runbook) => {
      if (runbook.id == selectedRunbook.id) {
        this.selectedRB = runbook;
        this.newAssessment.emit(this.selectedRB);
      } else runbook["selected"] = false;
    });
  }
  openFlow(template: TemplateRef<any>, runbook) {
    this.runbookToView = runbook;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      class: "min-width-dialog-modal",
    });
  }
  openDescriptionModal(template: TemplateRef<any>, desc) {
    this.descriptionToShow = desc;
    unified()
      .use(remarkParse)
      .use(remarkGfm)
      .use(remarkRehype)
      .use(remarkStringify)
      .process(desc)
      .then((data) => {
        this.descriptionToShow = data.value;
      });
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      class: "min-width-dialog-modal",
    });
  }
  getListOfIndusties() {
    this.airTableService.industryListAndCount().subscribe((data: any) => {
      if (data) {
        this.industries = data;
      }
    });
  }
  filterByIndustries(clear = false) {
    if (clear) {
      this.selectedIndustries = [];
      this.industries.forEach((a) => (a.selected = false));
    } else {
      this.selectedIndustries = this.industries.filter((ind) => ind.selected);
    }
    this.getMolecularRunBookSearchDetails();
  }
}
