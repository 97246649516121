import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { NewviewsComponent } from "./newviews.component";
import { DashboardComponent } from "./vendor-dashboard/dashboard.component";
import { ExternalScoreComponent } from "./external-score/external-score.component";
import { FootprintComponent } from "./footprint/footprint.component";
import { ManageSelfDomainComponent } from "./external-score/manage-self-domain.component";
import { ManageVendorDomainsComponent } from "./vendor-dashboard/manage-vendor-domains.component";
import { IpDetailsComponent } from "./footprint/ip-details.component";
import { QuestionnaireComponent } from "./vendor-assessment/questionnaire.component";
import { VendorQuestionComponent } from "./vendor-assessment/vendor-question.component";
import { VendorResponseComponent } from "./vendor-response/vendor-response.component";
import { ProjectDetailsComponent } from "./vendor-assessment/project-details.component";
import { RoleGuard } from "../_guards/role-guard.service";
import { AuthGuardService } from "../_guards/auth-guard.service";
import { LabsComponent } from "./labs/labs.component";
import { ActiveMonitoringDashboardComponent } from "./active-scan/active-monitoring-dashboard.component";
import { RedTeamingComponent } from "./red-teaming/red-teaming.component";
import { RemediationComponent } from "./remediation/remediation.component";
import { ExecutiveReportComponent } from "./executive-report/executive-report.component";
import { NewDomainDiscoveryComponent } from "./new-domain-discovery/new-domain-discovery.component";
import { KeywordDataLeaksComponent } from "./labs/keyword-data-leaks/keyword-data-leaks.component";
import { ReportsComponent } from "./reports/reports.component";
import { RunAttacksComponent } from "./run-attacks/run-attacks.component";
import {
  customClaims,
  redirectUnauthorizedTo,
  canActivate,
  AngularFireAuthGuard,
} from "@angular/fire/auth-guard";
import { pipe } from "rxjs";
import { map } from "rxjs/operators";
import { CustomersComponent } from "./customers/customers.component";
import { LabsV2Component } from "./labs-v2/labs-v2.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { SettingsComponent } from "./settings/settings.component";
import { VulnerabilitiesComponent } from "./labs/vulnerabilities/vulnerabilities.component";
import { NewDashboardComponent } from "app/views/dashboard/dashboard_v2/new-dashboard.component";
import { SearchComponent } from "./footprint/search/search.component";
const serviceProviderOnly = () =>
  pipe(
    customClaims,
    map((claims) => claims.account_type === "service_provider")
  );
const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(["pages/login"]);

const routes: Routes = [
  {
    path: "",
    data: {
      title: "",
    },
    children: [
      {
        path: "vulnerabilities",
        component: VulnerabilitiesComponent,
        data: {
          title: "Vulnerabilities",
        },
      },
      {
        path: "digital-risk",
        component: LabsComponent,
        data: {
          title: "My Org / Other Risk",
        },
      },
      {
        path: "search",
        component: SearchComponent,
        data: {
          title: " Search",
        },
      },
      {
        path: "active-monitoring",
        component: ActiveMonitoringDashboardComponent,
        data: {
          title: "Risk",
        },
      },
      {
        path: "run-attacks",
        component: RunAttacksComponent,
        data: {
          title: "Labs / Run Attacks",
        },
      },
      // {
      // 	path: 'red-teaming',
      // 	component: RedTeamingComponent,
      // 	data: {
      // 		title: 'Red Teaming'
      // 	}
      // },

      {
        path: "show-external-score",
        canActivate: [RoleGuard],
        component: ExternalScoreComponent,
        data: {
          title: " Dashboard",
        },
      },
      {
        path: "vendor-dashboard",
        canActivate: [RoleGuard],
        component: DashboardComponent,
        data: {
          title: "Labs / Vendor Risk",
        },
      },
      {
        path: "footprint",
        canActivate: [RoleGuard],
        component: FootprintComponent,
        data: {
          title: " Attack Surface",
        },
      },
      {
        path: "self-domains",
        canActivate: [AuthGuardService],
        component: ManageSelfDomainComponent,
        data: {
          title: "Manage",
        },
      },
      {
        path: "vendor-domains",
        ...canActivate(serviceProviderOnly),
        component: ManageVendorDomainsComponent,
      },
      {
        path: "ip-details",
        component: IpDetailsComponent,
        data: {
          title: "",
        },
      },
      {
        path: "questionnaire",
        component: QuestionnaireComponent,
        data: {
          title: "",
        },
      },
      {
        path: "vendor-question",
        component: VendorQuestionComponent,
        data: {
          title: "",
        },
      },
      {
        path: "vendor-response",
        component: VendorResponseComponent,
        data: {
          title: "",
        },
      },
      {
        path: "project-responce-details/:vendor_project_id",
        component: ProjectDetailsComponent,
        data: {
          title: "",
        },
      },
      {
        path: "digital-risk",
        component: LabsComponent,
        data: {
          title: "Risk",
        },
      },
      {
        path: "active-monitoring",
        component: ActiveMonitoringDashboardComponent,
        data: {
          title: "Risk",
        },
      },
      {
        path: "remediation-center",
        component: RemediationComponent,
        data: {
          title: "Labs / Remediation",
        },
      },

      {
        path: "data-leaks",
        component: KeywordDataLeaksComponent,
        data: {
          title: "Data Leaks",
        },
      },

      {
        path: "discover-domain",
        component: NewDomainDiscoveryComponent,
        data: {
          title: "Configure",
        },
      },
      {
        path: "reports",
        component: ReportsComponent,
        data: {
          title: "Reports",
        },
      },
      {
        path: "labs/risks",
        component: LabsV2Component,
        data: {
          title: "Beta / Other Risks",
        },
      },
      {
        path: "alerts",
        component: AlertsComponent,
        data: {
          title: "Alerts",
        },
      },
    ],
  },
  {
    path: "discover-domain",
    component: NewDomainDiscoveryComponent,
    data: {
      title: "Configure",
    },
  },
  {
    path: "reports",
    component: ReportsComponent,
    data: {
      title: "Reports",
    },
  },
  {
    path: "settings",
    component: SettingsComponent,
    data: {
      title: "Settings",
    },
  },
  {
    path: "customers",
    canActivate: [AngularFireAuthGuard],
    component: CustomersComponent,
    data: {
      title: "Customers",
      authGuardPipe: serviceProviderOnly,
    },
  },
  {
    path: "dashboard",
    component: NewDashboardComponent,
    data: {
      title: "Labs / Dashboard 2.0 ",
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewviewRoutingModule {}
