import { Component, OnInit } from '@angular/core';
import {Router, Route, ActivatedRoute} from "@angular/router";
// import { ModalDirective } from 'ngx-bootstrap/modal/modal.component';
import { PortfolioSurveyService } from './../../_services/portfolio-survey.service';
// import { jsonpFactory } from '@angular/http/src/http_module';

@Component({
  selector: 'app-vendor-response',
  templateUrl: './vendor-response.component.html',
  styleUrls: ['./vendor-response.component.scss']
})
export class VendorResponseComponent implements OnInit {
  public projectDetails=[]
  public predfineQuestions=[]
  public customiseQuestion=[]
  public vendorProjects=[]
  public displayPredifine=-1;
  public displayCustom=-1;
  public vendorResponse={}
  public saveQuestionSetDetails={}
  public showQuestionTab:boolean = false;
  public singelePredefine=[]
  public preDefineResponseData=[]
  public customResponseData=[]
  public updateResponse:boolean = false
  public statusPost:boolean=false;
  public vendorProjectDetails;
  constructor(
    private portfolioSurveyService: PortfolioSurveyService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
  }

  ngOnInit() {
    this.portfolioSurveyService.getVendorDetailsByEmail().subscribe((data:any) => {
    this.projectDetails  = data.projectsDetails;
    this.vendorProjects = data.vendorsProjects;
    console.log("this.vendorProjects...1...",this.vendorProjects)
    })
  }
  getDate(dataTime) {
    return dataTime.split('T')[0];  
  }
  getTemplate(queSetId){
    var index = this.projectDetails.findIndex(abc => abc.question_set_id == queSetId)
   
      return this.projectDetails[index]['template_type']
  }

  getDueDate(queSetId){
    var index = this.projectDetails.findIndex(abc => abc.question_set_id == queSetId)
   
      var dueDate=this.projectDetails[index]['due_date']
    return dueDate.split('T')[0];  

  }
  getProjectDetails(details, target){
    this.vendorProjectDetails=details
    var index = this.projectDetails.findIndex(abc => abc.question_set_id == details.question_set_id)
    this.saveQuestionSetDetails= this.projectDetails[index]
      this.predfineQuestions= details.predefined_questions_details;
      this.customiseQuestion=details.custom_questions_details;
      this.showQuestionTab=true;
    target.scrollIntoView();
  }
  gotoUp(uptarget){
    uptarget.scrollIntoView();
  }
  getPreQueDetails(index,singelePredefine){
    this.displayPredifine=index
  }
  getCustomQueDetails(index){
    this.displayCustom=index

  }
  getOptions(opt) {
    return JSON.parse(opt);
  }

 
}

