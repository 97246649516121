import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ThirdPartyDashboardComponent } from './third-party-dashboard.component';
import { PipeModule } from 'app/pipes/pipe.module';
import { DashboardTimelineComponent } from './dashboard-timeline.component';
import { AvatarModule } from 'ngx-avatar';
import { NewDashboardComponent } from './dashboard_v2/new-dashboard.component';
import { InsightsComponent } from './dashboard_v2/insights/insights.component';
import { LoaderComponent } from 'app/shared/loader/loader.component';
import { ExternalScoreComponent } from 'app/newviews/external-score/external-score.component';
import { DashboardV3Component } from './dashboard-v3/dashboard-v3.component';
import { NewPaginationComponent } from '../../shared/newPagination.component';
import { AttackSurfaceComponent } from './dashboard-v3/attack-surface/attack-surface.component';

@NgModule({
  imports: [
    DashboardRoutingModule,
    ChartsModule,
    BsDropdownModule,
    CommonModule,
    PipeModule,
    AvatarModule
  ],
  declarations: [
    DashboardComponent, 
    ThirdPartyDashboardComponent, 
    DashboardTimelineComponent, 
    NewDashboardComponent, 
    InsightsComponent, 
    LoaderComponent, 
    ExternalScoreComponent, 
    DashboardV3Component,
    NewPaginationComponent,
    AttackSurfaceComponent
  ]
})
export class DashboardModule { }
