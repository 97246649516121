import { Component, OnInit, Input, SimpleChanges, TemplateRef } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from 'app/_services';
import { throwServerError } from '@apollo/client/core';

@Component({
	selector: 'app-s3-buckets',
	templateUrl: './s3-buckets.component.html',
	styleUrls: ['./s3-buckets.component.scss']
})
export class S3BucketsComponent implements OnInit {

	@Input() mainDomain: any;
	@Input() isUserTrial = false;
	@Input() buckets: any;
	@Input() showExecutiveReport;
	@Input() bucketPercentage
	@Input() buckets_count 
	@Input() showSpinner = false
	@Input() csvDetails
	@Input() filters = {};
	@Input() filesData;
	public seeMoreBucket = 50;
	public seeMoreFile = 50;
	public openThis: number = -1;
	public sortKey = null;
	public order = 1;
	public initialSort: boolean = false;
	public keysToSearch = ['bucket_name'];
	public showDowloadText: boolean = false;
	public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
	public fileCountLoader = false;
	public bucketFileCount = 0;
	// public delReason: string = null;
	public fieldsToDownload = {
		'Entity':'subject_name',
		'Bucket URL':'bucket_name',
		'Files':'files',
		'Created At': 'created_at',
		'Updated At': 'updated_at',
	}
	public page = 1;
	public limit = 10;
	public pageFile = 1;
    public limitFile = 10;
	public searchKey = null;
	public params= {};
	public paramsFile = {};
	public bucketSelected = []
	public bucketFiles;
	public delBucketData = null;
	public sortWithKey=null;
	constructor(
		private commonService: CommonService, private portfolioSurveyService: PortfolioSurveyService,
		private modalService: BsModalService,
		private modalRef: BsModalRef
	) { }

	ngOnInit() {
	}

	getFilesCount(bucket_data){
		var bucketNames = []
		var buckeNameStr = null;
		bucket_data.forEach(element => {
		  bucketNames.push(element['bucket_name'])
		  
		});
		buckeNameStr = bucketNames.join()
		this.portfolioSurveyService.getFileCount(this.mainDomain['domain_name'],buckeNameStr).subscribe((data) => {
		  if(data){
			this.filesData=data['data']
			this.fileCountLoader=false
	
		  }
		})
	  }

	public stateOfBucket(state) {
		switch (true) {
			case (state == 'open'): return 'text-danger';
			case (state == 'resolved'): return 'text-success';
			case (state == 'inprogress'): return 'text-warning';

			default: return 'text-primary';
		}
	}
	displayTags(bucket) {
		if (bucket.tags && bucket.tags.length > 0) {
			return true
		} else if (bucket.takeover_risk && (bucket.takeover_risk == 'high' || bucket.takeover_risk >= 8)) {
			return true
		} else {
			return false
		}
	}

	convertTag2StandardName(tag) {
		if (tag && tag == 'PUBLICALLY_OPEN') {
			return 'OPEN';
		} else {
			return tag
		}
	}
	openRemoveOptions(template: TemplateRef<any>, cloud_bucket) {
		cloud_bucket['selected'] = true;
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}

	delBucket(reason) {
		this.delBucketData = this.buckets.filter(function(bucket){
			return bucket.selected==true;

		})[0]
		// this.buckets.map(ph => {
		// 	console.log("ph--",ph)
		// 	if (ph.selected == true) {
		// 		console.log("phhhhh",ph.selected)
		// 		ph.updated = true;
		// 		ph.archive = true;
		// 		ph.archive_reason = reason;
		// 		delete ph['selected']
		// 		// this.delReason = null;
		// 	}
		// })

		this.updateBuckets();
	}

	public updateBuckets() {
		this.portfolioSurveyService.updateCloudBuckets(this.delBucketData, this.mainDomain['domain_name']).subscribe(data => {
			this.modalRef.hide();
			if(data['status']=="Pass"){
				alert("Enitity deleted successfully!")
				this.page=1
				this.getBucketsForDomains()
			}
			else{
				alert("Enitity deleted Failure!")
			}

		}, err => {
			this.modalRef.hide();
		});
	}
	delReason(reason) {
		this.delBucket(reason)

	}
	cancelDel(a) {
		this.buckets.map(content => {
		   content.selected = false})
	 }
	 getBucketsForDomains() {
		this.showSpinner = true
		this.buckets=[]
		this.openThis = -1
		 this.params = {
			 page : this.page,
			 limit : this. limit, 
			 searchKey :this.searchKey,
			 ...this.filters
		 }
		 if (this.sortWithKey) this.params['sortKey'] = this.order == -1 ? this.sortWithKey : '-' + this.sortWithKey
		this.portfolioSurveyService.getCloudBuckets(this.mainDomain['domain_name'],this.params).subscribe((data) => {
		  if (data) {
			this.buckets = data["results"];
			this.buckets_count = data["count"];
			this.showSpinner = false
			this.getFilesCount(this.buckets)
		  }
		},(error)=>{
			this.showSpinner = false

		});
	  }
	  goToPage(val) {
        this.page = val;
    	this.getBucketsForDomains()
      }
      searchAllBucketDetail() {
        this.searchKey = null
        this.getBucketsForDomains()
      }
      searchBucketDetail(domain) {
        this.page = 1;
        this.getBucketsForDomains()
	  }
	  getFileDetails(bucket,fileCount){
		  this.bucketFiles = null
		  this.bucketSelected = bucket
			this.paramsFile = {
			page : this.pageFile,
			limit : this. limitFile, 
		}
		if(fileCount>0){
			this.bucketFileCount=fileCount
		this.portfolioSurveyService.getFilesForBuckets(this.mainDomain['domain_name'],this.bucketSelected['bucket_name'], this.paramsFile).subscribe(data=>{
			this.bucketFileCount = data['data']['count']	
			this.bucketFiles = data
		})
	}
	else{
		this.bucketFileCount=0
	}
	  }
	  goToPageFile(val) {
        this.pageFile = val;
    	this.getFileDetails(this.bucketSelected,this.bucketFileCount)
      }

}
