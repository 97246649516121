import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewChild, SimpleChange } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { PortfolioSurveyService } from 'app/_services';
import { RolesService } from 'app/_services/roles.service';
import { ToastrService } from 'ngx-toastr';
import { DownloadButtonComponent } from 'app/shared/download.component';

@Component({
	selector: 'app-application-vulnerabilty',
	templateUrl: './application-vulnerabilty.component.html',
	styleUrls: ['./application-vulnerabilty.component.scss']
})
export class ApplicationVulnerabiltyComponent implements OnInit {

	@Input() mainDomain: any;
	@Input() app_type: string = "app_vulnerabilities";
	public vulnerabilties = [];
	@Input() type
	@Input() showExecutiveReport;
	@Input() isUserTrial: boolean = false;
	@Input() filters;
	public totalCount = 0;
	@Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
	@Input() webVulnerabitiesLoader;
	@Input() mobileVulnerabitiesLoader=true;
	@ViewChild("webappComponent") webappComponent;
	@ViewChild("filterBlock") filterBlock;
	@ViewChild("getAllWebVulnerabilitiesButton") getAllWebVulnerabilitiesButton: DownloadButtonComponent;
	@Input() selectedScope = '';
	public subjectType = 'web_app_cve';
	public allObsIns;
	public showSpinner: boolean = false;
	public selectAllRecord = false;
  	public noOfSelectedRecord = 0;
	public selectedCve:any = [];
	public infoFlag: any = false
	public addNewTopRisk: Object = {};
	public entityType=null;
	public viewType:string = 'cve-view';
	public webCveSubjectObsIns;
	public order: number = 1;
	public sortWith = 'cve_id';
	public initialSort: boolean = false;
	public vulIndex = null;
	public show_max_list: number = 3;
	public showItem_i;
	public showItem_j;
	public seeMore = 50;
	public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
	// public delReason = null;
	public vulnerabilityData = {};
	public detailVul={};
	public show_max_location = 3;
	public allVulnerabilites = []
	public obsInsCountSpinner = false;
	public vulDetailForCve;
	public cveIdArray = []
	public fieldsToDownload = {
		'Title': 'cve_id' ,
		'Description' : 'cve_summary' ,
		'Severity' : 'severity' ,
		'Apps (Validated)': 'applications.source_url',
		// 'Impact' : 'impact',
		'Remediation' : 'remediation',
		'References' : 'references',
		'Discovered At' : 'applications.discovered_at',
		'Last Seen At' : 'applications.applicationcve_updated_at',
		'Observations' : 'observation',
		'Insights' : 'insight'
	}
	stateCommentTitle = {
		'open':'',
		'resolved':'Comment',
		'ready_to_retest':'Comment',
		'closed':'Comment'
	}
	public page=1;
	public limit=10;
	// public webVulnerabitiesLoader = false;
	public totalWebCount = 0;
	public topRiskLoader=false;
	public allWebVulnerbities = [];
	public searchKey = null;
	public openDropdown;
	public showThis = 'stateFilter';
	public stateFinalFilter:any = ['open','ready_to_retest'];
	public filter_states = [
		{ selected:true, key: 'open', value: 'Open' },
		{ selected:true, key: 'ready_to_retest', value: 'Ready To Retest' },
		{ selected:false, key: 'closed', value: 'Closed' },
		{ selected:false, key: 'resolved', value: 'Resolved' },
		{ selected:false, key: 'retest_in_progress', value: 'Retest In Progress' }
	]
	public severitiesFinalFilter = [];
	public filter_severities =
	[
		{ selected:false, key: 'critical', value: 'Critical'},
		{ selected:false, key: 'high', value: 'High'},
		{ selected:false, key: 'medium', value: 'Medium' },
		{ selected:false, key: 'low', value: 'Low' },
	]
	public validatedFinalFilter = [true,false];
	public validated_flags = [
		{ selected: true, key: 'Validated', value: true },
		{ selected: true, key: 'Not Validated', value: false },
	];
	public openThis;
	public pocSubjectComment;
	public filterApplied = true;
	public openActionDropdown = false;
	public retestParam:any = {
		"web_apps": {
			"application_cve_ids": [],
			"cve_ids": [],
			"application_urls": []
		}
	};
	public allFilterList:any = ['State', 'Validate'];
	public applyRetestConfirmModal:any = null;
	public downloadLoading = false;
	newRequest: any;
	newRequesttwo: any;
	public lastParam:any = null;
	public impactServ:any = [];
	public editImpact = false;
	constructor(public commonService: CommonService, public portfolioSurveyService: PortfolioSurveyService,
		public rolesService: RolesService,
		public modalService: BsModalService,
		public modalRef: BsModalRef,
		public modalRef2: BsModalRef,
		public toastr:ToastrService
	) { }

	ngOnInit() {
		// this.getAllWebVulnerability();
		// this.getWebVulnerability();
		this.setScopeStatus();
		this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
			this.getWebVulnerability()
		});
	}
	async setScopeStatus() {
		await this.commonService.delay(2000);
		this.infoFlag = localStorage.getItem('info_vul_config')
		if (this.infoFlag === 'true') {
		this.filter_severities =[
		{ selected:false, key: 'critical', value: 'Critical' },
		{ selected:false, key: 'high', value: 'High' },
		{ selected:false, key: 'medium', value: 'Medium' },
		{ selected:false, key: 'low', value: 'Low' },
		{ selected:false, key: 'info', value: 'Info' },
	]
		}
	};

	ngOnChanges(changes:SimpleChange) {
		if(this.selectedScope){
			if(!this.allFilterList.includes('Scope')) this.allFilterList.push('Scope');
		}else{
			if(this.filterBlock){
				this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Scope');
			}
		}
		if (this.vulnerabilties && this.vulnerabilties.length) {
			this.vulnerabilties.forEach(serVul => {
				let severity = this.commonService.confidenceLevel(serVul.severity)
				this.allVulnerabilites.push({ ...serVul, ...{severity : severity } })
				this.totalWebCount = this.vulnerabilties[0]['total']
				this.webVulnerabitiesLoader=false
				this.mobileVulnerabitiesLoader=false
						
			})
			this.formatTags(this.vulnerabilties)
		}else{
			this.totalWebCount = 0
		}
		if(changes['mainDomain'] || changes['filters'] || changes['selectedScope'] || changes['webVulnerabitiesLoader']){
			this.getWebVulnerability();
		}else if(changes['totalCount']){
			this.filterCounter(this.mainDomain);
		}
	}

	formatTags(vul_data){
		vul_data.forEach((entry) => {
			let vulTags = []
			entry['applications'].forEach((tagsEntry) =>{
				if(tagsEntry['tags']){
					vulTags = [...vulTags, ...tagsEntry['tags']]
				}

			});
			entry['tags_status'] = this.checkTopRiskStatus(vulTags)
		});
	}


	checkTopRiskStatus(tagsData){
		return tagsData.includes("top_risk")

	}

	goToPage(val){
		this.page = val
		this.getWebVulnerability()
	}

	getAllWebVulnerability() {
		this.newRequest ? this.newRequest.unsubscribe(): null;
		this.allWebVulnerbities = [];
		this.downloadLoading = true;
		var csvData = [];
		var params = {
			"page":this.page,
			"limit":null,
		  }
		// if(this.selectedScope) this.fieldsToDownload['Scope'] = "applications.scope";
		this.newRequest=this.portfolioSurveyService.getWebAppVulnerability(this.mainDomain, params).subscribe(vul => {
			csvData = vul['data'];
			this.showSpinner = true
			this.portfolioSurveyService.getAllObsAndIns(this.mainDomain, this.subjectType).subscribe((data) => {
				this.allObsIns = data['data']
				this.showSpinner = false
				if (csvData && csvData.length) {
					csvData.forEach(serVul => {
						let severity = this.commonService.confidenceLevel(serVul.severity);
						serVul=this.commonService.parseValidate(serVul);
						var applications_data = serVul.applications;
						var len_applications_data = applications_data.length
						serVul.obs_arr = {}
						serVul.obs_arr[serVul.cve_id] = {}
						serVul.ins_arr = {}
						serVul.ins_arr[serVul.cve_id] = {}
						if (len_applications_data) {
							applications_data.forEach(app_data => {
								app_data.discovered_at = (app_data.discovered_at) ? new Date(app_data.discovered_at).toLocaleString() : app_data.discovered_at;
								app_data.applicationcve_updated_at = (app_data.applicationcve_updated_at) ? new Date(app_data.applicationcve_updated_at).toLocaleString() : app_data.applicationcve_updated_at;
								serVul.obs_arr[serVul.cve_id][app_data.source_url]=[]
								serVul.ins_arr[serVul.cve_id][app_data.source_url]=[]
								this.allObsIns.forEach(obsIns => {
									app_data.applicationcve_id.forEach(appcve_id => {
										if (obsIns.subject_id == appcve_id) {
											serVul.obs_arr[serVul.cve_id][app_data.source_url].push(obsIns.observation)
											serVul.ins_arr[serVul.cve_id][app_data.source_url].push(obsIns.insight)
										}

									})
								})
							})
						}
						var observations_data = serVul.obs_arr[serVul.cve_id]
						var insights_data = serVul.ins_arr[serVul.cve_id]
						for (let key in observations_data ) {
						  if (!observations_data[key].length) {
							delete observations_data[key]
						  }
						}
						for (let key in insights_data) {
						  if (!insights_data[key].length) {
							delete insights_data[key]
						  }
						}
						if (Object.keys(observations_data).length === 0) {observations_data  = ''}
						if (Object.keys(insights_data).length === 0) {insights_data = ''}
						this.allWebVulnerbities.push({ ...serVul, ...{ severity: severity }, ...{ observation: observations_data}, ...{ insight: insights_data}})
					})
					this.getAllWebVulnerabilitiesButton.download();
					this.allWebVulnerbities = [];
			}
			}, error => {
				this.showSpinner = false;
			})
			this.downloadLoading = false;
		})
	}

	clearSearch() {
		this.searchKey = null;
		this.page = 1
		this.getWebVulnerability()
	}

	searchVulnerability(){
	  this.page=1
	  this.getWebVulnerability();
	  
	}

	getWebVulnerability() {
		if(!this.mainDomain) return;
		this.newRequesttwo ? this.newRequesttwo.unsubscribe(): null;
		this.webVulnerabitiesLoader = true
		this.vulnerabilties=[]
		this.cveIdArray = []
		var params = {
		  "page":this.page,
		  "limit":10,
		  searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
		  sortKey: this.sortWith ? this.order == -1 ? '-' + this.sortWith : this.sortWith : null,
		  ...this.filters
		}
		let stateString = '';
		if(this.stateFinalFilter.length){
			stateString = this.joinState(this.stateFinalFilter);			
		}else{
			let allFilter:any = [];
			this.filter_states.map(x=>allFilter.push(x.key));
			stateString = this.joinState(allFilter);
		}
		if(stateString){
			params['state'] = stateString;
		}
		let severityString = this.joinState(this.severitiesFinalFilter);
		if(severityString){
			params['severities'] = severityString;
		}
		if(this.selectedScope){
			params['scope'] = this.selectedScope;
		}
		let validatedString = this.joinState(this.validatedFinalFilter);
		if(validatedString){
			params['validated_flags'] = validatedString;
		}
		this.filterApplied = true;
		this.newRequesttwo=this.portfolioSurveyService.getWebAppVulnerability(this.mainDomain,params).subscribe(vul => {
		  this.newRequesttwo = null;
		  let profile: any = localStorage.getItem('profile');
		  let parsedData = JSON.parse(profile);
		  let account_type = parsedData.account_type;
		  this.vulnerabilties = vul['data'];
		  if(this.vulIndex !== null){
			this.detailVul['applications'] = this.vulnerabilties[this.vulIndex]['applications']
		}
		  this.totalWebCount = vul['count']
		  this.formatTags(this.vulnerabilties)
		  this.webVulnerabitiesLoader = false
		  this.vulnerabilties.forEach(web_vul => {
			  let web_apps = web_vul.applications
			  web_apps.forEach(app_detail => {	
				app_detail.account_type = account_type
				app_detail.subjectTypeName = {
					"subject_type": this.subjectType,
					"subject_name": app_detail.source_url,
					"subject_id": app_detail.applicationcve_id[0],		  
				  }
					this.cveIdArray.push(app_detail.applicationcve_id[0])
					})
			  })
		  if (this.cveIdArray.length) {
			this.getObsInsCountsForEachCVE(this.cveIdArray)
			}	
		})
		this.filterCounter(this.mainDomain);
	  }
	openRemoveOptions(template: TemplateRef<any>, application) {
		application['applications'].forEach(element => {
			if (this.vulnerabilityData['ids']) {
				this.vulnerabilityData['ids'].push(...element['applicationcve_id'])
			} else {
				this.vulnerabilityData['ids'] = element['applicationcve_id']
			}
		});
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	openModalRemoveEachAssest(template: TemplateRef<any>, app) {
		this.modalRef.hide()
		this.vulnerabilityData['ids'] = app['applicationcve_id']
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	delApplicationVul(reason) {
		this.vulnerabilityData['archive_reason'] = reason;
		this.deletewebapplicationvulnerability();
		// this.delReason = null;
	}

	public deletewebapplicationvulnerability() {
		this.portfolioSurveyService.deletewebapplicationvulnerability(this.vulnerabilityData, this.mainDomain).subscribe(data => {
			this.modalRef.hide();
			alert("Vulnerability deleted successfully!")
			this.vulnerabilityData = {};
			this.getWebVulnerability();
			this.refreshCount.emit('webapp');
		}, err => {
			this.modalRef.hide();
			this.vulnerabilityData = {};
		});
	}

	dataToRemediate(data) {
		this.vulnerabilityData['archive_reason'] = data['archive_reason'];
		this.vulnerabilityData['comment'] = data['comment']
		this.deletewebapplicationvulnerability();
	}

	closeModel(value) {
		if (value) {
			this.modalRef.hide();
			this.vulnerabilityData = {};
		}
	}
	delReason(reason) {
		this.delApplicationVul(reason)
	}
	openDetailVul(template: TemplateRef<any>, service, index) {
		this.vulIndex = index;
		this.detailVul=service;
		this.detailVul['applications'].map((x)=>{x.locationlimit=5})
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	// severityOfRiskLevel(riskLevel) {
	// 	switch (true) {
	// 		case (riskLevel == 'Critical'): return 'badge-danger';
	// 		case (riskLevel == 'High'): return 'badge-danger';
	// 		case (riskLevel == 'Low'): return 'badge-success';
	// 		case (riskLevel == 'Medium'): return 'badge-warning';
	// 		default: return 'badge-primary';
	// 	}
	// }
	cancelDel(a) {
		this.vulnerabilityData = {};
	}
	getTopRiskMapping(template: TemplateRef<any>, vul_data){
		this.topRiskLoader=true
		this.entityType="web_app"
		vul_data['risk_level'] = this.commonService.confidenceLevel(vul_data.severity)
		this.portfolioSurveyService.topRiskMap(vul_data,this.entityType).subscribe(data => {
			if(data){
				this.modalOpenForAdd(template,data)
				this.topRiskLoader=false

			}
		});

	}
	modalOpenForAdd(template: TemplateRef<any>, vul_data) {
		this.addNewTopRisk = vul_data['data']
		this.addNewTopRisk['main_domain'] = this.mainDomain
		this.modalRef = this.modalService.show(template, {
		  backdrop: "static",
		  keyboard: false,
		  class: "min-width-dialog-modal",
		});
	  }
	  sortVuls() {
		  this.page = 1
		this.getWebVulnerability()
	}
	beautifyState(str){
		if(str){
			return str.split('_').join(' ');
		}else{
			console.log("I am in open")
			return 'open';
		}
	}
	filterByUpdatedAt($event) {
		console.log($event);
		this.sortWith = 'updated_at';
		this.initialSort = true;
		this.openThis = -1;
		this.sortVuls()
	}
	checkActiveStatus(app){
		if(app.tempState){
			if(app.tempState=='open'){
				return true;
			}else{
				if(this.strTrim(app.stateComment)){
					return true;
				}else{
					return false;
				}
			}
		}else{
			return false;
		}
	}
	strTrim(str=''){
		return str.trim();
	}
	updateState(ip){
		console.log(ip);
		if(ip.tempState && ip['applicationcve_id'][0]){
			let payload = {
				"applicationcve_id":ip['applicationcve_id'][0],
    			"state":ip.tempState,
    			"comment":ip['stateComment']
			}
			ip.isSubmiting = true;
			this.portfolioSurveyService.updateState('web_app',payload, this.mainDomain).subscribe((data:any) => {
				ip.isSubmiting = false;
				if(data.status=="pass"){
					if(data.message){
						this.toastr.success(data.message);
						ip.editingState = false;
						ip['state'][0] = ip.tempState;
					}
				}else{
					console.log(data);
					this.toastr.info("Unable to update state.");
				}
			},
			(error) => {
				ip.isSubmiting = false;
				console.log(error);
				this.toastr.error("Something went wrong.");
			});
		}else{
			this.toastr.error("Please select State to update.");
		}
	}
	detectFilters(){
		this.allFilterList = this.filterBlock.checkState(this.stateFinalFilter,this.allFilterList,'State');
		this.allFilterList = this.filterBlock.checkState(this.severitiesFinalFilter,this.allFilterList,'Severities');
		this.allFilterList = this.filterBlock.checkState(this.validatedFinalFilter,this.allFilterList,'Validate');
	}
	changeStateFilter(state){
		this.stateFinalFilter=[];
		this.filter_states.map((x)=>{
			if(x.selected) this.stateFinalFilter.push(x.key);
		});
		this.detectFilters();
	}
	filterSubDomainByState(action){
		if(action=='clear'){
			this.stateFinalFilter=[];
			this.filter_states.map((x)=>{
				x.selected = false
			});
			this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'State');
		}
		// Filter send to api
		this.page=1
		this.getWebVulnerability();
		
	}
	changeSeverityFilter(state){
		this.severitiesFinalFilter=[];
		this.filter_severities.map((x)=>{
			if(x.selected) this.severitiesFinalFilter.push(x.key);
		});
		this.detectFilters();
	}
	filterSubDomainBySeverities(action){
		if(action=='clear'){
			this.severitiesFinalFilter=[];
			this.filter_severities.map((x)=>{
				x.selected = false
			});
			this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Severities');
		}
		// Filter send to api
		this.page=1
		this.getWebVulnerability();
		
	}
	joinState(states,commaSep=true){
		if(states.length){
			let state_string = "";
			if(commaSep){
				state_string = '"'+states.join('","')+'"';
			}else{
				state_string = states.join('');
			}
			return state_string;
		}else{
			return "";
		}
	}
	changeState(filter,index){
		this.viewType = "cve-view";
		console.log(filter,index);
		if(filter=='severity'){
			this.filter_severities.map(x=>{
				x.selected = false;
			});
			this.filter_severities[index].selected = true;
			this.changeSeverityFilter(this.filter_severities[index].key);
			this.filterSubDomainByState('clear');
			this.page=1
			this.getWebVulnerability();
		}
		if(filter=='state'){
			this.filter_states.map(x=>{
				x.selected = false;
			});
			this.filter_states[index].selected = true;
			this.changeStateFilter(this.filter_states[index].key);
			this.filterSubDomainBySeverities('clear');
			this.page=1
			this.getWebVulnerability();
		}
	}
	applyRetest(modalTemplate){
		this.openActionDropdown = false;
		// console.log(this.vulnerabilties);

		this.retestParam = {
			"web_apps": {
				"application_cve_ids": [],
				"cve_ids": [],
				"application_urls": []
			}
		};
		let application_urls:any = [];
		let cve_id:any = [];
		let application_cve_ids:any = [];

		this.selectedCve.map(x => {
		  x.applications.map(cve => {
			  	if(!application_urls.includes(cve.source_url)){
					application_urls.push(cve.source_url);
			  	}
			  	if(!cve_id.includes(x.cve_id)){
					cve_id.push(x.cve_id);
			  	}
			  	if(cve.applicationcve_id && cve.applicationcve_id.length>0){
					cve.applicationcve_id.map((ids)=>{
						if(!application_cve_ids.includes(ids)){
							application_cve_ids.push(ids);
						}
					})
				}
		  	})
		});
		this.retestParam.web_apps.application_cve_ids = application_cve_ids;
		this.retestParam.web_apps.cve_ids = cve_id;
		this.retestParam.web_apps.application_urls = application_urls;
		// console.log(this.retestParam);
		// this.submitForReset(this.retestParam);
		this.applyRetestConfirmModal = this.modalService.show(modalTemplate, {
		  backdrop: "static",
		  keyboard: false,
		  class: "min-width-dialog-modal",
		});
	  }
	submitForReset(param = {}) {
		let profile: any = localStorage.getItem('profile');
		let parsedData = JSON.parse(profile);
		let customer_id = parsedData.cust_id;
		this.openActionDropdown = false;
		this.applyRetestConfirmModal.hide();
		this.portfolioSurveyService.triggerReset(this.mainDomain, param, customer_id).subscribe((data: any) => {
			// console.log(data);
			this.toastr.success("Applied for retest successfully.");
		}, (error) => {
			// console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
	refreshData(){
		let that = this;
		setTimeout(function(){
			if(that.webappComponent) that.webappComponent.getWebApplicationDetailsFromDeepc();
		},100)
	}
	checkUncheckCve(ip) {
		let checkedStatus = ip.checked;
		if (!checkedStatus) this.selectAllRecord = false;
		ip.checkedStatus = !ip.checkedStatus;
		this.selectedCve = this.getSelectedRecords();
	}
	selectAllIp() {
		if (this.selectAllRecord) {
			this.vulnerabilties.map(x => {
				x.checked = true;
				this.checkUncheckCve(x);
			});
		} else {
			this.vulnerabilties.map(x => {
				x.checked = false;
				this.checkUncheckCve(x);
			});
		}
		this.selectedCve = this.getSelectedRecords();
	}
	getSelectedRecords() {
		let selectedCve: any = [];
		this.noOfSelectedRecord = 0;
		this.vulnerabilties.map(x => {
			if (x.checked) {
				this.noOfSelectedRecord = this.noOfSelectedRecord + 1;
				selectedCve.push(x);
			}
		});
		return selectedCve;
	}
	filterCounter(mainDomain){
		// (this.chartApiCall)?this.chartApiCall.unsubscribe():null;
		let params:any = {
			searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
			...this.filters
		}
		let stateString = '';
		if(this.stateFinalFilter.length){
			stateString = this.joinState(this.stateFinalFilter);			
		}else{
			let allFilter:any = [];
			this.filter_states.map(x=>allFilter.push(x.key));
			stateString = this.joinState(allFilter);
		}
		if(stateString){
			params['state'] = stateString;
		}
		let severityString = this.joinState(this.severitiesFinalFilter);
		if(severityString){
			params['severities'] = severityString;
		}
        params['scope'] = this.selectedScope;
		let validatedString = this.joinState(this.validatedFinalFilter);
		if(validatedString){
			params['validated_flags'] = validatedString;
		}
		if(this.lastParam && !this.commonService.matchAllParamValue(this.lastParam,params)) return;
		this.lastParam = params;
		this.portfolioSurveyService.getWebChartData(mainDomain, params).subscribe((data: any) => {
			this.lastParam = null;
			let totalCount = 0;
			if(data && data['by_severity']){
				Object.keys(data['by_severity']).forEach(function (key) {
					totalCount = totalCount + data['by_severity'][key] || 0;
				});
			}
			this.totalCount = totalCount;
			console.log('NewCount',this.totalCount);
		}, (error) => {
			console.log(error);
		});
	}
	changeValidatedFilter() {
		this.validatedFinalFilter = [];
		this.validated_flags.map((x) => {
		  if (x.selected) this.validatedFinalFilter.push(x.value);
		});
		this.detectFilters();
	  }
	  filterValidatedCve(action) {
		if (action == 'clear') {
		  this.selectAllRecord = false;
		  this.validatedFinalFilter = [];
		  this.validated_flags.map((x) => {
			x.selected = false
		  });
		  this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Validate');
		}
		// Filter send to api
		this.page = 1
		this.getWebVulnerability();
		
	  }
	getObsInsCountsForEachCVE(arrayList) {
		this.obsInsCountSpinner = true
		const params = {
		  subject_ref_list: arrayList,
		  subject_type: this.subjectType
		}
		this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain, params).subscribe(data => {
		  this.obsInsCountSpinner = false
		  this.vulDetailForCve = data['data']
		  this.vulnerabilties.forEach(web_vul => {
			let web_apps = web_vul.applications
			web_apps.forEach(app_detail => {	
				let applicationcve_id = app_detail.applicationcve_id[0]
				if (this.vulDetailForCve[applicationcve_id]) {
					app_detail.obs_count = this.vulDetailForCve[applicationcve_id].count_obs
					app_detail.ins_count = this.vulDetailForCve[applicationcve_id].count_ins
				}
			})
		  })
		})
	  }
	openCommentsModule(template: TemplateRef<any>, app) {
		let profile: any = localStorage.getItem('profile');
		let parsedData = JSON.parse(profile);
		let account_type = parsedData.account_type;
		this.pocSubjectComment = {
			"subject_type": app['subjectTypeName']['subject_type'],
			"subject_name": app['subjectTypeName']['subject_name'].trim(),
			"subject_id": app['subjectTypeName']['subject_id'],
			"visible_to": account_type
		}
		this.modalRef2 = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}
	openImpact(template: TemplateRef<any>, ser) {
		this.editImpact = false;
		this.impactServ = ser;
		console.log(this.impactServ);
		// this.impactServ['impact'] = 'Lorem Ipsome';
		if(!this.impactServ['impact'][0]) this.editImpact = true;
		this.modalRef2 = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}
	updateImpact(){
		let ip = this.impactServ;
		let payload = {
			"ids":[ip['applicationcve_id'][0]],
			"impact":ip['impact'][0]
		}
		this.portfolioSurveyService.updateState('web_app',payload, this.mainDomain).subscribe((data:any) => {
			if(data.status=="pass"){
				if(data.message){
					this.editImpact = false;
					this.toastr.success('Impact updated successfully');
				}
			}else{
				this.toastr.info("Unable to update impact.");
			}
		},
		(error) => {
			console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
	deleteImpct(){
		let ip = this.impactServ;
		let payload = {
			"ids":[ip['applicationcve_id'][0]],
			"impact":null
		}
		this.portfolioSurveyService.updateState('web_app',payload, this.mainDomain).subscribe((data:any) => {
			if(data.status=="pass"){
				if(data.message){
					this.editImpact = true;
					this.toastr.success('Impact deleted successfully');
					ip['impact'][0] = null;
				}
			}else{
				this.toastr.info("Unable to delete impact.");
			}
		},
		(error) => {
			console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
}