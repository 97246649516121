import {
  Component,
  OnInit,
  TemplateRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AssetTypePipe } from "app/pipes/asset-type.pipe";
import { AssessmentService } from "app/_services";
import { CommonService } from "app/_services/common.service";
import { KgToNgxGraphFormatService } from "app/_services/kg-to-ngx-graph-format.service";
import { RbToNgxGraphFormatService } from "app/_services/rb-to-ngx-graph-format.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { classicNameResolver } from "typescript";
import { SpoceBaseURL } from "environments/environment";

@Component({
  selector: "app-rb-results",
  templateUrl: "./rb-results.component.html",
  styleUrls: ["./rb-results.component.scss"],
})
export class RbResultsComponent implements OnInit {
  public assessmentId = null;
  public assessmentDetails;
  public loadingData = false;
  public expandTab = "assessment";
  public showAssessment = false;
  public showRB = true;
  public summary;
  public scans;
  public targetsToShow = [];
  public showRunstep = [];
  public layoutSettings = {
    orientation: "BT",
  };
  public kgGraph;
  public allGraphs = {};
  public loadingKgGraph = false;
  public assetTypePipe = new AssetTypePipe();
  public runbook;
  public scanIds = [];
  public selectedRunStep = null;
  showNoDataWarning: boolean;
  @Output() showAssessmentList = new EventEmitter<any>();

  constructor(
    public route: ActivatedRoute,
    public assessmentService: AssessmentService,
    public commonService: CommonService,
    public rbToNgxGraphFormat: RbToNgxGraphFormatService,
    public kgToNgxGraphFormat: KgToNgxGraphFormatService,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.assessmentId = this.route.snapshot.paramMap.get("attack_id");
    if (this.assessmentId) {
      this.getAssessmentDetails();
    }
  }
  loadAssessmentDetails() {
    this.getAssessmentDetails();
  }

  getAssessmentDetails() {
    this.loadingData = true;
    this.showRunstep = [];
    this.assessmentService.getAssessmentDetails(this.assessmentId).subscribe(
      (data) => {
        this.loadingData = false;
        if (data && data["runbook"]) {
          this.rbToNgxGraphFormat.convertElementsToNodesAndEdges([
            data["runbook"],
          ]);
          this.assessmentDetails = data;
          this.runbook = this.assessmentDetails.runbook;
          this.assessmentService
            .getAssessmentResults(this.assessmentId)
            .subscribe((result) => {
              this.summary = result["summary"];
              this.scans = result["scans"];
              let scanStateAbort = false;
              if (
                this.runbook &&
                this.runbook.runflow &&
                this.runbook.runflow.length
              ) {
                this.runbook.runflow[0].nodes.forEach((node) => {
                  if (node && !["End", "Start"].includes(node.label)) {
                    let scanLocal = this.scans.find(
                      (s) => s.runstep_id == node.id
                    );
                    if (!scanLocal) {
                      if (!scanStateAbort) {
                        let nextStep = this.kgToNgxGraphFormat.getNextNodes(
                          node,
                          this.assessmentDetails?.runbook?.runflow[0]?.elements
                        );
                        node.status = "queued";
                        node.nextStep_id =
                          nextStep && nextStep.length ? nextStep[0].id : null;
                      }
                      node = {
                        ...node,
                        runflow_steps: { data: node.data, ...node },
                      };
                      this.scans.push(node);
                    } else {
                      if (
                        (scanLocal && scanLocal.status == "suspended") ||
                        scanLocal.status == "error"
                      ) {
                        scanStateAbort = true;
                      }
                    }
                  }
                });
              }
              this.scans.forEach((scan) => {
                if (
                  scan.runflow_steps &&
                  scan.runflow_steps.data &&
                  scan.runflow_steps.data.phase &&
                  scan.runflow_steps.data.phase[0] == "Manual" &&
                  scan.status == "in_progress"
                ) {
                  scan.status = "paused";
                }
                if (
                  !scan.status &&
                  this.assessmentDetails.status == "completed"
                ) {
                  scan.status = this.assessmentDetails.status;
                } else if (scan.status == "queued" && scan.nextStep_id) {
                  if (this.assessmentDetails.status == "completed") {
                    scan.status = this.assessmentDetails.status;
                  } else {
                    let nextStepScans = this.scans.find(
                      (s) => s.runstep_id == scan.nextStep_id
                    );
                    if (nextStepScans && nextStepScans.status) {
                      scan.status = nextStepScans.status;
                    }
                  }
                }
                this.showRunstep.push(true);
              });
              this.scans.sort((a, b) => {
                if (a?.runflow_steps?.orderId && b?.runflow_steps?.orderId) {
                  return a?.runflow_steps?.orderId - b?.runflow_steps?.orderId;
                }
                return 1;
              });
            });
        }
      },
      (error) => {
        alert("Something went wrong! Please try again.");
        this.router.navigate(["/playbook"]);

        this.loadingData = false;
      }
    );
  }
  targetType(target, runStep) {
    const entity: String = this.assetTypePipe.transform(target, true);
    return this.commonService.returnInitials(entity);
  }
  targetTypeHoverText(target) {
    const entity: String = this.assetTypePipe.transform(target, true);
    return this.commonService.returnHoverText(entity);
  }
  showRunOptions() {
    return (
      this.runbook.run_options &&
      this.runbook.run_options.scope &&
      Object.keys(this.runbook.run_options.scope).length
    );
  }
  getKeysFromSummary(summary) {
    return Object.keys(summary).filter((a) => !["scan"].includes(a));
  }
  openDiscoveryGraphModel(template: TemplateRef<any>) {
    if (!this.allGraphs["overallKG"]) {
      this.loadingKgGraph = true;
      this.assessmentService
        .getAssessmentResults(this.assessmentId, { summary: "kg" })
        .subscribe((data) => {
          if (data && data["summary"]) {
            this.kgToNgxGraphFormat.scanNode = data["summary"]["nodes"]
              ? data["summary"]["nodes"].find(
                  (node) => node["typeof"] == "scan"
                ) || {}
              : {};
            this.allGraphs["overallKG"] = {
              edges: this.kgToNgxGraphFormat.convertEdges(
                data["summary"]["edges"]
              ),
              nodes: [
                ...this.kgToNgxGraphFormat.convertNodes(
                  data["summary"]["nodes"]
                ),
              ],
            };
            this.kgGraph = this.allGraphs["overallKG"];
            this.modalRef = this.modalService.show(template, {
              backdrop: "static",
              keyboard: false,
              class: "min-width-dialog-modal",
            });
            this.loadingKgGraph = false;
          } else {
            this.showNoDataWarning = true;
          }
        });
    } else {
      this.kgGraph = this.allGraphs["overallKG"];
      this.modalRef = this.modalService.show(template, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }
  }
  openDiscoveryGraphModelForRunStep(runStep, template: TemplateRef<any>) {
    if (!this.allGraphs[runStep.id]) {
      this.loadingKgGraph = true;
      this.assessmentService
        .getAssessmentResultsForRunStep(runStep.id, { summary: "kg" })
        .subscribe((data) => {
          if (data && data["summary"]) {
            this.kgToNgxGraphFormat.scanNode = data["summary"]["nodes"]
              ? data["summary"]["nodes"].find(
                  (node) => node["typeof"] == "scan"
                ) || {}
              : {};
            this.allGraphs[runStep.id] = {
              edges: this.kgToNgxGraphFormat.convertEdges(
                data["summary"]["edges"]
              ),
              nodes: [
                ...this.kgToNgxGraphFormat.convertNodes(
                  data["summary"]["nodes"]
                ),
              ],
            };
            this.kgGraph = this.allGraphs[runStep.id];
            this.modalRef = this.modalService.show(template, {
              backdrop: "static",
              keyboard: false,
              class: "min-width-dialog-modal",
            });
          } else {
            this.showNoDataWarning = true;
          }
          this.loadingKgGraph = false;
        });
    } else {
      this.kgGraph = this.allGraphs[runStep.id];
      this.modalRef = this.modalService.show(template, {
        backdrop: "static",
        keyboard: false,
        class: "min-width-dialog-modal",
      });
    }
  }
  showTargets(template: TemplateRef<any>, targets) {
    this.targetsToShow = targets;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  hideToUser(key, scope_details, runbook) {
    if (scope_details && key in scope_details) {
      return scope_details[key]?.hideToUser;
    } else if (
      runbook.run_options.scopeIdentity &&
      runbook.run_options.scopeIdentity[key]
    ) {
      return runbook.run_options.scopeIdentity[key].hideToUser;
    }
  }
  getDisplayName(key, items) {
    if (items[key]) {
      if (items[key].display_name) {
        return items[key].display_name;
      } else {
        return key.replaceAll("_", " ");
      }
    }
    else {
      return key.replaceAll("_", " ")
    }
  }
  getUnique(targets) {
    if (targets && targets.length) {
      return targets.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    } else return targets;
  }
  fetchReports(runstep: any) {
    runstep["scansList"] = [];
    runstep["scanLoading"] = true;
    runstep["totelScans"] = 0;
    if (!runstep["cloudCurrentPage"]) {
      runstep["cloudCurrentPage"] = 1;
    }
    this.assessmentService
      .getScans(
        runstep.assessment_id,
        runstep.runstep_id,
        runstep["cloudCurrentPage"]
      )
      .subscribe(
        (data) => {
          runstep["scanLoading"] = false;
          runstep["scansList"] = data["results"];
          runstep["totelScans"] = data["count"];
        },
        (error) => {
          runstep["scanLoading"] = false;
          console.log(error);
        }
      );
    // console.log(runstep);
    // https://apis-qa2.firecompass.com/runbook/admin/scan?assessment_id=9a4085ca-2ec5-41b2-97ac-03afc552cf39&runstep_id=dndnode_ulz24&page_size=100
  }
  goToPage(val, runstep) {
    runstep["cloudCurrentPage"] = val;
    this.fetchReports(runstep);
  }

  openRedirectModal(template: TemplateRef<any>, attack) {
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
    this.selectedRunStep = attack;
  }

  openTriageCenter() {
    console.log(this.selectedRunStep);
    if (
      this.selectedRunStep.runstep_name &&
      this.selectedRunStep.runstep_name.match(/event_triaging_/g)
    ) {
      window.open(
        SpoceBaseURL.url +
          "assessmentcentre/assessments/expertactions/redirect/" +
          this.selectedRunStep.assessment_id +
          "/" +
          this.selectedRunStep.runstep_id,
        "_blank"
      );
    }
    this.modalRef.hide();
  }

  cancelRedirect() {
    this.selectedRunStep = null;
    this.modalRef.hide();
  }
}
