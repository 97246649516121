import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ViewChild, ElementRef } from "@angular/core";
import { PortfolioSurveyService } from "./../../_services/portfolio-survey.service";
import { CommonService } from "app/_services/common.service";
// import { GoogleChartInterface } from 'ng2-google-charts';

@Component({
  selector: "app-external-score",
  templateUrl: "./external-score.component.html",
  styleUrls: ["./external-score.component.scss"],
})
export class ExternalScoreComponent implements OnInit {
  @ViewChild("portAndIps") elementView: ElementRef;
  @ViewChild('targetService') targetSrv: ElementRef;
  @ViewChild('targetWeb') targetApp: ElementRef;
  @Input() selectedEntity:any;
  @Input() showingFrom:any = "old-dashboard";
  @Input() inscopeFlagOverWrite:any;
  
  public showBlock = {
    'cards' : true,
    'top_risk_insights' : true,
    'network_vulnerability' : true,
    'web_app_vulnerability' : true,
    'exposed_databases' : true,
    'open_ports': false
  }
  @Output() countEmitor: EventEmitter<any> = new EventEmitter<any>();
  // displayLogic
  public showCards = true;
  public showRisk = true;
  public showPorts = true;
  public showNetworkVulnerabilities = true;
  public showWebVulnerabilities = true;

  public canvas: any;
  public showThis = "leaked-credentials";
  public domain;
  public osintDetails;
  public breachedCredentials;
  public userDomains;
  public scores;
  public noPremission: boolean = false;
  public riskSummarySpinner: boolean = false;
  public overAllGrade;
  public dnsDetails;
  public sslDetails;
  public webDetails;
  public mailDetails;
  public details;
  public showError;
  public riskCounts;
  public probableDigitalRisk;
  public topRisk;
  public risk;
  public digitalFootprintScore: any;
  public fetchingNewScore = false;
  public newScores: any;
  public riskDetails = {};
  public dataToDisplay = {};
  public riskType: string = null;
  public ipDetails: boolean = false;
  public getIpdetails: any = {};
  public cveDetails;
  public total_cve_count;
  public ips_with_vul_mal = [];
  public newPortList = [];
  public portChartLabel = [];
  public portChartData = [];
  public typeOfRiskToShow = null;
  public treemapData = [];
  public newPortSpinner: boolean = false;
  public serviceVulnerabilties = [];
  public networkCriticalVulToShow = [];
  public networkHighVulToShow = [];
  public networkMedVulToShow = [];
  public networkLowVulToShow = [];
  public networkInfoVulToShow = [];
  public webVulnerabilties = [];
  public webCriticalVulToShow = [];
  public webHighVulToShow = [];
  public webMedVulToShow = [];
  public webLowVulToShow = [];
  public webInfoVulToShow = [];
  public webVulToShow = [];
  public showPortsFromTop = 0;
  public hidePortsFromTop = 0;
  public showPortsFromBottom = 0;
  public showSpinnerData = false;
  public showSpinnerforChart: false;
  public showSpinnerforServiceVulChart = false;
  public showSpinnerforWebVulChart = false;
  public show_max_location = 3;
  public show_max_location_web = 3;
  public maliciousCount = 0;
  public criticalPorts = [
    "7210",
    "3306",
    "1521",
    "1830",
    "5432",
    "1433",
    "1434",
    "8529",
    "9042",
    "5984",
    "9200",
    "9300",
    "27017",
    "27018",
    "27019",
    "28017",
    "7473",
    "7474",
    "6379",
    "8087",
    "8098",
    "7574",
    "8983",
    "2379",
    "2380",
    "6443",
    "514",
    "873",
    "445",
  ];
  public mediumPorts = [
    "7000",
    "7001",
    "5050",
    "5051",
    "2181",
    "2888",
    "3888",
    "20",
    "21",
    "389",
    "137",
    "138",
    "139",
    "3389",
  ];
  // Doughnut
  public serviceVulLabels = ["Critical", "High", "Medium", "Low", "Info"];
  public serviceVulLabels_mod = [];
  public serviceVulData:any = [];
  public webVulLabels = ["Critical", "High", "Medium", "Low", "Info"];
  public webVulLabels_mod = [];
  public webVulData:any = [];
  public doughnutChartType = "doughnut";
  public serviceVulChartColor: any = [
    {
      backgroundColor: ["#f86c6b", "#ffc107", "#20a8d8", "#4dbd74","#adb3b8"],
    },
  ];
  public doughnutChartOptions = {
    legend: {
      display: true,
      position: "left",
      label: {
        generateLabels: () => {
          return [
            {
              text: "20",
              fillStyle: "#42a5f5",
              strokeStyle: "#fff",
            },
          ];
        },
      },
    },
    // chartClicked: (evt, item) => {
    // 	item[0]._model.outerRadius += 10
    //   }
  };
  public serviceVulToShow = [];
  public serDetailVul = {};
  public detailVul = {};
  public webDetailVul = {};
  public show_max_list: number = 3;
  public sortWith: string = "cves";
  public order: number = -1;
  public initialSort: boolean = true;
  public portsOut = [];
  public title = "";
  public type = "TreeMap";
  public portData = [];
  public oldPortData = [];
  columnNames = [
    "Location",
    "Parent",
    "Market trade volume (size)",
    "Market increase/decrease (color)",
  ];
  options = {
    minColor: "#00bfff", //red
    midColor: "#00bfff", //orange
    maxColor: "#00bfff", //blue
    headerHeight: 0,
    showScale: false,
    // generateTooltip: this.showStaticTooltip
  };
  public inscopeConfig: any;
  public inscopeFlag
  public scopeConfParams ={}
  public risksTypes:any = {
    leakcred:{
      loading:true,
      data:null,
      display_name:"Leaked Credentials",
      keyname:'leakedCreds'
    },
    prepodservice:{
      loading:true,
      data:null,
      display_name:"PRE-PROD SERVICES",
      keyname:'preprodServices'
    },
    exposedservice:{
      loading:true,
      data:null,
      display_name:"EXPOSED SERVICES",
      keyname:'exposedServices'
    },
    exposeddatabase:{
      loading:true,
      data:null,
      display_name:"EXPOSED DATABASES",
      keyname:'exposedDatabase'
    // },
    // takeoverrisk:{
    //   loading:true,
    //   data:null,
    //   display_name:"TAKEOVER RISK",
    //   keyname:'takeoverRisk'
    }
  };
  public probableRisksTypes:any = {
    // phishingdomain:{
    //   loading:true,
    //   data:null,
    //   display_name:"Lookalike Domains",
    //   keyname:'phishingDomains'
    // },
    // maliciouscount:{
    //   loading:true,
    //   data:null,
    //   display_name:"IP Reputation",
    //   keyname:'maliciousEntities'
    // }
  };
  constructor(
    public portfolioSurveyService: PortfolioSurveyService,
    public route: ActivatedRoute,
    public router: Router,
    public modalService: BsModalService,
    public modalRef: BsModalRef,
    public commonService: CommonService,
  ) {}
  public doughnutColors: Array<any> = [
    {
      backgroundColor: ["rgb(255,0,0)", "rgb(250,250,250)"],
    },
  ];
  public doughnutChartOptions1 = {
    legend: {
      display: false,
      position: "right",
      label: {
        generateLabels: function () {
          return [
            {
              text: "50",
              fillStyle: "#42a5f5",
              strokeStyle: "#fff",
            },
          ];
        },
      },
    },
  };

  loadPortfolioAnalysisData(domain, count = 1) {
    this.portfolioSurveyService.getWebSecurity(this.domain).subscribe(
      (data: any) => {
        if (data.analyze && data.analyze.state === "FINISHED") {
          this.webDetails = data;
          this.details = data.detailedAnayze;
        } else if (data.error === "invalid-hostname") {
          this.webDetails = { analyze: { grade: "NA" } };
          this.showError = data.text;
        } else {
          if (count <= 3) this.loadPortfolioAnalysisData(domain, ++count);
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  getAllOsintDetails(domain, count?) {
    this.portfolioSurveyService.getAllDetailsOsint(domain).subscribe(
      (data: any) => {
        this.overAllGrade = data["overAllGrade"];
        this.osintDetails = data["osintDetails"];
        this.dnsDetails = this.osintDetails.dnsDetails;
        this.webDetails = data.webDetails;
        this.details = this.webDetails.detailedAnayze;
        if (
          this.osintDetails.sslDetails &&
          this.osintDetails.sslDetails["SSLDetailsForDomain"]
        ) {
          // setTimeout(() =>
          // {
          //   count = count ? count : 1;
          //   if(count < 4)
          //     this.getAllOsintDetails(domain, count++);
          // },
          // 10000);
        } else {
          this.sslDetails = this.osintDetails.sslDetails;
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }
  ngOnInit() {
    let that = this;
    setTimeout(function(){
      that.getDomainName();
    },800);
    this.blockDiaplsyLogic();
  }
  ngOnChanges(change:SimpleChange){
    if(change && change['selectedEntity']){
      this.blockDiaplsyLogic();
    }else if(change && change['inscopeFlagOverWrite']){
      this.filterByInscope(change['inscopeFlagOverWrite'].currentValue);
    }
  }
  blockDiaplsyLogic() {
    if (this.showingFrom == 'new-dashboard') {
      for (let x in this.showBlock) {
        if (x == this.selectedEntity) {
          this.showBlock[x] = true;
        }else{
          this.showBlock[x] = false;
        }
      }
    }
  }

  public getAllData() {
    if (this.domain) {
      this.inscopeConfig = localStorage.getItem('scope_config');
      if (this.inscopeConfig == 'true' && this.inscopeFlag != null) {
        this.scopeConfParams = {
          scope: this.inscopeFlag
        }
      } else delete this.scopeConfParams['scope'];
      this.scopeConfParams['validated_flags'] = '"true","false"';
      this.scopeConfParams['state'] = '"open","ready_to_retest","closed","resolved","retest_in_progress"';
      // this.getRiskCount();
      // this.getDigitalFootprintScore(this.domain);
      // this.getVulnerabilityDeepc();
      // this.getIpsDetailsFromDeepc();
      if(this.selectedEntity=='top_risk_insights'){
        this.getRiskCountIndApi();
        this.getTopRisk(this.domain);
      }else if(this.selectedEntity=='network_vulnerability'){
        this.getServiceVulnerability();
        this.getChartsData();
      }else if(this.selectedEntity=='web_app_vulnerability'){
        this.getWebVulnerability();
        this.getChartsData();
      }

      // this.maliciousDetail();
    } else {
      alert(
        "No domain associated with you, Please write us on contact@firecompass.com"
      );
    }
  }
  getRiskCountIndApi() {
    for (const key in this.risksTypes) {
      this.risksTypes[key]['loading'] = true;
      this.portfolioSurveyService
        .getDashboardRisksForDomain(this.domain, key, this.scopeConfParams)
        .subscribe((data: any) => {
          if (data["status"] == "pass") {
            this.risksTypes[key]['data'] = data["count"];
            this.countEmitor.emit({key:key,count:data["count"]});
          } else {
            this.risksTypes[key]['data'] = null;
            this.countEmitor.emit({key:key,count:0});
          }
          this.risksTypes[key]['loading'] = false;
        }, (error) => {
          this.errorForDomain(error);
          this.risksTypes[key]['loading'] = false;
        });
    }
    for (const key in this.probableRisksTypes) {
      this.probableRisksTypes[key]['loading'] = true;
      this.portfolioSurveyService
        .getDashboardProbableRisksForDomain(this.domain, key, this.scopeConfParams)
        .subscribe((data: any) => {
          if (data["status"] == "pass") {
            this.probableRisksTypes[key]['data'] = data["count"];
            this.countEmitor.emit({key:key,count:data["count"]});
          } else {
            this.probableRisksTypes[key]['data'] = null;
            this.countEmitor.emit({key:key,count:0});
          }
          this.probableRisksTypes[key]['loading'] = false;
        }, (error) => {
          this.errorForDomain(error);
          this.probableRisksTypes[key]['loading'] = false;
        });
    }
  }
getDataForScope(){
  if (this.domain) {
    this.inscopeConfig = localStorage.getItem('scope_config')
  if(this.inscopeConfig == 'true'&& this.inscopeFlag != null ){
    this.scopeConfParams = {
      scope : this.inscopeFlag
    }
  } else delete this.scopeConfParams['scope']
    this.getRiskCount();
    this.getIpsDetailsFromDeepc();
    this.getServiceVulnerability();
  } else {
    alert(
      "No domain associated with you, Please write us on contact@firecompass.com"
    );
  }
}
  maliciousDetail() {
    this.portfolioSurveyService
      .getRiskDetails(this.domain, "maliciousEntities")
      .subscribe((data: any) => {
        this.maliciousCount = data["count"];
      });
  }
  getRiskCount() {
    this.showSpinnerData = true;
    this.portfolioSurveyService
      .getRisksForDomain(this.domain, this.scopeConfParams)
      .subscribe((data: any) => {
        if (data["status"] == "pass") {
          this.riskCounts = data["data"];
          this.showSpinnerData = false;
        }
      },(error)=>{
        this.errorForDomain(error);
      });
    this.portfolioSurveyService
      .getProbableRiskCountForDomain(this.domain)
      .subscribe((data: any) => {
        if (data["status"] == "pass") {
          this.probableDigitalRisk = data["data"];
        }
      },(error)=>{
        this.errorForDomain(error);
      });
  }

  getDomainName() {
    this.route.queryParams.subscribe((params) => {
      if (params && params["domain"]) {
        this.domain = params["domain"];
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { domain: this.domain },
          queryParamsHandling: "merge",
          skipLocationChange: true,
        });
        this.getAllData();
      } else {
        this.portfolioSurveyService
          .getDomainsSearchForUser()
          .subscribe((data: any) => {
            data.filter((d) => {
              if (d["domain_type"] == "self") {
                this.domain = d["domain"];
                this.getAllData();
              }
            });
          });
      }
    });
    
  }

  convertNaNToDash(da) {
    if (da > -1000) {
      return da;
    } else if (String(da).startsWith("NA")) {
      return "-";
    } else if (String(da).startsWith("N/A")) {
      return "-";
    } else {
      return da;
    }
  }

  errorForDomain(error) {
    if (error.status === 504) {
      this.showError = "Time error! This error occurs while fetching data.";
    } else if (error.status === 403 && !this.noPremission) {
      // this.showError = "Forbidden! You are not allowed to perform this action."
      this.noPremission = true;
      alert(
        "Forbidden! You are not allowed to perform this action. Please contact us at contact@firecompass.com"
      );
      this.router.navigate(["/new/self-domains"]);
    } else {
      this.showError = "API not found! Error while connecting to backend.";
    }
  }
  
  public stateOfRisk(state) {
    switch (true) {
      case state == "open":
        return "text-danger";
      case state == "resolved":
        return "text-success";
      case state == "inprogress":
        return "text-warning";

      default:
        return "text-primary";
    }
  }
  public scoreForTotalRisk(score) {
    score = (score / 100) * 100;
    switch (true) {
      case score >= 0 && score <= 33:
        return "bg-success width-20";
      case score > 33 && score <= 66:
        return "bg-info  width-40";
      case score > 66 && score <= 80:
        return "bg-warning width-80";
      case score > 80:
        return "bg-danger width-100";

      default:
        return "bg-primary";
    }
  }
  public getTopRisk(domain) {
    this.portfolioSurveyService.getTopRisk(domain,this.inscopeFlag).subscribe((data: any) => {
      this.topRisk = data.data;
    });
  }
  modalOpenForAdd(template: TemplateRef<any>, risk) {
    this.modalHide();
    this.risk = risk;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
    });
  }
  // public computeScore() {
  //   this.fetchingNewScore = true;
  //   this.portfolioSurveyService.calculateScoreForDomain(this.domain).subscribe(
  //     (data) => {
  //       if (data && data["status"] === "pass") {
  //         this.digitalFootprintScore = data["data"];
  //         this.convertScores(this.digitalFootprintScore);
  //       }
  //       this.fetchingNewScore = false;
  //     },
  //     (error) => {
  //       this.fetchingNewScore = false;
  //     }
  //   );
  // }

  public HeadersJson = {
    leakedCreds: {
      domain: "Domain",
      leaked_credentials_count: "Leaked Credentials count",
    },
    preprodServices: {
      domain: "Domain",
      type: "Type",
      discovered_at: "Discovered At",
    },
    exposedServices: { ip_address: "IP Address", service_name: "Service Name" },
    exposedDatabase: { ip_address: "IP Address", service_name: "Service Name", banner: "Banner Details" },
    takeoverRisk: { domain: "Domain", takeover_risk: "Takeover Risk" },
    phishingDomains: {
      fraud_domain: "Domain",
      expires_at: "Expiring At",
      contact_email: "Contact Email",
    },
    maliciousEntities: {
      subject_name: "Ip/Domain",
      host_name: "Domain/Netblock",
      sources: "Source",
    },
    vulnerabilities: {
      subject_name: "Assets",
      service_name: "Location",
      count: "Count",
      cve_id: "Vulnerabiliy",
    },
  };
  public SortKeys = {
    leakedCreds: ["leaked_credentials_count"],
    preprodServices: ["discovered_at"],
    exposedServices: ["ip_address"],
    exposedDatabase: ["ip_address"],
    takeoverRisk: ["takeover_risk"],
    phishingDomains: ["expires_at"],
    maliciousEntities: ["subject_name"],
    vulnerabilities: ["count"],
  };

  public getRiskDetails(template: TemplateRef<any>,
    riskToShow,
    riskType,
    count
  ) {
    this.typeOfRiskToShow = riskToShow;
    this.riskType = riskType;
    this.modalHide();
    if (count && count > 0) {
      this.modalRef = this.modalService.show(template, {
        backdrop: "static",
        keyboard: true,
        class: "auto_width",
      });
      // if (this.riskDetails && this.riskDetails[riskType]) {
      //   if (this.riskDetails[riskType] == "Forbidden") {
      //     this.dataToDisplay = {
      //       headers: {
      //         forbiddon: "You do not have permission to view this content",
      //       },
      //       data: null,
      //     };
      //   } else {
      //     this.dataToDisplay = {
      //       headers: this.HeadersJson[riskType],
      //       data: this.riskDetails[riskType],
      //     };
      //     (this.sortWith = this.SortKeys[riskType][0]), (this.order = -1);
      //   }
      //   // this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: true, class: 'auto_width' });
      // } 
      // else {
        this.riskSummarySpinner = true;
        this.portfolioSurveyService
          .getRiskDetails(this.domain, riskType,this.scopeConfParams)
          .subscribe(
            (data: any) => {
              if (data && data["status"] == "pass") {
                if (data["data"] == "Forbidden") {
                  this.dataToDisplay = {
                    headers: {
                      forbiddon:
                        "You do not have permission to view this content",
                    },
                    data: null,
                  };
                } else {
                  if (riskType == "vulnerabilities") {
                    let dataToShow = this.calculateVulnerabilityRiskDetails(
                      data["data"]
                    );
                    this.riskDetails[riskType] = dataToShow;
                  } else {
                    this.riskDetails[riskType] = data["data"];
                  }
                  if (riskType == 'exposedDatabase') {
                    this.riskDetails[riskType].forEach(r => {
                      if (r.name && r.name != r.service_name) {
                        r.service_name = r.service_name + "(" +r.name+ ")"
                      }
                    })
                  }
                  this.dataToDisplay = {
                    headers: this.HeadersJson[riskType],
                    data: this.riskDetails[riskType],
                  };
                  (this.sortWith = this.SortKeys[riskType][0]),
                    (this.order = -1);
                }
                this.riskSummarySpinner = false;
              } else {
                this.riskSummarySpinner = false;
              }
            },
            (error) => {
              this.riskSummarySpinner = false;
            }
          );
      // }
    }
  }

  calculateVulnerabilityRiskDetails(data) {
    let vulToShow = {};
    data.forEach((v) => {
      let key = v["subject_name"] ? v["subject_name"] : v["ip_address"];
      let keyCheck = key + "--" + v["target"];
      if (!vulToShow[keyCheck]) {
        vulToShow[keyCheck] = {
          count: 1,
          subject_name: v["subject_name"] ? v["subject_name"] : v["ip_address"],
          service_name: v["target"] ? v["target"] : v["service_name"],
          cve_id: [v["cve_id"]],
          ip_addresses: v["ip_address"] ? [v["ip_address"]] : [],
        };
      } else {
        if (
          v["ip_address"] &&
          vulToShow[keyCheck]["ip_addresses"].indexOf(v["ip_address"]) == -1
        ) {
          vulToShow[keyCheck]["ip_addresses"].push(v["ip_address"]);
        }
        vulToShow[keyCheck]["count"] = vulToShow[keyCheck]["count"] + 1;
        vulToShow[keyCheck]["cve_id"].push(v["cve_id"]);
      }
    });
    let dataToShow = [];
    Object.keys(vulToShow).forEach((e) => {
      dataToShow.push(vulToShow[e]);
    });
    return dataToShow;
  }

  public getHeaders(headers) {
    if (headers) return Object.keys(headers).map((k) => headers[k]);
  }
  public getHeaderKeys(headers) {
    return Object.keys(headers);
  }

  public getHeaderKeyForSort(headers, i) {
    return Object.keys(headers)[i];
  }

  modalHide() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
  // public chartDetails() {
  // 	this.chartData.forEach(data => {
  // 		this.doughnutChartLabels.push(data.name)
  // 		this.doughnutChartData.push(data.score)
  // 	})
  // }
  // public doughnutChartLabels: string[] = ['Download', 'In-Store Sales', 'Mail-Order Sales','abcd','pqrs',' Sales','malicious', 'vulnerabilty'];
  // public doughnutChartData: number[] = [350, 450, 100,200,50,65,100,40];

  showIpDetails(ip) {
    this.ipDetails = false;
    this.portfolioSurveyService
      .getIpDetails(ip, this.domain)
      .subscribe((data: any) => {
        this.getIpdetails["subjects"] = [];
        // this.getIpdetails['ip'] = ""
        this.getIpdetails["ip"] = ip;
        data.data = data.data.filter((d) => d.host != null);
        if (data.data.length == 0) {
          this.getIpdetails["subjects"].push({
            name: "NA",
            discovered_at: "NA",
          });
        } else {
          this.getIpdetails["subjects"] = [];
        }
        data.data.forEach((ele) => {
          if (ele.host) {
            this.getIpdetails["subjects"].push({
              name: ele.host ? ele.host : "NA",
              created_at: ele.created_at,
              updated_at: ele.updated_at,
              discovered_at: ele.discovered_at ? ele.discovered_at : "NA",
            });
          }
        });

        this.getIpdetails["subjects"].forEach((ele) => {
          if (ele.name != null) this.ipDetails = true;
        });
      });
  }
  hideModel() {
    this.ipDetails = false;
    this.modalRef.hide();
    this.dataToDisplay = {};
  }
  getVulnerabilityDeepc() {
    this.portfolioSurveyService.getVulnerabilityDeepc(this.domain).subscribe(
      (data: any) => {
        if (data && data["status"] == "pass") {
          this.cveDetails = data["data"];
          this.total_cve_count = data["vulnerabilities_count"];
        }
      },
      (error) => {
        this.errorForDomain(error);
      }
    );
  }

  selected(port, tree) {
    this.portData = [...this.portData];
    const portName = this.portData[port[0].row][0];
    this.modalHide();
    var result = this.newPortList.find((obj) => {
      return portName == obj.port;
    });
    this.detailVul = result;
    this.modalRef = this.modalService.show(this.elementView, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  getIpsDetailsFromDeepc() {
    this.newPortSpinner = true;
    this.portfolioSurveyService
      .getIpsDetailsFromDeepc(this.domain, this.scopeConfParams)
      .subscribe((data) => {
        if (data) {
          this.ips_with_vul_mal = data["data"];
          if (this.ips_with_vul_mal.length) {
            this.ips_with_vul_mal.forEach((element) => {
              if (element && element.protocols) {
                element.protocols.forEach((protocol) => {
                  var index = -1;
                  if (protocol) {
                    var serviceObject = { name: "", port: protocol, count: 1 };
                    index = this.newPortList.findIndex(function (service) {
                      return service.port == serviceObject.port;
                    });
                  }
                  if (index == -1) {
                    serviceObject["ips"] = [element];
                    this.newPortList.push(serviceObject);
                  } else {
                    this.newPortList[index]["count"]++;
                    this.newPortList[index]["ips"].push(element);
                  }
                });
              }
            });
            this.newPortList.sort((a, b) => {
              return b.count - a.count;
            });
          }
        }
        this.newPortSpinner = false;
        this.treemapData = this.newPortList.map((a, i) => {
          let dataToReturn = {
            name: a.port,
            value: a.count,
          };
          if (this.criticalPorts.includes(a.port.split("/")[1])) {
            (this.options["minColor"] = "#f86c6b"), //blue
              (this.options["midColor"] = "#f6ae42"), //blue
              (dataToReturn["color"] = 0);
          } else if (this.mediumPorts.includes(a.port.split("/")[1])) {
            (this.options["minColor"] = "#f86c6b"), //blue
              (this.options["midColor"] = "#f6ae42"), //blue
              (dataToReturn["color"] = 10);
          } else {
            dataToReturn["color"] = 50;
          }
          return dataToReturn;
        });
        this.portData = this.treemapData.map((td) => {
          return [td.name, "ports", td.value, td.color];
        });
        this.portData.push(["ports", null, 0, 0]);
        this.drawChart();
        this.oldPortData = [...this.portData];
        if(this.oldPortData.length>101){
          this.setPortOption("show_top_100")
        }
      });
  }

  drawChart() {
    if (this.portData.length > 1) {
      google.charts.load("current", { packages: ["treemap"] });
      google.charts.setOnLoadCallback(() => {
        if (!document.getElementById("chart_div")) {
          console.log(
            "I am port data but I cannot find chart div",
            this.portData.length
          );
          return;
        }
        let tree = new google.visualization.TreeMap(
          document.getElementById("chart_div")
        );
        var data = google.visualization.arrayToDataTable([
          this.columnNames,
          ...this.portData,
        ]);

        tree.draw(data, this.options);
        google.visualization.events.addListener(tree, "select", () => {
          this.portData = [...this.portData];
          // const portName = this.portData[port[0].row][0];
          const portName = this.portData[tree.getSelection()[0].row];
          this.modalHide();
          var result = this.newPortList.find((obj) => {
            return portName[0] == obj.port;
          });
          this.detailVul = result;
          this.modalRef = this.modalService.show(this.elementView, {
            backdrop: "static",
            keyboard: false,
            class: "min-width-dialog-modal",
          });
          tree.draw(data, this.options);
        });
      });
    }
  }
  // public showStaticTooltip(row, size, value) {
  // 	console.log(row, size, value, this.portData)
  // 	return `<div style="background-color:#fff; padding: 4px;">IPs: ${size}</div>`
  // }
  showIpdetais(t, portName) {
    this.modalHide();
    var result = t.newPortList.find((obj) => {
      return portName == obj.port;
    });
    this.detailVul = result;
    this.modalRef = this.modalService.show(this.elementView, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  getServiceVulnerability() {
    this.showSpinnerforServiceVulChart = true;
    this.portfolioSurveyService.getServiceVulnerability(this.domain, this.scopeConfParams).subscribe(
      (vul) => {
        console.log('vul',vul);
        this.networkCriticalVulToShow = []
        this.networkHighVulToShow = []
        this.networkMedVulToShow = []
        this.networkLowVulToShow = []
        this.networkInfoVulToShow = []
        this.serviceVulToShow = []
        this.serviceVulnerabilties = vul["data"];
        this.showSpinnerforServiceVulChart = false;
        this.serviceVulnerabilties.forEach((net) => {
          let severiry = this.commonService.confidenceLevel(net.cve_score);
          if (severiry == 'Critical') {
            this.networkCriticalVulToShow.push(net);
          } else if (severiry == 'High') {
            this.networkHighVulToShow.push(net);
          } else if (severiry == 'Medium') {
            this.networkMedVulToShow.push(net);
          } else if (severiry == 'Low') {
            this.networkLowVulToShow.push(net);
          } else {
            this.networkInfoVulToShow.push(net);
          }
        });
      // if(localStorage.getItem('info_vul_config')=="true"?
      //   this.serviceVulData = [
      //   this.networkCriticalVulToShow.length || 0,
      //   this.networkHighVulToShow.length || 0,
      //   this.networkMedVulToShow.length || 0,
      //   this.networkLowVulToShow.length || 0,
      //   this.networkInfoVulToShow.length || 0,
      // ]:this.serviceVulData = [
      //   this.networkCriticalVulToShow.length || 0,
      //   this.networkHighVulToShow.length || 0,
      //   this.networkMedVulToShow.length || 0,
      //   this.networkLowVulToShow.length || 0
      // ])

      if(localStorage.getItem('info_vul_config')=="true"?this.serviceVulLabels = ["Critical", "High", "Medium", "Low", "Info"]:
      this.serviceVulLabels = ["Critical", "High", "Medium", "Low"])
      if (
        this.networkCriticalVulToShow &&
        this.networkCriticalVulToShow.length
      )
        this.serviceVulToShow = this.networkCriticalVulToShow;
        else if (this.networkHighVulToShow && this.networkHighVulToShow.length)
        this.serviceVulToShow = this.networkHighVulToShow;
        else if (this.networkMedVulToShow && this.networkMedVulToShow.length)
        this.serviceVulToShow = this.networkMedVulToShow;
        else if (this.networkLowVulToShow && this.networkLowVulToShow.length)
        this.serviceVulToShow = this.networkLowVulToShow;
        else if (this.networkInfoVulToShow && this.networkInfoVulToShow.length)
        this.serviceVulToShow = this.networkInfoVulToShow;
     },
      (error) => {
        console.log(error);
      }
    );
  }

  serviceVulChartClicked(event) {
    if (event && event.active && event.active[0]) {
      const index = event.active[0]._index;
      if (index == 0) {
        this.serviceVulToShow = this.networkCriticalVulToShow;
      } else if (index == 1) {
        this.serviceVulToShow = this.networkHighVulToShow;
      } else if (index == 2) {
        this.serviceVulToShow = this.networkMedVulToShow;
      } else if (index == 3) {
        this.serviceVulToShow = this.networkLowVulToShow;
      } else {
        this.serviceVulToShow = this.networkInfoVulToShow;
      }
    }
  }

  serviceOpenDetailVul(template1: TemplateRef<any>, service) {
    this.modalHide();
    this.serDetailVul = service;
    this.modalRef = this.modalService.show(template1, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  webOpenDetailVul(template: TemplateRef<any>, web) {
    this.modalHide();
    this.webDetailVul = web;
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }

  closeModel(value) {
    if (value) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
  getWebVulnerability() { 
    this.showSpinnerforWebVulChart = true;
    this.portfolioSurveyService.getWebAppVulnerability(this.domain, this.scopeConfParams).subscribe(
      (vul) => {
        this.webCriticalVulToShow = []
        this.webHighVulToShow = []
        this.webMedVulToShow = []
        this.webLowVulToShow = []
        this.webInfoVulToShow = []
        this.webVulToShow = []
        
        this.webVulnerabilties = vul["data"];
        this.showSpinnerforWebVulChart = false;
        this.webVulnerabilties.forEach((net) => {
          if (net.severity >= 9) {
            this.webCriticalVulToShow.push(net);
          }
          else if (net.severity >= 7) {
            this.webHighVulToShow.push(net);
          } else if (net.severity >= 4) {
            this.webMedVulToShow.push(net);
          } else if (net.severity >= 0) {
            this.webLowVulToShow.push(net);
          }else {
            this.webInfoVulToShow.push(net);
          }
        });
        // if(localStorage.getItem('info_vul_config')=="true"?
        // this.webVulData = [
        //   this.webCriticalVulToShow.length,
        //   this.webHighVulToShow.length,
        //   this.webMedVulToShow.length,
        //   this.webLowVulToShow.length,
        //   this.webInfoVulToShow.length,
        // ]:
        //   this.webVulData = [
        //   this.webCriticalVulToShow.length,
        //   this.webHighVulToShow.length,
        //   this.webMedVulToShow.length,
        //   this.webLowVulToShow.length,
        // ])

        if(localStorage.getItem('info_vul_config')=="true"?
        this.webVulLabels = ["Critical","High", "Medium", "Low", "Info"]:
        this.webVulLabels = ["Critical","High", "Medium", "Low"])

        if (this.webCriticalVulToShow && this.webCriticalVulToShow.length)
        this.webVulToShow = this.webCriticalVulToShow;
       else if (this.webHighVulToShow && this.webHighVulToShow.length)
          this.webVulToShow = this.webHighVulToShow;
        else if (this.webMedVulToShow && this.webMedVulToShow.length)
          this.webVulToShow = this.webMedVulToShow;
        else if (this.webLowVulToShow && this.webLowVulToShow.length)
          this.webVulToShow = this.webLowVulToShow;
        else if (this.webInfoVulToShow && this.webInfoVulToShow.length)
          this.webVulToShow = this.webInfoVulToShow;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  webVulChartClicked(event) {
    if (event && event.active && event.active[0]) {
      const index = event.active[0]._index;
      if (index == 0) {
        this.webVulToShow = this.webCriticalVulToShow;
      }
      else if (index == 1) {
        this.webVulToShow = this.webHighVulToShow;
      } else if (index == 2) {
        this.webVulToShow = this.webMedVulToShow;
      } else if (index == 3) {
        this.webVulToShow = this.webLowVulToShow;
      } else {
        this.webVulToShow = this.webInfoVulToShow;
      }
    }
  }
  setPortOption(value) {
    this.hidePortsFromTop = 0;
    this.showPortsFromBottom = 0;
    this.showPortsFromTop = 0
    if (value == "hide_top_5") this.hidePortsFromTop = 5;
    else if (value == "hide_top_10") this.hidePortsFromTop = 10;
    else if (value == "hide_top_100") this.hidePortsFromTop = 100;
    else if (value == "show_bottom_5") this.showPortsFromBottom = 6;
    else if (value == "show_bottom_10") this.showPortsFromBottom = 11;
    else if (value == "show_bottom_100") this.showPortsFromBottom = 101;
    else if (value == "show_top_100") this.showPortsFromTop = 101;
    else if (value == "show_all") {
      this.hidePortsFromTop = this.showPortsFromBottom = 0;
      this.portData = [...this.oldPortData];
    }
    if (
      this.hidePortsFromTop &&
      this.oldPortData &&
      this.oldPortData.length > this.hidePortsFromTop
    ) {
      this.portData = this.oldPortData.slice(
        this.hidePortsFromTop,
        this.oldPortData.length
      );
    }
    if (
      this.showPortsFromBottom &&
      this.oldPortData &&
      this.oldPortData.length > this.showPortsFromBottom
    ) {
      this.portData = this.oldPortData.slice(
        this.oldPortData.length - this.showPortsFromBottom,
        this.oldPortData.length
      );
    }
    if (
      this.showPortsFromTop && this.oldPortData &&
      this.oldPortData.length > this.showPortsFromTop
    ) {
      this.portData = this.oldPortData.slice(1, this.showPortsFromTop);
      this.portData.push(["ports", null, 0, 50])
    }
    if (this.portData) {
      (this.options["minColor"] = "#00bfff"), //blue
        (this.options["midColor"] = "#00bfff"), //blue
        this.portData.forEach((a, i) => {
          if (this.criticalPorts.includes(a[0].split("/")[1])) {
            (this.options["minColor"] = "#f86c6b"), //blue
              (this.options["midColor"] = "#f6ae42"), //blue
              (a[3] = 0);
          } else if (this.mediumPorts.includes(a[0].split("/")[1])) {
            (this.options["minColor"] = "#f86c6b"), //blue
              (this.options["midColor"] = "#f6ae42"), //blue
              (a[3] = 10);
          } else {
            a[3] = 50;
          }
        });
    }
    this.drawChart();
  }
  scroll(vul_type) {
    if (vul_type==='network') {
      this.targetSrv.nativeElement.scrollIntoView();
    }
    else if (vul_type==='web') {
      this.targetApp.nativeElement.scrollIntoView();
    }
  }
  filterByInscope(scope){
  this.inscopeFlag = scope;
  // this.getDataForScope();
  this.getAllData()
  }

  getChartsData() {
    if(!this.domain) return;
    // this.serviceChartLoading = true;
    // this.webappChartLoading = true;
    let params:any = {};
    if (this.inscopeConfig == 'true') {
      params = {
        scope: (this.inscopeFlag != null)?this.inscopeFlag:'IN'
      }
    }
    if(this.selectedEntity=='network_vulnerability'){
      this.portfolioSurveyService.getServiceChartData(this.domain,params).subscribe(
        (data: any) => {
          this.serviceVulData = [
            data.by_severity?.critical || 0, 
            data.by_severity?.high || 0,
            data.by_severity?.medium || 0, 
            data.by_severity?.low || 0,
            data.by_severity?.info || 0
          ];
          let countTotal = this.serviceVulData.reduce((partialSum, a) => partialSum + a, 0);
          this.countEmitor.emit({key:'network_vulnerability',count:countTotal});

          this.serviceVulLabels_mod = this.concatLabel(
            this.serviceVulLabels,
            this.serviceVulData);

          let values = Object.values(this.serviceVulData);
        },
        (error) => {
          console.log(error);
          // this.errorForDomain(error);
          // this.serviceChartLoading = false;
        }
      );
    }else if(this.selectedEntity=='web_app_vulnerability'){
      this.portfolioSurveyService.getWebChartData(this.domain, params).subscribe(
        (data: any) => {
          this.webVulData = [
            data.by_severity?.critical || 0, 
            data.by_severity?.high || 0,
            data.by_severity?.medium || 0,
            data.by_severity?.low || 0,
            data.by_severity?.info || 0
          ];
          
          let countTotal = this.webVulData.reduce((partialSum, a) => partialSum + a, 0);
          this.countEmitor.emit({key:'web_app_vulnerability',count:countTotal});

          this.webVulLabels_mod = this.concatLabel(
            this.webVulLabels,
            this.webVulData);
        },
        (error) => {
          console.log(error);
          // this.errorForDomain(error);
          // this.webappChartLoading = false;
        }
      );
    }
  }
  concatLabel(label:any,data:any){
    let mod:any = [];
    let count = 0;
    label.map(x => {
      mod.push(x+' ('+data[count]+')');
      count++;
    });
    console.log('concatLabel',mod);
    return mod;
  }

}