import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewChild, SimpleChange } from '@angular/core';
import { CommonService } from 'app/_services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { reasonToDeleteDigitalRisk } from 'app/_services/common-var.service';
import { PortfolioSurveyService } from 'app/_services';
import { RolesService } from 'app/_services/roles.service'
import { ToastrService } from 'ngx-toastr';
import { DownloadButtonComponent } from 'app/shared/download.component';


@Component({
	selector: 'app-service-vulnerabilty',
	templateUrl: './service-vulnerabilty.component.html',
	styleUrls: ['./service-vulnerabilty.component.scss']
})
export class ServiceVulnerabiltyComponent implements OnInit {

	@Input() mainDomain: any;
	public serviceVulnerabilties = [];
	@Input() showExecutiveReport;
	@Input() isUserTrial: boolean = false;
	@Input() filters = {};
	public  totalCount = 0;
	@Output() refreshCount: EventEmitter<any> = new EventEmitter<any>();
	@Output() updateScopeFilter: EventEmitter<string> = new EventEmitter<string>();
	@Input() serviceVulnerabitiesLoader = true;
    @Input() assetWiseServiceVulnerabilities = [];
	@Input() selectedScope = '';
	@ViewChild("serviceComponent") serviceComponent;
	@ViewChild("filterBlock") filterBlock;
	@ViewChild("getAllNetworkVulnerabilitiesButton") getAllNetworkVulnerabilitiesButton: DownloadButtonComponent;
	public allObsIns;
	public showSpinner: boolean = false;
	public networkCveSubjectObsIns;
	public subjectType = 'service_cve'
	public selectAllRecord = false;
  	public noOfSelectedRecord = 0;
	public selectedCve:any = [];
	public infoFlag: any = false
	public severity = null;
	public order: number = 1;
	public sortWith = 'cve_id';
	public entityType = null;
	public initialSort: boolean = false;
	public vulIndex= null;
    public scopeConfig: boolean = false;
	newRequest: any;
	public ipsToShow = []
	public hostList = [];
	public ipTypeToFilter = 'all'
	public selectedIpType = 'all';
	public show_max_list: number = 3;
	public show_max_location: number = 3;
	public addNewTopRisk: Object = {};
	public seeMore = 50;
	public addNew = true;
	public reasonToDeleteDigitalRisk = reasonToDeleteDigitalRisk;
	// public delReason = null;
	public vulnerabilityData = {};
	public detailVul = {}
	public allVulnerabilites = []
	public newState = null;
	public obsInsCountSpinner = false;
	public vulDetailForCve;
	public ServiceCveIdArray = []
	public fieldsToDownload = {
		'Title': 'cve',
		'Description': 'summary',
		'Severity': 'severity',
		'IP': 'ip',
		'Port': 'port',
		'State': 'state',
		'Validated': 'validate',
		// 'Impact': 'impact',
		// 'Remediation': 'remediation',
		'Discovered At': 'discovered_at',
		'Last Seen At' : 'last_seen_at',
		'References': 'references',
        'IP Validated': 'ip_validated',
		'Observations': 'observation',
		'Insights': 'insight'
	}
	stateCommentTitle = {
		'open':'',
		'resolved':'Comment',
		'ready_to_retest':'Comment',
		'closed':'Comment'
	}
	public page = 1;
	public limit = 10;
	public searchKey = null;
	public openThis;
	public totalServiceCount: number = 12;
    // public selectedScope = 'IN';
	public filterScopes = [
		{ selected: true, key: 'IN', value: 'In Scope' },
		{ selected: false, key: 'OUT', value: 'Out of Scope' },
		{ selected: false, key: 'NOT_DEFINED', value: 'Not Defined' },
		{ selected: false, key: 'ALL', value: 'All' }
	];
	public attribution = {
		"related_domain": "DNS",
		"host": "DNS",
		"net_block": "Netblock",
		"keyword": "Keyword",
		"manual": "Manual"

	}
	public searchip="";
	// public serviceVulnerabitiesLoader = true
	public tagsArray = [];
	public topRiskStatus = false;
	public topRiskLoader = false;
	public allServiceVulnerbity:any = []
	public openDropdown;
	public showThis = 'stateFilter';
	public stateFinalFilter:any = ['open','ready_to_retest'];
	public filter_states = [
		{ selected:true, key: 'open', value: 'Open' },
		{ selected:true, key: 'ready_to_retest', value: 'Ready To Retest' },
		{ selected:false, key: 'closed', value: 'Closed' },
		{ selected:false, key: 'resolved', value: 'Resolved' },
		{ selected:false, key: 'retest_in_progress', value: 'Retest In Progress' },
	];
	public severitiesFinalFilter = [];
	public filter_severities =
	[
		{ selected:false, key: 'critical', value: 'Critical'},
		{ selected:false, key: 'high', value: 'High'},
		{ selected:false, key: 'medium', value: 'Medium' },
		{ selected:false, key: 'low', value: 'Low'},
	]
	public validatedFinalFilter = [true,false];
	public validated_flags = [
		{ selected: true, key: 'Validated', value: true },
		{ selected: true, key: 'Not Validated', value: false },
	];
    public ip_validated_flags = [
        { value: true, selected: false, key: 'Validated'},
        { value: false, selected: false, key: 'Not Validated'},
        { value: null, selected: true, key: 'All'},
    ];
	public viewType = 'cve-view';
	public allFilterList:any = ['State', 'Validate'];
	public filterApplied = true;
	public openActionDropdown = false;
	public retestParam:any = {
		"services": {
			"service_cve_ids": [],
			"cve_ids": [],
			"ips": []
		}
	};
	public applyRetestConfirmModal:any = null;
	public downloadLoading = false;
	newRequesttwo: any;
	public lastParam:any = null;
	public pocSubjectComment;
    public ip_validated: boolean= null;
	public impactServ:any = [];
	public editImpact = false;
	constructor(
		public commonService: CommonService, 
		public portfolioSurveyService: PortfolioSurveyService,
		public rolesService: RolesService,
		public modalService: BsModalService,
		public modalRef: BsModalRef,
		public modalRef2: BsModalRef,
		public toastr: ToastrService
	) { 
		
	}

	ngOnInit() {
		// console.log('SelectedScope',this.selectedScope);
        // this.scopeConfig = localStorage.getItem('scope_config') === 'true';
		// this.getServiceVulnerability();
		this.setScopeStatus();
		this.portfolioSurveyService._vulenrbilitySubject.subscribe(res=>{
			this.getServiceVulnerability()
		});
	}
	async setScopeStatus() {
		await this.commonService.delay(2000);
		this.infoFlag = localStorage.getItem('info_vul_config')
		if (this.infoFlag === 'true') {
		this.filter_severities =[
		{ selected:false, key: 'critical', value: 'Critical'},
		{ selected:false, key: 'high', value: 'High'},
		{ selected:false, key: 'medium', value: 'Medium' },
		{ selected:false, key: 'low', value: 'Low'},
		{ selected:false, key: 'info', value: 'Info'},
	]
		}
	};
	
    public filterIpsByValidity(value: boolean) {
        this.ip_validated = value;
        if (value !== null) {
            if (!this.allFilterList.includes('IP Validated')) {
                this.allFilterList.push('IP Validated')
            }
        } else {
			this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'IP Validated');
        }
        this.getServiceVulnerability();
    }
 
	formatTags(vul_data) {
		vul_data.forEach((entry) => {
			let vulTags = []
			entry['ips'].forEach((tagsEntry) => {
				if (tagsEntry['tags']) {
					vulTags = [...vulTags, ...tagsEntry['tags']]
				}
			});
			entry['tags_status'] = this.checkTopRiskStatus(vulTags)
		});
	}
	checkTopRiskStatus(tagsData) {
		return tagsData.includes("top_risk")


	}
	goToPage(val) {
		this.page = val
		this.getServiceVulnerability();

	}
	getAllServiceVulnerability() {
		if(!this.mainDomain) return;
		this.allServiceVulnerbity = [];
		this.newRequest ? this.newRequest.unsubscribe(): null;
		var csvData:any = [];
		var obsevationsInsightsArr = {};
		this.downloadLoading = true;
		// if(params.page) params.page = this.page;
		// if(params.limit) params.limit = null;
		// // params = {
		// // 	"page": this.page,
		// // 	"limit": null
		// // }
        // params['scope'] = this.selectedScope;
		var params = {
			"page": this.page,
			"limit": null
		}
		params['scope'] = this.selectedScope;
		if(this.selectedScope) this.fieldsToDownload['Scope'] = "scope";
		this.newRequest=this.portfolioSurveyService.getServiceVulnerability(this.mainDomain, params).subscribe(vul => {
			this.showSpinner=true
			this.portfolioSurveyService.getAllObsAndIns(this.mainDomain, this.subjectType).subscribe((data) => {
				this.allObsIns = data['data']
				this.showSpinner = false
				csvData = vul['data'];
				this.newRequest = null;
				if (csvData && csvData.length) {
					csvData.forEach(serVul => {
						let severity = this.commonService.confidenceLevel(serVul.cve_score)
						serVul.obs_arr = {}
						serVul.ins_arr = {}
						if (serVul.ips.length) {
							serVul.ips.forEach(ip => {
								if (ip.services.length) {
									ip.services.forEach(ser => {
										var servicecve_id = ''
										ip.servicecve.forEach(cve => {
											let id = ser.id
											if (id == cve.service_id) {
												servicecve_id = cve.servicecve_id
												serVul.obs_arr[servicecve_id] = []
												serVul.ins_arr[servicecve_id] = []
											}
										});
										this.allObsIns.forEach(obsIns => {
											if (obsIns.subject_id == servicecve_id) {
												serVul.obs_arr[servicecve_id].push(obsIns.observation)
												serVul.ins_arr[servicecve_id].push(obsIns.insight)
											}
										})
										this.allServiceVulnerbity.push({
											...ser, ...{
												cve: serVul.cve_id,
												summary: serVul.cve_summary,
												severity: severity,
												impact: serVul.impact,
												remediation: serVul.remediation,
												//discovered_at: serVul.discovered_at,
												//last_seen_at: serVul.servicecve_updated,
												references: serVul.references,
												ip: ip.ip_address,
												ip_validated: ip.ip_validated ? 'Yes' : 'No',
												scope: ip.scope,
												validate: this.getValidateState(ip.servicecve,ser.id),
												observation:serVul.obs_arr[servicecve_id],
												insight:serVul.ins_arr[servicecve_id],
												...this.getServiceCveDates(ip.servicecve,ser.id)
											},
											
										})
									})
								}
							});
						}
					})
					this.totalServiceCount = this.serviceVulnerabilties.length?this.serviceVulnerabilties[0]['total']:0;
					this.formatTags(this.serviceVulnerabilties);
					this.getAllNetworkVulnerabilitiesButton.download();
					this.allServiceVulnerbity = [];
					}
					this.downloadLoading = false;
				}, error => {
					this.showSpinner = false;
			})
		})
	}	
	// Return Validated Flag
	getValidateState(list,id){
		if(list && list.length>0){
			let vaidated = false;
			list.map((x)=>{
				if(x.service_id==id){
					vaidated = x.validated;
				}
			})
			return (vaidated)?"Yes":"No";
		}else{
			return 'No';
		}
	}
	getServiceCveDates(list,id){
		if(list && list.length>0){
			let data_info  = {};
			list.map((x)=>{
				if(x.service_id==id){
					data_info = {
						'discovered_at' : x.discovered_at,
						'last_seen_at' : x.updated_at
					}
					
				}
			})
			return data_info
		}
	}
	clearSearch() {
		this.searchKey = null;
		this.page = 1
		this.getServiceVulnerability()
	}

	searchVulnerability() {
		this.page = 1
		this.getServiceVulnerability();
		
	}
	getattributionforipV2(searchip, index){
		let ipAttributation = []
		this.searchip = searchip;
		this.showSpinner = true
		this.ipsToShow = []
		var params = {
			searchKey: this.searchip && this.searchip.length ? this.searchip : null,
		}
		this.portfolioSurveyService.getIpsDetailsPaginated(this.mainDomain, params).subscribe(
			(data) => {
				if (data['data']) {
				this.ipsToShow = data['data']
				this.showSpinner = false
				this.hostList = this.ipsToShow[0]['ip_details']
				this.detailVul['ips'][index].host = this.hostList
				}

			}
		);
	}

	getattributionforip(searchip, index){
		this.showSpinner = true
		this.getattributionforipV2(searchip, index)
	}

	getServiceVulnerability() {
		if(!this.mainDomain) return;
		this.newRequesttwo ? this.newRequesttwo.unsubscribe(): null;
		this.serviceVulnerabilties = []
		this.ServiceCveIdArray = []
		this.serviceVulnerabitiesLoader = true
		var params = {
			"page": this.page,
			"limit": 10,
			searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
			sortKey: this.sortWith ? this.order == -1 ? '-' + this.sortWith : this.sortWith : null,
			...this.filters
		}
		let stateString = '';
		if(this.stateFinalFilter.length){
			stateString = this.joinState(this.stateFinalFilter);			
		}else{
			let allFilter:any = [];
			this.filter_states.map(x=>allFilter.push(x.key));
			stateString = this.joinState(allFilter);
		}
		if(stateString){
			params['state'] = stateString;
		}
		let severityString = this.joinState(this.severitiesFinalFilter);
		if(severityString){
			params['severities'] = severityString;
		}
		let validatedString = this.joinState(this.validatedFinalFilter);
		if (validatedString) {
		params['validated_flags'] = validatedString;
		}
        params['scope'] = this.selectedScope;

        if (this.ip_validated === true) {
            params['ip_validated'] = true;
        } else if(this.ip_validated === false) {
            params['ip_validated'] = false;
        }

		this.filterApplied = true;

		this.newRequesttwo=this.portfolioSurveyService.getServiceVulnerability(this.mainDomain, params).subscribe(vul => {
			this.newRequesttwo = null;
			let profile: any = localStorage.getItem('profile');
			let parsedData = JSON.parse(profile);
			let account_type = parsedData.account_type;
			this.serviceVulnerabilties = vul['data'];
			if(this.vulIndex !== null){
				this.detailVul['ips'] = this.serviceVulnerabilties[this.vulIndex]['ips']
			}
			this.totalServiceCount = vul['count'];
			this.formatTags(this.serviceVulnerabilties)
			this.serviceVulnerabitiesLoader = false;
			this.serviceVulnerabilties.forEach(service_vul => {
				let service_ips = service_vul.ips
				service_ips.forEach(ip_detail => {
					var servicecve = ip_detail.servicecve
					servicecve.forEach(cve => {
						{
							cve.account_type = account_type
							var service_name = ''
							var servicecve_id = cve.servicecve_id
							var services = ip_detail.services
							var servicecve = ip_detail.servicecve
							services.forEach(service => {
								let id = service.id
								if (id == cve.service_id) {
									service_name = service.service_name
								}
							});
							if (service_name && servicecve_id) {
								cve.subjectTypeName = {
									"subject_type": this.subjectType,
									"subject_name": ' IP-' + ip_detail.ip_address + ', Port-' + service_name + ', CVE_Title-' + service_vul['cve_id'],
									"subject_id": servicecve_id,
								  };
							}
						}
						this.ServiceCveIdArray.push(cve.servicecve_id)
					})
				})
			  })
			if (this.ServiceCveIdArray.length) {
				this.getObsInsCountsForEachCVE(this.ServiceCveIdArray)
			}	
		});
		this.filterCounter(this.mainDomain);
	}
	ngOnChanges(changes:SimpleChange) {
		if(this.selectedScope){
			if(!this.allFilterList.includes('Scope')) this.allFilterList.push('Scope');
		}else{
			if(this.filterBlock){
				this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Scope');
			}
		}
		this.filterCounter(this.mainDomain);
		if (this.serviceVulnerabilties && this.serviceVulnerabilties.length) {
			this.serviceVulnerabilties.forEach(serVul => {
				let severity = this.commonService.confidenceLevel(serVul.cve_score)
				if (serVul.ips.length) {
					serVul.ips.forEach(ip => {
						if (ip.services.length) {
							ip.services.forEach(ser => {
								this.allVulnerabilites.push({ ...ser, ...{ cve: serVul.cve_id, summary: serVul.cve_summary, severity: severity, impact: serVul.impact, remediation: serVul.remediation, discovered_at: serVul.discovered_at, references: serVul.references, ip: ip.ip_address, scope: ip.scope } })
							})
						}
					});
				}
			})
			this.totalServiceCount = this.serviceVulnerabilties.length
				? this.serviceVulnerabilties[0]['total'] : 0;

			this.formatTags(this.serviceVulnerabilties)
		}
		else {
			this.totalServiceCount = 0;
		}
		if(changes['mainDomain'] || changes['filters'] || changes['selectedScope'] || changes['serviceVulnerabitiesLoader']){
			this.getServiceVulnerability();
		}else if(changes['totalCount']){
			this.getServiceVulnerability();
			// this.filterCounter(this.mainDomain);
		}
	}

	// formatIps(ips){
	// 	var ips_concat = []
	// 	ips.forEach(element => {
	// 		ips_concat.push(element.ip_address)
	// 	});
	// 	return ips_concat.join(",")
	// }

	getTopRiskMapping(template: TemplateRef<any>, vul_data) {
		this.topRiskLoader = true
		// vul_data.sources = this.formatIps(vul_data.ips)
		this.entityType = "network"
		vul_data['risk_level'] = this.commonService.confidenceLevel(vul_data.cve_score)
		this.portfolioSurveyService.topRiskMap(vul_data, this.entityType).subscribe(data => {
			if (data) {
				this.modalOpenForAdd(template, data)
				this.topRiskLoader = false
			}
		});

	}

	modalOpenForAdd(template: TemplateRef<any>, vul_data) {
		this.addNewTopRisk = vul_data['data']
		this.addNewTopRisk['main_domain'] = this.mainDomain
		this.modalRef = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}

	openRemoveOptions(template: TemplateRef<any>, service) {
		let service_ids = []
		this.modalRef.hide()
		service['ips'].forEach(element => {
			element['servicecve'].forEach(service_id =>{ 
				service_ids.push(service_id?.servicecve_id)

			})
			
		});
		this.vulnerabilityData['ids'] = service_ids
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}

	openModalRemoveEachAssest(template: TemplateRef<any>, ip) {
		this.modalRef.hide()
		var ids = []
		ip['servicecve'].forEach(element =>{
			ids.push(element['servicecve_id'])
		});
		this.vulnerabilityData['ids'] = ids
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	delServiceVul(reason) {
		this.vulnerabilityData['archive_reason'] = reason;
		this.deleteServiceVulnerability();
		// this.delReason = null;
	}

	public deleteServiceVulnerability() {
		this.portfolioSurveyService.deleteServiceVulnerability(this.vulnerabilityData, this.mainDomain).subscribe(data => {
			this.modalRef.hide();
			alert("Vulnerability deleted successfully!")
			this.getServiceVulnerability();
			this.vulnerabilityData = {};
			this.refreshCount.emit('network');
		}, err => {
			this.modalRef.hide();
			this.vulnerabilityData = {};
		});
	}
	dataToRemediate(data) {
		this.vulnerabilityData['archive_reason'] = data['archive_reason'];
		this.vulnerabilityData['comment'] = data['comment']
		this.deleteServiceVulnerability();
	}

	closeModel(value) {
		if (value) {
			this.modalRef.hide();
			this.vulnerabilityData = {};
		}
	}
	delReason(reason) {
		this.delServiceVul(reason)
	}
	openDetailVul(template: TemplateRef<any>, service, index) {
		this.detailVul = service
		this.vulIndex = index
		this.showSpinner = true
		this.detailVul['ips'].forEach((element, index) => {
			this.getattributionforip(element.ip_address, index)
		});
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'min-width-dialog-modal' });
	}
	
	// severityOfRiskLevel(riskLevel) {
	// 	switch (true) {
	// 		case (riskLevel == 'Critixcal'): return 'badge-danger';
	// 		case (riskLevel == 'High'): return 'badge-danger';
	// 		case (riskLevel == 'Low'): return 'badge-success';
	// 		case (riskLevel == 'Medium'): return 'badge-warning';

	// 		default: return 'badge-primary';
	// 	}
	// }
	cancelDel(a) {
		this.vulnerabilityData = {};
	}
	searchServiceVul() {
		this.page = 1;
		this.getServiceVulnerability()
	}
	searchAllServiceVul() {
		this.searchKey = null
		this.getServiceVulnerability()
	}
	sortVuls() {
		this.getServiceVulnerability()
	}
	updateState(ip,parentIp){
		// console.log(ip);
		// ip.servicecve_state = this.newState;
		if(ip['servicecve_id'] && ip['editingStateTemp']){
			ip['stateComment'] = (ip['stateComment'])?ip['stateComment'].trim():ip['stateComment'];
			let payload = {
				"servicecve_id":ip['servicecve_id'],
    			"state":ip['editingStateTemp'],
    			"comment":ip['stateComment']
			}
			ip.isSubmiting = true;
			this.portfolioSurveyService.updateState('service',payload, this.mainDomain).subscribe((data:any) => {
				ip.isSubmiting = false;
				if(data.status=="pass"){
					if(data.message){
						this.toastr.success(data.message);
						ip.editingState = false;
						ip['servicecve_state'] = ip['editingStateTemp'];
						parentIp.editingState = false;
					}
				}else{
					// console.log(data);
					this.toastr.info("Unable to update state.");
				}
				// this.newState = null;
			},
			(error) => {
				ip.isSubmiting = false;
				// this.newState = null;
				console.log(error);
				this.toastr.error("Something went wrong.");
			});
		}else{
			this.toastr.error("Please select State to update.");
		}
	}
	checkSpace(e){
		let text = e;
		return text.replace(/\s+/, "");
	}
	beautifyState(str){
		// console.log(str, "I am sftr")
		if(str){
			return str.split('_').join(' ');
		} else return 'open'
	}
	filterByUpdatedAt($event) {
		// console.log($event);
		this.sortWith = 'updated_at';
		this.initialSort = true;
		this.openThis = -1;
		this.sortVuls()
	}
	checkActiveStatus(ser){
		if(ser['editingStateTemp']){
			if(ser['editingStateTemp']=='open'){
				return true;
			}else{
				if(this.strTrim(ser.stateComment)){
					return true;
				}else{
					return false;
				}
			}
		}else{
			return false;
		}
	}
	strTrim(str=''){
		return str.trim();
	}
	getPort(list,key){
		// console.log(list, key)
		let port = null;
		list.forEach(e => {
			if(e.id==key){
				port = e.port;
			}
		});
		return port;
	}
	detectFilters(){
		this.allFilterList = this.filterBlock.checkState(this.stateFinalFilter,this.allFilterList,'State');
		this.allFilterList = this.filterBlock.checkState(this.severitiesFinalFilter,this.allFilterList,'Severities');
		this.allFilterList = this.filterBlock.checkState(this.validatedFinalFilter,this.allFilterList,'Validate');
	}
	changeStateFilter(state){
		this.stateFinalFilter=[];
		this.filter_states.map((x)=>{
			if(x.selected) this.stateFinalFilter.push(x.key);
		});
		this.detectFilters();
	}
	filterSubDomainByState(action=''){
		if(action=='clear'){
			this.stateFinalFilter=[];
			this.filter_states.map((x)=>{
				x.selected = false
			});
			this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'State');
		}
		// Filter send to api
		this.page=1
		this.getServiceVulnerability();
		
	}
	changeSeverityFilter(state){
		this.severitiesFinalFilter=[];
		this.filter_severities.map((x)=>{
			if(x.selected) this.severitiesFinalFilter.push(x.key);
		});
		this.detectFilters();
	}

    filterByScope (scope: string) {
        this.selectedScope = scope;
        this.page = 1;
        this.getServiceVulnerability();
        this.updateScopeFilter.emit(scope);
    }

	filterSubDomainBySeverities(action){
		if(action=='clear'){
			this.severitiesFinalFilter=[];
			this.filter_severities.map((x)=>{
				x.selected = false
			});
			this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Severities');
		}
		// Filter send to api
		this.page=1
		this.getServiceVulnerability();
		
	}
	joinState(states,commaSep=true){
		if(states.length){
			let state_string = "";
			if(commaSep){
				state_string = '"'+states.join('","')+'"';
			}else{
				state_string = states.join('');
			}
			return state_string;
		}else{
			return "";
		}
	}
	changeState(filter,index){
		this.viewType = "cve-view";
		// console.log(filter,index);
		if(filter=='severity'){
			this.filter_severities.map(x=>{
				x.selected = false;
			});
			this.filter_severities[index].selected = true;
			this.changeSeverityFilter(this.filter_severities[index].key);
			this.filterSubDomainByState('clear');
			this.page=1
			this.getServiceVulnerability();
		}
		if(filter=='state'){
			this.filter_states.map(x=>{
				x.selected = false;
			});
			this.filter_states[index].selected = true;
			this.changeStateFilter(this.filter_states[index].key);
			this.filterSubDomainBySeverities('clear');
			this.page=1
			this.getServiceVulnerability();
		}
	}
	applyRetest(modalTemplate) {
		let ip: any = [];
		let cve_id: any = [];
		let service_cve_id: any = [];
		this.selectedCve.map(x => {
			x.ips.map(cve => {
				if (!ip.includes(cve.ip_address)) {
					ip.push(cve.ip_address);
				}
				if (!cve_id.includes(x.cve_id)) {
					cve_id.push(x.cve_id);
				}
				cve.servicecve.map(ser_cve => {
					if (!service_cve_id.includes(ser_cve.servicecve_id)) {
						service_cve_id.push(ser_cve.servicecve_id);
					}
				})
			})
		});
		this.retestParam.services.service_cve_ids = service_cve_id,
			this.retestParam.services.cve_ids = cve_id,
			this.retestParam.services.ips = ip,
			this.applyRetestConfirmModal = this.modalService.show(modalTemplate, {
				backdrop: "static",
				keyboard: false,
				class: "min-width-dialog-modal",
			});
	}
	submitForReset(param = {}) {
		let profile: any = localStorage.getItem('profile');
		let parsedData = JSON.parse(profile);
		let customer_id = parsedData.cust_id;
		this.openActionDropdown = false;
		this.applyRetestConfirmModal.hide();
		if (param['web_apps']) delete param['web_apps'];
		this.portfolioSurveyService.triggerReset(this.mainDomain, param, customer_id).subscribe((data: any) => {
			this.toastr.success("Applied for retest successfully.");
		}, (error) => {
			// console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
	isValidCve(cve_id) {
		const regexExp = /CVE-\d{4}-\d{4,7}/
		const found = cve_id.match(regexExp);
		return (found) ? true : false
	}
	refreshData(){
		let that = this;
		setTimeout(function(){
			if(that.serviceComponent) that.serviceComponent.getIpsDetailsFromDeepc();
		},100)
	}
	selectAllIp() {
		if (this.selectAllRecord) {
			this.serviceVulnerabilties.map(x => {
				x.checked = true;
				this.checkUncheckCve(x)
			});
		} else {
			this.serviceVulnerabilties.map(x => {
				x.checked = false;
				this.checkUncheckCve(x)		
			});
		}
		this.selectedCve = this.getSelectedRecords();
	}
	checkUncheckCve(ip) {
		let checkedStatus = ip.checked;
		if (!checkedStatus) this.selectAllRecord = false;
		ip.checkedStatus = !ip.checkedStatus;
		this.selectedCve = this.getSelectedRecords();
		// console.log(this.selectedCve);
	}
	getSelectedRecords() {
		let selectedCve: any = [];
		this.noOfSelectedRecord = 0;
		this.serviceVulnerabilties.map(x => {
			if (x.checked) {
				this.noOfSelectedRecord = this.noOfSelectedRecord + 1;
				selectedCve.push(x);
			}			
		});
		return selectedCve;
	}
	filterCounter(mainDomain){
		// console.log("networkcve/stats 3",mainDomain, typeof mainDomain);
		if(!mainDomain) return;
		// (this.chartApiCall)?this.chartApiCall.unsubscribe():null;
		let params:any = {
			searchKey: this.searchKey && this.searchKey.length ? this.searchKey : null,
			...this.filters
		}
		let stateString = '';
		if(this.stateFinalFilter.length){
			stateString = this.joinState(this.stateFinalFilter);			
		}else{
			let allFilter:any = [];
			this.filter_states.map(x=>allFilter.push(x.key));
			stateString = this.joinState(allFilter);
		}
		if(stateString){
			params['state'] = stateString;
		}
		let severityString = this.joinState(this.severitiesFinalFilter);
		if(severityString){
			params['severities'] = severityString;
		}
		params['scope'] = this.selectedScope;
		if (this.ip_validated === true) {
			params['ip_validated'] = true;
		} else if (this.ip_validated === false) {
			params['ip_validated'] = false;
		}
		let validatedString = this.joinState(this.validatedFinalFilter);
		if (validatedString) {
			params['validated_flags'] = validatedString;
		}
		if(this.lastParam && !this.commonService.matchAllParamValue(this.lastParam,params)) return;
		this.lastParam = params;
		console.log(params);
		this.portfolioSurveyService.getServiceChartData(mainDomain, params).subscribe((data: any) => {
			this.lastParam = null;
			let totalCount_ = 0;
			if(data['by_severity']){
				Object.keys(data['by_severity']).forEach(function (key) {
					totalCount_ = totalCount_ + data['by_severity'][key] || 0;
				});
			}
			this.totalCount = totalCount_;
		}, (error) => {
			console.log(error);
		});
	}
	changeValidatedFilter() {
		this.validatedFinalFilter = [];
		this.validated_flags.map((x) => {
		  if (x.selected) this.validatedFinalFilter.push(x.value);
		});
		this.detectFilters();
	  }
	  filterValidatedCve(action) {
		if (action == 'clear') {
		  this.selectAllRecord = false;
		  this.validatedFinalFilter = [];
		  this.validated_flags.map((x) => {
			x.selected = false
		  });
		  this.allFilterList = this.filterBlock.removeFromArray(this.allFilterList,'Validate');
		}
		// Filter send to api
		this.page = 1
		this.getServiceVulnerability();
		
	  }
	getObsInsCountsForEachCVE(arrayList) {
		this.obsInsCountSpinner = true
		const params = {
		  subject_ref_list: arrayList,
		  subject_type: this.subjectType
		}
		this.portfolioSurveyService.getObsInsCountForEachDomain(this.mainDomain, params).subscribe(data => {
		  this.obsInsCountSpinner = false
		  this.vulDetailForCve = data['data']
		this.serviceVulnerabilties.forEach(service_vul => {
			let service_ips = service_vul.ips
			service_ips.forEach(ip_detail => {
				ip_detail.obs_count = {}
				ip_detail.ins_count = {}
				var servicecve = ip_detail.servicecve
				servicecve.forEach(cve => {
					var servicecve_id = cve.servicecve_id
					ip_detail.obs_count[servicecve_id] = 0
					ip_detail.ins_count[servicecve_id] = 0
					if (this.vulDetailForCve[servicecve_id]) {
						ip_detail.obs_count[servicecve_id] = this.vulDetailForCve[servicecve_id].count_obs
						ip_detail.ins_count[servicecve_id]  = this.vulDetailForCve[servicecve_id].count_ins
					}
				})
			})
		  })
		})
	  }
	openCommentsModule(template: TemplateRef<any>, ser) {
		let profile: any = localStorage.getItem('profile');
		let parsedData = JSON.parse(profile);
		let account_type = parsedData.account_type;
		this.pocSubjectComment = {
			"subject_type": ser['subjectTypeName']['subject_type'],
			"subject_name": ser['subjectTypeName']['subject_name'].trim(),
			"subject_id": ser['subjectTypeName']['subject_id'],
			"visible_to": account_type
		}
		this.modalRef2 = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}
	openImpact(template: TemplateRef<any>, ser) {
		this.editImpact = false;
		this.impactServ = ser;
		console.log(this.impactServ);
		// this.impactServ['impact'] = 'Lorem Ipsome';
		if(!this.impactServ['impact']) this.editImpact = true;
		this.modalRef2 = this.modalService.show(template, {
			backdrop: "static",
			keyboard: false,
			class: "min-width-dialog-modal",
		});
	}
	updateImpact(){
		let ip = this.impactServ;
		let payload = {
			"ids":[ip['servicecve_id']],
			"impact":ip['impact']
		}
		this.portfolioSurveyService.updateState('service',payload, this.mainDomain).subscribe((data:any) => {
			if(data.status=="pass"){
				if(data.message){
					this.editImpact = false;
					this.toastr.success('Impact updated successfully');
				}
			}else{
				this.toastr.info("Unable to update .");
			}
		},
		(error) => {
			console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
	deleteImpct(){
		let ip = this.impactServ;
		let payload = {
			"ids":[ip['servicecve_id']],
			"impact":null
		}
		this.portfolioSurveyService.updateState('service',payload, this.mainDomain).subscribe((data:any) => {
			if(data.status=="pass"){
				if(data.message){
					this.editImpact = true;
					this.toastr.success('Impact deleted successfully');
					ip['impact'] = null;
				}
			}else{
				this.toastr.info("Unable to delete impact.");
			}
		},
		(error) => {
			console.log(error);
			this.toastr.error("Something went wrong.");
		});
	}
}
