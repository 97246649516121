import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RbResultsComponent } from './rb-results/rb-results.component';
import { RbResumeComponent } from './rb-resume/rb-resume.component';
import { RunbookComponent } from './runbook.component';
import { AssesmentComponent } from './assesment/assesment.component'
import { AttackListAssessmentDetailsComponent } from './attack-list-assessment-details/attack-list-assessment-details.component'
const routes: Routes = [
  {
    path: '',
    data: {
      title: ""
    },
    children: [
      {
        path: 'resume/:attack_id/:runstep_id',
        component: RbResumeComponent,
        data: {
          title: " Playbooks / Review",
        },
      },
      {
        path: 'results/:attack_id',
        component: RbResultsComponent,
        data: {
          title: " Playbooks / Result",
        },
      },
      { 
        path: 'new', 
        component: AssesmentComponent,
        data: {
          title: "Playbooks / Run New",
        },
      },
      { 
        path: 'active', 
        component: RunbookComponent,
        data: {
          title: "Playbooks / Active",
        },
      },
      { 
        path: 'completed', 
        component: RunbookComponent,
        data: {
          title: "Playbooks / Completed",
        },
      },
      { 
        path: '', 
        component: RunbookComponent,
        data: {
          title: " Playbooks / Active",
        },
      },
    { 
      path: 'assessment/details/:id', 
      component: AttackListAssessmentDetailsComponent,
      data: {
        title: " Playbooks / Assessment",
      },
  },
  { 
    path: 'scan/details/:id', 
    component: AttackListAssessmentDetailsComponent,
    data: {
      title: " Playbooks / Scan/ Details",
    },
},
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RunbookRoutingModule { }
