import { Pipe, PipeTransform } from '@angular/core';
import * as validator from 'validator';

@Pipe({
	name: 'validateUrl'
})
export class ValidUrlPipe implements PipeTransform {

	constructor() { }

	transform(url: string) {
		if (url != null) {
			return validator.isURL(url);
			// var validurl = url.match("^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$")
			// if (validurl) {
			// 	return true
			// } else {
			// 	return false
			// }
		}
	}
}
