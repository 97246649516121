import { Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "./http.service";

import {
	AirtableConfig,
	AssessmentConfig
} from "../../environments/environment";

@Injectable()
export class EcsmService {

	public airTableConfig = AirtableConfig.url;
	public assessmentConfig = AssessmentConfig.url

	private headers = new Headers({
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("id_token"),
	});

	constructor(
		public httpSVC: HttpService
	) { }

	public getEcsmDetails() {
		var url = this.airTableConfig + "/at/api/ecsm/";
		return this.httpSVC.get(url);
	}
	public addSecrets(dataToSend) {
		var url = this.assessmentConfig + "/ecsm/secret";
		return this.httpSVC.post(url,dataToSend);
	}
	public addSecretsFile(uploadedFile) {
		var url = this.assessmentConfig + "/ecsm/secret";
		return this.httpSVC.uploadFile(url, uploadedFile);
	}
	
	public getPlatformCount(){
		var url = this.assessmentConfig + "/ecsm/secret/platform_count";
		return this.httpSVC.get(url);
	}
	public getPlatformDetails({id = null, page = 1 }= {}){
		var url = this.assessmentConfig + "/ecsm/secret?platform_id=" + id + "&archive=false" + "&page=" + page
		return this.httpSVC.get(url);
	}
	public getAllSectrets(){
		var url = this.assessmentConfig + "/ecsm/secret"
		return this.httpSVC.get(url)
	}
	public deletSecrateRecord(id){
		var url = this.assessmentConfig + "/ecsm/secret/" + id
		return this.httpSVC.delete(url)
	}
	public updateSecret(id,dataToSend){
		var url = this.assessmentConfig + "/ecsm/secret/" + id
		return this.httpSVC.patch(url,dataToSend)
	}
}