import {
  Component,
  OnInit,
  ElementRef,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { AuthService } from "./views/pages/auth.service";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  @ViewChild("template")
  private modalTemplate: TemplateRef<any>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private _elementRef: ElementRef
  ) {}

  ngOnInit() {
    // this.authService.handleAuthentication();
    this._elementRef.nativeElement.removeAttribute("ng-version");

    // Scroll to top on Route Change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
