// Network Vulnrability
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PortfolioSurveyService } from '../../../_services';
import { ToastrService } from "ngx-toastr";
import { CommonService } from '../../../_services/common.service';
@Component({
  selector: 'app-add-vulnerability-web-app',
  templateUrl: './add-vulnerability-web-app.component.html',
  styleUrls: ['./add-vulnerability-web-app.component.scss']
})
export class AddVulnerabilityWebAppComponent implements OnInit {

  @Output() filterByUpdatedAt: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedScope:any = null;
  domain:string = localStorage.getItem("mydomain");
  isSubmiting:boolean = false;
  isSearchingApplication:boolean = false;
  isSearchingCve:boolean = false;
  addNewVulnerability:any = {
      'application_url':"",
      'protocol':"",
      'cve_id':"",
      'cve_summary':"",
      'tags':"",
      'vul_id':"",
      'cve_score':0.0,
      'state':"open",
      'comment':"",
      'remediation':"",
      'locations':"",
      // 'discovered_at':""
  }
  stateCommentTitle = {
    'open':'',
    'resolved':'Add closure text',
    'ready_to_retest':'Add retest text',
    'closed':'Add comment'
  }
  availableCveRecord:any = [];
  availableApplications:any = [];
  search_target:any = "";
  tempTag:string = "";
  tempLocation:string = "";
  allTags:any = ["MANUAL"];
  allLocations:any = "";
  isUrlAvaulable = false;
  isSearching:boolean = false;
  searched:boolean = false;

  constructor(
    public modalService: BsModalService,
		public modalRef: BsModalRef,
    public portfolioSurveyService: PortfolioSurveyService,
    private toastr: ToastrService,
    public commonService:CommonService
  ) { 
    
  }

  ngOnInit(): void {
  }

  showVulneraBilityModal(template){
    this.resetAllFields();
    this.modalRef = this.modalService.show(template, {
      backdrop: "static",
      keyboard: false,
      class: "min-width-dialog-modal",
    });
  }
  appendTag(){
    if(this.tempTag){
      let checkCommal = this.tempTag.trim().split(',').filter(el => {
        el = el.trim(); return el != null && el != '' && el != ' ';
      });
      if(checkCommal.length>0){
        checkCommal = checkCommal.map((x)=>{ if(x) return x.trim().toUpperCase(); });
        this.allTags.push(...checkCommal);
        this.allTags = this.getUniqueValuesWithCase(this.allTags,false);
        this.addNewVulnerability.tags = this.allTags.join(",");
      }else{
        alert('Please enter one or multiple tags using comma "," as a separator.');
      }
      this.tempTag = '';
    }
  }
  appendLocation(){
    if(this.tempLocation){
      let checkCommal = this.tempLocation.trim().split(',').filter(el => {
        el = el.trim(); return el != null && el != '' && el != ' ';
      });
      if(checkCommal.length>0){
        checkCommal = checkCommal.map((x)=>{ if(x) return x.trim(); });
        this.allLocations.push(...checkCommal);
        this.allLocations = this.getUniqueValuesWithCase(this.allLocations,false);
        this.addNewVulnerability.locations = this.allLocations.join(",");
      }else{
        alert('Please enter one or multiple locations using comma "," as a separator.');
      }
      this.tempLocation = '';
    }
  }
  getUniqueValuesWithCase(arr, caseSensitive){
    let temp = [];
    return [...new Set(caseSensitive ? arr : arr.filter(x => {
        let _x = typeof x === 'string' ? x.toLowerCase() : x;
        if(temp.indexOf(_x) === -1){
            temp.push(_x)
            return x;
        }
    }))];
  }
  removeTag(array,value){
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
      this.addNewVulnerability.tags = this.allTags.join(",");
    }
  }
  removeLocation(array,value){
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
      this.addNewVulnerability.locations = this.allLocations.join(",");
    }
  }
  checkSpace(e){
    let text = e;
    return text.replace(/\s+/, "");
  }

  search_() {
    this.isSearchingApplication = true;
    let targetIp = this.search_target;
		const params = {
			searchKey: targetIp.trim()
		}
    if(this.selectedScope) params['scope'] = this.selectedScope;
    this.isSearching = true;
    this.isUrlAvaulable = false;
    this.searched = true;
		this.portfolioSurveyService.getwebapplication(this.domain, params).subscribe(
			(data:any) => {
        this.isSearchingApplication = false;
				if(data?.data && data['count_apps']>0){
          console.log(data['count_apps']);
          this.availableApplications = data.data;
          this.isUrlAvaulable = true;
        }
        this.isSearching = false;
			},
			(error) => {
        this.isSearchingApplication = false;
        this.isSearching = false;
				console.log(error);
			}
		);
	}
  checkComment(){
    if(this.addNewVulnerability.state!="open"){
      if(this.checkSpace(this.addNewVulnerability.comment)){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  }
  submitNewVulnerability(){
    this.isSubmiting = true;
    this.addNewVulnerability.tags = (this.allTags.length==1 && this.allTags[0]=="No tag available")?"":this.allTags.join(",");
    this.addNewVulnerability.locations = (this.allLocations.length==1 && this.allLocations[0]=="No Location available")?"":this.allLocations.join(",");
    this.portfolioSurveyService.addVulnerebility("web_app",this.addNewVulnerability, this.domain).subscribe(
			(data:any) => {
        
        this.isSubmiting = false;
        if(data.status=="pass"){
          if(data.message){
            this.toastr.success(data.message);
            this.modalRef.hide();
            this.filterByUpdatedAt.emit('update');
            setTimeout( () => {
              this.resetAllFields();
            },500);
          }
        }else{
          if (data.message){
            this.toastr.info(data.message);
          }else{
            this.toastr.info("Unable to add Vulnerability.");
          }
        }
			},
			(error) => {
        this.isSubmiting = false;
        console.log(error);
        this.toastr.error("Something went wrong.");
			}
		);
  }

  resetAllFields(){
    this.addNewVulnerability = {
      'application_url':"",
      'protocol':"",
      'cve_id':"",
      'cve_summary':"",
      'tags':"",
      'vul_id':"",
      'cve_score':0.0,
      'state':"open",
      'comment':"",
      'remediation':"",
      'locations':"",
      // 'discovered_at':""
    }
    this.tempTag = "";
    this.tempLocation = "";
    this.allTags = ["MANUAL"];
    this.allLocations = [];
    this.isSearching = false;
    this.searched = false;
    this.isUrlAvaulable = false;
    this.availableApplications = [];
    this.search_target = '';
  }
  search_cve(){
    this.cveReset();
    this.isSearchingCve = true;
    this.addNewVulnerability.cve_id = this.addNewVulnerability.cve_id.trim()
    this.portfolioSurveyService.searchCveId("webapplication",this.addNewVulnerability['cve_id'], this.domain).subscribe(
			(data:any) => {
        this.isSearchingCve = false;
        console.log(data);
        if(data.status=="pass"){
          if(data['data'].length>0){
            this.availableCveRecord = data.data;
          }else{
            this.toastr.info('No existing CVE found, proceed to create new one.');
            this.availableCveRecord = [];
          }
        }else{
          this.availableCveRecord = [];
        }
			},
			(error) => {
        this.isSearchingCve = false;
        console.log(error);
        this.toastr.error("Something went wrong.");
			}
		);
  }
  getSummary($e){
    // console.log($e);
    if(!this.addNewVulnerability.vul_id){
      this.cveReset();
      return;
    }
    if(this.availableCveRecord.length>0){
      this.availableCveRecord.map((x)=>{
        console.log(x);
        if(x.id==this.addNewVulnerability.vul_id){
          this.addNewVulnerability.cve_summary = x.cve_summary;
          // this.allTags = (x.tags)?x.tags:['No tag available'];
          this.addNewVulnerability.cve_score = x.severity || x.cve_score;
        }
      })
    }
  }
  cveReset(){
    this.addNewVulnerability.cve_summary = '';
    // this.allTags = ["MANUAL"];
    this.addNewVulnerability.cve_score = 0.0;
    this.addNewVulnerability.vul_id = '';
  }
  strTrim(str=''){
    return str.trim();
  }
}
