import { Component, OnInit, Input } from '@angular/core';
import { getThumURL } from 'thum.io';
// import { Http } from '@angular/http'
import * as validator from 'validator';

@Component({
  selector: 'app-url-screenshot-small-thumnail',
  templateUrl: './url-screenshot-small-thumnail.component.html',
  styleUrls: ['./url-screenshot-small-thumnail.component.scss']
})
export class UrlScreenshotSmallThumnailComponent implements OnInit {

  @Input() ip: any;
  @Input() url: any;
  @Input() fromView: any;
  screenShotUrl: string;
  thumURL: string;
  showImage: boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.ip) {
      this.screenShotUrl = 'http://' + this.ip;
    } else {
      if(this.fromView && this.fromView=="subdomain"){
        if (this.url.search('http') == -1 && this.url.search('https') == -1)
          this.screenShotUrl = 'https://' + this.url;
        else if(this.url.search('https') == -1 && this.url.search('http') >= 0)
          this.screenShotUrl = this.url.replace('http','https');
        else
          this.screenShotUrl = this.url
      }else{
        if (this.url.search('http') == -1)
          this.screenShotUrl = 'http://' + this.url;
        else
          this.screenShotUrl = this.url
      }
    }
    // if (!validator.isURL(this.screenShotUrl)) {
      this.thumURL = getThumURL({
        url: this.screenShotUrl,
        // auth: {
          //   type: 'raw',
          //   secret: 'security',
          //   keyId: '1526',
          // },
        });
      // }
  }

  showImageClick(event, value) {
    this.showImage = value;
    event.stopPropagation();
  }

}
